import { Loader } from "@hexocean/braintrust-ui-components";
import { PROFILE_COMPLETION_STEPS } from "@js/apps/freelancer/components/profile-completion-sequence/const";
import { ModalInstance } from "@js/components/modal";
import { assertUnreachable } from "@js/utils";

import { useProfileCompletionSequence } from "./hooks/use-profile-completion-sequence";
import { About, Role, Skills, WorkExperience } from "./steps";

import styles from "./styles.module.scss";

export const openProfileCompletionSequenceModal = () => {
  ModalInstance.open({
    children: <CompletionSequence />,
    closeButton: false,
    keepOnBackdropClick: true,
    padding: false,
    className: styles.wrapper,
  });
};

const CompletionSequence = () => {
  const { currentStep } = useProfileCompletionSequence();

  if (!currentStep) {
    return <Loader centered />;
  }

  switch (currentStep) {
    case PROFILE_COMPLETION_STEPS.ABOUT:
      return <About />;
    case PROFILE_COMPLETION_STEPS.ROLE:
      return <Role />;
    case PROFILE_COMPLETION_STEPS.SKILLS:
      return <Skills />;
    case PROFILE_COMPLETION_STEPS.WORK_EXPERIENCE:
      return <WorkExperience />;
    default:
      assertUnreachable(currentStep);
      return null;
  }
};
