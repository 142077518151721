import type { ReactElement } from "react";
import React from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { LoginAs } from "@js/apps/common/components";
import { getJobLoginAsUserId, isJobCompleted } from "@js/apps/jobs/utils";
import { JobActionsMenu } from "@js/apps/jobs/views/job-details/job-actions-menu";
import { Date } from "@js/components/date";
import { UserBubble } from "@js/components/user-bubble";
import type { Job, JobForCoreTeam } from "@js/types/jobs";
import {
  assertUnreachable,
  capitalizeEachFirstLetter,
  dateFromNow,
} from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

import { openManageJobOwnersModal } from "../../manage-job-owners";
import { MatchItem } from "../../matchers-listing/matcher-item";
import {
  EditJobButton,
  InviteTalentsButton,
  ViewJobBidsButton,
} from "../common";

import { useCoreTeamActionBarContext } from "./core-team-context";

const getCoreTeamActions = (job: Job) => {
  const isCompleted = isJobCompleted(job);
  const jobLoginAsUserId = getJobLoginAsUserId(job);

  return [
    jobLoginAsUserId ? (
      <LoginAs
        variant="secondary"
        key="login-as"
        as={jobLoginAsUserId}
        inverse
      />
    ) : null,

    <React.Fragment key="invite-talents-button">
      {!isCompleted && <InviteTalentsButton job={job} />}
    </React.Fragment>,
    <React.Fragment key="edit-job-button">
      {!isCompleted && <EditJobButton job={job} />}
    </React.Fragment>,
    <ViewJobBidsButton key="view-job-bids-button" job={job} />,
    <JobActionsMenu key="job-actions-menu" job={job} color="white" />,
  ].filter((component): component is ReactElement => Boolean(component));
};

export const CoreTeamActionBar = () => {
  const { job } = useCoreTeamActionBarContext();

  if (!job) return null;

  switch (job.job_status) {
    case ENUMS.JobStatus.OPEN: {
      return <CoreTeamActionBar_JobOpen />;
    }
    case ENUMS.JobStatus.ACTIVE:
    case ENUMS.JobStatus.ACTIVE_FILLED:
    case ENUMS.JobStatus.ACTIVE_NOT_FILLED: {
      return <CoreTeamActionBar_JobActive />;
    }
    case ENUMS.JobStatus.ON_HOLD: {
      return <CoreTeamActionBar_JobOnHold />;
    }
    case ENUMS.JobStatus.COMPLETED_HIRED: {
      return <CoreTeamActionBar_CompletedHired />;
    }
    case ENUMS.JobStatus.COMPLETED_NOT_HIRED: {
      return <CoreTeamActionBar_CompletedNotHired />;
    }
    case ENUMS.JobStatus.COMPLETED: {
      return null;
    }
    default: {
      assertUnreachable(job.job_status);
      return null;
    }
  }
};

const CoreTeamActionBar_JobOpen = () => {
  const values = useCoreTeamActionBarContext();
  const job = values.job as JobForCoreTeam;

  if (!job) return null;

  const title = `Job posted ${dateFromNow(job.published_at)}`;
  const subtitle =
    !job.bids_count || job.bids_count?.total_bids_count === 0
      ? "No Applications"
      : job.bids_count?.interviewing_bids_count > 0
        ? `${job.bids_count?.interviewing_bids_count}
            Candidate${
              job.bids_count?.interviewing_bids_count > 1 ? "s" : ""
            } In Screening`
        : `${job.bids_count?.total_bids_count} Active Application${
            job.bids_count?.total_bids_count > 1 ? "s" : ""
          }`;

  return (
    <CoreTeamActionBarDefault title={title} subtitle={subtitle} job={job} />
  );
};

const CoreTeamActionBar_JobActive = () => {
  const values = useCoreTeamActionBarContext();
  const job = values.job as JobForCoreTeam;

  if (!job) return null;

  const title = "This is an active project 🙌";
  const subtitle = `Project Hired ${capitalizeEachFirstLetter(
    dateFromNow(job.hired_at),
  )}`;

  return (
    <CoreTeamActionBarDefault title={title} subtitle={subtitle} job={job} />
  );
};

const CoreTeamActionBar_JobOnHold = () => {
  const values = useCoreTeamActionBarContext();
  const job = values.job as JobForCoreTeam;

  if (!job || !job.on_hold_since) return null;

  const title = "Job is paused";
  const subtitle = (
    <>
      Paused{" "}
      <Date
        date={job.on_hold_since}
        format={DateFormats["January 1st, 1970"]}
      />
    </>
  );

  return (
    <CoreTeamActionBarDefault title={title} subtitle={subtitle} job={job} />
  );
};

const CoreTeamActionBar_CompletedHired = () => {
  const { job } = useCoreTeamActionBarContext();

  if (!job) return null;

  const title = "This project is complete ✅";
  const subtitle = (
    <>
      Completed{" "}
      <Date date={job.completed_at} format={DateFormats["January 1st, 1970"]} />
    </>
  );

  return (
    <CoreTeamActionBarDefault title={title} subtitle={subtitle} job={job} />
  );
};

const CoreTeamActionBar_CompletedNotHired = () => {
  const values = useCoreTeamActionBarContext();
  const job = values.job as JobForCoreTeam;

  if (!job || !job.closed_at) return null;

  const title = "This job is closed. No talent was hired.";
  const subtitle = (
    <>
      Job Closed{" "}
      <Date date={job.closed_at} format={DateFormats["January 1st, 1970"]} />
    </>
  );

  return (
    <CoreTeamActionBarDefault title={title} subtitle={subtitle} job={job} />
  );
};

type CoreTeamActionBarProps = BoxProps & {
  title: string;
  subtitle: React.ReactNode;
  job: Job;
};

const CoreTeamActionBarDefault = ({
  title,
  subtitle,
  job,
  ...props
}: CoreTeamActionBarProps) => {
  const actions = getCoreTeamActions(job);

  return (
    <RoundedBox borderRadius="24px" bgcolor="var(--dark-blue)" {...props}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        gap={4}
        className="action__bar"
      >
        <Box>
          <Typography color="white" component="h2" size="large">
            {title}
          </Typography>
          <Typography color="white" variant="label" size="small" component="p">
            {subtitle}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexShrink={0}
          alignItems="center"
          className="buttons__wrapper"
        >
          {actions.map((action, index) => {
            return (
              <Box key={action.key} ml={index === 0 ? 0 : 1}>
                {action}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box mt={4}>
        <MatcherListingForCoreTeam job={job} />
      </Box>
    </RoundedBox>
  );
};

const MatcherListingForCoreTeam = ({ job }: { job: Job }) => {
  return (
    <Box
      maxWidth={1}
      display="flex"
      flexDirection="row"
      gap={1}
      flexWrap="wrap"
    >
      {job.creator && (
        <Box mb={1} minWidth="32%">
          <MatchItem
            user={job.creator}
            name={job.creator.public_name}
            title="Employer"
            actionIconType="view"
            job={job}
            to={job?.creator?.profile_url}
            color="var(--info-2)"
          />
        </Box>
      )}

      {!!job?.op_owners?.length ? (
        job?.op_owners?.map((op_owner) => (
          <Box mb={1} minWidth="32%" key={op_owner.id}>
            <MatchItem
              action={() => openManageJobOwnersModal({ job })}
              actionIconType="edit"
              user={op_owner}
              name={op_owner.public_name}
              job={job}
              title="Braintrust Matcher"
              color="var(--info-2)"
            />
          </Box>
        ))
      ) : (
        <Box mb={1} minWidth="32%">
          <AddOwnerArea job={job} title="Braintrust Matcher" />
        </Box>
      )}

      {!!job?.sale_owners?.length ? (
        job?.sale_owners?.map((sale_owner) => (
          <Box
            mb={1}
            minWidth="32%"
            maxWidth={1}
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
            key={sale_owner.id}
          >
            <MatchItem
              action={() => openManageJobOwnersModal({ job })}
              actionIconType="edit"
              user={sale_owner}
              name={sale_owner.public_name}
              job={job}
              title="Hiring Manager"
              color="var(--info-2)"
            />
          </Box>
        ))
      ) : (
        <Box mb={1} minWidth="32%">
          <AddOwnerArea job={job} title="Hiring Manager" />
        </Box>
      )}
    </Box>
  );
};

type AddOwnerAreaProps = {
  job: Job;
  title: string;
};

const AddOwnerArea = ({ job, title }: AddOwnerAreaProps) => {
  return (
    <Box mb={1} minWidth="32%">
      <UserBubble
        color="var(--info-2)"
        actionIconType="add"
        title={title}
        name="Add Owner"
        onClick={() => openManageJobOwnersModal({ job })}
      />
    </Box>
  );
};
