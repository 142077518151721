import type { FC } from "react";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Carousel,
  IconButton,
  Typography,
  useSwiperRef,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon, LinkIcon } from "@hexocean/braintrust-ui-components/Icons";
import { CarouselHeader } from "@js/apps/freelancer/components";
import { ModalInstance } from "@js/components/modal";
import { Sanitize } from "@js/components/sanitize";
import { useAnchorRedirectWarning } from "@js/hooks";
import { IMPLICIT_ANCHOR_WARNING_ATTR, sanitize } from "@js/services";
import type { WorkSampleItem } from "@js/types/freelancer";

type ProfileProjectsModuleItemProps = {
  item: WorkSampleItem;
};

export const ProfileProjectsModuleItem: FC<ProfileProjectsModuleItemProps> = ({
  item,
}) => {
  const { anchorWrapperRef } = useAnchorRedirectWarning<HTMLDivElement>();
  const projectDescription = (() => {
    if (!item.description) return null;

    const descriptionSanitized = sanitize(item.description, {
      ALLOWED_TAGS: [],
    });

    const description =
      descriptionSanitized.length > 120
        ? `${descriptionSanitized?.slice(0, 120)}...`
        : descriptionSanitized;

    return description;
  })();

  const projectMainImageUrl =
    item.main_image?.image.attachment.file ||
    `${SETTINGS.STATIC_URL}freelancer/project-empty-state-thumbnail.svg`;

  return (
    <Box
      bgcolor="var(--off-white)"
      padding={4}
      minWidth={0}
      maxWidth={412}
      minHeight={522}
      borderRadius={2}
      display="flex"
      justifyContent="center"
    >
      <Card
        sx={{
          minHeight: 380,
          backgroundColor: "var(--off-white)",
          boxShadow: 0,
        }}
      >
        <CardMedia
          component="img"
          height="164"
          image={projectMainImageUrl}
          alt="project image"
          sx={{ marginBottom: 2 }}
        />
        <CardContent
          sx={{
            padding: 0,
            maxWidth: 350,
            "&:last-child": { paddingBottom: 0 },
          }}
        >
          <Typography
            component="h3"
            variant="title"
            size="small"
            fontWeight={400}
            multilineEllipsis={2}
            marginBottom={1}
            style={{ wordBreak: "break-word" }}
          >
            {item.title}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            mb={1}
            gap={0.5}
            flexWrap="wrap"
          >
            <Typography
              variant="paragraph"
              color="grey-1"
              component="p"
              multilineEllipsis={2}
            >
              {projectDescription}
            </Typography>
            <Box display="flex" alignItems="center" gap={0.5}>
              <button
                className="btn-reset pointer text-underline"
                onClick={() => openReadMoreProjectModal(item)}
              >
                Read more
              </button>
            </Box>
          </Box>
          {item.url && (
            <Box display="flex" gap={1} mt={2} ref={anchorWrapperRef}>
              <LinkIcon />
              <div ref={anchorWrapperRef}>
                <a
                  href={item.url}
                  target="_blank"
                  className="text-underline"
                  rel="noopener noreferrer"
                  {...{ [IMPLICIT_ANCHOR_WARNING_ATTR]: true }}
                >
                  Project Link
                </a>
              </div>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

const openReadMoreProjectModal = (item: WorkSampleItem) => {
  ModalInstance.open({
    children: (
      <Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={2}
          mb={2}
        >
          <Typography
            component="h3"
            variant="title"
            size="small"
            fontWeight={400}
          >
            {item.title}
          </Typography>
          <IconButton
            variant="tertiary"
            size="small"
            aria-label="Delete project"
            onClick={ModalInstance.close}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {!!item.portfolio_item_images?.length && <ImageSwiper item={item} />}

        <Typography
          component="p"
          variant="paragraph"
          color="grey-1"
          maxHeight={286}
          mt={4}
          sx={{
            overflowY: "auto",
          }}
        >
          <Sanitize input={item.description} />
        </Typography>
      </Box>
    ),
    className: "profile-page-modal",
  });
};

export const ImageSwiper = ({ item }: ProfileProjectsModuleItemProps) => {
  const { anchorWrapperRef } = useAnchorRedirectWarning<HTMLDivElement>();
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  const images = item?.portfolio_item_images;
  const hasMoreThanOneImage = images?.length > 1;

  if (!images) return null;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={hasMoreThanOneImage ? 3 : 0}
      >
        {item.url && (
          <Box display="flex" gap={1} ref={anchorWrapperRef}>
            <LinkIcon />
            <a
              href={item.url}
              className="text-underline"
              target="_blank"
              rel="noopener noreferrer"
              {...{ [IMPLICIT_ANCHOR_WARNING_ATTR]: true }}
            >
              Project Link
            </a>
          </Box>
        )}
        <CarouselHeader
          title=""
          prevRef={prevElRef}
          nextRef={nextElRef}
          hideNavigationButtons={images.length <= 1}
        />
      </Box>
      <Carousel
        allowSlideNext={hasMoreThanOneImage}
        allowSlidePrev={hasMoreThanOneImage}
        allowTouchMove={hasMoreThanOneImage}
        slides={images.map((el) => (
          <CardMedia
            key={el.id}
            component="img"
            height={323}
            image={el.image.attachment.file}
            alt="project image"
            style={{
              borderRadius: 16,
              width: "auto",
              overflow: "hidden",
              objectFit: "contain",
            }}
          />
        ))}
        slideProps={{
          style: {
            display: "flex",
            justifyContent: "center",
          },
        }}
        data-testid="image-swiper"
        observer
        observeParents
        slidesPerView={"auto"}
        spaceBetween={24}
        navigation={{
          prevEl,
          nextEl,
        }}
      />
    </>
  );
};
