import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { JobCarouselItem } from "@js/apps/freelancer/components/home-carousel/job-carousel-item";
import { viewJobConfirmation } from "@js/apps/jobs/actions";
import { useGetRelevantJobsQuery } from "@js/apps/jobs/api";
import { JobLocationContext } from "@js/apps/jobs/context/job-location-context";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

import { BidCreateSuccessModalCloseButton } from "../bid-create-success-modal-close-button";

import styles from "./freelancer-screened-modal-content.module.scss";

type FreelancerScreenedModalContentProps = {
  closeModal: () => void;
  roleId: number;
};

export const FreelancerScreenedModalContent = ({
  closeModal,
  roleId,
}: FreelancerScreenedModalContentProps) => {
  const isMobile = useMediaQuery("sm");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetchRelevantJobsFilters = useMemo(
    () => ({ role: roleId, page_size: 3 }),
    [roleId],
  );

  const { data, isLoading } = useGetRelevantJobsQuery(fetchRelevantJobsFilters);

  const similarJobs = data?.results;

  const returnToJobsList = () => {
    navigate("/jobs/");
    closeModal();
  };

  const viewApplications = () => {
    navigate("/talent/dashboard/my_jobs/my_proposals");
    closeModal();
  };

  useEffect(() => {
    dispatch(viewJobConfirmation());
  }, [dispatch]);

  return (
    <>
      <div className={styles.topSection}>
        <BidCreateSuccessModalCloseButton
          variant="transparent"
          onClick={returnToJobsList}
          sx={{
            position: "absolute",
            top: "28px",
            right: "32px",
          }}
        />
        <Typography component="p" variant="title" size="large">
          🥳
        </Typography>
        <Typography
          component="p"
          variant="title"
          size="small"
          fontWeight={400}
          mb={3}
          mt={1.5}
        >
          Your application was sent! Good luck!
        </Typography>
        <Box maxWidth={600} mb={3.25}>
          Most clients review applications within a few days, so keep an eye out
          for interview requests or messages. In the meantime, keep applying to
          jobs on Braintrust!
        </Box>
        <div className={styles.ctaButtons}>
          <Button variant="secondary" onClick={viewApplications}>
            View application
          </Button>
          <Button
            variant="primary"
            onClick={returnToJobsList}
            endIcon={<ArrowRightIcon />}
          >
            Browse more jobs
          </Button>
        </div>
        <Alert
          withIcon
          alignIcon={isMobile ? "center" : "top"}
          className={styles.alert}
        >
          <Typography component="p">
            Only accept job offers on Braintrust. For more information on how to
            stay safe when job searching, visit our{" "}
            <Typography
              component="link"
              href={SETTINGS.SUPPORT_URLS.REPORT_SCAMS_AND_FRAUD_ARTICLE_URL}
              target="_blank"
              RouterLink={RouterLink}
            >
              help center
            </Typography>
            .
          </Typography>
        </Alert>
      </div>

      <Box className={styles.bottomSection}>
        {isLoading ? (
          <Loader centered />
        ) : (
          <>
            <Typography component="p" variant="label">
              👉 You’re a good match for these similar jobs
            </Typography>
            <JobLocationContext.Provider
              value={JobLocationContext.Values.recommended_jobs_modal}
            >
              <div className={styles.jobsList}>
                {similarJobs?.map((job) => (
                  <Box key={job.id} height="auto" width={350}>
                    <JobCarouselItem
                      job={job}
                      hideSkills
                      borderColor="var(--soft-beige)"
                    />
                  </Box>
                ))}
              </div>
            </JobLocationContext.Provider>
          </>
        )}
      </Box>
    </>
  );
};
