import { Box, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";

import {
  getApplicationStatusColorVariant,
  getApplicationTooltipText,
} from "./utils";

import styles from "./style.module.scss";

type ApplicationStatusWithTooltipProps = {
  status?: EnumType<typeof ENUMS.JobFreelancerStatus>;
  appliedDate?: string | null;
  offerCreatedDate?: string | null;
  offerAcceptedDate?: string | null;
  appliedTitle?: string | null;
  appliedTooltip?: string | null;
};

export const ApplicationStatusWithTooltip = ({
  status,
  appliedDate,
  offerCreatedDate,
  offerAcceptedDate,
  appliedTitle,
  appliedTooltip,
}: ApplicationStatusWithTooltipProps) => {
  // There is no pill for this status
  if (status === ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS || !status)
    return null;

  const title = getApplicationTooltipText({
    status,
    appliedDate,
    offerCreatedDate,
    offerAcceptedDate,
  });
  const variant = getApplicationStatusColorVariant(status);

  return (
    <Tooltip
      title={appliedTooltip || title}
      color="var(--black)"
      placement="top"
      maxWidth={238}
      disabled={!appliedTooltip && !title}
    >
      <span className={styles.tooltip} style={{ minWidth: 0 }}>
        <Box display="flex" gap="4px" alignItems="center">
          <Typography
            className={styles.applicationStatusPill}
            component="p"
            size="small"
            title={appliedTitle || ENUMS.JobFreelancerStatusLabels[status]}
            ellipsis
            sx={{
              backgroundColor: variant.bgColor,
              color: variant.textColor,
            }}
          >
            {appliedTitle || ENUMS.JobFreelancerStatusLabels[status]}
          </Typography>
          {(appliedTooltip || title) && <InfoIcon sx={{ fontSize: "20px" }} />}
        </Box>
      </span>
    </Tooltip>
  );
};
