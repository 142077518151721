import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { FormErrors } from "redux-form";
import { SubmissionError } from "redux-form";

import { API } from "@js/api";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { freddyWidgetManager } from "@js/apps/common/services/freddy-feedback";
import { setCurrentPostedATSJobId } from "@js/apps/employer/components/ats-integration-modal/ats-jobs-slice";
import { openReviewsDraftsJobModal } from "@js/apps/employer/components/review-drafts-modal/modal-content";
import { useGetJobDraftQuery, useGetManagedJobQuery } from "@js/apps/jobs/api";
import {
  closeSummarizeJobModal,
  openSummarizeJobModal,
} from "@js/apps/jobs/apps/create-job/components/summarize-module";
import type { GetDraftInitialValuesArg } from "@js/apps/jobs/apps/create-job/utils/form-initial-values";
import { getDraftInitialValues } from "@js/apps/jobs/apps/create-job/utils/form-initial-values";
import { getDraftInitialValuesOnBehalfOfAClient } from "@js/apps/jobs/apps/create-job/utils/form-initial-values-on-behalf-of-a-client";
import { getCreateJobInitialValues } from "@js/apps/jobs/apps/create-job/utils/get-create-job-initial-values";
import {
  prepareCreateJobSubmitFormValues,
  prepareJobDraftFormValues,
  prepareLatestDraftDataToSave,
} from "@js/apps/jobs/apps/create-job/utils/prepare-data";
import type { JobFormSubmitType } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { JOB_FORM_SUBMIT_TYPE } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector, useQueryParams } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import type { Job, JobFormValues } from "@js/types/jobs";
import { assertUnreachable, isNumeric } from "@js/utils";

import { clearJobFormState } from "../../actions";
import { jobEndDateValidator, jobStartDateValidator } from "../../validators";
import { useHandleFailedSubmission } from "../handle-failed-submission";
import { useCreateJobAndRemoveJobDraft } from "../use-create-job-and-remove-job-draft";
import { useCreateOrUpdateJobDraft } from "../use-create-or-update-job-draft";
import { useSaveLastJobDraftOnChange } from "../use-save-last-job-draft-on-change";

export const useCreateJob = () => {
  // proper submit type should be set when user clicks on submit button
  const submitType = useRef<JobFormSubmitType>();
  const updateSubmitType = useCallback((type: JobFormSubmitType) => {
    submitType.current = type;
  }, []);
  const user = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isEmployer } = useAccountType();
  const draftId = useIdParam();
  const { back_to_review_modal, copy_draft_id, copy_job_id } = useQueryParams();
  const shouldBackToReviewModal = back_to_review_modal === "true";
  const copyDraftId = copy_draft_id ? Number(copy_draft_id) : undefined;
  const copyJobId = copy_job_id ? Number(copy_job_id) : undefined;
  const draftIdToFetch = draftId || copyDraftId;
  const isUserVerified = user?.is_verified || user?.is_impersonated_session;

  const { createJobAndDeleteJobDraft } = useCreateJobAndRemoveJobDraft();
  const { data: draft, isFetching: isFetchingJobDraft } = useGetJobDraftQuery(
    { id: draftIdToFetch as number },
    { skip: !draftIdToFetch, refetchOnMountOrArgChange: true },
  );
  const uploadedJobDescription = useAppSelector(
    (state) => state.jobs.uploadedJobDescription,
  );
  const aiGenerated = useAppSelector((state) => state.jobs.aiGenerated);

  const userId = user?.id;

  const canManageJobAsCoreMember = useCanManageJobsOnBehalfOfClient();
  const onSubmitFail = useHandleFailedSubmission(draftId);

  const { createOrUpdateJobDraft } = useCreateOrUpdateJobDraft();

  const { onChange } = useSaveLastJobDraftOnChange({ draft });

  const invalidateDrafts = useCallback(() => {
    dispatch(API.util.invalidateTags([{ type: "JobDrafts", id: "LIST" }]));
  }, [dispatch]);

  const { data: jobToCopy, isFetching: isFetchingJobToCopy } =
    useGetManagedJobQuery({ jobId: copyJobId as number }, { skip: !copyJobId });

  // cleanup effect
  useEffect(() => {
    return () => {
      dispatch(clearJobFormState());
    };
  }, [dispatch]);

  const handleJobSubmit = useCallback(
    async (values: JobFormValues) => {
      const shouldCreateDraft = !isUserVerified;

      const data = prepareCreateJobSubmitFormValues(values);
      if (!data) {
        return;
      }

      if (!shouldCreateDraft) {
        return createJobAndDeleteJobDraft({
          ...data,
          dry_run: false,
        });
      }

      const draftDataToSave = prepareLatestDraftDataToSave(values, draft);
      if (draftDataToSave) {
        await createOrUpdateJobDraft({
          data: draftDataToSave,
        });
      }

      return createJobAndDeleteJobDraft({
        ...data,
        dry_run: true,
        public_after_verification: true,
      });
    },
    [draft, createOrUpdateJobDraft, isUserVerified, createJobAndDeleteJobDraft],
  );

  const onSubmit = useCallback(
    async (values: JobFormValues) => {
      switch (submitType.current) {
        case JOB_FORM_SUBMIT_TYPE.draft_set_up:
        case JOB_FORM_SUBMIT_TYPE.ats_job_draft_set_up:
        case JOB_FORM_SUBMIT_TYPE.draft: {
          const preparedValues = prepareJobDraftFormValues(values);

          return createOrUpdateJobDraft({
            data: preparedValues,
          });
        }

        case JOB_FORM_SUBMIT_TYPE.job_copy_draft_set_up: {
          if (!isNumeric(copyJobId)) {
            throw new SubmissionError({
              _error: `Missing or invalid job id to copy! job id: ${copyJobId}`,
            });
          }

          const preparedValues = prepareJobDraftFormValues(values);
          const original_job = Number(copyJobId);

          return createOrUpdateJobDraft({
            data: {
              ...preparedValues,
              locations: preparedValues.locations ?? [],
            },
            original_job,
          });
        }

        case JOB_FORM_SUBMIT_TYPE.job_dry_run: {
          const data = prepareCreateJobSubmitFormValues(values);

          if (!data) {
            return;
          }
          return createJobAndDeleteJobDraft({
            ...data,
            dry_run: true,
          });
        }

        case JOB_FORM_SUBMIT_TYPE.job: {
          return handleJobSubmit(values);
        }

        case undefined: {
          break;
        }

        default:
          assertUnreachable(submitType.current);
      }
    },
    [
      createJobAndDeleteJobDraft,
      copyJobId,
      handleJobSubmit,
      createOrUpdateJobDraft,
    ],
  );

  const onSubmitSuccess = useCallback(
    (
      response: Job | JobFormValues | Pick<Job, "id"> | undefined,
      _dispatch: unknown,
      props,
    ) => {
      if (!userId) {
        return;
      }

      switch (submitType.current) {
        case JOB_FORM_SUBMIT_TYPE.draft_set_up:
        case JOB_FORM_SUBMIT_TYPE.job_copy_draft_set_up: {
          if (canManageJobAsCoreMember) {
            props.initialize(
              getDraftInitialValuesOnBehalfOfAClient({
                draft: (response ?? {}) as GetDraftInitialValuesArg["draft"],
                userId,
                isEmployer,
              }),
            );
          } else {
            props.initialize(
              getDraftInitialValues({
                draft: (response ?? {}) as GetDraftInitialValuesArg["draft"],
                userId,
              }),
            );
          }

          navigate(`/jobs/add_new/${response?.id}/`);
          invalidateDrafts();
          break;
        }
        case JOB_FORM_SUBMIT_TYPE.draft: {
          const newDraftHasBeenCreated = draftId !== response?.id;
          if (newDraftHasBeenCreated) {
            navigate(`/jobs/add_new/${response?.id}/`);
            invalidateDrafts();
          }
          break;
        }

        case JOB_FORM_SUBMIT_TYPE.ats_job_draft_set_up: {
          navigate(`/jobs/add_new/${response?.id}/?back_to_review_modal=true`);
          invalidateDrafts();
          break;
        }
        case JOB_FORM_SUBMIT_TYPE.job_dry_run: {
          openSummarizeJobModal();
          break;
        }
        case JOB_FORM_SUBMIT_TYPE.job: {
          closeSummarizeJobModal();
          if (!isUserVerified) {
            navigate("/employer/dashboard/my_jobs/");
            Snackbar.toast({
              header: "Your account is not verified",
              buttonText: "Got it",
              content: `Your job offer has been saved as a draft, to make it public,
                  verify your email address by clicking the verification link in
                  the email sent to the address you provided during
                  registration.`,
            });
            break;
          }
          invalidateDrafts();
          navigate(`/jobs/${response?.id}/invite_talent/`);
          if (shouldBackToReviewModal) {
            dispatch(setCurrentPostedATSJobId(response?.id));
            openReviewsDraftsJobModal();
          } else {
            // Displays Freddy Feedback after job creation when user is not in flow with ats job.
            freddyWidgetManager.showFreddyWidget(
              SETTINGS.FREDDY_FEEDBACK_JOB_WIDGET_ID,
            );
          }

          break;
        }

        case undefined: {
          break;
        }

        default:
          assertUnreachable(submitType.current);
      }
    },
    [
      canManageJobAsCoreMember,
      dispatch,
      draftId,
      invalidateDrafts,
      isEmployer,
      navigate,
      shouldBackToReviewModal,
      isUserVerified,
      userId,
    ],
  );

  const initialValues = useMemo(() => {
    return getCreateJobInitialValues({
      userId,
      draft,
      jobToCopy,
      canManageJobAsCoreMember,
      aiGenerated,
      copyDraftId,
      isEmployer,
      uploadedJobDescription,
    });
  }, [
    userId,
    draft,
    jobToCopy,
    copyDraftId,
    canManageJobAsCoreMember,
    uploadedJobDescription,
    aiGenerated,
    isEmployer,
  ]);

  // we have to show loader whenever draft is being fetched to remount the form and reinitialize fields
  const loading = isFetchingJobDraft || isFetchingJobToCopy;

  return {
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    initialValues,
    loading,
    updateSubmitType,
    lastSavedDraft: draft,
    // we don't want to save onChange in set_up step
    onChange: draftId ? onChange : undefined,
    validate,
  };
};

const validate = (values: JobFormValues) => {
  const errors: FormErrors<JobFormValues> = {};

  errors["start_date"] = jobStartDateValidator(values.start_date);
  errors["deadline"] = jobEndDateValidator(values.deadline, values.start_date);

  return errors;
};
