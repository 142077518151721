import { useUser } from "@js/apps/common/hooks";

import { EmployerDashboardLayout } from "../../employer/views/dashboard/layout";
import { FreelancerDashboardLayout } from "../../freelancer/views/dashboard/layout";

export type FreelancerOrEmployerDashboardLayoutProps = {
  pageTitle: string;
  children: React.ReactNode;
  pageColor?: string;
  contentClassName?: string;
};

export const FreelancerOrEmployerDashboardLayout = ({
  children,
  ...rest
}: FreelancerOrEmployerDashboardLayoutProps) => {
  const user = useUser();

  if (!user) {
    throw Error("User data is missing");
  }

  const DashboardLayout = user.freelancer
    ? FreelancerDashboardLayout
    : EmployerDashboardLayout;

  return <DashboardLayout {...rest}>{children}</DashboardLayout>;
};
