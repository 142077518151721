import { Chip } from "@hexocean/braintrust-ui-components";
import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { EmployerInvoice } from "@js/types/invoices";

type StatusCellProps = GridRenderCellParams<
  EmployerInvoice,
  EmployerInvoice["status"]
>;

export const StatusCell = ({ value }: StatusCellProps) => {
  const statusLabel = value ? ENUMS.InvoiceStatusLabels[value] : value;
  return (
    <Chip
      label={<>{statusLabel}</>} // pass label as react element so title is not added to the chip
      size="small"
      sx={{
        p: "4px 12px !important",
        backgroundColor: `var(${getStatusChipBackgroundColor(value)}) !important`,
        maxWidth: "100%",
      }}
      LabelProps={{
        variant: "label",
        size: "small",
        color: "primary",
        ellipsis: true,
      }}
    />
  );
};

const getStatusChipBackgroundColor = (
  value: EmployerInvoice["status"] | undefined,
): string => {
  if (!value) {
    return "--grey-4";
  }

  switch (value) {
    case ENUMS.InvoiceStatus.WRITTEN_OFF:
    case ENUMS.InvoiceStatus.CANCELLED:
    case ENUMS.InvoiceStatus.NEED_PAYMENT_APPROVAL_FROM_FREELANCER:
    case ENUMS.InvoiceStatus.NEED_OFF_PLATFORM_PAYMENT_FOR_FREELANCER:
      return "--grey-4";
    case ENUMS.InvoiceStatus.OVERDUE:
      return "--medium-red";
    case ENUMS.InvoiceStatus.PAID:
      return "--medium-green";
    case ENUMS.InvoiceStatus.OPEN:
      return "--beige";
    case ENUMS.InvoiceStatus.PROCESSING_PAYMENT:
      return "--medium-teal";
    default:
      value satisfies never;
      return "beige";
  }
};
