import { Field as FormField } from "redux-form";
import { z } from "zod";

import {
  maxLength as maxLengthValidator,
  maxTextLength as maxTextLengthValidator,
  minLength as minLengthValidator,
  minTextLength as minTextLengthValidator,
} from "@js/forms/validators";

export const applyReduxFieldPatch = () => {
  const Field = FormField as typeof FormField & { render };
  const originalFieldRenderMethod = Field.render;

  const validatorsCache = {
    minLength: {},
    maxLength: {},
    minTextLength: {},
    maxTextLength: {},
  };

  Field.render = (props) => {
    const { minLength, maxLength, maxTextLength, minTextLength, validate } =
      props;
    const newProps = { ...props, validate: [...(validate || [])] };

    if (minLength) {
      if (!(minLength in validatorsCache.minLength)) {
        validatorsCache.minLength[minLength] = minLengthValidator(minLength);
      }

      newProps.validate.push(validatorsCache.minLength[minLength]);
    }

    if (maxLength) {
      if (!(maxLength in validatorsCache.maxLength)) {
        validatorsCache.maxLength[maxLength] = maxLengthValidator(maxLength);
      }

      newProps.validate.push(validatorsCache.maxLength[maxLength]);
    }

    if (maxTextLength) {
      if (!(maxTextLength in validatorsCache.maxTextLength)) {
        validatorsCache.maxTextLength[maxTextLength] =
          maxTextLengthValidator(maxTextLength);
      }

      newProps.validate.push(validatorsCache.maxTextLength[maxTextLength]);
    }

    if (minTextLength) {
      if (!(minTextLength in validatorsCache.minTextLength)) {
        validatorsCache.minTextLength[minTextLength] =
          minTextLengthValidator(minTextLength);
      }

      newProps.validate.push(validatorsCache.minTextLength[minTextLength]);
    }

    return originalFieldRenderMethod(newProps);
  };
};

type NestedError = {
  [key: string]: string | NestedError;
};

const REQUIRED_FIELDS_REGEX = /(add)|(select)|(required)/i;

const getNestedErrorMessage = (error: NestedError, depth = 0) => {
  if (depth > 10 || !error) return "";
  const current = error[Object.keys(error)[0]];
  if (typeof current === "string") {
    return current;
  }
  return getNestedErrorMessage(current, depth + 1);
};
export const getErrorsForRequiredFields = <
  FieldNames extends readonly string[],
>(
  errors: NestedError,
  requiredFields: FieldNames,
) => {
  return Object.keys(errors).reduce((current, key) => {
    const currentError: string =
      typeof errors[key] === "string"
        ? errors[key]
        : getNestedErrorMessage(errors[key] as NestedError);
    if (
      errors &&
      requiredFields.includes(key) &&
      currentError.match(REQUIRED_FIELDS_REGEX)
    ) {
      return { ...current, [key]: errors[key] };
    }

    return current;
  }, {}) as Record<
    FieldNames[number] | (string & Record<never, never>),
    // string & Record<never, never> lets us use string type to index into this object, and also keep autocompletion for the expected keys
    string
  >;
};

export const validateAttachments = (allValues: {
  attachments?: Array<{ isLoading?: boolean }> | "" | null;
}) => {
  if (!allValues?.attachments) {
    return;
  }

  const isAnyAttachmentUploading = allValues.attachments.some(
    (attachment) => !!attachment.isLoading,
  );

  return isAnyAttachmentUploading ? "Pending upload" : undefined;
};

const formSubmitErrorSchema = z.object({
  data: z
    .object({})
    .catchall(
      z.union([
        z.object({}).catchall(z.coerce.string()),
        z.array(z.string()).transform((val) => val.join(", ")),
        z.array(
          z.union([
            z.object({}).catchall(z.coerce.string()),
            z.coerce.string(),
          ]),
        ),
        z.coerce.string(),
      ]),
    ),
});

export const parseFormSubmitError = ({
  error,
  defaultGeneralFormError,
}: {
  error: unknown;
  defaultGeneralFormError?: string;
}) => {
  const parsedError = formSubmitErrorSchema.safeParse(error);

  if (parsedError.success) {
    return parsedError.data.data;
  }

  if (defaultGeneralFormError) {
    return { _error: defaultGeneralFormError };
  }

  return {};
};
