import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

import { Button, Slider } from "@hexocean/braintrust-ui-components";

const Cropper = ({
  onClose,
  onCrop,
  circularCrop = false,
  file,
  height = 350,
  width = 350,
  shouldRespectTransparency = true,
}) => {
  const editorRef = useRef<{ getImage: () => HTMLCanvasElement }>(null);
  const [scale, setScale] = useState(1);
  const transparencyValue = shouldRespectTransparency ? 0 : 1;

  const handleClickSave = async () => {
    if (!editorRef.current) {
      return;
    }
    const canvas = editorRef.current.getImage();

    if (!canvas) {
      return;
    }

    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    context.globalCompositeOperation = "destination-over";
    context.imageSmoothingQuality = "high";
    context.save();
    context.fillStyle = `rgba(255, 255, 255, ${transparencyValue})`;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();

    const newFile: Blob | null = await new Promise((resolve) => {
      canvas.toBlob(resolve, file.type);
    });
    if (!newFile) {
      return;
    }

    onCrop(new File([newFile], file.name));
  };

  const handleScale = (_e, newValue) => setScale(newValue);

  return (
    <div className="cropper">
      <AvatarEditor
        ref={editorRef}
        image={file}
        width={width}
        height={height}
        style={{
          maxWidth: width + 150,
          width: "100%",
          height: "100%",
        }}
        border={10}
        scale={scale}
        borderRadius={circularCrop ? 1000 : 0}
      />
      <Slider
        className="cropper__slider"
        color="secondary"
        min={0.3}
        max={3}
        step={0.001}
        value={scale}
        onChange={handleScale}
      />
      <div className="right mla">
        {onClose && (
          <Button variant="secondary" onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
        )}
        <Button variant="primary" onClick={handleClickSave}>
          Crop
        </Button>
      </div>
    </div>
  );
};

export default Cropper;
