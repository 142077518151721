import type { ReactNode } from "react";
import React, { createContext, useMemo } from "react";

import { useSelectedEmployerInvoices } from "../hooks/use-selected-employer-invoices";

export type EmployerInvoiceListingContextValue = {
  selectedInvoicesIds: number[];
  setSelectedInvoicesIds: React.Dispatch<React.SetStateAction<number[]>>;
  resetSelectedInvoicesIds: () => void;
};

export const EmployerInvoiceListingContext =
  createContext<EmployerInvoiceListingContextValue | null>(null);

export const EmployerInvoiceListingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    resetSelectedInvoicesIds,
  } = useSelectedEmployerInvoices();

  const contextValue = useMemo(() => {
    return {
      selectedInvoicesIds,
      setSelectedInvoicesIds,
      resetSelectedInvoicesIds,
    };
  }, [selectedInvoicesIds, setSelectedInvoicesIds, resetSelectedInvoicesIds]);

  return (
    <EmployerInvoiceListingContext.Provider value={contextValue}>
      {children}
    </EmployerInvoiceListingContext.Provider>
  );
};

export const useEmployerInvoiceListingContext = () => {
  const context = React.useContext(EmployerInvoiceListingContext);
  if (!context) {
    throw new Error(
      "useEmployerInvoiceListingContext must be used within a EmployerInvoiceListingContextProvider",
    );
  }
  return context;
};
