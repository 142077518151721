import { useState } from "react";

import { employerInvoicesCSVClicked } from "@js/apps/employer/actions";
import type { GetEmployerInvoicesParams } from "@js/apps/invoices/types";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { handleDownloadFile } from "@js/utils/download";

import type { EmployerInvoicesTableColumnField } from "../types";

import { ExportEmployerInvoicesModalCustomRange } from "./export-employer-invoices-modal-custom-range";
import { ExportEmployerInvoicesModalMainContent } from "./export-employer-invoices-modal-main-content";
import type {
  ExportCSVCustomDateRange,
  ExportCSVRangeOptionValue,
} from "./helpers";
import {
  EXPORT_CSV_RANGE_OPTION_VALUE,
  getDownloadInvoicesCSVParams,
  getExportCSVDateRangeOptions,
  getInitialCustomRange,
  getInitialSelectedOption,
} from "./helpers";

import styles from "./styles.module.scss";

export type ExportEmployerInvoicesModalContentProps = {
  filters: GetEmployerInvoicesParams;
  columns: EmployerInvoicesTableColumnField[];
};

export const ExportEmployerInvoicesModalContent = ({
  filters,
  columns,
}: ExportEmployerInvoicesModalContentProps) => {
  const dispatch = useAppDispatch();
  const employerProfile = useAppSelector(
    (state) => state.employer.employerProfile,
  );
  const employerCreatedAt = employerProfile?.created ?? null;
  const [isEditingCustomRange, setIsEditingCustomRange] = useState(false);
  const [options] = useState(() =>
    getExportCSVDateRangeOptions(employerCreatedAt),
  );
  const [customRange, setCustomRange] = useState(() =>
    getInitialCustomRange(filters),
  );
  const [selectedOption, setSelectedOption] =
    useState<ExportCSVRangeOptionValue>(() =>
      getInitialSelectedOption(customRange),
    );

  const handleChange = (value: ExportCSVRangeOptionValue) => {
    const shouldShowCustomRangeCalendars =
      value === EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE && !customRange;

    if (shouldShowCustomRangeCalendars) {
      return setIsEditingCustomRange(true);
    }

    setSelectedOption(value);
  };

  const handleEditCustomRangeClick = () => {
    setIsEditingCustomRange(true);
  };

  const handleEditCustomRange = (newCustomRange: ExportCSVCustomDateRange) => {
    setCustomRange(newCustomRange);
    setSelectedOption(EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE);
    setIsEditingCustomRange(false);
  };

  const handleEditCustomRangeCancel = () => {
    setIsEditingCustomRange(false);
  };

  const handleExportInvoicesClick = () => {
    const params = getDownloadInvoicesCSVParams({
      filters,
      columns,
      customRange,
      selectedOption,
      options,
    });

    if (!params) {
      Snackbar.error("Please select a date range.");
    }

    dispatch(employerInvoicesCSVClicked(selectedOption, customRange));

    handleDownloadFile({
      endpoint: "/api/employer_invoices/",
      params,
    });

    closeExportEmployerInvoicesModal();
  };

  if (isEditingCustomRange) {
    return (
      <ExportEmployerInvoicesModalCustomRange
        customRange={customRange}
        onCancel={handleEditCustomRangeCancel}
        onEditCustomRange={handleEditCustomRange}
      />
    );
  }

  return (
    <ExportEmployerInvoicesModalMainContent
      value={selectedOption}
      options={options}
      onChange={handleChange}
      customRange={customRange}
      onEditCustomRangeClick={handleEditCustomRangeClick}
      onCancelClick={closeExportEmployerInvoicesModal}
      onExportClick={handleExportInvoicesClick}
    />
  );
};

const closeExportEmployerInvoicesModal = () => {
  ModalInstance.close();
};

export const openExportEmployerInvoicesModal = (
  arg: ExportEmployerInvoicesModalContentProps,
) => {
  return ModalInstance.open({
    children: <ExportEmployerInvoicesModalContent {...arg} />,
    className: styles.modal,
    padding: false,
    closeButtonProps: { className: styles.closeButton, size: "medium" },
    closeButton: true,
  });
};
