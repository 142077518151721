import {
  Box,
  Button,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { OfferVisual } from "../../offer-visual";

type SuccessModalProps = {
  offer: HelpOffer;
};

const OfferAcceptedSuccessModalContent = ({ offer }: SuccessModalProps) => {
  const isMobile = useMediaQuery("sm");
  const { openMessengerModal } = useOpenMessengerModal();

  const helper = offer.author.user;
  const asker = offer.receiver.user;

  return (
    <div className="accept-offer-success-modal">
      <Typography
        component="label"
        variant="label"
        size={isMobile ? "medium" : "large"}
      >
        Offer activated
      </Typography>
      <OfferVisual exchangeFrom={asker} exchangeTo={helper} success />
      <Box>
        <Typography
          component="h2"
          variant={isMobile ? "paragraph" : "title"}
          size={isMobile ? "large" : "small"}
          fontWeight={400}
        >
          Success — Your offer is now active! Message {helper.first_name} to get
          started.
        </Typography>
        <Typography
          component="h2"
          variant={"paragraph"}
          size={isMobile ? "small" : "medium"}
          mt={0.5}
        >
          What’s next? Here are some tips:
        </Typography>
      </Box>
      <div className="accept-offer-success-modal__middle-section">
        <RowWithEmoji
          emoji="📝"
          text="Provide all the details upfront. It’ll help move things along faster."
        />
        <RowWithEmoji
          emoji="✅"
          text="Mark offers as complete when you have received the help to your satisfaction."
        />
        {Number(offer.budget) > 0 && <RefundPolicyPill />}
      </div>
      <div className="accept-offer-success-modal__btn">
        <Button
          variant="positive-2"
          shape="squared"
          onClick={() => {
            ModalInstance.close();
            openMessengerModal({
              context: {
                participants: [offer.receiver.user.id, offer.author.user.id],
              },
            });
          }}
        >
          Message {helper.first_name}
        </Button>
      </div>
    </div>
  );
};

type RowWithEmojiProps = {
  emoji: string;
  text: string;
};

const RowWithEmoji = ({ emoji, text }: RowWithEmojiProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <div className="row-with-emoji">
      <span>
        <div className="row-with-emoji__circle">
          <div className="row-with-emoji__emoji">{emoji}</div>
        </div>
      </span>
      <Typography
        component="label"
        variant="paragraph"
        size={isMobile ? "medium" : "large"}
      >
        {text}
      </Typography>
    </div>
  );
};

const RefundPolicyPill = () => {
  return (
    <div className="accept-offer-success-modal__check-policy-pill">
      <Tooltip
        title={
          <>
            <Typography component="span" variant="paragraph">
              You can request a refund if the help you receive doesn’t meet your
              expectations. View our
            </Typography>{" "}
            <Typography
              component="link"
              variant="paragraph"
              color="inherit"
              to={SETTINGS.PROFESSIONAL_NETWORK_TERMS_DOC_URL}
              rel="noopener noreferrer"
              RouterLink={RouterLink}
            >
              Refund Policy
            </Typography>
          </>
        }
      >
        <InfoIcon
          style={{
            width: 20,
            color: "inherit",
          }}
        />
      </Tooltip>

      <Typography component="p" variant="paragraph" size="small">
        Aren't satisfied? Check our{" "}
        <a
          href={SETTINGS.PROFESSIONAL_NETWORK_TERMS_DOC_URL}
          className="accept-offer-success-modal__link"
          target="_blank"
          rel="noreferrer"
        >
          Refund Policy
        </a>
      </Typography>
    </div>
  );
};

export const openOfferAcceptedSuccessModal = (offer: HelpOffer) =>
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    children: <OfferAcceptedSuccessModalContent offer={offer} />,
  });
