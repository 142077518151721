import { Typography } from "@hexocean/braintrust-ui-components";
import { type GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import { RouterLink } from "@js/components/link";
import type { EmployerMyTalent } from "@js/types/employer";

type EmailCellProps = GridRenderCellParams<
  EmployerMyTalent,
  EmployerMyTalent["email"]
>;

export const EmailCell = ({ value }: EmailCellProps) => {
  if (!value) {
    return <>N/A</>;
  }

  return (
    <Typography
      component="link"
      RouterLink={RouterLink}
      href={`mailto:${value}`}
      rel="noreferrer"
      variant="paragraph"
      size="medium"
      ellipsis
      className="underline-on-hover"
    >
      {value}
    </Typography>
  );
};
