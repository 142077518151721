import { useEffect } from "react";
import { Field, reduxForm } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { TextareaField } from "@js/forms/fields";

import { jobOverviewLengthValidator } from "./validator";

const AI_JOB_OVERVIEW = "aiJobOverview";
const placeholderText = `I am seeking a part-time Senior Product Manager residing in Canada with significant expertise in SaaS applications.

- $100/hr
- Remote
- 6 month contract
- Mid-level experience`;

export type JobOverviewCustomProps = {
  setFormIsLoading: (loading: boolean) => void;
};

export type JobOverviewFormData = {
  job_overview: string;
};

export const JobOverviewForm = reduxForm<
  JobOverviewFormData,
  JobOverviewCustomProps
>({
  form: AI_JOB_OVERVIEW,
})(({ error, submit, submitting, setFormIsLoading }) => {
  useEffect(() => setFormIsLoading(submitting), [setFormIsLoading, submitting]);

  return (
    <Form onSubmit={submitting ? null : submit}>
      <Box display="flex" flexDirection="column" gap="16px">
        <Field
          variant="standard"
          placeholder={placeholderText}
          inputProps={{
            "aria-label": `job overview`,
          }}
          InputProps={{
            componentsProps: {
              root: {
                style: {
                  padding: "5px 0",
                  borderRadius: "20px 20px 0 0",
                },
              },
              input: {
                style: {
                  resize: "none",
                },
              },
            },
          }}
          id="job-overview"
          name="job_overview"
          rows={9}
          maxLengthInHelpText
          maxLength={500}
          component={TextareaField}
          className="job-overview-textarea"
          style={{
            border: `1px solid var(${error ? "--negative-2" : "--grey-3"})`,
            backgroundColor: "white",
            borderRadius: "20px",
          }}
          validate={[jobOverviewLengthValidator]}
        />
        {!!error && (
          <Typography component="p" size="small" fontWeight={500} error>
            {error}
          </Typography>
        )}
        <Button
          variant="dark-violet"
          shape="squared"
          disabled={submitting}
          onClick={submit}
          startIcon={
            <img
              alt="job icon"
              src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon.svg`}
              style={{ filter: "brightness(0) invert(1)" }}
              width="26"
              height="26"
            />
          }
          sx={{
            whiteSpace: "nowrap",
            width: "fit-content",
            backgroundColor: "#5D1974",
          }}
        >
          Generate with AI
        </Button>
      </Box>
    </Form>
  );
});
