import type { FormSubmitHandler } from "redux-form";
import { resetSection, SubmissionError } from "redux-form";

import { useUser } from "@js/apps/common/hooks";
import { fetchEmployerPublicProfile } from "@js/apps/employer/actions";
import {
  useGetEmployerInfoQuery,
  useUpdateEmployerInfoMutation,
} from "@js/apps/employer/api";
import type { OrganizationInfoFormData } from "@js/apps/employer/forms/organization-info";
import { prepareOrganizationInfoValues } from "@js/apps/onboarding/utils";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

type UseEmployerOrganizationInfoConfig = {
  avatarUpdatedFieldName?: string;
};

export const useEmployerOrganizationInfo = (
  config?: UseEmployerOrganizationInfoConfig,
) => {
  const user = useUser();
  const dispatch = useAppDispatch();

  const { data: employerData, isLoading } = useGetEmployerInfoQuery(
    { employerId: user?.employer as number },
    { skip: user === null || !user?.employer },
  );

  const initialValues = {
    logo: employerData?.logo,
    organization_size: employerData?.organization_size,
    name: employerData?.name,
    industry_id: employerData?.industry?.id,
    google_full_location: employerData?.location,
    overview: employerData?.overview,
    website: employerData?.website,
  };

  const [updateEmployerInfo] = useUpdateEmployerInfoMutation();

  const onSubmit: FormSubmitHandler<OrganizationInfoFormData> = async (
    values,
    _dispatch,
    props,
  ) => {
    if (!employerData) return;

    const preparedValues = prepareOrganizationInfoValues(values);

    try {
      await updateEmployerInfo({
        employerId: employerData.id,
        newData: preparedValues,
      }).unwrap();

      if (props.form && config?.avatarUpdatedFieldName) {
        dispatch(resetSection(props.form, config?.avatarUpdatedFieldName));
      }
    } catch (e: any) {
      throw new SubmissionError(e.data);
    }
  };

  const onSubmitSuccess = () => {
    if (employerData) {
      dispatch(fetchEmployerPublicProfile(employerData.id));
    }

    Snackbar.success("Saved successfully!");
  };

  return {
    onSubmit,
    onSubmitSuccess,
    initialValues,
    isLoading,
  };
};
