import { Stack, Typography } from "@hexocean/braintrust-ui-components";
import type { CustomizeColumnsButtonProps } from "@js/apps/common/components/customize-columns-button";
import { CustomizeColumnsButton } from "@js/apps/common/components/customize-columns-button";
import { pluralize } from "@js/utils/string";

import type { EmployerInvoicesActionsProps } from "../employer-invoices-actions";
import { EmployerInvoicesActions } from "../employer-invoices-actions";
import type { EmployerInvoicesTableColumnField } from "../types";

type CustomiseEmployerInvoicesProps =
  CustomizeColumnsButtonProps<EmployerInvoicesTableColumnField>;

type EmployerInvoicesTableTopSectionProps = InvoicesCountProps &
  EmployerInvoicesActionsProps & {
    isLoading: boolean;
  } & CustomiseEmployerInvoicesProps;

export const EmployerInvoicesTableTopSection = ({
  invoicesCount,
  totalInvoicesCount,
  isLoading,
  selectedInvoicesIds,
  startPaymentFlow,
  columnsOptions,
  onColumnsChange,
  onColumnsMenuClose,
  selectedColumns,
  filters,
}: EmployerInvoicesTableTopSectionProps) => {
  const showSelectedInvoicesCount = !!selectedInvoicesIds.length;
  const showDisplayedInvoicesCount = !showSelectedInvoicesCount && !isLoading;

  const showCustomizeColumnsButton = !isLoading && !showSelectedInvoicesCount;

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      sx={{
        gap: 2,
        flexWrap: "wrap",
        alignItems: { xs: "start", sm: "center" },
        minHeight: "42px",
        justifyContent: "space-between",
        mb: { xs: 2, sm: 1 },
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="start"
        sx={{ gap: 2 }}
      >
        {showDisplayedInvoicesCount && (
          <InvoicesCount
            invoicesCount={invoicesCount}
            totalInvoicesCount={totalInvoicesCount}
          />
        )}
        {showSelectedInvoicesCount && (
          <SelectedInvoicesCount
            selectedInvoicesCount={selectedInvoicesIds.length}
          />
        )}
        {showCustomizeColumnsButton && (
          <CustomizeColumnsButton
            selectedColumns={selectedColumns}
            columnsOptions={columnsOptions}
            onColumnsChange={onColumnsChange}
            onColumnsMenuClose={onColumnsMenuClose}
          />
        )}
      </Stack>
      <EmployerInvoicesActions
        selectedInvoicesIds={selectedInvoicesIds}
        startPaymentFlow={startPaymentFlow}
        totalInvoicesCount={totalInvoicesCount}
        filters={filters}
        selectedColumns={selectedColumns}
      />
    </Stack>
  );
};

type InvoicesCountProps = {
  invoicesCount: number;
  totalInvoicesCount: number;
};

const InvoicesCount = ({
  invoicesCount,
  totalInvoicesCount,
}: InvoicesCountProps) => {
  return (
    <Typography
      component="p"
      variant="paragraph"
      size="medium"
      sx={{ minWidth: "180px" }}
      color="grey-1"
    >
      Showing{" "}
      <strong>
        {invoicesCount} of {totalInvoicesCount}
      </strong>{" "}
      invoice{pluralize(totalInvoicesCount, { zeroPlural: true })}
    </Typography>
  );
};

type SelectedInvoicesCountProps = {
  selectedInvoicesCount: number;
};

const SelectedInvoicesCount = ({
  selectedInvoicesCount,
}: SelectedInvoicesCountProps) => {
  return (
    <Typography component="p" variant="paragraph" size="medium" color="grey-1">
      {selectedInvoicesCount} invoice
      {pluralize(selectedInvoicesCount, { zeroPlural: true })} selected
    </Typography>
  );
};
