import type {
  AddedEventData,
  BatchedPostOrCommentEvent,
  DeletedPostCommentData,
  GetPostsParams,
  GetPostsReturnPayload,
  UpdatedEventData,
  VoteAddedEventData,
} from "@js/apps/give-and-get-help/types";
import type { PostsEventPayload } from "@js/apps/give-and-get-help/types";
import type { SpaceAddedPostEvent } from "@js/apps/spaces/types";
import type { User } from "@js/types/auth";
import type {
  IPost,
  PostComment,
  ReactionAddedEventData,
  ReactionDeletedEventData,
  StickerValue,
} from "@js/types/give-and-get-help";
import { normalizePaginatedData, typeGuard } from "@js/utils";
import { findEntity, findEntityIndex } from "@js/utils";

import {
  addReaction,
  deleteReaction,
  getAddReactionPayload,
  getDeleteReactionPayload,
  includesSelectedHashtag,
} from "../../common";
import {
  addCommentReaction,
  deleteCommentReaction,
  toggleUserCommentReaction,
  updateComment,
} from "../comments-update-recipe";

export type Draft = GetPostsReturnPayload | IPost[];

export const patchPostsOnPostAdded = ({
  addedPost,
  draft,
  args,
}: {
  addedPost: Optional<IPost, "comments">;
  draft: Draft;
  args: GetPostsParams;
}) => {
  if (!typeGuard<Draft, GetPostsReturnPayload>(draft, "results")) {
    return;
  }

  const isPostIncludesSelectedHashtag = includesSelectedHashtag(
    args.hashtag,
    addedPost.post_hashtags,
  );
  if (args.hashtag && !isPostIncludesSelectedHashtag) {
    return;
  }

  const normalizedAddedPostSpace = addedPost.space ?? undefined;
  const isSameSpace = normalizedAddedPostSpace === args.space;
  if (!isSameSpace) {
    // add only to the results from the same space
    return;
  }

  const postExists = !!findEntity(draft.results, addedPost.id);
  if (postExists) {
    return;
  }

  draft.count += 1;
  draft.results.unshift({ comments: [], ...addedPost });
  updatePostSlotPosition(draft);
};

export const patchPostsOnPostDeleted = (postId: number, draft: Draft) => {
  if (typeGuard<Draft, GetPostsReturnPayload>(draft, "results")) {
    const postIndex = findEntityIndex(draft.results, postId);
    if (postIndex === -1) return;

    draft.count -= 1;
    draft.results.splice(postIndex, 1);
    updatePostSlotPosition(draft);
  }
};

export const patchPostsOnPostUpdate = (
  updatedPost: IPost,
  draft: GetPostsReturnPayload,
  getPostsArg: GetPostsParams,
) => {
  updatePostList(updatedPost, draft, getPostsArg);
  updatePost({ data: updatedPost }, draft);
};

export const updatePostList = (
  updatedPost: IPost,
  draft: GetPostsReturnPayload,
  getPostsArg: GetPostsParams,
) => {
  const spaceParam = getPostsArg.space || null;
  const postSpaceId = updatedPost.space || null;
  const hashtagParam = getPostsArg.hashtag || null;
  // change undefined to null so it can be compared with updatedPost payload

  if (spaceParam !== postSpaceId) return;

  const postIndex = findEntityIndex(draft.results, updatedPost.id);
  const postOnList = postIndex !== -1;

  const isHashtagParam = !!hashtagParam;

  const isNewDataContainingHashtag = updatedPost.post_hashtags.some(
    ({ hashtag_id }) => hashtag_id === hashtagParam,
  );

  if (isHashtagParam && !postOnList && isNewDataContainingHashtag) {
    draft.count += 1;
    draft.results.unshift({
      ...updatedPost,
      comments: updatedPost.comments || [],
    });
    updatePostSlotPosition(draft);
    return;
  }
  const isOldDataContainingHashtag = postOnList
    ? draft.results[postIndex].post_hashtags.some(
        ({ hashtag_id }) => hashtag_id === hashtagParam,
      )
    : false;

  if (
    isHashtagParam &&
    !isNewDataContainingHashtag &&
    isOldDataContainingHashtag
  ) {
    draft.count -= 1;
    draft.results.splice(postIndex, 1);
    updatePostSlotPosition(draft);
    return;
  }
};

const getAffectedPostComment = ({
  draft,
  commentId,
}: {
  draft: Draft;
  commentId: number;
}) => {
  const normalizedDraft = normalizePaginatedData(draft);

  return normalizedDraft.find((post) =>
    post.comments.some(
      (comment) =>
        comment.id === commentId ||
        comment.comments?.some(
          (nestedComment) => nestedComment.id === commentId,
        ),
    ),
  );
};

export const updatePost = (
  eventData: Optional<
    UpdatedEventData<IPost>,
    "is_main_post" | "broadcast_type"
  >,
  draft: Draft,
) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const postIndex = findEntityIndex(normalizedDraft, eventData.data.id);

  normalizedDraft[postIndex] = {
    ...normalizedDraft[postIndex],
    ...eventData.data,
  };
};

export const updatePostComment = (
  eventData: UpdatedEventData<PostComment>,
  draft: Draft,
) => {
  const postWithUpdatedComment = getAffectedPostComment({
    draft,
    commentId: eventData.data.id,
  });
  if (!postWithUpdatedComment) {
    return;
  }

  updateComment(eventData, postWithUpdatedComment, postWithUpdatedComment.id);
};

export const updatePostOnCommentAdd = (
  eventData: AddedEventData<PostComment>,
  draft: Draft,
) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const postWithAddedComment = findEntity(
    normalizedDraft,
    eventData.data.main_post_id,
  );

  if (!postWithAddedComment) {
    return;
  }

  const addedComment = eventData.data;
  const commentExists = getCommentExists({
    post: postWithAddedComment,
    comment: addedComment,
  });

  if (commentExists) {
    return;
  }

  const preloadedCommentsCount =
    getPreloadedCommentsCount(postWithAddedComment);

  const MAX_PRELOADED_COMMENTS_COUNT = 3;
  const shouldAddPreloadedComment =
    preloadedCommentsCount < MAX_PRELOADED_COMMENTS_COUNT;

  if (!shouldAddPreloadedComment) {
    postWithAddedComment.comments_count =
      addedComment.main_post_comments_count ??
      postWithAddedComment.comments_count + 1;

    return;
  }

  patchPostOnCommentAdded({
    addedComment,
    post: postWithAddedComment,
  });
};

export const addPostReaction = (
  eventData: Optional<
    ReactionAddedEventData,
    "is_main_post" | "broadcast_type" | "type"
  >,
  draft: Draft,
) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const post = findEntity(normalizedDraft, eventData.data.post_id);

  if (!post) return;

  post.reactions = addReaction(post.reactions, eventData.data);
};

export const addPostCommentReaction = (
  eventData: ReactionAddedEventData,
  draft: Draft,
) => {
  const postWithReactedComment = getAffectedPostComment({
    draft,
    commentId: eventData.data.post_id,
  });
  if (!postWithReactedComment) {
    return;
  }

  addCommentReaction(eventData, postWithReactedComment);
};

export const deletePostReaction = (
  eventData: Optional<
    ReactionDeletedEventData,
    "is_main_post" | "broadcast_type" | "type"
  >,
  draft: Draft,
) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const post = findEntity(normalizedDraft, eventData.data.post_id);

  if (!post) return;

  post.reactions = deleteReaction(post.reactions, eventData.data);
};

export const deletePostCommentReaction = (
  eventData: ReactionDeletedEventData,
  draft: Draft,
) => {
  const postWithReactedComment = getAffectedPostComment({
    draft,
    commentId: eventData.data.post_id,
  });
  if (!postWithReactedComment) {
    return;
  }

  deleteCommentReaction(eventData, postWithReactedComment);
};

export const addVote = (eventData: VoteAddedEventData, draft: Draft) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const post = findEntity(normalizedDraft, eventData.data.post_id);

  if (!post) return;

  post.poll_votes_count = (post.poll_votes_count ?? 0) + 1;

  const votedOption = findEntity(
    post.poll_options,
    eventData.data.poll_option_id,
  );

  if (!votedOption) return;

  votedOption.votes_count = (votedOption.votes_count ?? 0) + 1;
};

export const isPostRelatedEvent = (
  event: MessageEvent,
): event is MessageEvent<PostsEventPayload> => {
  return event.data.is_main_post === true;
};

export const isSpacePostAddedRelatedEvent = (
  event: MessageEvent,
): event is MessageEvent<SpaceAddedPostEvent> => {
  return event.data.broadcast_type === ENUMS.BroadcastType.SPACE_POST_ADDED;
};

export const isPostOrCommentsRelatedBatchedEvents = (
  event: MessageEvent,
): event is MessageEvent<BatchedPostOrCommentEvent> => {
  return (
    event.data.broadcast_type ===
    ENUMS.BroadcastType.BATCHED_POST_OR_COMMENT_EVENTS
  );
};

export const updatePostSlotPosition = <T extends Draft>(draft: T) => {
  const normalizedDraft = normalizePaginatedData(draft);

  normalizedDraft.forEach((post, idx) => {
    post.slot_position = idx;
  });
};

export const addPostSlotPosition = (post: IPost, idx: number) => {
  return { ...post, slot_position: idx };
};

export const bookmarkPost = (
  eventData: { id: number; post: number },
  draft: Draft,
) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const post = Array.isArray(normalizedDraft)
    ? findEntity(normalizedDraft, eventData.post)
    : normalizedDraft;

  if (!post) return;

  post.saved_post_id = eventData.id;
  post.saved_posts_count++;
};

export const unbookmarkPost = (postId: number, draft: Draft) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const post = Array.isArray(normalizedDraft)
    ? findEntity(normalizedDraft, postId)
    : normalizedDraft;

  if (!post) return;

  post.saved_post_id = null;
  post.saved_posts_count--;
};

const togglePostReaction = ({
  currentUser,
  post,
  sticker,
  postId,
}: {
  currentUser: User;
  post: IPost;
  postId: number;
  sticker: StickerValue;
}) => {
  const currentReaction = post.reactions.find(
    (reaction) => reaction.sticker === sticker,
  );
  const hasCurrentUserReacted = !!currentReaction?.users.some(
    (user) => user.id === currentUser.id,
  );
  if (!currentReaction || !hasCurrentUserReacted) {
    post.reactions = addReaction(
      post.reactions,
      getAddReactionPayload({ sticker, postId: post.id, currentUser }),
    );

    return;
  }

  post.reactions = deleteReaction(
    post.reactions,
    getDeleteReactionPayload({ currentReaction, currentUser, postId, sticker }),
  );
};

export const patchPostOnReactionToggle = ({
  user: currentUser,
  post,
  sticker,
  postId,
}: {
  user: User;
  post: IPost;
  postId: number;
  sticker: StickerValue;
}) => {
  if (post.id !== postId) {
    toggleUserCommentReaction({
      user: currentUser,
      draft: post,
      postId,
      sticker,
    });
    return;
  }

  togglePostReaction({
    currentUser,
    post,
    sticker,
    postId,
  });
};

export const patchPostsOnReactionToggle = ({
  user: currentUser,
  draft,
  postId,
  sticker,
}: {
  user: User;
  draft: Draft;
  postId: number;
  sticker: StickerValue;
}) => {
  const normalizedDraft = normalizePaginatedData(draft);
  const currentPost = normalizedDraft.find((post) => post.id === postId);
  if (!currentPost) {
    const postWithReactionToComment = normalizedDraft.find((post) =>
      post.comments.some(
        (comment) =>
          comment.id === postId ||
          comment.comments?.some(
            (childrenComment) => childrenComment.id === postId,
          ),
      ),
    );
    if (postWithReactionToComment) {
      toggleUserCommentReaction({
        user: currentUser,
        draft: postWithReactionToComment,
        postId,
        sticker,
      });
    }
    return;
  }

  togglePostReaction({
    currentUser,
    post: currentPost,
    sticker,
    postId,
  });
};

export const patchPostsOnCommentAdded = ({
  addedComment,
  draft,
}: {
  addedComment: PostComment;
  draft: Draft;
}) => {
  const normalizedDraft = normalizePaginatedData(draft);
  const post = normalizedDraft.find((p) => p.id === addedComment.main_post_id);
  if (!post) {
    return;
  }

  patchPostOnCommentAdded({ addedComment, post });
};

export const patchPostOnCommentAdded = ({
  addedComment,
  post,
}: {
  addedComment: PostComment;
  post: IPost;
}) => {
  const commentExists = getCommentExists({ post, comment: addedComment });
  if (commentExists) {
    return;
  }

  post.comments_count =
    addedComment.main_post_comments_count ?? post.comments_count + 1;

  const isLvl1Comment = addedComment.parent_id === post.id;
  if (isLvl1Comment) {
    post.comments.push(addedComment);

    return;
  }

  const repliedToComment = post.comments.find(
    (postComment) => postComment.id === addedComment.parent_id,
  );
  if (!repliedToComment) {
    return;
  }

  const currentCommentRepliesCount = repliedToComment.comments_count ?? 0;
  repliedToComment.comments_count = currentCommentRepliesCount + 1;
  repliedToComment.comments ??= [];
  repliedToComment.comments.push(addedComment);
};

export const patchPostsOnCommentDeleted = ({
  deletedComment,
  draft,
}: {
  deletedComment: DeletedPostCommentData;
  draft: Draft;
}) => {
  const normalizedDraft = normalizePaginatedData(draft);
  const post = normalizedDraft.find(
    (p) => p.id === deletedComment.main_post_id,
  );
  if (!post) {
    return;
  }

  patchPostOnCommentDeleted({ deletedComment, post });
};

export const patchPostOnCommentDeleted = ({
  deletedComment,
  post,
}: {
  deletedComment: DeletedPostCommentData;
  post: IPost;
}) => {
  post.comments_count =
    deletedComment.main_post_comments_count ??
    post.comments_count - deletedComment.deleted_posts_count;

  post.comments = post.comments.filter(
    (postComment) => postComment.id !== deletedComment.id,
  );

  const deletedFromComment = post.comments.find((postComment) =>
    postComment.comments?.some((comment) => comment.id === deletedComment.id),
  );
  if (!deletedFromComment || !deletedFromComment?.comments) {
    return;
  }

  deletedFromComment.comments = deletedFromComment.comments.filter(
    (postComment) => postComment.id !== deletedComment.id,
  );

  const currentCommentRepliesCount = deletedFromComment.comments_count ?? 0;
  deletedFromComment.comments_count = currentCommentRepliesCount
    ? currentCommentRepliesCount - 1
    : 0;
};

export const patchPostsOnCommentUpdated = ({
  updatedComment,
  draft,
}: {
  updatedComment: PostComment;
  draft: Draft;
}) => {
  const normalizedDraft = normalizePaginatedData(draft);
  const post = normalizedDraft.find(
    (p) => p.id === updatedComment.main_post_id,
  );
  if (!post) {
    return;
  }

  updateComment({ data: updatedComment }, post, updatedComment.main_post_id);
};

const getCommentExists = ({
  comment,
  post,
}: {
  comment: PostComment;
  post: IPost;
}) => {
  const isLvl1Comment = comment.parent_id === post.id;
  const commentExists = post.comments.some((postComment) => {
    if (isLvl1Comment) {
      return postComment.id === comment.id;
    }

    return postComment?.comments?.some((reply) => reply.id === comment.id);
  });

  return commentExists;
};

const getPreloadedCommentsCount = (post: IPost) => {
  const preloadedCommentsCount = post.comments.reduce(
    (accumulatedCount, currentComment) => {
      const preloadedRepliesCount = currentComment.comments?.length ?? 0;

      return accumulatedCount + preloadedRepliesCount;
    },
    post.comments.length,
  );

  return preloadedCommentsCount;
};

export const patchPostsOnUserDeleted = ({
  draft,
  userId,
}: {
  draft: GetPostsReturnPayload;
  userId: number;
}) => {
  draft.results = draft.results.filter(
    (post) => post.freelancer.user.id !== userId,
  );
  updatePostSlotPosition(draft);
};

export const patchPostsOnVote = ({
  draft,
  optionId,
  postId,
}: {
  draft: Draft;
  optionId: number;
  postId: number;
}) => {
  const normalizedDraft = normalizePaginatedData(draft);
  const votedPost = normalizedDraft.find((post) => post.id === postId);
  if (!votedPost) {
    return;
  }

  patchPostOnVote({ optionId, draft: votedPost });
};

export const patchPostOnVote = ({
  draft,
  optionId,
}: {
  draft: IPost;
  optionId: number;
}) => {
  draft.voted = true;
  draft.poll_votes_count = (draft.poll_votes_count ?? 0) + 1;
  const selectedOption = draft.poll_options.find(
    (option) => option.id === optionId,
  );
  if (!selectedOption) {
    return;
  }

  selectedOption.user_voted = true;
  selectedOption.votes_count = (selectedOption.votes_count ?? 0) + 1;
};
