import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Stack } from "@hexocean/braintrust-ui-components";

export const ExportEmployerInvoicesModalContentWrapper = ({
  children,
  ...rest
}: StackProps) => {
  return (
    <Stack {...rest} sx={{ p: 3, ...rest?.sx }}>
      {children}
    </Stack>
  );
};
