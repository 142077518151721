import React, { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";
import { Field, reduxForm } from "redux-form";

import {
  ComboBoxMultiple,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { useGetJobMinimalSearchQuery } from "@js/apps/jobs/api";
import { useSearchPhrase } from "@js/components/autocomplete-new/hooks/search-phrase";
import { CommonConfirmationModal, ModalConfirm } from "@js/components/modal";
import { Form } from "@js/forms/components/form";
import type { Freelancer } from "@js/types/freelancer";
import type { JobMinimalSearchResult } from "@js/types/jobs";
import type { Job } from "@js/types/jobs";

type InviteTalentToBidFormProps = {
  onCancel: () => void;
};

type InviteTalentToBidFormValues = {
  jobs: Job[];
  freelancer: Freelancer["id"];
};

export const InviteTalentToBidForm = reduxForm<
  InviteTalentToBidFormValues,
  InviteTalentToBidFormProps
>({
  form: "invite-to-bid",
})(({ onCancel, submitting, submit, error, initialValues }) => {
  return (
    <Form onSubmit={submitting ? submit : null} error={error}>
      <ModalConfirm
        onCancel={onCancel}
        confirmText="Invite Talent"
        onConfirm={() => {
          CommonConfirmationModal.open({
            onClose: CommonConfirmationModal.close,
            children: (
              <ModalConfirm
                onCancel={CommonConfirmationModal.close}
                confirmText="Invite Talent"
                onConfirm={() => {
                  submit();
                  CommonConfirmationModal.close();
                }}
              >
                Are you sure you want to invite talent to specified jobs?
              </ModalConfirm>
            ),
          });
        }}
      >
        <Field
          name="jobs"
          component={OpenJobsField}
          freelancer={Number(initialValues.freelancer)}
        />
      </ModalConfirm>
    </Form>
  );
});

type OpenJobsFieldProps = WrappedFieldProps & { freelancer: number };

const OpenJobsField = ({ input, meta, freelancer }: OpenJobsFieldProps) => {
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: options } = useGetJobMinimalSearchQuery({
    search: searchPhrase,
    open: true,
    annotate_invited_by_client_or_matcher: true,
    freelancer: freelancer,
    ops_invitations: true,
  });

  const filteredOptions = useMemo(() => {
    const opt = (options || []).filter(
      (option) => !option.freelancer_already_invited_to_this_job,
    );
    return opt;
  }, [options]);

  return (
    <ComboBoxMultiple<JobMinimalSearchResult, false>
      initialTaxonomiesLoading={false}
      onInputChange={onInputChange}
      options={filteredOptions}
      displayAllOptions
      getOptionLabel={(option) => `${option?.title} (${option?.employer_name})`}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            key={option.id}
          >{`${option?.title} (${option?.employer_name})`}</li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Open Jobs"
            error={!!meta.error}
            helperText={meta.error}
          />
        );
      }}
      onChange={(_ew, value) => {
        input.onChange(value);
      }}
    />
  );
};
