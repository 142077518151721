import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Button,
  Loader,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { DownloadArrowIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useGetFreelancerInvoicesQuery } from "@js/apps/invoices/api";
import {
  Section,
  SectionSubtitleAlignCenterAction,
} from "@js/apps/settings/forms/helpers";

interface PdfFile {
  extension: string;
  file: string;
  file_download_url: string;
  id: string;
  mime: string;
  name: string;
}

interface Invoice {
  id: number;
  created: string;
  subject_title: string;
  pdf_file: PdfFile;
}

const BillingSection: React.FC = () => {
  const isMobile = useMediaQuery("sm");
  const [invoicesParams, setInvoicesParams] = useState({ page: 1 });
  const [invoicesData, setInvoicesData] = useState<Invoice[]>([]);

  const { data: invoices, isLoading: isLoadingInvoice } =
    useGetFreelancerInvoicesQuery(invoicesParams);

  useEffect(() => {
    if (invoices) {
      const data = [...invoices.results];
      setInvoicesData([...invoicesData, ...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  const invoiceButton = (pdf_file: PdfFile) => {
    const downloadFile = () => {
      const link = document.createElement("a");
      link.href = pdf_file?.file_download_url;
      link.download = pdf_file?.name; // Optional: specify a default filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    return (
      <Button
        onClick={downloadFile}
        variant="secondary"
        shape="squared"
        sx={{ minWidth: "18px !important" }}
        {...(!isMobile && {
          startIcon: <DownloadArrowIcon width={18} fontWeight={600} />,
        })}
      >
        {isMobile ? (
          <DownloadArrowIcon width={18} fontWeight={600} />
        ) : (
          "Invoice"
        )}
      </Button>
    );
  };

  return (
    <>
      <Section title="Billing history">
        {invoicesData?.length ? (
          invoicesData?.map((item: Invoice) => (
            <Box key={item?.id}>
              <SectionSubtitleAlignCenterAction
                title={item?.subject_title}
                description={dayjs(item?.created).format("MMMM D, YYYY")}
                action={<Box>{invoiceButton(item?.pdf_file)}</Box>}
                divider
              />
            </Box>
          ))
        ) : (
          <Box>
            <Typography
              variant="label"
              size="large"
              component="h4"
              fontWeight={500}
            >
              You have no billing history
            </Typography>
            <Typography
              variant="label"
              size="medium"
              component="h4"
              fontWeight={400}
            >
              Once you purchase an upgrade, they will appear here.
            </Typography>
          </Box>
        )}

        {isLoadingInvoice && (
          <Box display={"flex"} justifyContent={"center"}>
            <Loader />
          </Box>
        )}
        {invoicesData.length < invoices?.count && (
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              onClick={() =>
                setInvoicesParams({
                  ...invoicesParams,
                  page: invoicesParams.page + 1,
                })
              }
              variant="secondary"
              shape="squared"
            >
              Load more
            </Button>
          </Box>
        )}
      </Section>
    </>
  );
};

export default BillingSection;
