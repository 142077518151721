import type { KeyboardEvent } from "react";
import React, { useEffect, useState } from "react";

import type { TooltipProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  ButtonCore,
  IconButton,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseRoundedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openAvailabilityModal } from "@js/apps/available-for-work/components/availability-modal";
import { InteractiveTag } from "@js/components/interactive-text";

import style from "./style.module.scss";

type CoachMarkTooltipProps = {
  children: JSX.Element;
  showAvailableForWorkCoachMark: boolean;
  showWorkingHoursCoachMark: boolean;
  handleAvailableForWorkCoachMarkClose: () => void;
  handleWorkingHoursCoachMarkClose: () => void;
  placement?: TooltipProps["placement"];
};

const CONTENT_TYPE = {
  AVAILABILITY: "AVAILABILITY",
  WORKING_HOURS: "WORKING_HOURS",
} as const;

type CoachMarkContentType = ObjectValues<typeof CONTENT_TYPE>;

export const CoachMarkTooltip = ({
  children,
  handleAvailableForWorkCoachMarkClose,
  handleWorkingHoursCoachMarkClose,
  showWorkingHoursCoachMark,
  showAvailableForWorkCoachMark,
  placement,
}: CoachMarkTooltipProps) => {
  const [contentType, setContentType] = useState<CoachMarkContentType>();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (showAvailableForWorkCoachMark) {
      setContentType(CONTENT_TYPE.AVAILABILITY);
    } else if (showWorkingHoursCoachMark) {
      setContentType(CONTENT_TYPE.WORKING_HOURS);
    }

    setIsOpen(showAvailableForWorkCoachMark || showWorkingHoursCoachMark);
  }, [showAvailableForWorkCoachMark, showWorkingHoursCoachMark]);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleAnchorClick = () => {
    if (!isOpen) {
      return;
    }

    handleTooltipClose();
  };

  const handleAnchorKeyDown = (ev: KeyboardEvent<HTMLElement>) => {
    if (!isOpen || ev.key !== "Enter") {
      return;
    }

    handleTooltipClose();
  };

  const handleExited = () => {
    if (
      showAvailableForWorkCoachMark &&
      contentType === CONTENT_TYPE.AVAILABILITY
    ) {
      return handleAvailableForWorkCoachMarkClose();
    }

    if (
      showWorkingHoursCoachMark &&
      contentType === CONTENT_TYPE.WORKING_HOURS
    ) {
      return handleWorkingHoursCoachMarkClose();
    }
  };

  return (
    <Tooltip
      title={
        <>
          {contentType === CONTENT_TYPE.AVAILABILITY && (
            <AvailableForWorkCoachMark onClose={handleTooltipClose} />
          )}
          {contentType === CONTENT_TYPE.WORKING_HOURS && (
            <WorkingHoursCoachMark onClose={handleTooltipClose} />
          )}
        </>
      }
      enterNextDelay={1000}
      leaveDelay={0}
      TransitionProps={{
        onExited: handleExited,
      }}
      color="var(--black)"
      open={isOpen}
      placement={placement ?? "bottom"}
      PopperProps={{
        sx: { zIndex: 1200 },
        onClick: handleClick,
      }}
    >
      <Box
        onClickCapture={handleAnchorClick}
        onKeyDown={handleAnchorKeyDown}
        role="button"
      >
        {children}
      </Box>
    </Tooltip>
  );
};

type CoachMarkProps = {
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
};

const CoachMark = ({ onClose, className, children }: CoachMarkProps) => {
  return (
    <div className={className}>
      <IconButton
        variant="tertiary"
        size="x-small"
        aria-label="Close coachmark"
        onClick={() => onClose()}
        className={style.coachmarkCloseIcon}
      >
        <CloseRoundedIcon />
      </IconButton>
      <div>
        <Typography
          component="p"
          variant="label"
          size="small"
          textAlign="center"
        >
          {children}
        </Typography>
      </div>
    </div>
  );
};

export const AvailableForWorkCoachMark = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const lookingForWorkAction = () => openAvailabilityModal({ onClose });
  return (
    <CoachMark onClose={onClose}>
      Open yourself up to new opportunities by letting others know if you’re
      {"  "}
      <InteractiveTag
        tabIndex={0}
        tag="u"
        onClick={lookingForWorkAction}
        style={{ cursor: "pointer" }}
      >
        looking for work.
      </InteractiveTag>
    </CoachMark>
  );
};

export const WorkingHoursCoachMark = ({ onClose }: { onClose: () => void }) => {
  return (
    <CoachMark onClose={onClose} className={style.availableForWorkContent}>
      New:
      {"  "}
      <ButtonCore
        onClick={() => openAvailabilityModal({ onClose })}
        className={style.coachMarkButtonCta}
      >
        Add your working hours
      </ButtonCore>
      {"  "}
      to your availability to improve your job matches.
    </CoachMark>
  );
};
