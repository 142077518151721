import { useCallback, useEffect, useRef } from "react";

import type { GetATSIntegrationSyncStatusResponse } from "@js/apps/employer/api";
import {
  useGetATSIntegrationJobsQuery,
  useGetATSIntegrationSyncStatusQuery,
} from "@js/apps/employer/api";
import { Snackbar } from "@js/components/snackbar";

type UseATSIntegrationJobsPollingArg = {
  integrationId: number;
};

export const useATSIntegrationJobsPolling = ({
  integrationId,
}: UseATSIntegrationJobsPollingArg) => {
  const { data: syncStatusResponse, refetch: refetchSyncStatus } =
    useGetATSIntegrationSyncStatusQuery({
      id: integrationId,
      model: ENUMS.ATSDataSyncModel.JOB,
    });

  const isIntegrationSyncFinished =
    isATSIntegrationSyncFinished(syncStatusResponse);

  const { data: atsJobs, isFetching: isFetchingAtsJobs } =
    useGetATSIntegrationJobsQuery(integrationId, {
      refetchOnMountOrArgChange: false,
      skip: !isIntegrationSyncFinished,
    });

  const isLoading = !isIntegrationSyncFinished || isFetchingAtsJobs;

  const timeoutRef = useRef<number>();
  const pollSyncJobsStatus = useCallback(
    async (id: number) => {
      try {
        const response = await refetchSyncStatus().unwrap();

        if (isATSIntegrationSyncFinished(response)) {
          return;
        }

        timeoutRef.current = window.setTimeout(
          () => pollSyncJobsStatus(id),
          2000,
        );
      } catch (error) {
        Snackbar.error("Something went wrong during job sync.");
      }
    },
    [refetchSyncStatus],
  );

  const isPollingInitializedRef = useRef(false);
  useEffect(() => {
    if (!syncStatusResponse || isPollingInitializedRef.current) {
      return;
    }

    if (isIntegrationSyncFinished) {
      return;
    }

    isPollingInitializedRef.current = true;
    timeoutRef.current = window.setTimeout(
      () => pollSyncJobsStatus(integrationId),
      2000,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationId, syncStatusResponse, isIntegrationSyncFinished]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, atsJobs };
};

const isATSIntegrationSyncFinished = (
  response: GetATSIntegrationSyncStatusResponse | undefined,
): boolean => !!response && !response.is_initial_sync;
