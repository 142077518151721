import type { FC } from "react";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  CloseIcon,
  EditPenIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { sanitize } from "@js/services";
import type { WorkSampleItem } from "@js/types/freelancer";

import styles from "./styles.module.scss";

type ProfileProjectsModuleEditListItemProps = {
  project: WorkSampleItem;
  onEdit: () => void;
  onDelete: () => void;
};

export const ProfileProjectsModuleEditListItem: FC<
  ProfileProjectsModuleEditListItemProps
> = ({ project, onEdit, onDelete }) => {
  const isMobile = useMediaQuery("sm");

  const projectDescription = (() => {
    if (!project.description) return null;

    return sanitize(project.description, { ALLOWED_TAGS: [] });
  })();

  const projectMainImageUrl = project.main_image
    ? project.main_image.image.attachment.file
    : `${SETTINGS.STATIC_URL}freelancer/project-empty-state-thumbnail.svg`;

  return (
    <Card className={styles.profileProjectsEditListItem}>
      <Box className={styles.profileProjectsEditListItemMedia}>
        <CardMedia
          sx={{ width: "150px" }}
          component="img"
          image={projectMainImageUrl}
          alt="project image"
        />
      </Box>
      <CardContent
        sx={{ width: "100%", padding: 0, paddingLeft: isMobile ? 0 : 1 }}
      >
        <Typography
          component="h1"
          fontWeight={400}
          multilineEllipsis={2}
          variant="label"
          size="large"
        >
          {project.title}
        </Typography>
        <Typography
          variant="paragraph"
          color="grey-1"
          component="p"
          size="small"
          multilineEllipsis={2}
        >
          {projectDescription}
        </Typography>
      </CardContent>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
        paddingLeft={isMobile ? 0 : 2}
        paddingRight={isMobile ? 0 : 2}
        gap={1}
      >
        <IconButton
          aria-label="edit"
          onClick={onEdit}
          variant="tertiary"
          size="small"
        >
          <EditPenIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          variant="tertiary"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Card>
  );
};
