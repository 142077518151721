import { Loader } from "@hexocean/braintrust-ui-components";
import FullPageLayout from "@js/layouts/full-page";

import { useCanUserCreateInvoice } from "../../hooks/can-user-create-invoice";

type InvoicePageWrapperProps = {
  children: React.ReactNode;
  pageTitle: string;
};

export const InvoicePageLayout = ({
  children,
  pageTitle,
}: InvoicePageWrapperProps) => {
  const { isLoadingFinished } = useCanUserCreateInvoice();

  if (!isLoadingFinished) return <Loader centered />;

  return (
    <FullPageLayout
      pageTitle={pageTitle}
      showLogo={false}
      back
      closeURL="/talent/invoices/"
      className="invoice-page-layout"
    >
      {children}
    </FullPageLayout>
  );
};
