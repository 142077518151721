import { useMemo } from "react";

import { useIsAllowedToMarkInvoiceAsPaid } from "@js/apps/invoices/hooks/use-is-allowed-to-mark-invoice-as-paid";
import type { Invoice } from "@js/types/invoices";

type UseMarkAsPaidActionProps = {
  invoices: Pick<Invoice, "id" | "can_be_marked_as_paid">[];
};

export const useMarkAsPaidAction = ({ invoices }: UseMarkAsPaidActionProps) => {
  const isUserAllowedToMarkInvoiceAsPaid = useIsAllowedToMarkInvoiceAsPaid();

  const canBeMarkedAsPaid = useMemo(() => {
    if (!invoices?.length) {
      return false;
    }

    if (!isUserAllowedToMarkInvoiceAsPaid) {
      return false;
    }

    return invoices.every((invoice) => invoice.can_be_marked_as_paid);
  }, [invoices, isUserAllowedToMarkInvoiceAsPaid]);

  return { canBeMarkedAsPaid };
};
