import { SubmissionError } from "redux-form";

import {
  useAddBidNoteMutation,
  useDeleteBidNoteMutation,
  useGetBidNotesQuery,
} from "@js/apps/jobs/api";
import { Snackbar } from "@js/components/snackbar";
import type { NoteItem } from "@js/types/admin";
import { isErrorWithDetails } from "@js/types/errors";

type UseBidCommentsProps = {
  bidId: number;
};

export const useBidComments = ({ bidId }: UseBidCommentsProps) => {
  const { data: comments, isLoading } = useGetBidNotesQuery(
    {
      id: bidId,
    },
    {
      refetchOnMountOrArgChange: 60,
    },
  );
  const [addBidNote] = useAddBidNoteMutation();
  const [deleteBidNote] = useDeleteBidNoteMutation();

  const deleteComment = (note: NoteItem) => {
    return deleteBidNote({ noteId: note.id, bidId })
      .unwrap()
      .then(() => Snackbar.success("Comment successfully deleted."))
      .catch((error) => {
        const errorData = error?.data;

        if (isErrorWithDetails(errorData)) {
          Snackbar.error(errorData.detail);
        }
      });
  };

  const onSubmit = async (values: { id: number; content: string }) => {
    return await addBidNote(values)
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const onSubmitSuccess = (_result, _dispatch, props) => {
    props.reset();
    Snackbar.success("Comment successfully added.");
  };

  return {
    isLoading,
    comments,
    deleteComment,
    onSubmit,
    onSubmitSuccess,
  };
};
