import React from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import {
  useDeleteActiveSessionMutation,
  useGetActiveSessionsQuery,
} from "@js/apps/settings/api";
import { Snackbar } from "@js/components/snackbar";
import type { SessionItem as SessionItemType } from "@js/types/settings";

import { SessionItem } from "../item";

export const SessionList = () => {
  const { data: sessions, isLoading } = useGetActiveSessionsQuery();
  const [deleteActiveSession] = useDeleteActiveSessionMutation();

  const handleDeleteClick = async (session: SessionItemType) => {
    const canDeleteSession = !session.is_current_session;

    if (canDeleteSession) {
      try {
        await deleteActiveSession(session.id)
          .unwrap()
          .then(() => {
            Snackbar.success("Session deleted.");
          });
      } catch (err) {
        Snackbar.success("Session cannot be deleted.");
      }
    }
  };

  if (isLoading && !sessions) {
    return <Loader />;
  }

  return (
    <div>
      {sessions?.map((session, index) => {
        const canDeleteSession = !session.is_current_session;

        return (
          <SessionItem
            key={session.id}
            num={index + 1}
            session={session}
            onDeleteClick={canDeleteSession ? handleDeleteClick : null}
          />
        );
      })}
    </div>
  );
};
