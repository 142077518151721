import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { updateVisitsCounter } from "@js/apps/common/actions";
import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import { Snackbar } from "@js/components/snackbar";
import type {
  FreelancerForNodeStaff,
  FreelancerPublic,
} from "@js/types/freelancer";
import { UNEXPECTED_NETWORK_ERROR_MESSAGE } from "@js/utils";

import { useGetFreelancerPublicProfileQuery } from "../../api";

type UseFreelancerProfileType =
  | {
      isAccessToNodeStaff: true;
      profile: FreelancerForNodeStaff;
    }
  | {
      isAccessToNodeStaff: false;
      profile: FreelancerPublic;
    };

export type UseFreelancerPublicProfileReturnType = UseFreelancerProfileType & {
  user: FreelancerPublic["user"] | null;
};

export const useFreelancerPublicProfile = (
  freelancerId?: number | null,
): UseFreelancerPublicProfileReturnType => {
  const isNodeStaff = useIsNodeStaff();
  const user = useUser();
  const { data: profile, isError } = useGetFreelancerPublicProfileQuery(
    freelancerId ?? skipToken,
  );

  useEffect(() => {
    if (!freelancerId) {
      return;
    }

    if (isError) {
      Snackbar.error(UNEXPECTED_NETWORK_ERROR_MESSAGE);
      return;
    }

    updateVisitsCounter(freelancerId, ENUMS.VisitsCounterType.TALENT);
  }, [freelancerId, isError]);

  if (isNodeStaff) {
    return {
      user,
      profile: profile as FreelancerForNodeStaff,
      isAccessToNodeStaff: isNodeStaff,
    };
  }

  return {
    user,
    profile: profile as FreelancerPublic,
    isAccessToNodeStaff: isNodeStaff,
  };
};
