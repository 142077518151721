import { Fragment } from "react/jsx-runtime";

import type { FormControlLabelProps } from "@hexocean/braintrust-ui-components";
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  InversedRadio,
  RadioGroup,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  EditPenIcon,
  KeyboardArrowRightIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import { ExportEmployerInvoicesModalContentWrapper } from "./export-employer-invoices-modal-content-wrapper";
import type { ExportCSVCustomDateRange } from "./helpers";
import {
  EXPORT_CSV_RANGE_OPTION_LABELS,
  EXPORT_CSV_RANGE_OPTION_VALUE,
  type ExportCSVDateRangeOption,
  type ExportCSVRangeOptionValue,
  formatDateRange,
} from "./helpers";

type ExportEmployerInvoicesModalMainContentProps = {
  options: ExportCSVDateRangeOption[];
  value: ExportCSVRangeOptionValue;
  onChange: (value: ExportCSVRangeOptionValue) => void;
  onEditCustomRangeClick: () => void;
  onCancelClick: () => void;
  onExportClick: () => void;
} & ExportEmployerCSVCustomRangeLabelProps;

export const ExportEmployerInvoicesModalMainContent = ({
  value,
  onChange,
  options,
  customRange,
  onEditCustomRangeClick,
  onCancelClick,
  onExportClick,
}: ExportEmployerInvoicesModalMainContentProps) => {
  return (
    <ExportEmployerInvoicesModalContentWrapper
      sx={{ width: "min(550px, 100vw - 2rem)" }}
    >
      <Typography
        component="h2"
        variant="title"
        size="small"
        fontWeight={400}
        sx={{ mb: 2.5 }}
      >
        Export as CSV
      </Typography>
      <Typography
        id="date-range-label"
        component="label"
        variant="label"
        size="medium"
        sx={{ mb: 1 }}
      >
        Date range
      </Typography>
      <RadioGroup
        aria-labelledby="date-range-label"
        name="export-invoices-range-buttons-group"
        value={value}
        sx={{ mb: 2.5 }}
      >
        {options.map((option) => {
          return (
            <Fragment key={option.value}>
              <DateRangeOption
                value={option.value}
                label={<ExportEmployerCSVOptionLabel option={option} />}
                onClick={() => onChange(option.value)}
              />
              {option.value === EXPORT_CSV_RANGE_OPTION_VALUE.THIS_YEAR && (
                <Divider color="beige" sx={{ my: 1 }} />
              )}
            </Fragment>
          );
        })}
        <Divider color="beige" sx={{ my: 1 }} />
        <DateRangeOption
          value={EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE}
          label={
            <ExportEmployerCSVCustomRangeLabel
              customRange={customRange}
              onEditCustomRangeClick={onEditCustomRangeClick}
            />
          }
          onClick={() => onChange(EXPORT_CSV_RANGE_OPTION_VALUE.CUSTOM_RANGE)}
        />
      </RadioGroup>
      <Stack direction="row" sx={{ gap: 1, alignSelf: "end" }}>
        <Button
          shape="squared"
          variant="secondary"
          onClick={onCancelClick}
          size="x-small"
        >
          Cancel
        </Button>
        <Button
          shape="squared"
          variant="primary"
          onClick={onExportClick}
          size="x-small"
        >
          Export
        </Button>
      </Stack>
    </ExportEmployerInvoicesModalContentWrapper>
  );
};

const DateRangeOption = (
  props: DistributiveOmit<FormControlLabelProps, "control">,
) => {
  return (
    <FormControlLabel
      control={<InversedRadio sx={{ "&&": { p: "7px" } }} />}
      sx={{ mr: 0 }}
      slotProps={{ typography: { sx: { width: "100%" } } }}
      {...props}
    />
  );
};

const ExportEmployerCSVOptionLabel = ({
  option,
}: {
  option: ExportCSVDateRangeOption;
}) => {
  return (
    <Stack
      direction="row"
      sx={{ gap: 2, alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography variant="paragraph" component="label" size="medium">
        {option.label}
      </Typography>
      <Typography
        variant="label"
        component="span"
        size="medium"
        textAlign="right"
      >
        {formatDateRange(option)}
      </Typography>
    </Stack>
  );
};

type ExportEmployerCSVCustomRangeLabelProps = {
  customRange: ExportCSVCustomDateRange | undefined;
  onEditCustomRangeClick: () => void;
};

const ExportEmployerCSVCustomRangeLabel = ({
  customRange,
  onEditCustomRangeClick,
}: ExportEmployerCSVCustomRangeLabelProps) => {
  return (
    <Stack
      direction="row"
      sx={{ gap: 2, alignItems: "center", justifyContent: "space-between" }}
    >
      <Typography variant="paragraph" component="label" size="medium">
        {EXPORT_CSV_RANGE_OPTION_LABELS.CUSTOM_RANGE}
      </Typography>
      {!!customRange && (
        <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
          <IconButton
            aria-label="Edit date range"
            onClick={onEditCustomRangeClick}
            variant="transparent"
            size="medium"
            sx={{ "&&": { p: 0.5 } }}
          >
            <EditPenIcon />
          </IconButton>
          <Typography
            variant="label"
            component="span"
            size="medium"
            textAlign="right"
          >
            {formatDateRange(customRange)}
          </Typography>
        </Stack>
      )}
      {!customRange && (
        <IconButton
          variant="transparent"
          onClick={onEditCustomRangeClick}
          aria-label="Edit date range"
          size="medium"
          sx={{ "&&": { p: 0.5 }, mr: -0.5 }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      )}
    </Stack>
  );
};
