import React from "react";

import {
  Box,
  Button,
  IconButton,
  Menu,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import {
  boostBannerClicked,
  insightsBannerClicked,
} from "@js/apps/jobs/actions";
import {
  BOOST_DESCRIPTION,
  INSIGHTS_DESCRIPTION,
} from "@js/apps/jobs/components/application-onramp-modal/constants";
import {
  Section,
  SectionSubtitleMiddleAction,
} from "@js/apps/settings/forms/helpers";
import { useAppDispatch } from "@js/hooks";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";

const UpgradeSection = (): JSX.Element => {
  const { onClickPurchaseUpgrade: handleBoostPurchase } =
    usePurchaseUpgrade("boost");
  const { onClickPurchaseUpgrade: handleInsightPurchase } =
    usePurchaseUpgrade("insights");
  const user = useUser();
  const dispatch = useAppDispatch();

  const handleColorOnStatus = (status: string) => {
    if (status === "ACTIVE") {
      return "#447513";
    } else if (status === "WARNING") {
      return "var(--dark-orange)";
    } else if (status === "EXPIRED") {
      return "var(--negative-2)";
    } else {
      return "#676460";
    }
  };

  const handleBgOnStatus = (status: string) => {
    if (status === "ACTIVE") {
      return "var(--soft-green)";
    } else if (status === "WARNING") {
      return "var(--soft-orange)";
    } else if (status === "EXPIRED") {
      return "var(--soft-red)";
    } else {
      return "var(--soft-grey)";
    }
  };

  const statusBox = (type: string) => {
    const data = user?.upgrade_purchase_status?.[type];
    const status: string = data?.status ? data?.status : "";
    const color = handleColorOnStatus(status);
    const bg = handleBgOnStatus(status);

    const statusText = () => {
      if (
        type === "boost" &&
        data?.usage === "No boosts available" &&
        status !== ""
      ) {
        return "0/3 boosts available";
      } else if (
        type === "insights" &&
        data?.usage === "No insights available" &&
        status !== ""
      ) {
        return "0/30 days remaining";
      } else return data?.usage;
    };
    return (
      <Box bgcolor={bg} px={2} borderRadius={10} py={0.5}>
        <Typography
          component="p"
          sx={{ color: color }}
          size="medium"
          fontWeight={500}
        >
          {statusText()}
        </Typography>
      </Box>
    );
  };

  const rePurchaseHandler = async (type: string) => {
    if (type === "boost") {
      dispatch(boostBannerClicked());
      handleBoostPurchase();
    } else {
      dispatch(insightsBannerClicked());
      handleInsightPurchase();
    }
  };

  const actionButton = (type: string) => {
    return (
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchor={
          <IconButton
            aria-label={`Open ${type} upgrades menu`}
            aria-controls={`job-action-menu-old`}
            aria-haspopup="true"
            variant="transparent"
            size="small"
            sx={{ padding: "0px !important" }}
          >
            <Box
              bgcolor={"var(--off-white)"}
              borderRadius={"100%"}
              px={1}
              py={0.8}
            >
              <MoreVertIcon />
            </Box>
          </IconButton>
        }
      >
        <Menu.Item
          onClick={() => rePurchaseHandler(type)}
          key={"label"}
          tooltipText={"reason"}
        >
          Purchase additional {type === "insights" ? "days" : "boosts"}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <Section title="Upgrades">
        <SectionSubtitleMiddleAction
          title={"Application Insights"}
          description={INSIGHTS_DESCRIPTION}
          action={
            <Box display={"flex"} alignItems={"center"}>
              <Box> {statusBox("insights")} </Box>
              <Box padding={"0px 10px"}>{actionButton("insights")}</Box>
            </Box>
          }
          {...(user?.upgrade_purchase_status?.insights?.status !== "ACTIVE" && {
            endButton: (
              <Button
                onClick={() => rePurchaseHandler("insights")}
                shape="squared"
                sx={{ minWidth: "18px !important" }}
                variant={"primary"}
              >
                Purchase Application Insights
              </Button>
            ),
          })}
          divider
        />

        <SectionSubtitleMiddleAction
          title={"Application Boost"}
          description={BOOST_DESCRIPTION}
          action={
            <Box>
              <Box display={"flex"} alignItems={"center"}>
                <Box> {statusBox("boost")} </Box>
                <Box padding={"0px 10px"}>{actionButton("boost")}</Box>
              </Box>
            </Box>
          }
          {...(user?.upgrade_purchase_status?.boost?.status !== "ACTIVE" && {
            endButton: (
              <Button
                onClick={() => rePurchaseHandler("boost")}
                variant={"primary"}
                shape="squared"
                sx={{ minWidth: "18px !important" }}
              >
                Purchase Application Boosts
              </Button>
            ),
          })}
        />
      </Section>
    </>
  );
};

export default UpgradeSection;
