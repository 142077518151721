import { Loader, Stack } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { SectionPanel } from "@js/apps/freelancer/components/dashboard/utils";
import { useGetSuggestedSpacesQuery } from "@js/apps/spaces/api";
import { SpaceCard } from "@js/apps/spaces/components/space-card";

export const SuggestedSpacesSection = () => {
  const user = useUser();
  const { isLoading, data: suggestedSpaces } = useGetSuggestedSpacesQuery(
    undefined,
    { skip: !user?.is_verified },
  );

  if (isLoading) return <Loader />;

  if (!suggestedSpaces?.length) return null;

  return (
    <SectionPanel title="Spaces you might like" isNudge>
      <Stack direction="column" spacing={2} width="100%">
        {suggestedSpaces.map((space) => (
          <SpaceCard key={space.id} {...space} showMobileVariant />
        ))}
      </Stack>
    </SectionPanel>
  );
};
