import { change, Field } from "redux-form";

import type {
  AttachmentBase,
  FilesUploadFieldRenderPreviewArg,
} from "@js/apps/file-upload";
import {
  FileDropzonePlaceholderDashed,
  FilesPreviewList,
  FilesUploadField,
} from "@js/apps/file-upload";
import type { BidAttachment } from "@js/types/jobs";
import { formatBytes } from "@js/utils";

const subtitle = `Attach up to ${
  SETTINGS.BID_ATTACHMENTS_LIMIT
} files, max ${formatBytes(
  SETTINGS.BID_FILE_SIZE_LIMIT,
  0,
)} each. A cover letter is not required.`;

export const JobNewBidFileDropField = ({
  existing,
}: {
  existing?: BidAttachment[];
}) => {
  return (
    <Field
      name="attachments_ids"
      component={FilesUploadField}
      uploadType={ENUMS.UploadType.BID_ATTACHMENT_UPLOAD_TYPE}
      options={{
        maxFiles: SETTINGS.BID_ATTACHMENTS_LIMIT,
        maxSize: SETTINGS.BID_FILE_SIZE_LIMIT,
      }}
      existingFiles={existing}
      renderPlaceholder={({ isDragActive, isFocused, isUploading }) => (
        <FileDropzonePlaceholderDashed
          isFocused={isFocused}
          isDragActive={isDragActive}
          isLoading={isUploading}
          subtitle={subtitle}
          id="attachments_ids"
        />
      )}
      renderPreview={(arg: FilesUploadFieldRenderPreviewArg) =>
        renderBidAttachmentsPreview(existing ?? [], arg)
      }
    />
  );
};

const renderBidAttachmentsPreview = (
  existing: BidAttachment[],
  { meta, input, ...rest }: FilesUploadFieldRenderPreviewArg,
) => {
  return (
    <FilesPreviewList
      {...rest}
      onFileRename={(newAttachment) => {
        const shouldUpdateForm = getShouldUpdateForm({
          existing,
          newAttachment,
        });

        if (!shouldUpdateForm) {
          return;
        }
        // Note: we update `attachments` field manually to make sure the onChange is triggered.
        // ... this field has no effect when sending the edit bid request, it is just used to ...
        // ... remove 'pristine' state from the form
        meta.dispatch(
          change(meta.form, "attachments", [
            newAttachment,
            ...existing.map((existingAttachment) => existingAttachment.id),
          ]),
        );
      }}
    />
  );
};

export const getShouldUpdateForm = ({
  existing,
  newAttachment,
}: {
  existing: Array<Pick<BidAttachment, "id"> & { attachment: AttachmentBase }>;
  newAttachment: { id: number; attachment: AttachmentBase };
}) => {
  const renamedExistingAttachment = existing.find(
    (existingAttachment) => existingAttachment.id === newAttachment.id,
  );

  if (!renamedExistingAttachment) {
    return false;
  }

  const hasExistingAttachmentBeenRenamed =
    renamedExistingAttachment.attachment.name !== newAttachment.attachment.name;

  return hasExistingAttachmentBeenRenamed;
};
