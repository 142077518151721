import { StarSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";
import { useHasFilterPopoverAnimationFinished } from "@js/hooks/use-has-filter-popover-animation-finished";

import { EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL } from "../../constants";
import { JobOwnerForm } from "../forms";
import type { EmployerInvoicesFormValues } from "../schema";

type JobOwnerFilterProps = {
  jobOwners: EmployerInvoicesFormValues["job_owners"];
  onChange: (jobOwners: EmployerInvoicesFormValues["job_owners"]) => void;
};

export const JobOwnerFilter = ({
  jobOwners,
  onChange,
}: JobOwnerFilterProps) => {
  const isJobOwnerApplied = Boolean(jobOwners?.length);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, hasPopoverAnimationFinished } =
    useHasFilterPopoverAnimationFinished();

  const handleApply = (
    newJobOwners: EmployerInvoicesFormValues["job_owners"],
  ) => {
    onChange(newJobOwners);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterPopover
      label={EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL.job_owners}
      icon={<StarSVGIcon sx={{ path: { strokeWidth: "1.25" } }} />}
      isFilterApplied={isJobOwnerApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
      variant="green"
    >
      <JobOwnerForm
        jobOwners={jobOwners}
        onApply={handleApply}
        hasFilterPopoverFinishedAnimation={hasPopoverAnimationFinished}
      />
    </FilterPopover>
  );
};
