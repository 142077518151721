import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { CustomizeColumnsButtonProps } from "@js/apps/common/components";
import { CustomizeColumnsButton } from "@js/apps/common/components";

import type { EmployerMyTalentColumnField } from "../types";

type MyTalentTableTopSectionProps = {
  talentCount: number;
  totalTalentCount: number;
  handleDownloadCSV: () => void;
} & CustomizeColumnsButtonProps<EmployerMyTalentColumnField>;

export const MyTalentTableTopSection = ({
  talentCount,
  totalTalentCount,
  handleDownloadCSV,
  ...customizedColumnsProps
}: MyTalentTableTopSectionProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        gap: 2,
        flexWrap: "wrap",
        alignItems: "center",
        minHeight: "42px",
        mb: { xs: 1, sm: 0 },
      }}
    >
      <Typography
        component="p"
        variant="paragraph"
        size="medium"
        sx={{ minWidth: "130px" }}
      >
        Showing{" "}
        <strong>
          {talentCount} of {totalTalentCount}
        </strong>{" "}
        talent
      </Typography>

      <Box sx={{ mr: "auto" }}>
        <CustomizeColumnsButton {...customizedColumnsProps} />
      </Box>
      <Button variant="secondary" shape="squared" onClick={handleDownloadCSV}>
        Export CSV
      </Button>
    </Stack>
  );
};
