import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";
import { getLocationValueUniqueId } from "@js/components/autocomplete-new/google-places/utils";
import type { PopularLocation } from "@js/types/common";

export type RecommendedLocationButtonSelectProps = TypedWrappedFieldProps<
  LocationValue[]
> & {
  options: PopularLocation[];
  label: string;
};

export const RecommendedLocationButtonSelect = ({
  label,
  options,
  input,
}: RecommendedLocationButtonSelectProps) => {
  const valueIds = input.value.map(getLocationValueUniqueId);

  return (
    <Box mt={2}>
      <Typography
        component="label"
        display="block"
        mb={1}
        variant="label"
        size="small"
        fontWeight={400}
      >
        {label}
      </Typography>
      <Box display="flex" gap={1} flexWrap="wrap">
        {options.map((opt) => {
          const valueId = getLocationValueUniqueId(opt);
          const isSelected = valueIds.includes(valueId);

          const handleClick = () => {
            if (isSelected) {
              const newValue = input.value.filter((val) => {
                return getLocationValueUniqueId(val) !== valueId;
              });

              input.onChange(newValue);
            } else {
              input.onChange([...input.value, opt]);
            }
          };

          return (
            <Button
              key={opt.location}
              variant={isSelected ? "medium-violet" : "white-violet"}
              shape="squared"
              onClick={handleClick}
            >
              <Typography component="span" size="small">
                {opt.location}
              </Typography>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
