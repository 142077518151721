import { useMemo } from "react";

import { COMPLETION_AREA_TO_COMPONENT_TYPE_MAP } from "@js/apps/freelancer/constants";
import type { ProfileCompletion } from "@js/types/freelancer";

const useUnfinishedSections = (
  profileCompletion: ProfileCompletion | undefined,
) => {
  return useMemo(() => {
    if (!profileCompletion || profileCompletion?.completion_percent === 100) {
      return null;
    }

    const allUnfinishedSections = profileCompletion?.missing_areas?.map(
      (area) => COMPLETION_AREA_TO_COMPONENT_TYPE_MAP[area.keywords],
    );

    if (allUnfinishedSections?.length === 0) {
      return null;
    }

    return allUnfinishedSections;
  }, [profileCompletion]);
};

export const useFirstUnfinishedSection = (
  profileCompletion: ProfileCompletion | undefined,
) => {
  const unfinishedSections = useUnfinishedSections(profileCompletion);

  return unfinishedSections ? unfinishedSections[0] : null;
};
