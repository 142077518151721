import type React from "react";

export type ProfileCompletionStep = EnumType<typeof PROFILE_COMPLETION_STEPS>;
export type SetProfileCompletionStep = React.Dispatch<
  React.SetStateAction<ProfileCompletionStep | undefined>
>;
export const PROFILE_COMPLETION_STEPS = {
  ROLE: "Role",
  WORK_EXPERIENCE: "Work Experience",
  ABOUT: "About",
  SKILLS: "Skills",
} as const;
