import { useCallback } from "react";
import { type Control, Controller } from "react-hook-form";

import { Carousel, Stack } from "@hexocean/braintrust-ui-components";
import {
  employerInvoicesSearchApplied,
  filterEmployerInvoicesSearchApplied,
} from "@js/apps/employer/actions";
import { useAppDispatch } from "@js/hooks/redux";

import type { EmployerInvoicesFilterToApply } from "../constants";
import { EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL } from "../constants";

import {
  DateRangeFilter,
  JobOwnerFilter,
  ProjectFilter,
  SearchFilter,
  StatusFilter,
  TalentFilter,
} from "./filters";
import type { EmployerInvoicesFormValues } from "./schema";

import styles from "./styles.module.scss";

type EmployerInvoicesFiltersProps = {
  control: Control<EmployerInvoicesFormValues>;
};

export const EmployerInvoicesFilters = ({
  control,
}: EmployerInvoicesFiltersProps) => {
  const dispatch = useAppDispatch();

  const handleFilterApply = useCallback(
    ({
      filter,
      value,
    }: {
      filter: EmployerInvoicesFilterToApply;
      value: unknown;
    }) => {
      dispatch(
        filterEmployerInvoicesSearchApplied({
          filterType:
            EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL[filter].toLocaleLowerCase(),
          filterValue: value,
        }),
      );
    },
    [dispatch],
  );

  const handleSearchChange = useCallback(
    (search: string | null | undefined) => {
      if (!search) {
        return;
      }

      dispatch(employerInvoicesSearchApplied());
    },
    [dispatch],
  );

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
      <Controller
        control={control}
        name="search"
        render={({ field: { onChange, value } }) => (
          <SearchFilter
            search={value}
            onChange={(newFilterValue) => {
              onChange(newFilterValue);
              handleSearchChange(newFilterValue);
            }}
          />
        )}
      />

      <Carousel
        slideProps={{ style: { height: "auto", width: "auto" } }}
        spaceBetween={8}
        className={styles.filtersCarousel}
        slides={[
          <DateRangeFilter
            key="date"
            control={control}
            onChange={(newFilterValue) => {
              const stringifiedNewFilterValue = [
                newFilterValue.date_after,
                newFilterValue.date_before,
              ]
                .map((date) => date ?? "...")
                .join(" - ");

              handleFilterApply({
                filter: "date_range",
                value: stringifiedNewFilterValue,
              });
            }}
          />,

          <Controller
            key="status"
            control={control}
            name="status"
            render={({ field: { onChange, value, name } }) => (
              <StatusFilter
                status={value}
                onChange={(newFilterValue) => {
                  onChange(newFilterValue);
                  handleFilterApply({ filter: name, value: newFilterValue });
                }}
              />
            )}
          />,

          <Controller
            key="freelancer"
            control={control}
            name="freelancers"
            render={({ field: { onChange, value, name } }) => (
              <TalentFilter
                freelancers={value}
                onChange={(newFilterValue) => {
                  onChange(newFilterValue);
                  handleFilterApply({ filter: name, value: newFilterValue });
                }}
              />
            )}
          />,

          <Controller
            key="project"
            control={control}
            name="jobs"
            render={({ field: { onChange, value, name } }) => (
              <ProjectFilter
                jobs={value}
                onChange={(newFilterValue) => {
                  onChange(newFilterValue);
                  handleFilterApply({ filter: name, value: newFilterValue });
                }}
              />
            )}
          />,

          <Controller
            key="owner"
            control={control}
            name="job_owners"
            render={({ field: { onChange, value, name } }) => (
              <JobOwnerFilter
                jobOwners={value}
                onChange={(newFilterValue) => {
                  onChange(newFilterValue);
                  handleFilterApply({ filter: name, value: newFilterValue });
                }}
              />
            )}
          />,
        ]}
      />
    </Stack>
  );
};
