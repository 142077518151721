import { skipToken } from "@reduxjs/toolkit/query";

import {
  useGetJobQuery,
  useGetTalentInviteSuggestionsQuery,
} from "@js/apps/jobs/api";
import { useQueryParams } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";

import { usePollingSmartMatchingStatus } from "../polling-smart-matching-status";

export const useGetTalentInviteSuggestionsWithSmartMatching = () => {
  const id = useIdParam();
  const { page = 1 } = useQueryParams();
  const { data: job, isLoading: isJobLoading } = useGetJobQuery(
    id ? { id } : skipToken,
  );
  const totalBidsCount = job?.bids_count.total_bids_count || 0;

  const { isSmartMatchingInProgress, analyzingProfilesCount } =
    usePollingSmartMatchingStatus({ jobId: id });

  const {
    data: talentInviteSuggestions,
    isLoading: isLoadingTalentInviteSuggestions,
  } = useGetTalentInviteSuggestionsQuery(id ? { jobId: id, page } : skipToken, {
    skip: isSmartMatchingInProgress,
  });

  return {
    talentInviteSuggestions: talentInviteSuggestions?.results || [],
    inviteSuggestionsCount: talentInviteSuggestions?.count || 0,
    isSmartMatchingInProgress:
      isSmartMatchingInProgress ||
      isJobLoading ||
      isLoadingTalentInviteSuggestions,
    totalBidsCount,
    job,
    analyzingProfilesCount,
    jobId: id,
    page,
  };
};
