import { useEffect, useMemo, useState } from "react";
import _ from "underscore";
import uuid4 from "uuid4/browser";

import type { AutocompleteBaseProps } from "@hexocean/braintrust-ui-components";
import { AutocompleteBase } from "@hexocean/braintrust-ui-components";
import {
  KeyboardArrowDownIcon,
  LocationPinSmallIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { CUSTOM_JOB_LOCATIONS } from "@js/apps/common/constants";
import type {
  PlaceDetailsResponse,
  PlacesServicesTypes,
} from "@js/hooks/google-maps";
import { useGoogleMaps } from "@js/hooks/google-maps";

import styles from "./styles.module.scss";

export type GooglePlacesComboBoxProps<
  Multiple extends boolean,
  DisableClearable extends boolean,
> = DistributiveOmit<
  AutocompleteBaseProps<any, Multiple, DisableClearable, false>,
  "options" | "multiple" | "onChange"
> & {
  useCustomLocations?: boolean;
  placesServiceTypes?: PlacesServicesTypes;
  locationIcon?: boolean;
  onGetLocationStart?: () => void;
  onGetLocationEnd?: () => void;
  multiple?: Multiple;
  onChange?: (
    value: Multiple extends true ? Array<unknown> | null : unknown | null,
    reason?: string,
  ) => void;
};

type Location = PlaceDetailsResponse;

type Value = {
  id: string;
  name: string;
  custom?: boolean;
  disabled?: boolean;
  hidden?: boolean; // hidden is set to true on selected, preloaded values
  isSelected?: boolean;
} & Location;

/** @deprecated use components from autocomplete-new/google-places instead */
export const GooglePlacesDeprecated = <
  Multiple extends boolean,
  DisableClearable extends boolean,
>({
  onChange,
  value,
  locationIcon = true,
  useCustomLocations = false,
  placesServiceTypes = "cities",
  onGetLocationStart = () => ({}),
  onGetLocationEnd = () => ({}),
  multiple,
  ...props
}: GooglePlacesComboBoxProps<Multiple, DisableClearable>) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    getPlaceDetails,
    getPlaceDetailsAbortControllerRef,
  } = useGoogleMaps();
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [sessionToken, setSessionToken] = useState<string>();

  const processedValue = useMemo(() => {
    if (multiple) {
      return _.isArray(value)
        ? value.map((singleValue) => mapValue(singleValue))
        : [];
    }

    // @ts-ignore temporary ignore, it seems the whole component needs types improvement
    return !!value && !_.isArray(value) ? mapValue(value) : null;
  }, [value, multiple]);

  const options: Value[] = useMemo(() => {
    const locationOptions = useCustomLocations
      ? [
          ...CUSTOM_JOB_LOCATIONS,
          ...placePredictions.map((place) => ({
            id: place.place_id,
            name: place.description,
            disabled: false,
          })),
        ]
      : [
          ...placePredictions.map((place) => ({
            id: place.place_id,
            name: place.description,
            disabled: false,
          })),
        ];
    if (useCustomLocations && searchPhrase.length === 0) {
      locationOptions.unshift({
        id: "disabled",
        name: "Start typing to add any location",
        disabled: true,
      });
    }

    // Add preselected values (eg. from localstorage) to options - prevents MUI: The value provided to Autocomplete is invalid.
    const optionsToAdd = getOptionsFromValue(processedValue);

    return _.uniq([...locationOptions, ...optionsToAdd], "id");
  }, [useCustomLocations, searchPhrase, placePredictions, processedValue]);

  useEffect(() => {
    if (
      !!processedValue &&
      !Array.isArray(processedValue) &&
      processedValue.custom
    ) {
      getPlaceDetailsAbortControllerRef.current.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedValue]);

  const handleInputChange = (
    _event: unknown,
    input: string,
    reason: string,
  ) => {
    if (useCustomLocations) {
      setSearchPhrase(input);
    }
    if (reason === "reset") {
      return;
    }

    const token = uuid4();
    setSessionToken(token);

    getPlacePredictions({
      input,
      types: placesServiceTypes,
      sessionToken: token,
    });
  };

  const handleMultipleChange: AutocompleteBaseProps<
    any,
    true,
    DisableClearable,
    false
  >["onChange"] = (_event, newValue: Value | Value[], reason) => {
    if (!onChange || !Array.isArray(newValue)) {
      return;
    }

    if (reason === "removeOption") {
      return onChange?.([...newValue], reason);
    }

    const selectedValue = newValue.find((val) => !val.isSelected); // selected value does not have place details
    const currentValues = newValue.filter((val) => !!val.isSelected);

    if (useCustomLocations && selectedValue?.custom) {
      return onChange([...newValue], reason);
    }

    if (!selectedValue) {
      return onChange([...currentValues], reason);
    }

    getPlaceDetails(
      {
        sessionToken,
        placeId: selectedValue.id,
        fields: SETTINGS.GOOGLE_PLACES_DETAILS,
      },
      (placeDetails) => {
        onChange(
          [
            ...currentValues,
            {
              ...selectedValue,
              ...placeDetails,
              session_token: sessionToken,
            },
          ],
          reason,
        );
      },
    );
  };

  const handleSingleValueChange: AutocompleteBaseProps<
    any,
    false,
    DisableClearable,
    false
  >["onChange"] = (_event, newValue, reason) => {
    if (!onChange || Array.isArray(newValue)) {
      return;
    }

    if (useCustomLocations && newValue?.custom) {
      return onChange(newValue, reason);
    }

    onGetLocationStart?.();
    getPlaceDetails(
      {
        sessionToken,
        placeId: newValue.id,
        fields: SETTINGS.GOOGLE_PLACES_DETAILS,
      },
      (placeDetails) => {
        onChange(
          {
            ...newValue,
            ...placeDetails,
            session_token: sessionToken,
          },
          reason,
        );

        onGetLocationEnd?.();
      },
    );
  };

  const handleChange: AutocompleteBaseProps<
    any,
    Multiple,
    DisableClearable,
    false
  >["onChange"] = (_event, newValue, reason) => {
    if (!onChange) {
      return;
    }

    if (!newValue) {
      onChange(null, reason);
      return;
    }

    const changeCallback = multiple
      ? handleMultipleChange
      : handleSingleValueChange;

    return changeCallback(_event, newValue, reason);
  };

  return (
    <AutocompleteBase<any, Multiple, DisableClearable, false>
      multiple={multiple}
      onInputChange={handleInputChange}
      onChange={handleChange}
      getOptionLabel={(option: Value) => {
        // use formatted address as primary label as this is the format the backend uses when storing the values
        // (options use name, as they don't have formatted_address)
        return option.formatted_address || option.name;
      }}
      // @ts-ignore temporary ignore, it seems the whole component needs types improvement
      value={processedValue} // null required to fix console warning
      loading={isPlacePredictionsLoading}
      getOptionDisabled={(option: Value) => !!option?.disabled}
      filterOptions={(opts: Value[], { inputValue }) => {
        const filteredOptions = opts.filter((option) => {
          if (inputValue) {
            return !option.custom && !option.hidden;
          }

          return option.custom && !option.hidden;
        });

        return filteredOptions;
      }}
      isOptionEqualToValue={(option: Value, val: Value | null | undefined) => {
        return option.id === val?.id;
      }}
      classes={
        locationIcon
          ? { popupIndicatorOpen: styles.popupIndicatorOpen }
          : undefined
      } // Prevent location icon from rotating on open
      popupIcon={
        locationIcon ? <LocationPinSmallIcon /> : <KeyboardArrowDownIcon />
      }
      {...props}
      options={options}
    />
  );
};

const mapValue = (value: Value | string): Value => {
  if (typeof value === "string") {
    return {
      id: value,
      name: value,
      hidden: true,
      isSelected: true,
    };
  }

  return {
    ...value,
    id: value.id ?? value.place_id,
    hidden: true,
    isSelected: true,
  };
};

const getOptionsFromValue = (value: Value | Value[] | null): Value[] => {
  if (!value) {
    return [];
  }

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
};
