import { buildFiltersURL } from "@js/apps/common/filters";
import type { PaginatedResult } from "@js/types/generic";
import type {
  Bid,
  Job,
  JobListingJob,
  MainJobListingJob,
} from "@js/types/jobs";
import { getUTMQueryParamsSearch } from "@js/utils";

import type { UIUsedParams } from "../common/components/filters";

import type { JobLocationKey } from "./context";
import { JOB_LOCATION } from "./context";

export type JobItemsDraft =
  | PaginatedResult<MainJobListingJob>
  | MainJobListingJob[];

export const mapScreenSizeToJobTitleSize = (
  mobileSmall: boolean,
  mobileLarge: boolean,
) => {
  if (mobileSmall) return "small";

  if (mobileLarge) return "medium";

  return "large";
};

export const isJobCompleted = (
  job: Job | JobListingJob | MainJobListingJob | null,
): boolean => {
  if (!job) return false;

  return (
    job?.job_status === ENUMS.JobStatus.COMPLETED ||
    job?.job_status === ENUMS.JobStatus.COMPLETED_NOT_HIRED ||
    job?.job_status === ENUMS.JobStatus.COMPLETED_HIRED
  );
};

export const getWorkTimeLabel = (expectedHoursPerWeek: number) => {
  if (expectedHoursPerWeek < 40) {
    return "Part time";
  } else {
    return "Full time";
  }
};

export const handleBookingTalentsCalendarClick = async (
  bid: Pick<Bid, "id" | "calendar_url">,
  bidInterviewIntended: ({ bidId }: { bidId: number }) => Promise<unknown>,
) => {
  window.open(bid.calendar_url as string, "_blank", "noopener,noreferrer");
  return await bidInterviewIntended({ bidId: bid.id });
};

export const getRecommendedTalentListURL = (job) => {
  const filters: UIUsedParams = {
    job: job.id,
    approved: job.job_type === ENUMS.JobType.GRANT ? undefined : true,
    invite_to_job: true,
    page: 1,
    skill_search_operator: "and",
  };

  return `/talent/${buildFiltersURL(filters).search()}`;
};

export const getJobTypeLabels = (
  accountType?: EnumType<typeof ENUMS.AccountType>,
) => {
  return {
    ...ENUMS.JobTypeLabels,
    [ENUMS.JobType.FREELANCE]:
      accountType === ENUMS.AccountType.EMPLOYER
        ? "Contractor"
        : ENUMS.JobTypeLabels.freelance,
  };
};

export const getMakeOfferUrl = ({
  jobId,
  bidId,
}: {
  jobId: Job["id"];
  bidId: Bid["id"];
}) => {
  return `/jobs/${jobId}/offers/${bidId}/new/`;
};

export const getEditOfferUrl = ({
  jobId,
  offerId,
}: {
  jobId: Job["id"];
  offerId: number;
}) => {
  return `/jobs/${jobId}/offers/${offerId}/edit/`;
};

export const getJobDetailsUrl = ({
  id,
  location,
  search,
}: {
  id: Job["id"];
  location: JobLocationKey;
  search: string;
}): string => {
  if (location === JOB_LOCATION.public_job_listing) {
    const utmParams = getUTMQueryParamsSearch(search);

    return `/jobs/${id}${utmParams}`;
  }

  return `/jobs/${id}`;
};

// return valid create room arg with sender and receiver only when both values are defined
type GetUseGetOrCreateRoomArgValues = {
  receiverUserId?: number;
  senderUserId?: number;
};

type GetUseGetOrCreateRoomArgReturnType =
  | { receiverUserId: undefined; senderUserId: undefined }
  | { receiverUserId: number; senderUserId: number };

export const getUseGetOrCreateRoomArg = ({
  receiverUserId,
  senderUserId,
}: GetUseGetOrCreateRoomArgValues): GetUseGetOrCreateRoomArgReturnType => {
  if (!receiverUserId || !senderUserId) {
    return { senderUserId: undefined, receiverUserId: undefined };
  }

  return { senderUserId, receiverUserId };
};

export const generateRateOptions = (min: string, max: string) => {
  const minNumber = Number(min);
  const maxNumber = Number(max);

  if (minNumber === maxNumber) {
    const firstValue = Math.floor(minNumber * 0.8);
    const thirdValue = Math.floor(minNumber * 1.2);

    return [
      { label: `$${firstValue}`, value: firstValue },
      { label: `$${minNumber}`, value: minNumber },
      { label: `$${thirdValue}`, value: thirdValue },
    ].map((option) => ({ ...option, value: String(option.value) }));
  }

  const minMaxDifference = Math.floor((maxNumber - minNumber) * 100) / 100;

  if (minMaxDifference <= 0.04) {
    return [
      { label: `$${minNumber}`, value: minNumber },
      { label: `$${maxNumber}`, value: maxNumber },
    ].map((option) => ({ ...option, value: String(option.value) }));
  }

  const secondValue =
    minMaxDifference <= 2
      ? Math.floor((minNumber + (maxNumber - minNumber) * 0.25) * 100) / 100
      : Math.floor(minNumber + (maxNumber - minNumber) * 0.25);
  const thirdValue =
    minMaxDifference <= 2
      ? Math.floor((minNumber + (maxNumber - minNumber) * 0.75) * 100) / 100
      : Math.floor(minNumber + (maxNumber - minNumber) * 0.75);
  return [
    {
      label: `$${minNumber}`,
      value: minNumber,
    },
    {
      label: `$${secondValue}`,
      value: secondValue,
    },
    {
      label: `$${thirdValue}`,
      value: thirdValue,
    },
    {
      label: `$${maxNumber}`,
      value: maxNumber,
    },
  ].map((option) => ({ ...option, value: String(option.value) }));
};

export const getJobLoginAsUserId = (
  job: Pick<Job, "employer" | "creator">,
): number | undefined => {
  const jobOwner = job.creator;
  const loginAsEmployerUserId = job.employer.login_as_user_id;
  if (!jobOwner) {
    return loginAsEmployerUserId;
  }

  const jobOwnerId = jobOwner.id;
  const isUserRemoved = jobOwner?.account_type !== ENUMS.AccountType.EMPLOYER;
  if (isUserRemoved) {
    return loginAsEmployerUserId;
  }

  return jobOwnerId;
};
