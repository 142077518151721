import {
  type GridRenderCellParams,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { EmployerMyTalent } from "@js/types/employer";

type JobTitleCellProps = GridRenderCellParams<
  EmployerMyTalent,
  EmployerMyTalent["job_title"]
>;

export const JobTitleCell = ({ value, row }: JobTitleCellProps) => {
  return (
    <Typography
      component="link"
      RouterLink={RouterLink}
      variant="paragraph"
      to={`/jobs/${row.job_id}/`}
      size="medium"
      ellipsis
      className="underline-on-hover"
    >
      {value}
    </Typography>
  );
};
