import React, { useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import {
  Box,
  Popover,
  PopoverAnchorButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ThumbUpEmojiIcon } from "@hexocean/braintrust-ui-components/Icons";
import { OptionTileButtons } from "@js/components/option-tile-buttons";
import { DollarNumberField } from "@js/forms/fields";
import type { OptionWithPrimitiveValue } from "@js/types/common";
import { assertUnreachable, formatCurrency, shortenValue } from "@js/utils";

type PaymentAmountCustomFieldProps = TypedWrappedFieldProps<string> & {
  options: OptionWithPrimitiveValue[];
  renderLabel: PaymentAmountPopoverProps["renderLabel"];
  renderPopoverContent: PaymentAmountPopoverProps["renderPopoverContent"];
};

export const PaymentAmountCustomField = ({
  options,
  renderLabel,
  renderPopoverContent,
  ...props
}: PaymentAmountCustomFieldProps) => {
  const paymentAmount = props.input.value;

  const handleClick = (value: string): void => {
    const shouldResetValue = value === props.input.value;
    if (shouldResetValue) {
      props.input.onChange(null);

      return;
    }

    props.input.onChange(value);
  };

  return (
    <>
      <div>
        <OptionTileButtons
          className="rate-tile-buttons-wrapper--proposal-payment-amount"
          value={paymentAmount}
          onChange={(rate) => handleClick(rate as string)}
          options={options}
          renderCustomOptionButton={({ onClick, setActiveIndex }) => {
            return (
              <PaymentAmountPopover
                paymentAmount={paymentAmount}
                options={options}
                renderLabel={renderLabel}
                renderPopoverContent={renderPopoverContent}
                onClick={onClick}
                setActiveIndex={setActiveIndex}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export const getYesOrCustomPaymentAmountOptions = (defaultValue: string) => [
  {
    label: (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box mr={1}>
          <ThumbUpEmojiIcon />
        </Box>
        Yes
      </Box>
    ),
    value: defaultValue || "0",
  },
];

type PaymentAmountPopoverProps = {
  paymentAmount: string;
  options: OptionWithPrimitiveValue[];
  renderLabel: {
    initial: string;
    selected: (amount: string | number) => string;
  };
  renderPopoverContent: () => JSX.Element;
  onClick: () => void;
  setActiveIndex: (index: number) => void;
};

const PaymentAmountPopover = ({
  options,
  paymentAmount,
  renderPopoverContent,
  renderLabel,
  onClick,
  setActiveIndex,
}: PaymentAmountPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const predefinedOptionSelectedOrMatchingIndex = options.findIndex(
    (option) => Number(option.value) === Number(paymentAmount),
  );
  const isPredefinedOptionSelectedOrMatching =
    predefinedOptionSelectedOrMatchingIndex !== -1;
  const isCustomOptionSelected =
    (!!paymentAmount && !isPredefinedOptionSelectedOrMatching) || isOpen;

  const label = getLabel({
    renderLabel,
    isCustomOptionSelected,
    amount: paymentAmount,
  });

  const handleClose = () => {
    if (isPredefinedOptionSelectedOrMatching) {
      return setActiveIndex(predefinedOptionSelectedOrMatchingIndex);
    }

    if (isCustomOptionSelected) {
      return onClick();
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <Popover
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onOpenChange={handleOpenChange}
      onClose={handleClose}
      anchor={
        <PopoverAnchorButton
          isOpen={isOpen}
          isSelected={isCustomOptionSelected}
          onClick={onClick}
        >
          {label}
        </PopoverAnchorButton>
      }
    >
      {renderPopoverContent()}
    </Popover>
  );
};

export const renderDefaultPopoverContent = (paymentType) => {
  return (
    <Box maxWidth="170px" p={2}>
      <Typography
        mb={2}
        sx={{ fontSize: "0.7rem !important" }}
        fontWeight={400}
        size="small"
        component="h4"
        variant="label"
      >
        {getCustomRateLabel(paymentType)}
      </Typography>
      <Box display="flex" alignItems="center">
        <Field
          variant="outlined"
          id="payment_amount"
          name="payment_amount"
          inputProps={{
            decimalScale: 2,
          }}
          placeholder={getCustomRatePlaceholder(paymentType)}
          component={DollarNumberField}
        />
      </Box>
    </Box>
  );
};

const getLabel = ({
  renderLabel,
  isCustomOptionSelected,
  amount,
}: {
  renderLabel: {
    initial: string;
    selected: (amount: string | number) => string;
  };
  isCustomOptionSelected: boolean;
  amount: string;
}) => {
  if (!isCustomOptionSelected || !amount) {
    return renderLabel.initial;
  }

  return renderLabel.selected(
    shortenValue(
      formatCurrency(amount, { removeDecimal: +amount % 1 === 0 }) || "0",
      8,
    ),
  );
};

const getCustomRateLabel = (
  paymentType: EnumType<typeof ENUMS.JobPaymentType>,
) => {
  switch (paymentType) {
    case ENUMS.JobPaymentType.HOURLY: {
      return (
        <>
          Enter your <strong>hourly rate</strong>
        </>
      );
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      return (
        <>
          Enter your <strong>annual rate</strong>
        </>
      );
    }
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      return (
        <>
          Enter your <strong>fixed rate</strong>
        </>
      );
    }
    default: {
      assertUnreachable(paymentType);
      return "";
    }
  }
};

const getCustomRatePlaceholder = (
  paymentType: EnumType<typeof ENUMS.JobPaymentType>,
) => {
  switch (paymentType) {
    case ENUMS.JobPaymentType.HOURLY: {
      return "100";
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      return "100,000";
    }
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      return "";
    }
    default: {
      assertUnreachable(paymentType);
      return "";
    }
  }
};
