import React from "react";

import { Box, Button, Tooltip } from "@hexocean/braintrust-ui-components";
import { downloadInvoicesPDF } from "@js/apps/invoices/actions";
import { pluralize } from "@js/utils";

import {
  EmployerInvoicesGlobalActions,
  type EmployerInvoicesGlobalActionsProps,
} from "../employer-invoices-global-actions";

import styles from "./styles.module.scss";

type EmployerInvoicesActionsProps = {
  selectedInvoicesIds: number[];
} & Pick<
  EmployerInvoicesGlobalActionsProps,
  "markInvoiceAsPaid" | "startPaymentFlow" | "invoiceList"
>;

export const EmployerInvoicesActions = ({
  selectedInvoicesIds,
  invoiceList,
  markInvoiceAsPaid,
  startPaymentFlow,
}: EmployerInvoicesActionsProps) => {
  const selectedCount = selectedInvoicesIds.length;
  const handleDownloadInvoicesPdfClick = () => {
    downloadInvoicesPDF(
      selectedInvoicesIds.length ? { ids: selectedInvoicesIds } : undefined,
    );
  };

  return (
    <Box className={styles.actions}>
      <Box visibility={selectedCount ? "visible" : "hidden"} marginLeft={2}>
        {selectedCount} invoice{pluralize(selectedCount)} selected
      </Box>
      <Box display="flex" gap={1} flexWrap="wrap">
        <Tooltip
          title="Please select at least one invoice"
          disabled={selectedCount > 0}
        >
          <Box>
            <EmployerInvoicesGlobalActions
              selectedCount={selectedCount}
              selectedInvoicesIds={selectedInvoicesIds}
              invoiceList={invoiceList}
              markInvoiceAsPaid={markInvoiceAsPaid}
              startPaymentFlow={startPaymentFlow}
            />
          </Box>
        </Tooltip>
        <Tooltip
          disabled={selectedCount <= SETTINGS.INVOICES_IN_ZIP_MAX}
          title={`Maximum of ${SETTINGS.INVOICES_IN_ZIP_MAX} invoices can be downloaded at once. Please unselect some.`}
        >
          <Box>
            <Button
              variant="secondary"
              onClick={handleDownloadInvoicesPdfClick}
              disabled={
                selectedCount > SETTINGS.INVOICES_IN_ZIP_MAX ||
                selectedCount === 0
              }
              aria-label="Download invoices PDF"
              sx={{ whiteSpace: "nowrap" }}
            >
              Download PDF
            </Button>
          </Box>
        </Tooltip>
        <Button variant="primary" type="submit">
          Search
        </Button>
      </Box>
    </Box>
  );
};
