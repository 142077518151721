import { useUser } from "@js/apps/common/hooks";
import { useGetATSIntegrationsQuery } from "@js/apps/employer/api";
import {
  getAtsJobsMovedToDraftCount,
  getAtsJobsPublishedCount,
  getShouldShowPostImportInfoBar,
  toggleShowPostImportInfoBar,
} from "@js/apps/employer/components/ats-integration-modal/ats-jobs-slice";
import { useAppDispatch, useAppSelector } from "@js/hooks";

type BarVariants =
  | "all-published"
  | "partially-published-auto-fill"
  | "not-published-auto-fill"
  | "partially-published"
  | "not-published";

export const useATSInfoBar = (): {
  variant: BarVariants | undefined;
  open: boolean;
  close: () => void;
  publishedJobsCount: number;
  jobsAddedToDraft: number;
  jobsCount: number;
  integrationName: string;
} => {
  const user = useUser();
  const employerId = user?.employer;
  const { data: integrations } = useGetATSIntegrationsQuery(undefined, {
    skip: !employerId,
  });

  const integration = integrations?.[0];
  const shouldShowPostImportInfoBar = useAppSelector(
    getShouldShowPostImportInfoBar,
  );
  const jobsMovedToDraft = useAppSelector(getAtsJobsMovedToDraftCount);
  const publishedJobs = useAppSelector(getAtsJobsPublishedCount);
  const totalSelectedJobsCount = jobsMovedToDraft + publishedJobs;
  const dispatch = useAppDispatch();
  const toggleShowInfoBar = () => dispatch(toggleShowPostImportInfoBar());
  const atsBarVariantToShow = !!integration?.auto_fill_job_fields
    ? getBarAutoFillVariant(publishedJobs, totalSelectedJobsCount)
    : getBarVariant(publishedJobs, totalSelectedJobsCount);

  return {
    variant: atsBarVariantToShow,
    open: shouldShowPostImportInfoBar,
    close: toggleShowInfoBar,
    publishedJobsCount: publishedJobs,
    jobsAddedToDraft: jobsMovedToDraft,
    jobsCount: totalSelectedJobsCount,
    integrationName: integration?.integration || "ATS platform",
  };
};

const getBarVariant = (publishedJobs: number, totalJobs: number) => {
  if (publishedJobs === totalJobs) {
    return "all-published";
  }
  if (publishedJobs > 0 && publishedJobs < totalJobs) {
    return "partially-published";
  }
  if (publishedJobs === 0 && totalJobs > 0) {
    return "not-published";
  }
};

const getBarAutoFillVariant = (publishedJobs: number, totalJobs: number) => {
  if (publishedJobs === totalJobs) {
    return "all-published";
  }
  if (publishedJobs > 0 && publishedJobs < totalJobs) {
    return "partially-published-auto-fill";
  }
  if (publishedJobs === 0 && totalJobs > 0) {
    return "not-published-auto-fill";
  }
};
