import { useEffect, useMemo } from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { useFirstUnfinishedSection } from "@js/apps/freelancer/hooks";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { ModalInstance } from "@js/components/modal";

export const useProfileCompletionSequence = () => {
  const { data: profileCompletion } = useCurrentFreelancerProfileCompletion();
  const { isFreelancerApproved } = useAccountType();

  const firstUnfinishedSection = useFirstUnfinishedSection(profileCompletion);

  const currentStep = useMemo(() => {
    if (!firstUnfinishedSection) {
      return null;
    }
    return firstUnfinishedSection.type;
  }, [firstUnfinishedSection]);

  const isProfileCompleted = profileCompletion?.completion_percent === 100;
  const shouldCloseProfileCompletionSequence =
    isProfileCompleted || isFreelancerApproved;

  useEffect(() => {
    if (shouldCloseProfileCompletionSequence) {
      ModalInstance.close();
    }
  }, [shouldCloseProfileCompletionSequence]);

  return {
    currentStep,
  };
};
