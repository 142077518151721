import { canFreelancerUseJobRelatedFeatures } from "@js/routes/utils";
import type { User } from "@js/types/auth";

type MyWorkLinkPath =
  | "/talent/dashboard/my_jobs/history/"
  | "/talent/dashboard/my_jobs/invites/";

export const getFreelancerMyWorkLinkPath = (
  user: User | null,
): MyWorkLinkPath => {
  const canUseFeaturesRelatedToJob = canFreelancerUseJobRelatedFeatures(user);

  return `/talent/dashboard/my_jobs${canUseFeaturesRelatedToJob ? "/history/" : "/invites/"}`;
};
