import React from "react";
import classNames from "classnames";

import { Typography } from "@hexocean/braintrust-ui-components";
import { isSiteNotificationNew } from "@js/apps/notifications/components/site-notification/utils";
import { DotBadge } from "@js/components/dot-badge";
import { RouterLink } from "@js/components/link";
import { Sanitize } from "@js/components/sanitize";
import { TimeFromNow } from "@js/components/time-from-now";
import { useAppDispatch } from "@js/hooks";
import type { SiteNotification } from "@js/types/notifications";

import { siteNotificationMarkAsRead } from "../../actions";

type SiteNotificationProps = React.PropsWithChildren<{
  live?: boolean;
  className?: string;
  closeNotificationsPopup?: () => void;
  notification: SiteNotification;
}>;

export const SiteNotificationItem = ({
  notification,
  live = false,
  className = "",
  children = null,
  closeNotificationsPopup = () => {},
}: SiteNotificationProps) => {
  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    dispatch(siteNotificationMarkAsRead(notification.id));

    if (closeNotificationsPopup) {
      closeNotificationsPopup();
    }
  };

  return (
    <div className={classNames("site-notification", className)}>
      <RouterLink
        forceRemount
        to={notification.href || undefined}
        className="site-notification-content text-content"
        onClick={handleOnClick}
      >
        <DotBadge show={!live && isSiteNotificationNew(notification)}>
          <Sanitize
            className="site-notification-content__title"
            input={notification.content}
          />
        </DotBadge>
        {!live && (
          <Typography
            fontWeight={400}
            component="p"
            variant="paragraph"
            size="small"
            color="grey-1"
          >
            <TimeFromNow time={notification.created} />
          </Typography>
        )}
      </RouterLink>
      {children}
    </div>
  );
};
