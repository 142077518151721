import { PersonIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";
import { useHasFilterPopoverAnimationFinished } from "@js/hooks/use-has-filter-popover-animation-finished";

import { EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL } from "../../constants";
import { TalentForm } from "../forms";
import type { EmployerInvoicesFormValues } from "../schema";

type TalentFilterProps = {
  freelancers: EmployerInvoicesFormValues["freelancers"];
  onChange: (freelancer: EmployerInvoicesFormValues["freelancers"]) => void;
};

export const TalentFilter = ({ freelancers, onChange }: TalentFilterProps) => {
  const isFilterApplied = Boolean(freelancers?.length);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, hasPopoverAnimationFinished } =
    useHasFilterPopoverAnimationFinished();

  const handleApply = (
    newTalents: EmployerInvoicesFormValues["freelancers"],
  ) => {
    onChange(newTalents);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterPopover
      label={EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL.freelancers}
      icon={<PersonIcon />}
      isFilterApplied={isFilterApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
      variant="green"
    >
      <TalentForm
        freelancers={freelancers}
        onApply={handleApply}
        hasFilterPopoverFinishedAnimation={hasPopoverAnimationFinished}
      />
    </FilterPopover>
  );
};
