import cs from "classnames";

import { Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Datetime } from "@js/components/date";
import { formatTokenAmount, round } from "@js/utils";
import type { IsoDateTime } from "@js/utils/date/types";
import { DateTimeFormats } from "@js/utils/date/types";

import type { ActivityIconProps } from "./activity-icon";
import { ActivityIcon } from "./activity-icon";

export type ActivityItemModel = {
  label?: React.ReactNode;
  title: React.ReactNode;
  createdDate: IsoDateTime | null;
  completedDate?: IsoDateTime;
  iconVariant: ActivityIconProps["variant"];
  subtitle?: React.ReactNode;
  disabled?: boolean;
  frozen?: boolean;
  className?: string;
} & ActivityTransferProps;

export const ActivityItem = ({
  label,
  title,
  subtitle,
  createdDate,
  completedDate,
  transferAmount,
  transferType,
  disabled,
  iconVariant,
  frozen = false,
  className,
}: ActivityItemModel) => {
  const sm = useMediaQuery("sm");

  return (
    <div
      className={cs("activity-list-item", className, {
        "activity-list-item--disabled": disabled,
        "activity-list-item--frozen": frozen,
      })}
    >
      <div className="activity-list-item__details">
        <ActivityIcon variant={iconVariant} />
        <div className="activity-list-item__top-section">
          <Typography
            component="p"
            size={sm ? "medium" : "large"}
            className="activity-list-item__title"
          >
            <span className="activity-list-item__title-inner">{title}</span>
            {label}
            <CompletedDate completedAt={completedDate} />
          </Typography>
        </div>
        <Typography
          component="p"
          size={sm ? "small" : "medium"}
          className="activity-list-item__subtitle"
        >
          {!!subtitle && <>{subtitle} · </>}
          <span className="no-wrap">
            {createdDate ? (
              <Datetime
                date={createdDate}
                format={DateTimeFormats["Jan 01 1970 0:00 AM"]}
              />
            ) : null}
          </span>
        </Typography>
      </div>

      <ActivityTransfer
        transferAmount={transferAmount}
        transferType={transferType}
      />
    </div>
  );
};

const CompletedDate = ({ completedAt }: { completedAt?: IsoDateTime }) => {
  const isMobile = useMediaQuery(360);

  if (!completedAt) return null;

  return (
    <Typography
      component="span"
      variant="paragraph"
      size={isMobile ? "small" : "medium"}
      sx={{ color: "var(--dark-grey)" }}
      className="no-wrap"
    >
      at{" "}
      <Datetime
        date={completedAt}
        format={DateTimeFormats["Jan 01 1970 0:00 AM"]}
      />
    </Typography>
  );
};

type ActivityTransferType = "debit" | "credit" | "transfer";
type ActivityTransferProps = {
  transferAmount: number;
  transferType: ActivityTransferType;
};
const ActivityTransfer = ({
  transferAmount,
  transferType,
}: ActivityTransferProps) => {
  const color = getTransferColor(transferType);
  const prefix = getTransferPrefix(transferType);
  return (
    <Typography
      component="p"
      size="large"
      color={color}
      className="activity-list-item__amount no-wrap"
    >
      <>
        {!!prefix && <span>{prefix}</span>}
        <BraintrustIcon className="activity-list-item__bt-icon" />{" "}
        <Tooltip style={{ cursor: "pointer" }} title={transferAmount}>
          <span> {formatTokenAmount(round(transferAmount, 0.001))}</span>
        </Tooltip>
      </>
    </Typography>
  );
};

const getTransferColor = (transferType: ActivityTransferType) => {
  switch (transferType) {
    case "debit":
      return "negative";
    case "credit":
      return "positive";
    case "transfer":
      return;
    default: {
      transferType satisfies never;
    }
  }
};

const getTransferPrefix = (transferType: ActivityTransferType) => {
  switch (transferType) {
    case "debit":
      return "-";
    case "credit":
      return "+";
    case "transfer":
      return;
    default: {
      transferType satisfies never;
    }
  }
};
