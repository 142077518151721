import { useMemo, useState } from "react";
import _ from "underscore";
import uuid4 from "uuid4/browser";

import type {
  AutocompleteChangeReason,
  ComboBoxMultipleProps,
} from "@hexocean/braintrust-ui-components";
import { ComboBoxMultiple } from "@hexocean/braintrust-ui-components";
import {
  KeyboardArrowDownIcon,
  LocationPinSmallIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { CUSTOM_JOB_LOCATIONS_NEW_FORMAT } from "@js/apps/common/constants";
import { Snackbar } from "@js/components/snackbar";
import type { PlacesServicesTypes } from "@js/hooks/google-maps";
import { useGoogleMaps } from "@js/hooks/google-maps";

import { type LocationValue, LocationValueType } from "./types";

import styles from "./styles.module.scss";

export type GoogleComboBoxMultipleProps<DisableClearable extends boolean> =
  DistributiveOmit<
    ComboBoxMultipleProps<LocationValue, DisableClearable>,
    "onChange" | "options" | "initialTaxonomiesLoading" | "defaultValue"
  > & {
    onChange: (
      value: LocationValue[],
      reason: AutocompleteChangeReason,
    ) => void;
    placesServiceTypes?: PlacesServicesTypes;
    useCustomLocations?: boolean;
    locationIcon?: boolean;
  };

export const GoogleComboBoxMultiple = <DisableClearable extends boolean>({
  value: valueToMap,
  onChange,
  placesServiceTypes = "cities",
  useCustomLocations = false,
  locationIcon = true,
  ...props
}: GoogleComboBoxMultipleProps<DisableClearable>) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    getPlaceDetails,
  } = useGoogleMaps();
  const [sessionToken, setSessionToken] = useState(uuid4());

  const value = useMemo(() => {
    return (valueToMap || []).map((val) => {
      return { ...val, selected: true };
    });
  }, [valueToMap]);

  const options = useMemo((): LocationValue[] => {
    const mappedPredictions: LocationValue[] = placePredictions.map(
      (place) => ({
        location_type: LocationValueType.google,
        place_id: place.place_id,
        location: place.description,
      }),
    );

    const initial = !!value ? value : [];

    if (useCustomLocations) {
      const uniq = getUniqueOptions([
        ...initial,
        ...CUSTOM_JOB_LOCATIONS_NEW_FORMAT,
        ...mappedPredictions,
      ]);

      return uniq;
    } else {
      const uniq = getUniqueOptions([...initial, ...mappedPredictions]);

      return uniq;
    }
  }, [value, useCustomLocations, placePredictions]);

  return (
    <ComboBoxMultiple<LocationValue, DisableClearable>
      value={value}
      onInputChange={(_event, input, reason) => {
        if (reason === "reset") {
          return;
        }

        getPlacePredictions({
          input,
          types: placesServiceTypes,
          sessionToken,
        });
      }}
      onChange={(_event, newValue, reason) => {
        if (reason === "removeOption") {
          onChange([...newValue], reason);
          return;
        }

        const selectedValue = newValue.find((val) => !val?.selected); // selected value does not have place details
        const currentValues = newValue.filter((val) => !!val?.selected); // selected previously

        if (
          selectedValue &&
          selectedValue.location_type === LocationValueType.google
        ) {
          getPlaceDetails(
            {
              sessionToken,
              placeId: selectedValue.place_id,
            },
            (placeDetails) => {
              setSessionToken(uuid4());

              if (!placeDetails?.place_id || !placeDetails?.formatted_address) {
                Snackbar.error(
                  "Some place details is missing, please try set location again",
                );
                console.error(
                  "Missing place detail for: ",
                  selectedValue.place_id,
                );
                return;
              }

              onChange(
                [
                  ...currentValues,
                  {
                    location_type: LocationValueType.google,
                    place_id: placeDetails.place_id,
                    location: placeDetails.formatted_address,
                  },
                ],
                reason,
              );
            },
          );
          return;
        }

        onChange([...newValue], reason);
      }}
      loading={isPlacePredictionsLoading}
      classes={
        locationIcon
          ? { popupIndicatorOpen: styles.popupIndicatorOpen }
          : undefined
      } // Prevent location icon from rotating on open
      popupIcon={
        locationIcon ? <LocationPinSmallIcon /> : <KeyboardArrowDownIcon />
      }
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }

        const optionType = option.location_type;
        switch (optionType) {
          case LocationValueType.custom:
          case LocationValueType.google: {
            return option.location;
          }
          default: {
            optionType satisfies never;
            return "";
          }
        }
      }}
      filterOptions={(opts, { inputValue }) => {
        const filteredOptions = opts.filter((option) => {
          const isCustom = option?.location_type === LocationValueType.custom;
          /**
           * Initial value needs to be in options list to fix MUI warning,
           * but we don't want to display it in options list as it is faked object (not compatible with the backend).
           */
          const isInitial = !!option?.selected;

          if (inputValue) {
            return !isCustom && !isInitial;
          }

          return isCustom;
        });

        return filteredOptions;
      }}
      isOptionEqualToValue={(option, val) => {
        if (
          option?.location_type === LocationValueType.custom &&
          val?.location_type === LocationValueType.custom
        ) {
          return option.custom_location === val?.custom_location;
        }

        if (
          option?.location_type === LocationValueType.google &&
          val?.location_type === LocationValueType.google
        ) {
          return option?.place_id === val?.place_id;
        }

        return false;
      }}
      {...props}
      options={options}
      initialTaxonomiesLoading={false}
      multiple={true}
    />
  );
};

const getUniqueOptions = (array: LocationValue[]): LocationValue[] => {
  const opt = array.map(mapWithUniqId);

  const uniq = _.uniq(opt, "unique_id").map((option) => {
    return _.omit(option, "unique_id");
  });

  return uniq;
};

const mapWithUniqId = (
  val: LocationValue,
): LocationValue & { unique_id: string } => {
  const id =
    val?.location_type === LocationValueType.custom
      ? val.custom_location
      : val?.place_id;

  return {
    ...val,
    unique_id: id,
  };
};
