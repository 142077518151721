import URI from "urijs";

import { paramsToObject } from "@js/apps/common/filters";

import { clearEmpties } from "../data";
import { stripTrailingSlash } from "../string";

export const isValidUrl = (string) => {
  try {
    return !!new URL(string);
  } catch (_) {
    return false;
  }
};

export const isValidUrlRegex = (url: string) => {
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return urlRegex.test(url);
};

export const deleteQueryStringParameters = (
  queryParams: string[] | string,
  url = window.location.href,
) => {
  const uri = new URI(url);
  return uri.removeSearch(queryParams).toString();
};

export const updateQueryStringParameter = (key, value, url) => {
  // Credits: https://stackoverflow.com/a/6021027/2668489
  const uri = url || window.location.href.replace(window.location.origin, "");
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";

  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  } else {
    return `${uri}${separator}${key}=${value}`;
  }
};

export const getQuery = (queryParam: string, url = window.location.href) => {
  const currentURL = new URI(url);

  if (currentURL.hasQuery(queryParam)) {
    const queryValues = currentURL.query(true);

    if (queryParam === "back" || queryParam === "prevPath") {
      const backParamValue = queryValues[queryParam] as string;

      try {
        if (
          decodeURIComponent(backParamValue).indexOf(
            "/download_attachment/",
          ) !== -1
        ) {
          return "/";
        } else if (isValidUrl(backParamValue)) {
          if (new URL(backParamValue).origin !== location.origin) {
            return null;
          }
        }
      } catch (error) {
        return "/";
      }
    }

    return queryValues[queryParam] || "";
  }

  return null;
};

export const getURL = (where) => {
  return where.replace(window.location.origin, "");
};

export const getURLWithBackLink = (path) => {
  const where = updateQueryStringParameter(
    "back",
    encodeURIComponent(
      window.location.href.replace(window.location.origin, ""),
    ),
    path,
  );

  return where.replace(window.location.origin, "");
};

export const replaceUrl = (newUrl: string) =>
  window.history.replaceState({ path: newUrl }, "", newUrl);

export const getCurrentPath = () =>
  location.pathname + location.search + location.hash;

export const getCurrentPathEncoded = () => encodeURIComponent(getCurrentPath());

// https://stackoverflow.com/a/24006120/5908844
type TrimUrlOptions = {
  trimSearchParams?: boolean;
};

export const trimURL = (href, options?: TrimUrlOptions) => {
  let url;
  try {
    url = new URL(href);
  } catch {
    try {
      // to cover case with link like : 'talent/example' instead of '/talent/example'
      url = new URL(href, "http://www.example.com/");
    } catch {
      return href;
    }
  }

  return stripTrailingSlash(
    url.pathname + (options?.trimSearchParams ? "" : url.search) + url.hash,
  );
};

/** serializers object properties into querystring format, joining array elements with commas */
export const serializeArrayParams = (params: Record<string, unknown>) => {
  return serializeArrayParamsBy(params);
};

export const serializeArrayParamsBy = (
  params: Record<string, unknown>,
  joinBy: string = ",",
) => {
  return Object.entries(params)
    .map(([key, value]) => {
      if (key) {
        if (Array.isArray(value)) {
          return `${key}=${value.join(joinBy)}`;
        }

        return `${key}=${value}`;
      }

      return undefined;
    })
    .join("&");
};

const UTM_PARAM_PREFIX = "utm";

export const getUTMQueryParamsSearch = (search: string): string => {
  const currentQueryParams = new URLSearchParams(search);
  const utmParamsObject = Object.fromEntries(
    [...currentQueryParams.entries()].filter(([key]) =>
      key.startsWith(UTM_PARAM_PREFIX),
    ),
  );

  return new URLSearchParams(utmParamsObject).toString();
};

export const normalizePath = (path: string) => {
  // Remove trailing slash if present
  return path.replace(/\/$/, "");
};

/** @param options.skipCurrentParams in case you would like to skip params set outside of the form like `page`, `ordering` */
export const buildSearchParams = (
  newFilters: Record<string, unknown>,
  options?: {
    skipCurrentParams?: boolean;
  },
) => {
  const { skipCurrentParams } = {
    skipCurrentParams: false,
    ...options,
  };

  const currentParams = skipCurrentParams
    ? {}
    : paramsToObject(window.location.search);

  const searchParams = new URLSearchParams(
    clearEmpties({
      ...currentParams,
      ...newFilters,
      page: undefined,
    }) as Record<string, string>,
  );

  return searchParams;
};
