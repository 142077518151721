import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import type { HelpOffer } from "@js/types/give-and-get-help";
import { matchFontColorToBackgroundColor } from "@js/utils";

export const HELP_OFFER_META_STATUS = {
  PENDING_APPROVAL: "PENDING_APPROVAL",
  REPORTED: "REPORTED",
  REFUND_REQUESTED: "REFUND_REQUESTED",
} as const;

const HELP_OFFER_META_STATUS_LABELS = {
  PENDING_APPROVAL: "Pending approval",
  REPORTED: "Reported",
  REFUND_REQUESTED: "Refund requested",
};

type HelpOfferStatusTagStatus =
  | HelpOffer["status"]
  | EnumType<typeof HELP_OFFER_META_STATUS>;

type HelpOfferStatusTagProps = {
  status: HelpOfferStatusTagStatus;
};

export const HelpOfferStatusTag = ({ status }: HelpOfferStatusTagProps) => {
  const statusBgColors: Record<HelpOfferStatusTagStatus, string> = {
    [ENUMS.HelpOfferStatus.PENDING]: "--medium-yellow",
    [ENUMS.HelpOfferStatus.DECLINED]: "--medium-grey",
    [ENUMS.HelpOfferStatus.ACCEPTED]: "--medium-green",
    [ENUMS.HelpOfferStatus.COMPLETED]: "--medium-violet",
    [HELP_OFFER_META_STATUS.REFUND_REQUESTED]: "--medium-red",
    [HELP_OFFER_META_STATUS.PENDING_APPROVAL]: "--medium-blue",
    [HELP_OFFER_META_STATUS.REPORTED]: "--medium-red",
    [ENUMS.HelpOfferStatus.CREATED]: "",
    [ENUMS.HelpOfferStatus.EXPIRED]: "",
    [ENUMS.HelpOfferStatus.DELETED]: "",
  };

  const bgColor = statusBgColors[status];
  const fontColor = matchFontColorToBackgroundColor(bgColor) || "--grey-1";
  const label = getHelpOfferStatusLabel(status);

  return (
    <Box
      className="dashboard-help-offer-card__offer-status-tag"
      bgcolor={`var(${bgColor})`}
      color={`var(${fontColor})`}
      fontWeight={500}
    >
      {label}
    </Box>
  );
};

const getHelpOfferStatusLabel = (status: HelpOfferStatusTagStatus) => {
  switch (status) {
    case ENUMS.HelpOfferStatus.PENDING:
    case ENUMS.HelpOfferStatus.DECLINED:
    case ENUMS.HelpOfferStatus.ACCEPTED:
    case ENUMS.HelpOfferStatus.COMPLETED:
    case ENUMS.HelpOfferStatus.CREATED:
    case ENUMS.HelpOfferStatus.EXPIRED:
    case ENUMS.HelpOfferStatus.DELETED:
      return ENUMS.HelpOfferStatusLabels[status];

    case HELP_OFFER_META_STATUS.REFUND_REQUESTED:
    case HELP_OFFER_META_STATUS.PENDING_APPROVAL:
    case HELP_OFFER_META_STATUS.REPORTED:
      return HELP_OFFER_META_STATUS_LABELS[status];

    default:
      return "";
  }
};
