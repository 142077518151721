import React, { useMemo } from "react";
import { omit } from "underscore";

import type { AvatarSrc } from "@hexocean/braintrust-ui-components";
import {
  Avatar,
  Box,
  Button,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CheckIcon,
  CloseIcon,
  DownloadCloudIcon,
  VerifiedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useGetJobApplicationInsightsQuery } from "@js/apps/jobs/api";
import { InfoTooltip } from "@js/components/info-tooltip";
import { useAppSelector } from "@js/hooks";
import type { Job } from "@js/types/jobs";

import { openInsightPurchaseModal } from "../../application-insight-purchase-modal";
import {
  formatMatchLabel,
  getMatchLabelColors,
  useMatchPlacement,
} from "../../avatar-with-score-match-badge/utils";

const PREVIEW_INSIGHTS = [
  "Selectively applying to jobs right now",
  "Top candidate for multiple past job positions",
];

type ApplicationInsightsProps = {
  job: Job;
};

const ApplicationInsights: React.FC<ApplicationInsightsProps> = ({ job }) => {
  const user = useAppSelector((state) => state.auth.user);
  const avatarSrc = useMemo(
    () => omit(user, "account_type") as AvatarSrc,
    [user],
  );
  const matchLabelStyleProps = getMatchLabelColors(job.match_label, true);
  const { isLoading, data } = useGetJobApplicationInsightsQuery({ id: job.id });
  const { label: matchPlacementLabel, summaryType: matchLabelSummaryType } =
    useMatchPlacement(job.match_label, data?.total_applicants || 0);
  const canViewInsights = useMemo(
    () => user?.can_view_insights,
    [user?.can_view_insights],
  );
  const canShowPreview = useMemo(
    () => !user?.can_view_insights && user?.freelancer_approved,
    [user?.can_view_insights, user?.freelancer_approved],
  );
  const hasTopSkills = !data?.skills.includes("0") || false;
  const [currentSkills, totalSkills] = data?.skills.split("/") || [0, 0];

  return isLoading || !job.match_label ? null : (
    <Box
      sx={{ background: "var(--white)" }}
      display="flex"
      flexDirection="column"
      gap={2}
      borderRadius={4}
      mb={3}
      px={4}
      pt={4}
      pb={canViewInsights ? 4 : 2}
    >
      <Box display="flex" alignItems="center">
        <Typography component="p" size="medium" fontWeight={500}>
          Application Insights
        </Typography>
        {!canViewInsights && (
          <InfoTooltip
            title={
              "Gain personalized job analysis with Application Insights, identifying promising opportunities and leveraging feedback to enhance your job search strategy."
            }
          />
        )}
      </Box>
      <Box display="flex" gap={1} alignItems="center">
        <Box
          px={1}
          sx={{ backgroundColor: matchLabelStyleProps.backgroundColor }}
          borderRadius="100px"
          height="fit-content"
          width="fit-content"
        >
          <Typography
            sx={{ color: matchLabelStyleProps.textColor }}
            component="p"
            size="small"
            fontWeight={500}
          >
            {formatMatchLabel(job.match_label)}
          </Typography>
        </Box>
        <Box display="flex">
          <VerifiedIcon sx={{ alignSelf: "end", fontSize: "32px" }} />
          <Avatar
            src={avatarSrc}
            sx={{
              height: 29,
              width: 29,
              marginLeft: -1,
              border: "2px solid var(--white)",
            }}
          />
        </Box>
      </Box>
      <Box>
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          gap={1}
          {...(canShowPreview && {
            sx: {
              "&::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "57px",
                backdropFilter: "blur(8px)",
                "-webkit-backdrop-filter": "blur(5px)",
                pointerEvents: "none",
              },
            },
          })}
        >
          {(canShowPreview || canViewInsights) && (
            <Box display="flex" gap={1} alignItems="center">
              {hasTopSkills ? (
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                />
              )}
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                {currentSkills} of {totalSkills} top skills for this job
              </Typography>
            </Box>
          )}
          {canViewInsights && (
            <Box display="flex" gap={1}>
              {matchLabelSummaryType === "positive" ? (
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                />
              )}
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                {matchPlacementLabel}
              </Typography>
            </Box>
          )}
          {canViewInsights && (
            <>
              {Array.isArray(data?.match_summary) &&
                data?.match_summary?.map(({ sentence, summary_type }) => (
                  <Box key={sentence} display="flex" gap={1}>
                    {summary_type === "positive" ? (
                      <CheckIcon
                        sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                      />
                    ) : (
                      <CloseIcon
                        sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                      />
                    )}
                    <Typography
                      sx={{ color: "var(--black)" }}
                      component="p"
                      variant="paragraph"
                      size="small"
                    >
                      {sentence}
                    </Typography>
                  </Box>
                ))}
              <Box display="flex" gap={1}>
                <DownloadCloudIcon
                  sx={{ color: "var(--black)", fontSize: "20px" }}
                />
                <Typography
                  sx={{ color: "var(--black)" }}
                  component="p"
                  variant="paragraph"
                  size="small"
                >
                  Please wait while we gather more insights.
                </Typography>
              </Box>
            </>
          )}
          {canShowPreview &&
            PREVIEW_INSIGHTS.map((insight) => (
              <Box key={insight} display="flex" gap={1} alignItems="center">
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
                <Typography component="p" variant="paragraph" size="small">
                  {insight}
                </Typography>
              </Box>
            ))}
        </Box>
        {!canViewInsights && (
          <Box textAlign="center" marginTop={canShowPreview ? "-23px" : 0}>
            <Button
              onClick={openInsightPurchaseModal}
              sx={{
                textWrap: "nowrap",
                marginBlock: "auto",
                width: "fit-content",
                paddingY: "7px !important",
              }}
              variant="positive-2"
              shape="squared"
              size="medium"
              endIcon={<ArrowRightIcon />}
            >
              Unlock powerful insights
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ApplicationInsights;
