import { useMemo } from "react";

import type { GridSortModel } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { GetEmployerBatchInvoicesParamsOrderBy } from "@js/apps/invoices/types";
import type { InvoiceBatch } from "@js/types/invoices";

import { EmployerInvoicesTableCommon } from "../employer-invoices-table-common";

import { getEmployerInvoiceBatchesTableColumns } from "./columns";

import styles from "./styles.module.scss";

const columns = getEmployerInvoiceBatchesTableColumns();

export type EmployerInvoiceBatchesTableComponentProps = {
  invoiceBatches: InvoiceBatch[];
  sortBy: GetEmployerBatchInvoicesParamsOrderBy | undefined;
  sortDir: "asc" | "desc" | undefined;
  onSortChange: (arg: {
    sortBy: GetEmployerBatchInvoicesParamsOrderBy | undefined;
    sortDir: "asc" | "desc" | undefined;
  }) => void;
};

export const EmployerInvoiceBatchesTableComponent = ({
  invoiceBatches,
  sortBy,
  sortDir,
  onSortChange,
}: EmployerInvoiceBatchesTableComponentProps) => {
  const sortModel = useMemo<
    [{ field: string; sort: "asc" | "desc" }] | []
  >(() => {
    if (!sortBy || !sortDir) {
      return [];
    }

    return [{ field: sortBy, sort: sortDir }];
  }, [sortBy, sortDir]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const [sortObject] = newSortModel || [];
    onSortChange({
      sortBy: sortObject?.field as
        | GetEmployerBatchInvoicesParamsOrderBy
        | undefined,
      sortDir: sortObject?.sort ?? undefined,
    });
  };

  const invoiceBatchesWithId = useMemo(() => {
    return invoiceBatches.map((invoiceBatch) => {
      return {
        ...invoiceBatch,
        id: invoiceBatch.pdf_file.id || invoiceBatch.summary_page_pdf_file?.id,
      };
    });
  }, [invoiceBatches]);

  return (
    <EmployerInvoicesTableCommon
      className={styles.invoiceBatchesTable}
      columns={columns}
      rows={invoiceBatchesWithId}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      disableMultipleRowSelection
    />
  );
};
