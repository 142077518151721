import { API } from "@js/api";
import type { RootState } from "@js/store";
import type {
  EmployerBid,
  InitialOffer,
  SaveOptionalFeesProps,
} from "@js/types/jobs";
import { typeGuard } from "@js/utils";

import {
  getAllFetchBidsArgs,
  patchBidOnHideToggle,
  patchBidOnMatcherRecommendedToggle,
  patchBidOnReactionUpdate,
  patchBidOnViewedUpdate,
  patchBidsOnHideToggle,
  patchBidsOnMatcherRecommendedToggle,
  patchBidsOnReactionUpdate,
  patchBidsOnViewedUpdate,
} from "./helpers";
import type {
  BidInterviewIntendedParams,
  FetchBidParams,
  FetchBidsParams,
  FetchBidsResponse,
  FetchInitialOfferParams,
  GetBidRecommendedLocationsResponse,
  HideProposalParams,
  HideProposalsParams,
  MarkBidAsViewedParams,
  MarkCalendarViewedParams,
  UnrejectBidParams,
  UpdateBidReactionParams,
  UpdateBidStatusParams,
  UpdateFeedbackParams,
  UpdateMatcherRecommendationParams,
} from "./types";

export const bidApi = API.injectEndpoints({
  endpoints: (build) => ({
    unRejectBid: build.mutation<void, UnrejectBidParams>({
      query: ({ bidId }) => ({
        url: `/employer_bids/${bidId}/unreject/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
      ],
    }),

    fetchBids: build.query<FetchBidsResponse, FetchBidsParams>({
      query: (args) => {
        const ids = args?.ids?.length ? args.ids.join(",") : undefined;
        return {
          url: "/employer_bids/",
          method: "GET",
          params: { ...args, ids },
        };
      },
      providesTags: [{ type: "EmployerBids", id: "LIST" }],
    }),

    markBidAsViewed: build.mutation<void, MarkBidAsViewedParams>({
      query: ({ bidId }) => ({
        url: `/employer_bids/${bidId}/mark_as_viewed/`,
        method: "POST",
      }),
      async onQueryStarted({ bidId }, { dispatch, getState, queryFulfilled }) {
        const state = getState() as RootState;
        const patchResults: Array<{ undo: () => void }> = [];

        const allFetchBidsArgs = getAllFetchBidsArgs(state);
        allFetchBidsArgs.forEach((fetchBidsArg) => {
          const bidsPatchResult = dispatch(
            bidApi.util.updateQueryData("fetchBids", fetchBidsArg, (draft) => {
              patchBidsOnViewedUpdate({ draft, bidId });
            }),
          );

          patchResults.push(bidsPatchResult);
        });

        const bidPatchResult = dispatch(
          bidApi.util.updateQueryData("fetchBid", { bidId }, (draft) => {
            patchBidOnViewedUpdate({ bid: draft });
          }),
        );

        patchResults.push(bidPatchResult);

        try {
          await queryFulfilled;
        } catch (e) {
          if (
            typeGuard<unknown, { error: unknown }>(e, "error") &&
            typeGuard<unknown, { status: number }>(e.error, "status") &&
            e.error.status === 400
          ) {
            // do not undo patches in case of status 400 as it means bid is already viewed ...
            // ... so we can leave UI updated
            return;
          }

          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
    }),

    updateEmployerBidSection: build.mutation<void, UpdateBidStatusParams>({
      query: ({ bidId, status }) => ({
        url: `/employer_bids/${bidId}/update_status/`,
        method: "POST",
        data: { status },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
      ],
    }),

    updateEmployerBidInterviewSection: build.mutation<
      void,
      { bidId: number; status: EnumType<typeof ENUMS.JobInterviewStatus> }
    >({
      query: ({ bidId, status }) => ({
        url: `/employer_bids/${bidId}/update_interview_status/`,
        method: "POST",
        data: { status },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
        { type: "EmployerBids", id: "LIST" },
      ],
    }),

    resendEmployerBidInterview: build.mutation<void, { bidId: number }>({
      query: ({ bidId }) => ({
        url: `/employer_bids/${bidId}/resend_ai_interview_request/`,
        method: "POST",
        data: {},
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
        { type: "EmployerBids", id: "LIST" },
      ],
    }),

    updateEmployerBidFeedback: build.mutation<void, UpdateFeedbackParams>({
      query: ({ bidId, feedback }) => ({
        url: `/employer_bids/${bidId}/update_feedback/`,
        method: "POST",
        data: { ...feedback },
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
      ],
    }),

    updateEmployerBidInBulk: build.mutation<
      {
        id: number;
        result: string;
        status: string;
      }[],
      { status: string; pks: number[] }
    >({
      query: ({ status, pks }) => ({
        url: `/employer_bids/update_status_bulk/`,
        method: "POST",
        data: { status, pks },
      }),
      invalidatesTags: [{ type: "EmployerBids" as const, id: "LIST" }],
    }),

    fetchBid: build.query<EmployerBid, FetchBidParams>({
      query: ({ bidId }) => ({
        url: `/employer_bids/${bidId}/`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
      ],
      keepUnusedDataFor: 1,
    }),

    markCalendarViewed: build.mutation<void, MarkCalendarViewedParams>({
      query: ({ bidId }) => ({
        url: `/employer_bids/${bidId}/mark_calendar_as_viewed/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
      ],
    }),

    saveOptionalFees: build.mutation<void, SaveOptionalFeesProps>({
      query: ({ bidId, fees }) => ({
        url: `/employer_bids/${bidId}/update_fees/`,
        method: "POST",
        data: fees,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerBids", id: arg.bidId },
      ],
    }),

    hideProposal: build.mutation<void, HideProposalParams>({
      query: ({ bidId, isHidden }) => ({
        url: `/employer_bids/${bidId}/change_visibility/`,
        method: "POST",
        data: { is_hidden: isHidden },
      }),
      async onQueryStarted(
        { bidId, isHidden },
        { dispatch, getState, queryFulfilled },
      ) {
        const state = getState() as RootState;
        const patchResults: Array<{ undo: () => void }> = [];

        const allFetchBidsArgs = getAllFetchBidsArgs(state);
        allFetchBidsArgs.forEach((fetchBidsArg) => {
          const bidsPatchResult = dispatch(
            bidApi.util.updateQueryData("fetchBids", fetchBidsArg, (draft) => {
              patchBidsOnHideToggle({ draft, bidId, isHidden });
            }),
          );

          patchResults.push(bidsPatchResult);
        });

        const bidPatchResult = dispatch(
          bidApi.util.updateQueryData("fetchBid", { bidId }, (draft) => {
            patchBidOnHideToggle({ bid: draft, isHidden });
          }),
        );

        patchResults.push(bidPatchResult);

        try {
          await queryFulfilled;
        } catch (e) {
          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
    }),

    hideProposals: build.mutation<void, HideProposalsParams>({
      query: ({ bidIds, isHidden }) => ({
        url: `/employer_bids/change_visibility_bulk/`,
        method: "POST",
        data: { is_hidden: isHidden, pks: bidIds },
      }),
      invalidatesTags: ["EmployerBids"],
    }),

    updateBidReaction: build.mutation<void, UpdateBidReactionParams>({
      query: ({ bidId, reaction }) => ({
        url: `/employer_bids/${bidId}/update_reaction/`,
        method: "POST",
        data: { reaction },
      }),
      async onQueryStarted(
        { bidId, reaction },
        { dispatch, getState, queryFulfilled },
      ) {
        const state = getState() as RootState;
        const patchResults: Array<{ undo: () => void }> = [];

        const allFetchBidsArgs = getAllFetchBidsArgs(state);
        allFetchBidsArgs.forEach((fetchBidsArg) => {
          const bidsPatchResult = dispatch(
            bidApi.util.updateQueryData("fetchBids", fetchBidsArg, (draft) => {
              patchBidsOnReactionUpdate({ draft, bidId, reaction });
            }),
          );

          patchResults.push(bidsPatchResult);
        });

        const bidPatchResult = dispatch(
          bidApi.util.updateQueryData("fetchBid", { bidId }, (draft) => {
            patchBidOnReactionUpdate({ bid: draft, reaction });
          }),
        );

        patchResults.push(bidPatchResult);

        try {
          await queryFulfilled;
        } catch (e) {
          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
    }),

    bidInterviewIntended: build.mutation<void, BidInterviewIntendedParams>({
      query: ({ bidId }) => ({
        url: `/employer_bids/${bidId}/interview_intended/`,
        method: "POST",
      }),
    }),

    updateMatcherRecommendation: build.mutation<
      void,
      UpdateMatcherRecommendationParams
    >({
      query: ({ bidId, isRecommended }) => ({
        url: `/employer_bids/${bidId}/update_matcher_recommendation/`,
        method: "POST",
        data: { recommended_by_matcher: isRecommended },
      }),
      invalidatesTags: (_result, _error, arg) => {
        const shouldInvalidateBid = !arg.isRecommended;
        if (!shouldInvalidateBid) {
          return [{ type: "EmployerBids", id: "LIST" }];
        }

        return [
          { type: "EmployerBids", id: "LIST" },
          { type: "EmployerBids", id: arg.bidId },
        ];
      },
      async onQueryStarted(
        { bidId, isRecommended },
        { dispatch, getState, queryFulfilled },
      ) {
        const state = getState() as RootState;
        const patchResults: Array<{ undo: () => void }> = [];

        const allFetchBidsArgs = getAllFetchBidsArgs(state);
        allFetchBidsArgs.forEach((fetchBidsArg) => {
          const bidsPatchResult = dispatch(
            bidApi.util.updateQueryData("fetchBids", fetchBidsArg, (draft) => {
              patchBidsOnMatcherRecommendedToggle({
                draft,
                bidId,
                isRecommended,
              });
            }),
          );

          patchResults.push(bidsPatchResult);
        });

        const bidPatchResult = dispatch(
          bidApi.util.updateQueryData("fetchBid", { bidId }, (draft) => {
            patchBidOnMatcherRecommendedToggle({ bid: draft, isRecommended });
          }),
        );

        patchResults.push(bidPatchResult);

        try {
          await queryFulfilled;
        } catch (e) {
          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
    }),
    fetchInitialOffer: build.query<InitialOffer, FetchInitialOfferParams>({
      query: ({ bidId }) => ({
        url: `/employer_bids/${bidId}/offer_initial_data/`,
        method: "GET",
      }),
    }),
    getBidRecommendedLocations: build.query<
      GetBidRecommendedLocationsResponse,
      {
        jobId: number;
      }
    >({
      query: ({ jobId }) => ({
        url: `/employer_jobs_minimal/${jobId}/bids_locations`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [
        { type: "JobBidsLocations", id: arg.jobId },
      ],
    }),
  }),
});

export const {
  useUnRejectBidMutation,
  useBidInterviewIntendedMutation,
  useFetchBidsQuery,
  useHideProposalMutation,
  useHideProposalsMutation,
  useMarkBidAsViewedMutation,
  useUpdateBidReactionMutation,
  useUpdateEmployerBidFeedbackMutation,
  useUpdateEmployerBidInBulkMutation,
  useSaveOptionalFeesMutation,
  useUpdateEmployerBidSectionMutation,
  useUpdateEmployerBidInterviewSectionMutation,
  useResendEmployerBidInterviewMutation,
  useUpdateMatcherRecommendationMutation,
  useFetchBidQuery,
  useFetchInitialOfferQuery,
  useGetBidRecommendedLocationsQuery,
} = bidApi;

export const useFetchInitialOfferQueryState =
  bidApi.endpoints.fetchInitialOffer.useQueryState;
