import { useRequestHelpOfferRefundMutation } from "@js/apps/give-and-get-help/api";
import type { RequestRefundModalProps } from "@js/apps/give-and-get-help/components/request-refund-modal/request-refund-modal";
import { RequestOfferRefundFormModal } from "@js/apps/give-and-get-help/components/request-refund-modal/request-refund-modal";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

type UseHelpOfferRefundArgs = RequestRefundModalProps & {
  offerId: number;
};

export const useHelpOfferRefund = ({
  helperFirstName,
  offer,
  offerId,
}: UseHelpOfferRefundArgs) => {
  const [requestOfferRefund] = useRequestHelpOfferRefundMutation();

  const handleHelpOfferRefund = () => {
    ModalInstance.open({
      className: "request-refund-modal",
      closeButton: true,
      closeButtonProps: {
        variant: "tertiary",
        size: "x-small",
      },
      children: (
        <RequestOfferRefundFormModal
          onSubmit={({
            refund_requested_reason,
          }: {
            refund_requested_reason: string;
          }) => {
            requestOfferRefund({
              offerId,
              data: { refund_requested_reason },
            })
              .unwrap()
              .then(() => {
                Snackbar.success("Refund requested.");
              })
              .catch(() => Snackbar.error("Failed to request refund."));
          }}
          onSubmitSuccess={() => {
            ModalInstance.close();
          }}
          helperFirstName={helperFirstName}
          offer={offer}
        />
      ),
    });
  };

  return handleHelpOfferRefund;
};
