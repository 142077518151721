import cs from "classnames";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { CircleCheckOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import { BarCloseButton } from "./bar-close-button";
import { BarCtaButton } from "./bar-cts-button";

import style from "./style.module.scss";

type PartiallyPublishedAutoFillBarProps = {
  jobsCount: number;
  publishedJobsCount: number;
  onClose: () => void;
  integrationName: string;
};

export const PartiallyPublishedAutoFillBar = ({
  jobsCount,
  publishedJobsCount,
  onClose,
  integrationName,
}: PartiallyPublishedAutoFillBarProps) => {
  return (
    <Box className={cs(style.atsInfoBar, style.positive)}>
      <Box className={style.atsInfoBarHeader}>
        <CircleCheckOutlineIcon
          className={cs(
            style.atsInfoBarHeaderIcon,
            style.atsInfoBarHeaderIconPositive,
          )}
        />
        <Typography
          component="h2"
          fontWeight={500}
          className={style.atsInfoBarHeaderText}
        >
          Success! {publishedJobsCount} out of {jobsCount} {integrationName}{" "}
          jobs have been published to Braintrust!
        </Typography>
        <BarCloseButton onClose={onClose} />
      </Box>
      <Typography
        component="p"
        size="large"
        className={style.atsInfoBarTextContent}
      >
        Your remaining jobs have been moved to your{" "}
        <Typography
          component="link"
          variant="link"
          to="#job-drafts"
          size="large"
          RouterLink={RouterLink}
        >
          Job drafts
        </Typography>
        . We were unable to auto-fill certain job fields. Please review and edit
        your drafts before publishing it on Braintrust.
      </Typography>
      <Box className={style.atsInfoBarCtaContainer}>
        <BarCtaButton />
      </Box>
    </Box>
  );
};
