import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ComboBoxMultiple,
  PaperWithVioletOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useGetRolesQuery } from "@js/apps/roles/api";
import type { RhfForm } from "@js/rhf/types";
import type { Role } from "@js/types/roles";

import { FilterButtonPopoverFooter } from "../filter-button-popover-footer";
import { FilterPopoverFormLayoutWrapper } from "../filter-popover-form-layout-wrapper";
import { useInitiallyOpenDropdown } from "../hooks";
import { employerMyTalentRoleFormSchema } from "../schemas";

import { EmployerMyTalentFiltersAutocomplete } from "./employer-my-talent-filters-autocomplete";

type Form = RhfForm<typeof employerMyTalentRoleFormSchema>;
type FormValues = Form["Values"];
type DefaultFormValues = Form["DefaultValues"];

type EmployerMyTalentRoleFormProps = {
  roles: DefaultFormValues["roles"];
  onApply: (roles: FormValues["roles"]) => void;
  shouldOpenDropdown: boolean;
};

export const EmployerMyTalentRoleForm = ({
  roles,
  onApply,
  shouldOpenDropdown,
}: EmployerMyTalentRoleFormProps) => {
  const defaultValues = useMemo(() => {
    return { roles };
  }, [roles]);
  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerMyTalentRoleFormSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onApply(values.roles);
  };

  const onReset = () => {
    reset({ roles: [] });
  };

  const { field } = useController({
    name: "roles",
    control: control,
  });

  const { data: rolesData = [] } = useGetRolesQuery();

  const valueObject = useMemo(() => {
    return rolesData.filter((role) => {
      return field.value?.includes(role.id);
    });
  }, [field.value, rolesData]);

  const isFilterEmpty = !field.value?.length;
  const { autocompleteTextFieldRef } = useInitiallyOpenDropdown({
    isFilterEmpty,
    shouldOpenDropdown,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <ComboBoxMultiple<Role, true>
            openOnFocus
            {...field}
            value={valueObject}
            ref={null}
            onChange={(_event, values) => {
              field.onChange(
                values.map((value) => {
                  return value.id;
                }),
              );
            }}
            ChipProps={{
              deleteIcon: (
                <CloseIcon sx={{ color: "var(--black)!important" }} />
              ),
            }}
            disableClearable
            component={EmployerMyTalentFiltersAutocomplete}
            PaperComponent={PaperWithVioletOptions}
            initialTaxonomiesLoading={false}
            options={rolesData}
            getOptionLabel={(role) => {
              return role.name;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={autocompleteTextFieldRef}
                  {...params}
                  placeholder={isFilterEmpty ? "Select a role" : undefined}
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Select roles",
                  }}
                />
              );
            }}
          />
        }
        formFooter={<FilterButtonPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
