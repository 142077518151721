import { useState } from "react";

export const useIsPopoverRendered = () => {
  const [isPopoverRendered, setIsPopoverRendered] = useState(false);

  const onPopoverEnter = () => setIsPopoverRendered(true);
  const onPopoverClose = () => setIsPopoverRendered(false);

  return { onPopoverEnter, onPopoverClose, isPopoverRendered };
};
