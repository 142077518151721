import { useEffect, useRef, useState } from "react";

import { isPaymentStillBlocked } from "@js/apps/invoices/utils";
import type { EmployerInvoice } from "@js/types/invoices";

export const useIsEmployerInvoicePaymentBlocked = (
  invoice: EmployerInvoice,
) => {
  const [isPaymentTemporaryBlocked, setIsPaymentTemporaryBlocked] = useState(
    isPaymentStillBlocked(invoice.payment_blocked_until),
  );
  const isPaymentTemporaryBlockedIntervalRef =
    useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    setIsPaymentTemporaryBlocked(
      isPaymentStillBlocked(invoice.payment_blocked_until),
    );
  }, [invoice]);

  // TODO improve - remove interval and simply add a timeout after which the payment is enabled
  useEffect(() => {
    const interval = isPaymentTemporaryBlockedIntervalRef.current;
    clearInterval(interval);

    if (isPaymentTemporaryBlocked) {
      isPaymentTemporaryBlockedIntervalRef.current = setInterval(() => {
        setIsPaymentTemporaryBlocked(
          isPaymentStillBlocked(invoice.payment_blocked_until),
        );
      }, 5000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentTemporaryBlocked]);

  return {
    isPaymentTemporaryBlocked,
  };
};
