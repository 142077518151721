import { useEffect, useMemo } from "react";
import type {
  FieldValues,
  FormState,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormWatch,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { useEqualMemo } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { buildSearchParams } from "@js/utils";

type Args<T extends FieldValues> = {
  watch: UseFormWatch<T> | T;
  handleSubmit: UseFormHandleSubmit<T>;
  formState: FormState<T>;
  onSubmit?: SubmitHandler<T>;
  onError?: SubmitErrorHandler<T>;
};

/**
 * @description Hook triggers submit on form data change.
 * @param watch pass `useForm` `watch` function to trigger submit on every data change,
 *              or pass data object for more specific cases (don't need to be memoized, as hook takes care of it)
 * @param handleSubmit `useForm` function
 * @param onSubmit submit handler
 * @param onError submit error handler
 */
export const useSubmitFormOnChange = <T extends FieldValues>({
  watch,
  handleSubmit,
  formState,
  onSubmit,
  onError,
}: Args<T>): void => {
  const [, setSearchParams] = useSearchParams();

  const data = typeof watch === "function" ? watch() : watch;
  /* we don't want to submit on `page` change. `page` is handled separately and
   * is reset within onSubmit to `undefined` (which is expected for new filters) */
  const dataWithoutPage = useMemo(() => {
    return { ...data, page: undefined };
  }, [data]);
  const memoizedData = useEqualMemo(dataWithoutPage);

  const onSubmitRef = useEffectRef(onSubmit);
  const onErrorRef = useEffectRef(onError);

  useEffect(() => {
    /** To make sure it is not invoked on mount which causes remount of the page and infinite loop */
    if (formState.isDirty) {
      const defaultOnSubmit: SubmitHandler<T> = (values: T) => {
        const filters = buildSearchParams(values);
        setSearchParams(filters, {
          state: { disableRemountIfTheSameLocation: true },
        });
      };

      const onValid: SubmitHandler<T> = (...arg) => {
        if (!onSubmitRef.current) {
          return defaultOnSubmit(...arg);
        }
        onSubmitRef.current(...arg);
      };

      const onInvalid: SubmitErrorHandler<T> = (...arg) => {
        onErrorRef.current?.(...arg);
      };

      handleSubmit(onValid, onInvalid)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedData, onSubmitRef, onErrorRef]);
};
