import React, { useMemo } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import {
  fetchEmployerProfile,
  updateEmployerProfile,
} from "@js/apps/employer/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import type { VisibilityFormValues } from "../../forms/visibility";
import { VisibilityForm } from "../../forms/visibility";

export const EmployerVisibility = () => {
  const dispatch = useAppDispatch();
  const employerProfile = useAppSelector(
    (state) => state.employer.employerProfile,
  );
  const fetchingEmployerProfile = useAppSelector(
    (state) => state.employer.fetchingEmployerProfile,
  );

  useEffectOnce(() => {
    dispatch(fetchEmployerProfile());
  });

  const onSubmit = (
    values: Pick<VisibilityFormValues, "show_number_of_jobs">,
  ) => {
    return dispatch(updateEmployerProfile(values, employerProfile?.id)).then(
      () => {
        dispatch(fetchCurrentUser());
      },
    );
  };

  const initialValues = useMemo(() => {
    return {
      show_number_of_jobs: employerProfile?.show_number_of_jobs,
    };
  }, [employerProfile]);

  if (!employerProfile || fetchingEmployerProfile) {
    return <Loader centered />;
  }

  return (
    <VisibilityForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      accountType={ENUMS.AccountType.EMPLOYER}
    />
  );
};
