import type { MouseEvent, ReactNode } from "react";
import { forwardRef } from "react";

import {
  Button,
  Popover,
  Typography,
} from "@hexocean/braintrust-ui-components";

type FilterPopoverProps = {
  label: string;
  icon: JSX.Element;
  isFilterApplied: boolean;
  isFilterOpen: boolean;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  onPopoverEnter?: () => void;
  anchorElement: HTMLElement | null;
  children: ReactNode;
  variant?: PopoverAnchorComponentProps["variant"];
};

export const FilterPopover = ({
  label,
  icon,
  isFilterApplied,
  children,
  isFilterOpen,
  onClick,
  onClose,
  onPopoverEnter,
  anchorElement,
  variant,
}: FilterPopoverProps) => {
  return (
    <Popover
      open={isFilterOpen}
      onClose={onClose}
      sx={{ mt: 1 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionProps={{ onEntered: onPopoverEnter }}
      disableRestoreFocus
      anchorEl={anchorElement}
      anchor={
        <PopoverAnchorComponent
          label={label}
          icon={icon}
          isActive={isFilterOpen || isFilterApplied}
          onClick={onClick}
          variant={variant}
        />
      }
    >
      {children}
    </Popover>
  );
};

type PopoverAnchorComponentProps = {
  label: string;
  icon: JSX.Element;
  isActive: boolean;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  variant?: "green" | "violet";
};

const PopoverAnchorComponent = forwardRef<
  HTMLButtonElement,
  PopoverAnchorComponentProps
>(({ label, icon, isActive, onClick, variant = "violet" }, ref) => {
  return (
    <Button
      ref={ref}
      onClick={onClick}
      variant={
        isActive
          ? `${variant}-with-dark-border`
          : `white-${variant}-dark-border`
      }
      startIcon={icon}
      shape="squared"
      sx={{
        "&& .icon > svg": {
          fontSize: "18px",
        },
        "&& .icon--start": {
          mr: "5px",
        },
      }}
    >
      <Typography component="p" size="small">
        {label}
      </Typography>
    </Button>
  );
});
