import dayjs from "dayjs";

import {
  maxLength,
  maxValue,
  minValue,
  requiredWithSpecificMessage,
  restrictedSigns,
} from "@js/forms/validators";
import { sanitize } from "@js/services";
import type { JobFormValues } from "@js/types/jobs";
import { isDateBeforeUTCToday, isDateValid } from "@js/utils/date";

import { MAX_OPENINGS_NUMBER } from "../fields/openings-number-field";

export const JOB_FORM_ERROR_MESSAGES = {
  job_title: "Please add a job title",
  pay_rate: "Please add a pay rate",
  start_date: "Please add a start date",
  end_date: "Please add an end date",
  experience_level: "Please select years of experience",
  openings: "Please add an openings number",
  job_length: "Please select job length",
  job_description: "Please add a job description",
  other_role_name: "Please add the other role name",
  job_description_too_long:
    "Ensure this field has no more than 100 000 characters.",
  skills_top_skills: "Please select at least 1 skill marked as a top skill",
  skills: "Please select at least 1 skill",
  top_skills: "Please select at least 1 top skill",
};

export const jobTitleValidator = requiredWithSpecificMessage(
  JOB_FORM_ERROR_MESSAGES.job_title,
);
export const jobTitleLengthValidator = maxLength(255);
export const jobTitleSignValidator = restrictedSigns(["<", ">"]);
export const maxOpeningsNumberValidator = maxValue(MAX_OPENINGS_NUMBER);
export const minOpeningsNumberValidator = (min: number) => minValue(min);
export const openingsValidator = requiredWithSpecificMessage(
  JOB_FORM_ERROR_MESSAGES.openings,
);
export const experienceValidator = requiredWithSpecificMessage(
  JOB_FORM_ERROR_MESSAGES.experience_level,
);

export const payRateValidator = requiredWithSpecificMessage(
  JOB_FORM_ERROR_MESSAGES.pay_rate,
);

export const startDateValidator = requiredWithSpecificMessage(
  JOB_FORM_ERROR_MESSAGES.start_date,
);
export const endDateValidator = requiredWithSpecificMessage(
  JOB_FORM_ERROR_MESSAGES.end_date,
);
export const projectDetailsValidator = requiredWithSpecificMessage(
  JOB_FORM_ERROR_MESSAGES.job_length,
);

export const skillsValidator = (_value: number[], allValues: JobFormValues) => {
  const { new_skills, top_skills } = allValues;

  const shouldShowNewSkillsError = !new_skills || new_skills.length < 1;
  const shouldShowTopSkillsError = !top_skills || top_skills.length < 1;

  if (shouldShowNewSkillsError && shouldShowTopSkillsError) {
    return JOB_FORM_ERROR_MESSAGES.skills_top_skills;
  }

  if (shouldShowNewSkillsError) return JOB_FORM_ERROR_MESSAGES.skills;
  if (shouldShowTopSkillsError) return JOB_FORM_ERROR_MESSAGES.top_skills;
};

export const jobDescriptionValidator = (
  value: string,
  allValues: JobFormValues,
) => {
  if (allValues.introduction?.length) return;

  const parsedValue = sanitize(value, {});

  if (parsedValue.length > 100000) {
    return JOB_FORM_ERROR_MESSAGES.job_description_too_long;
  }

  // {} to overwrite config
  return requiredWithSpecificMessage(JOB_FORM_ERROR_MESSAGES.job_description)(
    parsedValue,
  );
};

export const jobStartDateValidator = (value: string | undefined | null) => {
  if (!value || !isDateValid(value)) {
    return;
  }

  if (isDateBeforeUTCToday(value)) {
    return `Project Start Date can't be in the past.`;
  }
};

export const jobEndDateValidator = (
  endDate: string | undefined | null,
  startDate: string | undefined | null,
) => {
  if (!endDate || !isDateValid(endDate)) {
    return;
  }

  if (isDateBeforeUTCToday(endDate)) {
    return `Project End Date can't be in the past.`;
  }

  if (!startDate) {
    return;
  }

  const isEndBeforeStart = dayjs(endDate).isBefore(dayjs(startDate), "day");
  if (isEndBeforeStart) {
    return `Project end date can't be before its start date.`;
  }
};
