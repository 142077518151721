import { useAppSelector } from "@js/hooks";

import { useGetFreelancerProfileCompletionQuery } from "../../api";

export const useCurrentFreelancerProfileCompletion = ({
  skip,
}: { skip?: boolean } = {}) => {
  const freelancerId = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.id,
  );

  return useGetFreelancerProfileCompletionQuery(
    { freelancerId: freelancerId as number },
    { skip: !freelancerId || skip },
  );
};
