import { FlagIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";

import { MY_TALENT_FILTER_TO_APPLY_LABEL } from "./constants";
import { EmployerMyTalentStatusForm } from "./forms";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentStatusFilterPopoverProps = {
  status: EmployerMyTalentFormValues["status"];
  onChange: (status: EmployerMyTalentFormValues["status"]) => void;
};

export const MyTalentStatusFilterPopover = ({
  status,
  onChange,
}: MyTalentStatusFilterPopoverProps) => {
  const isAnyStatusApplied = Boolean(status && status.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const handleApply = (newStatus: EmployerMyTalentFormValues["status"]) => {
    onChange(newStatus);
    handleClose();
  };

  return (
    <FilterPopover
      label={MY_TALENT_FILTER_TO_APPLY_LABEL.status}
      icon={<FlagIcon />}
      isFilterApplied={isAnyStatusApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handleClose}
      anchorElement={anchorElement}
    >
      <EmployerMyTalentStatusForm status={status} onApply={handleApply} />
    </FilterPopover>
  );
};
