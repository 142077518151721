import { useEffect, useRef } from "react";
import videojs from "video.js";
import type PlayerType from "video.js/dist/types/player";

import "video.js/dist/video-js.css";
import "./styles.css";

interface VideoJsPlayerOptions {
  autoplay?: boolean;
  controls?: boolean;
  responsive?: boolean;
  fluid?: boolean;
  sources?: { src: string; type: string }[];
}

interface HlsPlayerProps {
  options: VideoJsPlayerOptions;
  onReady?: (player: PlayerType) => void;
  onLoadedMetadata?: (player: PlayerType) => void;
  customClassName?: string;
}

const HlsPlayer = ({
  options,
  onReady,
  onLoadedMetadata,
  customClassName,
}: HlsPlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<PlayerType | null>(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      if (videoRef?.current) {
        videoRef.current.appendChild(videoElement);
        if (customClassName) {
          videoRef.current.classList.add(customClassName);
        }
      }

      const player = (playerRef.current = videojs(videoElement, options, () => {
        if (onReady) {
          onReady(player);
        }
      }));
      player.on("loadedmetadata", () => {
        if (onLoadedMetadata) {
          onLoadedMetadata(player);
        }
      });
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay ?? false);
      if (options.sources?.length) {
        player.src(options.sources);
      }
    }
  }, [options, videoRef, onReady, onLoadedMetadata, customClassName]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{ width: "100%" }}>
      <div ref={videoRef} />
    </div>
  );
};

export default HlsPlayer;
