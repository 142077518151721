import { Box, Button, Loader } from "@hexocean/braintrust-ui-components";
import { MyWorkEmptyState } from "@js/apps/freelancer/components";
import { BidListItem } from "@js/apps/freelancer/components/bid-list/bid-item";
import { useFreelancerBids } from "@js/apps/jobs/apps/bids";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { JobListBoostBanner } from "@js/apps/jobs/components/job-list-boost-banner";
import { RouterLink } from "@js/components/link";
import type { FreelancerBid } from "@js/types/jobs";

type FreelancerDashboardBidsPageProps = {
  showClosedApplications: boolean;
};

export const FreelancerDashboardBidsPage = ({
  showClosedApplications,
}: FreelancerDashboardBidsPageProps) => {
  const { isLoading, bids } = useFreelancerBids(showClosedApplications);

  return (
    <>
      <BidList
        isLoading={isLoading}
        bids={bids}
        emptyStateMessage="Applications you have submitted will appear here."
      />
    </>
  );
};

type BidListProps = {
  bids: FreelancerBid[];
  isLoading: boolean;
  emptyStateMessage: string;
};

const BidList = ({ bids, isLoading, emptyStateMessage }: BidListProps) => {
  if (isLoading) return <Loader />;

  if (!bids.length)
    return (
      <MyWorkEmptyState
        title="No applications yet"
        description={emptyStateMessage}
        icon="✏️"
        iconBackgroundColor="var(--medium-violet)"
        cta={
          <Button
            sx={{ mt: 2 }}
            variant="white-border-beige"
            shape="squared"
            to="/role-jobs/"
            RouterLink={RouterLink}
          >
            Search jobs
          </Button>
        }
      />
    );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {emptyStateMessage ===
        "Applications you have submitted will appear here." && (
        <ApplicationBoostFeatureFlagWrapper>
          <JobListBoostBanner />
        </ApplicationBoostFeatureFlagWrapper>
      )}

      <Box>
        {bids.map((bid) => (
          <BidListItem bid={bid} key={bid.id} />
        ))}
      </Box>
    </Box>
  );
};
