// Mapping Timezones to Frontend <-> Backend formats
import { timezoneOptions } from "@js/apps/jobs/apps/create-job/components/project-details-section-module/location-timezone-section/location-and-timezone-fields/timezone-with-overlap/job-timezone-field/helpers";
import type {
  JobDraftTimezone,
  JobTimezone,
  TimezoneOption,
  TimezonePayload,
} from "@js/types/jobs";

export const mapTimezonesToFrontendFormat = (
  timezones: JobTimezone[] | JobDraftTimezone[],
) => {
  return timezones.map((timezone) => {
    return timezoneOptions.find(
      (option) =>
        option.id === timezone.timezone || option.id === timezone.timezone_id,
    );
  }) as TimezoneOption[];
};

export const mapTimezonesToBackendFormat = (
  timezones: TimezoneOption[],
): TimezonePayload[] => {
  return timezones.map((timezone) => {
    // We need to store `timezone_id` to make sure it is mapped properly to frontend format (within drafts)
    return { timezone: timezone.value, timezone_id: timezone.id };
  });
};
