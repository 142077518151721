import type { AnyAction } from "@reduxjs/toolkit";

import {
  AVATAR_FIELD_UPLOADING,
  CLEAR_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PROFILE,
  FETCH_EMPLOYER_PROFILE_FAILED,
  FETCH_EMPLOYER_PROFILE_SUCCESS,
  FETCH_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED,
  FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS,
  FETCH_EMPLOYER_TEAM_MEMBERS,
  FETCH_EMPLOYER_TEAM_MEMBERS_FAILED,
  FETCH_EMPLOYER_TEAM_MEMBERS_SUCCESS,
  UPDATE_EMPLOYER_PROFILE_SUCCESS,
} from "@js/apps/employer/action-types";

import type { EmployerState } from "../../types/employer";

const INITIAL_STATE: EmployerState = {
  openJobs: [],
  fetchingOpenJobs: false,
  fetchingEmployerProfile: false,
  employerProfile: undefined,
  employerPublicProfile: undefined,
  fetchingEmployerTeamMembers: false,
  avatarFieldUploading: false,
  employerTeamMembers: [],
};

export default (state = INITIAL_STATE, action: AnyAction): EmployerState => {
  switch (action.type) {
    case FETCH_EMPLOYER_PROFILE:
      return {
        ...state,
        fetchingEmployerProfile: true,
      };

    case FETCH_EMPLOYER_PROFILE_SUCCESS:
      // For now, we only support one employer profile.
      return {
        ...state,
        fetchingEmployerProfile: false,
        employerProfile: action.payload.length
          ? action.payload[0]
          : INITIAL_STATE.employerProfile,
      };

    case FETCH_EMPLOYER_PROFILE_FAILED:
      return {
        ...state,
        fetchingEmployerProfile: false,
        employerProfile: INITIAL_STATE.employerProfile,
      };

    case UPDATE_EMPLOYER_PROFILE_SUCCESS:
      return {
        ...state,
        employerProfile: { ...state.employerProfile, ...action.payload },
      };

    case FETCH_EMPLOYER_PUBLIC_PROFILE:
      return {
        ...state,
        fetchingEmployerProfile: true,
      };

    case FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS:
      return {
        ...state,
        employerPublicProfile: action.payload,
        fetchingEmployerProfile: false,
      };

    case CLEAR_EMPLOYER_PUBLIC_PROFILE:
      return {
        ...state,
        employerPublicProfile: undefined,
      };

    case FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED:
      return {
        ...state,
        employerPublicProfile: undefined,
        fetchingEmployerProfile: true,
      };

    case FETCH_EMPLOYER_TEAM_MEMBERS:
      return {
        ...state,
        fetchingEmployerTeamMembers: true,
        employerTeamMembers: INITIAL_STATE.employerTeamMembers,
      };

    case FETCH_EMPLOYER_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        fetchingEmployerTeamMembers: false,
        employerTeamMembers: action.payload,
      };

    case FETCH_EMPLOYER_TEAM_MEMBERS_FAILED:
      return {
        ...state,
        fetchingEmployerTeamMembers: false,
        employerTeamMembers: INITIAL_STATE.employerTeamMembers,
      };

    case AVATAR_FIELD_UPLOADING:
      return {
        ...state,
        avatarFieldUploading: action.payload,
      };

    default:
      return state;
  }
};
