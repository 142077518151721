import { SuitcaseOutlinedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";
import { useHasFilterPopoverAnimationFinished } from "@js/hooks/use-has-filter-popover-animation-finished";

import { EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL } from "../../constants";
import { ProjectForm } from "../forms";
import type { EmployerInvoicesFormValues } from "../schema";

type ProjectFilterProps = {
  jobs: EmployerInvoicesFormValues["jobs"];
  onChange: (jobs: EmployerInvoicesFormValues["jobs"]) => void;
};

export const ProjectFilter = ({ jobs, onChange }: ProjectFilterProps) => {
  const isAnyJobApplied = Boolean(jobs && jobs.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, hasPopoverAnimationFinished } =
    useHasFilterPopoverAnimationFinished();

  const handleApply = (newJobs: EmployerInvoicesFormValues["jobs"]) => {
    onChange(newJobs);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterPopover
      label={EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL.jobs}
      icon={<SuitcaseOutlinedIcon sx={{ rect: { strokeWidth: "1" } }} />}
      isFilterApplied={isAnyJobApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
      variant="green"
    >
      <ProjectForm
        jobs={jobs}
        onApply={handleApply}
        hasFilterPopoverFinishedAnimation={hasPopoverAnimationFinished}
      />
    </FilterPopover>
  );
};
