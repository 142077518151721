import type { Skill } from "@js/types/admin";
import type { User } from "@js/types/auth";
import type { Attachment } from "@js/types/uploads";
import type { IsoDateOrIsoDateTime } from "@js/utils/date/types";

import type {
  Degree,
  FreelancerPublicExternalProfile,
  ProfileReviews,
  School,
} from "./common";
import type { Employer } from "./employer";
import type { PaginatedResult } from "./generic";
import type { PostCategory } from "./give-and-get-help";
import type { FreelancerOffer, Job, ResumeAttachment } from "./jobs";
import type { Role } from "./roles";
import type { MySpace } from "./spaces";

export type FreelancerState = {
  freelancerProfile: Freelancer | undefined;
  fetchingFreelancerProfile: boolean;

  fetchingJobList: boolean;
  jobList: Array<Job>;

  fetchingJobListSelect: boolean;
  jobSelectList: Array<JobSelect>;

  notInterestedJobIds: number[];

  fetchingFreelancerOffers: boolean;
  offers: Array<FreelancerOffer> | null;

  fetchingEvents: boolean;
  events: {
    results: Array<any>;
    count: number;
  } | null;
};

export type JobInvitations = PaginatedResult<Job>;

export type JobSelect = {
  id: number;
  title: string;
  employer: Employer;
  invoice_can_be_created: boolean;
};

export type ProfileCompletion = {
  completion_percent: number;
  missing_areas: Array<{
    keywords: string;
    items_count: number | null;
    progress: number;
  }>;
  profile_completion_hit_at: string | null;
};

// to make sure user will include all missing areas
export type ProfileCompletionAreasMap<T> = Record<
  EnumType<typeof ENUMS.ProfileCompletionArea>,
  T
>;

export type FreelancerForInvoice = {
  company_name: string;
  id: number;
  user: {
    first_name: string;
    has_avatar_set: boolean;
    last_name: string;
    public_name: string;
  };
};

export type FreelancerForNodeStaff = FreelancerPublic & {
  email: string;
  signed_up_at: string;
  acquisition_source: string;
  utm_source: string;
  utm_campaign: string;
  joining_reasons: Array<string>;
  location_data: {
    ip_check: boolean;
    location_from_ip: string | null;
    reported_address: string | null;
  };
  preferred_work_type: string;
  preferred_start_time: string;
  english_proficiency: string;
  hireflix_interview_link: string | null;
  location_mismatch: boolean;
  average_internal_review_rating: string | null;
  internal_review_ratings_count: number;
};

export function isFreelancerForNodeStaffEntity(
  obj: unknown,
): obj is FreelancerForNodeStaff {
  return (obj as FreelancerForNodeStaff)?.location_mismatch !== undefined;
}

export type FreelancerSuperpower = {
  id: number;
  name: string;
};

export type FreelancerCertificate = {
  id: number;
  certificate: {
    id: number;
    name: string;
    is_default: boolean;
  };
  issuer: string;
  year_of_issuing: number;
  order: number;
};

export type FreelancerSkill = {
  id: number;
  level: string | null;
  role: Role;
  skill: Skill;
  order: number;
  is_superpower: boolean;
};

export type FreelancerWorkExperience = {
  id: number;
  company: {
    id: number;
    name: string;
    is_default: boolean;
    order: number | null;
  };
  title: string;
  year_from: number;
  year_to: number | null;
  month_from: number;
  month_to: number | null;
  description: string;
};

export type FreelancerSchool = {
  id: number;
  school: School;
  degree: Degree | null;
  graduation_date: IsoDateOrIsoDateTime | null;
};

export type WorkSampleItem = {
  id: number;
  title: string;
  completion_date: IsoDateOrIsoDateTime;
  description: string | null;
  url: string | null;
  main_image: WorkSampleItemImage | null;
  portfolio_item_images: Array<WorkSampleItemImage>;
  order: number;
};

export type PortfolioImage = {
  id: number;
  thumbnail: string;
  attachment: Attachment;
};

type WorkSampleItemImage = {
  id: number;
  image: PortfolioImage;
};

export type TalentExampleProfile = {
  id: number;
  approved: boolean;
  cover_photo: null;
  accent_color: string;
  location: string | null;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    public_name: string;
    gravatar: string;
    avatar: string;
    avatar_thumbnail: string;
    freelancer_approved: boolean;
    agency: null;
    profile_url: string;
    account_type: string;
    title: string;
    created: string;
    has_agency_avatar_set: boolean;
    agency_avatar: string;
    agency_avatar_thumbnail: string;
    has_avatar_set: boolean;
  };
};

export type FreelancerTotals = {
  total_jobs?: number;
};

export type FreelancerRole = {
  id: number;
  role: Role;
  primary: boolean;
  years_experience: number;
  experience_as_arrow: number;
};

export type FreelancerHoverState = {
  roles: FreelancerRole[];
  approved: boolean;
  review_count: number;
  location: string | null;
  introduction_headline: string | null;
  public_name: string;
  services: PostCategory[];
  top_skills: FreelancerSkill[];
  average_rating: string;
  cover_photo: string | null;
};

type FreelancerBase = {
  id: number;
  user: User;
  role: Role;
  approved: boolean;
  account: number;
  location: string | null;
  accent_color: string;
  availability_for_work: boolean;
  availability_for_work_options: EnumType<
    typeof ENUMS.FreelancerAvailabilityForWork
  >[];
  working_hours_start: number | null;
  working_hours_end: number | null;
  working_hours_tz_abbr: string | null;
  working_hours_utc_offset: string | null;
};

export type FreelancerPublic = FreelancerBase &
  ProfileReviews & {
    cover_photo: string | null;
    cover_photo_id: number | null;
    freelancer_certificates: Array<FreelancerCertificate>;
    freelancer_skills: Array<FreelancerSkill>;
    freelancer_schools: Array<FreelancerSchool>;
    external_profiles: Array<FreelancerPublicExternalProfile>;
    years_experience: number;
    roles: FreelancerRole[];
    portfolio_items: WorkSampleItem[];
    freelancer_work_experience: Array<FreelancerWorkExperience>;
    can_bid: boolean;
    profile_visits_count: number;
    experience_level?: string | null;
    experience_as_arrow: number;
    spaces: MySpace[];
    resume: ResumeAttachment | null;
  };

export type Freelancer = Omit<
  FreelancerPublic,
  | "spaces"
  | "cover_photo_id"
  | "cover_photo"
  | "portfolio_items"
  | "freelancer_schools"
  | "experience_as_arrow"
  | "freelancer_certificates"
  | "freelancer_work_experience"
  | "freelancer_skills"
  | "roles"
  | "external_profiles"
> & {
  commit_becoming_ambassador: boolean;
  commit_quote_agreement: boolean;
  commit_charity_percent: boolean;
  commitments_submitted: boolean;
  hide_token_balance: boolean;
  show_number_of_jobs: boolean | null;
  profile_visibility: "private" | "public";
  profile_visits_count_visibility: "private" | "public";
  show_availability_coachmark?: boolean;
  has_bid?: boolean;
  company_name?: string;
  pending_approval: boolean;
};

export type Talent = FreelancerBase &
  Omit<ProfileReviews, "stars_breakdown"> & {
    average_internal_review_rating: string | null;
    internal_review_ratings_count: number;
    search_score: string;
    matching_skills_percent?: number[];
    personal_rank: number[];
    can_be_invited_to_bid?: boolean;
    is_invited_to_this_job?: boolean;
    timezone: string;
    timezone_offset: string;
    country: string | null;
    location_mismatch: boolean;
    superpowers: Array<FreelancerSuperpower>;
    rate?: number;
  };

export function isTalentEntity(obj: unknown): obj is Talent {
  return (obj as Talent)?.search_score !== undefined;
}
