import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetEmployerOpenJobsQuery } from "@js/apps/jobs/api";
import { useAppDispatch, useAppSelector, useNavigate } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import { castToInteger } from "@js/utils";

import {
  clearEmployerPublicProfile,
  fetchEmployerPublicProfile,
} from "../../actions";

export const useEmployerProfile = () => {
  const currentProfileId = useIdParam();
  const navigate = useNavigate();

  const employerPublicProfile = useAppSelector(
    (state) => state.employer.employerPublicProfile,
  );
  const fetchingEmployerProfile = useAppSelector(
    (state) => state.employer.fetchingEmployerProfile,
  );
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { data: openJobs, isLoading: isFetchingOpenJobs } =
    useGetEmployerOpenJobsQuery(id ? { employerId: Number(id) } : skipToken);

  const fetchEmployerProfileAndJobs = useCallback(async () => {
    const idAsInteger = castToInteger(id);
    if (!idAsInteger) return;

    try {
      dispatch(fetchEmployerPublicProfile(idAsInteger));
    } catch (_err) {
      // handled by the 404
    }
  }, [dispatch, id]);

  useEffect(() => {
    fetchEmployerProfileAndJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearEmployerPublicProfile());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentProfileId) {
      navigate("/page-not-found/?reloaded=true", { replace: true });
    }
  }, [currentProfileId, navigate]);

  const loading =
    fetchingEmployerProfile ||
    isFetchingOpenJobs ||
    employerPublicProfile?.id !== Number(id);

  return {
    fetchEmployerProfileAndJobs,
    openJobs: openJobs || [],
    employerPublicProfile,
    loading,
  };
};
