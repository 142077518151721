import { SubmissionError } from "redux-form";
import _ from "underscore";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import type { RefetchBidList } from "@js/apps/jobs/context/refetch-bids-context";
import { OptionalFeesForm } from "@js/apps/jobs/forms/optional-fees";
import { Modal } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { EmployerBidListBid, SaveOptionalFeesProps } from "@js/types/jobs";

import { useSaveOptionalFeesMutation } from "../../api";

type OpenOptionalFeesModalProps = {
  bid: EmployerBidListBid;
  refetchBidList: RefetchBidList;
  isFetchingBids: boolean;
};

export const OptionalFeesModalInstance = Modal("optional-fees-modal", {
  closeButton: false,
  className: "optional-fees-modal",
});

type Error = { data: Record<string, string> };

const OptionalFeesModalContent = ({
  bid,
  isFetchingBids,
  refetchBidList,
}: OpenOptionalFeesModalProps) => {
  const [saveOptionalFees] = useSaveOptionalFeesMutation();

  if (!refetchBidList) return null;

  const handleSubmit = (values: SaveOptionalFeesProps["fees"]) => {
    return saveOptionalFees({ bidId: bid.id, fees: values })
      .unwrap()
      .then(() => {
        refetchBidList();
        OptionalFeesModalInstance.close();
        Snackbar.success("Fees have been saved.");
      })
      .catch((error: Error) => {
        throw new SubmissionError(error.data);
      });
  };

  return (
    <Box minHeight="382px" position="relative">
      <Typography
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
        className="mb+"
      >
        Optional fees
      </Typography>
      {isFetchingBids ? (
        <Loader centered />
      ) : (
        <OptionalFeesForm
          initialValues={_.pick(
            bid,
            "compliance_fee_percent",
            "background_fee_amount",
            "w2_markup_percent",
            "braintrust_pays_talent_off_platform_enabled",
          )}
          onSubmit={handleSubmit}
          onCancel={OptionalFeesModalInstance.close}
          canChangeBackgroundFee={bid.can_change_background_fee}
        />
      )}
    </Box>
  );
};

export const OptionalFeesModal = (props: OpenOptionalFeesModalProps) => {
  return (
    <OptionalFeesModalInstance>
      <OptionalFeesModalContent {...props} />
    </OptionalFeesModalInstance>
  );
};
