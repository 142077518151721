import { useRef } from "react";
import cs from "classnames";

import { Button } from "@hexocean/braintrust-ui-components";
import { PostTextWithHashtagLinks } from "@js/components/rich-text-editor/custom-plugins/hashtag-plugin/utils/post-text-with-hashtag-links";
import { prepareDOMInput } from "@js/services";
import type { IPost } from "@js/types/give-and-get-help";

import { useHandleReadMore } from "../../hooks/use-handle-read-more";
import type { PostHashtag } from "../../types";

export type ReadMoreProps = {
  id: number;
  description: string;
  onExpand?: () => void;
  onCollapse?: () => void;
  postHashtags?: PostHashtag[];
  postData?: IPost;
};

export const ReadMore = ({
  id,
  description,
  onExpand,
  onCollapse,
  postHashtags,
  postData,
}: ReadMoreProps): JSX.Element => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { fullContent, showButton, showFullText, showMore } = useHandleReadMore(
    {
      id,
      content: description,
      parentRef: contentRef,
      onExpand,
      onCollapse,
    },
  );

  return (
    <div ref={contentRef}>
      <PostTextWithHashtagLinks
        text={prepareDOMInput(fullContent, { markdown: false })}
        hashtags={postHashtags}
        postData={postData}
      />

      {!showFullText && showButton && (
        <span className="read-more-button__separator">·</span>
      )}

      {showButton && (
        <Button
          fontWeight={400}
          className={cs("read-more-button", {
            "read-more-button--more": !showFullText,
            "read-more-button--less": showFullText,
          })}
          variant="no-padding"
          onClick={showMore}
        >
          Read {showFullText ? "less" : "more"}
        </Button>
      )}
    </div>
  );
};
