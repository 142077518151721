import { useEffect, useState } from "react";
import Decimal from "decimal.js";

import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { useMyWalletPage } from "@js/apps/dashboard/hooks";
import { useLazyInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api";
import { BasicAccordion } from "@js/apps/on-ramp/components/onramp-modal/accordion";
import { LoadingOverlay } from "@js/apps/onboarding/views/create-account-page/loading-overlay";

import { ApplicationBoostModalLeft } from "./boost-modal-left-content";
import { ApplicationBoostModalRight } from "./boost-modal-right-content";

import styles from "./styles.module.scss";

const btrstNumber: number = 39;
const withOutBtrstAccData = [
  {
    title: "How does it work?",
    body: "Application Boost lets you select 3 job applications to boost. Boosting your job application places it above similar match applicants with a “boost” badge to the employer, increasing your visibility and likelihood of securing the job.",
  },
  {
    title: "How much is it?",
    body: "For 39 BTRST, you get 3 credits to boost your applications whenever you need.",
  },
  {
    title: "Why do we need to use Coinbase?",
    body: "Coinbase is the largest online exchange that allows people to buy, sell, and trade cryptocurrencies, including BTRST, on the Blockchain. We chose Coinbase for it’s trusted reputation, secure platform, and easy-to-use interface that makes it ideal for beginner to advanced cryptocurrency traders.",
  },
  {
    title: "Is it safe to use Coinbase?",
    body: (
      <>
        Coinbase is the most trusted crypto exchange:
        <br />
        <a
          href="https://www.coinbase.com/security"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          https://www.coinbase.com/security
        </a>
      </>
    ),
  },
  {
    title: "How will I know when my BTRST is available?",
    body: (
      <>
        We'll notify you when your BTRST is available. This could take up to 3
        hours. You can view your token balance anytime in your Braintrust{" "}
        <a
          href="https://app.usebraintrust.com/auth/login/?next=%2Ftalent%2Fdashboard%2Fmy_wallet%2F"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          Wallet
        </a>
        .
      </>
    ),
  },
];

const withBtrstAccData = [
  {
    title: "How does it work?",
    body: "Application Boost lets you select 3 job applications to boost. Boosting your job application places it above similar match applicants with a “boost” badge to the employer, increasing your visibility and likelihood of securing the job.",
  },
  {
    title: "How much is it?",
    body: "For 39 BTRST, you get 3 credits to boost your applications whenever you need.",
  },
];

export const ApplicationBoostModal = () => {
  const { balance } = useMyWalletPage();
  const [isloadingModal, setIsloadingModal] = useState(true);
  const [initPayment, { data, isLoading }] = useLazyInitCoinbasePaymentQuery();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery("sm");

  useEffect(() => {
    initPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let requireBTRST: string = "0";
  const userWallet = new Decimal(balance?.available_tokens ?? "0");
  const boostCost = new Decimal(btrstNumber);

  const diff = boostCost.minus(userWallet);

  if (diff.lessThan(0)) requireBTRST = "0";
  else requireBTRST = diff.toString();

  const isBtrst =
    balance?.available_tokens !== undefined &&
    Number(balance?.available_tokens) < btrstNumber
      ? false
      : true;

  useEffect(() => {
    if (isLoading) {
      setIsloadingModal(false);
    }
    setTimeout(() => setIsloadingModal(false), 100);
  }, [isLoading]);

  if (isLoading || isloadingModal) {
    return (
      <div>
        <LoadingOverlay
          loaderProps={{ size: 64 }}
          show={isLoading || isloadingModal}
        />
      </div>
    );
  }

  const modalLeftContent = () => {
    if (isBtrst) {
      return (
        <>
          <Typography
            component="h1"
            variant="title"
            size={isMobile ? "small" : "medium"}
            fontWeight={400}
            mb={isMobile ? 1 : 4}
          >
            Boost 3 job applications
          </Typography>
          <Typography component="p" size={"medium"} fontWeight={400} mb={2}>
            Get to the top of the applicant list to increase your chances of
            getting hired.
          </Typography>
          <Box>
            <BasicAccordion options={withBtrstAccData} />
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box display={"flex"} gap={1} alignItems={"center"} marginBottom={2}>
            <Typography component="p" size="large" fontWeight={500}>
              Purchase
            </Typography>
            <Box display={"flex"} alignContent={"center"}>
              <img
                style={{ width: "20px" }}
                src={`${SETTINGS.STATIC_URL}boostmodal/logo.svg`}
                alt="logo"
              />
            </Box>
            <Typography component="p" size="large" fontWeight={500}>
              BTRST
            </Typography>
          </Box>

          <Typography
            component="h1"
            fontWeight={400}
            variant="title"
            size={"medium"}
          >
            Add{" "}
            <Tooltip title={requireBTRST} open={isOpen}>
              <span
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
              >
                {parseFloat(requireBTRST).toFixed(2)}
              </span>
            </Tooltip>{" "}
            BTRST to your wallet to purchase Application Boosts
          </Typography>
          <Typography
            mt={3}
            component="p"
            size={"medium"}
            fontWeight={500}
            mb={2}
          >
            Get to the top of the applicant list to increase your chances of
            getting hired.
          </Typography>
          <Typography mb={2} component="p" variant="paragraph" size="small">
            Looks like you don't have enough BTRST for Application Boosts. No
            worries. You can securely buy BTRST through Coinbase using USD or
            the fiat of your choice. Once the tokens are in your wallet, we'll
            help you get Application Boosts.
          </Typography>
          <Box>
            <BasicAccordion options={withOutBtrstAccData} />
          </Box>
        </>
      );
    }
  };
  // TODO: Consolidate approach via https://braintrust-jira.atlassian.net/browse/TOK-128
  return (
    <Box className={styles.boxmodal}>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={5}>
          <ApplicationBoostModalLeft>
            {modalLeftContent()}
          </ApplicationBoostModalLeft>
        </Grid>
        <Grid item xs={12} md={7}>
          <ApplicationBoostModalRight
            btrst={isBtrst}
            walletAddress={data?.safe_wallet_address}
            transactionUuid={data?.payment_uuid}
            requireBTRST={requireBTRST}
            btrstNumber={btrstNumber}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
