import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import {
  Box,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import { openReviewsDraftsJobModal } from "@js/apps/employer/components/review-drafts-modal/modal-content";
import {
  CantManageMessage,
  InviteTalentEmptyState,
  JobManagementHeader,
  JobManagementNav,
} from "@js/apps/jobs/components";
import { JobManagement } from "@js/apps/jobs/layout";
import { RouterLink } from "@js/components/link";
import { useQueryParams } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";

import { useJobBids } from "../../apps/bids";
import { InvitesLoadingStateTalentsWithSmartMatching } from "../../components/loading-state-talents-with-smart-matching";
import { TalentInviteSuggestionList } from "../../components/talent-invite-suggestion-list";
import { useGetTalentInviteSuggestionsWithSmartMatching } from "../../hooks/get-talent-invite-suggestions-with-smart-matching";

import { useInviteTalentByUrl } from "./use-invite-talent-by-url";

export const InviteTalentPage = () => {
  const { back_to_review_modal } = useQueryParams();
  const shouldBackToReviewModal = back_to_review_modal === "true";
  const { hasInvitationUrl } = useInviteTalentByUrl();
  const id = useIdParam();

  useEffect(() => {
    if (shouldBackToReviewModal) {
      openReviewsDraftsJobModal();
    }
  }, [shouldBackToReviewModal]);

  if (!id) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  return (
    <JobManagement.Layout pageTitle="Job - Invite Talent">
      {hasInvitationUrl ? null : <InviteTalentPageContent jobId={id} />}
    </JobManagement.Layout>
  );
};

const InviteTalentPageContent = ({ jobId }: { jobId: number }) => {
  const {
    talentInviteSuggestions,
    isSmartMatchingInProgress,
    totalBidsCount,
    job,
    analyzingProfilesCount,
    page,
    inviteSuggestionsCount,
  } = useGetTalentInviteSuggestionsWithSmartMatching();

  const { interviewingBidList } = useJobBids(jobId);

  if (!job || !analyzingProfilesCount) {
    return <></>;
  }

  if (!job.user_can_manage) {
    return (
      <CantManageMessage
        title="You can't manage talent invitations in this job."
        to={`/jobs/${job?.id}`}
      />
    );
  }

  return (
    <JobManagement.ContentLayout
      header={<JobManagementHeader job={job} />}
      main={
        <JobManagement.ContentBox>
          <JobManagementNav
            job={job}
            totalBidsCount={totalBidsCount}
            totalInterviewingBidsCount={interviewingBidList.length}
          />
          {isSmartMatchingInProgress ? (
            <InvitesLoadingStateTalentsWithSmartMatching
              analyzingProfilesCount={analyzingProfilesCount}
            />
          ) : talentInviteSuggestions?.length ? (
            <>
              <TalentInviteSuggestionList
                talentInviteSuggestions={talentInviteSuggestions}
                job={job}
              />
              <PaginationWrapper>
                <Pagination
                  page={Number(page)}
                  RouterLink={RouterLink}
                  perPage={SETTINGS.TALENT_INVITE_SUGGESTION_PER_PAGE}
                  count={inviteSuggestionsCount}
                />
              </PaginationWrapper>
            </>
          ) : (
            <Box my={6}>
              <InviteTalentEmptyState job={job} />
            </Box>
          )}
        </JobManagement.ContentBox>
      }
    />
  );
};
