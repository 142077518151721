import { Box, useMediaQuery } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { MyWorkBaseCard } from "@js/apps/freelancer/components";
import { BidActionButton, RolesTag } from "@js/apps/jobs/components";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { ApplicationStatusWithTooltip } from "@js/apps/jobs/components/application-status";
import { BoostJobButton } from "@js/apps/jobs/components/boost-job-button";
import type { FreelancerBid } from "@js/types/jobs";

import {
  MyWorkCardBasicInfo,
  PaymentAmount,
} from "../my-work-base-component/components";

import { ActionMenu } from "./action-menu";

type BidListItemProps = {
  bid: FreelancerBid;
};
export const BidListItem = ({ bid }: BidListItemProps) => {
  const user = useUser();
  const isMobile = useMediaQuery("sm");

  return (
    <MyWorkBaseCard
      job={bid.job}
      infoSlot={<MyWorkCardBasicInfo job={bid.job} />}
      roleSlot={<RolesTag role={bid.job.role} />}
      statusSlot={
        <ApplicationStatusWithTooltip
          status={bid.status_for_freelancer}
          appliedDate={bid.created}
          offerAcceptedDate={bid.current_offer_accepted_at}
          offerCreatedDate={bid.current_offer_created_at}
        />
      }
      rateSlot={
        <Box display="flex" alignItems="center">
          <PaymentAmount
            paymentAmount={bid.payment_amount}
            paymentType={bid.payment_type}
          />
          <ActionMenu bid={bid} mr={-2.25} />
        </Box>
      }
      ctaSlot={
        <BidActionButton
          job={bid.job}
          bid={bid}
          statusForFreelancer={bid.status_for_freelancer}
          size={isMobile ? "x-small" : "medium"}
        />
      }
      applicationBoostSlot={
        <ApplicationBoostFeatureFlagWrapper>
          <BoostJobButton
            variant="secondary"
            color="secondary"
            statusForFreelancer={bid.status_for_freelancer}
            fullWidth={false}
            sx={{
              display: { xs: "none", sm: "flex" },
              flexShrink: 0,
              height: { sm: 40 },
            }}
            alreadyBoosted={bid?.is_boosted}
            bidId={bid.id}
            isOpen={bid?.job?.is_open}
            boostCredit={user?.boost_credit}
            size={isMobile ? "x-small" : "medium"}
          />
        </ApplicationBoostFeatureFlagWrapper>
      }
    />
  );
};
