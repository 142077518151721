import { useMediaQuery } from "@hexocean/braintrust-ui-components";
import { MyWorkBaseCard } from "@js/apps/freelancer/components";
import {
  MyWorkCardBasicInfo,
  PaymentAmount,
} from "@js/apps/freelancer/components/my-work-base-component/components";
import { JobsOfferActionButton, RolesTag } from "@js/apps/jobs/components";
import { ApplicationStatusWithTooltip } from "@js/apps/jobs/components/application-status";
import type { MyJobFreelancer } from "@js/types/jobs";

type ListItemProps = {
  job: MyJobFreelancer;
};

export const ListItem = ({ job }: ListItemProps) => {
  const isMobile = useMediaQuery("sm");
  return (
    <MyWorkBaseCard
      job={job}
      infoSlot={<MyWorkCardBasicInfo job={job} />}
      roleSlot={<RolesTag role={job.role} />}
      statusSlot={
        <ApplicationStatusWithTooltip
          status={job.status_for_freelancer}
          offerAcceptedDate={job.offer_accepted_at}
        />
      }
      rateSlot={
        <PaymentAmount
          paymentAmount={job.payment_amount}
          paymentType={job.payment_type}
        />
      }
      ctaSlot={
        <JobsOfferActionButton
          jobId={job.id}
          offerId={job?.offer_id}
          statusForFreelancer={job?.status_for_freelancer}
          size={isMobile ? "x-small" : "medium"}
        />
      }
    />
  );
};
