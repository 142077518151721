import { useEffect } from "react";

import { useAccountType, useFreelancerId } from "@js/apps/common/hooks";
import { fetchEmployerPublicProfile } from "@js/apps/employer/actions";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useGetFreelancerBidsQuery } from "@js/apps/freelancer/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { EmployerPublicProfile } from "@js/types/employer";
import type { FreelancerBid, Job } from "@js/types/jobs";

import { useGetJobQuery } from "../../api";

type JobDetailsData = {
  job: Job | undefined;
  loading: boolean;
  bid?: FreelancerBid;
  employerProfile: EmployerPublicProfile | undefined;
  errorStatus: null | number | boolean;
};

export const useJobDetails = (jobId: number): JobDetailsData => {
  const dispatch = useAppDispatch();
  const { isFreelancer } = useAccountType();
  const { data: job, isFetching, isError } = useGetJobQuery({ id: jobId });
  const { data: currentJobBids, isLoading: isLoadingBids } =
    useGetFreelancerBidsQuery({ job: jobId }, { skip: !isFreelancer });

  const employerProfile = useAppSelector(
    (state) => state.employer.employerPublicProfile,
  );
  const fetchingEmployerProfile = useAppSelector(
    (state) => state.employer.fetchingEmployerProfile,
  );
  const freelancerId = useFreelancerId();
  const freelancerProfileId = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.id,
  );

  useEffect(() => {
    if (!jobId || !freelancerId) {
      return;
    }

    const profileIsFetched = !!freelancerProfileId;
    if (profileIsFetched) {
      return;
    }

    dispatch(fetchFreelancerProfile(freelancerId));
  }, [dispatch, jobId, freelancerId, freelancerProfileId]);

  const jobEmployerId = job?.employer?.id;
  useEffect(() => {
    if (!jobEmployerId) {
      return;
    }

    dispatch(fetchEmployerPublicProfile(jobEmployerId)).catch(() => {
      Snackbar.error("Sorry, something went wrong. Please try again.");
    });
  }, [dispatch, jobEmployerId]);

  const bid = currentJobBids?.[0];

  const loading =
    isFetching ||
    !job ||
    !employerProfile ||
    fetchingEmployerProfile ||
    isLoadingBids;

  return {
    loading,
    job,
    bid,
    employerProfile,
    errorStatus: isError,
  };
};
