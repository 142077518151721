import type { MouseEvent, ReactNode } from "react";

import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";

export type EmployerInvoicesDiscoverSelectionTooltipProps = {
  children: ReactNode;
} & TooltipContentProps;

export const EmployerInvoicesDiscoverSelectionTooltip = ({
  children,
  onTryItOutClick,
  onDismissClick,
}: EmployerInvoicesDiscoverSelectionTooltipProps) => {
  return (
    <Tooltip
      open
      title={
        <TooltipContent
          onTryItOutClick={onTryItOutClick}
          onDismissClick={onDismissClick}
        />
      }
      variant="white"
      placement="top"
      arrow
      disableTouchListener
      disableFocusListener
      disableHoverListener
      PopperProps={{
        sx: {
          "&&": {
            zIndex: 1500,
            ".MuiTooltip-tooltip": {
              maxWidth: "unset",
              borderRadius: 3,
              p: 0,
            },
          },
        },
      }}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

type TooltipContentProps = {
  onTryItOutClick: () => void;
  onDismissClick: () => void;
};

const TooltipContent = ({
  onTryItOutClick,
  onDismissClick,
}: TooltipContentProps) => {
  const handleDismissClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    onDismissClick();
  };

  return (
    <Stack
      sx={{ width: "278px", borderRadius: 3, overflow: "hidden" }}
      data-testid="introduction-tooltip"
    >
      <Box sx={{ p: 2, background: "var(--off-white)" }}>
        <Box
          component="img"
          src={`${SETTINGS.STATIC_URL}invoices/employer-invoices-discover-selection.svg`}
          width="246px"
          height="150px"
        />
      </Box>
      <Box sx={{ p: 2, background: "var(--white)" }}>
        <Typography
          component="p"
          variant="paragraph"
          size="large"
          sx={{ mb: "10px" }}
        >
          Pay invoices in bulk by selecting multiple at a time.
        </Typography>
        <Stack direction="row" sx={{ gap: 1 }}>
          <Button
            variant="secondary"
            shape="squared"
            onClick={handleDismissClick}
          >
            Dismiss
          </Button>
          <Button variant="primary" shape="squared" onClick={onTryItOutClick}>
            Try it out
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
