import {
  Button,
  Loader,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { downloadEmployerInvoicesClicked } from "@js/apps/employer/actions";
import { downloadInvoicesPDF } from "@js/apps/invoices/actions";
import { useLazyGetEmployerInvoicesMinimalQuery } from "@js/apps/invoices/api";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks/redux";

export type EmployerInvoicesSelectedActionsProps = {
  selectedInvoicesIds: number[];
  startPaymentFlow: () => void;
};

export const EmployerInvoicesSelectedActions = ({
  selectedInvoicesIds,
  startPaymentFlow,
}: EmployerInvoicesSelectedActionsProps) => {
  const dispatch = useAppDispatch();
  const selectedCount = selectedInvoicesIds?.length;

  const [getEmployerInvoicesMinimal, { isFetching }] =
    useLazyGetEmployerInvoicesMinimalQuery();

  const isPaySelectedDisabled = isFetching;

  const handleDownloadInvoicesPdfClick = () => {
    dispatch(downloadEmployerInvoicesClicked());
    downloadInvoicesPDF(
      selectedInvoicesIds.length ? { ids: selectedInvoicesIds } : undefined,
    );
  };

  const handlePaySelectedClick = async () => {
    if (isPaySelectedDisabled || !selectedInvoicesIds.length) {
      return;
    }

    try {
      const selectedInvoices = await getEmployerInvoicesMinimal(
        { ids: selectedInvoicesIds },
        true,
      ).unwrap();

      const isUnpayableInvoiceSelected = selectedInvoices.some(
        (invoice) => invoice.can_be_paid === false,
      );

      if (isUnpayableInvoiceSelected) {
        return openUnpayableInvoicesSelectedInfoModal();
      }

      startPaymentFlow();
    } catch (_error) {
      Snackbar.error("Failed to fetch selected invoices data.");
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        onClick={handleDownloadInvoicesPdfClick}
        disabled={selectedCount > SETTINGS.INVOICES_IN_ZIP_MAX}
        sx={{ whiteSpace: "nowrap" }}
        shape="squared"
        disabledType="opacity"
      >
        Download invoices
      </Button>
      <Button
        variant="primary"
        shape="squared"
        onClick={handlePaySelectedClick}
        disabled={isPaySelectedDisabled}
        disabledType="opacity"
        endIcon={isFetching ? <Loader size={20} color="inherit" /> : undefined}
      >
        Pay invoices
      </Button>
    </>
  );
};

const openUnpayableInvoicesSelectedInfoModal = () => {
  ModalInstance.open({
    children: (
      <ModalConfirm
        confirmText="Okay"
        cancelText="Cancel"
        onConfirm={ModalInstance.close}
      >
        <UnpayableInvoicesSelectedModalContent />
      </ModalConfirm>
    ),
  });
};

const UnpayableInvoicesSelectedModalContent = () => {
  const isMobile = useMediaQuery("sm");

  return (
    <>
      <Typography
        variant={isMobile ? "paragraph" : "title"}
        size={isMobile ? "large" : "small"}
        component="h3"
        fontWeight={400}
        sx={{ mb: { xs: 1, sm: 2 } }}
      >
        Some of selected invoices can't be paid.
      </Typography>
      <Typography
        variant="paragraph"
        size={isMobile ? "medium" : "large"}
        component="p"
      >
        Maybe they're already paid?
      </Typography>
    </>
  );
};
