import { FreelancerInvoicesLazy } from "../lazy";

import { FreelancerDashboardLayout } from "./layout";

export const FreelancerDashboardInvoicesPage = () => (
  <FreelancerDashboardLayout
    pageTitle="Invoices"
    contentClassName="my-jobs-dashboard-layout"
  >
    <FreelancerInvoicesLazy onlyMobileMenu />
  </FreelancerDashboardLayout>
);
