import { SubmissionError } from "redux-form";
import axios from "axios";

import {
  DELETE_USER_SESSION,
  FETCHING_USER_SESSIONS,
  FETCHING_USER_SESSIONS_FAILED,
  FETCHING_USER_SESSIONS_SUCCESS,
} from "@js/apps/settings/action-types";
import { Snackbar } from "@js/components/snackbar";
import type { AppThunkAction } from "@js/store";

export const saveAccountInfo = (values) => {
  return new Promise((resolve, reject) =>
    axios
      .patch(`/api/account_settings/account_info/`, values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

export const updateAccountInfo = (values) => {
  return new Promise((resolve, reject) =>
    axios
      .patch(`/api/account_settings/account_info/`, values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

export const fetchActiveSessions =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCHING_USER_SESSIONS,
      });

      return axios
        .get(`/api/user_sessions/`)
        .then((response) => {
          dispatch({
            type: FETCHING_USER_SESSIONS_SUCCESS,
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: FETCHING_USER_SESSIONS_FAILED,
          });
          reject(error.response.data);
        });
    });

export const deleteActiveSession =
  (id): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      return axios
        .delete(`/api/user_sessions/${id}/`)
        .then((response) => {
          dispatch({
            type: DELETE_USER_SESSION,
            payload: id,
          });

          resolve(response.data);
        })
        .catch((error) => reject(error.response.data));
    });

export const deleteAccount = () => {
  return axios
    .post("/api/user/delete/")
    .then(() => {
      Snackbar.info("Please check your email to confirm account deleting.");
    })
    .catch((error) => {
      Snackbar.error(
        `Failed to delete your account. ${
          error.response.data._error || "Unknown error"
        }.`,
        { persist: true },
      );
    });
};
