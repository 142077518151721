import React, { useEffect, useState } from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Sanitize } from "@js/components/sanitize";
import { Snackbar } from "@js/components/snackbar";
import { useQueryParams } from "@js/hooks";
import type { CanWithdrawToken } from "@js/types/dashboard";
import { getEnumLabel } from "@js/utils";

import type { CanConfirmTransactionResponse } from "../../api";
import {
  useCanConfirmWithdrawalTransactionMutation,
  useGetCanWithdrawTokensQuery,
  useGetWalletBalanceQuery,
} from "../../api";
import {
  openConfirmWithdrawalTransactionModal,
  WithdrawalModal,
} from "../../components";

import { useOnConfirmWithdrawal } from "./use-on-confirm-withdrawal";

export const useMyWalletPage = () => {
  const query = useQueryParams();
  const code: string | undefined = query.code;

  const user = useUser();

  const userBalanceId = Number(user?.balance_holder);
  const { data: balance, isLoading: isBalanceLoading } =
    useGetWalletBalanceQuery({ balanceHolderId: userBalanceId });
  const { data: canWithdrawTokens, refetch } = useGetCanWithdrawTokensQuery();
  const [canConfirmWithdrawalTransaction] =
    useCanConfirmWithdrawalTransactionMutation();
  const onConfirmWithdrawal = useOnConfirmWithdrawal({ code });

  const reason =
    canWithdrawTokens?.reason ||
    ENUMS.ExternalTokenWithdrawalRejectReason.TOKEN_WITHDRAWALS_DISABLED;

  const disabledReason: string = getEnumLabel(
    ENUMS.ExternalTokenWithdrawalRejectReasonLabels,
    reason,
  );
  const areWithdrawalsEnabled =
    SETTINGS.TOKEN_WITHDRAWALS_ENABLED && canWithdrawTokens?.can_withdraw_token;

  const [alerts, setAlerts] = useState<JSX.Element | null>(null);
  const isWithdrawalLimitsExceeded =
    reason === ENUMS.ExternalTokenWithdrawalRejectReason.LIMIT_EXCEEDED;
  const detailReason = canWithdrawTokens?.details;

  useEffect(() => {
    if (!code) {
      return;
    }

    canConfirmWithdrawalTransaction({ code })
      .unwrap()
      .then((transactionResponse: CanConfirmTransactionResponse) => {
        openConfirmWithdrawalTransactionModal({
          onConfirm: () =>
            onConfirmWithdrawal({ transactionResponse, setAlerts }),
          transactionResponse,
        });
      })
      .catch((error) => {
        Snackbar.error(
          error?.data?.code ||
            "There was an issue with the withdrawal transaction confirmation.",
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const onClickWithdrawalButton = async () => {
    if (!SETTINGS.TOKEN_WITHDRAWALS_ENABLED) {
      return;
    }

    const { data, isError } = await refetch();
    if (isError) {
      Snackbar.error(
        "Sorry! Something went wrong during fetching withdrawal status.",
      );

      return;
    }

    if (!data?.can_withdraw_token) {
      return openWarningModal(data);
    }

    if (!user?.is_otp_enabled) {
      return openOTPDisabledWarningModal();
    }

    WithdrawalModal.openModal();
  };

  return {
    loading: isBalanceLoading,
    balance,
    areWithdrawalsEnabled,
    onClickWithdrawalButton,
    disabledReason,
    alerts,
    isWithdrawalLimitsExceeded,
    detailReason,
  };
};

const openWarningModal = (canWithdrawTokens: CanWithdrawToken | undefined) => {
  const reason =
    canWithdrawTokens?.reason ||
    ENUMS.ExternalTokenWithdrawalRejectReason.TOKEN_WITHDRAWALS_DISABLED;

  const disabledReason: string = getEnumLabel(
    ENUMS.ExternalTokenWithdrawalRejectReasonLabels,
    reason,
  );

  let warningModalContent: React.ReactNode = disabledReason;
  let warningModalConfirmText = "Ok";
  let warningModalOnConfirm = () => ModalInstance.close();

  if (!SETTINGS.TOKEN_WITHDRAWALS_ENABLED) {
    if (SETTINGS.TOKEN_WITHDRAWALS_DISABLING_REASON) {
      warningModalContent = (
        <Sanitize
          input={SETTINGS.TOKEN_WITHDRAWALS_DISABLING_REASON}
          unescape
        />
      );
    } else {
      warningModalConfirmText = "Read more";
      warningModalOnConfirm = () => {
        ModalInstance.close();
        window.location.href = "https://www.usebraintrust.com/referral-fti";
      };
      warningModalContent = (
        <Typography component="p" mr={1}>
          BTRST tokens will be available for transfer when FTI conditions are
          met.
        </Typography>
      );
    }
  }

  ModalInstance.open({
    children: (
      <ModalConfirm
        onConfirm={warningModalOnConfirm}
        confirmText={warningModalConfirmText}
      >
        {warningModalContent}
      </ModalConfirm>
    ),
    className: "max-width-400",
  });
};
