import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";

import { Snackbar } from "@js/components/snackbar";
import { useParsedQueryParamsWithDefaults } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { useSubmitFormOnChange } from "@js/rhf/hooks/submit-form-on-change";

import type { EmployerMyTalentFormValues } from "../schemas";
import {
  employerMyTalentFormSchema,
  type EmployerMyTalentFormValuesWithDefaults,
} from "../schemas";

const defaultFormValues = {
  search: null,
  status: [],
  jobs: [],
  job_owners: [],
  rate_types: [],
  roles: [],
  order_dir: undefined,
  order_by: undefined,
  page: 1,
} satisfies EmployerMyTalentFormValuesWithDefaults;

export const initialFormValues = {
  status: [ENUMS.IsActiveStatus.ACTIVE],
  order_dir: "asc",
  order_by: "name",
} satisfies EmployerMyTalentFormValues;

export const useMyTalentFilters = () => {
  const [, setSearchParams] = useSearchParams();
  const { data: formDataFromSearchParams, success } =
    useParsedQueryParamsWithDefaults(
      employerMyTalentFormSchema,
      defaultFormValues,
    );

  const { control, setValue, handleSubmit, watch, reset, formState } =
    useForm<EmployerMyTalentFormValues>({
      resolver: zodResolver(employerMyTalentFormSchema),
      mode: "onSubmit",
      values: formDataFromSearchParams,
    });

  useSubmitFormOnChange({ watch, handleSubmit, formState });

  const onReset = useCallback(() => {
    reset(defaultFormValues, {
      keepDefaultValues: true,
    });
  }, [reset]);

  const setSearchParamsRef = useEffectRef(setSearchParams);

  useEffect(() => {
    if (success) {
      return;
    }

    setSearchParamsRef.current();
    Snackbar.error(
      "Invalid filters detected. Please try again with different settings.",
    );
  }, [success, setSearchParamsRef]);

  return {
    control,
    setValue,
    filters: formDataFromSearchParams,
    onReset,
  };
};
