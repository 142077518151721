import { IconButton, useMediaQuery } from "@hexocean/braintrust-ui-components";
import { MessageIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { MyWorkBaseCard } from "@js/apps/freelancer/components";
import {
  MyWorkCardBasicInfo,
  PaymentAmount,
} from "@js/apps/freelancer/components/my-work-base-component/components";
import { JobsOfferActionButton, RolesTag } from "@js/apps/jobs/components";
import { ApplicationStatusWithTooltip } from "@js/apps/jobs/components/application-status";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import type { FreelancerOffer } from "@js/types/jobs";

export const OfferItem = ({ offer }: { offer: FreelancerOffer }) => {
  const user = useUser();
  const isMobile = useMediaQuery("sm");
  const { openMessengerModal } = useOpenMessengerModal();

  return (
    <MyWorkBaseCard
      job={offer.job}
      infoSlot={<MyWorkCardBasicInfo job={offer.job} />}
      roleSlot={<RolesTag role={offer.role} />}
      statusSlot={
        <ApplicationStatusWithTooltip
          status={offer.status_for_freelancer}
          offerAcceptedDate={offer.accepted_at}
          offerCreatedDate={offer.created}
        />
      }
      rateSlot={
        <PaymentAmount
          paymentAmount={offer.payment_amount}
          paymentType={offer.payment_type}
        />
      }
      ctaSlot={
        <>
          <JobsOfferActionButton
            jobId={offer.job.id}
            offerId={offer.id}
            statusForFreelancer={offer.status_for_freelancer}
            size={isMobile ? "x-small" : "medium"}
          />

          <IconButton
            variant="transparent-border-beige"
            shape="squared"
            size={isMobile ? "x-small" : "medium"}
            aria-label="Application reactions"
            onClick={() =>
              user &&
              openMessengerModal({
                context: {
                  participants: [user?.id, offer.job.creator.id],
                },
              })
            }
          >
            <MessageIcon style={{ fontSize: "16px" }} />
          </IconButton>
        </>
      }
    />
  );
};
