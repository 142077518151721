import { Field, Fields, reduxForm } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Form } from "@js/forms/components/form";
import { CheckboxField, TextField } from "@js/forms/fields";
import { enumToOptions } from "@js/utils";

type BidRejectionFeedbackFormProps = {
  handleGoBackClick: () => void;
};

export const BidRejectionFeedbackForm = reduxForm<
  any,
  BidRejectionFeedbackFormProps
>({
  form: "bid-rejection-form",
  onChange: (values, dispatch, props) => {
    if (!values.other && props.change) {
      dispatch(props.change("reason_other", null));
    }
  },
})(({ submitting, submit, error, handleGoBackClick }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Box
        sx={{
          bgcolor: "var(--soft-orange)",
          height: 88,
          py: 2.5,
          px: { xs: 2.5, sm: 10 },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={handleGoBackClick}
          variant="transparent"
          startIcon={<CloseIcon />}
        >
          Go back
        </Button>
        <Button type="submit" shape="squared" variant="medium-orange">
          Send rejection
        </Button>
      </Box>

      <Box
        px={{ xs: 5, sm: 10 }}
        display="flex"
        mt={7}
        mx="auto"
        flexDirection="column"
        maxWidth={740}
      >
        <Typography
          component="h3"
          fontWeight={400}
          variant="title"
          size="large"
          mb={4}
        >
          Add feedback
        </Typography>
        <Box>
          {enumToOptions(ENUMS.BidNotHiringReasonTypeLabels).map(
            ({ value, label }) => {
              return (
                <Field
                  key={value}
                  name={value}
                  id={value}
                  customValue={label}
                  label={label}
                  component={CheckboxField}
                />
              );
            },
          )}
        </Box>
        <Fields
          names={["reason_other", "other"]}
          component={OtherReasonFiled}
        />
      </Box>
    </Form>
  );
});

const OtherReasonFiled = ({ other }) => {
  if (!other.input.value) {
    return null;
  }

  return (
    <Field
      className="mb+"
      variant="standard"
      id="reason_other"
      name="reason_other"
      component={TextField}
      label="Other"
    />
  );
};
