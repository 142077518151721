import { useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  createTaxonomyItem,
  searchTaxonomyItems,
} from "@js/apps/common/actions";
import { useUser } from "@js/apps/common/hooks";
import {
  createNewSchool,
  deleteSchool,
  updateSchool,
} from "@js/apps/freelancer/actions";
import { CommonConfirmationModal, ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";
import type { Degree, School } from "@js/types/common";
import { deepClone, isEqualTaxonomyName } from "@js/utils";
import { getDateYear } from "@js/utils";
import type { IsoDate } from "@js/utils/date/types";

import { freelancerApi, useGetFreelancerPublicProfileQuery } from "../../api";
import { ADD_ANOTHER_ITEM } from "../../constants";
import type { FormData } from "../../forms/education-form";
import { EDUCATION_FORM_ID } from "../../forms/education-form";

export const useEducation = () => {
  const dispatch = useAppDispatch();
  const [submitType, setSubmitType] = useState<string | null>(null);

  const freelancerId = useUser()?.freelancer;
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    freelancerId ?? skipToken,
  );

  const createSchool = (phrase: string) =>
    dispatch(createTaxonomyItem(phrase, "schools", "new_school"));
  const createDegree = (phrase: string) =>
    dispatch(createTaxonomyItem(phrase, "degrees", "degree"));

  const onSubmit = async (values) => {
    const newValue = deepClone(values);

    if (newValue.new_school) {
      const foundSchools = (await searchTaxonomyItems({
        phrase: newValue.new_school,
        endpoint: "schools",
        immediate: true,
        params: {},
        dispatch,
      })) as School[];

      let school = foundSchools.find(
        ({ name }) => name === newValue.new_school,
      );

      if (!school || !isEqualTaxonomyName(school.name, newValue.new_school)) {
        school = (await createSchool(newValue.new_school)) as School;
      }

      newValue.new_school = school.id;
    }

    if (newValue.degree) {
      const foundDegrees = (await searchTaxonomyItems({
        phrase: newValue.degree,
        endpoint: "degrees",
        immediate: true,
        params: {},
        dispatch,
      })) as Degree[];
      let degree = foundDegrees.find(({ name }) => name === newValue.degree);

      if (!degree || !isEqualTaxonomyName(degree.name, newValue.degree)) {
        degree = (await createDegree(newValue.degree)) as Degree;
      }

      newValue.degree = degree.id;
    }

    if (newValue.graduation_date) {
      newValue.graduation_date = `${newValue.graduation_date}-01-01`;
    }

    return values.id
      ? dispatch(updateSchool(values.id, newValue))
      : dispatch(createNewSchool(newValue));
  };

  const onSubmitSuccess = async (_, methodDispatch, props) => {
    methodDispatch(
      freelancerApi.util.invalidateTags(["FreelancerPublicProfile"]),
    );

    if (submitType === ADD_ANOTHER_ITEM) {
      props.reset(EDUCATION_FORM_ID);
      setSubmitType(null);
    } else {
      ModalInstance.close();
    }
  };

  const getInitialValues = (
    editedEducationId: number,
  ): FormData | undefined => {
    if (!profile) return;

    const { freelancer_schools } = profile;

    const editedEducation = freelancer_schools.find(
      (education) => education.id === editedEducationId,
    );

    let initialValues = {};
    if (editedEducationId !== undefined && editedEducation) {
      initialValues = {
        id: editedEducation.id,
        new_school: editedEducation.school.name,
        degree: editedEducation.degree?.name,
        graduation_date: editedEducation.graduation_date
          ? String(getDateYear(editedEducation.graduation_date as IsoDate))
          : null,
      };
    }

    return initialValues as FormData;
  };

  const onDeleteEducation = async (educationId: number) => {
    await dispatch(deleteSchool(educationId));

    dispatch(freelancerApi.util.invalidateTags(["FreelancerPublicProfile"]));
    CommonConfirmationModal.close();
  };

  const onUpdateEducationSuccess = async () => {
    dispatch(freelancerApi.util.invalidateTags(["FreelancerPublicProfile"]));
    CommonConfirmationModal.close();
  };

  return {
    onSubmit,
    getInitialValues,
    onDeleteEducation,
    onSubmitSuccess,
    onUpdateEducationSuccess,
    setSubmitType,
    profile,
  };
};
