import { HourglassIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";

import { MY_TALENT_FILTER_TO_APPLY_LABEL } from "./constants";
import { EmployerMyTalentRateTypeForm } from "./forms";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentRateTypeFilterPopoverProps = {
  rateTypes: EmployerMyTalentFormValues["rate_types"];
  onChange: (rateTypes: EmployerMyTalentFormValues["rate_types"]) => void;
};

export const MyTalentRateTypeFilterPopover = ({
  rateTypes,
  onChange,
}: MyTalentRateTypeFilterPopoverProps) => {
  const isAnyStatusApplied = Boolean(rateTypes && rateTypes.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const handleApply = (
    newRateTypes: EmployerMyTalentFormValues["rate_types"],
  ) => {
    onChange(newRateTypes);
    handleClose();
  };

  return (
    <FilterPopover
      label={MY_TALENT_FILTER_TO_APPLY_LABEL.rate_types}
      icon={<HourglassIcon />}
      isFilterApplied={isAnyStatusApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handleClose}
      anchorElement={anchorElement}
    >
      <EmployerMyTalentRateTypeForm
        rateTypes={rateTypes}
        onApply={handleApply}
      />
    </FilterPopover>
  );
};
