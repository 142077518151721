import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";
import { type TypedWrappedFieldProps } from "redux-form";
import classNames from "classnames";

import {
  Box,
  FormHelperText,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { DownloadCloudIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { avatarFieldUploading } from "@js/apps/employer/actions";
import { useGetEmployerInfoQuery } from "@js/apps/employer/api";
import type { UploadType } from "@js/apps/file-upload";
import { useCropAndUploadImage } from "@js/apps/file-upload";
import { VALID_AVATAR_FORMATS } from "@js/forms/validators";
import { useAppDispatch } from "@js/hooks";

import { useAvatarField } from "./hooks";
import { OrganizationLogoOrAvatar } from "./organization-logo-or-avatar";

import styles from "./style.module.scss";

export type AvatarType = "talent" | "organization" | "employer";

/**
 * Example usage:
 *
 * ```
 * <Field
 *     name="avatar"
 *     updateFieldName="avatar_id"
 *     component={AvatarField}
 *     uploadType={ENUMS.UploadType.USER_AVATAR_IMAGE_UPLOAD} />
 * ```
 */

export type AvatarFieldProps = {
  maxSize?: number;
  minWidth?: number;
  minHeight?: number;
  customRef?: MutableRefObject<HTMLInputElement | undefined>;
  isMobile?: boolean;
  className?: string;
  label?: string;
  input: TypedWrappedFieldProps<string>["input"];
  meta: TypedWrappedFieldProps<string>["meta"];
  uploadType: UploadType;
  updateFieldName: string;
  title?: string;
  type: AvatarType;
};

export const AvatarField = (props: AvatarFieldProps) => {
  const {
    maxSize = SETTINGS.JOB_FILE_SIZE_LIMIT,
    label,
    minWidth = 200,
    minHeight = 200,
    customRef,
    isMobile = false,
    className,
    input: { name, value },
    meta: { error, touched, form },
    uploadType,
    updateFieldName,
    title,
    type,
  } = props;
  const user = useUser();
  const {
    cropAndUploadImage,
    error: uploadError,
    uploadedFile,
    isUploading: isLoading,
  } = useCropAndUploadImage();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { data: employerProfile } = useGetEmployerInfoQuery(
    { employerId: user?.employer as number },
    { skip: user === null || !user?.employer },
  );
  const dispatch = useAppDispatch();

  const { handleOnChange, previewImage } = useAvatarField({
    updateFieldName,
    uploadedFile,
    cropAndUploadImage,
    form,
    name,
    value,
    type,
    minWidth,
    minHeight,
    uploadType,
    maxSize,
    validFormats: VALID_AVATAR_FORMATS.split(", "),
  });

  const errorMessage = error || uploadError;

  const isDefaultOrganizationAvatar =
    type === "organization" &&
    employerProfile &&
    !employerProfile.logo_thumbnail;

  useEffect(() => {
    dispatch(avatarFieldUploading(isLoading));
  }, [dispatch, isLoading]);

  return (
    <>
      <label
        htmlFor={`avatar-field-${name}`}
        data-testid="avatar-field"
        className={classNames(className, styles.avatar, {
          [styles.avatarSquared]: type === "organization",
          [styles.avatarHasValue]: previewImage,
          [styles.avatarWhite]: isDefaultOrganizationAvatar,
        })}
      >
        <span className="sr-only">{label || "Avatar"}</span>
        <div className={styles.avatarContainer}>
          <input
            ref={(element) => {
              inputRef.current = element;
              if (customRef) customRef.current = element ?? undefined;
            }}
            tabIndex={-1}
            className="sr-only"
            accept={VALID_AVATAR_FORMATS}
            id={`avatar-field-${name}`}
            onChange={handleOnChange}
            type="file"
            data-testid="avatar-field-input"
          />
          <div className={styles.avatarPlaceholder}>
            <DownloadCloudIcon className={styles.placeholderIcon} />
            <Typography component="p" variant="label">
              Upload
            </Typography>
          </div>
          <OrganizationLogoOrAvatar
            user={user}
            employerProfile={employerProfile}
            src={previewImage}
            className={styles.avatarImage}
            title={title}
            type={type}
          />
          {isLoading && <Loader />}
        </div>
        {touched && !!errorMessage && (
          <Box sx={{ mb: isMobile ? "50%" : "30%" }}>
            <FormHelperText className={styles.error} error>
              {errorMessage}
            </FormHelperText>
          </Box>
        )}
      </label>
    </>
  );
};
