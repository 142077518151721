import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Box } from "@hexocean/braintrust-ui-components";
import {
  FilterPopoverFooter,
  FilterPopoverFormLayoutWrapper,
} from "@js/apps/employer/components/filter-popover";
import type { RhfForm } from "@js/rhf/types";

import { CheckboxButton } from "../checkbox-button";
import { employerMyTalentStatusFormSchema } from "../schemas";

import style from "../style.module.scss";

type Form = RhfForm<typeof employerMyTalentStatusFormSchema>;
type FormValues = Form["Values"];
type DefaultFormValues = Form["DefaultValues"];

type EmployerMyTalentStatusFormProps = {
  status: DefaultFormValues["status"];
  onApply: (status: FormValues["status"]) => void;
};

export const EmployerMyTalentStatusForm = ({
  status,
  onApply,
}: EmployerMyTalentStatusFormProps) => {
  const defaultValues = useMemo(() => {
    return { status };
  }, [status]);
  const { handleSubmit, control, getValues, reset, watch } =
    useForm<FormValues>({
      resolver: zodResolver(employerMyTalentStatusFormSchema),
      defaultValues,
    });

  const { field } = useController({
    name: "status",
    control: control,
  });

  const handleCheck = (checkboxId: EnumType<typeof ENUMS.IsActiveStatus>) => {
    const formValue = getValues("status");

    if (!formValue) {
      return [checkboxId];
    }

    const nextStatus = formValue.includes(checkboxId)
      ? formValue.filter((val) => {
          return val !== checkboxId;
        })
      : [...formValue, checkboxId];

    return nextStatus;
  };

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onApply(values.status);
  };

  const onReset = () => {
    reset({ status: [] });
  };

  const statusFieldValues = watch("status");

  const isActiveCheckboxSelected = Boolean(
    statusFieldValues &&
      statusFieldValues.includes(ENUMS.IsActiveStatus.ACTIVE),
  );

  const isInactiveCheckboxSelected = Boolean(
    statusFieldValues &&
      statusFieldValues.includes(ENUMS.IsActiveStatus.INACTIVE),
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <Box className={style.filterCheckboxContainer}>
            <CheckboxButton
              isSelected={isActiveCheckboxSelected}
              label="Active"
              onClick={() => {
                field.onChange(handleCheck(ENUMS.IsActiveStatus.ACTIVE));
              }}
            />

            <CheckboxButton
              isSelected={isInactiveCheckboxSelected}
              label="Inactive"
              onClick={() => {
                field.onChange(handleCheck(ENUMS.IsActiveStatus.INACTIVE));
              }}
            />
          </Box>
        }
        formFooter={<FilterPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
