import React, { useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import URI from "urijs";

import { logout } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks/use-user";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { useOnce } from "@js/hooks/use-once";
import {
  deleteAmplitudeCookies,
  pruneUnsentTrackingEvents,
} from "@js/services/analytics/utils";

import { useNavigate } from "../hooks";

import type { ACCESS_RULE } from "./constants";
import { canEnterProtectedRoute } from "./utils";

type ProtectedRouteProps = {
  rules: EnumType<typeof ACCESS_RULE>[];
  onEnter?(): Promise<void> | void;
  onLeave?(): Promise<void> | void;
};

export const ProtectedRoute: React.FC<
  React.PropsWithChildren<ProtectedRouteProps>
> = ({ rules, children }) => {
  const user = useUser();
  const userRef = useEffectRef(user);

  const location = useLocation();

  // do not recalculate on user change so we can control switching routes with navigation whenever user data is updated
  const canVisit = useMemo<{ can: boolean; to: string }>(() => {
    return canEnterProtectedRoute({ rules, location, user: userRef.current });
  }, [rules, userRef, location]);

  if (process.env.NODE_ENV === "test") return <>{children}</>;

  return canVisit.can ? (
    <>{children}</>
  ) : (
    <Navigate to={canVisit.to as string} />
  );
};

export const RedirectAuthenticatedUserFromLoginToNext = ({ children }) => {
  const navigate = useNavigate();
  const userId = useUser()?.id;

  const next = new URI().search(true).next;

  useEffect(() => {
    if (userId && next) {
      navigate(next, { replace: true });
    }
  }, [userId, next, navigate]);

  return children;
};

export const Logout = () => {
  const user = useUser();
  const location = useLocation();
  const prevPath = location.state?.prevPath;

  useOnce(() => {
    //preventing the user from logging out using the /auth/logout/ path directly in the browser or via an external link
    if (!prevPath) {
      window.location.href = "/";
      return;
    }

    deleteAmplitudeCookies();
    pruneUnsentTrackingEvents();

    logout()
      .catch(() => null)
      .finally(() => {
        // finally does not execute every time due to 302 redirect on logout
        const redirectURL = user?.is_impersonated_session
          ? user?.logout_redirect_url
          : "/";
        window.location.href = redirectURL;
      });
  });

  return null;
};
