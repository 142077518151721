import { useGetFreelancerJobOffersQuery } from "@js/apps/freelancer/api";

export const useFreelancerOffers = (showClosedJobOffers: boolean) => {
  const { isLoading: isLoadingCurrentJobOffers, data: currentJobOffers = [] } =
    useGetFreelancerJobOffersQuery({
      section: ENUMS.TalentOffersSectionFilter.CURRENT,
    });

  const { isLoading: isLoadingPastJobOffers, data: pastJobOffers = [] } =
    useGetFreelancerJobOffersQuery(
      {
        section: ENUMS.TalentOffersSectionFilter.PAST,
      },
      { skip: !showClosedJobOffers },
    );

  const jobOffers = showClosedJobOffers
    ? [...currentJobOffers, ...pastJobOffers]
    : currentJobOffers;

  return {
    isLoading: isLoadingCurrentJobOffers || isLoadingPastJobOffers,
    jobOffers,
  };
};
