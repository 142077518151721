import { ModalInstance } from "@js/components/modal";

import { ApplicationOnrampModal, type ApplicationOnrampModalProps } from ".";

export const openApplicationOnrampModal = (
  upgradeType: ApplicationOnrampModalProps["upgradeType"],
) => {
  ModalInstance.open({
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },
    children: <ApplicationOnrampModal upgradeType={upgradeType} />,
  });
};
