import { Stack } from "@hexocean/braintrust-ui-components";

import { MyTalentStats } from "./my-talent-stats";
import { MyTalentTable } from "./my-talent-table";
import { MyTalentTitle } from "./my-talent-title";

export const EmployerMyTalentComponent = () => {
  return (
    <Stack sx={{ gap: 3 }}>
      <MyTalentTitle />
      <MyTalentStats />
      <MyTalentTable />
    </Stack>
  );
};
