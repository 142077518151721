import { PenIcon } from "@hexocean/braintrust-ui-components/Icons";

import { FilterButtonPopover } from "./filter-button-popover";
import { EmployerMyTalentRoleForm } from "./forms";
import { useFilterPopover, useIsPopoverRendered } from "./hooks";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentRoleFilterProps = {
  roles: EmployerMyTalentFormValues["roles"];
  onChange: (roles: EmployerMyTalentFormValues["roles"]) => void;
};

export const MyTalentRoleFilterPopover = ({
  roles,
  onChange,
}: MyTalentRoleFilterProps) => {
  const isAnyJobApplied = Boolean(roles && roles.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, isPopoverRendered } =
    useIsPopoverRendered();

  const handleApply = (newRoles: EmployerMyTalentFormValues["roles"]) => {
    onChange(newRoles);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterButtonPopover
      label="Role"
      icon={<PenIcon />}
      isFilterApplied={isAnyJobApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
    >
      <EmployerMyTalentRoleForm
        roles={roles}
        onApply={handleApply}
        shouldOpenDropdown={isPopoverRendered}
      />
    </FilterButtonPopover>
  );
};
