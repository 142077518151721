import URI from "urijs";

import { Stack, useMediaQuery } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ChatIcon,
  MessageIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks/use-user";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { ApplicationQAList } from "@js/apps/jobs/components/application-qa-list";
import { InvitedBadge } from "@js/apps/jobs/components/invited-badge";
import {
  JobInterviewGradingSummary,
  JobInterviewPendingGradingSummary,
} from "@js/apps/jobs/components/job-interview-grading-summary";
import { RecommendationBadge } from "@js/apps/jobs/components/recommendation-badge";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks/can-manage-job-as-core-member";
import type {
  ExpandBid,
  IsBidExpanded,
} from "@js/apps/jobs/hooks/expanded-bids";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { JobSkillChip } from "@js/apps/skills/components";
import { RouterLink } from "@js/components/link";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { BidActionsMenu } from "../../bid-actions-menu";
import { BidCTAButton } from "../../bid-cta-button";
import { BidMatchScoreBadge } from "../../bid-score-match-bagde";
import { BoostBidBadge } from "../../boosted-bid/boosted-bid-badge";
import { setIndexToScrollTo } from "../../virtualized-job-bid-list/rendered-item-index";
import { getBidItemStatusMeta, useGenerateLinkAction } from "../helpers";

export const SkillsAndQASection = ({
  bid,
  link,
  shouldLinkOpenOnParent,
}: {
  bid: EmployerBidListBid;
  link: string;
  shouldLinkOpenOnParent?: boolean;
}) => {
  const parentProps = useGenerateLinkAction({
    link: shouldLinkOpenOnParent ? link : "",
  });

  return (
    <>
      <Box {...parentProps}>
        <Box mb={2}>
          <Grid container spacing={1}>
            {bid.freelancer.superpower_skills.map((item) => (
              <Grid item key={item.id}>
                <JobSkillChip skill={item.skill} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <ApplicationQAList
          questions={bid.application_questions}
          answers={bid.application_answers}
          showSingleQuestion
          height={45}
          link={link}
        />
      </Box>
      <Divider color="beige" style={{ marginBottom: "12px" }} />
    </>
  );
};

type ShowCommentsBtnProps = {
  bidId: number;
  isBidExpanded: IsBidExpanded | null;
  expandBid: ExpandBid | null;
  commentsCount: number;
};

export const ShowCommentsBtn = ({
  bidId,
  isBidExpanded,
  expandBid,
  commentsCount,
}: ShowCommentsBtnProps) => {
  return (
    <IconButton
      variant={
        isBidExpanded && isBidExpanded(bidId)
          ? "white-border-grey-2"
          : "white-border-beige"
      }
      shape="squared"
      size="medium"
      aria-label="Application comments"
      sx={{
        height: "42px",
      }}
      onClick={() => (expandBid ? expandBid(bidId) : null)}
    >
      {!!commentsCount && (
        <Typography component="span" marginRight="5px">
          {commentsCount}
        </Typography>
      )}
      <ChatIcon style={{ fontSize: "19px" }} />
    </IconButton>
  );
};

type CTASectionProps = {
  bid: EmployerBidListBid;
  job: Job;
  link: string;
  index?: number;
};

export const CTASection = ({ bid, job, link, index }: CTASectionProps) => {
  const { openMessengerModal } = useOpenMessengerModal();
  const user = useUser();
  const canManageOnBehalfOfAClient = useCanManageJobsOnBehalfOfClient();
  return (
    <>
      {bid.interview?.scorecard_completed_at && (
        <Button
          shape="squared"
          variant="white-border-beige"
          to={new URI(link).setQuery("tab", "interview").toString()}
          size={"x-small"}
          state={{ noScroll: true }}
          sx={{
            height: "42px",
            whiteSpace: "nowrap",
            flexShrink: 0,
            marginLeft: { sm: "auto" },
          }}
          RouterLink={RouterLink}
        >
          View scorecard
        </Button>
      )}
      {(job.user_is_owner || canManageOnBehalfOfAClient) && (
        <Box
          sx={{
            display: "grid",
            whiteSpace: "nowrap",
            marginLeft: {
              sm: bid.interview?.scorecard_completed_at ? "" : "auto",
            },
          }}
        >
          <BidCTAButton bid={bid} job={job} index={index} onlyInterview />
        </Box>
      )}
      {(!bid.interview || bid.status !== ENUMS.BidStatus.INTERVIEWING) && (
        <Button
          shape="squared"
          variant="white-border-beige"
          to={link}
          size={"x-small"}
          state={{ noScroll: true }}
          sx={{
            height: "42px",
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
          RouterLink={RouterLink}
          onClick={() => {
            if (!index) {
              return;
            }

            setIndexToScrollTo(index);
          }}
        >
          View application
        </Button>
      )}
      {bid.freelancer.user.can_be_messaged && (
        <IconButton
          variant="white-border-beige"
          shape="squared"
          size="medium"
          aria-label="Application reactions"
          sx={{
            height: "42px",
            width: "42px",
          }}
          onClick={() =>
            user &&
            openMessengerModal({
              context: {
                participants: [user?.id, bid.freelancer.user.id],
              },
            })
          }
        >
          <MessageIcon style={{ fontSize: "16px" }} />
        </IconButton>
      )}
      <BidActionsMenu
        bid={bid}
        job={job}
        sx={{
          width: "42px",
          justifySelf: "flex-end",
        }}
        index={index}
        includeMakeOfferAction
      />
    </>
  );
};

export const BidCardLabels = ({
  bid,
  job,
}: {
  bid: EmployerBidListBid;
  job: Job;
}) => {
  const isMobile = useMediaQuery("sm");
  const direction = !isMobile ? "row" : "column";
  const rowGap = isMobile ? 1 : 0;
  const columnGap = isMobile ? 0 : 1;
  return (
    <Stack
      flexWrap={"wrap"}
      direction={direction}
      columnGap={columnGap}
      rowGap={rowGap}
    >
      <BidMatchScoreBadge bid={bid} job={job} />
      <ApplicationBoostFeatureFlagWrapper>
        {bid.is_boosted && <BoostBidBadge />}
      </ApplicationBoostFeatureFlagWrapper>
      <InvitedBadge bid={bid} />
      <RecommendationBadge bid={bid} />
    </Stack>
  );
};

type BidItemStatusLabelProps = {
  bid: EmployerBidListBid;
};

export const BidItemStatusLabel = ({ bid }: BidItemStatusLabelProps) => {
  const { label, color } = getBidItemStatusMeta(
    bid.status,
    bid.interview?.status,
  );

  if (!label) return null;

  return (
    <Typography component="h4" variant="paragraph" sx={{ color }}>
      {label}
    </Typography>
  );
};

export const BidItemGradingSummary = ({
  bid,
  link,
}: {
  bid: EmployerBidListBid;
  link?: string;
}) => {
  const props = useGenerateLinkAction({ link });

  if (!bid.interview) {
    return null;
  }

  if (!bid.interview.is_completed) {
    return <JobInterviewPendingGradingSummary bid={bid} />;
  }

  return (
    <Box {...props}>
      <JobInterviewGradingSummary
        titleIcon={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
        grade={parseFloat(bid.interview.overall_score?.toString() || "")}
        title={"AI Interview summary"}
        description={bid.interview.overall_feedback || "Summary not available"}
        isCollapsed
      />
      <Divider
        color="beige"
        style={{ marginBottom: "12px", marginTop: "12px" }}
      />
    </Box>
  );
};
