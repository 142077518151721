import type { GridSlotProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import styles from "./my-talent-table.module.scss";

export const MyTalentNoDataEmptyState = () => {
  return (
    <Box className={styles.tableEmptyState}>
      <Box>
        <Typography component="p" variant="label">
          Your talent will appear here.
        </Typography>
        <Typography component="p" mt={1}>
          Once you hire your first talent, they’ll appear here.
        </Typography>
      </Box>

      <Box display="flex" gap={1} justifyContent="center">
        <Button
          size="x-small"
          variant="primary"
          RouterLink={RouterLink}
          to="/jobs/add_new/"
        >
          Post a job
        </Button>
        <Button
          size="x-small"
          variant="secondary"
          RouterLink={RouterLink}
          to="/talent"
        >
          Browse talent
        </Button>
      </Box>
    </Box>
  );
};

type MyTalentTableEmptyStateProps = {
  onResetFilters: () => void;
} & GridSlotProps["noRowsOverlay"];

export const MyTalentTableEmptyState = ({
  onResetFilters,
}: MyTalentTableEmptyStateProps) => {
  return (
    <Box className={styles.tableEmptyState}>
      <Box>
        <Typography component="p" variant="label">
          No talent found
        </Typography>
        <Typography component="p" mt={1}>
          Your search did not match any talent.
        </Typography>
      </Box>

      <Box display="flex" gap={1} justifyContent="center">
        <Button size="x-small" variant="secondary" onClick={onResetFilters}>
          Clear search
        </Button>
      </Box>
    </Box>
  );
};
