import { useEffect, useMemo } from "react";

import { useUser } from "@js/apps/common/hooks/use-user";
import { employerInvoicesSortOrderChanged } from "@js/apps/employer/actions";
import { markEmployerInvoicesAsPaid } from "@js/apps/invoices/actions";
import {
  employerInvoicesApi,
  useGetEmployerInvoicesQuery,
  useGetEmployerInvoiceStatisticsQuery,
} from "@js/apps/invoices/api";
import {
  closeInvoicePreviewModal,
  openInvoicePreviewModal,
} from "@js/apps/invoices/components/invoice-preview";
import { useEmployerInvoiceListingContext } from "@js/apps/invoices/context/employer-invoice-listing";
import { openPaymentModal } from "@js/apps/payments/components/payment-modal";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { EmployerInvoice, Invoice } from "@js/types/invoices";
import { removeNullValues } from "@js/utils/data";

import { useEmployerInvoicesFilters } from "../employer-invoices-filters";

import { EMPLOYER_INVOICES_TABLE_COLUMNS } from "./columns";
import type { EmployerInvoicesTableComponentProps } from "./employer-invoices-table-component";

export const useEmployerInvoicesTable = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const {
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    resetSelectedInvoicesIds,
  } = useEmployerInvoiceListingContext();

  const onFiltersChange = () => {
    resetSelectedInvoicesIds();
  };

  const { filters, control, clearFilters, setValue } =
    useEmployerInvoicesFilters({ onFiltersChange });

  const mappedFilters = useMemo(() => removeNullValues(filters), [filters]);
  const {
    data: invoicesResponse,
    isFetching: isFetchingEmployerInvoices,
    isLoading: isLoadingEmployerInvoices,
    isError: isErrorEmployerInvoices,
  } = useGetEmployerInvoicesQuery(mappedFilters, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: employerInvoicesStats,
    isLoading: isLoadingEmployerInvoicesStats,
    isError: isErrorEmployerInvoicesStats,
    isFetching: isFetchingEmployerInvoicesStats,
  } = useGetEmployerInvoiceStatisticsQuery();

  const isError = isErrorEmployerInvoices || isErrorEmployerInvoicesStats;
  const isFetching =
    isFetchingEmployerInvoices || isFetchingEmployerInvoicesStats;

  const totalInvoicesCountWithoutFilters =
    employerInvoicesStats?.total_invoices_count ?? 0;

  useEffect(() => {
    if (!isError) {
      return;
    }

    Snackbar.error("Something went wrong.");
  }, [isError]);

  const totalInvoicesCount = invoicesResponse?.count ?? 0;
  const invoices = useMemo(() => {
    return invoicesResponse?.results ?? [];
  }, [invoicesResponse]);

  const invalidateData = () => {
    dispatch(
      employerInvoicesApi.util.invalidateTags([
        "EmployerInvoiceStatusCounts",
        "EmployerInvoiceStatistics",
        "EmployerInvoicesMinimal",
        "EmployerInvoicesIds",
        "EmployerInvoices",
      ]),
    );
  };

  const onCloseSuccessModal = () => {
    closeInvoicePreviewModal();
    resetSelectedInvoicesIds();
  };

  const startSelectedInvoicesPaymentFlow = () => {
    startPaymentFlow(selectedInvoicesIds);
  };

  const startPaymentFlow = (invoiceIds: number[]) => {
    if (!user?.is_otp_enabled) {
      openOTPDisabledWarningModal();
      return;
    }

    openPaymentModal({
      onCloseSuccessModal,
      invoiceIds,
    });
  };

  const handleSingleInvoicePayment = (invoice: Invoice) => {
    setSelectedInvoicesIds([invoice.id]);
    startPaymentFlow([invoice.id]);
  };

  const onInvoicesMarkedAsPaid = () => {
    resetSelectedInvoicesIds();
  };

  const markInvoiceAsPaid = async (invoice: EmployerInvoice) => {
    return markEmployerInvoicesAsPaid([invoice.id])
      .then(() => {
        invalidateData();
        onInvoicesMarkedAsPaid();
        Snackbar.success("Successfully marked as paid.");
      })
      .catch((error) => Snackbar.error(error));
  };

  const openInvoicePreview = (invoice: EmployerInvoice) => {
    openInvoicePreviewModal({
      onPay: handleSingleInvoicePayment,
      markInvoiceAsPaidCallback: () => {
        invalidateData();
        onInvoicesMarkedAsPaid();
        closeInvoicePreviewModal();
      },
      invoice,
      invoiceEndpoint: "employer_invoices",
    });
  };

  const handleSortChange: EmployerInvoicesTableComponentProps["onSortChange"] =
    ({ sortBy, sortDir }) => {
      setValue("order_by", sortBy, { shouldDirty: true });
      setValue("order_dir", sortDir, { shouldDirty: true });

      if (!sortBy) {
        return;
      }

      dispatch(
        employerInvoicesSortOrderChanged({
          columns: EMPLOYER_INVOICES_TABLE_COLUMNS,
          sortBy,
        }),
      );
    };

  return {
    invoices,
    selectedInvoicesIds,
    totalInvoicesCount,
    totalInvoicesCountWithoutFilters,
    isFetching,
    isLoadingEmployerInvoicesStats,
    isLoadingEmployerInvoices,
    filters: mappedFilters,
    control,
    handleSingleInvoicePayment,
    markInvoiceAsPaid,
    openInvoicePreview,
    startSelectedInvoicesPaymentFlow,
    setSelectedInvoicesIds,
    clearFilters,
    handleSortChange,
  };
};
