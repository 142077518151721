/* eslint-disable max-len */
import type { ConfigProps } from "redux-form";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { MODULE_EDIT_MODAL_PROPERTIES } from "@js/apps/freelancer/constants";
import type { CertificatesFormData } from "@js/apps/freelancer/forms/certificates-form";
import { CertificatesForm } from "@js/apps/freelancer/forms/certificates-form";
import { CommonConfirmationModal } from "@js/components/modal";
import type { FreelancerCertificate } from "@js/types/freelancer";

type EditCertificatesModalContentProps = {
  initialValues: CertificatesFormData | undefined;
  onSubmit: ConfigProps<CertificatesFormData>["onSubmit"];
  onSubmitSuccess: ConfigProps<CertificatesFormData>["onSubmitSuccess"];
  setSubmitType: (type: string | null) => void;
};

type DeleteCertificatesModalContentProps = {
  certificate: FreelancerCertificate;
  onDelete: (certificateId: number) => Promise<void>;
};

export const openEditCertificatesModal = ({
  initialValues,
  onSubmit,
  onSubmitSuccess,
  setSubmitType,
}: EditCertificatesModalContentProps) =>
  CommonConfirmationModal.open({
    children: (
      <EditCertificatesModalContent
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        setSubmitType={setSubmitType}
      />
    ),
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

export const openDeleteCertificatesModal = ({
  certificate,
  onDelete,
}: DeleteCertificatesModalContentProps) =>
  CommonConfirmationModal.open({
    ...MODULE_EDIT_MODAL_PROPERTIES,
    children: (
      <DeleteCertificatesModalContent
        certificate={certificate}
        onDelete={onDelete}
      />
    ),
  });

export const EditCertificatesModalContent = ({
  initialValues,
  onSubmit,
  onSubmitSuccess,
  setSubmitType,
}: EditCertificatesModalContentProps) => {
  return (
    <Box>
      <ModalHeaderWithArrow title="Edit certificates" />

      <CertificatesForm
        initialValues={initialValues}
        editMode
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
        setSubmitType={setSubmitType}
      />
    </Box>
  );
};

export const DeleteCertificatesModalContent = ({
  certificate,
  onDelete,
}: DeleteCertificatesModalContentProps) => {
  return (
    <Box>
      <ModalHeaderWithArrow title="Remove certificates" />
      <Typography variant="paragraph" component="p" mb={4}>
        Removing your {certificate.certificate.name} certificate from your
        profile can’t be undone. Are you sure you want to remove this
        certificate?
      </Typography>
      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button
          variant="black-outlined"
          shape="squared"
          onClick={CommonConfirmationModal.close}
        >
          Keep certificate
        </Button>
        <Button
          variant="destructive"
          shape="squared"
          onClick={() => onDelete(certificate.id)}
        >
          Remove certificate
        </Button>
      </Box>
    </Box>
  );
};

const ModalHeaderWithArrow = ({ title }: { title: string }) => (
  <Box display="flex" gap={2} mb={4}>
    <span>
      <IconButton
        variant="tertiary"
        onClick={CommonConfirmationModal.close}
        aria-label="go back"
      >
        <ArrowLeftIcon />
      </IconButton>
    </span>
    <Typography variant="title" component="h3" size="small" fontWeight={400}>
      {title}
    </Typography>
  </Box>
);
