import { Box } from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

type ApplicationBoostModalLeftProps = {
  children: React.ReactNode;
};

export const ApplicationBoostModalLeft = ({
  children,
}: ApplicationBoostModalLeftProps) => {
  return (
    <Box className={styles.leftMainContainer}>
      <Box display={"flex"} justifyContent={"center"} width={"100%"}>
        <Box position={"absolute"} top={0} right={0} zIndex={0}>
          <img
            src={`${SETTINGS.STATIC_URL}boostmodal/leftpattern.svg`}
            alt="bgpattern"
          />
        </Box>
        <Box maxWidth={"432px"} p={2} zIndex={1}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
