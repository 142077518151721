import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

import {
  Box,
  Divider,
  Stack,
  StaticDateRangePicker,
} from "@hexocean/braintrust-ui-components";
import { FilterPopoverFooter } from "@js/apps/employer/components/filter-popover";
import type { RhfForm } from "@js/rhf/types";

import { employerInvoicesDateRangeFormSchema } from "../schema";

type FormType = RhfForm<typeof employerInvoicesDateRangeFormSchema>;
type FormValues = FormType["Values"];
type DefaultFormValues = FormType["DefaultValues"];

type DateRangeFormProps = {
  dateBefore: DefaultFormValues["date_before"];
  dateAfter: DefaultFormValues["date_after"];
  onApply: (values: FormValues) => void;
};

export const DateRangeForm = ({
  dateBefore,
  dateAfter,
  onApply,
}: DateRangeFormProps) => {
  const defaultValues = useMemo(() => {
    return { date_before: dateBefore, date_after: dateAfter };
  }, [dateAfter, dateBefore]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerInvoicesDateRangeFormSchema),
    defaultValues,
  });

  const {
    field: dateBeforeField,
    fieldState: { error: dateBeforeError },
  } = useController({
    name: "date_before",
    control: control,
  });

  const {
    field: dateAfterField,
    fieldState: { error: dateAfterError },
  } = useController({
    name: "date_after",
    control: control,
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onApply(values);
  };

  const onReset = () => {
    reset({ date_after: null, date_before: null });
  };

  const errorMessage = dateAfterError?.message ?? dateBeforeError?.message;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        sx={{
          width: "min(auto, 100vw - 2rem)",
          minWidth: { sm: "500px" },
          alignItems: "center",
          borderRadius: 2,
          boxShadow: !!errorMessage
            ? "0 0 0 1px inset var(--negative-2)"
            : undefined,
        }}
        data-testid="date-range-calendars"
      >
        <StaticDateRangePicker
          id="date-range"
          containerProps={{
            sx: {
              ".MuiPickersLayout-root": { backgroundColor: "transparent" },
            },
          }}
          startDate={serializeDate(dateAfterField.value)}
          endDate={serializeDate(dateBeforeField.value)}
          colorVariant="green"
          onStartDateChange={(value) => {
            dateAfterField.onChange(deserializeDate(value));
          }}
          onEndDateChange={(value) => {
            dateBeforeField.onChange(deserializeDate(value));
          }}
        />
        <Box sx={{ px: 3, width: "100%" }}>
          <Divider color="beige" />
        </Box>
        <FilterPopoverFooter
          onReset={onReset}
          containerProps={{ sx: { px: 3, pb: 3, mt: 2 } }}
          errorMessage={errorMessage}
        />
      </Stack>
    </form>
  );
};

const serializeDate = (date: string | null | undefined) => {
  return date ? dayjs.utc(date) : null;
};

const deserializeDate = (date: Dayjs | null) => {
  return date ? date.format("YYYY-MM-DD") : null;
};
