import { useGetFreelancerJobsQuery } from "@js/apps/freelancer/api";

export const useFreelancerJobs = (showClosedJobs: boolean) => {
  const { isLoading: isLoadingOpenJobs, data: openJobs = [] } =
    useGetFreelancerJobsQuery({
      completed: false,
      show_jobs_of_removed_users: true,
    });

  const { isLoading: isLoadingClosedJobs, data: closedJobs = [] } =
    useGetFreelancerJobsQuery(
      {
        completed: true,
        show_jobs_of_removed_users: true,
      },
      { skip: !showClosedJobs },
    );

  const jobs = showClosedJobs ? [...openJobs, ...closedJobs] : openJobs;

  return {
    jobs,
    isLoading: isLoadingOpenJobs || isLoadingClosedJobs,
  };
};
