import React from "react";
import cs from "classnames";

import type { ButtonSelectProps } from "@hexocean/braintrust-ui-components";
import { Carousel, useSwiperRefs } from "@hexocean/braintrust-ui-components";
import {
  Box,
  FormHelperText,
  IconButton,
  SelectableButton,
  Typography,
  useButtonSelect,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import type { WalletActivityFiltersOption } from "../constants";

export const WalletActivityFiltersButtons = ({
  value = [],
  options,
  error,
  multiple = true,
  canSingleValueBeEmpty = false,
  variant,
  selectedVariant,
  labelVariant,
  selectedIcon,
  startIcon,
  shape,
  typographySize,
  labelledBy,
  hideError,
  size,
  fontWeight,
  selectedFontWeight,
  className,
  ...props
}: ButtonSelectProps<WalletActivityFiltersOption>) => {
  const { containerA11yProps, isSelected, handleChange, itemA11yProps } =
    useButtonSelect({
      value,
      multiple,
      canSingleValueBeEmpty,
      onChange: props.onChange,
      labelledBy,
    });

  const { prevEl, prevElRef, nextEl, nextElRef } = useSwiperRefs();
  return (
    <Box display="flex" width="100%" alignItems="center" position="relative">
      <NavigationButton ref={prevElRef} type="prev" />

      <Carousel
        slides={[
          ...options.map((option) => {
            const _isSelected = isSelected(option);

            const _fontWeight =
              _isSelected && selectedFontWeight
                ? selectedFontWeight
                : fontWeight || 500;

            return (
              <SelectableButton
                key={option.label}
                focusVisibleClassName="swiper-slide-active"
                {...itemA11yProps(_isSelected)}
                className={className}
                isSelected={_isSelected}
                variant={variant || "tertiary"}
                size={size}
                fontWeight={_fontWeight}
                selectedVariant={selectedVariant || variant || "primary"}
                shape={shape}
                startIcon={startIcon || undefined}
                selectedStartIcon={selectedIcon}
                onClick={() => {
                  handleChange(option);
                }}
              >
                <Typography
                  component="p"
                  size={typographySize}
                  color={labelVariant || "inherit"}
                  fontWeight={_fontWeight}
                >
                  {option.label}
                </Typography>
              </SelectableButton>
            );
          }),
        ]}
        className="activity-filters-carousel__swiper"
        threshold={10}
        slidesPerView="auto"
        resistance={false}
        spaceBetween={8}
        mousewheel
        navigation={{
          prevEl,
          nextEl,
          disabledClass: "activity-filters-carousel__nav-btn--hidden",
        }}
        {...containerA11yProps}
      />

      {error && !hideError && (
        <FormHelperText error sx={{ marginTop: 1 }}>
          {error}
        </FormHelperText>
      )}

      <NavigationButton ref={nextElRef} type="next" />
    </Box>
  );
};

interface NavigationButtonProps {
  type: "next" | "prev";
}

const NavigationButton = React.forwardRef<unknown, NavigationButtonProps>(
  ({ type }, ref) => {
    return (
      <Box
        ref={ref}
        className={cs(
          "activity-filters-carousel__nav-btn",
          `activity-filters-carousel__nav-btn--${type}`,
        )}
      >
        <IconButton variant="transparent" aria-label="next" size="small">
          {type === "next" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        </IconButton>

        <div className="activity-filters-carousel__nav-btn__fade" />
      </Box>
    );
  },
);
