import { useEffect, useRef, useState } from "react";

type UseInitiallyOpenDropdownProps = {
  shouldOpenDropdown: boolean;
  isFilterEmpty: boolean;
};

export const useInitiallyOpenDropdown = ({
  shouldOpenDropdown,
  isFilterEmpty,
}: UseInitiallyOpenDropdownProps) => {
  const autocompleteTextFieldRef = useRef<HTMLInputElement>(null);
  const [isFilterEmptyInitial] = useState(isFilterEmpty);

  useEffect(() => {
    if (!shouldOpenDropdown || !isFilterEmptyInitial) return;

    if (autocompleteTextFieldRef.current) {
      autocompleteTextFieldRef.current.focus();
    }
  }, [shouldOpenDropdown, isFilterEmptyInitial, autocompleteTextFieldRef]);

  return {
    autocompleteTextFieldRef,
  };
};
