import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";

type FilterButtonPopoverFooterProps = {
  onReset: () => void;
};

export const FilterButtonPopoverFooter = ({
  onReset,
}: FilterButtonPopoverFooterProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Button onClick={onReset} component="p" variant="no-padding">
        <Typography component="p" variant="label" size="small" color="grey-3">
          Clear all
        </Typography>
      </Button>
      <Button variant="primary" type="submit" shape="squared">
        Apply
      </Button>
    </Box>
  );
};
