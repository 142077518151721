import { Typography, useMediaQuery } from "@hexocean/braintrust-ui-components";

export const MyTalentTitle = () => {
  const isMobile = useMediaQuery("sm");
  return (
    <Typography
      component="h1"
      variant="title"
      size={isMobile ? "medium" : "large"}
      fontWeight={400}
    >
      My Talent
    </Typography>
  );
};
