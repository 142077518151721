import { useEffect, useState } from "react";

import { useFreelancerId } from "@js/apps/common/hooks";
import { useGetCompletionAreaToEditMap } from "@js/apps/freelancer/hooks/get-profile-nudge-completion-area";
import type { ProfileCompletion } from "@js/types/freelancer";

export const useFirstUnfinishedSectionLink = (
  profileCompletion: ProfileCompletion | undefined,
) => {
  const [link, setLink] = useState("");
  const completionAreas = useGetCompletionAreaToEditMap();
  const freelancerId = useFreelancerId();
  const defaultLink = `/talent/${freelancerId}`;

  useEffect(() => {
    if (
      !profileCompletion ||
      profileCompletion?.completion_percent === 0 ||
      profileCompletion?.completion_percent === 100
    ) {
      setLink(defaultLink);
      return;
    }

    try {
      const unfinishedSections = profileCompletion?.missing_areas?.map(
        (area) => completionAreas[area.keywords],
      );
      if (unfinishedSections) {
        setLink(unfinishedSections[0].link);
      }
    } catch (error) {
      console.error(
        "First unfinished section. Default link has been set.",
        error,
      );
      setLink(defaultLink);
    }
  }, [profileCompletion, completionAreas, freelancerId, defaultLink]);
  return link;
};
