import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export const EmployerInvoicesEmptyState = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        background: "var(--white)",
        p: 4,
        borderRadius: 4,
        textAlign: "center",
      }}
    >
      <Box
        component="img"
        src={`${SETTINGS.STATIC_URL}invoices/employer-invoices-empty-state-illustration.svg`}
        width="172px"
        height="223px"
      />
      <Typography component="h3" variant="label" size="medium" sx={{ mb: 1 }}>
        Your invoices will appear here.
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size="medium"
        sx={{ mb: 3 }}
      >
        Once your hired talent submits their invoice, it’ll appear here.
      </Typography>
      <Stack direction="row" sx={{ gap: 1, justifyContent: "center" }}>
        <Button
          size="x-small"
          variant="primary"
          RouterLink={RouterLink}
          to="/jobs/add_new/"
        >
          Post a job
        </Button>
        <Button
          size="x-small"
          variant="secondary"
          RouterLink={RouterLink}
          to="/talent/"
        >
          Browse talent
        </Button>
      </Stack>
    </Stack>
  );
};

export const EmployerInvoicesTableEmptyState = ({
  onResetFilters,
}: {
  onResetFilters: () => void;
}) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        p: 4,
        borderRadius: 4,
        background: "var(--white)",
        textAlign: "center",
      }}
    >
      <Typography component="p" variant="label" sx={{ mb: 1 }}>
        No invoices found
      </Typography>
      <Typography component="p" sx={{ mb: 3 }}>
        Your search did not match any invoices.
      </Typography>

      <Stack direction="row" sx={{ gap: 1 }}>
        <Button size="x-small" variant="secondary" onClick={onResetFilters}>
          Clear search
        </Button>
      </Stack>
    </Stack>
  );
};
