import { type FC, useCallback, useEffect } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import {
  type PurchaseUpgradeParams,
  useReserveUpgradeMutation,
} from "@js/apps/auth/api";
import { useLazyInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { openPopup } from "@js/utils";

import { Description } from "./components/description";
import { PurchaseSummary } from "./components/purchase-summary";
import { usePreCheckBalanceUpgrade } from "./hooks";

export type ApplicationOnrampModalProps = {
  upgradeType: PurchaseUpgradeParams["upgrade_type"];
};

export const ApplicationOnrampModal: FC<ApplicationOnrampModalProps> = ({
  upgradeType,
}) => {
  const { formattedRequiredTotalBtrst, requiredTotalBtrstRaw, upgradeCost } =
    usePreCheckBalanceUpgrade(upgradeType);
  const [initPayment, { data, isLoading: isLoadingCoinbase }] =
    useLazyInitCoinbasePaymentQuery();
  const { onRampURL } = useCoinbasePay({
    transactionUuid: data?.payment_uuid || "",
    walletAddress: data?.safe_wallet_address || "",
    /**
     * @note
     *  we use the string version of the result so we could avoid passing results that has exponent(e.g 1.24444444e+12)
     * So that we always ensure we pass to the parameter is accurate with the decimals
     */
    tokensToBuy: requiredTotalBtrstRaw as unknown as number,
  });
  const [reserveUpgrade, { isLoading: isLoadingReserveUpgrade }] =
    useReserveUpgradeMutation();

  useEffect(() => {
    initPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPurchase = useCallback(async () => {
    if (onRampURL) {
      await reserveUpgrade({ upgrade_type: upgradeType });
      ModalInstance.close();
      openPopup({
        url: onRampURL,
      });
    } else {
      Snackbar.error("onRamp URl is null");
    }
  }, [onRampURL, reserveUpgrade, upgradeType]);

  return (
    <Box
      minWidth={{
        lg: "1248px",
      }}
      width={{
        xs: "90vw",
        sm: "88vw",
        md: "86.5vw",
        lg: "auto",
      }}
      minHeight={{ xs: "100%", lg: "800px" }}
      padding={2}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection={{ xs: "column", lg: "row" }}
    >
      <Description
        requiredTotalBtrst={formattedRequiredTotalBtrst}
        requiredTotalBtrstRaw={requiredTotalBtrstRaw}
        upgradeType={upgradeType}
      />
      <PurchaseSummary
        isLoadingCoinbase={isLoadingCoinbase}
        isPurchasing={isLoadingReserveUpgrade}
        requiredTotalBtrst={formattedRequiredTotalBtrst}
        requiredTotalBtrstRaw={requiredTotalBtrstRaw}
        onPurchase={onPurchase}
        upgradeTypeCost={upgradeCost}
        upgradeType={upgradeType}
      />
    </Box>
  );
};
