import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import { openGenericMessenger } from "@js/apps/messenger/messengers/modal/manage-messenger-modal";
import { siteNotificationMarkAsRead } from "@js/apps/notifications/actions";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import type { SiteNotification } from "@js/types/notifications";

type SiteNotificationButtonProps = {
  button: SiteNotification["buttons"][number];
  context: SiteNotification["context"];
  hideNotification: (notificationId: SiteNotification["id"]) => void;
};

export const SiteNotificationButton = ({
  button,
  context,
  hideNotification,
}: SiteNotificationButtonProps) => {
  const {
    id,
    notification,
    text,
    href,
    is_hide_modal_button: isHideModalButton,
  } = button;

  const dispatch = useAppDispatch();

  const hide = () => {
    hideNotification(notification);
    dispatch(siteNotificationMarkAsRead(notification));
  };

  const hideAndAct = () => {
    hide();
    if (context && context.room_id) {
      dispatch(openGenericMessenger({ roomId: context.room_id }));
    }
  };

  return (
    <Button
      key={id}
      to={href}
      variant={isHideModalButton ? "secondary" : "primary"}
      onClick={isHideModalButton ? hide : hideAndAct}
      RouterLink={RouterLink}
    >
      {text}
    </Button>
  );
};
