import React from "react";
import { Field, Fields, reduxForm } from "redux-form";

import { Button } from "@hexocean/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import { CheckboxField, TextField } from "@js/forms/fields";
import { enumToOptions, isAKeyOfAnObject } from "@js/utils";

const BID_FEEDBACK_FORM = "bid-feedback";

const BidFeedbackForm = ({ submit, error, submitting, onCancelClick }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      {enumToOptions(ENUMS.BidNotHiringReasonTypeLabels).map(
        ({ value, label }) => (
          <Field
            key={value}
            name={value}
            id={value}
            customValue={label}
            label={label}
            component={CheckboxField}
          />
        ),
      )}
      <Fields names={["reason_other", "other"]} component={OtherReasonFiled} />
      <div className="bid-feedback-modal__buttons">
        <Button variant="transparent" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          className="ml"
          variant="transparent"
          onClick={submitting ? null : submit}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

const OtherReasonFiled = ({ other }) => {
  if (!other.input.value) {
    return null;
  }

  return (
    <Field
      className="mb+"
      variant="outlined"
      id="reason_other"
      name="reason_other"
      component={TextField}
      label="Other"
    />
  );
};

export const prepareBidFeedback = (formValues: BidFeedbackFormData) => {
  const reason = Object.keys(formValues).filter(
    (key): key is keyof Omit<BidFeedbackFormData, "reason_other"> => {
      return key !== "reason_other" && isAKeyOfAnObject(formValues, key);
    },
  );

  return {
    reason: reason,
    reason_other: formValues.reason_other,
  };
};

type Props = {
  onCancelClick: () => void;
};

export type BidFeedbackFormData = {
  [Property in EnumType<typeof ENUMS.BidNotHiringReasonType>]: string;
} & { reason_other: string | null };

export default reduxForm<BidFeedbackFormData, Props>({
  form: BID_FEEDBACK_FORM,
  onChange: (values, dispatch, props) => {
    if (!values.other && props.change) {
      dispatch(props.change("reason_other", null));
    }
  },
})(BidFeedbackForm);
