import { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import type {
  ExpandBid,
  IsBidExpanded,
} from "@js/apps/jobs/hooks/expanded-bids";
import type { EmployerBidListBid } from "@js/types/jobs";
import { isInteractiveElementClicked } from "@js/utils";

import { useMarkBidAsViewedMutation } from "../../api";

type BidListProps = {
  bid: EmployerBidListBid;
  jobId: number;
};

type BidListItemReturnType = {
  linkToSidePanel: string;
  isSkillsAndQASectionEmpty: boolean;
  handleClickWithinItem: (
    ev: React.MouseEvent<HTMLDivElement>,
  ) => Promise<void>;
  isBidExpanded: IsBidExpanded | null;
  expandBid: ExpandBid | null;
};

export const useBidListItem = ({
  bid,
  jobId,
}: BidListProps): BidListItemReturnType => {
  const [markBidAsViewed] = useMarkBidAsViewedMutation();
  const { search } = useLocation();
  const { isBidExpanded, expandBid } = useBidsContext();

  const linkToSidePanel = `/jobs/${jobId}/proposals/${bid.id}${search}`;
  const isSkillsAndQASectionEmpty =
    !bid.freelancer.superpower_skills.length && !bid.application_answers.length;

  const handleClickWithinItem = useCallback(
    async (event: React.MouseEvent<HTMLDivElement>) => {
      if (bid.status === ENUMS.BidStatus.NEW) {
        const interactiveElementClicked = isInteractiveElementClicked(event);

        if (!interactiveElementClicked) return;

        await markBidAsViewed({ bidId: bid.id });
      }
    },
    [bid, markBidAsViewed],
  );

  return {
    linkToSidePanel,
    isSkillsAndQASectionEmpty,
    handleClickWithinItem,
    isBidExpanded,
    expandBid,
  };
};
