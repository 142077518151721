import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { clickPostHashtag } from "@js/apps/give-and-get-help/actions";
import {
  HASHTAG_QUERY_PARAM,
  POST_HASHTAG_QUERY_PARAM,
} from "@js/apps/give-and-get-help/constants";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context";
import type { PostHashtag } from "@js/apps/give-and-get-help/types";
import { RouterLink } from "@js/components/link";
import { getHashtagRawName } from "@js/components/rich-text-editor/custom-plugins/hashtag-plugin/utils/get-hashtag-raw-name";
import { useAppDispatch } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

type TextWithHashtags = {
  text: string;
  hashtags?: PostHashtag[];
  postData?: IPost;
};

export const splitTextByHashtags = (text: string): string[] => {
  const hashtagRegExp = /(#\w+)/gi;
  const textArray = text.split(hashtagRegExp);
  return textArray;
};

export const PostTextWithHashtagLinks = ({
  text,
  hashtags,
  postData,
}: TextWithHashtags) => {
  if (!hashtags || !hashtags.length)
    return <span dangerouslySetInnerHTML={{ __html: text }} />;

  const uniqueHashtagsIds = new Set<number>();

  const textArray = splitTextByHashtags(text);

  const parsedTextArray = textArray.map((textFragment) => {
    const hashtagInText = hashtags.find((hashtag) => {
      return textFragment.includes(hashtag.hashtag_name);
    });

    if (!hashtagInText) {
      return textFragment;
    }

    if (uniqueHashtagsIds.has(hashtagInText.id)) {
      return textFragment;
    }

    uniqueHashtagsIds.add(hashtagInText.id);

    return {
      hashtag: hashtagInText,
    };
  });

  return (
    <div className="read-more-button__sanitized">
      {parsedTextArray.map((textFragment) => {
        if (typeof textFragment === "string") {
          return (
            <span
              key={textFragment}
              dangerouslySetInnerHTML={{ __html: textFragment }}
            />
          );
        }

        const { hashtag } = textFragment;

        return (
          <HashtagLink key={hashtag.id} hashtag={hashtag} postData={postData} />
        );
      })}
    </div>
  );
};

const HashtagLink = ({
  hashtag,
  postData,
}: {
  hashtag: PostHashtag;
  postData?: IPost;
}) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();

  const spaceId = postData?.space;
  const redirectLink = spaceId
    ? `/spaces/${spaceId}/discussion?${HASHTAG_QUERY_PARAM}=${hashtag.hashtag_id}`
    : `/feed/?${HASHTAG_QUERY_PARAM}=${hashtag.hashtag_id}&${POST_HASHTAG_QUERY_PARAM}=${hashtag.id}`;

  const handlePostHashtagClick = () => {
    if (!postData) {
      return;
    }
    const { id: postHashtagId, hashtag_id, hashtag_name } = hashtag;
    const postHashtag = {
      id: postHashtagId,
      hashtag_id,
      hashtag_name,
    };

    dispatch(
      clickPostHashtag({
        hashtag_id: postHashtag.hashtag_id,
        post_id: postData.id,
        post_level: postData.level,
        slot_position: postData.slot_position,
        post_location: postLocation,
      }),
    );
  };

  return (
    <Typography
      className="post-hashtag-link"
      component="link"
      to={redirectLink}
      RouterLink={RouterLink}
      size="large"
      variant="link"
      color="blue"
      noTextDecoration
      onClick={handlePostHashtagClick}
    >
      #{getHashtagRawName(hashtag.hashtag_name)}
    </Typography>
  );
};
