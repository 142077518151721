import type { Dispatch } from "redux";
import { SubmissionError } from "redux-form";
import * as Sentry from "@sentry/react";
import axios from "axios";
import _ from "underscore";

import {
  CTA_CLICKED_EVENT,
  FETCH_INDUSTRIES_SUCCESS,
  FETCH_POPULAR_LOCATION_OPTIONS_SUCCESS,
  FORCE_PAGE_REMOUNT,
  HIDE_LEFT_SIDEBAR,
  SEARCH_TAXONOMY_SUCCESS,
  SET_ACTIVE_MESSENGER_ROOM,
  SET_LAYOUT_BG_COLOR,
  SET_POSTS_TO_SHOW_PULSE_ANIMATION,
  SHOW_LEFT_SIDEBAR,
} from "@js/apps/common/action-types";
import { Snackbar } from "@js/components/snackbar";
import { Events } from "@js/services/analytics";
import type { AppThunkAction } from "@js/store";
import type { FilterAppliedPayload } from "@js/types/common";

export type CtaClickedActionTypes = {
  ctaName: EnumType<typeof ENUMS.UserClicksEventCTAName>;
  ctaLocation: EnumType<typeof ENUMS.UserClicksEventCTALocation>;
};

export const CtaClickedAction = (params: CtaClickedActionTypes) => {
  return {
    type: CTA_CLICKED_EVENT,
    payload: params,
  };
};

export const forcePageRemount = () => ({
  type: FORCE_PAGE_REMOUNT,
});

export const showLeftSidebar = () => ({
  type: SHOW_LEFT_SIDEBAR,
});

export const hideLeftSidebar = () => ({
  type: HIDE_LEFT_SIDEBAR,
});

export const setLayoutBackgroundColor = (color: string | undefined) => ({
  type: SET_LAYOUT_BG_COLOR,
  payload: color,
});

const _searchTaxonomyItems = (
  phrase,
  endpoint,
  resolve,
  keepInStore = true,
  params,
  dispatch,
) =>
  axios
    .get(`/api/${endpoint}/?search=${encodeURIComponent(phrase)}`, { params })
    .then((response) => {
      if (keepInStore) {
        if (dispatch) {
          dispatch({
            type: SEARCH_TAXONOMY_SUCCESS,
            payload: response.data,
          });
        } else {
          throw new Error(
            "searchTaxonomyItems: you should provide 'dispatch' or change keepInStore to false",
          );
        }
      }

      resolve(response.data);
    });

const _searchTaxonomyItemsDebounce = _.debounce(_searchTaxonomyItems, 300);

type SearchTaxonomyItemsArgs = {
  phrase: string;
  endpoint: string;
  immediate?: boolean;
  keepInStore?: boolean;
  dispatch: Dispatch;
  params?: Record<string, any>;
};

export const searchTaxonomyItems = ({
  phrase,
  endpoint,
  immediate,
  keepInStore,
  params,
  dispatch,
}: SearchTaxonomyItemsArgs) =>
  new Promise((resolve) => {
    (immediate ? _searchTaxonomyItems : _searchTaxonomyItemsDebounce)(
      phrase,
      endpoint,
      resolve,
      keepInStore,
      params,
      dispatch,
    );
  });

export const getTaxonomyItem = (id, endpoint, endpointParams?) =>
  new Promise((resolve) =>
    axios
      .get(`/api/${endpoint}/${id}/`, { params: endpointParams })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) =>
        Sentry.captureException("GetTaxonomyItemException", {
          extra: { error },
        }),
      ),
  );

// to create skill you have to use bulkSaveTaxonomyItems
export const createTaxonomyItem = (name, endpoint, fieldName) => () =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/${endpoint}/`, { name })
      .then((response) => resolve(response.data))
      .catch(({ response: { data = {} } }) => {
        let submissionError = {};

        if (fieldName && data.name) {
          submissionError[fieldName] = data.name;
        } else {
          submissionError = data;
        }

        return reject(new SubmissionError(submissionError));
      }),
  );

export const bulkSaveTaxonomyItems = <T extends string>(
  taxonomy: T,
  items: Record<T, Array<unknown>>,
  fieldName: string,
) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/${taxonomy}/bulk_save/`, items)
      .then((response) => resolve(response.data[taxonomy]))
      .catch(({ response: { data } }) => {
        if (_.isArray(data)) {
          const errors = data.map((item, index) =>
            item.name
              ? {
                  [fieldName]: item.name,
                  original: items[taxonomy][index] as string | undefined,
                }
              : {},
          );

          const errorsObj = errors[0];

          return reject(new SubmissionError(errorsObj));
        } else {
          Snackbar.error(data);
        }
      }),
  );

export const fetchIndustries = (): AppThunkAction<any> => (dispatch) =>
  new Promise((resolve) =>
    axios.get("/api/industries/").then((response) => {
      dispatch({
        type: FETCH_INDUSTRIES_SUCCESS,
        payload: response.data,
      });

      resolve(response.data);
    }),
  );

/*
 *   @param id - id of the entity that was set as `type`
 *   @param type - one of ENUMS.VisitsCounterType
 */
export const updateVisitsCounter = (id, type) =>
  new Promise((resolve) => {
    return axios
      .get(`/api/visits_counter/?id=${id}&type=${type}`)
      .then(resolve)
      .catch(() => {
        // ignore
      });
  });

export const confirmAction = (data) =>
  new Promise((resolve, reject) =>
    axios
      .post("/api/confirm/", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data)),
  );

export const fetchPopularLocationOptions =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) =>
      axios.get(`/api/frequent_talent_location_filters/`).then((response) => {
        dispatch({
          type: FETCH_POPULAR_LOCATION_OPTIONS_SUCCESS,
          payload: response.data,
        });

        resolve(response.data);
      }),
    );

export const setActiveMessengerRoom = (roomId: number | undefined) => ({
  type: SET_ACTIVE_MESSENGER_ROOM,
  payload: { roomId },
});

export const signUpAttempt = (amplitude_device_id) =>
  axios.post(`/api/users/signup_attempt/`, {
    amplitude_device_id: amplitude_device_id,
  });

export const setPostsToShowPulseAnimation =
  (showPulseAnimationForPosts: number[]) => (dispatch) =>
    dispatch({
      type: SET_POSTS_TO_SHOW_PULSE_ANIMATION,
      payload: { showPulseAnimationForPosts },
    });

export const filterApplied = (payload: FilterAppliedPayload) => ({
  type: Events.FILTER_SEARCH,
  payload,
});
