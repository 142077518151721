import React from "react";

import { useGetFaqEntriesQuery } from "../../api";
import { ConnectorContainer } from "../connector-container";

import { FaqAccordion } from "./faq-accordion";

export const Faq = () => {
  const { data: faqItems, isLoading } = useGetFaqEntriesQuery();

  return (
    <ConnectorContainer title="FAQs" isLoading={isLoading}>
      {!!faqItems && <FaqAccordion items={faqItems} />}
    </ConnectorContainer>
  );
};
