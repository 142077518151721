import type { AIGeneratedJobFormData } from "@js/apps/employer/hooks/generate-ai/utils";
import type { GetDraftInitialValuesArg } from "@js/apps/jobs/apps/create-job/utils/form-initial-values";
import {
  getCopyJobInitialValues,
  getDraftInitialValues,
  getEmptyJobInitialValues,
  getInitialValues,
  getUploadJobDescriptionInitialValues,
} from "@js/apps/jobs/apps/create-job/utils/form-initial-values";
import type { GetCreateJobInitialValuesArg } from "@js/apps/jobs/apps/create-job/utils/types";
import type { User } from "@js/types/auth";
import type {
  Job,
  JobFormValues,
  ManagedJob,
  UploadedJob,
} from "@js/types/jobs";

export const resolveEmployerIdFromJob = (
  job: Partial<ManagedJob | Job>,
): number | undefined => {
  return typeof job.employer === "object" ? job.employer.id : job.employer;
};

export const getInitialValuesOnBehalfOfAClient = (
  job: ManagedJob,
): Partial<JobFormValues> => {
  return {
    ...getInitialValues(job),
    id: job.id,
    employer: resolveEmployerIdFromJob(job),
  };
};

export const getCopyJobInitialValuesOnBehalfOfAClient = (
  job: ManagedJob | AIGeneratedJobFormData,
): Partial<JobFormValues> => {
  return {
    ...getCopyJobInitialValues(job),
    job_subscribers: [],
    job_subscriber_invitations: [],
    employer: resolveEmployerIdFromJob(job),
    hiring_manager: job.hiring_manager,
    op_owners: job.op_owners || [],
    sale_owners: job.sale_owners || [],
    job_fill_support_type: job.job_fill_support_type,
  };
};

export const getDraftInitialValuesOnBehalfOfAClient = (
  arg: GetDraftInitialValuesArg & { isEmployer: boolean },
): Partial<JobFormValues> => {
  const { draft, userId, isEmployer } = arg;
  const jobOwnerId = isEmployer
    ? draft.hiring_manager ?? userId
    : draft.hiring_manager; // do not use current user Id if not employer
  const jobSubscribersOnBehalfOfAClient: JobFormValues["job_subscribers"] =
    jobOwnerId ? [{ team_member_id: jobOwnerId }] : [];

  return {
    ...getDraftInitialValues({ userId, draft }),
    job_subscribers: jobSubscribersOnBehalfOfAClient,
    employer: draft.employer,
    hiring_manager: draft.hiring_manager,
    op_owners: draft.op_owners,
    sale_owners: draft.sale_owners,
    job_fill_support_type: draft.job_fill_support_type,
  };
};

export const getCopyDraftInitialValuesOnBehalfOfAClient = (
  arg: GetDraftInitialValuesArg & { isEmployer: boolean },
): Partial<JobFormValues> => {
  return {
    ...getDraftInitialValuesOnBehalfOfAClient(arg),
    timezones: [],
    locations: [],
    job_subscriber_invitations: [],
    id: undefined,
  };
};

export const getEmptyJobInitialValuesOnBehalfOfAClient = (
  userId?: User["id"],
) => {
  return {
    ...getEmptyJobInitialValues(userId),
    //it is set to draft.hiring_manager (or userId in case of employer impersonated session) after going into the second step
    jobOwnerId: undefined,
    //it is set to [{ team_member_id: draft.hiring_manager (or userId in case of employer impersonated session) }] after going into the second step
    job_subscribers: [],
  };
};

export const getUploadJobDescriptionInitialValuesOnBehalfOfAClient = (
  job: UploadedJob,
  userId: number,
  isEmployer?: boolean,
): Partial<JobFormValues> => {
  const jobOwnerId = isEmployer ? userId : undefined;
  const jobSubscribersOnBehalfOfAClient: JobFormValues["job_subscribers"] =
    jobOwnerId ? [{ team_member_id: jobOwnerId }] : [];
  return {
    ...getUploadJobDescriptionInitialValues(job, jobOwnerId),
    jobOwnerId,
    job_subscribers: jobSubscribersOnBehalfOfAClient,
  };
};

export const getCreateJobInitialValuesOnBehalfOfAClient = (
  args: Omit<GetCreateJobInitialValuesArg, "userId"> & { userId: User["id"] },
): Partial<JobFormValues> => {
  const {
    userId,
    draft,
    jobToCopy,
    aiGenerated,
    copyDraftId,
    isEmployer,
    uploadedJobDescription,
  } = args;
  if (copyDraftId && draft) {
    return getCopyDraftInitialValuesOnBehalfOfAClient({
      draft,
      userId,
      isEmployer,
    });
  }

  if (draft) {
    return getDraftInitialValuesOnBehalfOfAClient({
      draft,
      userId,
      isEmployer,
    });
  }

  if (jobToCopy) {
    return getCopyJobInitialValuesOnBehalfOfAClient(jobToCopy);
  }

  if (aiGenerated) {
    return getCopyJobInitialValuesOnBehalfOfAClient(aiGenerated);
  }

  if (uploadedJobDescription) {
    return getUploadJobDescriptionInitialValuesOnBehalfOfAClient(
      uploadedJobDescription,
      userId,
      isEmployer,
    );
  }

  return getEmptyJobInitialValuesOnBehalfOfAClient(userId);
};
