import { useId } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { openNotesModal } from "@js/apps/common/components/open-notes-button";
import { useUser } from "@js/apps/common/hooks/use-user";
import { openSubscribeTeamMembersToJobModal } from "@js/apps/employer/components/subscribe-team-members-to-job-modal";
import { useCheckJobsLimit } from "@js/apps/employer/hooks/check-jobs-limit";
import {
  addTeamMembersClicked,
  holdJob,
  jobPostDataRefresh,
  releaseJob,
  toggleJobInvoicing,
} from "@js/apps/jobs/actions";
import {
  useCompleteJobMutation,
  useSubscribeToJobMutation,
  useUnsubscribeFromJobMutation,
} from "@js/apps/jobs/api";
import ChangeJobOwnerForm from "@js/apps/jobs/forms/change-job-owner";
import { Modal, ModalConfirm, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type {
  EmployerOwnJob,
  Job,
  JobForCoreTeam,
  NodeStaffMainJobListingJob,
} from "@js/types/jobs";
import { assertUnreachable, typeGuard } from "@js/utils/typescript";

import { CloseJobModalManager, openSuccessToast } from "../../close-job-modal";
import { JobActionsSubscriptionSnackbarContent } from "../../job-actions-subscription-snackbar-content";
import { openJobEventsModal } from "../../job-events-button";
import { openJobFillSupportTypeModal } from "../../job-fill-support-type-button";
import { openManageJobOwnersModal } from "../../manage-job-owners";
import { openReferJobModal } from "../../refer-to-job/button";
import { openReviewsPostJobCompletedModal } from "../../reviews-post-job-completed";
import { useWithJobActionsContext } from "../with-job-actions";

export type JobActionsEnum = EnumType<typeof ENUMS.JobActions>;

type JobType = Job | EmployerOwnJob | NodeStaffMainJobListingJob;

export const useJobAction = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { afterAction } = useWithJobActionsContext();
  const { checkJobsLimit } = useCheckJobsLimit();
  const [subscribeToJob] = useSubscribeToJobMutation();
  const [unsubscribeFromJob] = useUnsubscribeFromJobMutation();
  const [completeJob] = useCompleteJobMutation();
  const modalId = useId();
  const user = useUser();
  const JobActionModalInstance = Modal(
    `${"job-action-modal-private"}${modalId}`,
  );

  const closeJob = (job: JobType) => {
    JobActionModalInstance.open({
      children: (
        <CloseJobModalManager
          job={job}
          afterAction={() =>
            afterAction({ job, actionType: ENUMS.JobActions.COMPLETE_JOB })
          }
          modalInstance={JobActionModalInstance}
        />
      ),
      className: "close-job-modal",
      closeButton: true,
      closeButtonProps: {
        variant: "tertiary",
        size: "x-small",
      },
      keepOnBackdropClick: true,
    });
  };

  const handleJobAction = async (job: JobType, actionName: JobActionsEnum) => {
    switch (actionName) {
      case ENUMS.JobActions.CHANGE_OWNER: {
        ModalInstance.open({
          className: "job-actions-confirmation-modal",
          children: (
            <ChangeJobOwnerForm
              jobId={job.id}
              jobStatus={(job as EmployerOwnJob).status}
              onCancel={ModalInstance.close}
              onSuccess={() => afterAction({ job })}
            />
          ),
        });
        break;
      }
      case ENUMS.JobActions.CHANGE_OWNERS: {
        if ("op_owners" in job && "sale_owners" in job) {
          openManageJobOwnersModal({ job });
        }
        break;
      }
      case ENUMS.JobActions.JOB_EVENTS: {
        openJobEventsModal(job.id);
        break;
      }
      case ENUMS.JobActions.NOTES: {
        openNotesModal(job.id, ENUMS.NoteContentType.Job);
        break;
      }
      case ENUMS.JobActions.SHARE_JOB: {
        openReferJobModal({ job });
        break;
      }
      case ENUMS.JobActions.EDIT_SUPPORT_TYPE: {
        openJobFillSupportTypeModal(job as JobForCoreTeam);
        break;
      }
      case ENUMS.JobActions.EDIT_JOB: {
        navigate(`/jobs/${job.id}/edit/`);
        break;
      }
      case ENUMS.JobActions.DUPLICATE_JOB_POST: {
        checkJobsLimit({
          onLimitNotExceeded: () =>
            navigate(`/jobs/add_new?copy_job_id=${job.id}`),
        });
        break;
      }
      case ENUMS.JobActions.CREATE_INVOICE: {
        navigate(
          `/talent/invoices/add_new/?job=${job.id}&prevPath=/jobs/${job.id}/`,
        );
        break;
      }
      case ENUMS.JobActions.REVIEW_CANDIDATES: {
        navigate(`/jobs/${job.id}/proposals`);
        break;
      }
      case ENUMS.JobActions.INVITE_CANDIDATE: {
        navigate(`/jobs/${job.id}/invite_talent`);
        break;
      }
      case ENUMS.JobActions.PAY_INVOICES:
      case ENUMS.JobActions.PAY_OVERDUE_INVOICES: {
        navigate(`/employer/invoices/?unpaid=true&job=${job.id}`);
        break;
      }
      case ENUMS.JobActions.RESET_JOB_POST_DATE: {
        try {
          await jobPostDataRefresh(job.id);
          Snackbar.success("Job has been refreshed.");
          afterAction({ job });
          navigate(`/jobs/${job.id}/`);
        } catch {
          Snackbar.error("Sorry, this job can't be refreshed.");
        }
        break;
      }
      case ENUMS.JobActions.COMPLETE_JOB: {
        const title = `Are you sure you want to close this Job? This means job will be
        archived and no action can be performed on it. This action is
        irreversible`;
        const action = async () => {
          if (job?.hired_bids_count || job?.freelancers_hired_count) {
            try {
              await completeJob({
                jobId: job.id,
                data: {
                  client_feedback_was_job_filled: true,
                },
              }).unwrap();
              openReviewsPostJobCompletedModal(job.id);
              openSuccessToast();
              afterAction({ job });
              navigate(`/jobs/${job.id}/`);
            } catch {
              Snackbar.error(`Sorry, this job can't be closed.`);
            }
          } else {
            closeJob(job);
          }
        };
        openConfirmModal(title, action);
        break;
      }
      case ENUMS.JobActions.PUT_ON_HOLD: {
        const title = `Are you sure you want to pause this Job?  This means talent will not be able
        to submit an application on your job, and you will not be able
        to invite talent to this job. You can reverse this action later.`;
        const action = async () => {
          try {
            await holdJob(job.id);
            Snackbar.success("Job has been paused.");
            afterAction({ job });
            navigate(`/jobs/${job.id}/`);
          } catch {
            Snackbar.error(`Sorry, this job can't be paused.`);
          }
        };
        openConfirmModal(title, action);
        break;
      }
      case ENUMS.JobActions.RELEASE_FROM_HOLD: {
        const title = `Are you sure you want to unpause this Job? Talent will be able to submit an application
        in this Job and  you will be able to invite talent to this job.`;
        const action = async () => {
          try {
            await releaseJob(job.id);
            Snackbar.success("Job has been unpaused.");
            afterAction({ job });
            navigate(`/jobs/${job.id}/`);
          } catch {
            Snackbar.error(`Sorry, this job can't be unpaused.`);
          }
        };
        openConfirmModal(title, action);
        break;
      }
      case ENUMS.JobActions.CLOSE_INVOICING: {
        const title = `Are you sure you want to close invoicing for this Job? Talent will
        not be able to submit a new invoice.`;
        const action = async () => {
          try {
            await toggleJobInvoicing(job.id, {
              closed: true,
            });
            afterAction({ job });

            Snackbar.success("Invoicing has been closed.");
          } catch {
            Snackbar.error(`Sorry, this job invoicing can't be closed.`);
          }
        };
        openConfirmModal(title, action);
        break;
      }
      case ENUMS.JobActions.OPEN_INVOICING: {
        const title = `Are you sure you want to open invoicing for this Job? Talent
        will able to submit a new invoice.`;
        const action = async () => {
          try {
            await toggleJobInvoicing(job.id, {
              closed: false,
            });
            afterAction({ job });
            Snackbar.success("Invoicing has been opened.");
          } catch {
            Snackbar.error(`Sorry, this job invoicing can't be opened.`);
          }
        };
        openConfirmModal(title, action);
        break;
      }
      case ENUMS.JobActions.SUBSCRIBE_TO_JOB: {
        if (!user) {
          return;
        }
        try {
          await subscribeToJob({
            jobId: job.id,
            team_member_id: user.id,
          }).unwrap();

          afterAction({ job });

          Snackbar.custom(
            <JobActionsSubscriptionSnackbarContent>
              You’ve subscribed to this job: {job.title}
            </JobActionsSubscriptionSnackbarContent>,
            {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              key: job.id,
            },
          );
        } catch (error: unknown) {
          if (
            axios.isAxiosError(error) &&
            typeGuard<unknown, { job: string }>(error?.response?.data, "job")
          ) {
            return Snackbar.error(error?.response?.data?.job);
          }

          Snackbar.error(`Sorry, can't subscribe to this job.`);
        }
        break;
      }
      case ENUMS.JobActions.UNSUBSCRIBE_FROM_JOB: {
        if (!user) {
          return;
        }

        try {
          await unsubscribeFromJob({
            jobId: job.id,
            team_member_id: user.id,
          }).unwrap();

          afterAction({ job });

          return Snackbar.custom(
            <JobActionsSubscriptionSnackbarContent>
              You’ve unsubscribed from this job: {job.title}
            </JobActionsSubscriptionSnackbarContent>,
            {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              key: job.id,
            },
          );
        } catch (error: unknown) {
          if (
            axios.isAxiosError(error) &&
            typeGuard<unknown, { job: string }>(error?.response?.data, "job")
          ) {
            return Snackbar.error(error?.response?.data?.job);
          }

          Snackbar.error(`Sorry, can't unsubscribe from this job.`);
        }

        break;
      }
      case ENUMS.JobActions.ADD_TEAM_MEMBERS: {
        if (!user) {
          return;
        }

        dispatch(addTeamMembersClicked());

        openSubscribeTeamMembersToJobModal({
          jobId: job.id,
          jobOwnerId: job.creator?.id,
        });

        break;
      }
      default:
        assertUnreachable(actionName);
        break;
    }
  };

  const createInvoiceAction = (job: Job | null) =>
    job?.job_actions.find(
      (item) => item.action === ENUMS.JobActions.CREATE_INVOICE,
    );

  return {
    handleJobAction,
    createInvoiceAction,
    JobActionModalInstance,
  };
};

const openConfirmModal = (title: string, callback: () => Promise<void>) => {
  ModalInstance.open({
    className: "job-actions-confirmation-modal",
    children: (
      <ModalConfirm
        onConfirm={async () => {
          ModalInstance.close();
          await callback();
        }}
        onCancel={ModalInstance.close}
      >
        {title}
      </ModalConfirm>
    ),
  });
};
