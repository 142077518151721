import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";

import { Snackbar } from "@js/components/snackbar";
import { useParsedQueryParamsWithDefaults } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { useSubmitFormOnChange } from "@js/rhf/hooks/submit-form-on-change";
import { buildSearchParams } from "@js/utils/url";

import type { EmployerInvoicesFormValues } from "../schema";
import {
  employerInvoicesFormSchema,
  type EmployerInvoicesFormValuesWithDefaults,
} from "../schema";

const defaultFormValues = {
  search: null,
  status: [],
  jobs: [],
  job_owners: [],
  freelancers: [],
  date_before: null,
  date_after: null,
  order_by: undefined,
  order_dir: undefined,
  page: 1,
} satisfies EmployerInvoicesFormValuesWithDefaults;

type UseEmployerInvoicesFiltersArg = { onFiltersChange: () => void };

export const useEmployerInvoicesFilters = ({
  onFiltersChange,
}: UseEmployerInvoicesFiltersArg) => {
  const [, setSearchParams] = useSearchParams();
  const { data: formDataFromSearchParams, success } =
    useParsedQueryParamsWithDefaults(
      employerInvoicesFormSchema,
      defaultFormValues,
    );

  const { control, handleSubmit, watch, formState, reset, setValue } =
    useForm<EmployerInvoicesFormValues>({
      resolver: zodResolver(employerInvoicesFormSchema),
      mode: "onSubmit",
      values: formDataFromSearchParams,
    });

  const onSubmit = (values: EmployerInvoicesFormValues) => {
    const filters = buildSearchParams(values);
    setSearchParams(filters, {
      state: { disableRemountIfTheSameLocation: true },
    });
    onFiltersChange();
  };

  useSubmitFormOnChange({ watch, handleSubmit, formState, onSubmit });

  const clearFilters = () => {
    reset(defaultFormValues, { keepDefaultValues: true });
  };

  const setSearchParamsRef = useEffectRef(setSearchParams);

  useEffect(() => {
    if (success) {
      return;
    }

    setSearchParamsRef.current(
      {},
      { state: { disableRemountIfTheSameLocation: true } },
    );
    Snackbar.error(
      "Invalid filters detected. Please try again with different settings.",
    );
  }, [success, setSearchParamsRef]);

  return {
    control,
    filters: formDataFromSearchParams,
    clearFilters,
    setValue,
  };
};
