import React from "react";

import { Stack } from "@hexocean/braintrust-ui-components";
import type { EmployerBidListBid } from "@js/types/jobs";

type RecommendationBadgeProps = {
  bid: EmployerBidListBid;
};

export const RecommendationBadge = ({ bid }: RecommendationBadgeProps) => {
  if (
    bid.status !== ENUMS.BidStatus.INTERVIEWING ||
    !bid.interview ||
    !bid.interview.is_completed ||
    bid.interview.status !== ENUMS.JobInterviewStatus.ROUND_1
  ) {
    return null;
  }

  const isRecommended =
    parseInt(bid.interview?.overall_score?.toString() || "") >= 4;

  return (
    <Stack
      direction="row"
      className={`recommendation-badge recommendation-badge${isRecommended ? "--success" : "--fail"}`}
    >
      <img
        src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
        alt="ai job"
        height={18}
      />
      Recommend to {isRecommended ? "advance" : "reject"}
    </Stack>
  );
};
