import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";

import {
  ComboBoxMultiple,
  PaperWithVioletOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { useGetEmployerTeamMembersQuery } from "@js/apps/employer/api";
import type { RhfForm } from "@js/rhf/types";
import type { EmployerTeamMemberType } from "@js/types/employer";

import { useInitiallyOpenDropdown } from "../hooks";

type Form = RhfForm<typeof employerMyTalentJobOwnerFormSchema>;
type FormValues = Form["Values"];
type DefaultFormValues = Form["DefaultValues"];

type ComboBoxOptions = Pick<EmployerTeamMemberType, "id" | "public_name">;

type EmployerMyTalentJobOwnerFormProps = {
  jobOwners: DefaultFormValues["job_owners"];
  onApply: (jobOwners: FormValues["job_owners"]) => void;
  shouldOpenDropdown: boolean;
};

import { zodResolver } from "@hookform/resolvers/zod";

import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

import { FilterButtonPopoverFooter } from "../filter-button-popover-footer";
import { FilterPopoverFormLayoutWrapper } from "../filter-popover-form-layout-wrapper";
import { employerMyTalentJobOwnerFormSchema } from "../schemas";

import { EmployerMyTalentFiltersAutocomplete } from "./employer-my-talent-filters-autocomplete";

export const EmployerMyTalentJobOwnerForm = ({
  jobOwners,
  onApply,
  shouldOpenDropdown,
}: EmployerMyTalentJobOwnerFormProps) => {
  const defaultValues = useMemo(() => {
    return { job_owners: jobOwners };
  }, [jobOwners]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerMyTalentJobOwnerFormSchema),
    defaultValues,
  });

  const { field } = useController({
    name: "job_owners",
    control: control,
  });

  const onSubmit: SubmitHandler<FormValues> = (value) => {
    onApply(value.job_owners);
  };

  const { data: jobOwnersData = [], isLoading } =
    useGetEmployerTeamMembersQuery();

  const onReset = () => {
    reset({ job_owners: [] });
  };

  const valueList = useMemo(() => {
    return jobOwnersData.filter((data) => {
      return field.value?.includes(data.id);
    });
  }, [field.value, jobOwnersData]);

  const isFilterEmpty = !field.value?.length;
  const { autocompleteTextFieldRef } = useInitiallyOpenDropdown({
    isFilterEmpty,
    shouldOpenDropdown,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <ComboBoxMultiple<ComboBoxOptions, true>
            openOnFocus
            {...field}
            ref={null}
            value={valueList}
            onChange={(_event, values) => {
              field.onChange(
                values.map((value) => {
                  return value.id;
                }),
              );
            }}
            ChipProps={{
              deleteIcon: (
                <CloseIcon sx={{ color: "var(--black)!important" }} />
              ),
            }}
            disableClearable
            component={EmployerMyTalentFiltersAutocomplete}
            PaperComponent={PaperWithVioletOptions}
            initialTaxonomiesLoading={isLoading}
            options={jobOwnersData}
            getOptionLabel={(option) => {
              return option.public_name;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.public_name}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={autocompleteTextFieldRef}
                  {...params}
                  placeholder={isFilterEmpty ? "Select a job owner" : undefined}
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Select job owners",
                  }}
                />
              );
            }}
          />
        }
        formFooter={<FilterButtonPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
