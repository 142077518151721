import { getBodyFontSize, getTextWidth } from "../dom";

export type AutosizedColumnDef<T extends Record<string, unknown>> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  field: keyof T | (string & {}); // use string & {} to autocomplete field and still allow any string
  headerName: string;
  autosizeExtraWidth?: number;
  minWidth?: number;
  maxWidth?: number;
  getAutosizedCellContentWidth?: (
    row: T,
    fontSize: string,
  ) => number | undefined; // takes precedence over getAutosizedCellContent
  getAutosizedCellContent?: (row: T) => string | undefined;
};

const getTableTextFontSize = (fontSize?: string): string => {
  return fontSize || getBodyFontSize();
};

type GetColumnWidthToFitContentArg<T extends Record<string, unknown>> = {
  data: T[];
  column: AutosizedColumnDef<T>;
  fontSize?: string;
};

const getCellWidth = <T extends Record<string, unknown>>({
  row,
  column,
  fontSize,
}: {
  column: AutosizedColumnDef<T>;
  row: T;
  fontSize: string;
}) => {
  const columnWidthFromOptionalCallback = column.getAutosizedCellContentWidth?.(
    row,
    fontSize,
  );
  if (typeof columnWidthFromOptionalCallback !== "undefined") {
    return columnWidthFromOptionalCallback;
  }

  const rowValue = column.getAutosizedCellContent
    ? column.getAutosizedCellContent(row)
    : row[column.field];

  if (typeof rowValue !== "string") {
    return;
  }

  const extraWidth = column.autosizeExtraWidth ?? 0;
  const currentValueWidth = getTextWidth(rowValue, fontSize) + extraWidth;

  return currentValueWidth;
};

export const getColumnWidthToFitContent = <T extends Record<string, unknown>>({
  data,
  column,
  fontSize,
}: GetColumnWidthToFitContentArg<T>) => {
  const fontSizeForCanvas = getTableTextFontSize(fontSize);

  return data.reduce(
    (acc, row) => {
      const cellWidth = getCellWidth({
        row,
        column,
        fontSize: fontSizeForCanvas,
      });
      if (!cellWidth || acc >= cellWidth) {
        return acc;
      }

      return Math.ceil(cellWidth);
    },
    Math.ceil(getTextWidth(column.headerName, fontSizeForCanvas)),
  );
};

export const getAutosizedColumnWidth = <T extends Record<string, unknown>>({
  data,
  column,
  fontSize,
}: GetColumnWidthToFitContentArg<T>): number => {
  const columnTotalSidePadding = 16;
  const columnAdditionalSpaceWidth = 16;
  const columnWidthToFitContent = getColumnWidthToFitContent({
    data,
    column,
    fontSize,
  });

  const columnAutosizedWidth =
    columnWidthToFitContent +
    columnTotalSidePadding +
    columnAdditionalSpaceWidth;

  if (column.minWidth && column.minWidth > columnAutosizedWidth) {
    return column.minWidth;
  }
  if (column.maxWidth && column.maxWidth < columnAutosizedWidth) {
    return column.maxWidth;
  }

  return columnAutosizedWidth;
};
