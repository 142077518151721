import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { Snackbar } from "@js/components/snackbar";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useUpdateEmployerBidSectionMutation } from "../../api";

type AiInterviewButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  onOptionClick?: () => void;
  closeDrawer?: () => void;
} & Pick<ButtonProps, "sx" | "size" | "className">;

export const AiInterviewButton = ({
  job,
  bid,
  onOptionClick,
  closeDrawer,
  ...buttonProps
}: AiInterviewButtonProps) => {
  const { refetchBidList } = useBidsContext();

  const [updateEmployerBidSection] = useUpdateEmployerBidSectionMutation();

  const handleSendInterviewError = () => {
    Snackbar.error(
      "Updating status failed. Please refresh the page and try again",
    );
  };

  const handleSubmit = async () => {
    if (bid) {
      const sendInterviewResponse = await updateEmployerBidSection({
        bidId: bid.id,
        status: ENUMS.BidStatus.INTERVIEWING,
      });

      if (!!sendInterviewResponse && "error" in sendInterviewResponse) {
        handleSendInterviewError();
      } else {
        Snackbar.success("AI Interview request sent", {
          transitionDuration: {
            enter: 200,
            exit: 200,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }

      refetchBidList?.();
    }
  };

  return (
    <Button
      variant="primary"
      shape="squared"
      disabled={!!job.completed_at || job.openings_left === 0}
      sx={{ textWrap: "nowrap" }}
      {...buttonProps}
      onClick={() => {
        onOptionClick?.();
        handleSubmit();
      }}
    >
      Send AI Interview request
    </Button>
  );
};
