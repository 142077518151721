import { Box, Checkbox } from "@hexocean/braintrust-ui-components";
import type { UseTalentLocationFilterFieldManagerArg } from "@js/apps/common/hooks/use-talent-location-filter-field-manager";
import { useTalentLocationFilterFieldManager } from "@js/apps/common/hooks/use-talent-location-filter-field-manager";
import type {
  LocationSelectReason,
  TalentMixedLocation,
} from "@js/apps/freelancer/types";
import { LOCATIONS_TAGS_CHARACTERS_LIMIT } from "@js/apps/jobs/apps/create-job/constants";
import {
  prepareArrayWithTagsCharactersLimit,
  prepareAutocompleteValue,
} from "@js/apps/jobs/apps/create-job/utils/prepare-data";
import type { GooglePlacesMultipleFieldDeprecatedProps } from "@js/forms/fields";

import { useLocationAndTimezoneNotification } from "../../use-location-and-timezone-notification";
import { isLocationOptionOnTheList } from "../helpers";

import {
  filterDuplicatedOptions,
  filterSelectedAndCustomOptions,
  getLocationName,
  handleOptionDisabled,
  isOnlyLocation,
  mapSelectedOptions,
  sortSelectedAndCustomOptions,
} from "./helpers";

export type UseJobLocationFieldArg = UseTalentLocationFilterFieldManagerArg;

export const useJobLocationField = ({
  location,
  custom_location,
  place_id,
  session_token,
}: UseJobLocationFieldArg) => {
  const { handleLocationSelection } = useTalentLocationFilterFieldManager({
    location,
    custom_location,
    place_id,
    session_token,
  });
  const { showLimitNotification } = useLocationAndTimezoneNotification();

  const handleChange = (
    data: Array<TalentMixedLocation | string>,
    reason: LocationSelectReason,
  ) => {
    const { isOnlyLocation: isOnlyLocationChosen, location: onlyLocation } =
      isOnlyLocation(data);

    if (isOnlyLocationChosen && onlyLocation) {
      handleLocationSelection([onlyLocation], reason, true);
      return;
    }

    if (data.length > SETTINGS.JOB_LOCATIONS_LIMIT) {
      showLimitNotification("LOCATIONS", SETTINGS.JOB_LOCATIONS_LIMIT);
      return;
    }

    handleLocationSelection(data, reason);
  };

  const filterOptionsHandler = (
    options: TalentMixedLocation[],
    { inputValue }: { inputValue: string },
  ) => {
    return options
      .filter((option) => filterSelectedAndCustomOptions(option, inputValue))
      .sort(sortSelectedAndCustomOptions)
      .map(mapSelectedOptions)
      .filter(filterDuplicatedOptions);
  };

  const handleDisableOptionFromList = (option: TalentMixedLocation) => {
    return handleOptionDisabled(option, location.input.value || []);
  };

  const renderOptionsHandler: GooglePlacesMultipleFieldDeprecatedProps<false>["renderOption"] =
    (props, option: TalentMixedLocation, { selected }) => {
      const isOptionDisabled = handleOptionDisabled(
        option,
        location.input.value || [],
      );
      const isLocationHasBeenSelected = isLocationOptionOnTheList(
        location.input.value || [],
        option,
      );

      const isSelected = selected || isLocationHasBeenSelected;

      return (
        <div
          key={option.name}
          style={{
            cursor: isOptionDisabled ? "not-allowed" : "initial",
          }}
        >
          <Box component="li" {...props}>
            <Checkbox
              sx={{
                backgroundColor: isSelected ? "var(--yellow)" : "",
              }}
              checked={isSelected}
            />
            {option.name || option.formatted_address}
          </Box>
        </div>
      );
    };

  const renderTagsHandler: GooglePlacesMultipleFieldDeprecatedProps<false>["renderTags"] =
    (locationsCollection: TalentMixedLocation[], _, { focused }) => {
      const locationTagsPreparation = prepareArrayWithTagsCharactersLimit(
        locationsCollection,
        LOCATIONS_TAGS_CHARACTERS_LIMIT,
        getLocationName,
        focused,
      );

      return locationTagsPreparation.map((loc, index) => {
        return prepareAutocompleteValue({
          value: getLocationName(loc),
          isLast: index === locationTagsPreparation.length - 1,
          isInputFocused: focused,
          isLimitReached:
            locationTagsPreparation.length < locationsCollection.length,
        });
      });
    };

  return {
    handleDisableOptionFromList,
    handleChange,
    renderOptionsHandler,
    renderTagsHandler,
    filterOptionsHandler,
  };
};
