import type { ReactNode } from "react";

import { Box, Divider } from "@hexocean/braintrust-ui-components";

import style from "./filter-popover-form-layout-wrapper.module.scss";

type FilterPopoverFormLayoutWrapperProps = {
  formBody: ReactNode;
  formFooter: ReactNode;
};

export const FilterPopoverFormLayoutWrapper = ({
  formBody,
  formFooter,
}: FilterPopoverFormLayoutWrapperProps) => {
  return (
    <Box className={style.filterPopoverFormContentContainer}>
      {formBody}
      <Divider color="beige" />
      {formFooter}
    </Box>
  );
};
