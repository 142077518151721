import { z } from "zod";

import {
  zodCommaNumberArray,
  zodCommaStringToArray,
  zodDate,
} from "@js/rhf/schemas";
import type { RhfForm } from "@js/rhf/types";
import { DateFormats } from "@js/utils/date/types";

import { EMPLOYER_INVOICES_SORTABLE_FIELDS_MAP } from "../constants";

export const employerInvoicesSearchFormSchema = z.object({
  search: z.string().nullish(),
});

const superRefineDates = (
  {
    date_after,
    date_before,
  }: {
    date_after?: string | null;
    date_before?: string | null;
  },
  ctx: z.RefinementCtx,
) => {
  if (!date_after || !date_before) {
    return;
  }
  const isStartDateAfterEndDate = new Date(date_after) > new Date(date_before);
  if (!isStartDateAfterEndDate) {
    return;
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path: ["date_after"],
    message: "Start date cannot be after the end date",
  });

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path: ["date_before"],
    message: "Start date cannot be after the end date",
  });
};

export const employerInvoicesDateRangeBaseFormSchema = z.object({
  date_after: zodDate(DateFormats["1970-12-31"]).nullish(),
  date_before: zodDate(DateFormats["1970-12-31"]).nullish(),
});

export const employerInvoicesDateRangeFormSchema =
  employerInvoicesDateRangeBaseFormSchema.superRefine(superRefineDates);

export const employerInvoicesStatusFormSchema = z.object({
  status: zodCommaStringToArray.pipe(
    z.array(z.nativeEnum(ENUMS.InvoiceStatus)).optional(),
  ),
});

export const employerInvoicesTalentFormSchema = z.object({
  freelancers: zodCommaNumberArray,
});

export const employerInvoicesJobsFormSchema = z.object({
  jobs: zodCommaNumberArray,
});

export const employerInvoicesJobOwnerFormSchema = z.object({
  job_owners: zodCommaNumberArray,
});

export const employerInvoicesGeneralFormSchema = z.object({
  page: z.coerce.number().int().positive().optional(),
  order_by: z.nativeEnum(EMPLOYER_INVOICES_SORTABLE_FIELDS_MAP).optional(),
  order_dir: z.enum(["asc", "desc"]).optional(),
});

export const employerInvoicesFormSchema = z
  .object({})
  .merge(employerInvoicesGeneralFormSchema)
  .merge(employerInvoicesSearchFormSchema)
  .merge(employerInvoicesDateRangeBaseFormSchema)
  .merge(employerInvoicesStatusFormSchema)
  .merge(employerInvoicesTalentFormSchema)
  .merge(employerInvoicesJobsFormSchema)
  .merge(employerInvoicesJobOwnerFormSchema)
  .superRefine(superRefineDates);

export type EmployerInvoicesForm = RhfForm<typeof employerInvoicesFormSchema>;

export type EmployerInvoicesFormValues = EmployerInvoicesForm["Values"];

export type EmployerInvoicesFormValuesWithDefaults =
  EmployerInvoicesForm["DefaultValues"];
