import type { ReactNode } from "react";

import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";

import { EmployerInvoicesDiscoverSelectionTooltip } from "../../employer-invoices-discover-selection-tooltip";
import type { EmployerInvoiceRow } from "../../types";
import { useEmployerInvoicesTableContext } from "../employer-invoices-table-context";
import { isEmployerInvoiceItemRow } from "../helpers";

import { CheckboxCellSelectionLimitWrapper } from "./checkbox-cell-selection-limit-wrapper";

type CheckboxCellProps = {
  children: ReactNode;
} & GridRenderCellParams<EmployerInvoiceRow, boolean>;

export const CheckboxCell = ({
  children,
  row,
  value: isSelected,
}: CheckboxCellProps) => {
  const {
    selectionIntroductionInvoiceId,
    onDismissSelectionIntroductionClick,
    onTryOutSelectionClick,
    isSelectionLimitReached,
  } = useEmployerInvoicesTableContext();

  if (isEmployerInvoiceItemRow(row)) {
    return children;
  }

  const showIntroductionTooltip = selectionIntroductionInvoiceId === row.id;
  if (showIntroductionTooltip) {
    return (
      <EmployerInvoicesDiscoverSelectionTooltip
        onDismissClick={onDismissSelectionIntroductionClick}
        onTryItOutClick={onTryOutSelectionClick}
      >
        {children}
      </EmployerInvoicesDiscoverSelectionTooltip>
    );
  }

  const showSelectionLimitReachedTooltip =
    isSelectionLimitReached && !isSelected;
  if (showSelectionLimitReachedTooltip) {
    return (
      <CheckboxCellSelectionLimitWrapper>
        {children}
      </CheckboxCellSelectionLimitWrapper>
    );
  }

  return children;
};
