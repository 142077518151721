import { useState } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import HlsPlayer from "@js/components/video-player/hls-player";

import { JobInterviewDescription } from "../job-interview-grading-summary";

import "./style.css";

export const JobInterviewVideoIcon = ({
  videoUrl,
  setDuration,
}: {
  setDuration: (x: number | undefined) => void;
  videoUrl: string | null;
}) => {
  return (
    <Box
      sx={{
        borderRadius: "16px",
        backgroundColor: "var(--soft-violet)",
        overflow: "hidden",
        width: "142px",
        height: "128px",
      }}
    >
      {videoUrl ? (
        <HlsPlayer
          customClassName="video-icon"
          onLoadedMetadata={(player) => setDuration(player.duration())}
          options={{
            autoplay: false,
            sources: [
              {
                src: videoUrl,
                type: "application/x-mpegURL",
              },
            ],
          }}
        />
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          width: "100%",
          height: "100%",
          justifyContent: "center",
          zIndex: 3,
          position: "relative",
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-video.png`}
          width={36}
          alt="ai video icon"
        />
        <Button
          size="x-small"
          shape="squared"
          variant="medium-violet"
          sx={{ width: "102px", textWrap: "nowrap" }}
        >
          Watch Video
        </Button>
      </Box>
    </Box>
  );
};

export const JobInterviewVideoSummary = ({
  title,
  description,
  openVideo,
  videoUrl,
}: {
  openVideo: () => void;
  title: string;
  description: string;
  videoUrl: string | null;
}) => {
  const [duration, setDuration] = useState<undefined | number>(0);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        cursor: "pointer",
      }}
      onClick={openVideo}
    >
      <JobInterviewVideoIcon setDuration={setDuration} videoUrl={videoUrl} />
      <Box
        sx={{
          flex: 1,
        }}
        ml={2}
      >
        <Typography
          component="span"
          variant="paragraph"
          size="small"
          color="grey-2"
          mb={1}
        >
          {duration ? convertSecondsToTimeString(duration) : null}
        </Typography>
        <Typography component="h3" m={0} variant="label" size="medium">
          {title}
        </Typography>
        <Box style={{ pointerEvents: "none" }}>
          <JobInterviewDescription description={description} />
        </Box>
      </Box>
    </Box>
  );
};

export const convertSecondsToTimeString = (duration: number) => {
  const newDuration = Math.floor(duration);
  const minutes = Math.floor(newDuration / 60);
  const seconds = Math.round(newDuration % 60);

  return `${minutes} minutes ${seconds} seconds`;
};
