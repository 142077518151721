import { useMemo } from "react";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useCtaClickedContext } from "@js/apps/common/context";
import { BoxedEmployerLogo } from "@js/apps/employer/components/employer-logo";
import { AppliedJobsCardLastActionDate } from "@js/apps/freelancer/components/dashboard/applied-jobs-section/applied-jobs-card-last-action-date";
import { ApplicationStatusWithTooltip } from "@js/apps/jobs/components/application-status";
import { BoostedJobBadge } from "@js/apps/jobs/components/boosted-job-badge";
import { ALTERNATIVE_LISTING_BG_COLOR } from "@js/apps/jobs/constants";
import { RouterLink } from "@js/components/link";
import type { FreelancerBid } from "@js/types/jobs";

import { EmplyerNameAndJobTitle } from "../../employer-name-and-job-title";

import { AppliedJobsCTA } from "./applied-jobs-cta";

type AppliedJobsCardProps = {
  bid: FreelancerBid;
};

const STATUSES_FOR_APPLIED_JOBS_CARD: EnumType<
  typeof ENUMS.JobFreelancerStatus
>[] = [
  ENUMS.JobFreelancerStatus.IN_REVIEW,
  ENUMS.JobFreelancerStatus.PENDING_APPROVAL,
  ENUMS.JobFreelancerStatus.APPLICATION_SENT,
  ENUMS.JobFreelancerStatus.INTERVIEWING,
  ENUMS.JobFreelancerStatus.NEW_OFFER,
  ENUMS.JobFreelancerStatus.UPDATED_OFFER,
  ENUMS.JobFreelancerStatus.JOB_ON_HOLD,
];

export const AppliedJobsCard = ({ bid }: AppliedJobsCardProps) => {
  const { ctaClicked } = useCtaClickedContext();
  const { logo_background_color, logo_thumbnail } = bid.job.employer;
  const statusForFreelancer = bid.status_for_freelancer;

  const showApplicationStatus =
    STATUSES_FOR_APPLIED_JOBS_CARD.includes(statusForFreelancer);

  const displayStatus = useMemo(() => {
    if (
      bid?.interview?.status === ENUMS.JobInterviewStatus.ROUND_1 &&
      bid?.interview?.is_completed
    ) {
      return {
        appliedTitle: "In review",
        appliedTooltip: "Your AI Interview is being reviewed",
      };
    } else if (
      bid?.interview?.status === ENUMS.JobInterviewStatus.ROUND_1 &&
      !bid?.interview?.is_completed
    ) {
      return {
        appliedTooltip: "You’ve been invited to an AI interview",
      };
    }
  }, [bid]);

  return (
    <div className="applied-jobs-section__card">
      <div className="applied-jobs-section__header">
        <Typography
          component="p"
          size="small"
          color="secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          <AppliedJobsCardLastActionDate bid={bid} />
        </Typography>

        {showApplicationStatus && (
          <ApplicationStatusWithTooltip
            status={statusForFreelancer}
            appliedDate={bid.created}
            offerCreatedDate={bid.current_offer_created_at}
            offerAcceptedDate={bid.current_offer_accepted_at}
            {...displayStatus}
          />
        )}
      </div>

      <div className="applied-jobs-section__info">
        <BoxedEmployerLogo
          employer={bid.job.employer}
          size="small"
          source={logo_thumbnail}
          bgcolor={`var(${
            logo_background_color || ALTERNATIVE_LISTING_BG_COLOR
          })`}
          style={{
            borderRadius: "12px",
            minWidth: "48px",
            height: "48px",
          }}
        />
        <Box flex={1}>
          {bid.is_boosted && (
            <BoostedJobBadge jobId={bid.job.id} isJobOpen={bid.job.is_open} />
          )}
          <EmplyerNameAndJobTitle
            job={bid.job}
            employerNameSize="medium"
            jobTitleSize="small"
          />
        </Box>
        <IconButton
          variant="transparent"
          size="medium"
          aria-label="Go to job"
          to={`/jobs/${bid.job.id}`}
          onClick={() =>
            ctaClicked(ENUMS.UserClicksEventCTAName.VIEW_APPLICATION)
          }
          sx={{ mr: -1 }}
          RouterLink={RouterLink}
        >
          <ArrowRightIcon />
        </IconButton>
      </div>
      <AppliedJobsCTA bid={bid} />
    </div>
  );
};
