import type { JobApplicationInsightsResponse } from "@js/types/jobs";

type MatchSummaryArray = Array<{ sentence: string; summary_type: string }>;

const useSortedToolTip = (
  data?: JobApplicationInsightsResponse,
): MatchSummaryArray => {
  const matchSummary = [...(data?.match_summary || [])];

  if (matchSummary.length) {
    const sortedToolTip = matchSummary.sort((firstItem, secondItem) => {
      if (firstItem.sentence === "Great reviews for previous jobs") return -1;
      if (secondItem.sentence === "Great reviews for previous jobs") return 1;
      if (firstItem.summary_type === secondItem.summary_type) return 0;
      return firstItem.summary_type === "positive" ? -1 : 1;
    });
    return sortedToolTip;
  }

  return matchSummary;
};

export default useSortedToolTip;
