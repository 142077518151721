import { API } from "@js/api";
import type { EmployerReview } from "@js/types/employer";
import type { Freelancer } from "@js/types/freelancer";
import type {
  JobReview,
  ReviewProcess,
  TalentReview,
  TalentReviewsSummary,
} from "@js/types/reviews";

import type { PublicUser } from "../../types/auth";

type CreateTalentHelpReviewProps = {
  subject: number;
  score: number;
  tags: number[];
  additional_comments: string;
  review_process: number;
};

export type TalentHelpReview = CreateTalentHelpReviewProps & {
  id: number;
  reviewer: Freelancer;
  creator: PublicUser;
  created: string;
};

type TalentHelpReviewsTag = {
  name: string;
  id: number;
};

type ReportReviewResults = {
  id: number;
};

type ReportReviewParams = {
  id: number;
  data: { reason: string };
};

const reviewsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getTalentReviews: build.query<
      TalentReview[],
      { id: number; count: number }
    >({
      query: ({ id, count = 0 }) => ({
        url: `/freelancer_reviews/${id}/`,
        method: "GET",
        params: {
          page_size: count, // 0 = all
        },
      }),
      providesTags: ["TalentReviews"],
      transformResponse(
        response: TalentReview[] | { results: TalentReview[] },
      ) {
        return Array.isArray(response) ? response : response.results;
      },
    }),
    getTalentReviewsSummary: build.query<
      TalentReviewsSummary,
      { id: number; helpCategoryId?: number }
    >({
      query: ({ id, helpCategoryId }) => ({
        url: `/freelancer_reviews/${id}/summary`,
        method: "GET",
        params: {
          help_category_offer: helpCategoryId,
        },
      }),
      providesTags: ["TalentReviewsSummary"],
    }),
    getReviewTags: build.query<TalentHelpReviewsTag[], void>({
      query: () => ({
        url: `/review_tags`,
        method: "GET",
      }),
    }),
    createTalentHelpReview: build.mutation<
      TalentHelpReview,
      CreateTalentHelpReviewProps
    >({
      query: (data) => ({
        url: `/help_reviews/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "TalentReviews",
        "TalentReviewsSummary",
        "FreelancerPublicProfile",
      ],
    }),
    reportReview: build.mutation<ReportReviewResults, ReportReviewParams>({
      query: ({ id, data }) => ({
        url: `/user_reviews/${id}/report/`,
        method: "POST",
        data,
      }),
    }),
    getEmployerReviews: build.query<
      EmployerReview[],
      { id: number; count: number }
    >({
      query: ({ id, count = 0 }) => ({
        url: `/employer_reviews/${id}/`,
        method: "GET",
        params: {
          page_size: count, // 0 = all
        },
      }),
      providesTags: ["EmployerReviews"],
      transformResponse(
        response: EmployerReview[] | { results: EmployerReview[] },
      ) {
        return Array.isArray(response) ? response : response.results;
      },
    }),
    getOpenReviewProcesses: build.query<
      ReviewProcess[],
      { content_type: string; jobId?: number; subject?: number }
    >({
      query: (params) => ({
        url: `/open_reviews/`,
        method: "GET",
        params: {
          ...params,
          job: params.jobId,
        },
      }),
      providesTags: ["OpenReviewProcesses"],
    }),
    createJobWorkReview: build.mutation<void, JobReview>({
      query: (data) => ({
        url: `/work_reviews/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "TalentReviews",
        "TalentReviewsSummary",
        "EmployerReviews",
        "OpenReviewProcesses",
        "FreelancerPublicProfile",
      ],
    }),
    createJobEmploymentReview: build.mutation<void, JobReview>({
      query: (data) => ({
        url: `/employment_reviews/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "TalentReviews",
        "TalentReviewsSummary",
        "EmployerReviews",
        "OpenReviewProcesses",
      ],
    }),
    createJobCoworkerReview: build.mutation<void, JobReview>({
      query: (data) => ({
        url: `/coworker_reviews/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "TalentReviews",
        "TalentReviewsSummary",
        "OpenReviewProcesses",
        "EmployerReviews",
        "FreelancerPublicProfile",
      ],
    }),
  }),
});

export const {
  useLazyGetTalentReviewsQuery,
  useGetTalentReviewsQuery,
  useGetEmployerReviewsQuery,
  useGetOpenReviewProcessesQuery,
  useGetTalentReviewsSummaryQuery,
  useGetReviewTagsQuery,
  useReportReviewMutation,
  useCreateTalentHelpReviewMutation,
  useCreateJobEmploymentReviewMutation,
  useCreateJobCoworkerReviewMutation,
  useCreateJobWorkReviewMutation,
} = reviewsApi;
