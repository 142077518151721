import { useEffect, useState } from "react";

import {
  Box,
  Chip,
  ComboBox,
  IconButton,
  SelectStyled,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import {
  transformLanguageListToCommonFormat,
  useGetLanguageListQuery,
} from "@js/apps/common/api";
import { Snackbar } from "@js/components/snackbar";
import type { TypedWrappedFieldProps } from "@js/forms/utils";
import type { CommonLanguage } from "@js/types/common";
import { enumToOptions, sortLanguagesFirst } from "@js/utils";

type Language = {
  language_id: number;
  skill_level: string;
};

type LanguageFieldComponentProps = TypedWrappedFieldProps<Language[]> & {
  id: string;
};

export const LanguageField = ({
  id,
  input,
  meta,
}: LanguageFieldComponentProps) => {
  const {
    isLoading,
    isError,
    data: languageList = [],
  } = useGetLanguageListQuery();

  const [inputValue, setInputValue] = useState<string>("");

  const commonFormatLanguageList =
    transformLanguageListToCommonFormat(languageList);

  useEffect(() => {
    if (!isError) return;

    Snackbar.error("Failed to fetch languages.");
  }, [isError]);

  const addLanguage = (langId: number) => {
    const { value, onChange } = input;

    const langExists = !!value.filter((lang) => lang.language_id === langId)
      .length;

    if (!langExists) {
      const lang = commonFormatLanguageList.find(
        ({ value: langValue }) => langValue === langId,
      );

      if (lang) {
        onChange([
          ...value,
          {
            language_id: lang.value,
            skill_level: "",
          },
        ]);
      }
      setInputValue("");
    }
  };

  const removeLanguage = (langId: number) => {
    const { value, onChange } = input;

    onChange(value.filter((lang) => lang.language_id !== langId));
  };

  const changeSkillLevel = ({ language_id, skill_level }: Language) => {
    const { value: languages, onChange } = input;

    const languagesCopy = structuredClone(languages);

    const userLanguages = languagesCopy.map((lang) => {
      if (lang.language_id === language_id) {
        lang.skill_level = skill_level;
      }
      return lang;
    });
    onChange(userLanguages);
  };

  const sortedLanguageList = commonFormatLanguageList.sort(
    sortLanguagesFirst(["en"]),
  );

  const userLanguages = [...input.value].map((userLang) => {
    const [lang] = sortedLanguageList.filter(
      (language) => language.value === userLang.language_id,
    );
    return { ...lang, ...userLang };
  });

  const error = meta?.error?._error || meta.error || "";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <ComboBox<CommonLanguage, false>
          initialTaxonomiesLoading={false}
          id={id}
          value={null}
          inputValue={inputValue}
          onInputChange={(_ev, languageValue, reason) => {
            if (reason === "input") {
              setInputValue(languageValue);
            } else {
              setInputValue("");
            }
          }}
          onChange={(_ev, newValue) => {
            if (!newValue) return;
            addLanguage(newValue.value);
          }}
          getOptionLabel={(option) => option?.label || ""}
          options={sortedLanguageList}
          renderInput={(params) => {
            return (
              <TextField
                placeholder="E.g. English"
                error={!!error}
                helperText={error}
                label="Language"
                {...params}
                variant="standard"
              />
            );
          }}
        />
      </div>
      {userLanguages.map((lang) => {
        return (
          <Box
            key={lang.value}
            mt={2}
            display="flex"
            gap={2}
            flexDirection={{
              xs: "column",
              md: "row",
            }}
          >
            <Box
              width={{
                xs: "100%",
                md: "50%",
              }}
              display="flex"
              alignItems="flex-end"
              minWidth={0}
            >
              <Chip
                fullWidth
                color="tertiary"
                label={lang.label}
                endIcon={
                  <IconButton
                    variant="transparent"
                    onClick={() => removeLanguage(lang.value)}
                    size="x-small"
                    aria-label="Remove language"
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
            </Box>
            <Box width="100%" display="flex" alignItems="flex-end" minWidth={0}>
              <SelectStyled
                id={`${lang.label}-id`}
                label="Proficiency level"
                value={lang.skill_level}
                onChange={(event) => {
                  const languageId = lang.value;
                  const skillLevel = event.target.value;

                  changeSkillLevel({
                    language_id: languageId,
                    skill_level: skillLevel,
                  });
                }}
                options={enumToOptions(ENUMS.LanguageSkillLevelLabels)}
              />
            </Box>
          </Box>
        );
      })}
    </div>
  );
};
