import type { FC, ReactNode } from "react";
import cs from "classnames";

import type { PrimitiveValue } from "@hexocean/braintrust-ui-components";
import type {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@hexocean/braintrust-ui-components/components/DataGrid";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { EmployerInvoice, InvoiceItem } from "@js/types/invoices";
import { getFontSizeString, getTextWidth } from "@js/utils";
import { dateToFromNowDaily } from "@js/utils/date";
import type { AutosizedColumnDef } from "@js/utils/table/table";
import { getAutosizedColumnWidth } from "@js/utils/table/table";

import { EMPLOYER_INVOICES_SORTABLE_FIELDS } from "../constants";
import type {
  EmployerInvoiceItemRow,
  EmployerInvoiceRow,
  EmployerInvoicesTableColumnField,
  EmployerInvoicesTableColumnOption,
} from "../types";

import {
  ActionCell,
  AmountCell,
  CheckboxCell,
  CheckboxHeaderCell,
  DescriptionCell,
  EmailCell,
  FreelancerNameCell,
  JobTitleCell,
  PayeeCell,
  StatusCell,
  ToggleExpandCell,
} from "./cells";
import {
  formatCurrencyOrZero,
  getEmployerInvoiceItemRow,
  isEmployerInvoiceCellParams,
  isEmployerInvoiceItemRow,
  isEmployerInvoiceRow,
  isInvoiceItemCellParams,
} from "./helpers";

import styles from "./columns.module.scss";

export type EmployerInvoicesTableColumnDef = GridColDef<EmployerInvoiceRow> & {
  default?: boolean;
  field: EmployerInvoicesTableColumnField;
  headerName: string;
  alwaysVisible?: boolean;
  isHidden?: boolean;
  autosize?: boolean;
} & AutosizedColumnDef<EmployerInvoiceRow>;

type EmployerInvoicesTableCheckboxColDef = GridColDef & { isHidden?: boolean };

const getEmployerInvoiceRowItemToDisplay = (
  row: EmployerInvoice,
): InvoiceItem | undefined => {
  const items = row.items;
  const itemsCount = row.items.length;
  if (itemsCount !== 1) {
    return;
  }
  const itemDataToDisplay = items[0];
  if (!itemDataToDisplay) {
    return;
  }

  return itemDataToDisplay;
};

const renderEmployerInvoiceCell =
  (EmployerInvoiceCell: FC<GridRenderCellParams<EmployerInvoice>>) =>
  (params: GridRenderCellParams<EmployerInvoiceRow>) => {
    if (!isEmployerInvoiceCellParams(params)) {
      return <></>;
    }

    return <EmployerInvoiceCell {...params} />;
  };

const renderToggleExpandOrInvoiceItemCell =
  (renderEmployerInvoiceItemCell: (row: EmployerInvoiceItemRow) => ReactNode) =>
  (params: GridRenderCellParams<EmployerInvoiceRow>) => {
    if (isInvoiceItemCellParams(params)) {
      return renderEmployerInvoiceItemCell(params.row);
    }

    if (!isEmployerInvoiceCellParams(params) || !params.row.items.length) {
      return <></>;
    }

    const itemToDisplay = getEmployerInvoiceRowItemToDisplay(params.row);
    if (itemToDisplay) {
      return renderEmployerInvoiceItemCell(
        getEmployerInvoiceItemRow({ invoice: params.row, item: itemToDisplay }),
      );
    }

    return <ToggleExpandCell {...params} />;
  };

const valueFormatterEmployerInvoiceCell =
  <T,>(
    valueFormatter: (
      value: T,
      row: EmployerInvoice,
    ) => string | number | undefined,
  ) =>
  (value: T, row: EmployerInvoiceRow) => {
    if (!isEmployerInvoiceRow(row)) {
      return;
    }

    return valueFormatter(value, row);
  };

const dueDateValueFormatter = (
  value: EmployerInvoice["due_date"],
  row: EmployerInvoice,
) => {
  switch (row.status) {
    case ENUMS.InvoiceStatus.CANCELLED:
      return "Cancelled";
    case ENUMS.InvoiceStatus.PAID:
      return "Invoice paid";
    default:
      return dateToFromNowDaily(value);
  }
};

const getEmployerInvoiceRowAutosizedContentCallback =
  (field: keyof InvoiceItem) => (row: EmployerInvoiceRow) => {
    if (isEmployerInvoiceItemRow(row)) {
      return String(row[field]);
    }

    const itemToDisplay = getEmployerInvoiceRowItemToDisplay(row);
    if (!itemToDisplay) {
      return;
    }

    return String(itemToDisplay[field]);
  };

const CellWithTitle = ({ value }: { value: PrimitiveValue }) => {
  if (value === null || value === undefined) {
    return <></>;
  }

  return <span title={String(value)}>{value}</span>;
};

export const EMPLOYER_INVOICES_TABLE_COLUMNS: EmployerInvoicesTableColumnDef[] =
  [
    {
      field: "issued_at",
      headerName: "Invoice date",
      default: true,
      minWidth: 140,
    },
    {
      field: "status",
      headerName: "Status",
      default: true,
      renderCell: renderEmployerInvoiceCell(StatusCell),
      minWidth: 100,
      autosize: true,
      getAutosizedCellContentWidth: (row: EmployerInvoiceRow) => {
        if (!isEmployerInvoiceRow(row)) {
          return 0;
        }

        const statusCellChipTotalSidePaddingPx = 24;
        const statusCellFontSize = getFontSizeString({
          fontSize: "13px",
          fontWeight: "500",
        });
        const statusCellTextWidth = getTextWidth(
          ENUMS.InvoiceStatusLabels[row.status],
          statusCellFontSize,
        );

        return statusCellTextWidth + statusCellChipTotalSidePaddingPx;
      },
    },
    {
      field: "payee_name",
      headerName: "Payee",
      default: true,
      renderCell: renderEmployerInvoiceCell(PayeeCell),
      minWidth: 100,
      autosize: true,
      autosizeExtraWidth: 40,
    },
    {
      field: "freelancer_name",
      headerName: "Talent name",
      default: true,
      renderCell: renderEmployerInvoiceCell(FreelancerNameCell),
      minWidth: 150,
      autosize: true,
      getAutosizedCellContent: (row: EmployerInvoiceRow) => {
        if (!isEmployerInvoiceRow(row)) {
          return;
        }

        return row.freelancer?.user.public_name;
      },
    },
    {
      field: "freelancer__user__email",
      headerName: "Talent email address",
      renderCell: renderEmployerInvoiceCell(EmailCell),
      minWidth: 210,
      autosize: true,
      getAutosizedCellContent: (row: EmployerInvoiceRow) => {
        if (!isEmployerInvoiceRow(row)) {
          return;
        }

        return row.freelancer?.user.email;
      },
    },
    {
      field: "subject_title", // job title
      headerName: "Project",
      default: true,
      renderCell: renderEmployerInvoiceCell(JobTitleCell),
      minWidth: 110,
      autosize: true,
    },
    {
      field: "job_owner",
      headerName: "Job owner",
      minWidth: 140,
      autosize: true,
    },
    {
      field: "number",
      headerName: "Invoice #",
      default: true,
      minWidth: 120,
      autosize: true,
    },
    {
      field: "po_number",
      headerName: "PO #",
      minWidth: 120,
      autosize: true,
      renderCell: renderToggleExpandOrInvoiceItemCell((row) => (
        <CellWithTitle value={row.po_number} />
      )),
      getAutosizedCellContent:
        getEmployerInvoiceRowAutosizedContentCallback("po_number"),
    },
    {
      field: "date_from",
      headerName: "Start date",
      minWidth: 120,
      renderCell: renderToggleExpandOrInvoiceItemCell((row) => (
        <CellWithTitle value={row.date_from} />
      )),
    },
    {
      field: "date_to",
      headerName: "End date",
      minWidth: 120,
      renderCell: renderToggleExpandOrInvoiceItemCell((row) => (
        <CellWithTitle value={row.date_to} />
      )),
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: renderToggleExpandOrInvoiceItemCell((row) => (
        <DescriptionCell row={row} />
      )),
      minWidth: 120,
      maxWidth: 400,
      autosize: true,
      getAutosizedCellContent:
        getEmployerInvoiceRowAutosizedContentCallback("description"),
    },
    {
      field: "unit_price",
      headerName: "Unit price",
      renderCell: renderToggleExpandOrInvoiceItemCell((row) => (
        <CellWithTitle value={formatCurrencyOrZero(row.unit_price)} />
      )),
      headerClassName: styles.columnHeaderNumeric,
      align: "right",
      minWidth: 120,
      autosize: true,
      autosizeExtraWidth: 8, // for the $ sign
      getAutosizedCellContent:
        getEmployerInvoiceRowAutosizedContentCallback("unit_price"),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      renderCell: renderToggleExpandOrInvoiceItemCell((row) => (
        <CellWithTitle value={Number(row.quantity)} />
      )),
      headerClassName: styles.columnHeaderNumeric,
      align: "right",
      width: 120,
    },
    {
      field: "item_amount",
      headerName: "Line item amount",
      renderCell: renderToggleExpandOrInvoiceItemCell((row) => (
        <CellWithTitle value={formatCurrencyOrZero(row.gross_amount)} />
      )),
      headerClassName: styles.columnHeaderNumeric,
      align: "right",
      minWidth: 170,
    },
    {
      field: "gross_total",
      headerName: "Amount",
      default: true,
      renderCell: renderEmployerInvoiceCell(AmountCell),
      headerClassName: styles.columnHeaderNumeric,
      align: "right",
      minWidth: 120,
      autosize: true,
    },
    {
      field: "due_date",
      headerName: "Due date",
      default: true,
      valueFormatter: valueFormatterEmployerInvoiceCell<
        EmployerInvoice["due_date"]
      >(dueDateValueFormatter),
      minWidth: 125,
      autosize: true,
    },
    {
      field: "__filler_column",
      headerName: "",
      sortable: false,
      minWidth: 0,
      width: 0,
      flex: 1,
      valueFormatter: () => "",
      alwaysVisible: true,
      cellClassName: styles.fillerColumn,
      headerClassName: styles.fillerColumn,
    },
    {
      field: "action",
      headerName: "Action",
      cellClassName: styles.actionColumn,
      headerClassName: cs(styles.actionColumn, styles.actionColumnHeader),
      sortable: false,
      minWidth: 150,
      renderCell: ActionCell,
      alwaysVisible: true,
    },
  ];

const CHECKBOX_COLUMN: EmployerInvoicesTableCheckboxColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  renderHeader: (arg: GridColumnHeaderParams<EmployerInvoiceRow>) => {
    return (
      <CheckboxHeaderCell>
        {GRID_CHECKBOX_SELECTION_COL_DEF.renderHeader?.(arg)}
      </CheckboxHeaderCell>
    );
  },
  renderCell: (arg: GridRenderCellParams<EmployerInvoiceRow>) => {
    return (
      <CheckboxCell {...arg}>
        {GRID_CHECKBOX_SELECTION_COL_DEF.renderCell?.(arg)}
      </CheckboxCell>
    );
  },
};

const EMPLOYER_INVOICES_TABLE_COLUMNS_TO_TOGGLE =
  EMPLOYER_INVOICES_TABLE_COLUMNS.filter((column) => !column.alwaysVisible);

export const EMPLOYER_INVOICES_COLUMNS_OPTIONS: EmployerInvoicesTableColumnOption[] =
  EMPLOYER_INVOICES_TABLE_COLUMNS_TO_TOGGLE.map((column) => {
    return { label: column.headerName, value: column.field };
  });

export const EMPLOYER_INVOICES_ALL_COLUMNS_FIELDS =
  EMPLOYER_INVOICES_TABLE_COLUMNS_TO_TOGGLE.map((column) => column.field);

export const EMPLOYER_INVOICES_DEFAULT_COLUMNS_FIELDS =
  EMPLOYER_INVOICES_TABLE_COLUMNS_TO_TOGGLE.filter(
    (column) => column.default,
  ).map((column) => column.field);

export const getEmployerInvoicesTableColumns = ({
  visibleColumns,
  data,
}: {
  visibleColumns: EmployerInvoicesTableColumnField[];
  data: EmployerInvoiceRow[];
}): Array<
  EmployerInvoicesTableColumnDef | EmployerInvoicesTableCheckboxColDef
> => {
  const processedColumns: EmployerInvoicesTableColumnDef[] =
    EMPLOYER_INVOICES_TABLE_COLUMNS.map((column) => {
      const isHidden =
        !column.alwaysVisible && !visibleColumns.includes(column.field);

      const columnDef = {
        ...column,
        resizable: false,
        sortable: (
          EMPLOYER_INVOICES_SORTABLE_FIELDS as EmployerInvoicesTableColumnField[]
        ).includes(column.field),
        isHidden,
      };

      const isAutosized = !isHidden && column.autosize;
      if (isAutosized) {
        const columnMinWidth = getAutosizedColumnWidth({
          data,
          column,
        });

        columnDef.minWidth = columnMinWidth;
      }

      return columnDef;
    });

  return [CHECKBOX_COLUMN, ...processedColumns];
};
