import { useEffect, useRef, useState } from "react";

type UseInitiallyOpenDropdownProps = {
  hasFilterPopoverFinishedAnimation: boolean;
  isFilterEmpty: boolean;
};

export const useInitiallyOpenFilterPopoverDropdown = ({
  hasFilterPopoverFinishedAnimation,
  isFilterEmpty,
}: UseInitiallyOpenDropdownProps) => {
  const [isFilterEmptyInitial] = useState(isFilterEmpty);
  const autocompleteTextFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!hasFilterPopoverFinishedAnimation || !isFilterEmptyInitial) return;

    if (autocompleteTextFieldRef.current) {
      autocompleteTextFieldRef.current.focus();
    }
  }, [
    hasFilterPopoverFinishedAnimation,
    autocompleteTextFieldRef,
    isFilterEmptyInitial,
  ]);

  return {
    autocompleteTextFieldRef,
  };
};
