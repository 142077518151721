import type { AppDispatch } from "@js/store";
import type { User } from "@js/types/auth";
import type { InvitedTeamMember } from "@js/types/employer";

import { getActions } from "./get-actions";
import type { FetchDataType } from "./open-confirmation-modal";

export type ActionType = {
  label: string;
  key: string;
  onClick: () => void;
};

type GetTeamMembersActions = {
  member: User;
  user: User | null;
  fetchData: FetchDataType;
  canCurrentUserViewInvoices: boolean;
  dispatch: AppDispatch;
};

export const getTeamMembersActions = ({
  member,
  user,
  fetchData,
  canCurrentUserViewInvoices,
  dispatch,
}: GetTeamMembersActions): ActionType[] => {
  const isLoggedUser = user?.id === member.id;
  const canPreventViewing =
    member.can_view_employer_invoices && canCurrentUserViewInvoices;

  const canAllowViewing =
    !member.can_view_employer_invoices &&
    canCurrentUserViewInvoices &&
    !isLoggedUser;

  const { preventInvoiceView, allowInvoiceView, removeTeamMember } = getActions(
    {
      fetchData,
      memberId: member.id,
      isLoggedUser,
      dispatch,
    },
  );

  const actions = [
    canPreventViewing && {
      label: "Prevent from viewing Invoices",
      key: "preventInvoiceView",
      onClick: preventInvoiceView,
    },
    canAllowViewing && {
      label: "Allow to view Invoices",
      key: "allowInvoiceView",
      onClick: allowInvoiceView,
    },
    {
      label: "Delete",
      key: "delete",
      onClick: removeTeamMember,
    },
  ].filter(Boolean) as [];

  return actions;
};

type GetInvitedMembersActions = {
  member: InvitedTeamMember;
  fetchData: FetchDataType;
};

export const getInvitedMembersActions = ({
  member,
  fetchData,
}: GetInvitedMembersActions): ActionType[] => {
  const { resend, deleteTeamMemberInvitation } = getActions({
    fetchData,
    memberId: member.id,
  });

  const actions = [
    { label: "Resend", key: "resend", onClick: resend },
    {
      label: "Delete",
      key: "delete",
      onClick: deleteTeamMemberInvitation,
    },
  ];

  return actions;
};
