import { useMemo } from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { SharePostMenu } from "@js/apps/give-and-get-help/components/share-post-menu";
import { PostContext, PostLocation } from "@js/apps/give-and-get-help/context";
import {
  createPostOrCommentURL,
  includesSelectedHashtag,
} from "@js/apps/give-and-get-help/utils";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import type { IPost } from "@js/types/give-and-get-help";

import { useSelectedHashtag } from "../../hooks/use-selected-hashtag";
import type { PostLocationType } from "../../types";

type PostCreatedNotificationProps = {
  post: IPost;
  postLocation?: PostLocationType;
};

const PostCreatedNotification = ({
  post,
  postLocation,
}: PostCreatedNotificationProps) => {
  const postContextProviderValue = useMemo(() => {
    return { postData: post, isPublic: false };
  }, [post]);

  return (
    <PostLocation.Provider value={PostLocation.Values.notification}>
      <PostContext.Provider value={postContextProviderValue}>
        People in the Braintrust community can now see it, react to it, and
        leave a reply.{" "}
        <PostNotificationCTA post={post} postLocation={postLocation} /> to your
        other socials for more feedback.
      </PostContext.Provider>
    </PostLocation.Provider>
  );
};

const PostNotificationCTA = ({
  post,
  postLocation,
}: PostCreatedNotificationProps) => {
  const { selectedHashtagId } = useSelectedHashtag();
  const isPostIncludesSelectedHashtag = useMemo(
    () => includesSelectedHashtag(selectedHashtagId, post.post_hashtags),
    [post.post_hashtags, selectedHashtagId],
  );

  const isMyPostsPage = postLocation === PostLocation.Values.my_posts;
  const isHashtagPage = postLocation === PostLocation.Values.hashtag;
  const isFeedPage = postLocation === PostLocation.Values.feed;

  const shouldDisplayOnlyShareMenu =
    isMyPostsPage ||
    isFeedPage ||
    (isHashtagPage && isPostIncludesSelectedHashtag);

  if (shouldDisplayOnlyShareMenu)
    return (
      <>
        You can also <SharePostNotificationMenu post={post} />
      </>
    );

  return (
    <>
      You can manage your post from{" "}
      <Typography component="a" RouterLink={RouterLink} to="/feed/my_posts">
        My Posts
      </Typography>{" "}
      and also <SharePostNotificationMenu post={post} />
    </>
  );
};

const SharePostNotificationMenu = ({
  post,
}: Pick<PostCreatedNotificationProps, "post">) => {
  const postUrl = createPostOrCommentURL({ postId: post.id });

  return (
    <SharePostMenu
      post={post}
      postUrl={postUrl}
      anchor={
        <Typography
          variant="paragraph"
          component="span"
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          share it
        </Typography>
      }
      style={{ zIndex: 2000 }}
    />
  );
};

type SpacePostCreatedNotificationProps = {
  post: IPost;
  spaceName?: string;
};

const SpacePostCreatedNotification = ({
  post,
  spaceName,
}: SpacePostCreatedNotificationProps) => {
  const postUrl = createPostOrCommentURL({
    postId: post.id,
    spaceId: post.space,
  });
  return (
    <>
      Congrats!{" "}
      <Typography component="a" RouterLink={RouterLink} to={postUrl}>
        Your post
      </Typography>{" "}
      is now visible to all members of the {spaceName} space. You’ll get a
      notification when people react or leave a reply.
    </>
  );
};

type ShowSuccessToastProps = SpacePostCreatedNotificationProps & {
  composerLocation?: PostLocationType;
  header?: string;
  bgcolor?: string;
};

export const showSuccessToast = ({
  post,
  spaceName,
  composerLocation,
  header,
  bgcolor,
}: ShowSuccessToastProps) => {
  if (post?.space && spaceName) {
    return Snackbar.toast(
      {
        header: header || "Your post is live 🙌",
        buttonText: "Got it",
        content: (
          <SpacePostCreatedNotification post={post} spaceName={spaceName} />
        ),
        bgcolor,
      },
      { preventDuplicate: false },
    );
  }

  return Snackbar.toast(
    {
      header: header || "Your post is live! Congrats 🙌",
      buttonText: "Got it",
      content: (
        <PostCreatedNotification post={post} postLocation={composerLocation} />
      ),
      bgcolor,
    },
    { preventDuplicate: false },
  );
};
