import { SubmissionError } from "redux-form";
import amplitude from "amplitude-js";
import axios from "axios";
import { isEmpty } from "underscore";
import URI from "urijs";

import {
  EMAIL_VERIFIED,
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_FAILED,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_PUBLIC_INVITATION_DETAILS,
  FETCH_PUBLIC_INVITATION_DETAILS_FAILED,
  FETCH_PUBLIC_INVITATION_DETAILS_SUCCESS,
  FETCH_TIMEZONES_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  REGISTERED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER,
} from "@js/apps/auth/action-types";
import type { ResetPasswordProps } from "@js/apps/auth/views/reset-password";
import { Snackbar } from "@js/components/snackbar";
import { initializeAmplitude } from "@js/services/analytics/hooks/use-initialize-amplitude-analytics/initialize-amplitude";
import type { AppThunkAction } from "@js/store";
import type { AccountType, User } from "@js/types/auth";
import type { WithRecaptchaCode } from "@js/types/common";
import { isErrorWithDetails, isErrorWithMessage } from "@js/types/errors";
import type { Freelancer } from "@js/types/freelancer";
import { typeGuard } from "@js/utils";
import { formatErrorForSnackbar, stripSlashes } from "@js/utils/string";

import { loadSiteNotifications } from "../notifications/actions";

import type { LoginFormData } from "./forms";
import {
  getIsFreelancerTimezoneUpdated,
  getUserBrowserTimezone,
  sentrySetUser,
  setFreelancerTimezoneUpdated,
} from "./utils";

type FetchTimezonesResponse = {
  name: string;
  utc_offset: string;
};

export const fetchTimezones =
  (): AppThunkAction<Promise<FetchTimezonesResponse[]>> => (dispatch) => {
    return new Promise((resolve) => {
      return axios
        .get(`/api/timezones/`)
        .then((response) => {
          const payload: FetchTimezonesResponse[] = response.data;

          dispatch({
            type: FETCH_TIMEZONES_SUCCESS,
            payload,
          });

          resolve(payload);
        })
        .catch(() => {
          Snackbar.error("Failed to fetch timezones.");
        });
    });
  };

export const fetchCurrentUser =
  (): AppThunkAction<Promise<User>> => (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_CURRENT_USER,
      });

      return axios
        .get(`/api/user/user/`)
        .then((response) => {
          const payload: User = response.data;

          dispatch({
            type: FETCH_CURRENT_USER_SUCCESS,
            payload,
          });

          resolve(payload);
        })
        .catch((error) => {
          dispatch({
            type: FETCH_CURRENT_USER_FAILED,
            payload: "Not authenticated",
          });

          reject(error);
        });
    });
  };

export const updateUser =
  (
    values: Record<string, any>,
    snackbarError = false,
  ): AppThunkAction<Promise<User>> =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      const newValues = JSON.parse(JSON.stringify(values));
      delete newValues.avatar;

      if (newValues.user && newValues.user.avatar_id) {
        newValues.avatar_id = newValues.user.avatar_id;
      }

      axios
        .patch(`/api/user/user/`, newValues)
        .then((response) => {
          const payload: User = response.data;

          dispatch({
            type: UPDATE_USER,
            payload,
          });

          resolve(payload);
        })
        .catch((error) => {
          if (snackbarError) {
            Snackbar.error(error.response.data);
          } else {
            reject(new SubmissionError(error.response.data));
          }
        });
    });
  };

export const postUserLogin =
  (user: User): AppThunkAction<Promise<User>> =>
  (dispatch) =>
    new Promise((resolve) => {
      sentrySetUser(user);

      dispatch(loadSiteNotifications());

      resolve(user);
    });

const getShouldUpdateTimezone = (freelancer: Freelancer) => {
  const shouldUpdateTimezone =
    isEmpty(freelancer.location_full) && !getIsFreelancerTimezoneUpdated();

  return shouldUpdateTimezone;
};

export const postFreelancerLogin =
  (freelancer: Freelancer): AppThunkAction<Promise<void>> =>
  async (dispatch) => {
    const shouldUpdateTimezone = getShouldUpdateTimezone(freelancer);
    if (!shouldUpdateTimezone) {
      return;
    }

    dispatch(updateUserTimezone());
  };

const updateUserTimezone = (): AppThunkAction<Promise<void>> => (dispatch) => {
  const newUserTimezone = getUserBrowserTimezone();

  return dispatch(updateUser({ timezone: newUserTimezone }, true)).then(() => {
    setFreelancerTimezoneUpdated();
  });
};

export type LoginProps = WithRecaptchaCode<LoginFormData>;

type LoginResponse = {
  key: string;
};

export const login = async (values: LoginProps): Promise<LoginResponse> => {
  if (!values.code) {
    delete values.is_backup_code;
  }

  try {
    const response = await axios.post<LoginResponse, LoginResponse>(
      `/api/user/login/`,
      values,
    );

    return response;
  } catch (error) {
    if (
      typeGuard<unknown, { response: unknown }>(error, "response") &&
      typeGuard<unknown, { data: unknown }>(error.response, "data") &&
      !!error.response.data &&
      typeof error.response.data === "object"
    ) {
      throw new SubmissionError(error.response.data);
    }

    throw new SubmissionError({
      _error: "Something went wrong! Please try again.",
    });
  }
};

type LogoutResponse = {
  details: string;
};

export const logout = (): Promise<string> => {
  return new Promise((resolve, reject) =>
    axios
      .post("/api/user/logout/")
      .then((response) => {
        const payload: LogoutResponse = response.data;

        resolve(payload.details);
      })
      .catch((error) => {
        Snackbar.error(error.response.data);
        reject(error.response.data);
      }),
  );
};

type ForgotPasswordProps = WithRecaptchaCode<{
  email: string;
}>;

type ForgotPasswordResponse = {
  detail: string;
};

export const forgotPassword =
  (
    values: ForgotPasswordProps,
  ): AppThunkAction<Promise<ForgotPasswordResponse>> =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: FORGOT_PASSWORD,
      });

      return axios
        .post(`/api/user/password/reset/`, values)
        .then((response) => {
          const payload: ForgotPasswordResponse = response.data;

          dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: payload.detail,
          });

          resolve(payload);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });
  };

type ResetPasswordResponse = {
  detail: string;
};

export const resetPassword =
  (props: ResetPasswordProps): AppThunkAction<Promise<ResetPasswordResponse>> =>
  (dispatch) => {
    const { values, uid, token } = props;

    return new Promise((resolve, reject) => {
      dispatch({
        type: RESET_PASSWORD,
      });

      return axios
        .post(`/api/user/password/reset/confirm/`, { ...values, uid, token })
        .then((response) => {
          const payload: ResetPasswordResponse = response.data;

          dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: payload.detail,
          });

          resolve(payload);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });
  };

export const confirmEmailChange = (key: string): Promise<object> => {
  return new Promise((resolve, reject) => {
    return axios
      .post("/api/user/account-confirm-email/", { key })
      .then((response) => {
        const payload: object = response.data;
        return resolve(payload);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export type RegisterValues = {
  email: string;
  first_name: string;
  last_name?: string;
  phone_number?: { phone_number: string | null; country_iso2: string };
  password: string;
  joining_reasons: EnumType<typeof ENUMS.JoiningReason>[];
  interests: EnumType<typeof ENUMS.Interest>[];
  accept_terms_of_use: boolean;
};

type RegisterProps = {
  values: WithRecaptchaCode<RegisterValues>;
  referrer?: string;
  queryParams: object;
  job_id?: string;
  post_id?: string;
  space_id?: string;
};

type RegisterResponse = {
  email: string;
  finish_signup_url: string | null;
  id: User["id"];
};

export const register =
  (props: RegisterProps): AppThunkAction<Promise<RegisterResponse>> =>
  (dispatch) => {
    const { values, referrer, queryParams, job_id, post_id, space_id } = props;

    return new Promise((resolve, reject) => {
      const searchQueryString = URI()
        .search({
          referrer,
          job_id,
          post_id,
          space_id,
          ...queryParams,
          amplitude_device_id: amplitude.getInstance().getDeviceId(),
        })
        .search();

      return axios
        .post(`/api/user/registration/${searchQueryString}`, {
          ...values,
        })
        .then((response) => {
          const responseData: RegisterResponse = response.data;
          initializeAmplitude(responseData.id, { token: true });

          dispatch(registered("email"));
          resolve(responseData);
        })
        .catch((error) => reject(new SubmissionError(error.response?.data)));
    });
  };

type setJoiningInfoProps = {
  values: {
    joining_reasons: string[];
    interests: string[];
  };
};

export const setJoiningInfo =
  ({
    values,
  }: setJoiningInfoProps): AppThunkAction<Promise<RegisterResponse>> =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/api/users/set_joining_info/`, {
          ...values,
        })
        .then((response) => {
          const payload: RegisterResponse = response.data;

          dispatch({
            type: UPDATE_USER,
            payload,
          });

          resolve(payload);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });
  };

const registered = (payload: string) => {
  return {
    type: REGISTERED,
    payload,
  };
};

export const resendVerificationEmail = async (data: {
  email: string;
}): Promise<RegisterResponse | void> => {
  try {
    const response = await axios.post(
      `/api/user/registration/resend_verification_email/`,
      data,
    );
    const payload: RegisterResponse = response.data;
    Snackbar.success("A verification email has been resent successfully");
    return payload;
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      isErrorWithMessage(error?.response?.data)
    ) {
      const errors = error?.response?.data._error;
      Snackbar.error(formatErrorForSnackbar(errors));
      return;
    }

    if (
      axios.isAxiosError(error) &&
      isErrorWithDetails(error?.response?.data)
    ) {
      Snackbar.error(error?.response?.data?.detail);
      return;
    }

    Snackbar.error("Failed to resend verification email");
  }
};

type RegisterFinishProps = {
  confirmation_key: string;
};

type RegisterFinishResponse = {
  key: string;
};

export const registerFinish =
  (
    values: RegisterFinishProps,
  ): AppThunkAction<Promise<RegisterFinishResponse>> =>
  (dispatch) => {
    return new Promise((resolve, reject) =>
      axios
        .post(`/api/user/registration/finish/`, values)
        .then(async (response) => {
          const payload: RegisterFinishResponse = response.data;
          const user = await dispatch(fetchCurrentUser());
          await dispatch(postUserLogin(user));

          resolve(payload);
        })
        .catch((error) => reject(error?.response?.data)),
    );
  };

type FetchPublicInvitationDetails = {
  invitation_key: string | null;
  job_id?: string;
  referrer: string | undefined;
};

export type FetchPublicInvitationDetailsResponse = {
  account_type: AccountType;

  avatar: string;
  avatar_thumbnail: string;
  created: string;
  first_name: string;
  freelancer_approved: boolean;
  gravatar: string;

  has_avatar_set: boolean;
  id: number;
  invitation_error: string;
  invitation_type: string;
  last_name: string;
  profile_url: string;
  public_name: string;
  title: string;
};

export const fetchPublicInvitationDetails =
  (
    params: FetchPublicInvitationDetails,
  ): AppThunkAction<Promise<FetchPublicInvitationDetailsResponse>> =>
  (dispatch) => {
    return new Promise((resolve) => {
      dispatch({ type: FETCH_PUBLIC_INVITATION_DETAILS });

      return axios
        .get(`/api/users/public_invitation_details/`, { params })
        .then((response) => {
          const payload: FetchPublicInvitationDetailsResponse = response.data;

          dispatch({
            type: FETCH_PUBLIC_INVITATION_DETAILS_SUCCESS,
            payload,
          });

          resolve(payload);
        })
        .catch(() =>
          dispatch({ type: FETCH_PUBLIC_INVITATION_DETAILS_FAILED }),
        );
    });
  };

export type FetchOTPAuthURIResponse = {
  otp_auth_uri: string;
  otp_secret: string;
};

export const fetchOTPAuthURI = (): Promise<FetchOTPAuthURIResponse> => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/api/otp/get_auth_uri/`)
      .then((response) => {
        const payload: FetchOTPAuthURIResponse = response.data;

        return resolve(payload);
      })
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

type EnableOTPAuthResponse = {
  backup_codes: string[];
};

export const enableOTPAuth = (
  values: RecreateOTPAuthWithPassword,
): Promise<EnableOTPAuthResponse> => {
  return new Promise((resolve, reject) =>
    axios
      .post(`/api/otp/enable/`, values)
      .then((response) => {
        const payload: EnableOTPAuthResponse = response.data;
        return resolve(payload);
      })
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

export const disableOTPAuth = (values: RecreateOTPAuth): Promise<string> => {
  return new Promise((resolve, reject) =>
    axios
      .post(`/api/otp/disable/`, values)
      .then((response) => {
        const payload: string = response.data;

        return resolve(payload);
      })
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

type RecreateOTPAuth = {
  code: string;
};

type RecreateOTPAuthWithPassword = RecreateOTPAuth & {
  current_password: string;
};

type RecreateOPTAuthResponse = {
  otp_auth_uri: string;
  otp_secret: string;
};

export const recreateOTPAuth = (
  values: RecreateOTPAuth,
): Promise<RecreateOPTAuthResponse> => {
  return new Promise((resolve, reject) =>
    axios
      .post(`/api/otp/recreate_request/`, values)
      .then((response) => {
        const payload: RecreateOPTAuthResponse = response.data;
        return resolve(payload);
      })
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

type RecreateOTPAuthBackupCodes = {
  backup_codes: string[];
};

export const recreateOTPAuthBackupCodes = (
  values: RecreateOTPAuth,
): Promise<RecreateOTPAuthBackupCodes> => {
  return new Promise((resolve, reject) =>
    axios
      .post(`/api/otp/recreate_backup_codes/`, values)
      .then((response) => {
        const payload: RecreateOTPAuthBackupCodes = response.data;
        return resolve(payload);
      })
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

type RecreateConfirmOTPAuth = {
  code: string;
  otp_secret: string;
};

export const recreateConfirmOTPAuth = (
  values: RecreateConfirmOTPAuth,
): Promise<string> => {
  return new Promise((resolve, reject) =>
    axios
      .post(`/api/otp/recreate_confirm/`, values)
      .then((response) => {
        const payload: string = response.data;
        return resolve(payload);
      })
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );
};

export const loginAs =
  (userId: number): AppThunkAction<Promise<void>> =>
  (_dispatch, getState) => {
    return new Promise((_, reject) =>
      axios
        .post(
          `/${stripSlashes(
            SETTINGS.ADMIN_URL || (getState().auth.user?.admin_url as string),
          )}/login/user/${userId}/`,
          { from_profile: true },
        )
        .then(() => {
          window.location.reload();
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );
  };

export const emailVerified = (amplitudeDeviceId: string) => {
  return {
    type: EMAIL_VERIFIED,
    payload: { amplitudeDeviceId },
  };
};
