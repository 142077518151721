import { sanitize } from "@js/services";

const fieldsToSanitize = ["requirements", "introduction"];
const sanitizationRegex = />[^<]*[^<>\s]+[^>]*</i;

export const sanitizeRichEditorContent = <
  T extends { requirements?: string; introduction?: string } | undefined,
>(
  formValues: T,
): T => {
  if (formValues) {
    fieldsToSanitize.forEach((editorField) => {
      if (!sanitizationRegex.test(formValues[editorField])) {
        formValues[editorField] = "";
      }
    });
  }

  return formValues;
};

export const getSanitizedQuestions = <TQuestion extends { question: string }>(
  questions: TQuestion[],
): TQuestion[] => {
  return questions.map((questionItem) => ({
    ...questionItem,
    question: sanitize(questionItem.question, { ALLOWED_TAGS: [] }),
  }));
};
