import React, { useMemo } from "react";

import {
  Box,
  Loader,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useEmployerProfilePage } from "@js/apps/employer/hooks";
import { WithJobActionsContext } from "@js/apps/jobs/components/job-actions";
import { JobItem } from "@js/apps/jobs/components/job-item";
import { JobsContainer } from "@js/apps/jobs/components/listing/jobs-container";
import { JobLocationContext } from "@js/apps/jobs/context";
import { PageHead } from "@js/components/page-head";
import { PageTitle } from "@js/components/page-title";
import { AppLayout } from "@js/layouts/app";
import { pluralize } from "@js/utils";

import { EmployerLogoWithName, EmployerOverview } from "../../components";

import { BasicInfo, EmployerReviews, JobsInfo, TopBar } from "./components";

export const EmployerProfilePage = () => {
  const { refetchEmployerPublicProfile, employerPublicProfile, openJobs } =
    useEmployerProfilePage();

  const user = useUser();

  const withJobActionsContextProviderValue = useMemo(
    () => ({
      afterAction: () => {
        refetchEmployerPublicProfile();
      },
    }),
    [refetchEmployerPublicProfile],
  );

  if (!employerPublicProfile) {
    return <Loader centered />;
  }

  const isProfileOwner = employerPublicProfile.id === user?.employer;

  const sortedOpenJobs = [...openJobs].reverse();

  return (
    <AppLayout bgcolor="var(--soft-blue)" className="justify-center">
      <PageHead>
        <meta property="og:title" content={employerPublicProfile.name} />
        <meta property="og:image" content={employerPublicProfile.logo} />
        <meta
          property="og:description"
          content={employerPublicProfile.overview}
        />
        <meta name="description" content={employerPublicProfile.overview} />
      </PageHead>
      <PageTitle>{`Employer: ${employerPublicProfile.name}`}</PageTitle>
      <Box component="main" width="100%" maxWidth={1100}>
        <Box mb={4}>
          <TopBar
            employer={employerPublicProfile}
            isProfileOwner={isProfileOwner}
          />
        </Box>
        <Box mb={3}>
          <EmployerLogoWithName
            imageSource={employerPublicProfile.logo_thumbnail}
            employer={employerPublicProfile}
            gap={24}
            size="large"
            nameComponent="h1"
            nameVariant="title"
            nameSize="medium"
            color="var(--info-2)"
            fontWeight={400}
            noUrl
          />
        </Box>
        <Box component="section" mb={4} className="employer-profile__info">
          <div className="employer-profile__col-1">
            <div className="employer-profile__overview">
              <RoundedBox height={1}>
                <EmployerOverview
                  name={employerPublicProfile.name}
                  overview={employerPublicProfile.overview}
                />
              </RoundedBox>
            </div>
          </div>
          <div className="employer-profile__col-2">
            <div className="employer-profile__meta">
              <BasicInfo
                website={employerPublicProfile.website}
                industry={employerPublicProfile.industry}
                location={employerPublicProfile.location}
              />
            </div>
            <div className="employer-profile__total-jobs">
              <JobsInfo
                label="Completed Jobs"
                value={employerPublicProfile.completed_jobs_number}
              />
            </div>
            <div className="employer-profile__hired-talent">
              <JobsInfo
                label="Hired Talent"
                value={employerPublicProfile.talent_hired_count}
              />
            </div>
          </div>
        </Box>
        <EmployerReviews employer={employerPublicProfile} />
        {openJobs && openJobs.length > 0 && (
          <Box mt={8} component="section">
            <Typography
              component="h2"
              variant="title"
              size="small"
              fontWeight={400}
            >
              {employerPublicProfile.open_jobs_count} Open Job
              {pluralize(employerPublicProfile.open_jobs_count)}
            </Typography>
            <Box mt={3}>
              <div className="employer-jobs">
                <JobLocationContext.Provider
                  value={JobLocationContext.Values.employer_profile}
                >
                  <WithJobActionsContext.Provider
                    value={withJobActionsContextProviderValue}
                  >
                    <JobsContainer sx={{ gap: 2 }}>
                      {sortedOpenJobs.map((job) => {
                        return <JobItem key={job.id} job={job} />;
                      })}
                    </JobsContainer>
                  </WithJobActionsContext.Provider>
                </JobLocationContext.Provider>
              </div>
            </Box>
          </Box>
        )}
      </Box>
    </AppLayout>
  );
};

EmployerProfilePage.HTTP_404_ID = "employerNotFound";
