import type { MouseEvent } from "react";
import { useEffect, useRef, useState } from "react";

import { Box, Stack, Typography } from "@hexocean/braintrust-ui-components";
import { useIsTouchDevice } from "@js/apps/common/hooks/use-is-touch-device";

import type { EmployerInvoiceItemRow } from "../../types";
import { EmployerInvoicesTableCellTooltip } from "../employer-invoices-table-cell-tooltip";

type DescriptionCellProps = { row: EmployerInvoiceItemRow };

export const DescriptionCell = ({ row }: DescriptionCellProps) => {
  const isTouchDevice = useIsTouchDevice();
  const ref = useRef<HTMLElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const totalTextWidth = Math.ceil(ref.current?.scrollWidth ?? 0);
    const visibleTextWidth = Math.ceil(ref.current?.offsetWidth ?? 0);
    setShowTooltip(totalTextWidth > visibleTextWidth);
  }, []);

  const handleCellClick = (ev: MouseEvent<HTMLElement>) => {
    if (!isTouchDevice || !showTooltip) {
      return;
    }

    ev.stopPropagation();
  };

  return (
    <EmployerInvoicesTableCellTooltip
      title={row.description}
      disabled={!showTooltip}
    >
      <Stack direction="row" sx={{ height: "100%", alignItems: "center" }}>
        <Typography component="div" variant="paragraph" size="medium" ellipsis>
          <Box
            ref={ref}
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            onClick={handleCellClick}
          >
            {row.description}
          </Box>
        </Typography>
      </Stack>
    </EmployerInvoicesTableCellTooltip>
  );
};
