import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ComboBoxMultiple,
  PaperWithGreenOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useGetEmployerTeamMembersQuery } from "@js/apps/employer/api";
import {
  FilterPopoverFooter,
  FilterPopoverFormLayoutWrapper,
} from "@js/apps/employer/components/filter-popover";
import { useInitiallyOpenFilterPopoverDropdown } from "@js/apps/employer/hooks/use-initially-open-filter-popover-dropdown";
import type { RhfForm } from "@js/rhf/types";

import { employerInvoicesJobOwnerFormSchema } from "../schema";

import { EmployerInvoicesFiltersAutocomplete } from "./employer-invoices-filters-autocomplete";

type FormType = RhfForm<typeof employerInvoicesJobOwnerFormSchema>;
type FormValues = FormType["Values"];
type DefaultFormValues = FormType["DefaultValues"];

type JobOwnerFormProps = {
  jobOwners: DefaultFormValues["job_owners"];
  onApply: (jobOwner: FormValues["job_owners"]) => void;
  hasFilterPopoverFinishedAnimation: boolean;
};

export const JobOwnerForm = ({
  jobOwners,
  onApply,
  hasFilterPopoverFinishedAnimation,
}: JobOwnerFormProps) => {
  const defaultValues = useMemo(() => {
    return { job_owners: jobOwners };
  }, [jobOwners]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerInvoicesJobOwnerFormSchema),
    defaultValues,
  });

  const { field } = useController({
    name: "job_owners",
    control: control,
  });

  const onSubmit: SubmitHandler<FormValues> = (value) => {
    onApply(value.job_owners);
  };

  const { data: jobOwnersData = [], isLoading } =
    useGetEmployerTeamMembersQuery();

  const onReset = () => {
    reset({ job_owners: [] });
  };

  const valueList = useMemo(() => {
    return jobOwnersData.filter((data) => {
      return field.value?.includes(data.id);
    });
  }, [field.value, jobOwnersData]);

  const isFilterEmpty = !field.value?.length;
  const { autocompleteTextFieldRef } = useInitiallyOpenFilterPopoverDropdown({
    isFilterEmpty,
    hasFilterPopoverFinishedAnimation,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <ComboBoxMultiple
            {...field}
            openOnFocus
            value={valueList}
            ref={null}
            onChange={(_event, values) => {
              field.onChange(
                values.map((value) => {
                  return value.id;
                }),
              );
            }}
            ChipProps={{
              deleteIcon: (
                <CloseIcon sx={{ color: "var(--black) !important" }} />
              ),
            }}
            component={EmployerInvoicesFiltersAutocomplete}
            PaperComponent={PaperWithGreenOptions}
            initialTaxonomiesLoading={isLoading}
            options={jobOwnersData}
            getOptionLabel={(option) => {
              return option.public_name;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.public_name}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autocompleteTextFieldRef}
                  placeholder={isFilterEmpty ? "Select a job owner" : undefined}
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Select job owners",
                  }}
                />
              );
            }}
          />
        }
        formFooter={<FilterPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
