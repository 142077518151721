import { Typography } from "@hexocean/braintrust-ui-components";
import { CreditCardIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { formatCurrency } from "@js/utils/currency";

import type { EmployerInvoicesCreditCardFee } from "../../types";

export const openCcPaymentFeeModal = (
  props: Pick<CcPaymentFeeModalProps, "onConfirm" | "creditCardPaymentFee">,
) => {
  ModalInstance.open({
    closeButton: false,
    keepOnBackdropClick: true,
    className: "text-confirmation-modal",
    children: (
      <CcPaymentFeeModalContent {...props} closeModal={ModalInstance.close} />
    ),
  });
};

type CcPaymentFeeModalProps = {
  onConfirm: () => void;
  creditCardPaymentFee: EmployerInvoicesCreditCardFee;
  closeModal: () => void;
};

export const CcPaymentFeeModalContent = ({
  onConfirm,
  creditCardPaymentFee,
  closeModal,
}: CcPaymentFeeModalProps) => {
  const valueToPay = formatCurrency(creditCardPaymentFee.total_with_fee);

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <ModalConfirm
      onCancel={closeModal}
      onConfirm={handleConfirm}
      confirmText="Pay"
    >
      <Typography
        className="mb+ header-with-icon"
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
      >
        <CreditCardIcon /> Payment Fee
      </Typography>
      {creditCardPaymentFee.fee_charged ? (
        <>
          <Typography component="p">
            You've chosen to pay using Credit Card. This type of payment is
            charged a 3.9% processing fee. The new total is{" "}
            <strong>{valueToPay}</strong>. Proceed?
          </Typography>
          <Typography component="p" size="small" color="grey">
            Payment fee will be added to the appropriate fee invoice(s).
          </Typography>
        </>
      ) : (
        <Typography component="p">
          You are about to pay <strong>{valueToPay}</strong>. Proceed?
        </Typography>
      )}
    </ModalConfirm>
  );
};
