import { API } from "@js/api";
import { getUseQueryHookWithDefaultOptions } from "@js/utils/store";

import type { FrontendStorageKey } from "./constants";

type GetStorageValueProps = {
  key: FrontendStorageKey;
};

type GetStorageResponse = {
  key: FrontendStorageKey;
  value: unknown;
};

type SetStorageProps = {
  key: FrontendStorageKey;
  value: unknown | null;
};

const frontendStorageApi = API.injectEndpoints({
  endpoints: (build) => ({
    getStorageValue: build.query<unknown, GetStorageValueProps>({
      query: ({ key }) => ({
        url: `/frontend_storage/?key=${encodeURIComponent(key)}`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [
        { type: "FrontendStorage", id: arg.key },
      ],
      transformResponse: (response: GetStorageResponse): unknown => {
        if (!response) {
          return null;
        }

        return normalizeValue(response.value);
      },
      keepUnusedDataFor: 1000000,
    }),

    setStorageValue: build.mutation<void, SetStorageProps>({
      query: ({ key, value }) => {
        return {
          url: `/frontend_storage/`,
          method: "POST",
          data: { key, value: serializeValue(value) },
        };
      },
      async onQueryStarted({ key, value }, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          frontendStorageApi.util.updateQueryData(
            "getStorageValue",
            { key },
            () => {
              return value;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patch.undo();

          dispatch(
            frontendStorageApi.util.invalidateTags([
              { type: "FrontendStorage", id: key },
            ]),
          );
        }
      },
    }),
  }),
});

export const { useLazyGetStorageValueQuery, useSetStorageValueMutation } =
  frontendStorageApi;

export const useGetStorageValueQuery = getUseQueryHookWithDefaultOptions(
  frontendStorageApi.useGetStorageValueQuery,
  { refetchOnMountOrArgChange: false },
);

export const useGetStorageValueQueryState =
  frontendStorageApi.endpoints.getStorageValue.useQueryState;

const serializeValue = (value: unknown) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return value;
  }
};

const normalizeValue = (value: unknown): unknown => {
  try {
    return typeof value === "string" ? JSON.parse(value) : value;
  } catch (e) {
    return value;
  }
};
