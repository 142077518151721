import type { ReactNode } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import { EMPLOYER_INVOICES_TABLE_ID } from "../constants";

type EmployerInvoicesTableContainerProps = { children: ReactNode };

export const EmployerInvoicesTableContainer = ({
  children,
}: EmployerInvoicesTableContainerProps) => {
  return (
    <Box
      id={EMPLOYER_INVOICES_TABLE_ID}
      sx={{
        position: "relative",
      }}
    >
      {children}
    </Box>
  );
};
