import React, { useMemo } from "react";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useStatsQuery } from "@js/apps/common/api";
import { useUser } from "@js/apps/common/hooks";
import { ATSIntegrationDashboardModule } from "@js/apps/employer/components/ats-integration-dashboard-module";
import { EliteTalentModule } from "@js/apps/employer/components/elite-talent-module";
import { LetPostJob } from "@js/apps/employer/components/let-post-job";
import { OpenJobsModule } from "@js/apps/employer/components/open-jobs-module";
import { PrompToPostJob } from "@js/apps/employer/components/prompt-to-post-job";
import { useDismissATSModule } from "@js/apps/employer/hooks";
import { useLetPostJobModule } from "@js/apps/employer/hooks/let-post-job-module";
import { useAtsIntegrationPopupModal } from "@js/apps/employer/views/dashboard/ats-integration-popup-modal/use-ats-integration-popup-modal";
import { round } from "@js/utils";

import { EmployerDashboardLayout } from "../layout";

import style from "./style.module.scss";

export const EmployerDashboardWelcomePage = () => {
  const user = useUser();
  const { showFirstJobPromptModule, isLoading } = useLetPostJobModule();
  const isMobile = useMediaQuery("sm");

  useAtsIntegrationPopupModal({ user });

  return (
    <EmployerDashboardLayout
      contentClassName={style.employerDashboardContainer}
      pageTitle="Welcome"
      bgcolor="var(--soft-violet)"
    >
      <Typography
        component="h1"
        variant="title"
        size={isMobile ? "medium" : "small"}
        textTransform="capitalize"
        fontWeight={400}
        data-testid="dashboard-user-name"
        mb={3}
      >
        Hi, {user?.first_name}!
      </Typography>

      <div className={style.employerDashboardWelcome}>
        {isLoading ? (
          <Loader />
        ) : (
          <DashboardContent
            showFirstJobPromptModule={showFirstJobPromptModule}
          />
        )}
      </div>
    </EmployerDashboardLayout>
  );
};

export const DashboardContent = ({
  showFirstJobPromptModule,
}: {
  showFirstJobPromptModule: boolean;
}) => {
  const { data: stats } = useStatsQuery();
  const userCountToDisplay = useMemo(() => {
    return round(stats?.users_count, 100).toLocaleString("en-US");
  }, [stats?.users_count]);

  const { dismissed, handleDismissModule } = useDismissATSModule();

  const showLeftColumn = !!stats || dismissed;

  if (showFirstJobPromptModule) {
    return (
      <>
        {stats && <LetPostJob talentCountsToDisplay={userCountToDisplay} />}
        {dismissed ? (
          <EliteTalentModule />
        ) : (
          <>
            <div className={style.employerDashboardWelcomeColumnLeft}>
              <ATSIntegrationDashboardModule
                handleDismissModule={handleDismissModule}
              />
            </div>
            <div className={style.employerDashboardWelcomeColumnRight}>
              <EliteTalentModule />
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {showLeftColumn && (
        <div className={style.employerDashboardWelcomeColumnLeft}>
          {stats && (
            <PrompToPostJob talentCountsToDisplay={userCountToDisplay} />
          )}
          {!dismissed && (
            <ATSIntegrationDashboardModule
              handleDismissModule={handleDismissModule}
            />
          )}
        </div>
      )}
      <div className={style.employerDashboardWelcomeColumnRight}>
        <OpenJobsModule />
        <EliteTalentModule />
      </div>
    </>
  );
};
