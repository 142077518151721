import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getPostPath } from "@js/apps/give-and-get-help/utils";
import type { IPost } from "@js/types/give-and-get-help";

export const useRedirectFromSpacesPostToFeedPost = (
  post: IPost | undefined,
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (post && !post.space) {
      navigate(getPostPath(post), { replace: true });
    }
  }, [navigate, post]);
};
