import { Loader } from "@hexocean/braintrust-ui-components";
import type { FreelancerOffer } from "@js/types/jobs";

import { OfferItem } from "./offer-item";

type OfferListProps = {
  offers: FreelancerOffer[];
  emptyStateContent: JSX.Element;
  isLoading: boolean;
};

export const OfferList = ({
  offers,
  emptyStateContent,
  isLoading,
}: OfferListProps) => {
  if (isLoading) {
    return <Loader />;
  }

  if (!offers?.length) {
    return emptyStateContent;
  }

  return (
    <div>
      {offers.map((offer) => (
        <OfferItem key={offer.id} offer={offer} />
      ))}
    </div>
  );
};
