import { StarSVGIcon } from "@hexocean/braintrust-ui-components/Icons";

import { FilterButtonPopover } from "./filter-button-popover";
import { EmployerMyTalentJobOwnerForm } from "./forms";
import { useFilterPopover, useIsPopoverRendered } from "./hooks";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentJobOwnerFilterPopoverProps = {
  jobOwners: EmployerMyTalentFormValues["job_owners"];
  onChange: (jobOwners: EmployerMyTalentFormValues["job_owners"]) => void;
};

export const MyTalentJobOwnerFilterPopover = ({
  jobOwners,
  onChange,
}: MyTalentJobOwnerFilterPopoverProps) => {
  const isJobOwnerApplied = Boolean(jobOwners?.length);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, isPopoverRendered } =
    useIsPopoverRendered();

  const handleApply = (
    newJobOwner: EmployerMyTalentFormValues["job_owners"],
  ) => {
    onChange(newJobOwner);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterButtonPopover
      label="Job owner"
      icon={<StarSVGIcon />}
      isFilterApplied={isJobOwnerApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
    >
      <EmployerMyTalentJobOwnerForm
        jobOwners={jobOwners}
        onApply={handleApply}
        shouldOpenDropdown={isPopoverRendered}
      />
    </FilterButtonPopover>
  );
};
