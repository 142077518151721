import { Avatar, Stack, Typography } from "@hexocean/braintrust-ui-components";
import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import type {
  EmployerInvoice,
  EmployerInvoiceFreelancer,
} from "@js/types/invoices";

type PayeeCellProps = GridRenderCellParams<
  EmployerInvoice,
  EmployerInvoice["payee_name"]
>;

export const PayeeCell = ({ value, row }: PayeeCellProps) => {
  return (
    <Stack
      direction="row"
      title={value}
      sx={{
        alignItems: "center",
        gap: 1,
        height: "100%",
      }}
    >
      <PayeeAvatar
        freelancer={row.freelancer}
        isFeeInvoice={row.is_fee_invoice}
      />
      <PayeeLabel value={value} />
    </Stack>
  );
};

const PayeeAvatar = ({
  freelancer,
  isFeeInvoice,
}: {
  freelancer: EmployerInvoiceFreelancer | null;
  isFeeInvoice: boolean;
}) => {
  if (isFeeInvoice || !freelancer) {
    return (
      <BraintrustIcon
        variant="centered"
        sx={{
          color: "var(--white)",
          width: "32px",
          height: "32px",
          padding: "5px",
          background: "var(--black)",
          borderRadius: "50%",
        }}
      />
    );
  }

  return <Avatar src={freelancer.user} size="sm" textSize="small" />;
};

const commonLabelProps = {
  ellipsis: true,
  size: "medium",
  variant: "paragraph",
  component: "span",
} as const;

const PayeeLabel = ({
  value,
}: {
  value: EmployerInvoice["payee_name"] | undefined;
}) => {
  return (
    <Typography
      {...commonLabelProps}
      ellipsis
      size="medium"
      variant="paragraph"
      component="span"
    >
      {value}
    </Typography>
  );
};
