import { Loader } from "@hexocean/braintrust-ui-components";

import { useGetFreelancerJobInvitationsQuery } from "../../api";
import { FreelancerJobInvites } from "../../components/my-jobs-invites";

import { FreelancerDashboardLayout } from "./layout";

export const DashboardMyInvitesPage = () => {
  const { data: jobInvitations, isFetching: fetchingJobInvitations } =
    useGetFreelancerJobInvitationsQuery();

  return (
    <FreelancerDashboardLayout
      pageTitle="Invites"
      contentClassName="my-jobs-dashboard-layout"
    >
      {fetchingJobInvitations ? (
        <Loader />
      ) : (
        <>
          <FreelancerJobInvites jobs={jobInvitations?.results} />
        </>
      )}
    </FreelancerDashboardLayout>
  );
};
