import { PenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";
import { useHasFilterPopoverAnimationFinished } from "@js/hooks/use-has-filter-popover-animation-finished";

import { MY_TALENT_FILTER_TO_APPLY_LABEL } from "./constants";
import { EmployerMyTalentRoleForm } from "./forms";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentRoleFilterProps = {
  roles: EmployerMyTalentFormValues["roles"];
  onChange: (roles: EmployerMyTalentFormValues["roles"]) => void;
};

export const MyTalentRoleFilterPopover = ({
  roles,
  onChange,
}: MyTalentRoleFilterProps) => {
  const isAnyJobApplied = Boolean(roles && roles.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, hasPopoverAnimationFinished } =
    useHasFilterPopoverAnimationFinished();

  const handleApply = (newRoles: EmployerMyTalentFormValues["roles"]) => {
    onChange(newRoles);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterPopover
      label={MY_TALENT_FILTER_TO_APPLY_LABEL.roles}
      icon={<PenIcon />}
      isFilterApplied={isAnyJobApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
    >
      <EmployerMyTalentRoleForm
        roles={roles}
        onApply={handleApply}
        shouldOpenDropdown={hasPopoverAnimationFinished}
      />
    </FilterPopover>
  );
};
