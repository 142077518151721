import { useState } from "react";
import dayjs from "dayjs";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { Snackbar } from "@js/components/snackbar";
import type { AiInterviewCriteria, EmployerBidListBid } from "@js/types/jobs";
import { dateToFromNowDaily, getTimezoneByOffset } from "@js/utils";
import type { IsoDateOrIsoDateTime } from "@js/utils/date/types";

import { useResendEmployerBidInterviewMutation } from "../../apps/bids/api";

type JobInterviewGradingSummaryProps = {
  titleIcon?: string;
  isCollapsed?: boolean;
} & AiInterviewCriteria;

export const getGradeDetails = (
  grade?: number,
): { color: string; gradeString: string } => {
  const color =
    grade === undefined || Number.isNaN(grade)
      ? "var(--soft-beige)"
      : grade <= 1.3
        ? "var(--medium-red)"
        : grade <= 3.3
          ? "var(--medium-orange)"
          : grade <= 4.3
            ? "var(--medium-yellow)"
            : "var(--medium-green)";

  const gradeString =
    grade === undefined ? "-" : grade === 0 ? "0" : grade.toFixed(1);

  return { color, gradeString };
};

export const JobInterviewGradeBox = ({
  grade,
  isPending,
}: {
  grade?: number;
  isPending?: boolean;
}) => {
  const { color, gradeString } = getGradeDetails(grade);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: isPending ? "var(--medium-blue)" : color,
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "48px",
          height: "48px",
        }}
      >
        <Typography component="label" size="large" fontWeight={500}>
          {isPending ? "⏳" : gradeString}
        </Typography>
      </Box>
    </Box>
  );
};

interface JobInterviewDescriptionProps {
  description?: string;
  maxCharacters?: number;
  isCollapsed?: boolean;
}

export const JobInterviewDescription = ({
  description = "",
  maxCharacters = 100,
  isCollapsed,
}: JobInterviewDescriptionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    const truncated = text.substr(0, text.lastIndexOf(" ", maxLength));
    return truncated.length > 0 ? truncated : text.substr(0, maxLength);
  };

  const trimmedDescription = truncateText(description, maxCharacters);
  const itCanOverflow = description.length > trimmedDescription.length;
  const remainingText = itCanOverflow
    ? description.substring(trimmedDescription.length)
    : "";

  return (
    <Typography component="p" variant="paragraph">
      {trimmedDescription}
      {itCanOverflow && (
        <>
          <Typography
            component="span"
            variant="paragraph"
            sx={
              isExpanded || isCollapsed
                ? { display: "inline" }
                : { display: "none" }
            }
          >
            {remainingText}
          </Typography>
          {!isCollapsed && (
            <Typography
              ml={1}
              component="span"
              variant="paragraph"
              sx={{
                textDecoration: "underline",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Read less" : "Read more"}
            </Typography>
          )}
        </>
      )}
    </Typography>
  );
};

export const JobInterviewGradingSummary = ({
  titleIcon,
  grade,
  title,
  description,
  isCollapsed,
}: JobInterviewGradingSummaryProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        minHeight: 80,
      }}
    >
      <JobInterviewGradeBox grade={grade} />
      <Box ml={2}>
        <Box display="flex" flexWrap="nowrap" gap={1}>
          {titleIcon && <img src={titleIcon} alt="grading_icon" width={18} />}
          <Typography component="label" variant="label">
            {title}
          </Typography>
        </Box>
        <JobInterviewDescription
          description={description}
          isCollapsed={isCollapsed}
        />
      </Box>
    </Box>
  );
};

export const JobInterviewPendingGradingSummary = ({
  titleIcon,
  bid,
}: {
  titleIcon?: string;
  bid: EmployerBidListBid;
}) => {
  const [resendEmployerBidInterview] = useResendEmployerBidInterviewMutation();

  const resendInvite = async () => {
    resendEmployerBidInterview({ bidId: bid.id })
      .unwrap()
      .then(() => Snackbar.success("The invite has been successfully resent."))
      .catch(() =>
        Snackbar.error("Failed to resend the invite. Please try again later."),
      );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        minHeight: 80,
      }}
    >
      <JobInterviewGradeBox isPending />
      <Box ml={2}>
        <Box display="flex" flexWrap="nowrap" gap={1}>
          {titleIcon && <img src={titleIcon} alt="grading_icon" width={18} />}
          <Typography component="label" variant="label">
            Pending interview completion and grading
          </Typography>
        </Box>
        <Typography component="span" variant="paragraph">
          Interview request was sent{" "}
          {formatDateTime(
            bid.interview?.interview_request_sent_at || bid.created,
          )}
          .{" "}
          <Typography
            component="span"
            variant="paragraph"
            sx={{
              textDecoration: "underline",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={resendInvite}
          >
            Re-send AI Interview request.
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export const formatDateTime = (date: IsoDateOrIsoDateTime) => {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset > 0 ? "-" : "+";

  const tzOffset = `${sign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

  const timezone = getTimezoneByOffset(tzOffset);

  return `${dateToFromNowDaily(new Date(date))} at ${dayjs(date).format(`h:mm A`)} ${timezone?.abbreviation}`;
};
