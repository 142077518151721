import cs from "classnames";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box } from "@hexocean/braintrust-ui-components";
import { Button, Typography } from "@hexocean/braintrust-ui-components";
import {
  HandshakeEmojiIcon,
  RocketEmojiIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { CreatePostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { openCreatePostModal } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/open-modal";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { useAppDispatch } from "@js/hooks";

export const OffersEmptyState = () => {
  const dispatch = useAppDispatch();
  const location = usePostLocationContext();

  const handleOpenCreatePostModal = () =>
    dispatch(openCreatePostModal({ composer_location: location }));

  return (
    <Box className="listings-content-wrapper__empty" mt={3}>
      <Box mb={3.75}>
        <ImgComponentItem
          icon={<RocketEmojiIcon sx={{ width: "12px", height: "12px" }} />}
          circleColor="var(--medium-violet)"
          rectangleColor="var(--medium-yellow)"
        />
        <ImgComponentItem
          circleColor="var(--medium-green)"
          rectangleColor="var(--medium-green)"
          icon={<HandshakeEmojiIcon sx={{ width: "12px", height: "12px" }} />}
          position="relative"
          bottom="24.5px"
          left="18.5px"
          displayBoxShadow
        />
      </Box>
      <Typography variant="label" size="large" component="h4">
        No offers yet
      </Typography>
      <Typography variant="paragraph" component="p" mb={2}>
        Get help on various topics to expand your expertise
      </Typography>
      <Button
        variant="white-border-beige"
        shape="squared"
        onClick={handleOpenCreatePostModal}
      >
        Create a post
      </Button>
      <CreatePostModalInstance />
    </Box>
  );
};

type ImgComponentItemProps = BoxProps & {
  circleColor: string;
  rectangleColor: string;
  icon?: React.ReactNode;
  displayBoxShadow?: boolean;
};

const ImgComponentItem = ({
  circleColor,
  rectangleColor,
  icon,
  displayBoxShadow,
  ...props
}: ImgComponentItemProps) => {
  return (
    <Box
      className={cs("offers-empty-state__img-container", {
        "display-box-shadow": displayBoxShadow,
      })}
      {...props}
    >
      <Box width={23} height={23} borderRadius="100%" bgcolor={circleColor} />
      <Box
        width={35}
        height={26.5}
        borderRadius="6.5px"
        textAlign="center"
        bgcolor={rectangleColor}
      >
        {icon}
      </Box>
    </Box>
  );
};
