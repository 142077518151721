import { Button, Stack } from "@hexocean/braintrust-ui-components";
import { openExportEmployerInvoicesModal } from "@js/apps/employer/components/employer-invoices/export-employer-invoices-modal";
import type { GetEmployerInvoicesParams } from "@js/apps/invoices/types";

import {
  EmployerInvoicesSelectedActions,
  type EmployerInvoicesSelectedActionsProps,
} from "../employer-invoices-selected-actions";
import type { EmployerInvoicesTableColumnField } from "../types";

export type EmployerInvoicesActionsProps = {
  selectedInvoicesIds: number[];
  totalInvoicesCount: number;
  filters: GetEmployerInvoicesParams;
  selectedColumns: EmployerInvoicesTableColumnField[];
} & Pick<EmployerInvoicesSelectedActionsProps, "startPaymentFlow">;

export const EmployerInvoicesActions = ({
  selectedInvoicesIds,
  startPaymentFlow,
  totalInvoicesCount,
  filters,
  selectedColumns,
}: EmployerInvoicesActionsProps) => {
  const selectedCount = selectedInvoicesIds.length;
  const showSelectedInvoicesActions = !!selectedCount;
  const showExportCSV = !!totalInvoicesCount && !selectedCount;

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {showSelectedInvoicesActions && (
        <EmployerInvoicesSelectedActions
          selectedInvoicesIds={selectedInvoicesIds}
          startPaymentFlow={startPaymentFlow}
        />
      )}
      {showExportCSV && (
        <Button
          variant="secondary"
          shape="squared"
          onClick={() =>
            openExportEmployerInvoicesModal({
              filters,
              columns: selectedColumns,
            })
          }
        >
          Export CSV
        </Button>
      )}
    </Stack>
  );
};
