import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { useAccountType } from "@js/apps/common/hooks";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import {
  isInterviewAvailable,
  useGetLastInterviewQuery,
} from "@js/apps/interview";
import { StartScreeningButton } from "@js/apps/jobs/components/job-action-buttons/components";
import { DefaultActionBar } from "@js/apps/jobs/components/job-details-action-bar/default-action-bar";
import { useJobActionBarContext } from "@js/apps/jobs/components/job-details-action-bar/job-context";

export const FreelancerActionBar = () => {
  const freelancer = useFreelancerProfile();
  const { isFreelancerApproved, isUserVerified } = useAccountType();

  const { data: interview } = useGetLastInterviewQuery(undefined, {
    skip: isFreelancerApproved || !isUserVerified,
  });

  const isInterviewAvailableForUser = isInterviewAvailable(interview);

  if (!freelancer?.pending_approval || !isInterviewAvailableForUser)
    return null;

  return (
    <DefaultActionBar
      title="Congrats on your first application 🎉"
      subtitle="Start your video screening!"
      bgcolor="var(--medium-green)"
      textColor="primary"
      titleProps={{
        variant: "label",
        size: "medium",
      }}
      subtitleProps={{
        variant: "title",
        size: "small",
      }}
      content={
        <Typography
          color="primary"
          variant="paragraph"
          size="medium"
          component="p"
          fontWeight={400}
        >
          We require all our job-seeking talent to complete a 10-minute video
          screening, where you answer four questions. Once you pass screening,
          your application will be sent to the client.
        </Typography>
      }
      actions={[
        <StartScreeningButton
          key="screening-button"
          variant="primary"
          withIcon
        />,
      ]}
    />
  );
};

export const FreelancerActionBarForJobDetailsPage = () => {
  const { job } = useJobActionBarContext();

  if (
    job?.status_for_freelancer !== ENUMS.JobFreelancerStatus.PENDING_APPROVAL
  ) {
    return null;
  }

  return <FreelancerActionBar />;
};
