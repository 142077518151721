import type { KeyboardEvent } from "react";
import { type MouseEvent, useMemo } from "react";

import {
  Button,
  IconButton,
  ListItemText,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  DownloadArrowIcon,
  MoreVertIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useIsEmployerInvoicePaymentBlocked } from "@js/apps/employer/hooks/use-is-employer-invoice-payment-blocked";
import { openMarkAsPaidModal } from "@js/apps/invoices/components/mark-as-paid-action";
import { useMarkAsPaidAction } from "@js/apps/invoices/components/mark-as-paid-action/use-mark-as-paid-action";
import { getBlockedPaymentTime } from "@js/apps/invoices/utils";
import type { EmployerInvoice } from "@js/types/invoices";
import { handleDownloadFile } from "@js/utils/download";

import type { EmployerInvoiceRow } from "../../types";
import { useEmployerInvoicesTableContext } from "../employer-invoices-table-context";
import { isEmployerInvoiceItemRow } from "../helpers";

import { MarkAsPaidModalContent } from "./mark-as-paid-modal-content";

export type ActionCellProps = { row: EmployerInvoiceRow };

const commonButtonProps = {
  variant: "secondary",
  shape: "squared",
  size: "x-small",
  sx: { px: "0 !important", width: "56px", mr: 0.5 },
} as const;

export const ActionCell = ({ row }: ActionCellProps) => {
  const { onInvoiceMarkAsPaid, onInvoicePay } =
    useEmployerInvoicesTableContext();

  const invoice: EmployerInvoice = useMemo(() => {
    if (isEmployerInvoiceItemRow(row)) {
      return row.invoice;
    }

    return row;
  }, [row]);

  const { canBeMarkedAsPaid } = useMarkAsPaidAction({
    invoices: [invoice],
  });
  const { isPaymentTemporaryBlocked } =
    useIsEmployerInvoicePaymentBlocked(invoice);

  const isPayButtonVisible =
    (invoice.can_be_paid || isPaymentTemporaryBlocked) &&
    invoice.status !== ENUMS.InvoiceStatus.CANCELLED;

  const handlePayInvoiceClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    onInvoicePay(invoice);
  };

  const handleDownloadInvoiceClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    handleDownloadFile({ endpoint: invoice.pdf_file.file, blank: true });
  };

  const isViewButtonVisible = !isPayButtonVisible;

  const handleMarkInvoiceAsPaidClick = () => {
    openMarkAsPaidModal({
      onConfirm: () => onInvoiceMarkAsPaid(invoice),
      modalContent: <MarkAsPaidModalContent />,
    });
  };

  const stopPropagationOnEnter = (ev: KeyboardEvent<HTMLElement>) => {
    if (ev.key === "Enter") {
      ev.stopPropagation();
    }
  };

  return (
    <Stack
      direction="row"
      sx={{ flexWrap: "wrap", alignItems: "center", height: "100%", gap: 0.5 }}
    >
      {isPayButtonVisible && (
        <Tooltip
          disableHoverListener={!isPaymentTemporaryBlocked}
          disableFocusListener={!isPaymentTemporaryBlocked}
          disableTouchListener={!isPaymentTemporaryBlocked}
          disabled={!isPaymentTemporaryBlocked}
          placement="top"
          title={
            <Typography component="p" width="fit-content">
              This invoice is being edited and should be available to pay in{" "}
              {getBlockedPaymentTime(invoice.payment_blocked_until)}.
            </Typography>
          }
        >
          <span>
            <Button
              {...commonButtonProps}
              onClick={handlePayInvoiceClick}
              disabled={isPaymentTemporaryBlocked}
              onKeyDown={stopPropagationOnEnter}
            >
              Pay
            </Button>
          </span>
        </Tooltip>
      )}
      {isViewButtonVisible && <Button {...commonButtonProps}>View</Button>}
      <IconButton
        variant="transparent"
        onClick={handleDownloadInvoiceClick}
        aria-label="Download invoice PDF"
        size="medium"
        sx={{ p: "4px!important" }}
        onKeyDown={stopPropagationOnEnter}
      >
        <DownloadArrowIcon />
      </IconButton>

      {canBeMarkedAsPaid && (
        <Menu
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchor={
            <IconButton
              variant="transparent"
              aria-label="Open invoice menu"
              size="medium"
              sx={{ p: "4px!important" }}
              onClick={(ev: MouseEvent) => ev.stopPropagation()}
              onKeyDown={stopPropagationOnEnter}
            >
              <MoreVertIcon />
            </IconButton>
          }
        >
          <Menu.Item onClick={handleMarkInvoiceAsPaidClick}>
            <ListItemText>Mark as Paid</ListItemText>
          </Menu.Item>
        </Menu>
      )}
    </Stack>
  );
};
