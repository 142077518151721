export * from "./captcha";
export * from "./currency";
export * from "./data";
export * from "./date";
export * from "./dom";
export * from "./download";
export * from "./enums";
export * from "./iframe";
export * from "./lazy-with-retry";
export * from "./match-color";
export * from "./numbers";
export * from "./open-popup";
export * from "./permission";
export * from "./redux-form";
export * from "./rtkq";
export * from "./script";
export * from "./sort";
export * from "./string";
export * from "./typescript";
export * from "./url";
export * from "./websocket";

export const UNEXPECTED_NETWORK_ERROR_MESSAGE = `Unexpected error occurred. Please make sure you have
    an internet connection and double-check if the actions you performed are properly reflected in the system.`;

export const matchFontColorToBackgroundColor = (bgColorVariable: string) => {
  const baseTone = bgColorVariable.match(/soft|medium|dark/)?.[0];
  const tonesTransformMap = {
    soft: "medium",
    medium: "dark",
    dark: "medium",
  };

  try {
    if (!baseTone) throw new Error("Wrong background variable provided.");
    return bgColorVariable.replace(baseTone, tonesTransformMap[baseTone]);
  } catch (e: unknown) {
    console.error(e);
    return null;
  }
};
