import React, { useEffect } from "react";
import classNames from "classnames";

import { PageHead } from "@js/components/page-head";
import { PageTitle } from "@js/components/page-title";

import { CurrentBuildData } from "../../components/dev-current-build-copy";
import { useAppSelector } from "../../hooks";

export type CoreLayoutProps = {
  children: React.ReactNode;
  className?: string;
  pageTitle?: string;
  marginTop?: number;
};

export const CoreLayout = ({
  children,
  className = "",
  pageTitle = SETTINGS.OG_TAG_DEFAULT_TITLE,
  marginTop = 0,
}: CoreLayoutProps) => {
  useEffect(() => {
    CoreLayout.depth += 1;
  }, []);

  const notificationsNumber = useAppSelector((state) => {
    return (
      state.notifications?.siteNotifications?.filter(
        (notification) => !notification.read,
      )?.length || null
    );
  });

  const notificationsString = !!notificationsNumber
    ? `(${notificationsNumber}) `
    : "";

  return (
    <div
      className={classNames("core-layout", className)}
      style={{ zIndex: 900 + CoreLayout.depth, marginTop }}
    >
      <PageHead titleTemplate={`${notificationsString}%s`}>
        <meta property="og:title" content={SETTINGS.OG_TAG_DEFAULT_TITLE} />
        <meta
          property="og:image"
          content={SETTINGS.STATIC_URL + SETTINGS.OG_TAG_DEFAULT_IMAGE}
        />
        <meta
          property="og:description"
          content={SETTINGS.OG_TAG_DEFAULT_DESCRIPTION}
        />
        <meta
          property="description"
          content={SETTINGS.OG_TAG_DEFAULT_DESCRIPTION}
        />
      </PageHead>
      <PageTitle>{pageTitle}</PageTitle>
      {children}
      {process.env.DEPLOYMENT !== "production" && <CurrentBuildData />}
    </div>
  );
};

CoreLayout.depth = 0;
