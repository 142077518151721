import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";

import {
  Box,
  IconButton,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  ArrowRightIcon,
  CloseRoundedIcon,
  KeyboardArrowDownIcon,
  MenuTwoBarsIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useIntersectionObserver } from "@js/hooks/use-intersection-observer";

import { RouterLink } from "../link";
import { Logo } from "../logo";

import type { NavLink } from "./constants";
import { NAV_LINKS } from "./constants";
import {
  getHireTalentLinkUrl,
  getNavLinkToUrl,
  getSignUpLinkUrl,
} from "./helpers";
import { PublicTopHeader } from "./public-top-header";

import styles from "./public-top-navigation-mobile.module.scss";

export const PublicTopNavigationMobile = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const anchorRef = useRef(null);

  const isNearTopEdge = useIntersectionObserver(
    anchorRef,
    { threshold: 0.75 },
    true,
  );

  const handleMenuClick = () => {
    setIsNavOpen((prev) => !prev);
  };

  return (
    <>
      <Box ref={anchorRef} className={styles.pageOffset}></Box>
      <PublicTopHeader className={cs({ [styles.headerShort]: !isNearTopEdge })}>
        <Stack direction="row" className={cs(styles.navButtonContainer)}>
          <Logo size={120} />
          <IconButton
            aria-label="Open navigation"
            onClick={handleMenuClick}
            variant="transparent"
            disableRipple
            className={styles.menuButton}
            size="medium"
          >
            {isNavOpen ? (
              <CloseRoundedIcon sx={{ transform: "scale(1.1)" }} />
            ) : (
              <MenuTwoBarsIcon />
            )}
          </IconButton>
        </Stack>
        {isNavOpen && <NavLinksMenu />}
      </PublicTopHeader>
    </>
  );
};

const NavLinksMenu = () => {
  const isTabletOrLarge = !useMediaQuery("sm");
  const { search } = useLocation();

  return (
    <Box
      className={cs(styles.navContainer, {
        [styles.navContainerMedium]: isTabletOrLarge,
      })}
    >
      <Box component="nav" aria-label="Main menu">
        <Stack component="ul" className={styles.navLinks}>
          {NAV_LINKS.map(({ subLinks, ...linkData }) => {
            if (!subLinks?.length) {
              return <BasicNavLink key={linkData.label} {...linkData} />;
            }

            return (
              <NavLinkExpandable
                key={linkData.label}
                subLinks={subLinks}
                {...linkData}
              />
            );
          })}
        </Stack>
        <Stack sx={{ gap: "20px", pl: "6px", alignItems: "start" }}>
          <Box
            className={cs(styles.signUpWrapper, {
              [styles.signUpWrapperMedium]: isTabletOrLarge,
            })}
          >
            <RouterLink
              className={cs(styles.signUp, styles.ctaLink)}
              to={getSignUpLinkUrl(search)}
            >
              <span>Sign Up</span>
              <ArrowRightIcon />
            </RouterLink>
          </Box>

          <Stack
            direction="row"
            sx={{ alignItems: "center", gap: "18px", mb: "70px" }}
          >
            <RouterLink
              className={cs(styles.hireTalent, styles.ctaLink)}
              to={getHireTalentLinkUrl(search)}
            >
              Hire Talent
            </RouterLink>
            <ArrowRightIcon />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

type NavLinkWithUnderlineProps = Pick<NavLink, "url" | "label">;

const NavLinkWithUnderline = ({ label, url }: NavLinkWithUnderlineProps) => {
  return (
    <Typography
      component="link"
      to={url}
      RouterLink={RouterLink}
      className={styles.navLink}
    >
      {label}
    </Typography>
  );
};

type BasicNavLinkProps = Pick<
  NavLink,
  "url" | "label" | "persistUTMSearchParams"
>;

const BasicNavLink = ({
  url,
  label,
  persistUTMSearchParams,
}: BasicNavLinkProps) => {
  const { search } = useLocation();
  const toUrl = getNavLinkToUrl({ url, search, persistUTMSearchParams });

  return (
    <Stack
      direction="row"
      sx={{ height: "100%", alignItems: "center" }}
      className={styles.navLinkContainer}
    >
      <NavLinkWithUnderline url={toUrl} label={label} />
    </Stack>
  );
};

type NavLinkExpandableProps = BasicNavLinkProps &
  Pick<NavSubLinksProps, "subLinks">;

const NavLinkExpandable = ({
  url,
  label,
  subLinks,
}: NavLinkExpandableProps) => {
  const { search } = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <Stack
        direction="row"
        role="button"
        sx={{ height: "100%", alignItems: "center" }}
        className={styles.navLinkContainer}
        onClick={handleClick}
        aria-label={`Expand ${label}`}
        aria-expanded={isExpanded ? "true" : undefined}
      >
        <NavLinkWithUnderline url={url} label={label} />
        <KeyboardArrowDownIcon
          className={cs(styles.arrowIcon, { [styles.arrowIconUp]: isExpanded })}
        />
      </Stack>
      {isExpanded && <NavSubLinks subLinks={subLinks} search={search} />}
    </>
  );
};

type NavSubLinksProps = {
  subLinks: NonNullable<NavLink["subLinks"]>;
  search: string;
};

const NavSubLinks = ({ subLinks, search }: NavSubLinksProps) => {
  return (
    <Stack component="ul" className={styles.navSubLinks}>
      {subLinks.map(({ label, url, persistUTMSearchParams }) => {
        return (
          <Box key={label} component="li">
            <Typography
              component="link"
              to={getNavLinkToUrl({ url, search, persistUTMSearchParams })}
              RouterLink={RouterLink}
              className={cs(styles.navLink, styles.navSubLink)}
            >
              {label}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
};
