import { Button } from "@hexocean/braintrust-ui-components";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";
import { RouterLink } from "@js/components/link";

export const HelpOfferButtonsLeaveReview = ({
  offer,
  isOfferAuthor,
  isMobile,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const isOfferAuthorRemoved = offer.author.user.is_removed;
  if (isOfferAuthor || isOfferAuthorRemoved) return null;

  return (
    <>
      <Button
        variant="primary"
        shape="squared"
        className="help-offer-action-buttons__primary"
        size={isMobile ? "x-small" : "small"}
        to={`/talent/${offer.author.id}/?write_review=true&offer=${offer.id}&content_type=${ENUMS.ReviewType.HELP_OFFER_REVIEW}`}
        RouterLink={RouterLink}
      >
        Leave{" "}
        {!isMobile && (
          <span className="help-offer-action-buttons__ellipsis">
            {offer.author.user.first_name}
          </span>
        )}
        a review
      </Button>
    </>
  );
};
