import React from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { openInsightPurchaseModal } from "@js/apps/jobs/components/application-insight-purchase-modal";

type JobInsightsBannerProps = {
  onClose: () => void;
};

const JobInsightsBanner: React.FC<JobInsightsBannerProps> = ({ onClose }) => {
  const isMobile = useMediaQuery("md");

  return (
    <Box
      display="flex"
      minHeight="151px"
      flexDirection={isMobile ? "column" : "row"}
      border="1px solid var(--soft-grey)"
      overflow="hidden"
      borderRadius={4}
      sx={{ background: "var(--white)" }}
    >
      <Box
        sx={{ background: "var(--medium-green)" }}
        height={isMobile ? "148px" : "unset"}
        width={isMobile ? "unset" : "359px"}
        borderRadius={isMobile ? 0 : 2}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/job-insight-banner.png`}
          height="100%"
          width="100%"
          alt="job-insight-banner"
        />
      </Box>
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
        py={isMobile ? 2 : 1}
        px={isMobile ? 2 : 3}
      >
        <Box flex={1} pb={1.5}>
          <Typography component="h3" size="large">
            Maximize your job search success
          </Typography>
          <Typography component="p" size="small">
            Get tailored Application Insights to identify promising jobs,
            enhance your application, and more.
          </Typography>
        </Box>
        <Button
          sx={{
            textWrap: "nowrap",
            alignSelf: isMobile ? "end" : "center",
            paddingY: "7px !important",
          }}
          variant="positive"
          shape="squared"
          size="medium"
          endIcon={<ArrowRightIcon />}
          onClick={openInsightPurchaseModal}
        >
          Get Application Insights
        </Button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 16,
          right: 5,
        }}
      >
        <IconButton
          variant="transparent"
          size="small"
          aria-label="Close Job insight card"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default JobInsightsBanner;
