import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

export const BoostSuccessModal = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("sm");

  const topImageSection = () => {
    return (
      <Box>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: "var(--blue)",
            minHeight: "185px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: isMobile ? "cover" : "contain",
            backgroundImage: `url(${SETTINGS.STATIC_URL}boostmodal/${
              isMobile
                ? "boost-purchase-success-banner-mobile.png"
                : "boost-purchase-success-banner-desktop.png"
            })`,
          }}
        />
      </Box>
    );
  };

  const footer = () => {
    return (
      <Box display={"flex"} mt={isMobile ? 2 : 3} mb={4}>
        <Box mr={2}>
          <img
            src={`${SETTINGS.STATIC_URL}boostmodal/increaseBoost.png`}
            alt="BTRST Banner"
          />
        </Box>
        <Box>
          <Typography component="p" size="large" fontWeight={400}>
            Boost your job applications to increase your chances of getting
            hired.
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      boxSizing="border-box"
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      py={2}
      px={isMobile ? 3 : 4}
      maxWidth={isMobile ? "95vw" : "514px"}
      id="boost-success-modal"
    >
      <Box>
        {" "}
        <Typography
          component="h2"
          variant="label"
          size="medium"
          fontWeight={500}
          mt={1}
        >
          Application Boost activated
        </Typography>
      </Box>
      <Box pt={3}>{topImageSection()}</Box>
      <Box>
        <Typography
          component="p"
          variant="title"
          size="small"
          fontWeight={400}
          mt={isMobile ? 2 : 3}
        >
          Congratulations! Your Application Boosts are now ready
        </Typography>
      </Box>
      <Box>
        <Typography
          component="p"
          variant="paragraph"
          size="large"
          fontWeight={400}
          mt={1}
        >
          What’s next?
        </Typography>
      </Box>
      <Box>{footer()}</Box>
      <Box display={"flex"} justifyContent={"end"}>
        <Button
          onClick={() => {
            ModalInstance.close();
            navigate("role-jobs");
          }}
          variant="positive"
          sx={{
            height: "42px",
            borderRadius: "10px !important",
          }}
        >
          See open jobs
        </Button>
      </Box>
    </Box>
  );
};
