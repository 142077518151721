import { Outlet } from "react-router-dom";

import { Stack } from "@hexocean/braintrust-ui-components";

import { EmployerInvoicesStatistics } from "./employer-invoices-statistics";
import { EmployerInvoicesTabs } from "./employer-invoices-tabs";
import { EmployerInvoicesTitle } from "./employer-invoices-title";

export const EmployerInvoicesComponent = () => {
  return (
    <Stack sx={{ gap: 3 }}>
      <EmployerInvoicesTitle />
      <EmployerInvoicesStatistics />
      <EmployerInvoicesTabs />
      <Outlet />
    </Stack>
  );
};
