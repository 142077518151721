import type { GetEmployerInvoicesParamsOrderBy } from "@js/apps/invoices/types";

export type EmployerInvoicesTableColumnSortableField =
  GetEmployerInvoicesParamsOrderBy;

export const EMPLOYER_INVOICES_SORTABLE_FIELDS_MAP: Record<
  EmployerInvoicesTableColumnSortableField,
  EmployerInvoicesTableColumnSortableField
> = {
  due_date: "due_date",
  freelancer__user__email: "freelancer__user__email",
  freelancer_name: "freelancer_name",
  gross_total: "gross_total",
  issued_at: "issued_at",
  subject_title: "subject_title",
  status: "status",
  job_owner: "job_owner",
  number: "number",
  payee_name: "payee_name",
};

export const EMPLOYER_INVOICES_SORTABLE_FIELDS: EmployerInvoicesTableColumnSortableField[] =
  Object.values(EMPLOYER_INVOICES_SORTABLE_FIELDS_MAP);

export const EMPLOYER_INVOICES_TABLE_ID = "employerInvoicesTable";

export const EMPLOYER_INVOICES_SELECTION_LIMIT = 20;

export const EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL = {
  date_range: "Date range",
  status: "Invoice status",
  freelancers: "Talent name",
  jobs: "Project",
  job_owners: "Job owner",
} as const;

export type EmployerInvoicesFilterToApply =
  keyof typeof EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL;
