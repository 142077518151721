import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { COMPLETION_NUDGE_PARAMS } from "@js/apps/freelancer/constants";
import { useOpenModalsFromCompletionNudge } from "@js/apps/freelancer/hooks";
import type { FreelancerPublic } from "@js/types/freelancer";

import { WorkHistoryCard } from "./card";
import { openAddWorkHistoryModal } from "./modals";

type WorkHistoryModuleProps = {
  profile: FreelancerPublic;
};

export const WorkHistoryModule = ({ profile }: WorkHistoryModuleProps) => {
  const user = useUser();
  const isOwnProfile = user && profile.id === user.freelancer;
  const ownEmptyState =
    !profile.freelancer_work_experience.length && isOwnProfile;

  useOpenModalsFromCompletionNudge({
    requiredCondition: isOwnProfile,
    queryParam: COMPLETION_NUDGE_PARAMS.WORK_EXPERIENCE,
    openModal: openAddWorkHistoryModal,
  });

  return (
    <Box
      className={cs("work-history", {
        "profile-empty-state": ownEmptyState,
      })}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography
            component="h2"
            size="large"
            sx={{ color: "var(--grey-1)" }}
          >
            Work history
          </Typography>
          {isOwnProfile && !!profile.freelancer_work_experience.length && (
            <IconButton
              variant="black-outlined"
              size="x-small"
              className="experience__edit-btn"
              aria-label="Edit experience"
              onClick={openAddWorkHistoryModal}
            >
              <EditPenIcon />
            </IconButton>
          )}
        </Box>
        {!!profile.freelancer_work_experience.length ? (
          <Box display="grid" gap={4}>
            {profile.freelancer_work_experience.map((work) => {
              return <WorkHistoryCard key={work.id} work={work} />;
            })}
          </Box>
        ) : (
          <EmptyState profile={profile} ownEmptyState={ownEmptyState} />
        )}
      </Box>
    </Box>
  );
};

type EmptyStateProps = {
  profile: FreelancerPublic;
  ownEmptyState: boolean | null;
};

const EmptyState = ({ profile, ownEmptyState }: EmptyStateProps) => {
  return (
    <Box>
      <Typography
        component="p"
        fontStyle="italic"
        mt={-1}
        mb={2}
        multipleEllipsis
      >
        {ownEmptyState
          ? "Add your recent and relevant roles."
          : `${profile.user.first_name} hasn’t added their work history, yet.`}
      </Typography>
      {ownEmptyState && (
        <Button
          variant="black-outlined"
          size="medium"
          shape="squared"
          onClick={openAddWorkHistoryModal}
        >
          Add work history
        </Button>
      )}
    </Box>
  );
};
