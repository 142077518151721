import React, { useEffect, useMemo, useRef } from "react";
import { omit } from "underscore";

import type { AvatarSrc } from "@hexocean/braintrust-ui-components";
import {
  Avatar,
  Box,
  Button,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CheckIcon,
  CloseIcon,
  VerifiedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import {
  insightsSectionViewed,
  insightsTooltipClicked,
} from "@js/apps/jobs/actions";
import { useGetJobApplicationInsightsQuery } from "@js/apps/jobs/api";
import {
  formatMatchLabel,
  getMatchLabelColors,
  useMatchPlacement,
} from "@js/apps/jobs/components/avatar-with-score-match-badge/utils";
import { InfoTooltip } from "@js/components/info-tooltip";
import { useAppSelector } from "@js/hooks";
import { useAppDispatch } from "@js/hooks";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";
import useSortedToolTip from "@js/hooks/use-sorted-tooltip";
import type { Job } from "@js/types/jobs";

const PREVIEW_INSIGHTS = [
  "Selectively applying to jobs right now",
  "Top candidate for multiple past job positions",
];

type ApplicationInsightsProps = {
  job: Job;
};

const ApplicationInsights: React.FC<ApplicationInsightsProps> = ({ job }) => {
  const { onClickPurchaseUpgrade } = usePurchaseUpgrade("insights");
  const user = useAppSelector((state) => state.auth.user);
  const avatarSrc = useMemo(
    () => omit(user, "account_type") as AvatarSrc,
    [user],
  );
  const matchLabelStyleProps = getMatchLabelColors(job.match_label, true);
  const { isLoading, data } = useGetJobApplicationInsightsQuery({ id: job.id });
  const matchPlacement = useMatchPlacement(
    job.match_label,
    data?.total_applicants || 0,
    job.has_freelancer_bid,
  );
  const canViewInsights = useMemo(
    () => user?.can_view_insights,
    [user?.can_view_insights],
  );
  const canShowPreview = useMemo(
    () => !user?.can_view_insights && user?.freelancer_approved,
    [user?.can_view_insights, user?.freelancer_approved],
  );
  const hasTopSkills = !data?.skills.includes("0") || false;
  const [currentSkills, totalSkills] = data?.skills.split("/") || [0, 0];
  const applicationInsightsWrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const wrapperRefCurrent = applicationInsightsWrapperRef.current;

    if (!wrapperRefCurrent) return;
    if (isLoading || !job.match_label) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            dispatch(insightsSectionViewed());
            observer.disconnect();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      },
    );

    observer.observe(wrapperRefCurrent);

    return () => {
      if (wrapperRefCurrent) {
        observer.unobserve(wrapperRefCurrent);
      }
    };
  }, [applicationInsightsWrapperRef, isLoading, dispatch, job.match_label]);

  const handleButtonClick = () => {
    dispatch(insightsTooltipClicked({ jobId: job.id }));
    onClickPurchaseUpgrade();
  };

  const sortedToolTipdata = useSortedToolTip(data);

  return isLoading || !job.match_label ? null : (
    <Box
      sx={{ background: "var(--white)" }}
      display="flex"
      flexDirection="column"
      gap={2}
      borderRadius={4}
      mb={3}
      px={4}
      pt={4}
      pb={canViewInsights ? 4 : 2}
      ref={applicationInsightsWrapperRef}
    >
      <Box display="flex" alignItems="center">
        <Typography component="p" size="medium" fontWeight={500}>
          Application Insights
        </Typography>
        {!canViewInsights && (
          <InfoTooltip
            title={
              "Gain personalized job analysis with Application Insights, identifying promising opportunities and leveraging feedback to enhance your job search strategy."
            }
          />
        )}
      </Box>
      <Box display="flex" gap={1} alignItems="center">
        <Box
          px={1}
          sx={{ backgroundColor: matchLabelStyleProps.backgroundColor }}
          borderRadius="100px"
          height="fit-content"
          width="fit-content"
        >
          <Typography
            sx={{ color: matchLabelStyleProps.textColor }}
            component="p"
            size="small"
            fontWeight={500}
          >
            {formatMatchLabel(job.match_label)}
          </Typography>
        </Box>
        <Box display="flex">
          <Box height="29px" width="29px" position="relative">
            <VerifiedIcon
              sx={{
                top: 0,
                left: 0,
                position: "absolute",
                alignSelf: "end",
                fontSize: "36.33px",
              }}
            />
          </Box>
          <Avatar
            src={avatarSrc}
            sx={{
              boxSizing: "border-box",
              height: 33,
              width: 33,
              marginLeft: -0.1,
              border: "2px solid var(--white)",
              zIndex: 1,
            }}
          />
        </Box>
      </Box>
      <Box>
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          gap={1}
          {...(canShowPreview && {
            sx: {
              "&::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "57px",
                backdropFilter: "blur(2.5px)",
                "-webkit-backdrop-filter": "blur(2.5px)",
                pointerEvents: "none",
                background: "rgba(255, 255, 255, 0.5)",
              },
            },
          })}
        >
          {(canShowPreview || canViewInsights) && (
            <Box display="flex" gap={1} alignItems="center">
              {hasTopSkills ? (
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                />
              )}
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                {currentSkills} of {totalSkills} top skills for this job
              </Typography>
            </Box>
          )}
          {matchPlacement?.label && canViewInsights && (
            <Box display="flex" gap={1}>
              {matchPlacement.summaryType === "positive" ? (
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                />
              )}
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                {matchPlacement.label}
              </Typography>
            </Box>
          )}
          {canViewInsights && (
            <>
              {Array.isArray(data?.match_summary) &&
                sortedToolTipdata?.map(({ sentence, summary_type }) => (
                  <Box key={sentence} display="flex" gap={1}>
                    {summary_type === "positive" ? (
                      <CheckIcon
                        sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                      />
                    ) : (
                      <CloseIcon
                        sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                      />
                    )}
                    <Typography
                      sx={{ color: "var(--black)" }}
                      component="p"
                      variant="paragraph"
                      size="small"
                    >
                      {sentence}
                    </Typography>
                  </Box>
                ))}
              {data?.match_summary?.length === 0 && (
                <Box display="flex" gap={1}>
                  <img
                    height="20px"
                    width="auto"
                    src={`${SETTINGS.STATIC_URL}jobs/cloud-crossed.svg`}
                    alt="cloud-crossed"
                  />
                  <Typography
                    sx={{ color: "var(--black)" }}
                    component="p"
                    variant="paragraph"
                    size="small"
                  >
                    Please wait while we cook up more insights.
                  </Typography>
                </Box>
              )}
            </>
          )}
          {canShowPreview &&
            PREVIEW_INSIGHTS.map((insight) => (
              <Box key={insight} display="flex" gap={1} alignItems="center">
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
                <Typography
                  component="p"
                  variant="paragraph"
                  size="small"
                  sx={{ userSelect: "none" }}
                >
                  {insight}
                </Typography>
              </Box>
            ))}
        </Box>
        {!canViewInsights && (
          <Box textAlign="center" marginTop={canShowPreview ? "-23px" : 0}>
            <Button
              onClick={handleButtonClick}
              sx={{
                textWrap: "nowrap",
                marginBlock: "auto",
                width: "fit-content",
                paddingY: "7px !important",
                boxShadow: "0px 4px 11px 0px #0000001A",
                "&.typography": {
                  fontWeight: "500 !important",
                },
              }}
              variant="positive-2"
              shape="squared"
              size="medium"
              endIcon={
                <ArrowRightIcon
                  sx={{
                    "& > path": {
                      strokeWidth: "2.5px !important",
                    },
                  }}
                />
              }
            >
              Unlock full Application Insights
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ApplicationInsights;
