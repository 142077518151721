import { useCallback, useEffect, useState } from "react";
import { useMergeLink } from "@mergeapi/react-merge-link";

import { useUser } from "@js/apps/common/hooks";
import { useGetManagedEmployerQuery } from "@js/apps/employer/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import type { ATSIntegration } from "@js/types/employer";

import {
  atsIntegrationApiUtil,
  useCreateLinkTokenMutation,
  useLazyGetATSIntegrationsQuery,
  useLazyRelinkATSIntegrationsQuery,
  useSaveAccountTokenMutation,
} from "../../api";
import { openAtsIntegrationModal } from "../../components/ats-integration-modal";

type UseMergeLinkComponentConfig = {
  integration?: ATSIntegration;
  onSuccess?: () => void;
};
export const useMergeLinkComponent = ({
  integration,
  onSuccess,
}: UseMergeLinkComponentConfig = {}) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const [getATSIntegrations] = useLazyGetATSIntegrationsQuery();
  const [createLinkToken, { data: linkTokenResponse, reset, isLoading }] =
    useCreateLinkTokenMutation();
  const [saveAccountToken] = useSaveAccountTokenMutation();
  const { data: employerProfile } = useGetManagedEmployerQuery();
  const canCreateLinkedAccount = !!employerProfile?.can_create_linked_account;

  const [relink, { data: relinkTokenResponse }] =
    useLazyRelinkATSIntegrationsQuery();
  const [isATSIntegrationInitializing, setIsATSIntegrationInitializing] =
    useState(false);

  const linkToken =
    linkTokenResponse?.link_token || relinkTokenResponse?.link_token;

  const handleOpenATSJobsModal = useCallback(async () => {
    if (!user?.employer) {
      return;
    }

    try {
      const integrations = await getATSIntegrations().unwrap();

      if (integrations.length > 0) {
        openAtsIntegrationModal(integrations[0]);
      }
    } catch (_e) {
      Snackbar.error(
        "Something went wrong, please try again or contact with support team.",
      );
    }
  }, [user?.employer, getATSIntegrations]);

  const handleSuccess = useCallback(
    async (public_key: string) => {
      if (integration) {
        return;
      }

      try {
        await saveAccountToken({
          public_token: public_key,
          end_user_origin_id: linkTokenResponse?.end_user_origin_id || "",
        }).unwrap();
        onSuccess?.();
      } catch (_e) {
        Snackbar.error(
          "Something went wrong, please try again or contact with support team.",
        );
      }
    },
    [
      saveAccountToken,
      linkTokenResponse?.end_user_origin_id,
      integration,
      onSuccess,
    ],
  );

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess: handleSuccess,
    onExit: async () => {
      if (reset) {
        reset();
      }
      await handleOpenATSJobsModal();
    },
  });

  const openRef = useEffectRef(open);
  useEffect(() => {
    if (!isReady || !isATSIntegrationInitializing) {
      return;
    }

    setIsATSIntegrationInitializing(false);
    openRef.current();
  }, [isReady, isATSIntegrationInitializing, openRef]);

  const initializeATSIntegration = async () => {
    try {
      await createLinkToken().unwrap();
      setIsATSIntegrationInitializing(true);
    } catch (_e) {
      setIsATSIntegrationInitializing(false);
      Snackbar.error(
        "Something went wrong, please try again or contact with support team.",
      );
    }
  };

  const relinkATSIntegration = async () => {
    if (!integration) {
      return;
    }

    try {
      await relink({ integrationId: integration.id }).unwrap();
      dispatch(
        atsIntegrationApiUtil.invalidateTags([
          "ATSSyncedJobs",
          { type: "EmployerATSIntegrationStatus", id: integration.id },
        ]),
      );
      setIsATSIntegrationInitializing(true);
    } catch (_e) {
      setIsATSIntegrationInitializing(false);
      Snackbar.error(
        "Something went wrong, please try again or contact with support team.",
      );
    }
  };

  return {
    initializeATSIntegration,
    relinkATSIntegration,
    isATSIntegrationInitializing,
    isATSIntegrationDisabled:
      !canCreateLinkedAccount || isATSIntegrationInitializing || isLoading,
  };
};
