import { Button, Stack, Typography } from "@hexocean/braintrust-ui-components";
import {
  CircleCheckIcon,
  CircleUncheckedIcon,
} from "@hexocean/braintrust-ui-components/Icons";

type CheckboxButtonProps = {
  onClick: () => void;
  isSelected: boolean;
  label: string;
};

export const CheckboxButton = ({
  onClick,
  isSelected,
  label,
}: CheckboxButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant={isSelected ? "medium-violet" : "white-violet"}
      shape="squared"
      sx={{ flexGrow: 1 }}
    >
      <Checkbox isSelected={isSelected} label={label} />
    </Button>
  );
};

type CheckboxProps = {
  isSelected: boolean;
  label: string;
};

const Checkbox = ({ isSelected, label }: CheckboxProps) => {
  return (
    <Stack direction="row" gap={1} paddingBlock={1} width="100px">
      {isSelected ? (
        <CircleCheckIcon sx={{ color: "var(--black)" }} />
      ) : (
        <CircleUncheckedIcon />
      )}
      <Typography component="p" color="primary" variant="label">
        {label}
      </Typography>
    </Stack>
  );
};
