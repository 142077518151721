import React from "react";
import { Field, Fields } from "redux-form";

import { Box, Divider, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { HoursPerWeekSliderField } from "@js/forms/fields/hours-per-week-slider";
import { renderValueLabelFormatForHoursPerWeekField } from "@js/forms/fields/hours-per-week-slider/utils";
import { isError, isTouched } from "@js/forms/utils";

import { LocationAndTimezoneSection } from "./location-timezone-section";
import { ProjectDurationField } from "./project-duration";

export const ProjectDetailsSectionModule = ({
  contract_type,
  deadline,
  start_date,
  expected_hours_per_week,
  timezone_overlap,
  location,
  timezone,
}) => {
  const fields = [
    contract_type,
    expected_hours_per_week,
    timezone_overlap,
    deadline,
    start_date,
    location,
    timezone,
  ];
  const isMobile = useMediaQuery("sm");
  const isAnyTouched = isTouched(fields);
  const isErrorInSection = isError(fields);
  const isErrorInDataPicker = isError([start_date, deadline]);
  const displayError =
    (isErrorInSection && isAnyTouched) || isErrorInDataPicker;

  return (
    <FieldSectionPanel
      title="Job Details"
      description="Length of job, working hours, location, etc."
      isError={displayError}
    >
      <Divider color="beige" sx={{ my: "20px" }} />
      <Fields
        names={["contract_type", "deadline", "start_date"]}
        component={ProjectDurationField}
      />
      <Divider color="beige" sx={{ my: 3 }} />

      <Typography
        component="label"
        id="expected-hours-label"
        sx={{ marginBottom: "18px" }}
      >
        How many hours per week will Talent work?
      </Typography>
      <Box pt={{ xs: 12, sm: 10 }} pl={1.8}>
        <Field
          name="expected_hours_per_week"
          component={HoursPerWeekSliderField}
          aria-labelledby="expected-hours-label"
          valueLabelDisplay="on"
          size="medium"
          showErrors
          valueLabelFormat={(value) =>
            renderValueLabelFormatForHoursPerWeekField(value, isMobile)
          }
        />
      </Box>

      <Fields
        names={["locations", "timezones", "timezone_overlap"]}
        component={LocationAndTimezoneSection}
      />
    </FieldSectionPanel>
  );
};
