import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import {
  BoltIcon,
  CodeSVGIcon,
  LaptopIcon,
  LineChartIcon,
  MentoringIcon,
  PenIcon,
  RocketIcon,
  SupportIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { interestSelected } from "@js/apps/onboarding/actions";
import { OptionCheckbox } from "@js/apps/onboarding/fields/option-checkbox";
import {
  ONBOARDING_SUBMIT_TYPE,
  useOnboardingUpdateSubmitType,
} from "@js/apps/onboarding/hooks";
import { useAppDispatch } from "@js/hooks";
import { enumToOptions } from "@js/utils";

import { PROF_NET_ONBOARDING_PATHS } from "../../constants";
import { OnboardingProfNetLayout } from "../layout";

const ICONS: Record<keyof typeof ENUMS.InterestLabels, JSX.Element> = {
  design: <PenIcon />,
  engineering: <CodeSVGIcon />,
  product: <LineChartIcon />,
  web3: <RocketIcon />,
  freelancing: <LaptopIcon />,
  career_advice: <BoltIcon />,
  mentorship: <SupportIcon />,
  mentoring: <MentoringIcon />,
};

const OPTIONS = enumToOptions(ENUMS.InterestLabels).map(
  ({ value, label }, index) => ({
    value,
    label,
    icon: ICONS[value],
    "data-testid": `interest-${index}`,
  }),
);

const Greetings = ({ input }: TypedWrappedFieldProps<string>) => {
  return (
    <Typography component="h1" variant="paragraph" size="medium">
      Great to meet you, {input.value}
    </Typography>
  );
};

export const OnboardingInterestsPage = () => {
  const user = useUser();
  const navigate = useNavigate();
  const { search } = useLocation();
  const updateSubmitType = useOnboardingUpdateSubmitType();
  const dispatch = useAppDispatch();

  const handleOptionSelect = (selectedOption: string) => {
    dispatch(
      interestSelected({
        interests: selectedOption as EnumType<typeof ENUMS.Interest>,
      }),
    );
  };

  return (
    <OnboardingProfNetLayout
      activeStep={1}
      onBack={() =>
        navigate({
          pathname: PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path,
          search,
        })
      }
      onContinue={() => {
        if (!user) {
          updateSubmitType(ONBOARDING_SUBMIT_TYPE.interests);
        } else {
          updateSubmitType(ONBOARDING_SUBMIT_TYPE.set_joining_reason);
        }
      }}
      isChoosingGoalsAndInterests
    >
      <Field name="first_name" component={Greetings} />
      <Typography
        mt={1}
        id="interested-in"
        component="h2"
        variant="title"
        fontWeight={400}
        size="small"
      >
        What are you interested in?
      </Typography>
      <Field
        name="interests"
        labelledBy="interested-in"
        options={OPTIONS}
        onSelect={handleOptionSelect}
        component={OptionCheckbox}
      />
    </OnboardingProfNetLayout>
  );
};
