import { useMemo } from "react";
import type { SubmitHandler, UseControllerProps } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  IconButton,
  InputAdornment,
  RegularTextField,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  SearchSVGIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { RhfForm } from "@js/rhf/types";

import { employerInvoicesSearchFormSchema } from "../schema";
import { type EmployerInvoicesFormValues } from "../schema";

type FormValues = RhfForm<typeof employerInvoicesSearchFormSchema>["Values"];

type SearchFilterProps = {
  search: EmployerInvoicesFormValues["search"];
  onChange: (search: EmployerInvoicesFormValues["search"]) => void;
};

export const SearchFilter = ({ search, onChange }: SearchFilterProps) => {
  const initialValues = useMemo(() => {
    return { search: search };
  }, [search]);

  const { handleSubmit, control } = useForm<FormValues>({
    resolver: zodResolver(employerInvoicesSearchFormSchema),
    values: initialValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onChange(values.search);
  };

  const onReset = () => {
    onChange(null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SearchInputField name="search" control={control} onReset={onReset} />
    </form>
  );
};

type SearchInputFieldProps = UseControllerProps<FormValues> & {
  onReset: () => void;
};

const SearchInputField = ({ onReset, ...rhfProps }: SearchInputFieldProps) => {
  const { field } = useController(rhfProps);

  const { value, onChange } = field;

  const handleReset = () => {
    onChange("");
    onReset();
  };

  return (
    <RegularTextField
      {...field}
      value={value || ""}
      size="small"
      placeholder="Search by invoice #, project, or payee"
      InputProps={{
        sx: {
          width: "332px",
          minHeight: "40px",
          backgroundColor: "var(--white)",
          borderRadius: "5rem",
          fontSize: "13px",
          ".MuiOutlinedInput-notchedOutline": {
            borderRadius: "5rem",
            borderColor: "var(--grey-4)",
          },
          "input:-webkit-autofill": value
            ? {
                borderRadius: 0,
              }
            : {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
        },
        startAdornment: (
          <InputAdornment position="start" sx={{ ml: "6px" }}>
            <SearchSVGIcon sx={{ fontSize: "24px" }} />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={handleReset}
              variant="transparent"
              size="x-small"
            >
              <CloseIcon sx={{ fontSize: "inherit" }} />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};
