import cs from "classnames";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { CircleCheckOutlineIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import { BarCloseButton } from "./bar-close-button";

import style from "./style.module.scss";

type AllPublishedBarProps = {
  onClose: () => void;
};

export const AllPublishedBar = ({ onClose }: AllPublishedBarProps) => {
  return (
    <Box className={cs(style.atsInfoBar, style.positive)}>
      <Box className={style.atsInfoBarHeader}>
        <CircleCheckOutlineIcon
          className={cs(
            style.atsInfoBarHeaderIcon,
            style.atsInfoBarHeaderIconPositive,
          )}
        />
        <Typography
          component="h2"
          fontWeight={500}
          className={style.atsInfoBarHeaderText}
        >
          Success! Your job[s] have been published to Braintrust!
        </Typography>
        <BarCloseButton onClose={onClose} />
      </Box>
      <Typography
        component="p"
        size="large"
        className={style.atsInfoBarTextContent}
      >
        Find your next hire. Over 669,800 skilled individuals are available on
        the network, ready for your upcoming project. Post a job on Braintrust.
      </Typography>
      <Box className={style.atsInfoBarCtaContainer}>
        <Button
          variant="primary"
          RouterLink={RouterLink}
          href={"/jobs/add_new"}
          target="_blank"
          size="medium"
        >
          Create a job
        </Button>
      </Box>
    </Box>
  );
};
