import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { NotInterestedButton } from "@js/apps/jobs/components/not-interested-button";
import type { MainJobListingJob } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import {
  RespondToOfferButton,
  ReviewOfferButton,
  ScheduleInterviewButton,
  StartScreeningButton,
  ViewApplicationButton,
  ViewJobButton,
} from "./components";

type JobActionButtonProps = {
  job: MainJobListingJob;
  bidId?: number;
  fullWidth?: boolean;
  size?: ButtonProps["size"];
  resultPosition?: number;
  searchResults?: boolean;
  statusForFreelancer?: EnumType<typeof ENUMS.JobFreelancerStatus>;
};

export const JobActionButton = ({
  job,
  bidId,
  statusForFreelancer,
  fullWidth,
  size,
  ...props
}: JobActionButtonProps) => {
  if (!statusForFreelancer) {
    return (
      <ViewJobButton
        jobId={job.id}
        fullWidth={fullWidth}
        size={size}
        {...props}
      />
    );
  }

  switch (statusForFreelancer) {
    case ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS:
      return (
        <ViewJobButton
          jobId={job.id}
          fullWidth={fullWidth}
          size={size}
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
      return (
        <>
          <NotInterestedButton job={job} fullWidth={fullWidth} size={size} />
          <ViewJobButton
            jobId={job.id}
            fullWidth={fullWidth}
            size={size}
            {...props}
          />
        </>
      );
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
      return (
        <>
          <ViewApplicationButton
            jobId={job.id}
            bidId={bidId}
            fullWidth={fullWidth}
            variant="secondary"
            size={size}
          />
          <StartScreeningButton
            fullWidth={fullWidth}
            size={size}
            variant="primary"
            withIcon
          />
        </>
      );
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
      return (
        <ViewApplicationButton
          jobId={job.id}
          bidId={bidId}
          fullWidth={fullWidth}
          size={size}
          variant="primary"
          withIcon
        />
      );
    case ENUMS.JobFreelancerStatus.INTERVIEWING:
      if (!job?.creator_user_id) return null;

      return (
        <ScheduleInterviewButton
          employerUserId={job.creator_user_id}
          fullWidth={fullWidth}
          size={size}
          variant="primary"
        />
      );
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      if (!job?.offer_id) return null;
      return (
        <RespondToOfferButton
          jobId={job.id}
          offerId={job.offer_id}
          fullWidth={fullWidth}
          size={size}
          variant="primary"
        />
      );
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
      if (!job?.offer_id) return null;
      return (
        <ReviewOfferButton
          jobId={job.id}
          offerId={job.offer_id}
          fullWidth={fullWidth}
          size={size}
          variant="primary"
        />
      );
    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
      return (
        <ViewApplicationButton
          jobId={job.id}
          bidId={bidId}
          fullWidth={fullWidth}
          size={size}
          variant="secondary"
        />
      );

    case ENUMS.JobFreelancerStatus.JOB_FILLED:
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
      return null;

    default: {
      assertUnreachable(statusForFreelancer);
      return null;
    }
  }
};
