import React, { useMemo } from "react";

import type {
  Button,
  IconButtonProps,
} from "@hexocean/braintrust-ui-components";
import { IconButton, Tooltip } from "@hexocean/braintrust-ui-components";
import type { CreatePostAttachmentFieldType } from "@js/apps/give-and-get-help/context";
import { useCreatePostOpenedFieldsContext } from "@js/apps/give-and-get-help/context";

export type FooterActionButtonProps = Omit<IconButtonProps, "to" | "href"> & {
  fieldName: CreatePostAttachmentFieldType;
  title?: string;
  Component?: typeof IconButton | typeof Button;
  disablePostActions?: boolean;
};

export const FooterActionButton: React.FC<
  React.PropsWithChildren<Omit<FooterActionButtonProps, "variant">>
> = ({
  children,
  fieldName,
  Component = IconButton,
  title,
  onClick,
  disablePostActions,
  ...rest
}) => {
  const { toggleField } = useCreatePostOpenedFieldsContext();

  const styles = useMemo(() => {
    if (Component === IconButton) {
      return {
        "&:hover": { backgroundColor: "var(--yellow)" },
      };
    }
  }, [Component]);

  return (
    <Tooltip
      title={title || ""}
      placement="top"
      arrow={false}
      disabled={!title}
    >
      <Component
        variant="transparent"
        size="medium"
        sx={styles}
        onClick={(ev: any) => {
          toggleField(fieldName);
          if (onClick) {
            onClick(ev);
          }
        }}
        disabled={disablePostActions}
        {...rest}
      >
        {children}
      </Component>
    </Tooltip>
  );
};
