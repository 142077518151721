import * as z from "zod";

export const PROVIDE_VALID_NUMBER_MESSAGE = "Please provide a valid number";

export const zodNumberField = z
  .string() // user input
  .nullish()
  .or(z.number()) // form can be initialized with number
  .refine(
    (val) => {
      if (
        val === null ||
        val === "" ||
        val === undefined ||
        Number.isNaN(Number(val))
      ) {
        return false;
      }

      return true;
    },
    { message: PROVIDE_VALID_NUMBER_MESSAGE },
  )
  .transform((val) => Number(val)); // user input to number

export const zodNumberFieldOptional = z
  .string() // user input
  .nullish()
  .or(z.number()) // form can be initialized with number
  .refine(
    (val) => {
      if (Number.isNaN(Number(val))) {
        return false;
      }

      return true;
    },
    { message: PROVIDE_VALID_NUMBER_MESSAGE },
  )
  .transform((val) => {
    if (val === null || val === "" || val === undefined) {
      return undefined;
    }

    return Number(val);
  })
  .optional();
