import type { GridColDef } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { EmployerMyTalent } from "@js/types/employer";
import { formatCurrency } from "@js/utils";
import { getAutosizedColumnWidth } from "@js/utils/table";

import type { EmployerMyTalentColumnField } from "../types";

import { EmailCell, JobTitleCell, NameCell, StatusCell } from "./cells";

import styles from "./my-talent-table.module.scss";

export type EmployerMyTalentTableColumnDef = GridColDef<EmployerMyTalent> & {
  field: EmployerMyTalentColumnField;
  headerName: string;
  default?: boolean;
  view_invoices_permission_required?: boolean;
  autosize?: boolean;
  autosizeExtraWidth?: number;
  isHidden?: boolean;
};

const formatCurrencyOrZero = (value: string | null) =>
  formatCurrency(value) || "$0.00";

const valueOrNAWhenEmpty = (value: string | null) => value || "N/A";

export const MY_TALENT_TABLE_COLUMNS: EmployerMyTalentTableColumnDef[] = [
  {
    field: "status_label",
    headerName: "Status",
    default: true,
    minWidth: 110,
    renderCell: StatusCell,
  },
  {
    field: "name", // freelancer public name
    headerName: "Name",
    default: true,
    minWidth: 120,
    autosize: true,
    autosizeExtraWidth: 40, // we are rendering an avatar together with the freelancer name
    renderCell: NameCell,
  },
  {
    field: "email",
    headerName: "Email",
    autosize: true,
    minWidth: 100,
    renderCell: EmailCell,
  },
  {
    field: "type_label", // job hire type
    headerName: "Type",
    minWidth: 110,
    default: true,
  },
  {
    field: "role", // job role
    headerName: "Role",
    default: true,
    minWidth: 100,
    autosize: true,
  },
  {
    field: "job_title",
    headerName: "Job",
    default: true,
    minWidth: 120,
    maxWidth: 1000,
    autosize: true,
    renderCell: JobTitleCell,
  },
  {
    field: "job_owner",
    headerName: "Job owner",
    minWidth: 140,
    autosize: true,
    valueFormatter: valueOrNAWhenEmpty,
  },
  {
    field: "job_start_date",
    headerName: "Start date",
    default: true,
    minWidth: 130,
  },
  {
    field: "job_end_date",
    headerName: "End date",
    default: true,
    minWidth: 130,
  },
  {
    field: "rate", // job bid rate
    headerName: "Rate",
    view_invoices_permission_required: true,
    align: "right",
    minWidth: 90,
    width: 90, // override the deafult 100px width
    autosizeExtraWidth: 8, // for the $ sign
    autosize: true,
    headerClassName: styles.numberColumnHeader,
    valueFormatter: (value: EmployerMyTalent["rate"]) =>
      formatCurrencyOrZero(value),
  },
  {
    field: "bill_rate",
    headerName: "Bill rate",
    view_invoices_permission_required: true,
    align: "right",
    headerClassName: styles.numberColumnHeader,
    minWidth: 120,
    autosize: true,
    autosizeExtraWidth: 8, // for the $ sign
    valueFormatter: (value: EmployerMyTalent["bill_rate"]) =>
      formatCurrencyOrZero(value),
  },
  {
    field: "rate_type_label", // job rate type
    headerName: "Rate type",
    view_invoices_permission_required: true,
    minWidth: 130,
  },
  {
    field: "expected_hours_per_week",
    headerName: "Est weekly hrs",
    headerClassName: styles.numberColumnHeader,
    align: "right",
    minWidth: 155,
  },
  {
    field: "state", // freelancer state
    headerName: "State",
    minWidth: 140,
    autosize: true,
    valueFormatter: valueOrNAWhenEmpty,
  },
  {
    field: "country", // freelancer country
    headerName: "Country",
    minWidth: 140,
    autosize: true,
    valueFormatter: valueOrNAWhenEmpty,
  },
  {
    field: "last_invoice_date",
    headerName: "Last invoice date",
    view_invoices_permission_required: true,
    minWidth: 180,
    valueFormatter: valueOrNAWhenEmpty,
  },
  {
    field: "last_invoice_amount",
    headerName: "Last invoice amount",
    view_invoices_permission_required: true,
    headerClassName: styles.numberColumnHeader,
    align: "right",
    valueFormatter: (value: EmployerMyTalent["last_invoice_amount"]) =>
      value !== null ? formatCurrencyOrZero(value) : "N/A",
    minWidth: 200,
  },
  {
    field: "ytd_invoice_amount",
    headerName: "YTD invoice amount",
    view_invoices_permission_required: true,
    headerClassName: styles.numberColumnHeader,
    align: "right",
    valueFormatter: (value: EmployerMyTalent["ytd_invoice_amount"]) =>
      formatCurrencyOrZero(value),
    minWidth: 200,
  },
  {
    field: "total_invoiced",
    headerName: "Total invoiced",
    view_invoices_permission_required: true,
    headerClassName: styles.numberColumnHeader,
    align: "right",
    valueFormatter: (value: EmployerMyTalent["total_invoiced"]) =>
      formatCurrencyOrZero(value),
    minWidth: 160,
  },
];

export const getEmployerMyTalentTableColumns = (
  visibleColumns: EmployerMyTalentColumnField[],
  data: EmployerMyTalent[],
): EmployerMyTalentTableColumnDef[] => {
  return MY_TALENT_TABLE_COLUMNS.map((column) => {
    const isHidden = !visibleColumns.includes(column.field);
    const columnMinWidth =
      column.autosize && !isHidden
        ? getAutosizedColumnWidth({ data, column })
        : column.minWidth;

    return {
      ...column,
      isHidden,
      resizable: false,
      minWidth: columnMinWidth,
    };
  });
};
