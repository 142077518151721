import type { MUIAutocomplete } from "@hexocean/braintrust-ui-components";
import {
  autocompleteClasses,
  StyleAutocompleteMediumVioletChips,
  styled,
} from "@hexocean/braintrust-ui-components";

export const EmployerMyTalentFiltersAutocomplete = styled(
  StyleAutocompleteMediumVioletChips,
)({
  [`&& .${autocompleteClasses.input}`]: {
    padding: "6px",
  },
  [`&& .${autocompleteClasses.inputRoot}`]: {
    minHeight: "42px",
    paddingBlock: "5px !important",
    paddingLeft: "10px !important",
  },
  [`& .${autocompleteClasses.tag}`]: {
    fontSize: "16px",
    fontWeight: 500,
    height: "26px",
    ".MuiChip-label": {
      paddingInline: "8px",
    },
  },
}) as typeof MUIAutocomplete;
