import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Button, Stack, Typography } from "@hexocean/braintrust-ui-components";

type FilterPopoverFooterProps = {
  onReset: () => void;
  containerProps?: StackProps;
  errorMessage?: string;
};

export const FilterPopoverFooter = ({
  onReset,
  containerProps,
  errorMessage,
}: FilterPopoverFooterProps) => {
  return (
    <Stack
      direction="row"
      {...containerProps}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        width: "100%",
        ...containerProps?.sx,
      }}
    >
      <Button
        onClick={onReset}
        variant="transparent-grey"
        sx={{ ml: -1, px: "8px !important", whiteSpace: "nowrap" }}
        size="x-small"
      >
        Clear all
      </Button>
      <Stack direction="row" sx={{ gap: 2, alignItems: "center" }}>
        {!!errorMessage && (
          <Typography
            variant="paragraph"
            component="p"
            error
            size="medium"
            sx={{ textAlign: "right" }}
          >
            {errorMessage}
          </Typography>
        )}
        <Button
          variant="primary"
          type="submit"
          shape="squared"
          sx={{ flexShrink: 0 }}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};
