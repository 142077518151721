import { SheetPaperIcon } from "@hexocean/braintrust-ui-components/Icons";

import { EmployerMyTalentJobNameForm } from "./forms/employer-my-talent-job-name-form";
import { FilterButtonPopover } from "./filter-button-popover";
import { useFilterPopover, useIsPopoverRendered } from "./hooks";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentJobNameFilterPopoverProps = {
  jobs: EmployerMyTalentFormValues["jobs"];
  onChange: (jobs: EmployerMyTalentFormValues["jobs"]) => void;
};

export const MyTalentJobNameFilterPopover = ({
  jobs,
  onChange,
}: MyTalentJobNameFilterPopoverProps) => {
  const isAnyJobApplied = Boolean(jobs && jobs.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, isPopoverRendered } =
    useIsPopoverRendered();

  const handleApply = (newJobs: EmployerMyTalentFormValues["jobs"]) => {
    onChange(newJobs);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterButtonPopover
      label="Job title"
      icon={<SheetPaperIcon />}
      isFilterApplied={isAnyJobApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
    >
      <EmployerMyTalentJobNameForm
        jobs={jobs}
        onApply={handleApply}
        shouldOpenDropdown={isPopoverRendered}
      />
    </FilterButtonPopover>
  );
};
