import { useMemo } from "react";
import type { SubmitHandler, UseControllerProps } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  IconButton,
  InputAdornment,
  RegularTextField,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  SearchSVGIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { RhfForm } from "@js/rhf/types";

import {
  type EmployerMyTalentFormValues,
  employerMyTalentSearchFormSchema,
} from "./schemas";

type Form = RhfForm<typeof employerMyTalentSearchFormSchema>;
type FormValues = Form["Values"];

type MyTalentSearchFilterProps = {
  search: EmployerMyTalentFormValues["search"];
  onChange: (search: EmployerMyTalentFormValues["search"]) => void;
};

export const MyTalentSearchFilter = ({
  search,
  onChange,
}: MyTalentSearchFilterProps) => {
  const initialValues = useMemo(() => {
    return { search };
  }, [search]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerMyTalentSearchFormSchema),
    values: initialValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onChange(values.search);
  };

  const onReset = () => {
    reset({ search: null });
    onChange(null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MyTalentSearchInputField
        name="search"
        control={control}
        onReset={onReset}
      />
    </form>
  );
};

type MyTalentSearchInputFieldProps = {
  onReset: () => void;
} & UseControllerProps<FormValues>;

const MyTalentSearchInputField = ({
  onReset,
  ...rhfProps
}: MyTalentSearchInputFieldProps) => {
  const { field } = useController(rhfProps);

  return (
    <RegularTextField
      {...field}
      value={field.value || ""}
      placeholder="Search by name or job"
      size="small"
      InputProps={{
        sx: {
          width: "300px",
          minHeight: "40px",
          backgroundColor: "var(--white)",
          borderRadius: "5rem",
          fontSize: "13px",
          fontWeight: 500,
          ".MuiOutlinedInput-notchedOutline": {
            borderRadius: "5rem",
            borderColor: "var(--grey-4)",
          },
          "input:-webkit-autofill": field.value
            ? {
                borderRadius: 0,
              }
            : {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
        },
        startAdornment: (
          <InputAdornment position="start" sx={{ ml: "6px" }}>
            <SearchSVGIcon sx={{ fontSize: "24px" }} />
          </InputAdornment>
        ),
        endAdornment: field.value ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={onReset}
              variant="transparent"
              size="x-small"
            >
              <CloseIcon sx={{ fontSize: "13px" }} />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};
