import type { TooltipProps } from "@hexocean/braintrust-ui-components";
import { Tooltip } from "@hexocean/braintrust-ui-components";

import { EMPLOYER_INVOICES_TABLE_ID } from "../constants";

export const EmployerInvoicesTableCellTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      PopperProps={{
        // use table container not to trigger scroll on body when scrolling to the side
        container: () =>
          document.getElementById(EMPLOYER_INVOICES_TABLE_ID) ?? document.body,
      }}
      {...props}
    />
  );
};
