// Mapping Locations to Frontend <-> Backend formats
import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import type { CustomLocation } from "@js/types/common";
import type { LocationPayload } from "@js/types/jobs";
import { typeGuard } from "@js/utils";

export const mapLocationsToFrontendFormat = (
  locations: object[],
): TalentMixedLocation[] => {
  return locations
    .map((location) => {
      if (!("google_full_location" in location)) {
        return undefined;
      }

      if (
        location.google_full_location &&
        typeGuard<object, CustomLocation>(
          location.google_full_location,
          "custom",
        ) &&
        location.google_full_location.custom
      ) {
        return {
          ...location.google_full_location,
          formatted_address: location.google_full_location.name,
        } as unknown as TalentMixedLocation;
      }

      return location.google_full_location;
    })
    .filter((location): location is TalentMixedLocation => Boolean(location));
};

export const mapLocationsToBackendFormat = (
  locations: TalentMixedLocation[],
) => {
  return locations.map((location) => {
    if (location.custom_location) {
      const JobCustomLocationsKeys = Object.keys(
        ENUMS.JobCustomLocationTypeLabels,
      );

      const isUserCustomLocation = JobCustomLocationsKeys.includes(
        location.custom_location,
      );

      if (isUserCustomLocation) {
        return {
          location: location.formatted_address,
          google_full_location: {
            id: location.custom_location,
            name: location.name,
            custom: true,
          },
        };
      }
    }

    return {
      location: location.formatted_address ?? location.name,
      google_full_location: location,
    };
  }) as LocationPayload[];
};
