import React from "react";

import { EmployerDashboardJobsComponent } from "./my-jobs/jobs";
import { EmployerDashboardLayout } from "./layout";

const EmployerDashboardMyJobsPageComponent = () => {
  return (
    <EmployerDashboardLayout pageTitle="My Jobs" bgcolor="var(--soft-blue)">
      <EmployerDashboardJobsComponent />
    </EmployerDashboardLayout>
  );
};

export default EmployerDashboardMyJobsPageComponent;
