import { useMemo } from "react";
import { Field } from "redux-form";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { MessageIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import {
  BudgetLabel,
  CategoryBudgetBadgeContainer,
  CommentsBudget,
  getAvailableBudgetOptions,
} from "@js/apps/give-and-get-help/components";
import { CategoryLabel } from "@js/apps/give-and-get-help/components/category-and-budget-utils/category-label";
import {
  HelpOfferExtraMessage,
  HelpOfferMenu,
} from "@js/apps/give-and-get-help/components/help-offer-card";
import { useEditOfferCard } from "@js/apps/give-and-get-help/hooks/edit-offer-card";
import { createPostOrCommentURL } from "@js/apps/give-and-get-help/utils";
import {
  ActionBarOfferButtons,
  HelpOfferButtonsCompleted,
} from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { RouterLink } from "@js/components/link";
import { UserAvatar } from "@js/components/user-avatar";
import { createFormInstance } from "@js/forms/components";
import { required } from "@js/forms/validators";
import type { HelpOffer } from "@js/types/give-and-get-help";
import { relativeDateFromNow } from "@js/utils";

import {
  HELP_OFFER_META_STATUS,
  HelpOfferStatusTag,
} from "./help-offer-status-tag";

const OFFER_CARD_FORM_ID = "offer-card-form";
const OfferCardForm = createFormInstance(OFFER_CARD_FORM_ID);

type OfferCardProps = {
  offer: HelpOffer;
  shouldShowMessage: boolean;
};

export const DashboardHelpOfferCard = ({
  offer,
  shouldShowMessage,
}: OfferCardProps) => {
  const user = useUser();
  const isOwner = offer?.author_id === user?.id;
  const receiverOrAuthorInfo = isOwner ? offer?.receiver : offer?.author;
  const {
    initialValues,
    editMode,
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    turnEditModeOn,
    turnEditModeOff,
  } = useEditOfferCard(offer);

  const budgetOptions = getAvailableBudgetOptions(
    offer.original_budget,
    offer.category,
  );

  const isTablet = useMediaQuery("md");

  const receiverOrAuthorProfileUrl = isOwner
    ? offer.receiver.link
    : offer.author.link;

  const helpOfferStatus = useMemo(() => {
    if (offer.reported) {
      return HELP_OFFER_META_STATUS.REPORTED;
    }

    if (offer.status === ENUMS.HelpOfferStatus.COMPLETED) {
      return ENUMS.HelpOfferStatus.COMPLETED;
    }

    if (
      offer.status === ENUMS.HelpOfferStatus.ACCEPTED &&
      offer.marked_complete
    ) {
      return HELP_OFFER_META_STATUS.PENDING_APPROVAL;
    }

    if (offer.refund_requested) {
      return HELP_OFFER_META_STATUS.REFUND_REQUESTED;
    }

    return offer.status;
  }, [
    offer.marked_complete,
    offer.reported,
    offer.status,
    offer.refund_requested,
  ]);

  if (!receiverOrAuthorInfo) return null;

  const hasRoleOrIntroductionHeadline =
    !!receiverOrAuthorInfo?.user.introduction_headline ||
    !!receiverOrAuthorInfo?.role?.name;

  const shouldDisplayOfferContent =
    helpOfferStatus === ENUMS.HelpOfferStatus.PENDING;

  const shouldDisplayPendingApprovalMessage =
    helpOfferStatus === ENUMS.HelpOfferStatusFilter.PENDING_APPROVAL;

  const postUrl = offer.comment
    ? createPostOrCommentURL({
        postId: offer.comment.main_post_id,
        spaceId: offer.comment.space_id,
      })
    : "";

  return (
    <div className="dashboard-help-offer-card">
      <div className="dashboard-help-offer-card__head">
        <div className="dashboard-help-offer-card__offer-status-and-message">
          {(shouldShowMessage || shouldDisplayPendingApprovalMessage) && (
            <HelpOfferExtraMessage
              offer={offer}
              helpOfferStatus={helpOfferStatus}
            />
          )}
          <div className="dashboard-help-offer-card__offer-status">
            <Box display="flex" gap={0.5} alignItems="center">
              <Box flex="1">
                <HelpOfferStatusTag status={helpOfferStatus} />
              </Box>
              <Typography
                color="grey-2"
                component="span"
                size="small"
                fontWeight={400}
                className="dashboard-help-offer-card__offer-created-time"
              >
                {relativeDateFromNow(offer.created)}
              </Typography>
              <HelpOfferMenu offer={offer} isOwner={isOwner} />
            </Box>
          </div>
        </div>
        <div className="dashboard-help-offer-card__user-info">
          <AvatarWrapper url={receiverOrAuthorProfileUrl}>
            <UserAvatar
              className="dashboard-help-offer-card__avatar"
              size="md"
              user={receiverOrAuthorInfo.user}
              hideBorder
              disableShadow
              hideBadge
            />
          </AvatarWrapper>
          <div className="dashboard-help-offer-card__user-details">
            <Typography
              className="dashboard-help-offer-card__username no-decoration"
              to={receiverOrAuthorProfileUrl}
              component={receiverOrAuthorProfileUrl ? "link" : "h3"}
              variant="label"
              size={isTablet ? "small" : "medium"}
              color="blue"
              RouterLink={RouterLink}
              fontWeight={500}
              flexShrink={0}
              sx={{ maxWidth: { xs: "100px", sm: "200px" } }}
              ellipsis
            >
              {receiverOrAuthorInfo.user.public_name}
            </Typography>
            {hasRoleOrIntroductionHeadline && (
              <Typography
                component="span"
                size={isTablet ? "small" : "medium"}
                fontWeight={500}
                flexShrink={1}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {receiverOrAuthorInfo?.user.introduction_headline ||
                  receiverOrAuthorInfo?.role?.name}
              </Typography>
            )}
            <Typography
              color="grey-2"
              component="span"
              fontWeight={400}
              className="dashboard-help-offer-card__offer-created-time desktop"
            >
              {relativeDateFromNow(offer.created)}
            </Typography>
          </div>
        </div>
      </div>
      {shouldDisplayOfferContent && (
        <>
          <div className="dashboard-help-offer-card__content">
            <Typography
              className="dashboard-help-offer-card__description"
              component="p"
              variant="paragraph"
              size="medium"
              sx={{ wordBreak: "break-word" }}
              multilineEllipsis={3}
            >
              {offer.description}
            </Typography>
            {offer.comment && (
              <Typography
                className="dashboard-help-offer-card__reference"
                component="p"
                variant="paragraph"
                size="small"
              >
                {isOwner
                  ? `From ${offer.receiver.user.first_name_possessive} post: `
                  : "From your post: "}
                <Typography
                  component="link"
                  size="small"
                  variant="link"
                  color="blue"
                  RouterLink={RouterLink}
                  to={postUrl}
                >
                  {offer.comment.main_post_title}
                </Typography>
              </Typography>
            )}
          </div>
          <Divider color="beige" sx={{ mb: 2 }} />
        </>
      )}
      <div className="dashboard-help-offer-card__footer">
        <div className="dashboard-help-offer-card__offer">
          <CategoryBudgetBadgeContainer categoryColor={offer.category.color}>
            <CategoryLabel category={offer.category} />

            <BudgetLabel budget={offer.budget} />
          </CategoryBudgetBadgeContainer>
          <div className="dashboard-help-offer-card__actions">
            <HelpOfferMessageButton
              interlocutorId={receiverOrAuthorInfo?.user.id}
              userId={user?.id}
            />

            <div className="dashboard-help-offer-card__actions__buttons">
              <ActionBarOfferButtons
                offer={offer}
                isOfferAuthor={isOwner}
                openEditMode={turnEditModeOn}
                type="dashboard"
                CompletedStatusComponent={HelpOfferButtonsCompleted}
              />
            </div>
          </div>
        </div>
        {editMode && (
          <OfferCardForm
            form={`${OFFER_CARD_FORM_ID}-${offer.id}`}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={onSubmitFail}
          >
            <div className="dashboard-help-offer-card__edit">
              {offer.comment && (
                <Typography
                  component="p"
                  variant="paragraph"
                  size="small"
                  multilineEllipsis={3}
                >
                  {offer.comment.text}
                </Typography>
              )}
              <Field
                name="budget"
                editMode
                type="edit-comment"
                component={CommentsBudget}
                validate={[required]}
                options={budgetOptions}
              />
              <div className="dashboard-help-offer-card__edit-buttons">
                <Button
                  variant="transparent-border-beige"
                  shape="squared"
                  onClick={turnEditModeOff}
                >
                  Cancel
                </Button>
                <Button variant="positive" shape="squared" type="submit">
                  Update
                </Button>
              </div>
            </div>
          </OfferCardForm>
        )}
      </div>
    </div>
  );
};

type HelpOfferMessageButtonProps = {
  interlocutorId?: number;
  userId?: number;
};

const HelpOfferMessageButton = ({
  interlocutorId,
  userId,
}: HelpOfferMessageButtonProps) => {
  const { openMessengerModal } = useOpenMessengerModal();

  if (!interlocutorId || !userId) return null;

  return (
    <IconButton
      variant="transparent-border-beige"
      shape="squared"
      size="medium"
      aria-label="Send message"
      onClick={() => {
        openMessengerModal({
          context: { participants: [userId, interlocutorId] },
        });
      }}
      sx={{ width: "42px", height: "42px" }}
    >
      <MessageIcon style={{ fontSize: "20px" }} />
    </IconButton>
  );
};

type AvatarWrapperProps = {
  children: JSX.Element;
  url?: string;
};

const AvatarWrapper = ({ children, url }: AvatarWrapperProps) => {
  if (!url) {
    return children;
  }

  return <RouterLink to={url}>{children}</RouterLink>;
};
