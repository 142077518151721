import type { MouseEvent } from "react";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Tooltip } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import { ViewBoostModal } from "../job-item/components/view-boost-modal";

import style from "./style.module.scss";

type BoostJobButtonProps = {
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus> | undefined;
  fullWidth?: boolean;
  alreadyBoosted?: boolean;
  boostCredit?: number;
  bidId?: number;
  isOpen?: boolean;
} & Pick<
  ButtonProps,
  "variant" | "sx" | "style" | "className" | "color" | "size" | "shape"
>;

const FREELANCER_STATUSES_TO_SHOW_BOOST_BUTTON: EnumType<
  typeof ENUMS.JobFreelancerStatus
>[] = [
  ENUMS.JobFreelancerStatus.APPLICATION_SENT,
  ENUMS.JobFreelancerStatus.IN_REVIEW,
];

export const BoostJobButton = ({
  fullWidth,
  alreadyBoosted,
  boostCredit,
  bidId,
  isOpen,
  statusForFreelancer,
  ...props
}: BoostJobButtonProps) => {
  if (
    !statusForFreelancer ||
    !FREELANCER_STATUSES_TO_SHOW_BOOST_BUTTON.includes(statusForFreelancer)
  ) {
    return null;
  }

  const isBoostCredit = !!boostCredit;
  const btnText = !!alreadyBoosted ? "Boosted" : "Boost";
  const btnStyle = !!alreadyBoosted
    ? `${style["boosted-button-job"]}`
    : isBoostCredit
      ? `${style["boost-button-job"]}`
      : `${style["inactive-boost-button-job"]}`;

  const btnInActiveStyle = !isOpen
    ? `${style["inactive-boost-button-job"]}`
    : ``;

  const tooltipText = alreadyBoosted
    ? "You’ve boosted this application and this action cannot be undone."
    : isBoostCredit
      ? ""
      : "You don’t have enough Booost credits";

  const btnVariant = "positive";

  const openBoostConfirmationModal = () => {
    if (isOpen && alreadyBoosted) {
      return;
    } else {
      if (boostCredit ?? 0 > 0) {
        if (isOpen) ViewBoostModal(boostCredit ?? 0, bidId ?? -1);
      }
    }
  };

  const handleMouseDown = (e: MouseEvent<HTMLElement>) => {
    if (e.button === 0) return;
  };

  return (
    <>
      <Tooltip
        disabled={alreadyBoosted || (isOpen && !isBoostCredit) ? false : true}
        title={tooltipText}
      >
        <Button
          {...props}
          fullWidth={fullWidth}
          variant={btnVariant}
          disableTouchRipple={
            alreadyBoosted || (!isOpen && isBoostCredit) || !isBoostCredit
          }
          onClick={openBoostConfirmationModal}
          className={!isOpen ? btnInActiveStyle : btnStyle}
          onMouseDown={handleMouseDown}
          rel="noopener noreferrer"
          target="_blank"
          RouterLink={RouterLink}
        >
          <Box className={`${style["boost-button__image"]}`}></Box>
          {btnText}
        </Button>
      </Tooltip>
    </>
  );
};
