import React from "react";

import { FreelancerInvoicesListingPageContent } from "@js/apps/invoices/views/freelancer/content";

import { FreelancerDashboardLayout } from "./layout";

const FreelancerDashboardInvoicesPage = () => (
  <FreelancerDashboardLayout
    pageTitle="Invoices"
    contentClassName="my-jobs-dashboard-layout"
  >
    <FreelancerInvoicesListingPageContent onlyMobileMenu />
  </FreelancerDashboardLayout>
);

export default FreelancerDashboardInvoicesPage;
