import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";

const useIsAllowedToMarkInvoiceAsPaid = () => {
  const user = useUser();

  const isImpersonatedSession = Boolean(user?.is_impersonated_session);
  const isNodeStaff = useIsNodeStaff();

  return isImpersonatedSession || isNodeStaff;
};

export { useIsAllowedToMarkInvoiceAsPaid };
