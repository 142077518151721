import { ApprovedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";

import { EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL } from "../../constants";
import { StatusForm } from "../forms";
import type { EmployerInvoicesFormValues } from "../schema";

type StatusFilterProps = {
  status: EmployerInvoicesFormValues["status"];
  onChange: (status: EmployerInvoicesFormValues["status"]) => void;
};

export const StatusFilter = ({ status, onChange }: StatusFilterProps) => {
  const isAnyStatusApplied = Boolean(status && status.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const handleApply = (newStatus: EmployerInvoicesFormValues["status"]) => {
    onChange(newStatus);
    handleClose();
  };

  return (
    <FilterPopover
      label={EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL.status}
      icon={<StyledApprovedIcon />}
      isFilterApplied={isAnyStatusApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handleClose}
      anchorElement={anchorElement}
      variant="green"
    >
      <StatusForm status={status} onApply={handleApply} />
    </FilterPopover>
  );
};

const StyledApprovedIcon = () => {
  return <ApprovedIcon sx={{ strokeWidth: "2.5px" }} />;
};
