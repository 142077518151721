import type { User } from "@js/types/auth";
import type { InvitedTeamMember } from "@js/types/employer";

import type { ActionType } from "../../actions";
import { ActionMenu } from "../action-menu";
import { TableRowComponent } from "../table-row";

type MemberProps<T> = {
  getActions: (m: T) => ActionType[];
  teamMember: T;
};

type InvitedMemberProps = MemberProps<InvitedTeamMember>;

export const InvitedMember = ({
  teamMember,
  getActions,
}: InvitedMemberProps) => {
  return (
    <TableRowComponent name={teamMember.invitee.email} isInvitation>
      <ActionMenu actions={getActions(teamMember)} id={teamMember.id} />
    </TableRowComponent>
  );
};

type TeamMemberProps = MemberProps<User>;

export const TeamMember = ({ teamMember, getActions }: TeamMemberProps) => {
  return (
    <TableRowComponent
      name={teamMember.public_name}
      canViewInvoices={!!teamMember.can_view_employer_invoices}
    >
      <ActionMenu actions={getActions(teamMember)} id={teamMember.id} />
    </TableRowComponent>
  );
};
