import { HourglassIcon } from "@hexocean/braintrust-ui-components/Icons";

import { FilterButtonPopover } from "./filter-button-popover";
import { EmployerMyTalentRateTypeForm } from "./forms";
import { useFilterPopover } from "./hooks";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentRateTypeFilterPopoverProps = {
  rateTypes: EmployerMyTalentFormValues["rate_types"];
  onChange: (rateTypes: EmployerMyTalentFormValues["rate_types"]) => void;
};

export const MyTalentRateTypeFilterPopover = ({
  rateTypes,
  onChange,
}: MyTalentRateTypeFilterPopoverProps) => {
  const isAnyStatusApplied = Boolean(rateTypes && rateTypes.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const handleApply = (
    newRateTypes: EmployerMyTalentFormValues["rate_types"],
  ) => {
    onChange(newRateTypes);
    handleClose();
  };

  return (
    <FilterButtonPopover
      label="Rate type"
      icon={<HourglassIcon />}
      isFilterApplied={isAnyStatusApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handleClose}
      anchorElement={anchorElement}
    >
      <EmployerMyTalentRateTypeForm
        rateTypes={rateTypes}
        onApply={handleApply}
      />
    </FilterButtonPopover>
  );
};
