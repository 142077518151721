import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { LinkIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIntegrationDashboardModule } from "@js/apps/employer/components/ats-integration-dashboard-module/use-integration-dashboard-module";

import styles from "./styles.module.scss";

export const ATSIntegrationDashboardModule = () => {
  const { handleSyncJobsClick, syncJobsButtonProps } =
    useIntegrationDashboardModule();

  return (
    <div className={styles.module}>
      <Typography variant="title" component="h3" size="small" mb={1}>
        ATS Integration 🔗
      </Typography>
      <Typography variant="paragraph" component="p" size="medium" mb={3}>
        Sync jobs from your ATS platform. Connect with your ATS platform to
        maximize your time and streamline your workflow.
      </Typography>
      <div className={styles.cta}>
        <Button
          variant="primary"
          size="small"
          shape="squared"
          onClick={handleSyncJobsClick}
          {...syncJobsButtonProps}
        >
          <LinkIcon sx={{ mr: 1 }} /> Sync jobs
        </Button>
      </div>
    </div>
  );
};
