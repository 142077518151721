import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ComboBoxMultiple,
  createFilterOptions,
  PaperWithGreenOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import {
  FilterPopoverFooter,
  FilterPopoverFormLayoutWrapper,
} from "@js/apps/employer/components/filter-popover";
import { useInitiallyOpenFilterPopoverDropdown } from "@js/apps/employer/hooks/use-initially-open-filter-popover-dropdown";
import { useGetEmployerJobsMinimalQuery } from "@js/apps/jobs/api";
import type { RhfForm } from "@js/rhf/types";
import type { EmployerJobMinimal } from "@js/types/jobs";

import { employerInvoicesJobsFormSchema } from "../schema";

import { EmployerInvoicesFiltersAutocomplete } from "./employer-invoices-filters-autocomplete";

type FormType = RhfForm<typeof employerInvoicesJobsFormSchema>;
type FormValues = FormType["Values"];
type DefaultFormValues = FormType["DefaultValues"];

type ProjectFormProps = {
  jobs: DefaultFormValues["jobs"];
  onApply: (jobs: FormValues["jobs"]) => void;
  hasFilterPopoverFinishedAnimation: boolean;
};

const jobTitleStartsWithFilter = createFilterOptions<EmployerJobMinimal>({
  trim: true,
  matchFrom: "start",
});

export const ProjectForm = ({
  jobs,
  onApply,
  hasFilterPopoverFinishedAnimation,
}: ProjectFormProps) => {
  const defaultValues = useMemo(() => {
    return { jobs };
  }, [jobs]);
  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerInvoicesJobsFormSchema),
    defaultValues,
  });

  const { field } = useController({
    name: "jobs",
    control: control,
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onApply(values.jobs);
  };

  const { data: jobsData = [] } = useGetEmployerJobsMinimalQuery();

  const onReset = () => {
    reset({ jobs: [] });
  };

  const valueObject = useMemo(() => {
    return jobsData.filter((job) => {
      return field.value?.includes(job.id);
    });
  }, [field.value, jobsData]);

  const isFilterEmpty = !field.value?.length;
  const { autocompleteTextFieldRef } = useInitiallyOpenFilterPopoverDropdown({
    isFilterEmpty,
    hasFilterPopoverFinishedAnimation,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <ComboBoxMultiple
            {...field}
            openOnFocus
            multiple
            value={valueObject}
            ref={null}
            onChange={(_event, values) => {
              field.onChange(
                values.map((value) => {
                  return value.id;
                }),
              );
            }}
            ChipProps={{
              deleteIcon: (
                <CloseIcon sx={{ color: "var(--black) !important" }} />
              ),
            }}
            disableClearable
            component={EmployerInvoicesFiltersAutocomplete}
            PaperComponent={PaperWithGreenOptions}
            initialTaxonomiesLoading={false}
            options={jobsData}
            filterOptions={jobTitleStartsWithFilter}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            getOptionLabel={(job) => {
              return job.title;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option?.title}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autocompleteTextFieldRef}
                  placeholder={isFilterEmpty ? "Select a project" : undefined}
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Select jobs",
                  }}
                />
              );
            }}
          />
        }
        formFooter={<FilterPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
