import { useState } from "react";
import type { DecoratedFormProps } from "redux-form";
import { SubmissionError } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import { bulkSaveTaxonomyItems } from "@js/apps/common/actions";
import { useUser } from "@js/apps/common/hooks";
import type { HistoryWorkFormData } from "@js/apps/freelancer/forms/work-history-form";
import { CommonConfirmationModal, ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";
import { sanitize } from "@js/services";
import type { AppDispatch } from "@js/store";
import type { Company, FreelancerWorkExperience } from "@js/types/freelancer";
import { deepClone, typeGuard } from "@js/utils";

import {
  workExperienceAdded,
  workExperienceDeleted,
  workExperienceEdited,
} from "../../actions";
import {
  useCreateWorkExperienceMutation,
  useDeleteWorkExperienceMutation,
  useGetFreelancerPublicProfileQuery,
  useUpdateWorkExperienceMutation,
} from "../../api";
import { ADD_ANOTHER_ITEM } from "../../constants";

export const useWorkExperience = (persistModalInstance = false) => {
  const [submitType, setSubmitType] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [createWorkExperience] = useCreateWorkExperienceMutation();
  const [updateWorkExperience] = useUpdateWorkExperienceMutation();
  const [deleteWorkExperience] = useDeleteWorkExperienceMutation();
  const freelancerId = useUser()?.freelancer;
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    freelancerId ?? skipToken,
  );

  const onSubmit = async (values: HistoryWorkFormData) => {
    const newValue = deepClone(values);

    if (newValue.new_company) {
      const companyName = getCompanyName(newValue.new_company);
      const companies = (await bulkSaveTaxonomyItems(
        "companies",
        { companies: [{ name: companyName }] },
        "new_company",
      )) as Company[];

      if (!companies || !Array.isArray(companies)) {
        return;
      }

      const company = companies[0];
      if (!company || !typeGuard<unknown, { id: unknown }>(company, "id")) {
        return;
      }

      newValue.new_company = company.id;
    }

    if (values.id) {
      return await updateWorkExperience(newValue)
        .unwrap()
        .then(() => {
          dispatch(workExperienceEdited());
        })
        .catch((error) => {
          throw new SubmissionError(error.data);
        });
    }

    return await createWorkExperience(newValue)
      .unwrap()
      .then(() => {
        dispatch(workExperienceAdded());
      })
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const onSubmitSuccess = (
    _: unknown,
    _methodDispatch: AppDispatch,
    props: DecoratedFormProps<HistoryWorkFormData>,
  ) => {
    if (submitType === ADD_ANOTHER_ITEM) {
      props.reset?.();
      return;
    }

    if (!persistModalInstance) {
      ModalInstance.close();
    }
  };

  const onUpdateJobSuccess = async () => {
    CommonConfirmationModal.close();
  };

  const removeWorkHistory = async (work: { id: number }) => {
    await deleteWorkExperience(work.id).then(() => {
      dispatch(workExperienceDeleted());
    });

    CommonConfirmationModal.close();
  };

  const getInitialValues = (initialWork: FreelancerWorkExperience) =>
    ({
      id: initialWork.id,
      title: initialWork.title,
      new_company: initialWork.company,
      month_from: initialWork.month_from,
      month_to: initialWork.month_to,
      year_from: initialWork.year_from && String(initialWork.year_from),
      year_to: initialWork.year_to && String(initialWork.year_to),
      description: sanitize(initialWork.description, { ALLOWED_TAGS: [] }),
      is_currently_working: !initialWork.year_to && !initialWork.month_to,
    }) as HistoryWorkFormData;

  return {
    freelancerWork: profile?.freelancer_work_experience,
    getInitialValues,
    onSubmit,
    onSubmitSuccess,
    onUpdateJobSuccess,
    removeWorkHistory,
    setSubmitType,
    submitType,
  };
};

const getCompanyName = (company: Company | string | number) => {
  if (typeof company === "string") return company;
  if (typeof company === "object") return company.name;
  return "";
};
