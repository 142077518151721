import { useMemo } from "react";

import {
  useGetL1NavBarColorVariants,
  useJobPagePath,
  useUser,
} from "@js/apps/common/hooks";
import { applySpacesSubElementsToFreelancerNavbar } from "@js/apps/common/hooks/use-freelancer-sidebar/utils";
import { useGetMySpacesQuery } from "@js/apps/spaces/api";
import { useMySpacesSubscribe } from "@js/apps/spaces/hooks/use-my-spaces-subscribe";
import {
  findCurrentNavItem,
  getNavMenuColorVariant,
} from "@js/layouts/app/utils/common-navigation";
import { getFreelancerNavMenuItems } from "@js/layouts/app/utils/freelancer-navigation";

export const useFreelancerSidebar = () => {
  const user = useUser();
  const { data } = useGetMySpacesQuery(undefined, { skip: !user?.is_verified });
  const { jobsPath } = useJobPagePath();
  const L1ColorVariant = useGetL1NavBarColorVariants();
  useMySpacesSubscribe();

  const FREELANCER_ITEMS = useMemo(
    () => getFreelancerNavMenuItems(jobsPath),
    [jobsPath],
  );

  const freelancerSidebarItems = useMemo(() => {
    const FREELANCER_ITEMS_WITH_SPACES_SUBLINKS =
      applySpacesSubElementsToFreelancerNavbar(FREELANCER_ITEMS, data);

    return FREELANCER_ITEMS_WITH_SPACES_SUBLINKS.filter((item) => {
      return item.canAccess;
    });
  }, [FREELANCER_ITEMS, data]);

  const currentLinkItem = findCurrentNavItem({
    items: FREELANCER_ITEMS,
    exact: true,
  });
  const currentLinkVariant = getNavMenuColorVariant(currentLinkItem);

  return {
    sidebarItems: freelancerSidebarItems,
    itemVariant: L1ColorVariant || currentLinkVariant,
  };
};
