import type { WrappedFieldProps } from "redux-form";
import { Field, submit } from "redux-form";

import {
  Box,
  Button,
  Switch,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

import { INTERVIEW_QUESTIONS_FORM_ID } from "../../constants";

import styles from "./style.module.scss";

export type InterviewQuestionsHeaderProps = {
  backLink: string;
  isLoading: boolean;
  userCanManage: boolean;
};

const BackButton = ({ backLink }) => (
  <Button
    variant="transparent"
    shape="squared"
    to={backLink}
    sx={{
      display: "grid",
      placeItems: "center",
      marginRight: 3,
    }}
    startIcon={<CloseIcon />}
    RouterLink={RouterLink}
  >
    Go back
  </Button>
);

export const SubmitButton = ({ disabled = false }: { disabled?: boolean }) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="dark-violet"
      shape="squared"
      sx={{
        display: "grid",
        placeItems: "center",
        width: "160px !important",
        height: "48px",
      }}
      type="submit"
      onClick={() => dispatch(submit(INTERVIEW_QUESTIONS_FORM_ID))}
      disabled={disabled}
    >
      Save interview
    </Button>
  );
};

type AIButtonProps = {
  isLoading: boolean;
  disabled: boolean;
};

const AIButton = ({
  isLoading,
  input,
  disabled,
}: AIButtonProps & WrappedFieldProps) => {
  return (
    <Box display="flex" gap={2} alignItems="center">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          padding: "8px 18px",
          borderRadius: "100px",
          justifyContent: "center",
          alignItems: "center",
          background:
            "linear-gradient(90deg, rgba(212, 228, 249, 0.5) 0.14%, rgba(229, 215, 251, 0.5) 48.5%, rgba(255, 245, 227, 0.5) 76.06%, rgba(255, 253, 189, 0.5) 99.97%)",
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
          alt="ai job"
          width={28}
          style={{ marginLeft: "-3px" }}
        />
        <Typography component="span" fontWeight={500} size="medium">
          AI Interview
        </Typography>
      </Box>
      <Typography component="span" fontWeight={500} size="small">
        is
      </Typography>
      <Switch
        checked={!!input.value}
        onChange={input.onChange}
        disabled={isLoading || disabled}
        label={!!input.value ? "Enabled" : "Disabled"}
      />
    </Box>
  );
};

export const InterviewQuestionsHeader = ({
  backLink,
  isLoading,
  userCanManage,
}: InterviewQuestionsHeaderProps) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#141417",
          display: "flex",
          justifyContent: "center",
          padding: "4px",
        }}
      >
        <Typography
          component="span"
          color="white"
          fontWeight={400}
          size="medium"
        >
          Edit the questions and grading criteria of this AI interview.
        </Typography>
      </Box>
      <Box
        py={2.5}
        px={{ xs: 2, md: 7.5 }}
        borderTop="1px solid var(--grey-4)"
        borderBottom="1px solid var(--grey-4)"
      >
        <Box className={styles.interviewQuestionHeader}>
          <BackButton backLink={backLink} />
          <Field
            name="is_ai_interview_enabled"
            component={AIButton}
            isLoading={isLoading}
            disabled={!userCanManage}
          />
          <SubmitButton disabled={isLoading || !userCanManage} />
        </Box>
      </Box>
    </>
  );
};
