import type { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import type {
  UpdatedEventData,
  VoteAddedEventData,
} from "@js/apps/give-and-get-help/types";
import type {
  IPost,
  ReactionAddedEventData,
  ReactionDeletedEventData,
} from "@js/types/give-and-get-help";
import { findEntity } from "@js/utils";

import { addReaction, deleteReaction } from "../../common";

type Draft = MaybeDrafted<IPost>;

export const updateSinglePost = (
  eventData: Optional<
    UpdatedEventData<IPost>,
    "broadcast_type" | "is_main_post"
  >,
  draft: Draft,
) => {
  if (draft.id !== eventData.data.id) return;

  return { ...draft, ...eventData.data };
};

export const addSinglePostReaction = (
  eventData: ReactionAddedEventData,
  draft: Draft,
) => {
  if (draft.id !== eventData.data.post_id) return;

  draft.reactions = addReaction(draft.reactions, eventData.data);
};

export const deleteSinglePostReaction = (
  eventData: ReactionDeletedEventData,
  draft: Draft,
) => {
  if (draft.id !== eventData.data.post_id) return;

  draft.reactions = deleteReaction(draft.reactions, eventData.data);
};

export const addSinglePostVote = (
  eventData: VoteAddedEventData,
  draft: Draft,
) => {
  if (!draft) return;

  draft.poll_votes_count = (draft.poll_votes_count ?? 0) + 1;

  const votedOption = findEntity(
    draft.poll_options,
    eventData.data.poll_option_id,
  );

  if (!votedOption) return;

  votedOption.votes_count = (votedOption.votes_count ?? 0) + 1;
};
