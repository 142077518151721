import { Field, Fields } from "redux-form";

import {
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { PrimaryRoleCheckboxField } from "@js/apps/freelancer/forms/fields/primary-role-checkbox-field";
import { SaveAndAdd } from "@js/apps/freelancer/forms/fields/save-and-add-field";
import { SelectYourRoleField } from "@js/apps/freelancer/forms/fields/select-role-field";
import { YearsOfExperience } from "@js/apps/freelancer/forms/fields/years-of-experience-field";
import { createFormInstance } from "@js/forms/components";
import type { FreelancerRole } from "@js/types/freelancer";

import { experienceValidator, requiredRole } from "../../forms/validators";
import { useRoleModuleForm } from "../../hooks";

export type RolesFormData = {
  id?: number;
  selected_role?: number;
  years_experience?: number;
  primary?: boolean;
  primary_freelancer_role?: number;
  add_another?: boolean;
};

const RoleModuleFormInstance = createFormInstance<RolesFormData, unknown>(
  "experience-module-form",
);

type RoleModuleFormProps = {
  initialValues?: RolesFormData;
  isEdit?: boolean;
  roles?: FreelancerRole[];
  canSaveAndAddAnother: boolean;
  onCancel: () => void;
  onSave: (addAnother?: boolean) => void;
  persistModalInstance?: boolean;
  saveButtonText?: string;
};

export const RoleModuleForm = ({
  initialValues,
  isEdit,
  roles,
  canSaveAndAddAnother,
  onCancel,
  onSave,
  persistModalInstance,
  saveButtonText,
}: RoleModuleFormProps) => {
  const { handleSave } = useRoleModuleForm({
    onSave,
    isEdit,
    persistModalInstance,
  });

  return (
    <RoleModuleFormInstance
      initialValues={initialValues}
      onSubmit={handleSave}
      className="role-form"
    >
      {({ pristine }) => (
        <>
          {isEdit ? (
            <div className="role-form__edit">
              <IconButton
                variant="tertiary"
                size="x-small"
                aria-label="Edit role"
                onClick={() => onSave()}
              >
                <ArrowLeftIcon />
              </IconButton>
              <Typography component="h3" variant="title" size="small">
                Edit role
              </Typography>
            </div>
          ) : (
            <Typography
              component="p"
              variant="label"
              size="medium"
              mb={3}
              mt={3}
            >
              Add a role
            </Typography>
          )}

          <Field
            component={SelectYourRoleField}
            name="selected_role"
            validate={[requiredRole]}
          />
          <Field
            component={YearsOfExperience}
            name="years_experience"
            normalize={Number}
            validate={[experienceValidator]}
          />

          <Fields
            names={["primary", "primary_freelancer_role"]}
            wasPrimary={initialValues?.primary && isEdit}
            firstRole={initialValues?.primary && !isEdit}
            component={PrimaryRoleCheckboxField}
            roles={roles?.filter((role) => role.id !== initialValues?.id)}
          />

          {!isEdit && !pristine && (
            <Field
              name="add_another"
              canSaveAndAddAnother={canSaveAndAddAnother}
              component={SaveAndAdd}
            />
          )}

          <div className="role-form__actions">
            {!pristine && isEdit && (
              <Button variant="secondary" shape="squared" onClick={onCancel}>
                Discard changes
              </Button>
            )}

            {!pristine && (
              <Button variant="positive" shape="squared" type="submit">
                {saveButtonText || "Save"}
              </Button>
            )}
          </div>
        </>
      )}
    </RoleModuleFormInstance>
  );
};
