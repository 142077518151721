import { API } from "@js/api";
import { postsApi } from "@js/apps/give-and-get-help/api";
import type { GetPostsParams } from "@js/apps/give-and-get-help/types";
import { getAllGetPostsArgs } from "@js/apps/give-and-get-help/utils";
import { patchPostsOnUserDeleted } from "@js/apps/give-and-get-help/utils/update-recipes";
import type { RootState } from "@js/store";
import type { IPost } from "@js/types/give-and-get-help";
import type { SpaceRule, SuggestedSpace } from "@js/types/spaces";
import { combineResults, listenerWrapper, typeGuard } from "@js/utils";

import type {
  AboutThisSpaceFormData,
  AboutThisSpaceProps,
} from "./components/about-this-space/types";
import type {
  AddSpaceRulePayload,
  DeleteSpaceMember,
  DeleteSpaceRulePayload,
  EditSpaceRulePayload,
  GetMySpacesResponse,
  GetReferringUserResponse,
  GetSpaceDetailsResponse,
  GetSpaceMembersParams,
  GetSpaceMembersPublicParams,
  GetSpaceMembersPublicResponse,
  GetSpaceMembersResponse,
  GetSpaceQueryParams,
  GetSpaceRulesResponse,
  GetSpacesParam,
  GetSpacesResponse,
  GetSpaceSuggestedMembersParams,
  GetSpaceSuggestedMembersResponse,
  GetTrendingHashtagsResponse,
  JoinSpacesParams,
  ReorderSpaceRulesPayload,
  SpaceInviteParams,
  UpdateNotification,
  VerifySuggestedEmailParams,
} from "./types";
import {
  filterOutSpace,
  mySpacesUpdateRecipe,
  resetMySpaceNotificationCounter,
} from "./utils";

const spacesApi = API.injectEndpoints({
  endpoints: (build) => ({
    editSpaceDescription: build.mutation<
      AboutThisSpaceFormData,
      AboutThisSpaceProps
    >({
      query: ({ id, description }) => ({
        url: `/spaces/${id}/`,
        method: "PATCH",
        data: { description },
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSpace } = await queryFulfilled;
          dispatch(
            spacesApi.util.updateQueryData(
              "getSpaceDetails",
              { id: _args.id },
              (draft) => {
                draft.description = updatedSpace.description;
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getSpaceTrendingHashtags: build.query<
      GetTrendingHashtagsResponse,
      GetSpaceQueryParams
    >({
      query: ({ id }) => ({
        url: `/hashtags/trending_hashtags?space_id=${id}`,
        method: "GET",
      }),
    }),
    getSuggestedSpaces: build.query<SuggestedSpace[], void>({
      query: () => ({
        url: "/spaces/suggested_spaces",
        method: "GET",
      }),
      providesTags: ["SuggestedSpaces"],
    }),
    getReferringUser: build.query<
      GetReferringUserResponse,
      { referrer: string }
    >({
      query: ({ referrer }) => ({
        url: `/users/public_invitation_details/?referrer=${referrer}`,
        method: "GET",
      }),
    }),
    getInviteRecommendedMembers: build.query<
      GetSpaceSuggestedMembersResponse,
      GetSpaceSuggestedMembersParams
    >({
      query: ({ searchPhrase, spaceId }) => ({
        url: `/spaces/${spaceId}/invitations/suggested_members`,
        method: "GET",
        params: {
          search: searchPhrase,
        },
      }),
    }),
    sendSpaceInvitation: build.mutation<void, SpaceInviteParams>({
      query: ({ emails, spaceId, users }) => ({
        url: `/spaces/${spaceId}/invitations/`,
        method: "POST",
        data: { emails, users },
      }),
    }),
    getSpaces: build.query<GetSpacesResponse, GetSpacesParam>({
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs = { ...queryArgs };
        delete serializeQueryArgs.page;
        delete serializeQueryArgs.page_size;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      query: (params) => ({
        url: `/spaces/`,
        method: "GET",
        params,
      }),
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingCachedPage =
          !!previousArg?.page &&
          !!currentArg?.page &&
          previousArg?.page >= currentArg?.page;

        if (isFetchingCachedPage) {
          return false;
        }
        const paramsKeys: Array<keyof GetPostsParams> = ["page", "page_size"];
        return paramsKeys.some(
          (paramKey) => currentArg?.[paramKey] !== previousArg?.[paramKey],
        );
      },
      merge: (currentCache, newItems) => {
        const combinedPostsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedPostsResults,
        };
      },
      providesTags: ["Spaces"],
    }),
    getMySpaces: build.query<GetMySpacesResponse[], void>({
      query: () => ({
        url: `/spaces/talent_spaces`,
        method: "GET",
      }),
      async onCacheEntryAdded(
        _args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: mySpacesUpdateRecipe,
          listenerId: "my-spaces-listener",
        });
      },
      providesTags: ["MySpaces"],
    }),

    getSpaceDetails: build.query<GetSpaceDetailsResponse, GetSpaceQueryParams>({
      query: ({ id }) => ({
        url: `/spaces/${id}`,
        method: "GET",
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data: spaceDetails } = await queryFulfilled;
          dispatch(
            spacesApi.util.updateQueryData(
              "getMySpaces",
              undefined,
              (draft) => {
                resetMySpaceNotificationCounter(draft, spaceDetails);
              },
            ),
          );
        } catch (error: unknown) {
          const isAccessDenied =
            !!error &&
            typeGuard<object, { error: object }>(error, "error") &&
            error.error &&
            typeGuard<object, { status: unknown }>(error.error, "status") &&
            error.error.status === 403;

          if (isAccessDenied) {
            dispatch(spacesApi.util.invalidateTags(["MySpaces"]));
          }
        }
      },
      providesTags: (_result, _error, arg) => [{ type: "Spaces", id: arg.id }],
    }),

    addSpaceRule: build.mutation<SpaceRule, AddSpaceRulePayload>({
      query: ({ spaceId, rule }) => {
        return {
          url: `/spaces/${spaceId}/rules`,
          method: "POST",
          data: rule,
        };
      },
      invalidatesTags: ["SpaceRules"],
    }),

    editSpaceRule: build.mutation<SpaceRule, EditSpaceRulePayload>({
      query: ({ spaceId, rule, ruleId }) => {
        return {
          url: `/spaces/${spaceId}/rules/${ruleId}`,
          method: "PATCH",
          data: rule,
        };
      },
      invalidatesTags: ["SpaceRules"],
    }),

    deleteSpaceRule: build.mutation<SpaceRule, DeleteSpaceRulePayload>({
      query: ({ spaceId, ruleId }) => {
        return {
          url: `/spaces/${spaceId}/rules/${ruleId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["SpaceRules"],
    }),

    reorderSpaceRules: build.mutation<SpaceRule, ReorderSpaceRulesPayload>({
      query: ({ rulesIds, spaceId }) => {
        return {
          url: `/spaces/${spaceId}/rules/update_positions/`,
          method: "PATCH",
          data: {
            space_rules_in_order: rulesIds,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { spaceId, rulesIds } = args;

        const rulesPatchResult = dispatch(
          spacesApi.util.updateQueryData(
            "getSpaceRules",
            { id: spaceId },
            (draft) => {
              const currentRules = [...draft];
              const reorderedRules = currentRules.sort((a, b) => {
                const indexOfRuleA = rulesIds.indexOf(a.id);
                const indexOfRuleB = rulesIds.indexOf(b.id);

                return indexOfRuleA - indexOfRuleB;
              });

              return reorderedRules;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (e) {
          rulesPatchResult.undo();
        }
      },
    }),

    getSpaceRules: build.query<GetSpaceRulesResponse, GetSpaceQueryParams>({
      query: ({ id }) => ({
        url: `/spaces/${id}/rules`,
        method: "GET",
      }),
      keepUnusedDataFor: 1000 * 60 * 60,
      providesTags: ["SpaceRules"],
    }),
    getSpacesPublicPosts: build.query<IPost[], { id: string }>({
      query: ({ id }) => ({
        url: `/posts_public/?spaces=${id}/`,
        method: "GET",
      }),
    }),
    getSpaceMembers: build.query<
      GetSpaceMembersResponse,
      GetSpaceMembersParams
    >({
      query: ({ id, ...filters }) => ({
        url: `/spaces/${id}/members`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["SpaceMembers"],
    }),
    getSpaceMembersPublic: build.query<
      GetSpaceMembersPublicResponse,
      GetSpaceMembersPublicParams
    >({
      query: ({ id }) => ({
        url: `/spaces/${id}/members/public`,
        method: "GET",
      }),
    }),
    deleteSpaceUser: build.mutation<void, DeleteSpaceMember>({
      query: ({ spaceId, memberId }) => {
        return {
          url: `/spaces/${spaceId}/members/${memberId}/ban`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        "SpaceMembers",
        { type: "Spaces", id: arg.spaceId },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const allPostQueries = getAllGetPostsArgs(state);
        const postsSpaceQuery = allPostQueries.find((queryArg) => {
          return queryArg.space === args.spaceId;
        });
        if (!postsSpaceQuery) {
          return;
        }

        const patch = dispatch(
          postsApi.util.updateQueryData(
            "getPosts",
            postsSpaceQuery,
            (draft) => {
              patchPostsOnUserDeleted({ draft, userId: args.memberId });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (e) {
          patch.undo();
        }
      },
    }),
    leaveSpace: build.mutation<void, DeleteSpaceMember>({
      query: ({ spaceId, memberId }) => {
        return {
          url: `/spaces/${spaceId}/members/${memberId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "SpaceMembers",
        "MySpaces",
        "Spaces",
        "SuggestedSpaces",
        "FreelancerPublicProfile",
      ],
    }),
    joinSpaces: build.mutation<void, JoinSpacesParams>({
      query: ({ spaceId }) => ({
        url: `/spaces/${spaceId}/members/`,
        method: "POST",
      }),
      invalidatesTags: [
        "SpaceMembers",
        "MySpaces",
        "Spaces",
        "SuggestedSpaces",
        "FreelancerPublicProfile",
      ],
      onCacheEntryAdded: async (
        { spaceId },
        { dispatch, getState, cacheDataLoaded, cacheEntryRemoved },
      ) => {
        try {
          await cacheDataLoaded;

          const state = getState() as RootState;

          const allGetSpacesQueries = spacesApi.util.selectInvalidatedBy(
            state,
            ["Spaces"],
          );

          allGetSpacesQueries.forEach(({ endpointName, originalArgs }) => {
            if (endpointName === "getSpaces") {
              dispatch(
                spacesApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (draft) => {
                    filterOutSpace(draft, spaceId);
                  },
                ),
              );
            }
          });
        } catch (error) {
          console.error(error);
        }

        await cacheEntryRemoved;
      },
    }),
    updateNotificationSettings: build.mutation<void, UpdateNotification>({
      query: ({ spaceId, memberId, notificationEnabled }) => ({
        url: `/spaces/${spaceId}/members/${memberId}`,
        method: "PUT",
        data: { notifications_enabled: notificationEnabled },
      }),
      invalidatesTags: ["MySpaces"],
    }),
    verifyEmailInvitation: build.query<
      { already_invited: boolean },
      VerifySuggestedEmailParams
    >({
      query: ({ spaceId, email }) => ({
        url: `/spaces/${spaceId}/invitations/verify_email/`,
        method: "POST",
        data: { email },
      }),
    }),
  }),
});

export const {
  useAddSpaceRuleMutation,
  useEditSpaceRuleMutation,
  useDeleteSpaceRuleMutation,
  useReorderSpaceRulesMutation,
  useEditSpaceDescriptionMutation,
  useGetSuggestedSpacesQuery,
  useGetSpaceMembersQuery,
  useGetSpaceMembersPublicQuery,
  useGetSpaceTrendingHashtagsQuery,
  useGetReferringUserQuery,
  useGetSpacesQuery,
  useGetSpaceDetailsQuery,
  useGetSpaceRulesQuery,
  useGetSpacesPublicPostsQuery,
  useGetInviteRecommendedMembersQuery,
  useSendSpaceInvitationMutation,
  useGetMySpacesQuery,
  useJoinSpacesMutation,
  useDeleteSpaceUserMutation,
  useLeaveSpaceMutation,
  useUpdateNotificationSettingsMutation,
  useLazyVerifyEmailInvitationQuery,
} = spacesApi;
