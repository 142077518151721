import { useEffect, useRef } from "react";
import type { Location, NavigationType } from "react-router-dom";
import { useLocation, useNavigationType } from "react-router-dom";

export const useUpdateScrollPosition = () => {
  const location = useLocation();
  const previousLocationRef = useRef(location);
  const navigationType = useNavigationType();
  useEffect(() => {
    const { hash, state, search } = location;
    const previousLocation = previousLocationRef.current;
    previousLocationRef.current = location;
    if (state?.noScroll) {
      return;
    }

    if (hash) {
      return createFindAndScrollToElementAttempt(0, hash);
    }

    if (
      shouldRestoreScrollPosition({
        location,
        previousLocation,
        navigationType,
      })
    ) {
      return;
    }

    const behaviorType = getBehaviorType(search);

    return scrollToTop(0, behaviorType);
  }, [location, navigationType, previousLocationRef]);
};

const MAX_ATTEMPTS = 4;

let scrollToTopTimeout: number;
const scrollToTop = (attemptNo: number, behaviorType: ScrollBehavior) => {
  const callback = () => {
    const isModalBackdropPresent =
      !!document.getElementsByClassName("modal-backdrop").length;

    if (!isModalBackdropPresent) {
      window?.scrollTo({
        top: 0,
        left: 0,
        behavior: behaviorType,
      });

      return;
    }

    const hasReachedMaxAttempt = attemptNo >= MAX_ATTEMPTS;
    if (hasReachedMaxAttempt) {
      return;
    }
    scrollToTop(attemptNo + 1, behaviorType);
  };

  if (attemptNo === 0) {
    // do not queue, call immediately instead
    callback();
  } else {
    scrollToTopTimeout = window.setTimeout(callback, attemptNo * 100);
  }

  return () => {
    clearTimeout(scrollToTopTimeout);
  };
};

const earnRegex = /^\/talent\/dashboard\/earn\/?/;
const spaceRegex = /^\/spaces\/\d+\//;
const earnAndReferTabsRegex = /^\/talent\/dashboard\/earn\//;
const myWalletRegex = /^\/talent\/dashboard\/my_wallet\/?$/;
const proposalsPageRegex = /^\/jobs\/\d+\/proposals\/?$/;
const employerMyTalentRegex = /^\/employer\/dashboard\/my_talent/;

const pagesWithTabsRegexpList = [earnRegex, spaceRegex, earnAndReferTabsRegex];
const pagesWithRefetchOnSearchChange = [
  spaceRegex,
  myWalletRegex,
  earnAndReferTabsRegex,
  employerMyTalentRegex,
];
const pagesWithCustomScroll = [proposalsPageRegex];

const shouldRestoreScrollPosition = ({
  location,
  previousLocation,
  navigationType,
}: {
  location: Location;
  previousLocation: Location;
  navigationType: NavigationType;
}) => {
  if (navigationType === "POP") {
    return true;
  }

  const { pathname, search } = location;
  const { pathname: previousPathname, search: previousSearch } =
    previousLocation;

  const isSearchChange =
    search !== previousSearch && pathname === previousPathname;
  const isSearchChangeOnTheSamePage =
    isSearchChange &&
    pagesWithRefetchOnSearchChange.some((regexp) => regexp.test(pathname));

  if (isSearchChangeOnTheSamePage) {
    return true;
  }

  const currentPage = new URLSearchParams(search).get("page");
  const previousPage = new URLSearchParams(previousSearch).get("page");
  const isPageChange = currentPage !== previousPage;
  const isPageWithCustomScroll =
    isPageChange &&
    pagesWithCustomScroll.some((regexp) => regexp.test(pathname));

  if (isPageWithCustomScroll) {
    return true;
  }

  const isPathChange = pathname !== previousPathname;
  const isTabChangeOnTheSamePage =
    isPathChange &&
    pagesWithTabsRegexpList.some(
      (regexp) => regexp.test(pathname) && regexp.test(previousPathname),
    );

  return isTabChangeOnTheSamePage;
};

let findAndScrollToElementAttemptTimeout: number;
const createFindAndScrollToElementAttempt = (
  attemptNo: number,
  hash: string,
) => {
  if (!hash) {
    return;
  }

  findAndScrollToElementAttemptTimeout = window.setTimeout(() => {
    // use of getElementById in favor of querySelector is purposeful, as some hashes might not be a valid selector
    const element = document.getElementById(hash.slice(1));

    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 130,
        behavior: "smooth",
      });
    } else if (attemptNo < MAX_ATTEMPTS - 1) {
      createFindAndScrollToElementAttempt(attemptNo + 1, hash);
    }
  }, attemptNo * 500);

  return () => {
    clearTimeout(findAndScrollToElementAttemptTimeout);
  };
};

const getBehaviorType = (urlSearch: string) => {
  return urlSearch ? "smooth" : "auto";
};
