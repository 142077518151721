import { useMemo } from "react";
import type { FormSubmitHandler } from "redux-form";
import { SubmissionError } from "redux-form";

import { useUser } from "@js/apps/common/hooks";
import { skillsSaved } from "@js/apps/freelancer/actions";
import { useEditFreelancerSkillsMutation } from "@js/apps/freelancer/api";
import { useFreelancerPublicProfile } from "@js/apps/freelancer/hooks";
import { useAppDispatch } from "@js/hooks";
import type { FreelancerSkill } from "@js/types/freelancer";
import type { Role } from "@js/types/roles";
import { typeGuard } from "@js/utils";

const defaultFreelancerSkills: FreelancerSkill[] = [];

export type SkillsFormData = {
  new_skills: Pick<FreelancerSkill, "id" | "skill" | "is_superpower">[];
  role: Role | undefined;
};

export const useSkillsForm = () => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const [editFreelancerSkills] = useEditFreelancerSkillsMutation();
  const { profile: freelancerPublicProfile } = useFreelancerPublicProfile(
    user?.freelancer,
  );

  const freelancerSkills =
    freelancerPublicProfile?.freelancer_skills ?? defaultFreelancerSkills;

  const freelancerRoles = freelancerPublicProfile?.roles;

  const freelancerMainRole = useMemo(
    () =>
      !!freelancerRoles?.length
        ? freelancerRoles.find((role) => role.primary)?.role ||
          freelancerRoles[0].role
        : undefined,
    [freelancerRoles],
  );

  const initialValues: SkillsFormData = useMemo(() => {
    return {
      role: freelancerMainRole,
      new_skills: freelancerSkills,
    };
  }, [freelancerMainRole, freelancerSkills]);

  const onSubmit: FormSubmitHandler<SkillsFormData> = (
    values,
    _dispatch,
    props,
  ) => {
    const freelancerId = user?.freelancer;
    if (!freelancerId) {
      return;
    }

    const editFreelancerSkillsArg = {
      id: freelancerId,
      new_skills: values.new_skills.map((skill) => {
        return {
          is_superpower: skill.is_superpower,
          new_skill: skill.skill.id,
        };
      }),
    };
    return editFreelancerSkills(editFreelancerSkillsArg)
      .unwrap()
      .then(() => {
        dispatch(
          skillsSaved(
            values.new_skills.map((skill) => {
              return {
                name: skill.skill.name,
                categories: skill.skill.categories,
                is_superpower: skill.is_superpower,
              };
            }),
          ),
        );

        props.reset?.();
      })
      .catch((error: unknown) => {
        const errorToThrow =
          error && typeGuard<unknown, { data: object }>(error, "data")
            ? error.data
            : {};

        throw new SubmissionError(errorToThrow);
      });
  };

  return {
    initialValues,
    onSubmit,
  };
};
