import { JOB_HAS_BEEN_SAVED_AS_FAVORITE } from "@js/apps/bookmarks/actions";
import {
  CLICK_APPLY_AFTER_SEARCH,
  EXPAND_DETAILS_AFTER_SEARCH,
  JOB_DETAILS_VISITED,
  JOB_FILTER_SAVED,
  JOB_ITEM_HAS_BEEN_EXPANDED,
  JOB_TITLE_CLICKED_AFTER_SEARCH,
  UNAUTHENTICATED_USER_JOB_APPLY_CLICKS,
  VIEW_JOB_CLICKED_AFTER_SEARCH,
  VIEW_JOB_CONFIRMATION,
  VIEW_JOB_LINK_CLICKED,
} from "@js/apps/jobs/action-types";
import type { JobAfterSearchPayload } from "@js/apps/jobs/actions";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { jobListingToLegacySearch } from "@js/middlewares/analytics/user-tracking/helpers";
import { getKeysOfAppliedURLParams } from "@js/middlewares/analytics/utils";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleJobsAnalyticsActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case VIEW_JOB_CONFIRMATION: {
      logEvent(Events.VIEW_JOB_CONFIRMATION);
      break;
    }

    case JOB_DETAILS_VISITED: {
      const { source, jobUrl, jobId } = action.payload;
      logEvent(Events.OUTBOUND_VIEW_JOB_DETAILS, {
        [EventsProperties.source]: source,
        [EventsProperties.url]: jobUrl,
        [EventsProperties.job_id]: jobId,
      });
      break;
    }

    case VIEW_JOB_LINK_CLICKED: {
      const { source, search } = action.payload;
      const appliedFilters = getKeysOfAppliedURLParams(search);
      logEvent(Events.VIEW_JOB_DETAILS, {
        [EventsProperties.source]: source,
        [EventsProperties.filter_applied_type]: appliedFilters,
      });
      break;
    }

    case JOB_ITEM_HAS_BEEN_EXPANDED: {
      const { context, search } = action.payload;
      const appliedFilters = getKeysOfAppliedURLParams(search);

      logEvent(Events.VIEW_JOB_CARD_EXPANDED_VIEW, {
        [EventsProperties.context]: context,
        [EventsProperties.filter_applied_type]: appliedFilters,
      });
      break;
    }

    case JOB_HAS_BEEN_SAVED_AS_FAVORITE: {
      const { savedJob, search, appendFilters } = action.payload;
      const properties = appendFilters
        ? {
            [EventsProperties.job_id]: savedJob.job,
            [EventsProperties.filter_applied_type]:
              getKeysOfAppliedURLParams(search),
          }
        : { [EventsProperties.job_id]: savedJob.job };

      logEvent(Events.JOB_SAVED, properties);
      break;
    }

    case UNAUTHENTICATED_USER_JOB_APPLY_CLICKS: {
      const { jobId } = action.payload;
      logEvent(Events.UNAUTHENTICATED_USER_JOB_APPLY_CLICKS, {
        job_id: jobId,
      });
      break;
    }

    case JOB_FILTER_SAVED: {
      logEvent(Events.FILTER_SAVED);
      break;
    }

    case Events.BACK_TO_FIRST_JOB_POSTING_SCREEN: {
      logEvent(Events.BACK_TO_FIRST_JOB_POSTING_SCREEN, action.payload);
      break;
    }

    case Events.BACK_TO_SECOND_JOB_POSTING_SCREEN: {
      logEvent(Events.BACK_TO_SECOND_JOB_POSTING_SCREEN, action.payload);
      break;
    }

    case Events.CLICK_CREATE_JOB_HOME_DASHBOARD: {
      logEvent(Events.CLICK_CREATE_JOB_HOME_DASHBOARD);
      break;
    }

    case Events.CLICK_CREATE_JOB_MY_JOBS: {
      logEvent(Events.CLICK_CREATE_JOB_MY_JOBS);
      break;
    }

    case Events.CLICK_UPLOAD_JD: {
      logEvent(Events.CLICK_UPLOAD_JD);
      break;
    }

    case Events.CLICK_POST_JOB: {
      logEvent(Events.CLICK_POST_JOB, action.payload);
      break;
    }

    case Events.CLICK_REVIEW_JOB: {
      logEvent(Events.CLICK_REVIEW_JOB, action.payload);
      break;
    }

    case Events.CLICK_SET_UP_NEW_JOB: {
      logEvent(Events.CLICK_SET_UP_NEW_JOB, action.payload);
      break;
    }

    case Events.DELETE_JOB_DRAFT_CREATION_FLOW: {
      logEvent(Events.DELETE_JOB_DRAFT_CREATION_FLOW, action.payload);
      break;
    }

    case CLICK_APPLY_AFTER_SEARCH: {
      const { jobId, resultPosition, location, searchQueryId } =
        action.payload as JobAfterSearchPayload;
      logEvent(Events.CLICK_APPLY_SEARCH, {
        [EventsProperties.job_id]: jobId,
        [EventsProperties.result_position]: resultPosition,
        [EventsProperties.job_location]: jobListingToLegacySearch(location),
        [EventsProperties.search_query_id]: searchQueryId,
      });

      break;
    }

    case VIEW_JOB_CLICKED_AFTER_SEARCH: {
      const { jobId, resultPosition, location, searchQueryId } =
        action.payload as JobAfterSearchPayload;

      logEvent(Events.CLICK_VIEW_JOB_SEARCH, {
        [EventsProperties.job_id]: jobId,
        [EventsProperties.result_position]: resultPosition,
        [EventsProperties.job_location]: jobListingToLegacySearch(location),
        [EventsProperties.search_query_id]: searchQueryId,
      });
      break;
    }

    case JOB_TITLE_CLICKED_AFTER_SEARCH: {
      const { jobId, resultPosition, location, searchQueryId } =
        action.payload as JobAfterSearchPayload;
      logEvent(Events.CLICK_JOB_TITLE_SEARCH, {
        [EventsProperties.job_id]: jobId,
        [EventsProperties.result_position]: resultPosition,
        [EventsProperties.job_location]: jobListingToLegacySearch(location),
        [EventsProperties.search_query_id]: searchQueryId,
      });

      break;
    }

    case EXPAND_DETAILS_AFTER_SEARCH: {
      const { jobId, resultPosition, location, searchQueryId } =
        action.payload as JobAfterSearchPayload;
      logEvent(Events.CLICK_EXPAND_DETAILS_SEARCH, {
        [EventsProperties.job_id]: jobId,
        [EventsProperties.result_position]: resultPosition,
        [EventsProperties.job_location]: jobListingToLegacySearch(location),
        [EventsProperties.search_query_id]: searchQueryId,
      });

      break;
    }

    case Events.CLICK_EVENT_CREATE_CUSTOM: {
      logEvent(Events.CLICK_EVENT_CREATE_CUSTOM);
      break;
    }

    case Events.CLICK_EVENT_CREATE_AI: {
      logEvent(Events.CLICK_EVENT_CREATE_AI);
      break;
    }

    case Events.CLICK_EVENT_CREATE_UPLOAD: {
      logEvent(Events.CLICK_EVENT_CREATE_UPLOAD);
      break;
    }

    case Events.CLICK_ADD_TEAM_MEMBERS: {
      logEvent(Events.CLICK_ADD_TEAM_MEMBERS);
      break;
    }

    case Events.CLICK_JOB_CARD_BROWSE_JOBS: {
      logEvent(Events.CLICK_JOB_CARD_BROWSE_JOBS);
      break;
    }

    default: {
      break;
    }
  }
};
