import type { ReactNode } from "react";

import { useEmployerInvoicesTableContext } from "../employer-invoices-table-context";

import { CheckboxCellSelectionLimitWrapper } from "./checkbox-cell-selection-limit-wrapper";

type CheckboxHeaderCellProps = {
  children: ReactNode;
};

export const CheckboxHeaderCell = ({ children }: CheckboxHeaderCellProps) => {
  const { isSelectionLimitReached, currentPageHasSelectedInvoices } =
    useEmployerInvoicesTableContext();

  const showSelectionLimitReachedTooltip =
    isSelectionLimitReached && !currentPageHasSelectedInvoices;
  if (showSelectionLimitReachedTooltip) {
    return (
      <CheckboxCellSelectionLimitWrapper>
        {children}
      </CheckboxCellSelectionLimitWrapper>
    );
  }

  return children;
};
