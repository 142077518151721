import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { matchPath } from "react-router-dom";
import { formValueSelector, SubmissionError } from "redux-form";

import { setPostsToShowPulseAnimation } from "@js/apps/common/actions";
import { freddyWidgetManager } from "@js/apps/common/services/freddy-feedback";
import { finishCreatePost } from "@js/apps/give-and-get-help/actions";
import { POST_FIELD_POLL_OPTIONS } from "@js/apps/give-and-get-help/components/create-poll/constants";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "@js/apps/give-and-get-help/form/constants";
import { useGetSpaceDetailsQuery } from "@js/apps/spaces/api";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { SEARCH_PARAMS } from "@js/routes/constants";
import type { AppDispatch } from "@js/store";
import type { IPost, PollOption } from "@js/types/give-and-get-help";
import { pluralize } from "@js/utils";

import { useAddPostMutation, useUpdatePostMutation } from "../../api";
import { closeCreatePostModal } from "../../components/create-or-edit-post-modal/modal/close-modal";
import { EditPostModalInstance } from "../../components/create-or-edit-post-modal/modal/modal-instances";
import { useEtiquette } from "../../components/etiquette-side-panel/hook";
import { showSuccessToast } from "../../components/post-created-notification";
import { FEED_MAIN_PAGE_URL } from "../../constants";
import type { CreateOrEditPostFormData } from "../../form";
import { preparePostSubmitValues } from "../../form/utils";
import { getInitialExcludedLinks } from "../../forms/fields/links-from-text-adapter/helpers";
import type { AddPostParams, UpdatePostPropsParams } from "../../types";
import { filterLinks, getInitialBudget } from "../../utils";

export const useCreateOrEditPost = (initialValues?: Partial<IPost>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [createPost] = useAddPostMutation();
  const [updatePost] = useUpdatePostMutation();
  const composerLocation = usePostLocationContext();
  const { closeEtiquettePanel } = useEtiquette();

  const { data: spaceData } = useGetSpaceDetailsQuery(
    {
      id: Number(initialValues?.space),
    },
    {
      skip: !initialValues?.space,
    },
  );

  const linksAddedViaButton = useMemo(() => {
    return initialValues
      ? filterLinks(initialValues, { isAddedByButton: true })
      : [];
  }, [initialValues]);
  const linksAddedFromText = useMemo(() => {
    return initialValues
      ? filterLinks(initialValues, { isAddedByButton: false })
      : [];
  }, [initialValues]);

  const mappedInitialValues = useMemo(() => {
    if (!initialValues) {
      return;
    }

    const linksFromTextInitialValue = linksAddedFromText.map(
      (link) => link.url,
    );
    const excludedLinksFromTextInitialValue = getInitialExcludedLinks({
      textValue: initialValues.text,
      linksFromTextValue: linksFromTextInitialValue,
    });
    return {
      ...initialValues,
      budget: getInitialBudget(initialValues.budget),
      category: initialValues.category?.id,
      links_metadata: linksAddedViaButton.map((link) => ({
        ...link,
        id: link.id || link.url,
        isFromText: false,
      })),
      links_metadata_from_text: linksAddedFromText.map((link) => ({
        ...link,
        id: link.id || link.url,
        isFromText: true,
      })),
      links_from_text: linksAddedFromText.map((link) => link.url),
      excluded_links_from_text: excludedLinksFromTextInitialValue,
      links: linksAddedViaButton.map((link) => {
        return {
          value: link.url,
          id: link.id || link.url,
          isFromText: false,
        };
      }),
    };
  }, [initialValues, linksAddedViaButton, linksAddedFromText]);

  const handleNewPostSubmit = async (values: CreateOrEditPostFormData) => {
    try {
      const preparedValues = initialValues?.space
        ? {
            ...preparePostSubmitValues<AddPostParams>(values),
            space: initialValues.space,
          }
        : preparePostSubmitValues<AddPostParams>(values);
      const post = await createPost(preparedValues).unwrap();

      const spaceUrl = `/spaces/${post.space}/`;

      dispatch(closeCreatePostModal({ composer_location: composerLocation }));
      dispatch(finishCreatePost({ composer_location: composerLocation }));

      showSuccessToast({
        post,
        spaceName: spaceData?.name,
        composerLocation,
        bgcolor: "var(--medium-green)",
      });

      dispatch(setPostsToShowPulseAnimation([post.id]));

      if (post?.is_user_first_post) {
        freddyWidgetManager.showFreddyWidget(
          SETTINGS.FREDDY_FEEDBACK_POST_WIDGET_ID,
        );
      }

      if (post.space && !matchPath(spaceUrl + "discussion", pathname)) {
        return navigate(spaceUrl);
      }

      if (!matchPath(FEED_MAIN_PAGE_URL, pathname) && !post.space) {
        // when created from single post view - redirect to newly created post
        navigate(FEED_MAIN_PAGE_URL + "/" + post.id);
      }
    } catch (error: any) {
      throw new SubmissionError(error.data);
    }
  };

  const handleUpdatePostSubmit = async (values: CreateOrEditPostFormData) => {
    try {
      const preparedValues =
        preparePostSubmitValues<UpdatePostPropsParams>(values);
      const post = await updatePost({
        ...preparedValues,
      }).unwrap();

      EditPostModalInstance.close();

      showSuccessToast({
        post,
        spaceName: spaceData?.name,
        header: "Your post is successfully updated 🙌",
        bgcolor: "var(--medium-yellow)",
      });
    } catch (error: any) {
      throw new SubmissionError(error.data);
    }
  };

  const onSubmitSuccess = (
    _result: null,
    _dispatch: AppDispatch,
    props: { reset: () => void },
  ) => {
    if (props.reset) props.reset();
  };

  const handleSubmit = async (values: CreateOrEditPostFormData) => {
    if (values.id) {
      await handleUpdatePostSubmit(values);
    } else {
      await handleNewPostSubmit(values);
      closeEtiquettePanel();
    }
  };

  return {
    handleSubmit,
    mappedInitialValues,
    onSubmitSuccess,
  };
};

const createPostFormSelector = formValueSelector(CREATE_POST_FORM_ID);
const editPostFormSelector = formValueSelector(EDIT_POST_FORM_ID);
const MIN_POLL_OPTIONS = 2;
const MAX_POLL_OPTIONS = 3;
const defaultPollOptions: PollOption[] = [];

export const usePostValidation = ({
  isEditing,
}: {
  isEditing: boolean | undefined;
}) => {
  const pollOptions = useAppSelector((state) => {
    const selector = isEditing ? editPostFormSelector : createPostFormSelector;
    return selector(state, POST_FIELD_POLL_OPTIONS) || defaultPollOptions;
  });

  const pollOptionsNotEmpty = useMemo(() => {
    return pollOptions.filter((pollOption: PollOption) =>
      pollOption.text?.trim(),
    );
  }, [pollOptions]);

  return {
    pollOptions: {
      MIN_POLL_OPTIONS,
      MAX_POLL_OPTIONS,
      errors: {
        minNumberOfValues: `You need to add more than
        ${MIN_POLL_OPTIONS - 1} option${pluralize(MIN_POLL_OPTIONS - 1)}`,
        maxNumberOfValues: `You can't add more than ${MAX_POLL_OPTIONS} options`,
      },
      shouldValidate: !!pollOptionsNotEmpty.length,
    },
  };
};

export const useNewPostModalVisibleURL = (): {
  isModalVisibleUrlParam: boolean;
  addModalVisibleUrlParam: () => void;
  removeModalVisibleUrlParam: () => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isParamTrue = useCallback((): boolean => {
    return (
      searchParams
        .get(SEARCH_PARAMS.POST_MODAL_VISIBLE)
        ?.toLocaleLowerCase() === "true"
    );
  }, [searchParams]);
  const addModalVisibleUrlParam = useCallback((): void => {
    if (isParamTrue()) return;
    searchParams.set(SEARCH_PARAMS.POST_MODAL_VISIBLE, "True");
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams, isParamTrue]);

  const removeModalVisibleUrlParam = useCallback((): void => {
    searchParams.delete(SEARCH_PARAMS.POST_MODAL_VISIBLE);
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  return {
    isModalVisibleUrlParam: isParamTrue(),
    addModalVisibleUrlParam,
    removeModalVisibleUrlParam,
  };
};
