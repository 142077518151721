import { Box } from "@hexocean/braintrust-ui-components";

import { RoleModal } from "../../role-module/role-modal";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const Role = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <RoleModal persistModalInstance saveButtonText="Save and continue" />
    </Box>
  );
};
