import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import type { BoxProps, GridSpacing } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Grid,
  IconButton,
  SwipeableDrawer,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { MenuIcon } from "@hexocean/braintrust-ui-components/Icons";
import { UserSidebar } from "@js/apps/common/components/user-sidebar";
import { useUser } from "@js/apps/common/hooks/use-user";
import { freddyWidgetManager } from "@js/apps/common/services/freddy-feedback";
import { CompleteProfileTopBar } from "@js/apps/dashboard/components/complete-profile-bar";
import { PaymentMethodFailedTopBar } from "@js/apps/employer/components/payment-method-failed-top-bar";
import { NavigationProfileCompletionNudgeCard } from "@js/apps/freelancer/components";
import { UniversalSearchForm } from "@js/apps/universal-search";
import { Logo } from "@js/components/logo";
import { useAppSelector } from "@js/hooks";
import type { User } from "@js/types/auth";

import { CoreLayout } from "../core";

import { useAlertsPortal } from "./hooks/use-alerts-portal";
import { COMMON_Z_INDEX, HEADER_HEIGHT } from "./constants";
import Footer from "./footer";
import { LeftColumn } from "./left-column";
import { Navbar } from "./navbar";

export type OverwriteContentComponentProps = {
  alertsElement: JSX.Element;
  headerBoxProps: {
    top: number;
    zIndex: number;
  };
  contentBoxProps: {
    className: string;
    overflow: string;
  };
};

export type AppLayoutProps = {
  children: React.ReactNode;
  className?: string;
  coreClassName?: string;
  /**
   *  By default, all immediate children are aligned in row (inline), you can change this
   *  behavior by setting this to true (then all immediate children will be distributed
   *  in new lines).
   */
  flexColumn?: boolean;
  hideHeader?: boolean;
  hideMenu?: boolean;
  showFooter?: boolean;
  spacing?: GridSpacing;
  pageTitle?: string;
  showMenuToggleAllTheTime?: boolean;
  bgcolor?: string;
  OverwriteContentComponent?: (
    props: OverwriteContentComponentProps,
  ) => JSX.Element;
  renderOverwriteNavigationComponent?: (
    props: TopNavigationProps,
  ) => JSX.Element;
  disableSubheaderPortal?: boolean;
  /**
   *  Used on pages with virtualization to fix the issue of the white background flickering
   */
  setBgOnBody?: boolean;
};

export const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = ({
  children,
  className,
  coreClassName,
  flexColumn,
  spacing = 0,
  hideHeader,
  hideMenu,
  showFooter,
  showMenuToggleAllTheTime,
  bgcolor,
  OverwriteContentComponent,
  renderOverwriteNavigationComponent,
  disableSubheaderPortal,
  setBgOnBody,
  ...rest
}) => {
  const location = useLocation();
  const showLeftNavGlobal = useAppSelector(
    (state) => state.common.showLeftSidebar,
  );
  const layoutBgColor = useAppSelector((state) => state.common.layoutBgColor);

  const user = useUser();

  const [open, setOpen] = useState(false);

  const handleCloseDrawer = () => setOpen(false);

  const isTablet = useMediaQuery("md");

  const pageBackgroundColor = bgcolor || layoutBgColor;

  const sidebar = user ? <UserSidebar /> : null;

  const showLeftNav =
    !!user &&
    !hideMenu &&
    !isTablet &&
    !showMenuToggleAllTheTime &&
    showLeftNavGlobal;

  useEffect(() => {
    if (user?.id) {
      freddyWidgetManager.embedCrossPageFreddyWidget();
    }
  }, [location.pathname, user?.id]);

  useLayoutEffect(() => {
    if (!pageBackgroundColor || !setBgOnBody) {
      return;
    }

    document.body.style.setProperty("--page-bg", pageBackgroundColor);
    return () => {
      document.body.style.removeProperty("--page-bg");
    };
  }, [setBgOnBody, pageBackgroundColor]);

  return (
    <CoreLayout {...rest} className={classNames("app-layout", coreClassName)}>
      <CompleteProfileTopBar />
      <PaymentMethodFailedTopBar />
      {sidebar && (
        <NavDrawer
          leftColumnBgColor={pageBackgroundColor}
          sidebar={sidebar}
          setClose={handleCloseDrawer}
          open={open}
        />
      )}
      <Box display="flex" flexGrow={1} bgcolor={pageBackgroundColor}>
        {showLeftNav && sidebar && (
          <LeftColumn bgcolor={pageBackgroundColor} sidebar={sidebar} />
        )}
        <Box display="flex" minWidth={0} flexDirection="column" flexGrow={1}>
          {!hideHeader &&
            (renderOverwriteNavigationComponent ? (
              renderOverwriteNavigationComponent({
                isTablet,
                hideMenu,
                showMenuToggleAllTheTime,
                user,
                setOpen,
                disableSubheaderPortal,
              })
            ) : (
              <TopNavigationComponent
                bgcolor={bgcolor}
                isTablet={isTablet}
                hideMenu={hideMenu}
                showMenuToggleAllTheTime={showMenuToggleAllTheTime}
                user={user}
                setOpen={setOpen}
                disableSubheaderPortal={disableSubheaderPortal}
              />
            ))}
          {OverwriteContentComponent ? (
            <Box flexGrow={1}>
              <OverwriteContentComponent
                contentBoxProps={{
                  className: classNames("app-layout-content", className),
                  overflow: "hidden",
                }}
                headerBoxProps={{
                  top: HEADER_HEIGHT,
                  zIndex: COMMON_Z_INDEX - 1,
                }}
                alertsElement={<AlertsPortalTarget />}
              />
            </Box>
          ) : (
            <Box flexGrow={1} overflow="hidden">
              <AlertsPortalTarget />
              <Grid
                container
                spacing={spacing}
                className={classNames("app-layout-content", className)}
                style={{
                  flexDirection: flexColumn ? "column" : "row",
                  flexWrap: flexColumn ? "nowrap" : undefined,
                }}
              >
                {children}
              </Grid>
            </Box>
          )}
          {!!showFooter && <Footer />}
        </Box>
      </Box>
    </CoreLayout>
  );
};

const AlertsPortalTarget = React.memo(() => {
  const alertsPortalRef = useRef<HTMLDivElement>();
  const alertsHeight = useAlertsPortal(alertsPortalRef);

  return (
    <Box
      id="alerts-portal"
      ref={alertsPortalRef}
      zIndex={COMMON_Z_INDEX}
      overflow="hidden"
      position="sticky"
      height={alertsHeight}
    />
  );
});

export type TopNavigationProps = BoxProps & {
  bgcolor?: string;
  isTablet: boolean;
  hideMenu?: boolean;
  showMenuToggleAllTheTime?: boolean;
  user: User | null;
  setOpen: (val: boolean) => void;
  disableSubheaderPortal?: boolean;
  logoSize?: number;
  children?: React.ReactNode;
  navLayoutClassName?: string;
};

export const TopNavigationComponent = ({
  bgcolor,
  isTablet,
  hideMenu,
  showMenuToggleAllTheTime,
  user,
  setOpen,
  disableSubheaderPortal,
  logoSize,
  children,
  navLayoutClassName,
  ...props
}: TopNavigationProps) => {
  return (
    <NavLayoutContainer className={navLayoutClassName} bgcolor={bgcolor}>
      <Box
        px={{ xs: 2, md: 6 }}
        width={1}
        height={HEADER_HEIGHT}
        display="flex"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        {...props}
      >
        <Box display="flex" flexGrow={1} alignItems="center">
          {((isTablet && !hideMenu) || showMenuToggleAllTheTime) && user && (
            <Box>
              <IconButton
                aria-label="open navigation menu"
                onClick={() => setOpen(true)}
                variant="transparent"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          {SETTINGS.TOP_SEARCH_BAR_VISIBLE && !user ? (
            <Logo href="https://usebraintrust.com" size={logoSize} />
          ) : (
            <UniversalSearchForm />
          )}
        </Box>
        {children ? children : <Navbar />}
      </Box>
      {!disableSubheaderPortal && <div id="subheader-portal" />}
    </NavLayoutContainer>
  );
};

type NavDrawerProps = {
  sidebar: JSX.Element;
  bgcolor?: string;
  setClose: () => void;
  leftColumnBgColor?: string;
  open: boolean;
};

const NavDrawer = ({ setClose, open, leftColumnBgColor }: NavDrawerProps) => {
  const onOpen = () => null;

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          background: leftColumnBgColor,
          maxWidth: 269,
          width: "100%",
        },
      }}
      open={open}
      onOpen={onOpen}
      onClose={setClose}
      onClick={setClose}
      disableSwipeToOpen
      disableDiscovery
      ModalProps={{ keepMounted: false }}
    >
      <Box display="flex" mt={3.5} ml={3.25}>
        <Logo />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100vh"
        p={2}
        pr="45px"
      >
        <UserSidebar />
        <NavigationProfileCompletionNudgeCard />
      </Box>
    </SwipeableDrawer>
  );
};

export const NavLayoutContainer = ({
  children,
  bgcolor = "var(--white)",
  ...props
}) => {
  return (
    <Box
      zIndex={COMMON_Z_INDEX + 1}
      position="sticky"
      top={0}
      p={{
        xs: "0 5px 0 5px",
        md: "0 40px",
      }}
      minWidth={320}
      display="flex"
      flexDirection="column"
      bgcolor={bgcolor}
      {...props}
    >
      {children}
    </Box>
  );
};
