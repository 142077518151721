import { getElementFontSize, getTextWidth } from "../dom";

export type ColumnDef<TKey extends string> = {
  headerName: string;
  field: TKey;
  autosizeExtraWidth?: number;
  minWidth?: number;
  maxWidth?: number;
};

let bodyFontSize: string | null = null;
const getTableTextFontSize = (fontSize?: string): string => {
  if (fontSize) {
    return fontSize;
  }

  bodyFontSize = bodyFontSize || getElementFontSize();
  return bodyFontSize as string;
};

type GetLongestTextWidthInColumnArg<T extends Record<string, unknown>> = {
  data: T[];
  column: ColumnDef<keyof T & string>;
  fontSize?: string;
};

export const getLongestTextWidthInColumn = <T extends Record<string, unknown>>({
  data,
  column,
  fontSize,
}: GetLongestTextWidthInColumnArg<T>) => {
  const fontSizeForCanvas = getTableTextFontSize(fontSize);

  return data.reduce(
    (acc, curr) => {
      const rowValue = curr[column.field];
      if (typeof rowValue !== "string") {
        return acc;
      }

      const extraWidth = column.autosizeExtraWidth ?? 0;
      const currentValueWidth =
        getTextWidth(rowValue, fontSizeForCanvas) + extraWidth;
      if (currentValueWidth > acc) {
        return Math.ceil(currentValueWidth);
      }

      return acc;
    },
    Math.ceil(getTextWidth(column.headerName, fontSizeForCanvas)),
  );
};

export const getAutosizedColumnWidth = <T extends Record<string, unknown>>({
  data,
  column,
  fontSize,
}: GetLongestTextWidthInColumnArg<T>): number => {
  const columnTotalSidePadding = 16;
  const columnAdditionalSpaceWidth = 16;
  const longestTextWidthInColumn = getLongestTextWidthInColumn({
    data,
    column,
    fontSize,
  });
  const columnLongestTextCellWidth =
    longestTextWidthInColumn +
    columnTotalSidePadding +
    columnAdditionalSpaceWidth;

  if (column.minWidth && column.minWidth > columnLongestTextCellWidth) {
    return column.minWidth;
  }
  if (column.maxWidth && column.maxWidth < columnLongestTextCellWidth) {
    return column.maxWidth;
  }

  return columnLongestTextCellWidth;
};
