import React from "react";

import { Box, Button, Menu } from "@hexocean/braintrust-ui-components";
import { Modal, ModalConfirm } from "@js/components/modal";
import type { Invoice } from "@js/types/invoices";

import { useMarkAsPaidAction } from "./use-mark-as-paid-action";

export const MarkAsPaidConfirmationModalInstance = Modal(
  "mark-as-paid-confirmation-modal",
  {
    onKeyDown: (ev) => {
      // prevents closing Menu if TriggerButton is an MenuItem which keeps modal mounted
      if (ev.key === "Tab") {
        ev.stopPropagation();
      }
    },
  },
);

type MarkAsPaidActionModalProps = {
  onConfirm: () => void;
  modalContent: React.ReactNode;
};

const MarkAsPaidActionModal = ({
  onConfirm,
  modalContent,
}: MarkAsPaidActionModalProps) => {
  return (
    <ModalConfirm
      onConfirm={() => {
        onConfirm();
        MarkAsPaidConfirmationModalInstance.close();
      }}
      onCancel={MarkAsPaidConfirmationModalInstance.close}
    >
      <Box mt={8}>{modalContent}</Box>
    </ModalConfirm>
  );
};

export const openMarkAsPaidModal = ({
  onConfirm,
  modalContent,
}: MarkAsPaidActionModalProps) =>
  MarkAsPaidConfirmationModalInstance.open({
    children: (
      <MarkAsPaidActionModal
        onConfirm={onConfirm}
        modalContent={modalContent}
      />
    ),
  });

export type MarkAsPaidActionProps = {
  children?: React.ReactNode;
  invoices: Pick<Invoice, "id" | "can_be_marked_as_paid">[];
  markAsPaid: (ids: number[]) => void;
  modalContent?: React.ReactNode;
  type?: "button" | "menuitem";
  className?: string;
};

export const MarkAsPaidAction = ({
  children = "Mark as Paid",
  invoices,
  markAsPaid,
  modalContent = "Are you sure you want to do this?",
  type = "button",
  ...rest
}: MarkAsPaidActionProps) => {
  const { canBeMarkedAsPaid } = useMarkAsPaidAction({
    invoices,
  });

  if (!canBeMarkedAsPaid) {
    return null;
  }

  const onConfirm = () => {
    markAsPaid(invoices.map((invoice) => invoice.id));
  };

  if (type === "menuitem") {
    return (
      <>
        <Menu.Item
          className="no-wrap"
          {...rest}
          onClick={() => openMarkAsPaidModal({ onConfirm, modalContent })}
        >
          {children}
        </Menu.Item>
        <MarkAsPaidConfirmationModalInstance />
      </>
    );
  }

  return (
    <>
      <Button
        variant="transparent"
        className="no-wrap"
        onClick={() => openMarkAsPaidModal({ onConfirm, modalContent })}
        {...rest}
      >
        {children}
      </Button>
      <MarkAsPaidConfirmationModalInstance />
    </>
  );
};
