import { API } from "@js/api";
import {
  getAllGetCommentsIds,
  getAllGetMyPostsArgs,
  getAllGetPostArgs,
  getAllGetPostsArgs,
  getAllRelevantPostsArgs,
  getAllSavedPostsArgs,
  shouldRefreshFeedRanking,
} from "@js/apps/give-and-get-help/utils";
import {
  addComment,
  addPostSlotPosition,
  addReplyToComment,
  bookmarkPost,
  commentsUpdateRecipe,
  type Draft,
  helpOffersUpdateRecipe,
  patchCommentsOnCommentDeleted,
  patchPostOnCommentAdded,
  patchPostOnCommentDeleted,
  patchPostOnReactionToggle,
  patchPostOnVote,
  patchPostsOnCommentAdded,
  patchPostsOnCommentDeleted,
  patchPostsOnCommentUpdated,
  patchPostsOnPostAdded,
  patchPostsOnPostDeleted,
  patchPostsOnPostUpdate,
  patchPostsOnReactionToggle,
  patchPostsOnVote,
  postsUpdateRecipe,
  toggleUserCommentReaction,
  unbookmarkPost,
  updateComment,
  updatePost,
} from "@js/apps/give-and-get-help/utils/update-recipes";
import { singlePostUpdateRecipe } from "@js/apps/give-and-get-help/utils/update-recipes/single-post-update-recipe";
import { Snackbar } from "@js/components/snackbar";
import type { RootState } from "@js/store";
import type {
  CategoryHelpRequestCount,
  HelpOffer,
  HelpService,
  IPost,
  PollOption,
  PostCategory,
  PostComment,
  ToggleReactionPayload,
} from "@js/types/give-and-get-help";
import {
  combineResults,
  createListenerIdFromArgs,
  listenerWrapper,
  updateQueryDataToUndefined,
} from "@js/utils";

import { messengerApi } from "../messenger/api";

import { updateSinglePost } from "./utils/update-recipes/single-post-update-recipe/helpers";
import type {
  AcceptHelpOfferParams,
  AddPostCommentParams,
  AddPostParams,
  BookmarkPostParams,
  DeclineOfferProps,
  DeleteCommentParams,
  DeletedPostCommentData,
  DeletedPostData,
  EditHelpOfferParams,
  EditPostCommentParams,
  GetCommentsReturnPayload,
  GetHelpOffersParams,
  GetMyPostsParams,
  GetPostsParams,
  GetPostsReturnPayload,
  GetSavedPostsParams,
  GetSavedPostsReturnPayload,
  Hashtag,
  HelpOfferRequestParams,
  IssueRefundPostParams,
  PostsParams,
  ReportPostParams,
  RequestHelpOfferApprovalParams,
  RequestHelpOfferRefundParams,
  SelfPromotionCheckResponse,
  SelfPromotionRequestBody,
  ToggleReactionParams,
  UpdatePostPropsParams,
} from "./types";
import { isNotFoundError } from "./types";

type HelpServiceProps = {
  category: number;
  description?: string;
  budget: string;
};

const SECONDS_FOR_CACHE_CLEAR = 60 * 60 * 24 * 3;

export const postsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getPosts: build.query<GetPostsReturnPayload, GetPostsParams>({
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs = { ...queryArgs };
        delete serializeQueryArgs.page;
        // ignore page size as posts can be refetched with different page size than before
        delete serializeQueryArgs.page_size;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      merge: (currentCache, newItems) => {
        const combinedPostsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedPostsResults.map(addPostSlotPosition),
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingCachedPage =
          !!previousArg?.page &&
          !!currentArg?.page &&
          previousArg?.page >= currentArg?.page;

        if (isFetchingCachedPage) {
          return false;
        }
        // serialized query arg makes it so we are not fetching on page change ...
        // ... force refetch when any param changes, including page (page must be higher)
        const paramsKeys: Array<keyof GetPostsParams> = [
          "page",
          "page_size",
          "ordering",
        ];
        const hasArgChanged = paramsKeys.some(
          (paramKey) => currentArg?.[paramKey] !== previousArg?.[paramKey],
        );

        return hasArgChanged;
      },
      query: (params) => {
        //postsSessionIdx is used only for cache invalidation purposes
        const { postsSessionIdx: _postsSessionIdx, ...arg } = params;
        const additionalParams = shouldRefreshFeedRanking(arg)
          ? { force_reload: true }
          : {};

        return {
          url: "/posts/",
          method: "GET",
          params: { ...additionalParams, ...arg },
        };
      },
      transformResponse: (data: GetPostsReturnPayload) => {
        return {
          ...data,
          results: data.results.map(addPostSlotPosition),
        };
      },
      async onCacheEntryAdded(
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: postsUpdateRecipe(args),
          listenerId: createListenerIdFromArgs(args, "my-posts-listener"),
        });
      },
      keepUnusedDataFor: SECONDS_FOR_CACHE_CLEAR,
      providesTags: [{ type: "Posts", id: "LIST" }],
    }),
    getMyPosts: build.query<GetPostsReturnPayload, GetMyPostsParams>({
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs = { ...queryArgs };
        delete serializeQueryArgs.page;
        // ignore page size as posts can be refetched with different page size than before
        delete serializeQueryArgs.page_size;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      merge: (currentCache, newItems) => {
        const combinedPostsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedPostsResults.map(addPostSlotPosition),
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingCachedPage =
          !!previousArg?.page &&
          !!currentArg?.page &&
          previousArg?.page >= currentArg?.page;

        if (isFetchingCachedPage) {
          return false;
        }
        // serialized query arg makes it, so we are not fetching on page change ...
        // ... force refetch when any param changes, including page (page must be higher)
        const paramsKeys: Array<keyof GetMyPostsParams> = ["page", "page_size"];
        const hasArgChanged = paramsKeys.some(
          (paramKey) => currentArg?.[paramKey] !== previousArg?.[paramKey],
        );

        return hasArgChanged;
      },
      query: (params) => ({
        url: "/posts/my_posts",
        method: "GET",
        params,
      }),
      transformResponse: (data: GetPostsReturnPayload) => {
        return {
          ...data,
          results: data.results.map(addPostSlotPosition),
        };
      },
      async onCacheEntryAdded(
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: postsUpdateRecipe(args, { isMyPostsPage: true }),
          listenerId: createListenerIdFromArgs(args, "feed-listener"),
        });
      },
      keepUnusedDataFor: SECONDS_FOR_CACHE_CLEAR,
      providesTags: ["MyPosts"],
    }),
    getRelevantPosts: build.query<IPost[], void>({
      query: () => ({
        url: `/posts/talent_home_relevant_posts/`,
        method: "GET",
      }),
      transformResponse: (response: IPost[]) => {
        return response.map(addPostSlotPosition);
      },
      providesTags: ["TalentHomeRelevantPosts"],
    }),
    refetchPostsSinglePost: build.query<IPost, PostsParams>({
      query: ({ id }) => ({
        url: `/posts/${id}/`,
        method: "GET",
      }),
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: singlePostUpdateRecipe,
          listenerId: "single_post_listener",
        });
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const allGetPostsArgs = getAllGetPostsArgs(getState() as RootState);

          allGetPostsArgs.forEach((getPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getPosts",
                getPostsArg,
                (draft) => {
                  draft.results = draft.results
                    .map((cachedResult: any) => {
                      if (cachedResult.id === id) return data;
                      return cachedResult;
                    })
                    .filter(Boolean)
                    .map(addPostSlotPosition);
                },
              ),
            );
          });
        } catch (e) {
          if (isNotFoundError(e)) {
            const allGetPostsArgs = getAllGetPostsArgs(getState() as RootState);

            allGetPostsArgs.forEach((getPostsArg) => {
              dispatch(
                postsApi.util.updateQueryData(
                  "getPosts",
                  getPostsArg,
                  (draft) => {
                    draft.results = draft.results
                      .filter((cachedResult: any) => {
                        if (cachedResult.id === id) return null;
                        return cachedResult;
                      })
                      .filter(Boolean)
                      .map(addPostSlotPosition);
                  },
                ),
              );
            });
          }
        }
      },
    }),
    getPost: build.query<IPost, PostsParams>({
      query: ({ id }) => ({
        url: `/posts/${id}/`,
        method: "GET",
      }),
      transformResponse: (response: IPost) => {
        return addPostSlotPosition(response, 0);
      },
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: singlePostUpdateRecipe,
          listenerId: "single_post_listener",
        });
      },
      providesTags: (_res, _err, arg) => [{ type: "Posts", id: arg.id }],
    }),

    addPost: build.mutation<IPost, AddPostParams>({
      query: (data) => ({
        url: "/posts/",
        method: "POST",
        data,
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: createdPost } = await queryFulfilled;
          const state = getState() as RootState;
          const allGetPostsArgs = getAllGetPostsArgs(state);

          allGetPostsArgs.forEach((getPostsArg) => {
            const isSameSpace =
              !getPostsArg.space || createdPost.space === getPostsArg.space;

            if (!isSameSpace) return;

            dispatch(
              postsApi.util.updateQueryData(
                "getPosts",
                getPostsArg,
                (draft) => {
                  patchPostsOnPostAdded({
                    addedPost: createdPost,
                    draft,
                    args: getPostsArg,
                  });
                },
              ),
            );
          });

          const allGetMyPostsArgs = getAllGetMyPostsArgs(state);
          allGetMyPostsArgs.forEach((getMyPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getMyPosts",
                getMyPostsArg,
                (draft) => {
                  patchPostsOnPostAdded({
                    addedPost: createdPost,
                    draft,
                    args: getMyPostsArg,
                  });
                },
              ),
            );
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deletePost: build.mutation<DeletedPostData, PostsParams>({
      query: ({ id }) => ({
        url: `/posts/${id}/`,
        method: "DELETE",
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: deletedPost } = await queryFulfilled;
          const state = getState() as RootState;
          const allGetPostsArgs = getAllGetPostsArgs(state);

          allGetPostsArgs.forEach((getPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getPosts",
                getPostsArg,
                (draft) => {
                  patchPostsOnPostDeleted(deletedPost.id, draft);
                },
              ),
            );
          });

          const allGetMyPostsArgs = getAllGetMyPostsArgs(state);
          allGetMyPostsArgs.forEach((getMyPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getMyPosts",
                getMyPostsArg,
                (draft) => {
                  patchPostsOnPostDeleted(deletedPost.id, draft);
                },
              ),
            );
          });

          dispatch(
            postsApi.util.updateQueryData(
              "getPost",
              { id: deletedPost.id },
              updateQueryDataToUndefined,
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    reportPost: build.mutation<PostsParams, ReportPostParams>({
      query: ({ id, data }) => ({
        url: `/posts/${id}/report/`,
        method: "POST",
        data,
      }),
    }),
    updatePost: build.mutation<IPost, UpdatePostPropsParams>({
      query: (data) => ({
        url: `/posts/${data.id}/`,
        method: "PUT",
        data,
      }),

      async onQueryStarted(_args, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const state = getState() as RootState;
          const allGetPostsArgs = getAllGetPostsArgs(state);

          allGetPostsArgs.forEach((getPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getPosts",
                getPostsArg,
                (draft) => {
                  patchPostsOnPostUpdate(updatedPost, draft, getPostsArg);
                },
              ),
            );
          });

          const allGetMyPostsArgs = getAllGetMyPostsArgs(state);
          allGetMyPostsArgs.forEach((getMyPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getMyPosts",
                getMyPostsArg,
                (draft) => {
                  updatePost({ data: updatedPost }, draft);
                },
              ),
            );
          });

          dispatch(
            postsApi.util.updateQueryData(
              "getPost",
              { id: updatedPost.id },
              (draft) => {
                return updateSinglePost({ data: updatedPost }, draft);
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getPostComments: build.query<
      GetCommentsReturnPayload,
      Omit<AddPostCommentParams, "data">
    >({
      query: ({ postId }) => ({
        url: `/posts/${postId}/comments/`,
        method: "GET",
      }),
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: commentsUpdateRecipe(_arg.postId),
          listenerId: createListenerIdFromArgs(_arg, "comments-listener"),
        });
      },
    }),
    addPostComment: build.mutation<PostComment, AddPostCommentParams>({
      query: ({ postId, data }) => ({
        url: `/posts/${postId}/reply/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_args, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: addedComment } = await queryFulfilled;
          const mainPostId = addedComment.main_post_id;
          dispatch(
            postsApi.util.updateQueryData(
              "getPostComments",
              { postId: mainPostId },
              (draft) => {
                addComment({ data: addedComment }, draft, mainPostId);
              },
            ),
          );

          const state = getState() as RootState;
          const allGetPostsArgs = getAllGetPostsArgs(state);

          const isReplyToCommentAdded = addedComment.parent_id !== mainPostId;
          if (isReplyToCommentAdded) {
            dispatch(
              postsApi.util.updateQueryData(
                "getPostComments",
                { postId: addedComment.parent_id },
                (draft) => {
                  addReplyToComment({ addedReply: addedComment, draft });
                },
              ),
            );
          }

          allGetPostsArgs.forEach((getPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getPosts",
                getPostsArg,
                (draft) => {
                  patchPostsOnCommentAdded({ addedComment, draft });
                },
              ),
            );
          });

          const getPostArg = { id: mainPostId };
          dispatch(
            postsApi.util.updateQueryData("getPost", getPostArg, (draft) => {
              patchPostOnCommentAdded({ addedComment, post: draft });
            }),
          );

          dispatch(
            postsApi.util.updateQueryData(
              "getRelevantPosts",
              undefined,
              (draft) => {
                patchPostsOnCommentAdded({ addedComment, draft });
              },
            ),
          );
        } catch (e: any) {
          const errorMessage = e?.error?.data?._error;
          if (errorMessage) {
            Snackbar.error(errorMessage);
          }
          console.error(e);
        }
      },
    }),
    updatePostComment: build.mutation<PostComment, EditPostCommentParams>({
      query: ({ postId, data }) => ({
        url: `/posts/${postId}/`,
        method: "PUT",
        data,
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedComment } = await queryFulfilled;
          const allGetPostsArgs = getAllGetPostsArgs(getState() as RootState);

          dispatch(
            postsApi.util.updateQueryData(
              "getRelevantPosts",
              undefined,
              (draft) => {
                patchPostsOnCommentUpdated({ draft, updatedComment });
              },
            ),
          );

          allGetPostsArgs.forEach((postArgs) => {
            dispatch(
              postsApi.util.updateQueryData("getPosts", postArgs, (draft) => {
                patchPostsOnCommentUpdated({ draft, updatedComment });
              }),
            );
          });

          const getPostArg = { id: updatedComment.main_post_id };
          dispatch(
            postsApi.util.updateQueryData("getPost", getPostArg, (draft) => {
              updateComment(
                { data: updatedComment },
                draft,
                updatedComment.main_post_id,
              );
            }),
          );

          const isReplyToCommentUpdated =
            updatedComment.parent_id !== updatedComment.main_post_id;

          if (isReplyToCommentUpdated) {
            dispatch(
              postsApi.util.updateQueryData(
                "getPostComments",
                { postId: updatedComment.parent_id },
                (draft) => {
                  updateComment(
                    { data: updatedComment },
                    draft,
                    updatedComment.main_post_id,
                  );
                },
              ),
            );
          }

          dispatch(
            postsApi.util.updateQueryData(
              "getPostComments",
              {
                postId: updatedComment.main_post_id,
              },
              (draft) => {
                updateComment(
                  { data: updatedComment },
                  draft,
                  updatedComment.main_post_id,
                );
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deletePostComment: build.mutation<
      DeletedPostCommentData,
      DeleteCommentParams
    >({
      query: ({ id }) => ({
        url: `/posts/${id}/`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { parent_id },
        { dispatch, getState, queryFulfilled },
      ) {
        try {
          const { data: deletedComment } = await queryFulfilled;
          const mainPostId = deletedComment.main_post_id;
          if (mainPostId) {
            dispatch(
              postsApi.util.updateQueryData(
                "getPostComments",
                { postId: mainPostId },
                (draft) => {
                  patchCommentsOnCommentDeleted(deletedComment, draft);
                },
              ),
            );
          }

          const isReplyToCommentDeleted =
            deletedComment.main_post_id !== parent_id;
          if (isReplyToCommentDeleted) {
            dispatch(
              postsApi.util.updateQueryData(
                "getPostComments",
                { postId: parent_id },
                (draft) => {
                  patchCommentsOnCommentDeleted(deletedComment, draft);
                },
              ),
            );
          }

          dispatch(
            postsApi.util.updateQueryData(
              "getRelevantPosts",
              undefined,
              (draft) => {
                patchPostsOnCommentDeleted({ draft, deletedComment });
              },
            ),
          );

          const allGetPostsArgs = getAllGetPostsArgs(getState() as RootState);
          allGetPostsArgs.forEach((getPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getPosts",
                getPostsArg,
                (draft) => {
                  patchPostsOnCommentDeleted({ draft, deletedComment });
                },
              ),
            );
          });

          if (mainPostId) {
            const getPostArg = { id: mainPostId };
            dispatch(
              postsApi.util.updateQueryData("getPost", getPostArg, (draft) => {
                patchPostOnCommentDeleted({ deletedComment, post: draft });
              }),
            );
          }
        } catch (e) {
          console.error(e);
        }
      },
    }),
    toggleReactionOnPost: build.mutation<
      ToggleReactionPayload,
      ToggleReactionParams
    >({
      query: ({ postId, data }) => ({
        url: `/posts/${postId}/toggle_reaction/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        const state = getState() as RootState;
        const user = state.auth.user;
        if (!user) {
          return;
        }
        const {
          postId,
          mainPostId,
          data: { sticker },
        } = args;

        const allGetPostsArgs = getAllGetPostsArgs(state);
        const patchResults: Array<{ undo: () => void }> = [];

        allGetPostsArgs.forEach((getPostsArg) => {
          const postsPatchResult = dispatch(
            postsApi.util.updateQueryData("getPosts", getPostsArg, (draft) => {
              patchPostsOnReactionToggle({
                postId,
                user,
                sticker,
                draft,
              });
            }),
          );

          patchResults.push(postsPatchResult);
        });

        const singlePostPatchResult = dispatch(
          postsApi.util.updateQueryData(
            "getPost",
            { id: mainPostId },
            (draft) => {
              patchPostOnReactionToggle({
                postId,
                user,
                sticker,
                post: draft,
              });
            },
          ),
        );
        patchResults.push(singlePostPatchResult);

        const relevantPostsPatchResult = dispatch(
          postsApi.util.updateQueryData(
            "getRelevantPosts",
            undefined,
            (draft) => {
              patchPostsOnReactionToggle({
                postId,
                user,
                sticker,
                draft,
              });
            },
          ),
        );

        patchResults.push(relevantPostsPatchResult);

        const allCommentIds = getAllGetCommentsIds(state);
        allCommentIds.forEach((id) => {
          const commentsPatchResult = dispatch(
            postsApi.util.updateQueryData(
              "getPostComments",
              { postId: id },
              (draft) => {
                toggleUserCommentReaction({
                  postId, // postOrCommenrId
                  user,
                  draft,
                  sticker,
                });
              },
            ),
          );

          patchResults.push(commentsPatchResult);
        });

        try {
          await queryFulfilled;

          dispatch(API.util.invalidateTags(["BookmarkedPosts"]));
        } catch (e) {
          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
    }),
    voteOnPollOption: build.mutation<
      PollOption[],
      { optionId: number; postId: number }
    >({
      query: ({ optionId }) => ({
        url: `/poll_options/${optionId}/vote/`,
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        const patchResults: Array<{ undo: () => void }> = [];
        const state = getState() as RootState;
        const allGetPostsArgs = getAllGetPostsArgs(state);
        allGetPostsArgs.forEach((getPostsArg) => {
          const patchPostsResult = dispatch(
            postsApi.util.updateQueryData("getPosts", getPostsArg, (draft) => {
              patchPostsOnVote({ draft, ...arg });
            }),
          );

          patchResults.push(patchPostsResult);
        });

        const allGetMyPostsArgs = getAllGetMyPostsArgs(state);
        allGetMyPostsArgs.forEach((getMyPostsArg) => {
          const patchMyPostsResult = dispatch(
            postsApi.util.updateQueryData(
              "getMyPosts",
              getMyPostsArg,
              (draft) => {
                patchPostsOnVote({ draft, ...arg });
              },
            ),
          );

          patchResults.push(patchMyPostsResult);
        });

        const patchSinglePostResult = dispatch(
          postsApi.util.updateQueryData(
            "getPost",
            { id: arg.postId },
            (draft) => {
              patchPostOnVote({ draft, optionId: arg.optionId });
            },
          ),
        );
        patchResults.push(patchSinglePostResult);

        const patchRelevantPostsResult = dispatch(
          postsApi.util.updateQueryData(
            "getRelevantPosts",
            undefined,
            (draft) => {
              patchPostsOnVote({ draft, ...arg });
            },
          ),
        );
        patchResults.push(patchRelevantPostsResult);

        const allGetSavedPostsArgs = getAllSavedPostsArgs(
          getState() as RootState,
        );
        allGetSavedPostsArgs.forEach((getSavedPostsArg) => {
          const patchSavedPostsResult = dispatch(
            postsBookmarksApi.util.updateQueryData(
              "getSavedPosts",
              getSavedPostsArg,
              (draft) => {
                patchPostsOnVote({ draft, ...arg });
              },
            ),
          );
          patchResults.push(patchSavedPostsResult);
        });

        try {
          await queryFulfilled;
        } catch (e) {
          patchResults.forEach((patch) => patch.undo());
        }
      },
    }),
    acceptHelpOffer: build.mutation<undefined, AcceptHelpOfferParams>({
      query: ({ offerId, code, accepted_budget, is_backup_code }) => ({
        url: `/help_offers/${offerId}/accept/`,
        method: "POST",
        data: { code, accepted_budget, is_backup_code },
      }),
      invalidatesTags: ["WalletBalance"],
      async onCacheEntryAdded(
        { offerId },
        { dispatch, getState, cacheDataLoaded },
      ) {
        try {
          await cacheDataLoaded;
          const roomId = (getState() as RootState).common.activeMessengerRoom;

          dispatch(
            messengerApi.util.updateQueryData(
              "getRoomMessages",
              { room: roomId },
              (draft) => {
                draft.results = draft.results.map((message) => {
                  if (message.help_offer?.id === offerId) {
                    message.help_offer.status = ENUMS.HelpOfferStatus.ACCEPTED;
                  }

                  return message;
                });
              },
            ),
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
      },
    }),
    declineHelpOffer: build.mutation<undefined, DeclineOfferProps>({
      query: ({ offerId, data }) => ({
        url: `/help_offers/${offerId}/decline/`,
        method: "POST",
        data,
      }),

      async onCacheEntryAdded(
        { offerId },
        { dispatch, getState, cacheDataLoaded },
      ) {
        try {
          await cacheDataLoaded;
          const roomId = (getState() as RootState).common.activeMessengerRoom;

          dispatch(
            messengerApi.util.updateQueryData(
              "getRoomMessages",
              { room: roomId },
              (draft) => {
                draft.results = draft.results.map((message) => {
                  if (message.help_offer?.id === offerId) {
                    message.help_offer.status = ENUMS.HelpOfferStatus.DECLINED;
                  }

                  return message;
                });
              },
            ),
          );
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
      },
    }),
    removeHelpOffer: build.mutation<undefined, { offerId: number }>({
      query: ({ offerId }) => ({
        url: `/help_offers/${offerId}`,
        method: "DELETE",
      }),
    }),

    createHelpOfferMessage: build.mutation<undefined, { offerId: number }>({
      query: ({ offerId }) => ({
        url: `/help_offers/${offerId}/create_message/`,
        method: "POST",
      }),
    }),

    requestHelpOfferApproval: build.mutation<
      undefined,
      RequestHelpOfferApprovalParams
    >({
      query: ({ offerId, data }) => ({
        url: `/help_offers/${offerId}/mark_complete/`,
        method: "POST",
        data,
      }),
    }),

    completeHelpOffer: build.mutation<
      undefined,
      RequestHelpOfferApprovalParams
    >({
      query: ({ offerId, data }) => ({
        url: `/help_offers/${offerId}/complete/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["FreelancerPublicProfile"],
    }),

    requestHelpOfferRevision: build.mutation<
      undefined,
      RequestHelpOfferApprovalParams
    >({
      query: ({ offerId, data }) => ({
        url: `/help_offers/${offerId}/request_revision/`,
        method: "POST",
        data,
      }),
    }),

    getHelpOffers: build.query<HelpOffer[], GetHelpOffersParams>({
      query: (params) => ({
        url: `/help_offers/`,
        method: `GET`,
        params,
      }),
      providesTags: ["HelpOffers"],
      async onCacheEntryAdded(
        _arg,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        const invalidateCache = () =>
          dispatch(API.util.invalidateTags(["HelpOffers"]));

        listenerWrapper({
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: helpOffersUpdateRecipe(invalidateCache),
          listenerId: createListenerIdFromArgs(_arg, "help_offers_listener"),
        });
      },
    }),
    updateHelpOffer: build.mutation<undefined, EditHelpOfferParams>({
      query: ({ id, data }) => ({
        url: `/help_offers/${id}/`,
        method: "PUT",
        data,
      }),
    }),
    reportHelpOffer: build.mutation<PostsParams, ReportPostParams>({
      query: ({ id, data }) => ({
        url: `/help_offers/${id}/report/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["MessengerRooms"],
    }),
    getHelpServices: build.query<HelpService[], { freelancerId: number }>({
      query: ({ freelancerId }) => ({
        url: `/help_services/?freelancer=${freelancerId}`,
        method: "GET",
      }),
      providesTags: ["HelpServices"],
    }),
    getHelpServicesDraft: build.query<HelpService[], void>({
      query: () => ({
        url: `/help_services/draft`,
        method: "GET",
      }),
      providesTags: ["HelpServices"],
    }),
    createHelpService: build.mutation<HelpService, HelpServiceProps>({
      query: (data) => ({
        url: "/help_services/",
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "HelpServices",
        "HelpServices",
        "FreelancerHoverStateData",
      ],
    }),
    editHelpService: build.mutation<
      HelpService,
      { id: number; data: HelpServiceProps }
    >({
      query: ({ id, data }) => ({
        url: `/help_services/${id}/`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["HelpServices", "FreelancerHoverStateData"],
    }),
    deleteHelpService: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/help_services/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "HelpServices",
        "HelpServices",
        "FreelancerHoverStateData",
      ],
    }),
    updateHelpServicePosition: build.mutation<
      void,
      { itemPositions: HelpService[] }
    >({
      query: ({ itemPositions }) => ({
        url: "/help_services/update_positions/",
        method: "POST",
        data: {
          item_positions: itemPositions,
        },
      }),
      invalidatesTags: ["HelpServices"],
    }),
    requestHelpOfferFromService: build.mutation<
      { id: number },
      { serviceId: number }
    >({
      query: (params) => ({
        url: `/help_services/${params.serviceId}/request_help_offer/`,
        method: "POST",
      }),
    }),

    cancelRefundRequest: build.mutation<HelpOffer, HelpOfferRequestParams>({
      query: ({ offerId, data }) => ({
        url: `/help_offers/${offerId}/cancel_request_refund/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["HelpOffers", "RoomMessages"],
    }),

    requestHelpOfferRefund: build.mutation<
      undefined,
      RequestHelpOfferRefundParams
    >({
      query: ({ offerId, data }) => ({
        url: `/help_offers/${offerId}/request_refund/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["RoomMessages"],
    }),

    issueHelpOfferRefund: build.mutation<undefined, IssueRefundPostParams>({
      query: ({ id, data }) => ({
        url: `/help_offers/${id}/issue_refund/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["HelpOffers"],
    }),
    markCareerHelpAsVisited: build.mutation<void, void>({
      query: () => ({
        url: `/users/mark_career_help_as_visited`,
        method: "POST",
      }),
    }),
    showedFeedThirdTime: build.mutation<void, void>({
      query: () => ({
        url: `/posts/shown_third_time/`,
        method: "POST",
      }),
    }),
    getPublicPosts: build.query<IPost, { id: string }>({
      query: ({ id }) => ({
        url: `/posts_public/${id}/`,
        method: "GET",
      }),
    }),
    reportPublicPost: build.mutation<PostsParams, ReportPostParams>({
      query: ({ id, data }) => ({
        url: `/posts_public/${id}/report/`,
        method: "POST",
        data,
      }),
    }),
    getRelevantPublicPosts: build.query<IPost[], { id: string }>({
      query: ({ id }) => ({
        url: `/posts_public/${id}/relevant_posts/`,
        method: "GET",
      }),
    }),
    trackSharePost: build.mutation<
      { id: number },
      { id: number; kind: EnumType<typeof ENUMS.SharePostEventKind> }
    >({
      query: ({ id, kind }) => ({
        url: `/posts/${id}/share/`,
        method: "POST",
        data: {
          kind,
        },
      }),
    }),
    markCreatingPostAsVisited: build.mutation<void, void>({
      query: () => ({
        url: `/users/mark_creating_post_flow_as_visited/`,
        method: "POST",
      }),
    }),
  }),
});

const postCategoriesApi = API.injectEndpoints({
  endpoints: (build) => ({
    getPostCategories: build.query<PostCategory[], void>({
      query: () => ({
        url: "/post_categories/",
        method: "GET",
      }),
      keepUnusedDataFor: SECONDS_FOR_CACHE_CLEAR,
    }),
    suggestPostCategory: build.mutation<undefined, { name: string }>({
      query: ({ name }) => ({
        url: "/post_categories/",
        method: "POST",
        data: {
          name,
        },
      }),
    }),
    getCategoryHelpRequestCounts: build.query<CategoryHelpRequestCount[], void>(
      {
        query: () => ({
          url: "/post_categories/help_request_counts/",
          method: "GET",
        }),
      },
    ),
  }),
});

const hashtagsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getTrendingHashtags: build.query<Hashtag[], void>({
      query: () => ({
        url: "/hashtags/trending_hashtags",
        method: "GET",
      }),
      keepUnusedDataFor: SECONDS_FOR_CACHE_CLEAR,
    }),
    getPopularHashtags: build.query<Hashtag[], void>({
      query: () => ({
        url: "/hashtags/popular_hashtags",
        method: "GET",
      }),
    }),
    getHashtagsAutocomplete: build.query<Hashtag[], string>({
      query: (query) => ({
        url: `/hashtags/autocomplete?query=${query}`,
        method: "GET",
      }),
    }),
    getHashtagById: build.query<Hashtag, number>({
      query: (id) => ({
        url: `/hashtags/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const postsBookmarksApi = API.injectEndpoints({
  endpoints: (build) => ({
    getSavedPosts: build.query<GetSavedPostsReturnPayload, { page: number }>({
      query: (arg) => ({
        url: "/posts/saved_posts",
        method: "GET",
        params: arg,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs: Partial<GetSavedPostsParams> = {
          ...queryArgs,
        };
        delete serializeQueryArgs.page;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingCachedPage =
          !!previousArg?.page &&
          !!currentArg?.page &&
          previousArg?.page >= currentArg?.page;

        if (isFetchingCachedPage) {
          return false;
        }
        // serialized query arg makes it so we are not fetching on page change ...
        // ... force refetch when any param changes, including page (page must be higher)
        const paramsKeys: Array<keyof GetSavedPostsParams> = ["page"];
        const hasArgChanged = paramsKeys.some(
          (paramKey) => currentArg?.[paramKey] !== previousArg?.[paramKey],
        );

        return hasArgChanged;
      },
      merge: (currentCache, newItems) => {
        const combinedPostsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedPostsResults,
        };
      },
      providesTags: ["BookmarkedPosts"],
    }),
    bookmarkPost: build.mutation<BookmarkPostParams, number>({
      query: (postId) => ({
        url: "/manage_saved_elements/posts/",
        method: "POST",
        data: {
          post: postId,
        },
      }),
      async onQueryStarted(_args, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const allGetPostsArgs = getAllGetPostsArgs(getState() as RootState);
          allGetPostsArgs.forEach((getPostsArg) => {
            dispatch(
              postsApi.util.updateQueryData(
                "getPosts",
                getPostsArg,
                (draft) => {
                  bookmarkPost(data, draft);
                },
              ),
            );
          });

          const allRelevantPostsArgs = getAllRelevantPostsArgs(
            getState() as RootState,
          );

          allRelevantPostsArgs.forEach(() => {
            dispatch(
              postsApi.util.updateQueryData(
                "getRelevantPosts",
                undefined,
                (draft) => {
                  bookmarkPost(data, draft);
                },
              ),
            );
          });

          const allGetPostArgs = getAllGetPostArgs(getState() as RootState);
          allGetPostArgs.forEach((getPostArg) => {
            dispatch(
              postsApi.util.updateQueryData("getPost", getPostArg, (draft) => {
                bookmarkPost(data, draft as unknown as Draft);
              }),
            );
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: (_result, error) =>
        !!error
          ? ["BookmarkedPosts", "MyPosts", "Posts", "TalentHomeRelevantPosts"]
          : ["BookmarkedPosts", "MyPosts"],
    }),
    unbookmarkPost: build.mutation<
      unknown,
      {
        savedPostId: number | null;
        postId: number;
      }
    >({
      query: ({ savedPostId }) => ({
        url: `/manage_saved_elements/posts/${savedPostId}/`,
        method: "DELETE",
      }),
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        const allGetPostsArgs = getAllGetPostsArgs(getState() as RootState);
        allGetPostsArgs.forEach((getPostsArg) => {
          dispatch(
            postsApi.util.updateQueryData("getPosts", getPostsArg, (draft) => {
              unbookmarkPost(args.postId, draft);
            }),
          );
        });

        const allRelevantPostsArgs = getAllRelevantPostsArgs(
          getState() as RootState,
        );

        allRelevantPostsArgs.forEach(() => {
          dispatch(
            postsApi.util.updateQueryData(
              "getRelevantPosts",
              undefined,
              (draft) => {
                unbookmarkPost(args.postId, draft);
              },
            ),
          );
        });

        const allGetPostArgs = getAllGetPostArgs(getState() as RootState);
        allGetPostArgs.forEach((getPostArg) => {
          dispatch(
            postsApi.util.updateQueryData("getPost", getPostArg, (draft) => {
              unbookmarkPost(args.postId, draft as unknown as Draft);
            }),
          );
        });

        const allGetSavedPostsArgs = getAllSavedPostsArgs(
          getState() as RootState,
        );
        allGetSavedPostsArgs.forEach((getSavedPostsArg) => {
          dispatch(
            postsBookmarksApi.util.updateQueryData(
              "getSavedPosts",
              getSavedPostsArg,
              (draft) => {
                patchPostsOnPostDeleted(args.postId, draft);
              },
            ),
          );
        });
        try {
          await queryFulfilled;
        } catch (e) {
          /* empty */
        }
      },
      invalidatesTags: (_result, error) =>
        !!error
          ? ["BookmarkedPosts", "MyPosts", "Posts", "TalentHomeRelevantPosts"]
          : ["MyPosts"],
    }),
  }),
});

export const {
  useGetRelevantPostsQuery,
  useGetPostsQuery,
  useLazyRefetchPostsSinglePostQuery,
  useGetPostQuery,
  useAddPostMutation,
  useDeletePostMutation,
  useReportPostMutation,
  useUpdatePostMutation,
  useGetPostCommentsQuery,
  useAddPostCommentMutation,
  useUpdatePostCommentMutation,
  useDeletePostCommentMutation,
  useToggleReactionOnPostMutation,
  useVoteOnPollOptionMutation,
  useAcceptHelpOfferMutation,
  useDeclineHelpOfferMutation,
  useCreateHelpOfferMessageMutation,
  useRemoveHelpOfferMutation,
  useGetHelpOffersQuery,
  useGetHelpServicesQuery,
  useGetHelpServicesDraftQuery,
  useCreateHelpServiceMutation,
  useEditHelpServiceMutation,
  useDeleteHelpServiceMutation,
  useUpdateHelpServicePositionMutation,
  useUpdateHelpOfferMutation,
  useRequestHelpOfferApprovalMutation,
  useRequestHelpOfferRevisionMutation,
  useCompleteHelpOfferMutation,
  useReportHelpOfferMutation,
  useRequestHelpOfferFromServiceMutation,
  useCancelRefundRequestMutation,
  useIssueHelpOfferRefundMutation,
  useRequestHelpOfferRefundMutation,
  useMarkCareerHelpAsVisitedMutation,
  useGetRelevantPublicPostsQuery,
  useGetPublicPostsQuery,
  useReportPublicPostMutation,
  useTrackSharePostMutation,
  useGetMyPostsQuery,
  useShowedFeedThirdTimeMutation,
  useMarkCreatingPostAsVisitedMutation,
} = postsApi;

export const useGetPostCommentsQueryState =
  postsApi.endpoints.getPostComments.useQueryState;

export const {
  useGetPostCategoriesQuery,
  useSuggestPostCategoryMutation,
  useGetCategoryHelpRequestCountsQuery,
} = postCategoriesApi;

export const {
  useGetTrendingHashtagsQuery,
  useGetPopularHashtagsQuery,
  useGetHashtagsAutocompleteQuery,
  useGetHashtagByIdQuery,
} = hashtagsApi;

export const {
  useGetSavedPostsQuery,
  useBookmarkPostMutation,
  useUnbookmarkPostMutation,
} = postsBookmarksApi;

const selfPromotionApi = API.injectEndpoints({
  endpoints: (build) => ({
    selfPromotionPostCheck: build.mutation<
      SelfPromotionCheckResponse,
      SelfPromotionRequestBody
    >({
      query: (body) => ({
        url: "/post_validation/validate/",
        method: "POST",
        data: body,
      }),
    }),
    selfPromotionPostServerInit: build.mutation<void, void>({
      query: () => ({
        url: "/post_validation/initial_request/",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useSelfPromotionPostCheckMutation,
  useSelfPromotionPostServerInitMutation,
} = selfPromotionApi;
