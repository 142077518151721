import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import cs from "classnames";
import { uniq } from "underscore";

import { BaseCheckbox } from "@hexocean/braintrust-ui-components";
import type {
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowClassNameParams,
  GridRowParams,
  GridRowSelectionModel,
  GridSlotProps,
  GridSortModel,
} from "@hexocean/braintrust-ui-components/components/DataGrid";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import type { EmployerInvoice } from "@js/types/invoices";

import type { EmployerInvoicesTableColumnSortableField } from "../constants";
import { EMPLOYER_INVOICES_SELECTION_LIMIT } from "../constants";
import { useEmployerInvoicesDiscoverSelection } from "../employer-invoices-discover-selection-tooltip";
import { EmployerInvoicesTableCommon } from "../employer-invoices-table-common";
import type {
  EmployerInvoiceItemRow,
  EmployerInvoiceRow,
  EmployerInvoicesTableColumnField,
} from "../types";

import { getEmployerInvoicesTableColumns } from "./columns";
import { EmployerInvoicesTableEmptyState } from "./employer-invoices-empty-state";
import { EmployerInvoicesTableContainer } from "./employer-invoices-table-container";
import type { EmployerInvoicesTableContextValue } from "./employer-invoices-table-context";
import { EmployerInvoicesTableContextProvider } from "./employer-invoices-table-context";
import {
  getEmployerInvoiceItemRow,
  isEmployerInvoiceItemRow,
  isEmployerInvoiceRow,
} from "./helpers";

import styles from "./styles.module.scss";

export type EmployerInvoicesTableComponentProps = {
  invoices: EmployerInvoice[];
  selectedInvoicesIds: number[];
  setSelectedInvoicesIds: (ids: number[]) => void;
  sortBy: EmployerInvoicesTableColumnSortableField | undefined;
  sortDir: "asc" | "desc" | undefined;
  onSortChange: (arg: {
    sortBy: EmployerInvoicesTableColumnSortableField | undefined;
    sortDir: "asc" | "desc" | undefined;
  }) => void;
  visibleColumns: EmployerInvoicesTableColumnField[];
  isLoading: boolean;
  onInvoiceClick: (invoice: EmployerInvoice) => void;
  onInvoicePay: (invoice: EmployerInvoice) => void;
  onInvoiceMarkAsPaid: (invoice: EmployerInvoice) => void;
  onResetFilters: () => void;
};

export const EmployerInvoicesTableComponent = ({
  invoices,
  selectedInvoicesIds,
  setSelectedInvoicesIds,
  visibleColumns,
  sortBy,
  sortDir,
  onSortChange,
  isLoading,
  onInvoiceClick,
  onInvoicePay,
  onInvoiceMarkAsPaid,
  onResetFilters,
}: EmployerInvoicesTableComponentProps) => {
  const {
    selectionIntroductionInvoiceId,
    handleDismissSelection,
    handleTryOutSelection,
  } = useEmployerInvoicesDiscoverSelection({
    invoices,
    setSelectedInvoicesIds,
  });

  const [expandedInvoicesIds, setExpandedInvoicesIds] = useState<number[]>([]);
  const onInvoicePayRef = useEffectRef(onInvoicePay);
  const onInvoiceMarkAsPaidRef = useEffectRef(onInvoiceMarkAsPaid);
  const onResetFiltersRef = useEffectRef(onResetFilters);

  useEffect(() => {
    setExpandedInvoicesIds((prev) => {
      if (!prev.length) {
        return prev;
      }

      return [];
    });
  }, [invoices]);

  const processedData: EmployerInvoiceRow[] = useMemo(() => {
    return invoices.flatMap((invoice) => {
      const isExpanded = expandedInvoicesIds.includes(invoice.id);
      if (!isExpanded) {
        return invoice;
      }

      const expandedInvoiceItemRows: EmployerInvoiceItemRow[] =
        invoice.items.map((item) => {
          return getEmployerInvoiceItemRow({ invoice, item });
        });

      return [invoice, ...expandedInvoiceItemRows];
    });
  }, [invoices, expandedInvoicesIds]);

  const processedColumns = useMemo(() => {
    return getEmployerInvoicesTableColumns({
      visibleColumns,
      data: processedData,
    });
  }, [visibleColumns, processedData]);

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const hiddenColumnFields = processedColumns
      .filter((column) => column.isHidden)
      .map((column) => column.field);

    const visibilityModel: GridColumnVisibilityModel = {};
    for (const hiddenColumnField of hiddenColumnFields) {
      visibilityModel[hiddenColumnField] = false;
    }

    return visibilityModel;
  }, [processedColumns]);

  const sortModel = useMemo<
    [{ field: string; sort: "asc" | "desc" }] | []
  >(() => {
    if (!sortBy || !sortDir) {
      return [];
    }

    return [{ field: sortBy, sort: sortDir }];
  }, [sortBy, sortDir]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const [sortObject] = newSortModel || [];
    onSortChange({
      sortBy: sortObject?.field as
        | EmployerInvoicesTableColumnSortableField
        | undefined,
      sortDir: sortObject?.sort ?? undefined,
    });
  };

  const isSelectionLimitReached =
    selectedInvoicesIds.length >= EMPLOYER_INVOICES_SELECTION_LIMIT;
  const isShowingSelectionIntroductionTooltip =
    !!selectionIntroductionInvoiceId;
  const hasNoData = !isLoading && !invoices.length;
  const currentPageHasSelectedInvoices = useMemo(() => {
    return invoices.some((invoice) => selectedInvoicesIds.includes(invoice.id));
  }, [selectedInvoicesIds, invoices]);

  const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel,
  ) => {
    if (isLoading) {
      return;
    }

    const newSelelectionExceedsLimit =
      selectionModel.length > EMPLOYER_INVOICES_SELECTION_LIMIT;
    if (newSelelectionExceedsLimit && isSelectionLimitReached) {
      return;
    }

    handleDismissSelection();

    if (!newSelelectionExceedsLimit) {
      return setSelectedInvoicesIds(selectionModel as number[]);
    }

    const currentPageInvoicesIds = invoices.map((invoice) => invoice.id);
    const invoiceIdsToSelect = uniq([
      ...selectedInvoicesIds,
      ...currentPageInvoicesIds,
    ]).slice(0, EMPLOYER_INVOICES_SELECTION_LIMIT);
    setSelectedInvoicesIds(invoiceIdsToSelect);
  };

  const handleRowClick: GridEventListener<"rowClick"> = ({
    row,
  }: {
    row: EmployerInvoiceRow;
  }) => {
    const invoice = isEmployerInvoiceRow(row) ? row : row.invoice;
    onInvoiceClick(invoice);
  };

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = (
    { row },
    event,
  ) => {
    const isActionKeyPressed = event.key === "Enter";
    if (!isActionKeyPressed) {
      return;
    }

    onInvoiceClick(row);
  };

  const renderNoRowsOverlay = useCallback(
    () => (
      <EmployerInvoicesTableEmptyState
        onResetFilters={() => onResetFiltersRef.current()}
      />
    ),
    [onResetFiltersRef],
  );

  const handleExpandRowToggle = useCallback((invoice: EmployerInvoice) => {
    setExpandedInvoicesIds((prev) => {
      const isExpanded = prev.includes(invoice.id);
      if (isExpanded) {
        return prev.filter((invoiceId) => invoice.id !== invoiceId);
      }

      return [...prev, invoice.id];
    });
  }, []);

  const contextValue = useMemo(() => {
    const value: EmployerInvoicesTableContextValue = {
      expandedRows: expandedInvoicesIds,
      onExpandRowToggle: handleExpandRowToggle,
      onInvoiceMarkAsPaid: (invoice: EmployerInvoice) =>
        onInvoiceMarkAsPaidRef.current(invoice),
      onInvoicePay: (invoice: EmployerInvoice) =>
        onInvoicePayRef.current(invoice),
      onDismissSelectionIntroductionClick: handleDismissSelection,
      onTryOutSelectionClick: handleTryOutSelection,
      selectionIntroductionInvoiceId,
      isSelectionLimitReached,
      currentPageHasSelectedInvoices,
    };

    return value;
  }, [
    onInvoiceMarkAsPaidRef,
    onInvoicePayRef,
    handleExpandRowToggle,
    expandedInvoicesIds,
    handleTryOutSelection,
    handleDismissSelection,
    selectionIntroductionInvoiceId,
    isSelectionLimitReached,
    currentPageHasSelectedInvoices,
  ]);

  return (
    <EmployerInvoicesTableContextProvider value={contextValue}>
      <EmployerInvoicesTableContainer>
        <EmployerInvoicesTableCommon
          className={cs(styles.invoicesTable, {
            [styles.tableEmpty]: hasNoData,
            [styles.tableDisableScroll]: isShowingSelectionIntroductionTooltip,
          })}
          columnVisibilityModel={columnVisibilityModel}
          columns={processedColumns}
          rows={processedData}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          rowSelectionModel={selectedInvoicesIds}
          onRowSelectionModelChange={handleSelectionModelChange}
          onRowClick={handleRowClick}
          onCellKeyDown={handleCellKeyDown}
          getRowClassName={(
            params: GridRowClassNameParams<EmployerInvoiceRow>,
          ) => {
            const invoice = isEmployerInvoiceRow(params.row)
              ? params.row
              : params.row.invoice;

            return cs({
              [styles.rowOverdue]:
                invoice.status === ENUMS.InvoiceStatus.OVERDUE,
              [styles.rowDashedTop]: isEmployerInvoiceItemRow(params.row),
            });
          }}
          isRowSelectable={(params: GridRowParams) =>
            isEmployerInvoiceRow(params.row)
          }
          classes={{
            overlayWrapper: hasNoData ? styles.noRowsOverlay : undefined,
            overlayWrapperInner: hasNoData
              ? styles.noRowsOverlayContent
              : undefined,
          }}
          slots={{
            baseCheckbox: CustomBaseCheckbox,
            noRowsOverlay: renderNoRowsOverlay,
          }}
        />
      </EmployerInvoicesTableContainer>
    </EmployerInvoicesTableContextProvider>
  );
};

const CustomBaseCheckbox = forwardRef<
  HTMLButtonElement,
  GridSlotProps["baseCheckbox"]
>((props: GridSlotProps["baseCheckbox"], ref) => {
  if (props.disabled) {
    return <></>;
  }

  return <BaseCheckbox {...props} ref={ref} />;
});
