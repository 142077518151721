import { useEffect, useMemo } from "react";
import type {
  TypedWrappedFieldProps,
  WrappedFieldProps,
  WrappedFieldsProps,
} from "redux-form";

import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import {
  ReviewBeforeSendingSummaryItem,
  type ReviewBeforeSendingSummaryItemProps,
} from "@js/apps/jobs/components";
import { useAIRInterview } from "@js/apps/jobs/hooks/use-air-interview";
import { useGetRoleName } from "@js/apps/roles/hooks";
import { sanitize } from "@js/services/dom-sanitize";
import type { TimezoneOption } from "@js/types/jobs";
import {
  assertUnreachable,
  formatBudget,
  formatCurrency,
  getEnumLabel,
  pluralize,
} from "@js/utils";

import { useSkills } from "./use-skills";

type StatusesForReviewDraftsProps = Pick<
  ReviewBeforeSendingSummaryItemProps,
  "isError" | "onEditClick"
>;

export const StatusForTitle = ({
  input,
  onEditClick,
  meta,
}: WrappedFieldProps & StatusesForReviewDraftsProps) => {
  const jobTitle = input.value;

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Job title"
      sectionContent={jobTitle}
      onEditClick={onEditClick}
      isError={!!meta.error}
    />
  );
};

export const StatusForCategory = ({
  role,
  onEditClick,
}: WrappedFieldsProps & StatusesForReviewDraftsProps) => {
  const roleValue: unknown = role.input.value;
  const roleName = useGetRoleName(
    typeof roleValue === "number" ? roleValue : undefined,
  );

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Category"
      sectionContent={roleName}
      onEditClick={onEditClick}
      isError={!!role.meta.error || !roleName}
    />
  );
};

const getLocationAndTimezoneLabel = (
  location: string | null,
  timezone: string | null,
): string => {
  if (!location && !timezone) {
    return "no location or time zone requirements";
  } else {
    if (location && !timezone) {
      return `${location}, no time zone requirement`;
    }
    if (!location && timezone) {
      return `no location requirement, ${timezone}`;
    }
    if (location && timezone) {
      return `${location}, ${timezone}`;
    }

    // Shouldn't exist
    return "";
  }
};

export const StatusForProjectDetails = ({
  contract_type,
  expected_hours_per_week,
  locations,
  timezones,
  onEditClick,
}: WrappedFieldsProps & StatusesForReviewDraftsProps) => {
  const isError =
    !!contract_type.meta.error ||
    !!expected_hours_per_week.meta.error ||
    !!locations.meta.error ||
    !!timezones.meta.error;
  const contractTypeValue = contract_type.input.value;
  const expectedHoursValue = expected_hours_per_week.input.value;

  const formattedLocationValue = useMemo(() => {
    const locationValue: TalentMixedLocation[] = locations.input.value || [];
    return locationValue
      .map((location) => {
        return location?.name ?? location?.formatted_address ?? locationValue;
      })
      .join(" | ");
  }, [locations.input.value]);

  const formattedTimezoneValue = useMemo(() => {
    const timezoneValue: TimezoneOption[] = timezones.input.value || [];
    return timezoneValue
      .map((timezone) => {
        return timezone.value;
      })
      .join(", ");
  }, [timezones.input.value]);

  const contractTypeLabel = getEnumLabel(
    ENUMS.JobContractTypeLabels,
    contractTypeValue,
  );
  const expectedHoursLabel = `${expectedHoursValue} hours`;

  const locationAndTimezoneLabel = getLocationAndTimezoneLabel(
    formattedLocationValue,
    formattedTimezoneValue,
  );

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Project details"
      sectionContent={`${contractTypeLabel}, ${expectedHoursLabel}, ${locationAndTimezoneLabel}`}
      ellipsis={false}
      onEditClick={onEditClick}
      isError={isError}
    />
  );
};

export const StatusForSkills = ({
  new_skills,
  role,
  onEditClick,
}: WrappedFieldsProps & StatusesForReviewDraftsProps) => {
  const selectedRole = role.input.value;
  const { skillsFromStore, fetchSkills } = useSkills(selectedRole);
  const newSkills = useMemo<number[]>(
    () => new_skills.input.value || [],
    [new_skills.input.value],
  );

  const skillsCount = newSkills?.length;

  useEffect(() => {
    const missingSkills = newSkills.filter((id) => {
      return !skillsFromStore.some((skill) => skill.id === id);
    });

    if (missingSkills.length > 0) {
      fetchSkills({ ids: missingSkills });
    }
  }, [skillsFromStore, newSkills, fetchSkills]);

  const skillsLabels = newSkills.map((id) => {
    return skillsFromStore.find((skill) => skill.id === id)?.name ?? "";
  });

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Skills"
      sectionContent={`${skillsLabels.join(", ")}${
        skillsCount > newSkills.length ? ` and ${skillsCount - 3} more` : ""
      }`}
      onEditClick={onEditClick}
      isError={!!new_skills.meta.error}
    />
  );
};

export const StatusForExperience = ({
  input,
  onEditClick,
  meta,
}: WrappedFieldProps & StatusesForReviewDraftsProps) => {
  const experience = input.value;
  const experienceLabel =
    getEnumLabel(ENUMS.JobExperienceLevelLabels, experience) || "None";

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Experience"
      sectionContent={experienceLabel}
      onEditClick={onEditClick}
      isError={!!meta.error}
    />
  );
};

export const StatusForRate = ({
  hourly_rate,
  min_rate,
  max_rate,
  min_annual_rate,
  max_annual_rate,
  fixed_rate,
  payment_type,
  onEditClick,
}: WrappedFieldsProps & StatusesForReviewDraftsProps) => {
  const _hourly_rate = hourly_rate.input.value;
  const _min_rate = min_rate.input.value;
  const _max_rate = max_rate.input.value;
  const _fixed_rate = fixed_rate.input.value;
  const paymentType: EnumType<typeof ENUMS.JobPaymentType> =
    payment_type.input.value;

  const checkIsMissingData = () => {
    if (!paymentType) {
      return;
    }

    switch (paymentType) {
      case ENUMS.JobPaymentType.FIXED_PRICE: {
        return !_fixed_rate;
      }
      case ENUMS.JobPaymentType.HOURLY: {
        if (_hourly_rate !== "range" && _hourly_rate !== "ai") {
          return !_hourly_rate;
        }

        return !_min_rate && !_max_rate;
      }
      case ENUMS.JobPaymentType.ANNUAL: {
        if (!min_annual_rate.input.value && !max_annual_rate.input.value) {
          return true;
        }

        return !min_annual_rate.input.value || !max_annual_rate.input.value;
      }
      default: {
        assertUnreachable(paymentType);
        return false;
      }
    }
  };

  const formattedValue = () => {
    const options = { removeDecimal: true };

    const hrSuffix = "/hr";

    if (!paymentType) {
      return;
    }

    switch (paymentType) {
      case ENUMS.JobPaymentType.FIXED_PRICE: {
        return `${formatCurrency(_fixed_rate, options)}`;
      }
      case ENUMS.JobPaymentType.HOURLY: {
        if (_hourly_rate !== "range" && _hourly_rate !== "ai") {
          return `${formatCurrency(_hourly_rate, options)}${hrSuffix}`;
        }

        return (
          "$" +
          (
            formatBudget(Math.round(_min_rate), Math.round(_max_rate), {
              paymentType,
              removeDecimal: true,
              hourlyRateSuffix: hrSuffix,
            }) as string
          )?.replaceAll("$", "")
        );
      }
      case ENUMS.JobPaymentType.ANNUAL: {
        if (!min_annual_rate.input.value && !max_annual_rate.input.value) {
          return undefined;
        }
        return (
          "$" +
          (
            formatBudget(
              Math.round(min_annual_rate.input.value),
              Math.round(max_annual_rate.input.value),
              {
                paymentType,
                removeDecimal: true,
              },
            ) as string
          )?.replaceAll("$", "")
        );
      }
      default: {
        assertUnreachable(paymentType);
        return "";
      }
    }
  };

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Rate"
      sectionContent={formattedValue()}
      onEditClick={onEditClick}
      isError={checkIsMissingData()}
    />
  );
};

export const StatusForJobDescription = ({
  introduction,
  description,
  onEditClick,
}: WrappedFieldsProps & StatusesForReviewDraftsProps) => {
  const introductionContent =
    description.input.value || introduction.input.value;
  const strippedString = sanitize(introductionContent, {
    ALLOWED_TAGS: [],
  }).replace(/(\&nbsp;)/gm, " ");

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Job description"
      sectionContent={strippedString}
      onEditClick={onEditClick}
      isError={!!description.meta.error}
    />
  );
};

export const StatusForApplicationQuestions = ({
  input,
  onEditClick,
  meta,
}: TypedWrappedFieldProps<{ question: string }[]> &
  StatusesForReviewDraftsProps) => {
  const new_application_questions = !!input.value
    ? input.value.filter((q) => q.question)
    : [];

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Application questions"
      sectionContent={
        !!new_application_questions?.length
          ? `${
              new_application_questions?.length
            } application question${pluralize(
              new_application_questions?.length,
            )}`
          : "None"
      }
      onEditClick={onEditClick}
      isError={!!meta.error}
    />
  );
};

export const StatusForAiInterview = ({ input }: WrappedFieldProps) => {
  const { showAIRInterview } = useAIRInterview();
  const is_ai_interview_enabled = input.value;

  if (!showAIRInterview) {
    return null;
  }

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="AI interview"
      sectionContent={`AI interviewing is ${is_ai_interview_enabled ? "enabled" : "disabled"}`}
    />
  );
};
