import React, { useEffect } from "react";

import { useUser } from "@js/apps/common/hooks";
import { getFreelancerMyWorkLinkPath } from "@js/apps/freelancer/utils/get-my-work-path";
import { DashboardMyJobsPage } from "@js/apps/freelancer/views/dashboard/jobs-history/my-jobs";
import { DashboardMyInvitesPage } from "@js/apps/freelancer/views/dashboard/my-invites";
import { useNavigate } from "@js/hooks";
import { ACCESS_RULE } from "@js/routes/constants";
import { ProtectedRoute } from "@js/routes/protected-route";

export const MyWorkIndexRedirect = () => {
  const user = useUser();
  const path = getFreelancerMyWorkLinkPath(user);

  const navigate = useNavigate();

  useEffect(() => {
    navigate(path, { replace: true });
  }, [navigate, path]);

  /*Required to prevent screen flash */
  return (
    <ProtectedRoute rules={[ACCESS_RULE.HAS_FREELANCER]}>
      <ComponentThatWillRenderAfterRedirect path={path} />
    </ProtectedRoute>
  );
};

const ComponentThatWillRenderAfterRedirect = ({ path }: { path: string }) => {
  if (path === "/talent/dashboard/my_jobs/history/") {
    return <DashboardMyJobsPage />;
  }
  if (path === "/talent/dashboard/my_jobs/invites/") {
    return <DashboardMyInvitesPage />;
  }
};
