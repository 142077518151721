import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { KeyboardArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useGetPostQuery } from "@js/apps/give-and-get-help/api";
import { EditPostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { useHandleDeleteSinglePost } from "@js/apps/give-and-get-help/hooks/websocket-subscribe/handle-delete-single-post";
import { Post } from "@js/apps/give-and-get-help/views/single-post";
import { CreateSpacePost } from "@js/apps/spaces/components";
import { useRedirectFromSpacesPostToFeedPost } from "@js/apps/spaces/hooks/use-redirect-from-spaces-post-to-feed-post";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";
import { castToInteger } from "@js/utils/numbers";

import { useGetSpaceDetailsQuery } from "../../api";
import { SpaceHeader } from "../../components";
import { openJoinThisSpaceModal } from "../../components/join-the-space-modal/join-this-space-modal";
import { JoinThisSpaceModalInstance } from "../../components/join-the-space-modal/join-this-space-modal-instance";
import { SpacePostsWrapper } from "../../components/space-posts-wrapper";
import { useHandleGetSpaceError } from "../../hooks/use-handle-get-space-error";
import { useIsSpaceAdmin } from "../../hooks/use-is-space-admin";
import { useIsSpaceMember } from "../../hooks/use-is-space-member";
import { useSpaceNotifications } from "../../hooks/use-space-notifications";

import styles from "./styles.module.scss";

export const SinglePostSpacePage = () => {
  const params = useParams();
  const spaceId = castToInteger(params.spaceId);
  const singlePostId = castToInteger(params.spacePostId);

  const user = useUser();

  if (!spaceId || !singlePostId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (!user) {
    return <Navigate to={`/spaces/${spaceId}/`} replace />;
  }

  return <SpacesSinglePost postId={singlePostId} spaceId={spaceId} />;
};

const SpacesSinglePost = ({
  postId,
  spaceId,
}: {
  postId: number;
  spaceId: number;
}) => {
  const { data: spaceData, error } = useGetSpaceDetailsQuery(
    { id: spaceId },
    { refetchOnMountOrArgChange: true },
  );

  useHandleGetSpaceError(error);

  const { isSpaceMember } = useIsSpaceMember({ spaceId });
  const { isSpaceAdmin } = useIsSpaceAdmin({ spaceId });
  const { areNotificationsTurnOn } = useSpaceNotifications();

  const { data: post, isFetching: loading } = useGetPostQuery(
    postId ? { id: postId } : skipToken,
  );

  useRedirectFromSpacesPostToFeedPost(post);
  useHandleDeleteSinglePost(post);

  const isMobile = useMediaQuery("sm");

  if (!spaceData) {
    return <></>;
  }

  return (
    <AppLayout bgcolor="var(--soft-green)" pageTitle="Spaces">
      <Box className={styles.container}>
        <SpaceHeader
          id={spaceData.id}
          title={spaceData.name}
          avatarSource={spaceData.avatar_thumbnail ?? ""}
          membersCount={spaceData.total_members}
          isMember={isSpaceMember}
          isAdmin={isSpaceAdmin}
          areNotificationsTurnOn={areNotificationsTurnOn}
        />

        <Button
          RouterLink={RouterLink}
          variant="secondary"
          fontWeight={500}
          endIcon={<KeyboardArrowRightIcon />}
          size={isMobile ? "x-small" : "small"}
          to={`/spaces/${spaceData.id}/`}
          className={styles.backBtn}
        >
          Go to Discussion
        </Button>

        <PostLocation.Provider
          value={PostLocation.Values.single_space_post_view}
        >
          <Box mt={3} mb={2}>
            <CreateSpacePost spaceData={spaceData} />
          </Box>

          <SpacePostsWrapper
            onBlockedInteraction={() => openJoinThisSpaceModal(spaceData)}
            isSpaceMember={isSpaceMember}
          >
            <Post post={post} loading={loading} />
          </SpacePostsWrapper>

          <EditPostModalInstance />
        </PostLocation.Provider>
      </Box>
      <JoinThisSpaceModalInstance />
    </AppLayout>
  );
};
