/* eslint-disable max-len */
import { useCallback } from "react";
import { SubmissionError } from "redux-form";
import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import {
  CloseModalOnLocationChange,
  ModalInstance,
} from "@js/components/modal";
import { sanitize } from "@js/services";
import type { AppDispatch } from "@js/store";
import type { Freelancer, FreelancerPublic } from "@js/types/freelancer";
import { getTruncatedText } from "@js/utils";

import { useUpdateFreelancerCustomizationMutation } from "../../api";
import { MODULE_EDIT_MODAL_PROPERTIES } from "../../constants";
import type { GetToKnowMeFormData } from "../../forms/get-to-know-me-form";
import { GetToKnowMeForm } from "../../forms/get-to-know-me-form";

type GetToKnowMeCardProps = {
  profile: FreelancerPublic;
};

export const GetToKnowMeCard = ({ profile }: GetToKnowMeCardProps) => {
  const user = useUser();
  const freelancer = useFreelancerProfile();
  const isOwnProfile = user && profile.id === user.freelancer;

  const [updateFreelancerCustomization] =
    useUpdateFreelancerCustomizationMutation();

  const subheader = getSubheader(profile.user.introduction_headline);
  const introduction = getIntroduction(profile);

  const publicEmptyState = !subheader && !introduction && !isOwnProfile;
  const ownEmptyState = !subheader && !introduction && isOwnProfile;

  const handleOpenEditModal = useCallback(() => {
    const onSubmit = async (data: GetToKnowMeFormData) =>
      await updateFreelancerCustomization({ id: profile.id, data })
        .unwrap()
        .catch((error) => {
          throw new SubmissionError(error.data);
        });

    if (freelancer) openEditModal(freelancer, onSubmit);
  }, [freelancer, profile.id, updateFreelancerCustomization]);

  if (publicEmptyState) {
    return <EmptyState name={profile.user.first_name} />;
  }

  return (
    <Box
      className={cs("about", {
        "profile-empty-state": ownEmptyState,
      })}
    >
      <Box mb="auto">
        <Box display="flex" justifyContent="space-between">
          <Box fontSize={30}>👋</Box>
          {!ownEmptyState && isOwnProfile && (
            <IconButton
              variant="black-outlined"
              size="x-small"
              aria-label="edit bio"
              onClick={handleOpenEditModal}
            >
              <EditPenIcon />
            </IconButton>
          )}
        </Box>
        {(subheader || isOwnProfile) && (
          <Typography
            multilineEllipsis={2}
            component="p"
            variant="title"
            size="small"
            fontWeight={400}
            mt={1}
          >
            {subheader || "Add your headline and bio"}
          </Typography>
        )}
        {(introduction || isOwnProfile) && (
          <Typography
            component="p"
            variant="paragraph"
            mt={3}
            fontStyle={ownEmptyState ? "italic" : "normal"}
            sx={{ wordBreak: "break-word" }}
          >
            {introduction ||
              "Share more about yourself and what you hope to accomplish."}
          </Typography>
        )}
      </Box>
      {ownEmptyState && (
        <Box mt={2}>
          <Button
            onClick={handleOpenEditModal}
            shape="squared"
            variant="secondary"
          >
            Add bio
          </Button>
        </Box>
      )}
    </Box>
  );
};

const openEditModal = (
  profile: Freelancer,
  onSubmit: (data: GetToKnowMeFormData) => Promise<Freelancer>,
) =>
  ModalInstance.open({
    children: (
      <GetToKnowMeForm
        onSubmit={onSubmit}
        onSubmitSuccess={(_result, dispatch: AppDispatch) => {
          dispatch(fetchFreelancerProfile(profile.id));
          dispatch(fetchCurrentUser());
          ModalInstance.close();
        }}
        initialValues={{
          user: {
            introduction_headline: profile.user?.introduction_headline,
            introduction: profile.user?.introduction,
          },
        }}
      />
    ),
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

const openReadMoreModal = (
  subheader: string | null,
  introduction: string | null,
  name: string,
) =>
  ModalInstance.open({
    children: (
      <Box maxWidth={464}>
        <CloseModalOnLocationChange closeModal={ModalInstance.close}>
          <Box display="flex" gap={1} alignItems="center" marginRight={5}>
            <Box fontSize={30}>👋</Box>
            <Typography
              component="h3"
              variant="title"
              size="small"
              ellipsis
              maxWidth="100%"
            >
              About {name}
            </Typography>
          </Box>
          <Typography
            component="p"
            variant="paragraph"
            size="large"
            mt={3}
            mb={3}
          >
            {subheader}
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            maxHeight={286}
            pr={4}
            sx={{
              overflowY: "hidden",
              "&:hover": { overflowY: "auto" },
            }}
          >
            {introduction}
          </Typography>
        </CloseModalOnLocationChange>
      </Box>
    ),
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });

const EmptyState = ({ name }: { name: string }) => {
  return (
    <Box className="about">
      <Box fontSize={30}>👋</Box>
      <Typography component="h3" variant="title" size="small" mt={2} ellipsis>
        Meet {name}
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        mt={3}
        fontStyle="italic"
        multipleEllipsis
      >
        {name} is still crafting the perfect bio. If we had to guess, they’re
        probably a big deal.
      </Typography>
    </Box>
  );
};

const getIntroduction = (profile: FreelancerPublic) => {
  if (!profile.user.introduction) return null;

  const introduction = sanitize(profile.user.introduction, {
    ALLOWED_TAGS: [],
  });

  // We need to truncate text to preserve the height of the card (max 3 lines)
  const truncatedIntroduction = getTruncatedText(introduction);
  if (introduction.length > truncatedIntroduction.length) {
    return (
      <>
        {truncatedIntroduction} ·{" "}
        <button
          className="btn-reset pointer text-underline"
          onClick={() =>
            openReadMoreModal(
              getSubheader(profile.user.introduction_headline),
              introduction,
              profile.user.first_name,
            )
          }
        >
          Read more
        </button>
      </>
    );
  }

  return introduction;
};

const getSubheader = (subheader: string | null) => {
  if (!subheader) return null;
  return sanitize(subheader, { ALLOWED_TAGS: [] });
};
