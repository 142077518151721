import { ModalInstance } from "@js/components/modal";

import { BoostSuccessModal } from "./boots-success-modal-content";
export const openBoostSuccessModal = (): void => {
  ModalInstance.open({
    padding: false,
    closeButton: true,
    keepOnBackdropClick: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
        padding: "2px",
      },
    },
    children: <BoostSuccessModal />,
  });
};
