import React, { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { Box, Loader } from "@hexocean/braintrust-ui-components";
import { TOGGLE_HIDE_REQUEST_LOADER } from "@js/apps/common/action-types";
import { useUser } from "@js/apps/common/hooks";
import { EditPostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { CreatePostSection } from "@js/apps/give-and-get-help/components/create-post";
import { PostItem } from "@js/apps/give-and-get-help/components/post";
import { useMarkCareerHelpVisited } from "@js/apps/give-and-get-help/hooks";
import { useRedirectFromFeedPostToSpacesPost } from "@js/apps/give-and-get-help/hooks/use-redirect-from-feed-post-to-spaes-post";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { PageHead } from "@js/components/page-head";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";
import type { IPost } from "@js/types/give-and-get-help";
import { getTruncatedText } from "@js/utils";

import { useGetPostQuery } from "../../api";
import { FeedHeader } from "../../components";
import { PostsListEmptyState } from "../../components/posts-list-empty-state";
import { PostLocation } from "../../context/post-location";
import { PostViewedContextProvider } from "../../context/post-viewed";
import { PostsContextProvider } from "../../context/posts";
import { useSubscribePostOrComment } from "../../hooks";
import { useShowSnackbarOnCelebratoryPostCreation } from "../../hooks/use-show-snackbar-on-celebratory-post-creation";
import { useHandleDeleteSinglePost } from "../../hooks/websocket-subscribe/handle-delete-single-post";
import { PublicPostPage } from "../public-single-post/index";

export const SinglePostPageWrapper = () => {
  const user = useUser();

  if (!user) {
    return <PublicPostPage />;
  }

  return <SinglePostPage />;
};

export const SinglePostPage = () => {
  const postId = useIdParam();
  const { data: post, isFetching: loading } = useGetPostQuery(
    postId ? { id: postId } : skipToken,
  );
  useHandleDeleteSinglePost(post);
  useShowSnackbarOnCelebratoryPostCreation(post);

  const postToSubscribe = useMemo(() => (post ? [post.id] : []), [post]);
  useSubscribePostOrComment(postToSubscribe);

  const dispatch = useAppDispatch();
  const hideRequestLoader = useAppSelector(
    (state) => state.common.hideRequestLoader,
  );

  useMarkCareerHelpVisited();

  useRedirectFromFeedPostToSpacesPost(post);

  useEffect(() => {
    if (!hideRequestLoader) {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    }

    return () => {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!postId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  const metaDescriptionContent = getTruncatedText(post?.text || "", 150, 150);

  return (
    <UniversalSearchContextProvider initialTab="CAREER_HELP">
      <PostLocation.Provider value={PostLocation.Values.single_post_view}>
        {post && (
          <PageHead>
            <meta property="og:title" content={post.title} />
            <meta property="description" content={metaDescriptionContent} />
            <meta property="og:description" content={metaDescriptionContent} />
          </PageHead>
        )}
        <AppLayout bgcolor="var(--soft-green)" pageTitle="Feed">
          <Box
            display="flex"
            flexDirection="column"
            justifyItems="center"
            mx="auto"
            width="100%"
            maxWidth="944px"
          >
            <FeedHeader showBackButton />

            <CreatePostSection />

            <Box mt={4}>
              <PostViewedContextProvider>
                <Post post={post} loading={loading} />
              </PostViewedContextProvider>
            </Box>
          </Box>
          <EditPostModalInstance />
        </AppLayout>
      </PostLocation.Provider>
    </UniversalSearchContextProvider>
  );
};

type PostProps = {
  post?: IPost | null;
  loading: boolean;
};

export const Post = ({ post, loading }: PostProps) => {
  const postId = post?.id;
  const initialExpandedIds = useMemo(() => {
    if (typeof postId === "undefined") {
      return [];
    }

    return [postId];
  }, [postId]);

  if (loading) {
    return <Loader centered />;
  }

  if (!post) {
    return <PostsListEmptyState>Post not found</PostsListEmptyState>;
  }

  return (
    <PostsContextProvider initialExpandedIds={initialExpandedIds}>
      <PostItem postData={post} isSinglePostView />
    </PostsContextProvider>
  );
};
