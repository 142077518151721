import { z } from "zod";

export const zodStringBoolean = z
  .string()
  .transform((value) => {
    if (value.toLowerCase() === "true") {
      return true;
    }

    if (value.toLowerCase() === "false") {
      return false;
    }

    return undefined;
  })
  .optional();
