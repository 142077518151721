import cs from "classnames";

import { IconButton } from "@hexocean/braintrust-ui-components";
import type {
  DataGridProps,
  GridColumnHeaderSortIconProps,
  GridRowProps,
} from "@hexocean/braintrust-ui-components/components/DataGrid";
import {
  DataGrid,
  GridRow,
} from "@hexocean/braintrust-ui-components/components/DataGrid";
import { KeyboardArrowUpIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./styles.module.scss";

type EmployerInvoicesTableCommonProps = DataGridProps;

export const EmployerInvoicesTableCommon = ({
  className,
  slots,
  ...rest
}: EmployerInvoicesTableCommonProps) => {
  return (
    <DataGrid
      autoHeight
      className={cs(styles.table, className)}
      disableVirtualization
      disableColumnMenu
      disableColumnResize
      disableColumnSelector
      hideFooter
      sortingMode="server"
      rowHeight={64}
      columnHeaderHeight={60}
      slots={{
        columnResizeIcon: EmptyColumnResizeIcon,
        columnHeaderSortIcon: ColumnHeaderSortIcon,
        row: CustomGridRow,
        ...slots,
      }}
      {...rest}
    />
  );
};

const CustomGridRow = (props: GridRowProps) => {
  return <GridRow {...props} data-testid={`invoice-${props.row.id}`} />;
};

const EmptyColumnResizeIcon = () => {
  return <></>;
};

const ColumnHeaderSortIcon = (props: GridColumnHeaderSortIconProps) => {
  const direction = props.direction;
  const isSorted = !!direction;

  return (
    <IconButton
      variant="transparent"
      aria-label="Sort button"
      sx={{ p: "0!important", ml: "3px" }}
      size="medium"
      tabIndex={-1}
      data-sorting={isSorted}
    >
      <KeyboardArrowUpIcon
        sx={{
          color: isSorted ? "var(--black)" : "var(--grey-2)",
          fontSize: "24px",
          borderRadius: "50%",
          backgroundColor: isSorted ? "var(--medium-green)" : undefined,
          transform: direction === "desc" ? "rotate(180deg)" : undefined,
        }}
      />
    </IconButton>
  );
};
