import { Field } from "redux-form";

import { TextField } from "@js/forms/fields";

import { InvoiceSearchFiltersInstance } from "../../filters/invoice-search-filters";

const INVOICE_SEARCH_FILTER_FORM_ID = "invoiceSearchFilterForm";

export const InvoiceSearchFilter = () => {
  return (
    <InvoiceSearchFiltersInstance form={INVOICE_SEARCH_FILTER_FORM_ID}>
      <Field
        variant="standard"
        id="invoice-search-filter"
        name="search"
        label="Search"
        size="small"
        style={{
          minWidth: 150,
        }}
        component={TextField}
      />
      <input type="submit" className="invisible" />
    </InvoiceSearchFiltersInstance>
  );
};
