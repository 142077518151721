import React from "react";
import { useSearchParams } from "react-router-dom";
import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import {
  CircleCheckGreenIcon,
  CircleUncheckedIcon,
  SkillIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { StyledPopoverForm } from "@js/apps/common/components/filters/fields/styled-popover-form.styles";
import { ApprovalStatusForm } from "@js/apps/common/components/filters/forms/approval-status-form";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import {
  APPROVAL_STATUS_LABELS,
  APPROVAL_STATUS_VALUE_TO_LABEL,
  APPROVAL_STATUS_VALUES,
} from "@js/apps/freelancer/views/talent-list/fields/approval-status-filter/constants";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import { PossibleFilters } from "@js/types/common";

const formId = "talent-filters__approve-status";

type ApprovalStatusLabelKey = keyof typeof APPROVAL_STATUS_VALUE_TO_LABEL;

export const ApprovalStatusFilter = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [params] = useSearchParams();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.APPROVAL_STATUS,
      filter_value: values,
    });
  };

  const userUiApprovalFilterChoices = params.get("talent_status");
  const approvalParamFilter = params.get("approved") as ApprovalStatusLabelKey;

  const areBothOptionsSelected = !!(
    userUiApprovalFilterChoices &&
    userUiApprovalFilterChoices?.split(",").length > 1
  );

  const isApprovalOptionSelected = !!approvalParamFilter;

  const label = (() => {
    if (areBothOptionsSelected) return APPROVAL_STATUS_VALUE_TO_LABEL["both"];
    if (isApprovalOptionSelected)
      return APPROVAL_STATUS_VALUE_TO_LABEL[approvalParamFilter];
    return APPROVAL_STATUS_VALUE_TO_LABEL["default"];
  })();

  return (
    <JobPopoverFilterButton
      startIcon={<SkillIcon />}
      isActive={isApprovalOptionSelected || areBothOptionsSelected}
      label={label}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      popoverContent={
        <ApprovalStatusForm
          form={formId}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Field
                submit={submit}
                name="talent_status"
                component={ApprovalStatusFilterContent}
              />
            );
          }}
        </ApprovalStatusForm>
      }
    />
  );
};

type ApprovalStatusFilterContentProps = WrappedFieldProps & {
  submit: () => void;
};

export const ApprovalStatusFilterContent = ({
  submit,
  input,
  meta,
}: ApprovalStatusFilterContentProps) => {
  const talentStatusField = { input, meta };
  const handleReset = () => {
    talentStatusField.input.onChange(null);
  };

  const APPROVAL_STATUS_OPTIONS = [
    {
      label: (
        <ApprovalStatusLabel
          title={APPROVAL_STATUS_LABELS.approved}
          subtitle="Braintrust approved Talent"
        />
      ),
      value: APPROVAL_STATUS_VALUES.approved,
    },
    {
      label: (
        <ApprovalStatusLabel
          title={APPROVAL_STATUS_LABELS.notApproved}
          subtitle="Talent hasn't been approved yet"
        />
      ),
      value: APPROVAL_STATUS_VALUES.notApproved,
    },
  ];

  return (
    <JobPopoverFilterButton.Content
      maxWidth={640}
      onApply={submit}
      onReset={handleReset}
    >
      <StyledPopoverForm>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <ButtonSelectField
            className="job-type-filter-button"
            labelledBy="sub-category-label"
            variant="transparent-border-beige"
            selectedVariant="violet"
            labelVariant="primary"
            startIcon={<CircleUncheckedIcon />}
            selectedIcon={<CircleCheckGreenIcon />}
            shape="squared"
            canSingleValueBeEmpty
            multiple
            options={APPROVAL_STATUS_OPTIONS}
            input={talentStatusField.input}
            meta={talentStatusField.meta}
          />
        </Box>
      </StyledPopoverForm>
    </JobPopoverFilterButton.Content>
  );
};

type ApprovalStatusLabelType = { title: string; subtitle: string };

const ApprovalStatusLabel = ({ title, subtitle }: ApprovalStatusLabelType) => {
  return (
    <Box ml={1}>
      <Typography component="p" variant="label" textAlign="left" size="large">
        {title}
      </Typography>
      <Typography component="p" textAlign="left" size="small">
        {subtitle}
      </Typography>
    </Box>
  );
};
