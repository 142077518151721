import { useEffect, useMemo, useRef } from "react";
import _ from "underscore";

import type { JobDraft, JobFormValues } from "@js/types/jobs";

import { useSaveLastJobDraft } from "../use-save-last-job-draft";

type UseSaveLastJobDraftOnChangeArg = {
  draft: JobDraft | undefined;
};

export const useSaveLastJobDraftOnChange = ({
  draft,
}: UseSaveLastJobDraftOnChangeArg) => {
  const { saveLastJobDraft } = useSaveLastJobDraft();
  const formStateRef = useRef<{
    lastSavedDraft: JobDraft | undefined;
    wasDirty: boolean;
  }>({
    lastSavedDraft: draft,
    // track wasDirty in case user rolls back the changes making the form not dirty ...
    // ... in which case we want to save the latest draft anyway
    wasDirty: false,
  });
  formStateRef.current.lastSavedDraft = draft;

  const saveLastDraftDebounced = useMemo(() => {
    return _.debounce(
      (
        values: JobFormValues,
        _dispatch: unknown,
        props: { dirty: boolean; submitting: boolean },
      ) => {
        if (
          !formStateRef.current ||
          values.isDeletingJobDraft ||
          props.submitting
        ) {
          return;
        }

        if (props.dirty) {
          formStateRef.current.wasDirty = true;
        }

        const canSaveLastJobDraft = jobFormHasRequiredJobDraftFields(values);
        if (!formStateRef.current.wasDirty || !canSaveLastJobDraft) {
          return;
        }
        saveLastJobDraft({
          values,
          lastSavedDraft: formStateRef.current.lastSavedDraft,
        });
      },
      2000,
    );
  }, [formStateRef, saveLastJobDraft]);

  // cancel saveLastDraft call after unmount
  useEffect(
    () => () => {
      saveLastDraftDebounced.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { onChange: saveLastDraftDebounced };
};

const jobDraftRequiredFields: Array<keyof JobDraft> = ["title"];

const jobFormHasRequiredJobDraftFields = (formValues: JobFormValues) =>
  jobDraftRequiredFields.every((field) => !!formValues[field]);
