import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import { useGetATSIntegrationsQuery } from "@js/apps/employer/api";
import { useMergeLinkComponent } from "@js/apps/employer/hooks/merge-link-component";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

import { clickSyncATSHomeDashboard } from "../../actions";

const ATS_INTEGRATION_PATH = "/employer/edit/ats_integration/";

export const useIntegrationDashboardModule = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const handleIntegrationSuccess = () => {
    if (pathname !== ATS_INTEGRATION_PATH) {
      navigate(ATS_INTEGRATION_PATH);
    }
  };

  const { initializeATSIntegration, isATSIntegrationDisabled } =
    useMergeLinkComponent({
      onSuccess: handleIntegrationSuccess,
    });

  const disabledButton = isATSIntegrationDisabled;
  const user = useUser();
  const employerId = user?.employer;
  const { data: integrations, isLoading: isIntegrationsLoading } =
    useGetATSIntegrationsQuery(undefined, { skip: !employerId });

  const trackClickInDashboardModule = useCallback(
    () => dispatch(clickSyncATSHomeDashboard()),
    [dispatch],
  );

  const syncJobsButtonProps = useMemo(() => {
    return !isIntegrationsLoading && !!integrations?.length
      ? {
          to: ATS_INTEGRATION_PATH,
          RouterLink: RouterLink,
          onClick: () => trackClickInDashboardModule(),
          disabled: undefined,
        }
      : { disabled: disabledButton };
  }, [
    isIntegrationsLoading,
    integrations,
    disabledButton,
    trackClickInDashboardModule,
  ]);

  const handleDashboardSyncJobsClick = useCallback(() => {
    trackClickInDashboardModule();
    initializeATSIntegration();
  }, [trackClickInDashboardModule, initializeATSIntegration]);

  return {
    handleSyncJobsClick: handleDashboardSyncJobsClick,
    disabledButton,
    syncJobsButtonProps,
  };
};
