import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import { CalendarIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";

import { EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL } from "../../constants";
import { DateRangeForm } from "../forms";
import type { EmployerInvoicesFormValues } from "../schema";

type DateRangeFilterProps = {
  control: Control<EmployerInvoicesFormValues>;
  onChange: (
    value: Pick<EmployerInvoicesFormValues, "date_after" | "date_before">,
  ) => void;
};

export const DateRangeFilter = ({
  control,
  onChange,
}: DateRangeFilterProps) => {
  const {
    field: { value: dateBefore, onChange: onDateBeforeChange },
  } = useController({
    name: "date_before",
    control: control,
  });
  const {
    field: { value: dateAfter, onChange: onDateAfterChange },
  } = useController({
    name: "date_after",
    control: control,
  });
  const isDateRangeApplied = Boolean(dateAfter) || Boolean(dateBefore);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const handleApply = (
    values: Pick<EmployerInvoicesFormValues, "date_after" | "date_before">,
  ) => {
    onDateAfterChange(values.date_after);
    onDateBeforeChange(values.date_before);
    onChange(values);
    handleClose();
  };

  return (
    <FilterPopover
      label={EMPLOYER_INVOICES_FILTER_TO_APPLY_LABEL.date_range}
      icon={<CalendarIcon sx={{ path: { strokeWidth: "1" } }} />}
      isFilterApplied={isDateRangeApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handleClose}
      anchorElement={anchorElement}
      variant="green"
    >
      <DateRangeForm
        dateBefore={dateBefore}
        dateAfter={dateAfter}
        onApply={handleApply}
      />
    </FilterPopover>
  );
};
