import React, { useCallback } from "react";
import classNames from "classnames";

import type { FormValue } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import { offerPriceClicked } from "@js/apps/give-and-get-help/actions";
import {
  useCommentContext,
  usePostLocationContext,
} from "@js/apps/give-and-get-help/context";
import type { ReplyType } from "@js/apps/give-and-get-help/types";
import { InfoTooltip } from "@js/components/info-tooltip";
import { useAppDispatch } from "@js/hooks";
import { assertUnreachable } from "@js/utils";

import { usePostContext } from "../../context";
import type { BudgetFieldProps } from "../../forms/fields/budget-field";
import { BudgetField } from "../../forms/fields/budget-field";

const TOOLTIP_TEXT = `Earn BTRST by offering your expertise to other users within the Braintrust Network.
By helping others with their professional development you're helping grow and improve the network.`;

type CommentsBudgetProps = Optional<BudgetFieldProps, "options"> & {
  editMode?: boolean;
  type?: ReplyType;
};

export const CommentsBudget = ({
  options,
  editMode,
  ...rest
}: CommentsBudgetProps) => {
  if (!options) return null;

  const commentsBudgetClasses = classNames("comments-budget", {
    "comments-budget--off-white": editMode,
  });

  return (
    <div className={commentsBudgetClasses}>
      {!editMode && (
        <Typography component="p" variant="label" size="small" mb={1}>
          Offer 1:1 help
          <InfoTooltip title={TOOLTIP_TEXT} />
        </Typography>
      )}
      <Typography component="p" variant="paragraph" size="large" mb={1}>
        Set your price
      </Typography>
      <CommentsBudgetField options={options} {...rest} />
    </div>
  );
};

const CommentsBudgetField = ({
  type,
  options,
  ...rest
}: CommentsBudgetProps) => {
  if (!options || !type) return null;

  switch (type) {
    case "reply-to-post":
      return (
        <BudgetFieldWithPostContext type={type} options={options} {...rest} />
      );
    case "reply-to-comment":
      return (
        <BudgetFieldWithPostAndCommentContext
          type={type}
          options={options}
          {...rest}
        />
      );
    case "edit-comment": {
      return <BudgetField options={options} {...rest} showCheckIcon={true} />;
    }
    default: {
      assertUnreachable(type);
      return null;
    }
  }
};

const BudgetFieldWithPostAndCommentContext = ({
  options,
  ...rest
}: CommentsBudgetProps) => {
  const dispatch = useAppDispatch();
  const commentContext = useCommentContext();
  const postLocation = usePostLocationContext();
  const postContext = usePostContext();

  const handleChange = useCallback(
    (data: FormValue) => {
      const contextData = {
        comment: commentContext.comment,
        post: postContext.postData,
      };

      dispatch(
        offerPriceClicked({
          ...contextData,
          data,
          post_location: postLocation,
        }),
      );
    },
    [dispatch, postContext, commentContext, postLocation],
  );

  if (!options) return null;

  return (
    <BudgetField
      options={options}
      onChange={handleChange}
      {...rest}
      showCheckIcon={true}
    />
  );
};

const BudgetFieldWithPostContext = ({
  options,
  ...rest
}: CommentsBudgetProps) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();
  const postContext = usePostContext();

  const handleChange = useCallback(
    (data: FormValue) => {
      const contextData = {
        post: postContext.postData,
      };

      dispatch(
        offerPriceClicked({
          ...contextData,
          data,
          post_location: postLocation,
        }),
      );
    },
    [dispatch, postContext, postLocation],
  );

  if (!options) return null;

  return (
    <BudgetField
      options={options}
      onChange={handleChange}
      {...rest}
      showCheckIcon={true}
    />
  );
};
