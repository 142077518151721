import { memoize } from "underscore";

export const isInteractiveElementClicked = (event: any) => {
  return (
    event.nativeEvent
      .composedPath()
      .filter((el) => el.tagName === "BUTTON" || el.tagName === "A").length > 0
  );
};

// credits: https://stackoverflow.com/a/37285344/5908844
// @param partial - set to true if partially visible element should by considered as visible
// note: position relative may introduce some errors!
// https://medium.com/@alexcambose/js-offsettop-property-is-not-great-and-here-is-why-b79842ef7582
export const checkIfElementIsInView = (
  container: HTMLElement,
  element: HTMLElement,
  partial = false,
) => {
  if (!container || !element) {
    return false;
  }

  //Get container properties
  const cTop = container.scrollTop;
  const cBottom = cTop + container.clientHeight;

  //Get element properties
  const eTop = element.offsetTop;
  const eBottom = eTop + element.clientHeight;

  //Check if in view
  const isTotal = eTop >= cTop && eBottom <= cBottom;
  const isPartial =
    partial &&
    ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));

  //Return outcome
  return isTotal || isPartial;
};

export const imageDimensionsValidator = (
  img: HTMLImageElement,
  minWidth = 0,
  minHeight = 0,
  callback: (error: string) => void,
) => {
  if (img.naturalWidth < minWidth && img.naturalHeight < minHeight) {
    callback(
      `Invalid image dimensions: minimum size is ${minWidth}x${minHeight}px`,
    );
    return false;
  } else if (img.naturalWidth < minWidth) {
    callback(`Invalid image dimensions: minimum width is ${minWidth}px`);
    return false;
  } else if (img.naturalHeight < minHeight) {
    callback(`Invalid image dimensions: minimum height is ${minHeight}px`);
    return false;
  }

  return true;
};

export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getTextLengthWithoutHTML = (text: string) =>
  text.replace(/<[^><]*>/g, "").trim().length;

/** @description returns true if text with text-overflow: ellipsis; is long enough to show the ellipsis */
export const isEllipsisVisible = (element: {
  scrollWidth: number;
  clientWidth: number;
}) => {
  return element.scrollWidth > element.clientWidth;
};

const getCssStyle = (element: HTMLElement, prop: string): string =>
  window.getComputedStyle(element, null).getPropertyValue(prop);

const DEFAULT_FONT_WEIGHT = "400";
const DEFAULT_FONT_SIZE = "16px";
const DEFAULT_FONT_FAMILY = "Graphik, Helvetica, Arial, sans-serif";

export const getElementFontSize = (el: HTMLElement = document.body): string => {
  const fontWeight = getCssStyle(el, "font-weight") || DEFAULT_FONT_WEIGHT;
  const fontSize = getCssStyle(el, "font-size") || DEFAULT_FONT_SIZE;
  const fontFamily = getCssStyle(el, "font-family") || DEFAULT_FONT_FAMILY;

  return `${fontWeight} ${fontSize} ${fontFamily}`;
};

// re-use canvas object for better performance
let canvasElement: HTMLCanvasElement | undefined;
export const getTextWidth = memoize((text: string, font: string) => {
  if (!canvasElement) {
    canvasElement = document.createElement("canvas");
  }

  let context: CanvasRenderingContext2D | null = null;
  try {
    context = canvasElement.getContext("2d");
  } catch (e) {
    return text.length * 8;
  }

  if (!context) {
    return 0;
  }

  context.font = font;
  const metrics = context.measureText(text);

  return metrics.width;
});
