import {
  DELETE_USER_SESSION,
  FETCHING_USER_SESSIONS,
  FETCHING_USER_SESSIONS_FAILED,
  FETCHING_USER_SESSIONS_SUCCESS,
} from "@js/apps/settings/action-types";
import type { SettingsState } from "@js/types/settings";

const INITIAL_STATE: SettingsState = {
  sessions: [],
  fetchingActiveSessions: false,
};

export default (state = INITIAL_STATE, action): SettingsState => {
  switch (action.type) {
    case FETCHING_USER_SESSIONS:
      return {
        ...state,
        sessions: [],
        fetchingActiveSessions: true,
      };
    case FETCHING_USER_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: action.payload,
        fetchingActiveSessions: false,
      };
    case FETCHING_USER_SESSIONS_FAILED:
      return {
        ...state,
        sessions: [],
        fetchingActiveSessions: true,
      };

    case DELETE_USER_SESSION:
      return {
        ...state,
        sessions: state.sessions.filter(
          (session) => session.id !== action.payload,
        ),
      };

    default:
      return state;
  }
};
