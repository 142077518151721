import React, { useEffect, useRef, useState } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { TextFieldProps } from "@hexocean/braintrust-ui-components";
import { Box } from "@hexocean/braintrust-ui-components";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { TextField } from "@js/forms/fields";

export const EditableTitleField = ({
  input,
  placeholder,
  meta,
  ...props
}: WrappedFieldProps & TextFieldProps) => {
  const [editMode, setEditMode] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  });

  const handleClickOutside = (event) => {
    if (meta.error) return null;
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      makeItUnEditable();
    }
  };

  const makeItEditable = () => setEditMode(true);
  const makeItUnEditable = () => setEditMode(false);

  return (
    <Box
      ref={wrapperRef}
      display="flex"
      alignItems="baseline"
      className="editable-title-container"
      onClick={makeItEditable}
      onFocus={makeItEditable}
      onBlur={makeItUnEditable}
    >
      <TextField
        size="large"
        inputTextSize="large"
        data-testid="job-title"
        placeholder={placeholder}
        input={input}
        inputProps={{
          readOnly: !editMode,
        }}
        hideBorder={!editMode}
        meta={meta}
        floatingLabel={false}
        variant="standard"
        {...props}
      />
      {!editMode && (
        <EditPenIcon
          className="editable-title-icon"
          style={{ color: "var(--grey-2)" }}
        />
      )}
    </Box>
  );
};
