import { SubmissionError } from "redux-form";

import { useDeclineHelpOfferMutation } from "@js/apps/give-and-get-help/api";
import { DeclineOfferForm } from "@js/apps/give-and-get-help/forms/decline-offer";
import { ModalInstance } from "@js/components/modal";
import type { HelpOffer } from "@js/types/give-and-get-help";
import type { SystemMessage } from "@js/types/messenger";

type UseDeclineHelpOfferConfig = {
  offer: HelpOffer | SystemMessage["system_message_target"];
};

export const useDeclineHelpOffer = ({ offer }: UseDeclineHelpOfferConfig) => {
  const [declineHelpOffer, { isLoading: isRejecting }] =
    useDeclineHelpOfferMutation();

  const declineHelpOfferHandler = async (values: {
    decline_reasons: string;
  }) => {
    return declineHelpOffer({ offerId: offer.id, data: values })
      .unwrap()
      .catch((error: any) => {
        throw new SubmissionError(error.data);
      });
  };

  const handleDecline = () =>
    ModalInstance.open({
      className: "decline-offer-modal",
      closeButton: true,
      keepOnBackdropClick: false,
      closeButtonProps: { variant: "tertiary", size: "x-small" },
      children: (
        <DeclineOfferForm
          onSubmit={declineHelpOfferHandler}
          onSubmitSuccess={() => {
            ModalInstance.close();
          }}
        />
      ),
    });

  return {
    handleDecline,
    isRejecting,
  };
};
