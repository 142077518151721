import { useMemo } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { ApplicationStatusWithTooltip } from "@js/apps/jobs/components/application-status";
import { BoostedJobBadge } from "@js/apps/jobs/components/boosted-job-badge";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemApplicationStatusProps = {
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus>;
  job: MainJobListingJob;
};

export const JobItemApplicationStatus = ({
  statusForFreelancer,
  job,
}: JobItemApplicationStatusProps) => {
  const displayStatus = useMemo(() => {
    if (
      job?.interview?.status === ENUMS.JobInterviewStatus.ROUND_1 &&
      job?.interview?.is_completed
    ) {
      return {
        appliedTitle: "In review",
        appliedTooltip: "Your AI Interview is being reviewed",
      };
    } else if (
      job?.interview?.status === ENUMS.JobInterviewStatus.ROUND_1 &&
      !job?.interview?.is_completed
    ) {
      return {
        appliedTooltip: "You’ve been invited to an AI interview",
      };
    }
  }, [job]);

  if (statusForFreelancer === ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS)
    return null;

  return (
    <Box className={styles.wrapper}>
      {job.is_boosted && <BoostedJobBadge jobId={job.id} isJobOpen />}
      <ApplicationStatusWithTooltip
        status={statusForFreelancer}
        offerAcceptedDate={job.offer_accepted_at}
        offerCreatedDate={job.offer_created_at}
        appliedDate={job.bid_created_at}
        {...displayStatus}
      />
    </Box>
  );
};
