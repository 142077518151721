import {
  IconButton,
  Menu,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  BackLeftIcon,
  FlagIcon,
  HighlightOffIcon,
  LinkIcon,
  MessageIcon,
  MoreVertIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useHelpOfferRefund } from "@js/apps/messenger/hooks/use-refund-offer";
import { ModalInstance } from "@js/components/modal";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { useGetOfferActions } from "../../hooks";

import { MenuItemWrapper } from "./menu-item-wrapper";
import { getHelpOfferStatusTagColors, getStatusMessage } from "./utils";

export const HelpOfferMenu = ({
  offer,
  isOwner,
}: {
  offer: HelpOffer;
  isOwner: boolean;
}): JSX.Element | null => {
  const { reportOffer, deleteOffer } = useGetOfferActions(offer);
  const handleHelpOfferRefund = useHelpOfferRefund({
    helperFirstName: offer.author.user.first_name,
    offer,
    offerId: offer.id,
  });

  const canDeleteOffer =
    isOwner &&
    (offer.status === ENUMS.HelpOfferStatus.PENDING ||
      offer.status === ENUMS.HelpOfferStatus.CREATED);

  const canRequestRefund =
    !isOwner &&
    Boolean(Number(offer.budget)) &&
    !offer.refund_requested &&
    offer.status === ENUMS.HelpOfferStatus.ACCEPTED;

  const canReportOffer = !offer.reported && !offer.refund_issued;

  const showMenu =
    offer.comment?.main_post_id ||
    canRequestRefund ||
    (offer.refund_requested && !offer.refund_issued) ||
    canReportOffer ||
    canDeleteOffer ||
    offer.reported;

  if (!showMenu) return null;

  return (
    <Menu
      anchor={
        <IconButton
          aria-label="help-offer-action-menu"
          aria-controls={`job-post-menu-${offer.id}`}
          aria-haspopup="true"
          variant="transparent"
          size="medium"
          className="help-offer-card__menu-anchor"
        >
          <MoreVertIcon style={{ color: "inherit" }} />
        </IconButton>
      }
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
    >
      {!!offer.comment?.main_post_id && (
        <MenuItemWrapper
          label="View original post"
          icon={<LinkIcon style={{ width: "24px", height: "24px" }} />}
          href={`/feed/${offer.comment.main_post_id}/`}
          onClick={ModalInstance.close}
        />
      )}
      {!!offer.reported && (
        <MenuItemWrapper
          label="Contact support"
          icon={<MessageIcon />}
          rel="noreferrer"
          target="_blank"
          to={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
          onClick={ModalInstance.close}
        />
      )}
      {canRequestRefund && (
        <MenuItemWrapper
          label={
            <Typography component="span" color="negative">
              Request refund
            </Typography>
          }
          icon={<BackLeftIcon sx={{ color: "var(--negative-1)" }} />}
          onClick={handleHelpOfferRefund}
        />
      )}
      {canReportOffer && (
        <MenuItemWrapper
          label={
            <Typography component="span" color="negative">
              Report offer
            </Typography>
          }
          icon={<FlagIcon sx={{ color: "var(--negative-1)" }} />}
          onClick={reportOffer}
        />
      )}
      {canDeleteOffer && (
        <MenuItemWrapper
          label={
            <Typography component="span" color="negative">
              Remove offer
            </Typography>
          }
          icon={<HighlightOffIcon style={{ color: "var(--negative-1)" }} />}
          onClick={() => {
            deleteOffer();
          }}
        />
      )}
    </Menu>
  );
};

type HelpOfferExtraMessageProps = {
  offer: HelpOffer;
  helpOfferStatus: EnumType<typeof ENUMS.HelpOfferStatusFilter>;
};

export const HelpOfferExtraMessage = ({
  offer,
  helpOfferStatus,
}: HelpOfferExtraMessageProps) => {
  const user = useUser();
  const isAsker = offer.receiver.user.id === user?.id;
  const statusMessage = getStatusMessage(offer, helpOfferStatus, isAsker);
  const { fontColor } = getHelpOfferStatusTagColors(helpOfferStatus);
  const isNegativeFontColor =
    offer.reported || (offer.refund_requested && !offer.refund_issued);

  if (!statusMessage) return null;

  return (
    <Typography
      component="h4"
      variant="label"
      size="small"
      whiteSpace="nowrap"
      sx={{
        color: isNegativeFontColor ? "var(--negative-1)" : `var(${fontColor})`,
      }}
      className="help-offer-extra-message"
    >
      {statusMessage}
    </Typography>
  );
};
