type OpenPopupProps = {
  url: string;
};

export const openPopup = ({ url }: OpenPopupProps) => {
  const newWindow = window.open(url, "_blank");

  if (!isPopupBlocked(newWindow)) return;

  alert("Please enable popups for this site to ensure full functionality.");
};

export const isPopupBlocked = (popup: Window | null) => {
  return !popup || popup.closed || typeof popup.closed === "undefined";
};
