import type { ReactNode } from "react";
import { createContext, useContext } from "react";

import type { EmployerInvoice } from "@js/types/invoices";

export type EmployerInvoicesTableContextValue = {
  onInvoicePay: (invoice: EmployerInvoice) => void;
  onInvoiceMarkAsPaid: (invoice: EmployerInvoice) => void;
  onExpandRowToggle: (invoice: EmployerInvoice) => void;
  onTryOutSelectionClick: () => void;
  onDismissSelectionIntroductionClick: () => void;
  selectionIntroductionInvoiceId: number | undefined;
  isSelectionLimitReached: boolean;
  currentPageHasSelectedInvoices: boolean;
  expandedRows: number[];
};

const EmployerInvoicesTableContext =
  createContext<EmployerInvoicesTableContextValue | null>(null);

export const EmployerInvoicesTableContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: EmployerInvoicesTableContextValue;
}) => {
  return (
    <EmployerInvoicesTableContext.Provider value={value}>
      {children}
    </EmployerInvoicesTableContext.Provider>
  );
};

export const useEmployerInvoicesTableContext = () => {
  const context = useContext(EmployerInvoicesTableContext);
  if (!context) {
    throw new Error("EmployerInvoicesTableContextProvider is missing");
  }

  return context;
};
