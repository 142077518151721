import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Box,
  ButtonSelect,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  FilterPopoverFooter,
  FilterPopoverFormLayoutWrapper,
} from "@js/apps/employer/components/filter-popover";
import { useGetEmployerInvoicesStatusCountsQuery } from "@js/apps/invoices/api";
import type { RhfForm } from "@js/rhf/types";

import { employerInvoicesStatusFormSchema } from "../schema";

import styles from "./status-form.module.scss";

type FormType = RhfForm<typeof employerInvoicesStatusFormSchema>;
type FormValues = FormType["Values"];
type DefaultFormValues = FormType["DefaultValues"];

type StatusFormProps = {
  status: DefaultFormValues["status"];
  onApply: (status: FormValues["status"]) => void;
};

const allInvoiceStatuses = Object.values(ENUMS.InvoiceStatus);

export const StatusForm = ({ status, onApply }: StatusFormProps) => {
  const defaultValues = useMemo(() => {
    return { status };
  }, [status]);

  const {
    data: statusCounts,
    isLoading,
    isFetching,
  } = useGetEmployerInvoicesStatusCountsQuery();
  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerInvoicesStatusFormSchema),
    defaultValues,
  });

  const statusesWithExistingInvoicesOptions = useMemo(() => {
    if (!statusCounts?.length) {
      return [];
    }
    return allInvoiceStatuses
      .filter((invoiceStatus) => {
        const currentStatusCount = statusCounts.find(
          (statusCount) => statusCount.status === invoiceStatus,
        );

        return !!currentStatusCount?.count;
      })
      .map((invoiceStatus) => {
        return {
          label: ENUMS.InvoiceStatusLabels[invoiceStatus],
          value: invoiceStatus,
        };
      });
  }, [statusCounts]);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onApply(values.status);
  };

  const onReset = () => {
    reset({ status: [] });
  };

  const showLoader = isLoading;
  const showButtons =
    !isLoading && !!statusesWithExistingInvoicesOptions.length;
  const showNoOptionsText =
    !isFetching && !statusesWithExistingInvoicesOptions?.length;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <>
            {showLoader && <PopoverLoader />}
            {showButtons && (
              <Controller
                name="status"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <ButtonSelect
                      variant="white-green"
                      selectedVariant="green"
                      shape="squared"
                      options={statusesWithExistingInvoicesOptions}
                      className={styles.option}
                      value={value}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                    />
                  );
                }}
              />
            )}
            {showNoOptionsText && (
              <Typography
                variant="paragraph"
                color="grey-1"
                size="medium"
                component="p"
              >
                No options available
              </Typography>
            )}
          </>
        }
        formFooter={<FilterPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};

const PopoverLoader = () => {
  return (
    <Box sx={{ minHeight: "5rem", position: "relative" }}>
      <Loader centered />
    </Box>
  );
};
