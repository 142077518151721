import { Typography } from "@hexocean/braintrust-ui-components";
import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import { RouterLink } from "@js/components/link";
import type { EmployerInvoice } from "@js/types/invoices";

type FreelancerNameCellProps = GridRenderCellParams<EmployerInvoice>;

export const FreelancerNameCell = ({ row }: FreelancerNameCellProps) => {
  const freelancerId = row.freelancer?.id;
  const isUserRemoved = !freelancerId;
  if (!row.freelancer || isUserRemoved) {
    return (
      <Typography component="span" variant="paragraph" size="medium">
        {row.freelancer?.user.public_name ?? "N/A"}
      </Typography>
    );
  }

  return (
    <Typography
      component="link"
      RouterLink={RouterLink}
      variant="paragraph"
      to={`/talent/${freelancerId}/`}
      size="medium"
      title={row.freelancer.user.public_name}
      ellipsis
      className="underline-on-hover"
      onClick={(ev) => ev.stopPropagation()}
    >
      {row.freelancer.user.public_name}
    </Typography>
  );
};
