import { useEffect } from "react";

import {
  Box,
  Pagination,
  PaginationWrapper,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useGetEmployerBatchInvoicesQuery } from "@js/apps/invoices/api";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";

import { InvoicesLoader } from "../invoices-loader";

import { EmployerInvoiceBatchesEmptyState } from "./employer-invoice-batches-empty-state";
import { EmployerInvoiceBatchesTableComponent } from "./employer-invoice-batches-table-component";
import { useEmployerInvoiceBatchesFilters } from "./use-employer-invoice-batches-filters";

export const EmployerInvoiceBatchesTable = () => {
  const { filters, handleSortChange } = useEmployerInvoiceBatchesFilters();
  const {
    data: invoiceBatchesResponse,
    isLoading,
    isError,
  } = useGetEmployerBatchInvoicesQuery(filters ?? {});

  const invoiceBatches = invoiceBatchesResponse?.results ?? [];
  const count = invoiceBatches.length;
  const totalCount = invoiceBatchesResponse?.count ?? 0;

  useEffect(() => {
    if (!isError) {
      return;
    }

    Snackbar.error("Something went wrong.");
  }, [isError]);

  if (isLoading) {
    return <InvoicesLoader />;
  }

  const showEmptyState = !count || !invoiceBatches;
  if (showEmptyState) {
    return <EmployerInvoiceBatchesEmptyState />;
  }

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ mb: { xs: 2, sm: 1 } }}>
          <Typography
            component="p"
            variant="paragraph"
            size="medium"
            color="grey-1"
          >
            Showing{" "}
            <strong>
              {count} of {totalCount}
            </strong>{" "}
            invoice batches
          </Typography>
        </Box>

        <EmployerInvoiceBatchesTableComponent
          invoiceBatches={invoiceBatches}
          onSortChange={handleSortChange}
          sortBy={filters?.order_by}
          sortDir={filters?.order_dir}
        />
      </Box>
      <PaginationWrapper>
        <Pagination
          page={filters.page}
          count={totalCount}
          perPage={SETTINGS.BATCH_INVOICES_LISTING_PER_PAGE}
          RouterLink={RouterLink}
        />
      </PaginationWrapper>
    </Box>
  );
};
