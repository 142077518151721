import * as Sentry from "@sentry/react";
import type { CancelToken } from "axios";
import axios from "axios";
import uuid4 from "uuid4/browser";
import { z } from "zod";

import { formatBytes, pluralize, typeGuard } from "@js/utils";

import type {
  AttachmentBase,
  FilePreviewData,
  FileWithId,
  UploadFileArg,
  UploadFileResponse,
  UploadType,
} from "../types";

export const uploadFile = async ({
  file,
  uploadType,
  cancelToken,
}: UploadFileArg): Promise<UploadFileResponse> => {
  try {
    const prepareUploadResponse = await prepareUpload({
      file,
      uploadType,
      cancelToken,
    });

    const fileFields: Record<string, string> = {
      ...prepareUploadResponse.fields,
    };
    delete fileFields.filename_signature;

    await uploadToCloudService({
      file,
      fileFields,
      url: prepareUploadResponse.url,
      cancelToken,
    });

    const response = await uploadAttachment({
      file,
      fileNameSignature: prepareUploadResponse.fields.filename_signature,
      cancelToken,
      uploadType,
    });

    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      return { cancelled: true };
    }

    logError(error);

    return { error: parseError(error) };
  }
};

const prepareUpload = async ({
  uploadType,
  file,
  cancelToken,
}: UploadFileArg): Promise<{
  url: string;
  fields: { key: string; filename_signature: string };
}> => {
  try {
    const response = await axios.post(
      `/api/prepare_upload/`,
      {
        upload_type: uploadType,
        file: file.name,
      },
      { cancelToken },
    );
    return response.data;
  } catch (error) {
    prependErrorMessage({ error, prependedMessage: "prepareUpload" });
    throw error;
  }
};

const uploadToCloudService = async ({
  file,
  url,
  fileFields,
  cancelToken,
}: {
  file: File;
  url: string;
  fileFields: Record<string, string>;
  cancelToken: CancelToken;
}): Promise<unknown> => {
  try {
    const data = new FormData();
    Object.entries(fileFields).forEach(([key, value]) =>
      data.append(key, value),
    );
    data.append("file", file);

    const uploadToCloudResponse = await axios.post(url, data, { cancelToken });
    return uploadToCloudResponse.data;
  } catch (error) {
    prependErrorMessage({ error, prependedMessage: "uploadToCloudService" });
    throw error;
  }
};

const uploadAttachment = async ({
  uploadType,
  file,
  cancelToken,
  fileNameSignature,
}: UploadFileArg & {
  fileNameSignature: string;
}): Promise<{ uuid: string }> => {
  try {
    const data = new FormData();
    data.append("upload_type", uploadType);
    data.append("filename_signature", fileNameSignature);
    data.append("original_filename", file.name.replace(/[^\x20-\x7E]/g, "_"));

    const response = await axios.post(`/api/upload_attachment/`, data, {
      cancelToken,
    });
    return response.data;
  } catch (error) {
    prependErrorMessage({ error, prependedMessage: "uploadAttachment" });
    throw error;
  }
};

const prependErrorMessage = ({
  error,
  prependedMessage,
}: {
  error: unknown;
  prependedMessage: string;
}) => {
  if (error instanceof Error) {
    error.message = `${prependedMessage}: ${error.message}`;
  }
};

const errorWithResponseDataSchema = z.object({
  response: z.object({ data: z.unknown() }),
});
const getErrorResponseData = (error: unknown): unknown | undefined => {
  const parseResult = errorWithResponseDataSchema.safeParse(error);
  if (!parseResult.success) {
    return;
  }

  return parseResult.data.response.data;
};

const logError = (error: unknown) => {
  Sentry.captureException(`File upload error`, {
    extra: { error, errorResponse: getErrorResponseData(error) },
  });
};

export const renameAttachment = ({
  uuid,
  uploadType,
  name,
}: {
  uuid: string;
  uploadType: UploadType;
  name: string;
}): Promise<{ attachment: AttachmentBase; id: number }> => {
  return new Promise((resolve, reject) =>
    axios
      .patch<{ attachment: AttachmentBase; id: number }>(
        `/api/rename_attachment/${uuid}/${uploadType}/`,
        { name },
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error?.response?.data)),
  );
};

export const getCancelTokenSource = () => axios.CancelToken.source();

export const getFileWithId = (file: File): FileWithId => {
  (file as FileWithId).fileId = uuid4();

  return file as FileWithId;
};

const defaultErrorMessage = "File upload failed.";

const parseError = (error: unknown): string => {
  // eslint-disable-next-line eqeqeq
  if (!error || error == null || !axios.isAxiosError(error)) {
    return defaultErrorMessage;
  }

  const { data } = error?.response ?? {};
  if (typeof data === "string") {
    return data;
  }

  if (!data || !typeGuard<unknown, { _error: unknown }>(data, "_error")) {
    return defaultErrorMessage;
  }

  const { _error } = data;
  if (typeof _error === "string") {
    return _error;
  }

  if (Array.isArray(_error) && _error.length && typeof _error[0] === "string") {
    return _error[0];
  }

  return defaultErrorMessage;
};

export const readFileAsDataURL = ({
  file,
  onFileLoad,
  onError,
}: {
  file: File;
  onFileLoad: (img: HTMLImageElement) => void;
  onError: (error: string) => void;
}) => {
  try {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (typeof e.target?.result !== "string") {
        return;
      }

      const img = new Image();
      img.onload = () => {
        onFileLoad(img);
      };

      img.onerror = () => {
        onError("Invalid image content.");
      };

      img.src = e.target?.result;
    };

    reader.readAsDataURL(file);
  } catch (error) {
    onError("Invalid image.");
  }
};

export const arrayMove = <T>(array: T[], from: number, to: number): T[] => {
  const arrayCopy = [...array];
  arrayCopy.splice(to, 0, ...arrayCopy.splice(from, 1));

  return arrayCopy;
};

const infinityIndex = 100; // index that puts file at the end
export const getFileIndexInValue = (
  filePreviewData: FilePreviewData,
  value: number[],
) => {
  if (!filePreviewData?.file) {
    return infinityIndex;
  }
  const indexInValue = value.indexOf(Number(filePreviewData.file.id));

  if (indexInValue === -1) {
    return infinityIndex;
  }

  return indexInValue;
};

export const validateFilesSize = (
  files: File[],
  maxSize: number = SETTINGS.DEFAULT_MAX_SIZE_FILE,
): string | undefined => {
  if (files.some((file) => file.size === 0)) {
    return `File can\'t be empty.`;
  }

  if (!maxSize) {
    return;
  }

  const isSizeValid = files.every((file) => file.size <= maxSize);
  if (isSizeValid) {
    return;
  }

  return `File can\'t be larger than ${formatBytes(maxSize, 2)}.`;
};

export const validateImageType = (files: File[], validFormats?: string[]) => {
  if (!validFormats) {
    return;
  }

  const wrongFileExtensions = files
    .filter((file) => !validFormats.includes(file.type))
    .map((file) => file.name.match(/\.[0-9a-z]+$/i)?.[0].replace(/\./, ""));

  if (wrongFileExtensions.length) {
    const isPlural = wrongFileExtensions.length > 1;
    return `File type${pluralize(
      wrongFileExtensions.length,
    )}: ${wrongFileExtensions.join(", ")} ${
      isPlural ? "are" : "is"
    } not supported.`;
  }
};

export const validateFilesCount = ({
  newFiles,
  currentFilesCount,
  maxFiles,
}: {
  newFiles: File[];
  currentFilesCount: number;
  maxFiles?: number;
}): string | undefined => {
  if (!maxFiles) {
    return;
  }

  if (currentFilesCount === maxFiles) {
    return "Can't select more files.";
  }
  const allFilesCount = currentFilesCount + newFiles.length;
  if (allFilesCount > maxFiles) {
    const canSelect = maxFiles - currentFilesCount;

    return `Too many files selected. You can choose up to ${canSelect}.`;
  }
};

export const shortFileName = (fileName: string, maxLength = 20) => {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const splitFileName = fileName.split(".");
  const trimmedFileName = splitFileName[0].slice(0, maxLength);
  const extension = splitFileName[splitFileName.length - 1];

  return `(${trimmedFileName}...).${extension}`;
};
