import { useEffect, useRef, useState } from "react";
import { throttle } from "underscore";

import { Box } from "@hexocean/braintrust-ui-components";
import { useShowCompleteProfileTopBar } from "@js/apps/dashboard/hooks/use-show-complete-profile-top-bar";
import { NavigationProfileCompletionNudgeCard } from "@js/apps/freelancer/components";
import { Logo } from "@js/components/logo";

import { useHandleLeftColumnWidth } from "./hooks/use-handle-left-column-width";
import {
  COMMON_Z_INDEX,
  HEADER_HEIGHT,
  PROFILE_COMPLETION_TOP_BAR_HEIGHT,
  SIDE_MENU_MIN_WIDTH,
} from "./constants";
import { getProfileCompletionTopBarVisiblePartHeight } from "./helpers";

type LeftColumnProps = {
  sidebar: JSX.Element;
  bgcolor?: string;
};

export const LeftColumn = ({ sidebar, bgcolor }: LeftColumnProps) => {
  const elRef = useRef<HTMLElement>(null);
  useHandleLeftColumnWidth(elRef);
  const { offsetTop } = useProfileCompletionNudgeOffset();

  const isBgBlack = bgcolor === "var(--black)";

  return (
    <Box
      ref={elRef}
      zIndex={COMMON_Z_INDEX}
      display="flex"
      minWidth={SIDE_MENU_MIN_WIDTH}
      flexShrink={0}
      flexDirection="column"
      bgcolor={bgcolor}
    >
      <Box
        position="sticky"
        top={0}
        height={HEADER_HEIGHT}
        pl={3.5}
        display="flex"
        alignItems="center"
        flexShrink={0}
      >
        <Logo variant={isBgBlack ? "full-white" : "full-black"} />
      </Box>
      <Box
        className="sidebar"
        position="sticky"
        top={HEADER_HEIGHT}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: `calc(100vh - ${HEADER_HEIGHT}px - ${offsetTop}px)` }}
      >
        {sidebar}
        <NavigationProfileCompletionNudgeCard />
      </Box>
    </Box>
  );
};

const trackedWindowScrollTopThreshold = PROFILE_COMPLETION_TOP_BAR_HEIGHT;

const useProfileCompletionNudgeOffset = () => {
  const [windowScrollTop, setWindowScrollTop] = useState(0);
  const { showCompleteProfileTopBar } = useShowCompleteProfileTopBar();

  useEffect(() => {
    if (!showCompleteProfileTopBar) {
      return;
    }

    const handleScroll = throttle(
      () => {
        const position = window.scrollY;
        const trackedWindowScrollTop =
          position >= trackedWindowScrollTopThreshold
            ? trackedWindowScrollTopThreshold
            : position;

        setWindowScrollTop(trackedWindowScrollTop);
      },
      100,
      { trailing: true },
    );

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showCompleteProfileTopBar]);

  const offsetTop = getProfileCompletionTopBarVisiblePartHeight({
    windowScrollTop,
    showCompleteProfileTopBar,
  });

  return { offsetTop };
};
