import { usePaidResumeHelp } from "../../hooks/get-career-help";

export const GetCareerHelpFeatureFlagWrapper = ({ children }) => {
  const { paidResumeHelp } = usePaidResumeHelp();

  if (paidResumeHelp) {
    return children;
  } else {
    return null;
  }
};
