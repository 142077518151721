import { useEffect, useMemo, useState } from "react";
import uuid4 from "uuid4/browser";

import type {
  AutocompleteBaseProps,
  AutocompleteChangeReason,
  ComboBoxProps,
} from "@hexocean/braintrust-ui-components";
import { ComboBox } from "@hexocean/braintrust-ui-components";
import {
  KeyboardArrowDownIcon,
  LocationPinSmallIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { CUSTOM_JOB_LOCATIONS_NEW_FORMAT } from "@js/apps/common/constants";
import { Snackbar } from "@js/components/snackbar";
import type { PlacesServicesTypes } from "@js/hooks/google-maps";
import { useGoogleMaps } from "@js/hooks/google-maps";

import type { LocationValue } from "./types";
import { LocationValueType } from "./types";

import styles from "./styles.module.scss";

export type GoogleComboboxProps<DisableClearable extends boolean> = Omit<
  ComboBoxProps<LocationValue, DisableClearable>,
  "onChange" | "options" | "initialTaxonomiesLoading"
> & {
  onChange: (
    value: AutocompleteBaseProps<
      LocationValue,
      false,
      DisableClearable,
      false
    >["value"],
    reason: AutocompleteChangeReason,
  ) => void;
  placesServiceTypes?: PlacesServicesTypes;
  useCustomLocations?: boolean;
  locationIcon?: boolean;
  /** @description Required for backward compatibility for jobs filters. It should be avoided. Consult with the team before using it. */
  includeFullPlaceDetails?: boolean;
  onGetLocationStart?: () => void;
  onGetLocationEnd?: () => void;
};

export const GoogleCombobox = <DisableClearable extends boolean>({
  value: valueToMap,
  onChange,
  includeFullPlaceDetails,
  placesServiceTypes = "cities",
  useCustomLocations = false,
  locationIcon = true,
  onGetLocationStart,
  onGetLocationEnd,
  ...props
}: GoogleComboboxProps<DisableClearable>) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    getPlaceDetails,
    getPlaceDetailsAbortController,
  } = useGoogleMaps();
  /** https://developers.google.com/maps/documentation/places/web-service/session-tokens */
  const [sessionToken, setSessionToken] = useState(uuid4());

  const value = useMemo((): LocationValue | null => {
    if (valueToMap) {
      return {
        ...valueToMap,
        selected: true,
      };
    }

    return null;
  }, [valueToMap]);

  const options = useMemo((): LocationValue[] => {
    const mappedPredictions: LocationValue[] = placePredictions.map(
      (place) => ({
        location_type: LocationValueType.google,
        place_id: place.place_id,
        location: place.description,
      }),
    );

    const initial = !!value ? [value] : [];

    if (useCustomLocations) {
      return [
        ...initial,
        ...CUSTOM_JOB_LOCATIONS_NEW_FORMAT,
        ...mappedPredictions,
      ];
    } else {
      return [...initial, ...mappedPredictions];
    }
  }, [value, useCustomLocations, placePredictions]);

  useEffect(() => {
    if (
      value === null ||
      (typeof value !== "string" &&
        value?.location_type === LocationValueType.custom)
    ) {
      getPlaceDetailsAbortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.location_type]);

  return (
    <ComboBox<LocationValue, DisableClearable>
      // @ts-expect-error `null` fallback is needed due to controlled/uncontrolled warning, but it is not accepted due to conditional `DisableClearable` type.
      value={value || null}
      onInputChange={(_event, input, reason) => {
        if (reason === "reset") {
          return;
        }

        getPlacePredictions({
          input,
          types: placesServiceTypes,
          sessionToken,
        });
      }}
      onChange={(_event, newValue, reason) => {
        if (!newValue) {
          onChange(newValue, reason);
          return;
        }

        if (newValue.location_type === LocationValueType.custom || !newValue) {
          onChange(newValue, reason);
          return;
        }

        if (newValue.place_id) {
          onGetLocationStart?.();
          getPlaceDetails(
            {
              sessionToken,
              placeId: newValue.place_id,
            },
            (placeDetails) => {
              setSessionToken(uuid4());

              if (!placeDetails?.place_id || !placeDetails?.formatted_address) {
                Snackbar.error(
                  "Some place details are missing, please select the location again",
                );
                console.error("Missing place detail for: ", newValue.place_id);
                return;
              }

              if (placeDetails) {
                onChange(
                  includeFullPlaceDetails
                    ? {
                        place_details: placeDetails,
                        location_type: LocationValueType.google,
                        place_id: placeDetails.place_id,
                        location: placeDetails.formatted_address,
                      }
                    : {
                        location_type: LocationValueType.google,
                        place_id: placeDetails.place_id,
                        location: placeDetails.formatted_address,
                      },
                  reason,
                );
              } else {
                onChange(undefined, reason);
              }

              onGetLocationEnd?.();
            },
          );
        }
      }}
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }

        const optionType = option.location_type;
        switch (optionType) {
          case LocationValueType.custom:
          case LocationValueType.google: {
            return option.location;
          }
          default: {
            optionType satisfies never;
            return "";
          }
        }
      }}
      loading={isPlacePredictionsLoading}
      filterOptions={(opts, { inputValue }) => {
        const filteredOptions = opts.filter((option) => {
          const isCustom = option?.location_type === LocationValueType.custom;
          /**
           * Initial value needs to be in options list to fix MUI warning,
           * but we don't want to display it in options list as it is faked object (not compatible with the backend).
           * We need to filter it out also to avoid duplicated item in suggested options.
           */
          const isInitial = !!option?.selected;

          if (inputValue) {
            return !isCustom && !isInitial;
          }

          return isCustom && !isInitial;
        });

        return filteredOptions;
      }}
      isOptionEqualToValue={(option, val) => {
        if (
          option?.location_type === LocationValueType.custom &&
          val?.location_type === LocationValueType.custom
        ) {
          return option.custom_location === val?.custom_location;
        }

        if (
          option?.location_type === LocationValueType.google &&
          val?.location_type === LocationValueType.google
        ) {
          return option?.place_id === val?.place_id;
        }

        return false;
      }}
      classes={
        locationIcon
          ? { popupIndicatorOpen: styles.popupIndicatorOpen }
          : undefined
      } // Prevent location icon from rotating on open
      popupIcon={
        locationIcon ? <LocationPinSmallIcon /> : <KeyboardArrowDownIcon />
      }
      {...props}
      options={options}
      initialTaxonomiesLoading={false}
    />
  );
};
