import { type Control, Controller } from "react-hook-form";

import { Carousel, Stack } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";

import { MyTalentJobNameFilterPopover } from "./my-talent-job-name-filter-popover";
import { MyTalentJobOwnerFilterPopover } from "./my-talent-job-owner-filter-popover";
import { MyTalentRateTypeFilterPopover } from "./my-talent-rate-type-filter-popover";
import { MyTalentRoleFilterPopover } from "./my-talent-role-filter-popover";
import { MyTalentSearchFilter } from "./my-talent-search-filter";
import { MyTalentStatusFilterPopover } from "./my-talent-status-filter-popover";
import type { EmployerMyTalentFormValues } from "./schemas";

import styles from "./my-talent-filters.module.scss";

type MyTalentFiltersProps = {
  control: Control<EmployerMyTalentFormValues>;
};

export const MyTalentFilters = ({ control }: MyTalentFiltersProps) => {
  const user = useUser();
  const userHasAccessToInvoices = !!user?.can_view_employer_invoices;

  const slides = getFilterSlides({ userHasAccessToInvoices, control });

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
      <Controller
        control={control}
        name="search"
        render={({ field: { onChange, value } }) => (
          <MyTalentSearchFilter search={value} onChange={onChange} />
        )}
      />

      <Carousel
        slideProps={{ style: { height: "auto", width: "auto" } }}
        spaceBetween={8}
        slides={slides}
        className={styles.filtersCarousel}
      />
    </Stack>
  );
};

const getFilterSlides = ({
  userHasAccessToInvoices,
  control,
}: {
  userHasAccessToInvoices: boolean;
  control: Control<EmployerMyTalentFormValues>;
}) => {
  return [
    <Controller
      key="status"
      control={control}
      name="status"
      render={({ field: { onChange, value } }) => (
        <MyTalentStatusFilterPopover status={value} onChange={onChange} />
      )}
    />,

    <Controller
      key="job"
      control={control}
      name="jobs"
      render={({ field: { onChange, value } }) => (
        <MyTalentJobNameFilterPopover jobs={value} onChange={onChange} />
      )}
    />,

    <Controller
      key="owner"
      control={control}
      name="job_owners"
      render={({ field: { onChange, value } }) => (
        <MyTalentJobOwnerFilterPopover jobOwners={value} onChange={onChange} />
      )}
    />,

    <Controller
      key="role"
      control={control}
      name="roles"
      render={({ field: { onChange, value } }) => (
        <MyTalentRoleFilterPopover roles={value} onChange={onChange} />
      )}
    />,

    userHasAccessToInvoices ? (
      <Controller
        key="rate"
        control={control}
        name="rate_types"
        render={({ field: { onChange, value } }) => (
          <MyTalentRateTypeFilterPopover
            rateTypes={value}
            onChange={onChange}
          />
        )}
      />
    ) : null,
  ].filter(Boolean);
};
