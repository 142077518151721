import { useSearchParams } from "react-router-dom";

import type { GetEmployerMyTalentParams } from "@js/apps/employer/api";
import { useGetEmployerMyTalentStatsQuery } from "@js/apps/employer/api";
import { buildSearchParams } from "@js/utils";

export const useMyTalentStats = () => {
  const [, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetEmployerMyTalentStatsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const filterActiveTalents = () => {
    const params: GetEmployerMyTalentParams = {
      status: ["active"],
    };

    const filters = buildSearchParams(params, { skipCurrentParams: true });

    setSearchParams(filters, {
      state: { disableRemountIfTheSameLocation: true, noScroll: true },
    });

    document.getElementById("table")?.scrollIntoView({ behavior: "smooth" });
  };

  const sortContracts = () => {
    const params: GetEmployerMyTalentParams = {
      order_by: "job_end_date",
      order_dir: "asc",
      status: [ENUMS.IsActiveStatus.ACTIVE],
    };

    const filters = buildSearchParams(params, { skipCurrentParams: true });

    setSearchParams(filters, {
      state: { disableRemountIfTheSameLocation: true, noScroll: true },
    });

    document.getElementById("table")?.scrollIntoView({ behavior: "smooth" });
  };

  return {
    stats: data,
    isLoading,
    filterActiveTalents,
    sortContracts,
  };
};
