import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { MyWorkEmptyState } from "@js/apps/freelancer/components/my-work-empty-state";
import { WithJobActionsContext } from "@js/apps/jobs/components/job-actions";
import { JobItem } from "@js/apps/jobs/components/job-item";
import { JobsContainer } from "@js/apps/jobs/components/listing/jobs-container";
import { JobLocationContext } from "@js/apps/jobs/context/job-location-context";
import { useGetDefaultActionsContextValue } from "@js/apps/jobs/hooks/get-default-job-actions";
import type { Job } from "@js/types/jobs";

type FreelancerJobInvitesProps = {
  jobs?: Array<Job>;
  hideHeading?: boolean;
};

export const FreelancerJobInvites = ({
  jobs,
}: FreelancerJobInvitesProps): JSX.Element => {
  const jobActionsContextValue = useGetDefaultActionsContextValue();

  return (
    <Box className="freelancer-job-invites">
      <Box className="freelancer-job-invites-wrapper">
        <Box className="freelancer-job-invites-content">
          {!!jobs?.length ? (
            <JobLocationContext.Provider
              value={JobLocationContext.Values.talent_invitations}
            >
              <WithJobActionsContext.Provider value={jobActionsContextValue}>
                <JobsContainer sx={{ gap: 2, pt: 2 }}>
                  {jobs.map((job) => (
                    <JobItem key={job.id} job={job} invitesTab />
                  ))}
                </JobsContainer>
              </WithJobActionsContext.Provider>
            </JobLocationContext.Provider>
          ) : (
            <MyWorkEmptyState
              title="No invites yet"
              description="When you are invited to submit an application for jobs they will appear here."
              icon="📬"
              iconBackgroundColor="var(--medium-yellow)"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
