import type { MouseEvent } from "react";

import { Chip, Stack, Typography } from "@hexocean/braintrust-ui-components";
import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { EmployerInvoice } from "@js/types/invoices";
import { round } from "@js/utils";

import { EmployerInvoicesTableCellTooltip } from "../employer-invoices-table-cell-tooltip";
import { formatCurrencyOrZero } from "../helpers";

export type AmountCellProps = Pick<
  GridRenderCellParams<EmployerInvoice, EmployerInvoice["gross_total"]>,
  "row" | "value"
>;

export const AmountCell = ({ row, value }: AmountCellProps) => {
  const amountPercentageChange = row.last_invoice_amount_percentage_change ?? 0;
  const hasInvoiceAmoutChanged = !!amountPercentageChange;
  const showTooltip = hasInvoiceAmoutChanged;
  const roundedAmountPercentageChange = round(amountPercentageChange, 0.1);

  return (
    <Stack sx={{ height: "100%", alignItems: "end", justifyContent: "center" }}>
      <EmployerInvoicesTableCellTooltip
        title={
          <Typography variant="paragraph" size="medium" component="p">
            {getAmountPercentageChangeTooltipText({
              value: roundedAmountPercentageChange,
              talentFirstName: row.freelancer?.user.first_name,
            })}
          </Typography>
        }
        disabled={!showTooltip}
        maxWidth={270}
        arrow
        placement="top"
      >
        <Stack sx={{ alignItems: "end" }}>
          <Typography component="p" variant="paragraph" size="medium" ellipsis>
            {formatCurrencyOrZero(value)}
          </Typography>
          {hasInvoiceAmoutChanged && (
            <Chip
              label={formatAmoutPercentageChange(roundedAmountPercentageChange)}
              size="x-small"
              color="beige"
              sx={{ "&&": { px: "6px" } }}
              onClick={(ev: MouseEvent<HTMLElement>) => {
                ev.stopPropagation();
              }}
              LabelProps={{
                variant: "label",
                size: "small",
                color: "primary",
              }}
            />
          )}
        </Stack>
      </EmployerInvoicesTableCellTooltip>
    </Stack>
  );
};

const formatAmoutPercentageChange = (value: number) => {
  const sign = value > 0 ? "+" : "";

  return `${sign}${value}%`;
};

const getAmountPercentageChangeTooltipText = ({
  value,
  talentFirstName,
}: {
  value: number;
  talentFirstName: string | undefined;
}) => {
  return `This invoice is ${Math.abs(value)}% ${value > 0 ? "higher" : "lower"} than the last invoice submitted by ${talentFirstName ?? "--"}.`;
};
