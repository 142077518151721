import {
  Box,
  Pagination,
  PaginationWrapper,
  Stack,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import { EmployerInvoicesFilters } from "../employer-invoices-filters";
import { InvoicesLoader } from "../invoices-loader";

import { EmployerInvoicesEmptyState } from "./employer-invoices-empty-state";
import { EmployerInvoicesTableComponent } from "./employer-invoices-table-component";
import { EmployerInvoicesTableTopSection } from "./employer-invoices-table-top-section";
import { useEmployerInvoicesCustomizedColumns } from "./use-employer-invoices-customized-columns";
import { useEmployerInvoicesTable } from "./use-employer-invoices-table";

export const EmployerInvoicesTable = () => {
  const {
    invoices,
    totalInvoicesCount,
    totalInvoicesCountWithoutFilters,
    selectedInvoicesIds,
    handleSingleInvoicePayment,
    markInvoiceAsPaid,
    openInvoicePreview,
    startSelectedInvoicesPaymentFlow,
    setSelectedInvoicesIds,
    isFetching,
    isLoadingEmployerInvoices,
    isLoadingEmployerInvoicesStats,
    control,
    clearFilters,
    filters,
    handleSortChange,
  } = useEmployerInvoicesTable();

  const { columns, onColumnsChange, columnsOptions, onColumnsMenuClose } =
    useEmployerInvoicesCustomizedColumns();

  const isTableEmpty = !invoices.length;
  const hasNoInvoices = !totalInvoicesCountWithoutFilters;

  const showLoader =
    isLoadingEmployerInvoices ||
    isLoadingEmployerInvoicesStats ||
    (isFetching && hasNoInvoices);

  const showTableLoader =
    isLoadingEmployerInvoices || (isFetching && isTableEmpty);

  const showEmptyInvoicesState = !isFetching && hasNoInvoices;

  if (showLoader) {
    return <InvoicesLoader />;
  }

  if (showEmptyInvoicesState) {
    return <EmployerInvoicesEmptyState />;
  }

  return (
    <Stack>
      <Box sx={{ mb: 3 }}>
        <EmployerInvoicesFilters control={control} />
      </Box>
      <EmployerInvoicesTableTopSection
        totalInvoicesCount={totalInvoicesCount}
        invoicesCount={invoices.length}
        isLoading={isLoadingEmployerInvoices}
        selectedInvoicesIds={selectedInvoicesIds}
        startPaymentFlow={startSelectedInvoicesPaymentFlow}
        columnsOptions={columnsOptions}
        onColumnsMenuClose={onColumnsMenuClose}
        onColumnsChange={onColumnsChange}
        selectedColumns={columns}
        filters={filters}
      />
      <EmployerInvoicesTableComponent
        invoices={invoices}
        selectedInvoicesIds={selectedInvoicesIds}
        setSelectedInvoicesIds={setSelectedInvoicesIds}
        sortBy={filters?.order_by}
        sortDir={filters?.order_dir}
        onSortChange={handleSortChange}
        visibleColumns={columns}
        isLoading={showTableLoader}
        onInvoiceClick={openInvoicePreview}
        onInvoicePay={handleSingleInvoicePayment}
        onInvoiceMarkAsPaid={markInvoiceAsPaid}
        onResetFilters={clearFilters}
      />
      <PaginationWrapper sx={{ mt: 4 }}>
        <Pagination
          page={filters.page}
          count={totalInvoicesCount}
          perPage={SETTINGS.EMPLOYER_INVOICE_LISTING_INVOICES_PER_PAGE}
          RouterLink={RouterLink}
          onChange={() => {
            document
              .getElementById("invoicePageTabs")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        />
      </PaginationWrapper>
    </Stack>
  );
};
