import { useCallback, useState } from "react";

export const useSelectedEmployerInvoices = () => {
  const [selectedInvoicesIds, setSelectedInvoicesIds] = useState<number[]>([]);

  const resetSelectedInvoicesIds = useCallback(() => {
    setSelectedInvoicesIds([]);
  }, []);

  return {
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    resetSelectedInvoicesIds,
  };
};
