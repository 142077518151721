import React, { useMemo } from "react";

import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Wizard } from "@js/components/wizard";
import type { CrumbProps } from "@js/components/wizard/breadcrumbs";
import { useAppDispatch } from "@js/hooks";

import { clickNavATSIntegration } from "../actions";
import { useEmployerId } from "../hooks";

type EditWizardProps = {
  pageTitle: string;
  continueButtonColor?: string;
  onContinue?: () => void;
  continueButtonText?: string;
  className?: string;
  children: React.ReactNode;
  disableSubmit?: boolean;
};

export const EmployerEditWizard = ({
  continueButtonText,
  ...props
}: EditWizardProps) => {
  const dispatch = useAppDispatch();
  const employerId = useEmployerId();
  const backUrl = `/employers/${employerId}/`;

  const wizardItems: CrumbProps[] = useMemo(() => {
    const items = [
      {
        title: "Personal Info",
        path: "/employer/edit/personal_info/",
      },
      {
        title: "Organization Info",
        path: "/employer/edit/organization_info/",
      },
      {
        title: "Team Members",
        path: "/employer/edit/team_members/",
      },
      {
        title: "ATS integration",
        path: "/employer/edit/ats_integration/",
        onClick: () => dispatch(clickNavATSIntegration()),
      },
    ];
    return items;
  }, [dispatch]);

  return (
    <Wizard
      crumbs={wizardItems}
      customBackURL={backUrl}
      customBackIcon={CloseIcon}
      oneActive
      hideSeparator
      continueButtonText={continueButtonText || "Save"}
      {...props}
    />
  );
};
