import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { Typography } from "@hexocean/braintrust-ui-components";
import { DotBadge } from "@js/components/dot-badge";
import { RouterLink } from "@js/components/link";
import { Sanitize } from "@js/components/sanitize";
import { TimeFromNow } from "@js/components/time-from-now";
import { mapTypedDispatchToProps } from "@js/utils/store";

import { siteNotificationMarkAsRead } from "../../actions";

class SiteNotificationConnected extends Component<any, any> {
  static defaultProps = {
    live: false,
    className: "",
    children: null,
    closeNotificationsPopup: null,
  };

  handleOnClick = () => {
    const {
      notification: { id },
      closeNotificationsPopup,
    } = this.props;

    this.props.dispatch(siteNotificationMarkAsRead(id));

    if (closeNotificationsPopup) {
      closeNotificationsPopup();
    }
  };

  static isNew = (siteNotification) => {
    return !!(!siteNotification.read || siteNotification.forceUnread);
  };

  render() {
    const { notification, className, live, children } = this.props;

    return (
      <div className={classNames("site-notification", className)}>
        <RouterLink
          forceRemount
          to={notification.href || null}
          className="site-notification-content text-content"
          onClick={this.handleOnClick}
        >
          <DotBadge show={!live && SiteNotification.isNew(notification)}>
            <Sanitize
              className="site-notification-content__title"
              input={notification.content}
            />
          </DotBadge>
          {!live && (
            <Typography
              fontWeight={400}
              component="p"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              <TimeFromNow time={notification.created} />
            </Typography>
          )}
        </RouterLink>
        {children}
      </div>
    );
  }
}

export const SiteNotification = connect(
  null,
  mapTypedDispatchToProps,
)(SiteNotificationConnected);
