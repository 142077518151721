import { useState } from "react";

export const useHasFilterPopoverAnimationFinished = () => {
  const [hasPopoverAnimationFinished, setHasPopoverAnimationFinished] =
    useState(false);

  const onPopoverEnter = () => setHasPopoverAnimationFinished(true);
  const onPopoverClose = () => setHasPopoverAnimationFinished(false);

  return { onPopoverEnter, onPopoverClose, hasPopoverAnimationFinished };
};
