import type { KeyboardEvent, MouseEvent } from "react";
import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { EmployerInvoice } from "@js/types/invoices";

import { useEmployerInvoicesTableContext } from "../employer-invoices-table-context";

type ToggleExpandCellProps = GridRenderCellParams<EmployerInvoice, unknown>;

export const ToggleExpandCell = ({ row }: ToggleExpandCellProps) => {
  const { onExpandRowToggle, expandedRows } = useEmployerInvoicesTableContext();
  const isExpanded = expandedRows.includes(row.id);

  const handleToggleExpandClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    onExpandRowToggle(row);
  };

  return (
    <Button
      variant="transparent-blue"
      size="medium"
      shape="squared"
      onClick={handleToggleExpandClick}
      sx={{ "&&": { p: "6px", fontWeight: 400 }, ml: -1, mr: -1 }}
      disabledType="opacity"
      className="underline-on-hover"
      onKeyDown={(ev: KeyboardEvent<HTMLElement>) => {
        if (ev.key === "Enter") {
          ev.stopPropagation();
        }
      }}
    >
      {isExpanded ? "Hide" : `Show all (${row.items.length})`}
    </Button>
  );
};
