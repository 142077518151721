/* eslint-disable react/jsx-pascal-case */
import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { assertUnreachable, dateFromNow } from "@js/utils";

import { DefaultActionBar } from "../default-action-bar";
import { useJobActionBarContext } from "../job-context";

export const EmployerActionBar = () => {
  const { job } = useJobActionBarContext();

  if (!job || !job.user_is_owner) return null;

  switch (job.job_status) {
    case ENUMS.JobStatus.OPEN: {
      return <EmployerActionBar_JobOpen />;
    }
    case ENUMS.JobStatus.ACTIVE:
    case ENUMS.JobStatus.ACTIVE_FILLED:
    case ENUMS.JobStatus.ACTIVE_NOT_FILLED: {
      return <EmployerActionBar_JobActive />;
    }
    case ENUMS.JobStatus.ON_HOLD: {
      return <EmployerActionBar_JobOnHold />;
    }
    case ENUMS.JobStatus.COMPLETED_HIRED: {
      return <EmployerActionBar_CompletedHired />;
    }
    case ENUMS.JobStatus.COMPLETED_NOT_HIRED: {
      return <EmployerActionBar_CompletedNotHired />;
    }
    case ENUMS.JobStatus.COMPLETED: {
      return null;
    }
    default: {
      assertUnreachable(job.job_status);
      return null;
    }
  }
};

const EmployerActionBar_JobOpen = () => {
  const values = useJobActionBarContext();
  const job = values.job;

  if (!job) return null;

  const title = `Job posted ${dateFromNow(job.published_at)}`;
  const subtitle =
    !job.bids_count || job.bids_count?.total_bids_count === 0
      ? "No Applications"
      : job.bids_count?.interviewing_bids_count > 0
        ? `${job.bids_count?.interviewing_bids_count}
            Candidate${
              job.bids_count?.interviewing_bids_count > 1 ? "s" : ""
            } In Screening`
        : `${job.bids_count?.total_bids_count} Active Application${
            job.bids_count?.total_bids_count > 1 ? "s" : ""
          }`;

  return (
    <DefaultEmployerActionBar job={job} title={title} subtitle={subtitle} />
  );
};

const EmployerActionBar_JobActive = () => {
  const { job } = useJobActionBarContext();

  if (!job) return null;

  const title = "Project is active 👏";
  const subtitle = `Hired ${job.hired_bids_count} Braintrust Talent`;

  return (
    <DefaultEmployerActionBar job={job} title={title} subtitle={subtitle} />
  );
};

const EmployerActionBar_JobOnHold = () => {
  const { job } = useJobActionBarContext();

  if (!job) return null;

  const title = "Project is paused ✋";
  const subtitle = "Not Accepting Applications";

  return (
    <DefaultEmployerActionBar job={job} title={title} subtitle={subtitle} />
  );
};

const EmployerActionBar_CompletedHired = () => {
  const { job } = useJobActionBarContext();

  if (!job) return null;

  const title = "Project is complete ✅";
  const subtitle = `${job.hired_bids_count} Braintrust Talent Hired`;

  return (
    <DefaultEmployerActionBar job={job} title={title} subtitle={subtitle} />
  );
};

const EmployerActionBar_CompletedNotHired = () => {
  const { job } = useJobActionBarContext();

  if (!job) return null;

  const title = "Project is closed ✋";
  const subtitle = "No Braintrust Talent Hired";

  return (
    <DefaultEmployerActionBar job={job} title={title} subtitle={subtitle} />
  );
};

const DefaultEmployerActionBar = ({ job, title, subtitle }) => {
  return (
    <DefaultActionBar
      title={title}
      bgcolor="var(--dark-blue)"
      subtitle={subtitle}
      actions={getDefaultEmployerActions(job)}
    />
  );
};

const ManageJobButton = ({ job }) => {
  return (
    <Button
      variant="primary"
      inverse
      to={`/jobs/${job?.id}/proposals/`}
      RouterLink={RouterLink}
    >
      Manage job
    </Button>
  );
};

const getDefaultEmployerActions = (job) => [
  <ManageJobButton key={job.id} job={job} />,
];
