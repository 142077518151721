import { useCallback, useMemo, useRef } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { SubmissionError } from "redux-form";
import _, { isObject } from "underscore";
import URI from "urijs";

import {
  fetchCurrentUser,
  register,
  setJoiningInfo,
} from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import { PROF_NET_ONBOARDING_PATHS } from "@js/apps/onboarding/constants";
import { useNewClientSignUpInvitation } from "@js/apps/onboarding/hooks/new-client-signup-invitation";
import { Snackbar } from "@js/components/snackbar";
import { globalConfig } from "@js/constants";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import { useAppDispatch, useAppSelector, useQueryParams } from "@js/hooks";
import { LocalStorage } from "@js/services";
import { assertUnreachable, iframePostMessage, isInIframe } from "@js/utils";

import { clientSignUpSelected, onboardingNextStepClicked } from "../../actions";
import { useNewClientSignUpSelfServe } from "../new-client-signup-self-serve";

import {
  getFinishSignupRedirectUrl,
  getInitialValues,
  getNextStepFrom,
  getSubmissionErrorOnCreateAccountStep,
  getValuesWithValidatePhoneNumber,
  performActionFor,
  updateLocalStorageData,
  validateSubmitGoals,
} from "./helpers";

export const ONBOARDING_SUBMIT_TYPE = {
  goals: "goals",
  interests: "interests",
  create_account: "create_account",
  set_joining_reason: "set_joining_reason",
} as const;

export type SubmitTypes = keyof typeof ONBOARDING_SUBMIT_TYPE;
type UpdateSubmitTypeContext = (type: SubmitTypes) => void;

export const useOnboardingUpdateSubmitType = () => {
  return useOutletContext<UpdateSubmitTypeContext>();
};

export const useOnboardingForm = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { job_id, ...queryParams } = useQueryParams();
  const user = useUser();
  const dispatch = useAppDispatch();
  const publicInvitationDetails = useAppSelector(
    (state) => state.auth.publicInvitationDetails,
  );
  const { isClientInvitation, newClientInvitationData } =
    useNewClientSignUpInvitation();
  const isClientSelfServe = useNewClientSignUpSelfServe();
  const isClientSignup = isClientInvitation || isClientSelfServe;

  const submitType = useRef<SubmitTypes>();
  const updateSubmitType = useCallback((type: SubmitTypes) => {
    submitType.current = type;
  }, []);

  const redirectAfterSignUp = useCallback(
    (pathname: string) => {
      if (isInIframe()) {
        globalConfig.surpressDisplayGenericRequestError = true;
        iframePostMessage({ redirect: window.location.origin + pathname });
      } else {
        //slice removes "?" from the query string
        const url = new URI(pathname).addSearch(search.slice(1)).toString();
        return navigate(url);
      }
    },
    [navigate, search],
  );

  const getReferrer = useCallback(() => {
    return (
      LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER, () => {
        return queryParams.referrer;
      }) || undefined
    );
  }, [queryParams.referrer]);

  const onSubmit = useCallback(
    async (values) => {
      switch (submitType.current) {
        case ONBOARDING_SUBMIT_TYPE.goals: {
          const error = validateSubmitGoals(values);
          if (error) {
            throw new SubmissionError(error);
          }

          updateLocalStorageData(values);
          dispatch(onboardingNextStepClicked(submitType.current));

          const onCreateEmployerAccount = () => {
            dispatch(clientSignUpSelected());
            return navigate({
              pathname: PROF_NET_ONBOARDING_PATHS.CREATE_ACCOUNT_PAGE.path,
              search: `${search}&new_employer_self_serve`,
            });
          };

          const onCreateUserAccount = () => {
            return navigate({
              pathname: PROF_NET_ONBOARDING_PATHS.INTERESTS_PAGE.path,
              search,
            });
          };

          const nextStep = getNextStepFrom(values.joining_reasons);

          return performActionFor(nextStep, {
            onCreateEmployerAccount,
            onCreateUserAccount,
          });
        }

        case ONBOARDING_SUBMIT_TYPE.interests: {
          if (!values.interests?.filter(Boolean).length) {
            throw new SubmissionError({
              onboarding_step_error: "Please choose at least one option above.",
            });
          }
          updateLocalStorageData(values);
          dispatch(onboardingNextStepClicked(submitType.current));
          return navigate({
            pathname: PROF_NET_ONBOARDING_PATHS.CREATE_ACCOUNT_PAGE.path,
            search,
          });
        }

        case ONBOARDING_SUBMIT_TYPE.create_account: {
          updateLocalStorageData(values);
          const valuesWithValidatedPhoneNumber =
            getValuesWithValidatePhoneNumber(values);

          const _values = await getValuesWithReCaptchaCode(
            valuesWithValidatedPhoneNumber,
            "sign_up",
          );

          try {
            const { email, finish_signup_url: finishSignupURL } =
              await dispatch(
                register({
                  values: _values,
                  referrer: getReferrer(),
                  job_id: job_id,
                  queryParams,
                }),
              );

            LocalStorage.setItem(
              LocalStorage.keys.REGISTRATION_EMAIL_LOCAL_STORAGE_KEY,
              email,
            );

            const accountType = (await dispatch(fetchCurrentUser()))
              .account_type;

            const postSignupRedirectUrl = getFinishSignupRedirectUrl({
              finishSignupURL,
              accountType,
              nextQueryParam: queryParams.next,
              invitationKeyQueryParam: queryParams.invitation_key,
            });

            return redirectAfterSignUp(postSignupRedirectUrl);
          } catch (err) {
            const error = isObject(err) ? err : { _error: err };

            const serverError = error.errors?._error || error._error;

            if (serverError) {
              Snackbar.error(serverError);
            }

            handleValidationErrorsOnCreateAccountStep(error.errors);
          }

          break;
        }

        case ONBOARDING_SUBMIT_TYPE.set_joining_reason: {
          updateLocalStorageData(values);
          const { joining_reasons, interests } = values;

          return dispatch(
            setJoiningInfo({
              values: { joining_reasons, interests },
            }),
          ).then(({ email }) => {
            LocalStorage.setItem(
              LocalStorage.keys.REGISTRATION_EMAIL_LOCAL_STORAGE_KEY,
              email,
            );
            return navigate("/");
          });
        }

        case undefined:
          break;
        default: {
          assertUnreachable(submitType.current);
        }
      }
    },
    [
      dispatch,
      navigate,
      search,
      getReferrer,
      job_id,
      queryParams,
      redirectAfterSignUp,
    ],
  );

  const handleValidationErrorsOnCreateAccountStep = (errors = {}) => {
    if (!_.isObject(errors)) {
      throw new Error(
        `Invalid argument: expected an object. (${JSON.stringify(errors)})`,
      );
    }

    throw new SubmissionError(getSubmissionErrorOnCreateAccountStep(errors));
  };

  const initialValues = useMemo(
    () =>
      getInitialValues(user, {
        job_id,
        publicInvitationDetails,
        newClientInvitationDetails: newClientInvitationData,
        isClientSignup,
      }),
    [
      user,
      job_id,
      publicInvitationDetails,
      newClientInvitationData,
      isClientSignup,
    ],
  );

  return {
    formProps: {
      onSubmit,
      initialValues,
      enableReinitialize: true,
    },
    updateSubmitType,
    getReferrer,
  };
};
