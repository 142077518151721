import type { ReactNode } from "react";

import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Divider, Stack } from "@hexocean/braintrust-ui-components";

type FilterPopoverFormLayoutWrapperProps = {
  formBody: ReactNode;
  formFooter: ReactNode;
  containerProps?: StackProps;
};

export const FilterPopoverFormLayoutWrapper = ({
  formBody,
  formFooter,
  containerProps,
}: FilterPopoverFormLayoutWrapperProps) => {
  return (
    <Stack
      {...containerProps}
      sx={{
        gap: 2,
        p: 3,
        width: "min(500px, 100vw - 2rem)",
        ...containerProps?.sx,
      }}
    >
      {formBody}
      <Divider color="beige" />
      {formFooter}
    </Stack>
  );
};
