import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";

import style from "./style.module.scss";

export const MigrationActivityInformModalContent = () => {
  return (
    <div className={style.migrationInfoModalContent}>
      <Typography component="h2" fontWeight={500} size="large" mb={3.5}>
        Your new blockchain wallet
      </Typography>
      <img
        src={`${SETTINGS.STATIC_URL}dashboard/migration-activity-info.svg`}
        alt="Safe wallet transfer image"
        width="450"
        height="236"
      />
      <Typography
        component="h2"
        variant="title"
        fontWeight={400}
        size="small"
        my={3}
      >
        Your BTRST wallet is now safer and more powerful
      </Typography>
      <MigrationInfoItem
        icon="✅"
        description="Your wallet is now on the blockchain, so you can verify and confirm
          every action"
      />
      <MigrationInfoItem
        icon="😍"
        description="Braintrust will still carry out your transactions, so no extra steps
        to take"
      />
      <div className={style.migrationInfoModalContentFaqTag}>
        <InfoIcon sx={{ fontSize: "24px", marginRight: "10px" }} />
        <Typography size="small" component="span" color="grey-1">
          View our
          <Typography
            ml={0.25}
            size="small"
            component="link"
            color="grey-1"
            RouterLink={RouterLink}
            href={
              SETTINGS.SUPPORT_URLS.HOW_TO_GET_TOKEN_ON_COINBASE_ARTICLE_URL
            }
          >
            Wallet FAQs
          </Typography>
        </Typography>
      </div>
      <Button
        sx={{ alignSelf: "flex-end" }}
        variant="primary"
        onClick={() => ModalInstance.close()}
        shape="squared"
      >
        Got it
      </Button>
    </div>
  );
};

export const openMigrationActivityInformModal = () => {
  ModalInstance.open({
    children: <MigrationActivityInformModalContent />,
    closeButton: true,
  });
};

const MigrationInfoItem = ({
  icon,
  description,
}: {
  icon: string;
  description: string;
}) => {
  return (
    <div className={style.migrationInfoItem}>
      <div className={style.migrationInfoItemIconWrapper}>{icon}</div>
      <Typography component="p" size="large">
        {description}
      </Typography>
    </div>
  );
};
