import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Box } from "@hexocean/braintrust-ui-components";
import type { RhfForm } from "@js/rhf/types";

import { CheckboxButton } from "../checkbox-button";
import { FilterButtonPopoverFooter } from "../filter-button-popover-footer";
import { FilterPopoverFormLayoutWrapper } from "../filter-popover-form-layout-wrapper";
import { employerMyTalentRateTypeFormSchema } from "../schemas";

import style from "../style.module.scss";

type Form = RhfForm<typeof employerMyTalentRateTypeFormSchema>;
type FormValues = Form["Values"];
type DefaultFormValues = Form["DefaultValues"];

type EmployerMyTalentRateTypeFormProps = {
  rateTypes: DefaultFormValues["rate_types"];
  onApply: (rateTypes: FormValues["rate_types"]) => void;
};

export const EmployerMyTalentRateTypeForm = ({
  rateTypes,
  onApply,
}: EmployerMyTalentRateTypeFormProps) => {
  const defaultValues = useMemo(() => {
    return { rate_types: rateTypes };
  }, [rateTypes]);
  const { handleSubmit, control, getValues, reset, watch } =
    useForm<FormValues>({
      resolver: zodResolver(employerMyTalentRateTypeFormSchema),
      defaultValues,
    });

  const { field } = useController({
    name: "rate_types",
    control: control,
  });

  const handleCheck = (checkboxId: EnumType<typeof ENUMS.JobPaymentType>) => {
    const rate_types = getValues("rate_types");

    if (!rate_types) {
      return [checkboxId];
    }

    const nextStatus = rate_types.includes(checkboxId)
      ? rate_types.filter((val) => {
          return val !== checkboxId;
        })
      : [...rate_types, checkboxId];

    return nextStatus;
  };

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onApply(values.rate_types);
  };

  const onReset = () => {
    reset({ rate_types: [] });
  };

  const rateTypesFieldValues = watch("rate_types");

  const isHourlyCheckboxSelected = Boolean(
    rateTypesFieldValues &&
      rateTypesFieldValues.includes(ENUMS.JobPaymentType.HOURLY),
  );

  const isFixedCheckboxSelected = Boolean(
    rateTypesFieldValues &&
      rateTypesFieldValues.includes(ENUMS.JobPaymentType.FIXED_PRICE),
  );

  const isAnnualCheckboxSelected = Boolean(
    rateTypesFieldValues &&
      rateTypesFieldValues.includes(ENUMS.JobPaymentType.ANNUAL),
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <Box className={style.filterCheckboxContainer}>
            <CheckboxButton
              isSelected={isHourlyCheckboxSelected}
              label="Hourly"
              onClick={() => {
                field.onChange(handleCheck(ENUMS.JobPaymentType.HOURLY));
              }}
            />

            <CheckboxButton
              isSelected={isFixedCheckboxSelected}
              label="Fixed"
              onClick={() => {
                field.onChange(handleCheck(ENUMS.JobPaymentType.FIXED_PRICE));
              }}
            />

            <CheckboxButton
              isSelected={isAnnualCheckboxSelected}
              label="Annual"
              onClick={() => {
                field.onChange(handleCheck(ENUMS.JobPaymentType.ANNUAL));
              }}
            />
          </Box>
        }
        formFooter={<FilterButtonPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
