import type { FC } from "react";
import { useState } from "react";
import { Field, FieldArray } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { GooglePlacesSingleField, TextField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
import type { FreelancerExternalProfile } from "@js/types/common";

import { ExternalProfilesField } from "../fields/external-profiles";

import styles from "./style.module.scss";

export type AccountInfoFormData = {
  user: {
    first_name: string;
    last_name: string | null;
  };
  full_location?: string | null;
  external_profiles?: Array<FreelancerExternalProfile>;
};

const AccountInfoFormInstance = createFormInstance<
  AccountInfoFormData,
  unknown
>("account-info-form-modal", { enableReinitialize: true });

type AccountInfoFormProps = {
  onSubmit: (data: AccountInfoFormData) => void;
  onSubmitSuccess: () => void;
  initialValues: AccountInfoFormData;
  headerComponent?: JSX.Element | string;
  hideExternalSites?: boolean;
};

export const AccountInfoForm: FC<AccountInfoFormProps> = ({
  headerComponent,
  hideExternalSites,
  ...props
}) => {
  const [isSaveReady, setIsSaveReady] = useState(true);

  return (
    <AccountInfoFormInstance {...props}>
      <Box>
        {headerComponent}
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography component="p" variant="paragraph" size="large">
            Let’s get started with some personal details
          </Typography>
          <div className={styles.bioFieldWrapper}>
            <Field
              id="first_name"
              required
              name="user.first_name"
              component={TextField}
              label="First name"
              placeholder="Enter your first name"
              validate={[required]}
            />

            <Field
              id="last_name"
              required
              name="user.last_name"
              component={TextField}
              label="Last name"
              placeholder="Enter your last name"
              validate={[required]}
            />
            <Field
              id="full_location"
              name="full_location"
              className={styles.location}
              required
              component={GooglePlacesSingleField}
              label="Location"
              onGetLocationStart={() => setIsSaveReady(false)}
              onGetLocationEnd={() => setIsSaveReady(true)}
              placeholder="Enter your city"
              validate={[required]}
            />
          </div>

          {!hideExternalSites && (
            <>
              <Typography component="p" variant="paragraph" size="large" mt={1}>
                Your online profiles
              </Typography>

              <FieldArray
                name="external_profiles"
                component={ExternalProfilesField}
              />
            </>
          )}
        </Box>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button
            variant="positive"
            disabled={!isSaveReady}
            shape="squared"
            type="submit"
          >
            Save and continue
          </Button>
        </Box>
      </Box>
    </AccountInfoFormInstance>
  );
};
