import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export const EmployerInvoiceBatchesEmptyState = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        background: "var(--white)",
        p: 4,
        borderRadius: 4,
        textAlign: "center",
      }}
    >
      <Box
        component="img"
        src={`${SETTINGS.STATIC_URL}invoices/invoice-batches-empty-state-illustration.svg`}
        width="228px"
        height="221px"
      />
      <Typography component="h3" variant="label" size="medium" sx={{ mb: 1 }}>
        No invoice batches found.
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size="medium"
        sx={{ mb: 3 }}
      >
        If you’re enrolled into invoice batches, they’ll appear here.
      </Typography>
      <Stack direction="row" sx={{ gap: 1, justifyContent: "center" }}>
        <Button
          size="x-small"
          variant="primary"
          RouterLink={RouterLink}
          to="/jobs/add_new/"
        >
          Post a job
        </Button>
        <Button
          size="x-small"
          variant="secondary"
          RouterLink={RouterLink}
          to="/talent/"
        >
          Browse talent
        </Button>
      </Stack>
    </Stack>
  );
};
