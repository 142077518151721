import { OfferListEmptyState } from "@js/apps/freelancer/views/dashboard/offers/offer-list/offer-list-empty-state";
import { useFreelancerOffers } from "@js/apps/jobs/hooks/freelancer-offers";

import { OfferList } from "./offer-list";

type FreelancerDashboardOffersPageProps = {
  showClosedJobOffers: boolean;
};

export const FreelancerDashboardOffersPage = ({
  showClosedJobOffers,
}: FreelancerDashboardOffersPageProps) => {
  const { jobOffers } = useFreelancerOffers(showClosedJobOffers);

  return (
    <OfferList
      offers={jobOffers}
      emptyStateContent={
        <OfferListEmptyState description="Offers made by employers will appear here." />
      }
    />
  );
};
