import cs from "classnames";

import { Avatar } from "@hexocean/braintrust-ui-components";
import { CategoryName } from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import { RouterLink } from "@js/components/link";
import { assertUnreachable } from "@js/utils";

import type {
  WalletActivityBalanceItem,
  WalletActivityHelpOfferUser,
} from "../../types";

import type { ActivityIconProps } from "./activity-icon";

type GetActivityTypeDetailsType = {
  title: React.ReactNode;
  iconVariant: ActivityIconProps["variant"];
  subtitle?: React.ReactNode;
};

export const getActivityTypeDetails = (
  activity: WalletActivityBalanceItem,
): GetActivityTypeDetailsType => {
  const sourceKind = activity.source?.kind;
  const defaultActivityDetails: GetActivityTypeDetailsType = {
    iconVariant: parseInt(activity.debit) ? "send" : "receive",
    title: activity.description,
  };
  const isSendingFunds = parseFloat(activity.debit) > 0;

  if (!activity.source) {
    return defaultActivityDetails;
  }

  switch (sourceKind) {
    case ENUMS.WalletActivitySourceKind.REFERRED_USER:
      return {
        iconVariant: "referral",
        title: activity.description,
      };
    case ENUMS.WalletActivitySourceKind.HELP_OFFER: {
      const iconVariant = isSendingFunds ? "send" : "receive";
      const receiverOfFunds = activity.refund
        ? activity.source.receiver
        : activity.source.author;
      const providerOfFunds = activity.refund
        ? activity.source.author
        : activity.source.receiver;

      const [receiveTitlePredicate, sentTitlePredicate] = activity.refund
        ? ["received a refund", "issued a refund"]
        : ["received funds", "sent funds"];

      const title = isSendingFunds ? (
        <>
          You {sentTitlePredicate} to <Author author={receiverOfFunds} />
        </>
      ) : (
        <>
          You {receiveTitlePredicate} from <Author author={providerOfFunds} />
        </>
      );

      const subtitle = (
        <>
          for <CategoryName category={activity.source.category} />
        </>
      );

      return {
        iconVariant,
        title,
        subtitle,
      };
    }
    case ENUMS.WalletActivitySourceKind.OUTSIDE_TOKEN_TRANSFER: {
      const iconVariant = isSendingFunds ? "send" : "receive";
      return {
        title: activity.description,
        iconVariant,
      };
    }

    case ENUMS.WalletActivitySourceKind.TOKEN_GRANT_PAYOUT:
    case ENUMS.WalletActivitySourceKind.REWARD_RULE:
    case ENUMS.WalletActivitySourceKind.INVOICE:
    case ENUMS.WalletActivitySourceKind.PAYMENT_TRANSACTION:
    case ENUMS.WalletActivitySourceKind.WITHDRAWAL_TRANSACTION:
    case ENUMS.WalletActivitySourceKind.COINBASE_TRANSACTION:
    case undefined:
      return defaultActivityDetails;

    default:
      assertUnreachable(sourceKind);
      return defaultActivityDetails;
  }
};

const Author = ({ author }: { author?: WalletActivityHelpOfferUser }) => {
  if (!author) return null;

  if (author.is_removed) {
    return (
      <div className="activity-list-item__profile">
        <Avatar
          style={{ width: 28, height: 28 }}
          textSize="small"
          src={author}
        />
        {author.first_name} {!!author.last_name && author.last_name}
      </div>
    );
  }

  return (
    <RouterLink
      to={author.profile_url}
      target="_blank"
      className={cs(
        "activity-list-item__profile",
        "activity-list-item--underline",
      )}
    >
      <Avatar style={{ width: 28, height: 28 }} textSize="small" src={author} />
      {author.first_name} {!!author.last_name && author.last_name}
    </RouterLink>
  );
};
