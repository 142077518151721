import type { PollOption } from "@js/types/give-and-get-help";
import { deepClone } from "@js/utils";

import type { PostAttachments, PostHashtag } from "../types";

import { REPLY_FORM_ID } from "./constants";
import type { CreateOrEditPostFormData } from "./index";

type Values = CreateOrEditPostFormData;

export const preparePostSubmitValues = <T>(values: Values): T => {
  const _values: any = deepClone(values);

  _values.links = values.links?.map((link) =>
    typeof link === "string" ? link : link.value,
  );

  delete _values.links_metadata_from_text;
  delete _values.excluded_links_from_text;

  if (
    !_values.poll_text &&
    Array.isArray(_values.poll_options) &&
    _values.poll_options.length === 0
  ) {
    delete _values.poll_options;
  }

  if (Array.isArray(_values.poll_options)) {
    // remove empty options
    _values.poll_options = _values.poll_options.filter(
      (pollOption: PollOption) => {
        return pollOption.text;
      },
    );
  }
  if (!_values.category) {
    delete _values.category;
  }

  if (Array.isArray(_values.post_hashtags)) {
    _values.hashtags = _values.post_hashtags.map(
      (hashtag: PostHashtag) => hashtag.hashtag_id || hashtag.id,
    );
    delete _values.post_hashtags;
  }

  if (Array.isArray(_values.attachments)) {
    // existing attachments doesn't have fileId parameter
    const alreadyUploaded = _values?.attachments?.filter(
      (item) => !item?.fileId,
    );
    const newlyUpload = _values?.attachments?.filter((item) => item?.fileId);
    _values.attachments_ids = [...alreadyUploaded, ...newlyUpload]
      .map((attachment: PostAttachments) => attachment.id)
      .filter(Boolean);

    // uploading resume only on resume application review category
    if (_values.category === 5) {
      _values.resume_attachment = alreadyUploaded
        .map((attachment) => attachment.id)
        .filter(Boolean)[0];
    } else {
      delete _values.resume_attachment;
    }

    delete _values.attachments;
  }

  delete _values.self_promotion_response_ignored;

  return _values;
};

export const getReplyFormId = (postOrCommentId: number): string => {
  return `${REPLY_FORM_ID}-${postOrCommentId}`;
};
