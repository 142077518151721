import { z } from "zod";

import { isNotNullable } from "@js/utils";

export const zodCommaStringToArray = z
  .union([
    z.array(z.coerce.string()),
    z.string().transform((val) => {
      const split = val.split(",").filter(Boolean);

      if (split.length) {
        return split;
      }

      return undefined;
    }),
  ])
  .optional();

export const zodCommaNumberArray = z
  .union([
    z.array(z.coerce.number()),
    z.string().transform((val) => {
      const split = val
        .split(",")
        .map((v) => (!!v ? Number(v) : undefined))
        .filter(isNotNullable);

      if (split.length) {
        return split;
      }

      return undefined;
    }),
  ])
  .refine((valueList) => {
    const hasNaNs = valueList?.some((value) => isNaN(value));

    return !hasNaNs;
  })
  .optional();
