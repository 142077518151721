import { Typography } from "@hexocean/braintrust-ui-components";
import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import { RouterLink } from "@js/components/link";
import type { EmployerInvoice } from "@js/types/invoices";

type EmailCellProps = GridRenderCellParams<EmployerInvoice, unknown>;

export const EmailCell = ({ row }: EmailCellProps) => {
  const isUserRemoved = !row.freelancer?.id;
  if (!row.freelancer || isUserRemoved) {
    return (
      <Typography component="span" variant="paragraph" size="medium">
        N/A
      </Typography>
    );
  }

  return (
    <Typography
      component="link"
      RouterLink={RouterLink}
      href={`mailto:${row.freelancer.user.email}`}
      variant="paragraph"
      size="medium"
      title={row.freelancer.user.email}
      ellipsis
      className="underline-on-hover"
      onClick={(ev) => ev.stopPropagation()}
    >
      {row.freelancer.user.email}
    </Typography>
  );
};
