import { useCallback, useEffect } from "react";
import type { To } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { buildFiltersURL, getChangeFilters } from "@js/apps/common/filters";
import { fetchFreelancerJobsSelect } from "@js/apps/freelancer/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { getURL } from "@js/utils";

import { FETCH_FREELANCER_LAST_INVOICE_SUCCESS } from "../../action-types";
import {
  cancelFreelancerInvoice as cancelFreelancerInvoiceAction,
  fetchFreelancerInvoices,
  fetchFreelancerInvoicesSummary,
  fetchFreelancerLastInvoice,
} from "../../actions";
import type { InvoiceFiltersFetchParams } from "../../filters";
import { useInvoiceFilters } from "../../filters";

export const useFreelancerInvoiceListing = () => {
  const {
    invoicesSummary,
    invoiceList,
    total,
    fetchingInvoiceList,
    lastInvoice,
  } = useAppSelector((state) => state.invoices);
  const { fetchingJobList } = useAppSelector((state) => state.freelancer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { filters, isAnyFilterApplied } = useInvoiceFilters();

  const fetchInvoices = useCallback(async () => {
    dispatch(fetchFreelancerInvoicesSummary());
    dispatch(fetchFreelancerJobsSelect());

    const _invoiceList = await dispatch(fetchFreelancerInvoices(filters));

    const hasFetchedAllInvoices = !isAnyFilterApplied && _invoiceList.count > 0;

    // we already have all invoices, there is no need to do any more fetch requests
    if (hasFetchedAllInvoices) {
      dispatch({
        type: FETCH_FREELANCER_LAST_INVOICE_SUCCESS,
        payload: _invoiceList.results[0],
      });
      return;
    }

    // we have fetched **some** invoices (filtered by query params)
    // we still need to fetch **all** invoices and extract the latest one
    if (_invoiceList.count > 0) {
      dispatch(fetchFreelancerLastInvoice());
    }
  }, [dispatch, filters, isAnyFilterApplied]);

  const cancelFreelancerInvoice = async (id: number) => {
    await dispatch(cancelFreelancerInvoiceAction(id));

    fetchInvoices();
  };

  const changeFilters = (newFilters: Partial<InvoiceFiltersFetchParams>) => {
    if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
      const to: To = getURL(
        buildFiltersURL(getChangeFilters(filters, newFilters)).toString(),
      );
      navigate(to);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  return {
    filters,
    invoicesSummary,
    invoiceList,
    total,
    loading: fetchingInvoiceList || fetchingJobList,
    isAnyFilterApplied,
    lastInvoice,
    canFreelancerCopyLatestInvoice: Boolean(lastInvoice?.id),
    copyFreelancerLatestInvoiceId: lastInvoice?.id,
    page: filters.page as number | undefined,
    cancelFreelancerInvoice,
    changeFilters,
    fetchInvoices,
  };
};
