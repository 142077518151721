import { InvoicePreviewModalInstance } from "@js/apps/invoices/components/invoice-preview";
import { MarkAsPaidConfirmationModalInstance } from "@js/apps/invoices/components/mark-as-paid-action";
import { EmployerInvoiceListingContextProvider } from "@js/apps/invoices/context/employer-invoice-listing";

import { EmployerInvoicesTable } from "./employer-invoices-table/employer-invoices-table";

export const EmployerInvoicesTableView = () => {
  return (
    <>
      <EmployerInvoiceListingContextProvider>
        <EmployerInvoicesTable />
      </EmployerInvoiceListingContextProvider>
      <MarkAsPaidConfirmationModalInstance />
      <InvoicePreviewModalInstance />
    </>
  );
};
