export const getFieldDescription = (
  boost: number,
  isEditPage: boolean | undefined,
  is_boosted: boolean | undefined,
) => {
  if (boost > 0) {
    if (isEditPage && is_boosted) return "Already boosted.";
    else return `${boost}/3 boosts available`;
  } else {
    if (isEditPage && is_boosted) return "Already boosted.";
    return "0/3 boosts available. You can purchase boosts upon completing your application and subsequently apply them.";
  }
};
