import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

type AiJobPostBannerProps = {
  is_ai_generated: TypedWrappedFieldProps<boolean>;
};

export const AiJobPostBanner = ({ is_ai_generated }: AiJobPostBannerProps) => {
  if (!is_ai_generated.input.value) {
    return null;
  }

  return (
    <Box className="ai-banner">
      <img
        src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
        height="35px"
        width="35px"
        alt="ai star"
      />
      <Typography
        component="p"
        size="small"
        fontWeight={500}
        color="primary"
        textAlign="justify"
      >
        Your job post has been generated using AI, which populated this
        information by matching your job needs with our vast database of recent
        job postings and hires. Please take a moment to review the details of
        your job posting to ensure accuracy. If you have any specific location
        or timezone preferences, kindly include them in the section below.
      </Typography>
    </Box>
  );
};
