import React from "react";

import {
  Avatar,
  type GridRenderCellParams,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { EmployerMyTalent } from "@js/types/employer";

type NameCellProps = GridRenderCellParams<
  EmployerMyTalent,
  EmployerMyTalent["name"]
>;

export const NameCell = ({ value, row }: NameCellProps) => {
  if (!row.freelancer_id) {
    return (
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: 1,
          height: "100%",
        }}
      >
        <Avatar src={row} size="sm" textSize="small" />
        <Typography component="span" variant="paragraph" size="medium" ellipsis>
          {value}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      component={RouterLink}
      to={`/talent/${row.freelancer_id}/`}
      direction="row"
      sx={{
        alignItems: "center",
        gap: 1,
        height: "100%",
        "&:hover": {
          textDecoration: "none",
          ".underline-on-hover": { textDecoration: "underline" },
        },
      }}
    >
      <Avatar src={row} size="sm" textSize="small" />
      <Typography
        component="span"
        variant="paragraph"
        size="medium"
        ellipsis
        className="underline-on-hover"
      >
        {value}
      </Typography>
    </Stack>
  );
};
