import { z } from "zod";

import type {
  RoleFormType,
  RoleParams,
} from "@js/apps/common/components/filters/forms/role-form";
import {
  defaultRole,
  defaultRoleParams,
} from "@js/apps/common/components/filters/forms/role-form";
import { defaultExperience } from "@js/apps/common/components/filters/forms/years-of-experience-form";
import {
  createFiltersForm,
  createFiltersFormAndHook,
  createFiltersSearchParamsHook,
  FiltersManager,
} from "@js/apps/filters/";
import type { UTCOffset } from "@js/apps/filters/types";
import { normalizeRolesAndSkillsFormat } from "@js/apps/filters/utils";
import {
  CUSTOM_RATE_VALUE,
  isRateOptionSelected,
} from "@js/apps/jobs/apps/listing/const";
import type { SearchEventEntityTypeKey } from "@js/types/tracking";

import {
  defaultSkills,
  defaultSkillsParams,
  type SkillsFormType,
  type SkillsParams,
} from "./forms/skills-form";

export type OrderingType = "-created" | "-score" | "newest" | "-search_score";
export type Ordering = { ordering?: OrderingType };
export type Search = { search?: string };
type ContractType = { contract_type?: EnumType<typeof ENUMS.JobContractType> };
type PaymentType = { payment_type?: EnumType<typeof ENUMS.JobContractType> };

export type UIUsedParams = {
  job: number;
  approved: undefined | true;
  invite_to_job: true;
  page?: number;
  skill_search_operator: "and";
};

type Rate = {
  hourly_budget_minimum_usd?: number | typeof CUSTOM_RATE_VALUE;
  custom_rate?: number;
};
type RateParams = { hourly_budget_minimum_usd?: number };
type Location = {
  work_from_anywhere?: boolean;
  work_from_location?: boolean;
  custom_location?: string;
  country_code?: string;
  state?: string;
  city?: string;
  location?: string;
  session_token?: string;
};

export type TalentLocation = {
  location?: string;
  custom_location?: string;
  place_id?: string;
  session_token?: string;
  include_verified_locations_only?: boolean;
};

export type YearsOfExperienceRange = "1-3" | "3-5" | "5-10" | "10+";

export type YearsOfExperience = {
  experience: YearsOfExperienceRange[];
};

type JobType = { job_type?: string[] };

export type ApprovalStatus = { approved?: boolean; talent_status?: string[] };

type AvailabilityForm = { availability?: [number, number] };
type AvailabilityParams = {
  availability_from: number | undefined;
  availability_to: number | undefined;
};

type TalentAvailabilityOption = "full_time" | "part_time" | "direct_hire";
type TalentAvailability = {
  applied_recently?: boolean;
  looking_for_work?: boolean;
  looking_for_work_options?: TalentAvailabilityOption[];
};
type TalentAvailabilityParams = {
  applied_recently?: boolean;
  looking_for_work?: string;
  looking_for_work_options?: string;
};

type ServiceFormType = {
  help_category: number | undefined;
};

export type WorkingTimezoneJobs = {
  match_my_working_hours?: boolean;
};

export const HOURS_OVERLAP = {
  full_work_day: "full_work_day",
  few_hours: "few_hours",
} as const;

export type HoursOverlap = keyof typeof HOURS_OVERLAP;

export const HOURS_OVERLAP_MAP: Record<
  HoursOverlap,
  { value: HoursOverlap; label: string; onFilterButtonLabel: string }
> = {
  full_work_day: {
    value: "full_work_day",
    label: "The full workday",
    onFilterButtonLabel: "Full day overlap",
  },
  few_hours: {
    label: "A few hours",
    value: "few_hours",
    onFilterButtonLabel: "Partial overlap",
  },
};

export type WorkingTimezoneTalents =
  | {
      timezone: UTCOffset;
      hours_overlap: HoursOverlap;
    }
  | {
      timezone?: undefined;
      hours_overlap?: undefined;
    };

type PostCategoryParam = {
  category?: number;
};

export type JobFilters = ContractType &
  PaymentType &
  Rate &
  Location &
  Ordering &
  Search &
  ApprovalStatus &
  JobType &
  WorkingTimezoneJobs &
  Partial<RoleFormType>;

export type PublicJobFilters = ContractType & Rate & Location & JobType;

export type TalentFilters = Rate &
  TalentLocation &
  Ordering &
  Search &
  ServiceFormType &
  YearsOfExperience &
  TalentAvailability &
  WorkingTimezoneTalents &
  RoleFormType &
  ApprovalStatus;

export type JobFiltersFormValuesType = JobFilters &
  SkillsFormType &
  AvailabilityForm &
  RoleFormType;

export type JobFiltersFetchParams = JobFilters &
  SkillsParams &
  AvailabilityParams &
  RoleParams;

export type PublicJobFiltersFormValuesType = PublicJobFilters &
  SkillsFormType &
  AvailabilityForm &
  RoleFormType;

export type PublicJobFiltersFetchParams = PublicJobFilters &
  SkillsParams &
  AvailabilityParams &
  RoleParams &
  Pick<UIUsedParams, "page">;

export type TalentFiltersFormValuesType = TalentFilters &
  SkillsFormType &
  RoleFormType;

export type TalentFiltersFetchParams = TalentFilters &
  SkillsParams &
  RoleParams &
  UIUsedParams;

export type PostFiltersFormValuesType = Search & Ordering;

export type PostFiltersFetchParams = PostCategoryParam &
  PostFiltersFormValuesType;

export type SearchDataType = SearchEventEntityTypeKey;

export const FEED_ORDERING = {
  CREATED: "-created",
  TOP: "-ranked",
} as const;

export type FeedOrderingType = ObjectValues<typeof FEED_ORDERING>;

export type FeedOrdering = { ordering?: FeedOrderingType };

export const defaultSearch: Search = { search: undefined };

export const SearchForm = createFiltersForm<Search>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultSearch,
  }),
);

////////////////////////////////////////////////////////////////////////////

export const defaultPage = { page: undefined };

////////////////////////////////////////////////////////////////////////////

// default filters must contain the fields, even if values should be undefined
const defaultOrdering: Ordering = { ordering: undefined };

export const SortForm = createFiltersForm<Ordering>(
  new FiltersManager({
    submitOnChange: true,
    useAllURLParams: true,
    defaultFormValues: defaultOrdering,
  }),
);

export const defaultFeedOrdering: FeedOrdering = {
  ordering: FEED_ORDERING.TOP,
};

export const [FeedSortForm, useFeedSortForm] =
  createFiltersFormAndHook<FeedOrdering>({
    submitOnChange: true,
    useAllURLParams: true,
    defaultFormValues: defaultFeedOrdering,
    mapValuesToParams: (values) => {
      if ("activePost" in values) {
        delete values.activePost;
      }

      return values;
    },
  });

////////////////////////////////////////////////////////////////////////////

const defaultRate: Rate = {
  hourly_budget_minimum_usd: undefined,
  custom_rate: undefined,
};

export const RateForm = createFiltersForm<Rate, RateParams>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultRate,
    mapValuesToParams: (values) => {
      const newValues = {
        ...values,
      };

      if (newValues.hourly_budget_minimum_usd === CUSTOM_RATE_VALUE) {
        newValues.hourly_budget_minimum_usd = newValues.custom_rate;
      }

      delete newValues.custom_rate;

      return newValues as RateParams;
    },
    mapParamsToValues: (values) => {
      const newValues = {
        ...values,
      } as Rate;

      const minBudget = values.hourly_budget_minimum_usd;

      if (minBudget && !isRateOptionSelected(minBudget)) {
        newValues.custom_rate = minBudget;
        newValues.hourly_budget_minimum_usd = CUSTOM_RATE_VALUE;
      }

      return newValues;
    },
  }),
);

////////////////////////////////////////////////////////////////////////////

const defaultService: ServiceFormType = {
  help_category: undefined,
};

export const ServicesForm = createFiltersForm<ServiceFormType>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultService,
  }),
);

////////////////////////////////////////////////////////////////////////////

const defaultLocation: Location = {
  work_from_anywhere: undefined,
  work_from_location: undefined,
  custom_location: undefined,
  country_code: undefined,
};

const mapLocation = (values: Location) => {
  const newValues = {
    ...values,
  };

  if (!newValues.work_from_anywhere) {
    newValues.work_from_anywhere = undefined;
  }

  if (!newValues.work_from_location) {
    newValues.location = undefined;
    newValues.country_code = undefined;
    newValues.city = undefined;
    newValues.state = undefined;
    newValues.custom_location = undefined;
  }

  return newValues as Location;
};

export const LocationForm = createFiltersForm<Location, Location>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultLocation,
    mapValuesToParams: mapLocation,
    mapParamsToValues: mapLocation,
  }),
);

const defaultTalentLocation: TalentLocation = {
  place_id: undefined,
  custom_location: undefined,
  location: undefined,
  session_token: undefined,
  include_verified_locations_only: undefined,
};

const mapValuesTalentLocation = (values: TalentLocation) => {
  const newValues = { ...values };

  delete newValues.location;

  return newValues as TalentLocation;
};

export const TalentLocationForm = createFiltersForm<
  TalentLocation,
  TalentLocation
>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultTalentLocation,
    mapValuesToParams: mapValuesTalentLocation,
    mapParamsToValues: mapValuesTalentLocation,
  }),
);

////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////

const defaultJobType: JobType = {
  job_type: [],
};

export const JobTypeForm = createFiltersForm<JobType>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultJobType,
  }),
);

////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////

const defaultCommitment: AvailabilityForm = {
  availability: [5, 40],
};

const defaultCommitmentParams: AvailabilityParams = {
  availability_from: 5,
  availability_to: 40,
};

const defaultCommitmentFilters: AvailabilityForm = {
  availability: undefined,
};

const defaultCommitmentParamsFilters: AvailabilityParams = {
  availability_from: undefined,
  availability_to: undefined,
};

export const CommitmentForm = createFiltersForm<
  AvailabilityForm,
  AvailabilityParams
>(
  new FiltersManager({
    useAllURLParams: true,
    allowToSubmitPristineForm: true,
    defaultFormValues: defaultCommitment,
    defaultParamsValues: defaultCommitmentParams,
    mapValuesToParams: (values) => {
      const newFilters = { ...values } as AvailabilityForm & AvailabilityParams;

      if (newFilters.availability) {
        newFilters.availability_from = newFilters.availability[0];
        newFilters.availability_to = newFilters.availability[1];
        delete newFilters.availability;
      }

      return newFilters as AvailabilityParams;
    },
    mapParamsToValues: (values) => {
      const newFilters = { ...values } as AvailabilityForm & AvailabilityParams;

      if (newFilters.availability_from || newFilters.availability_to) {
        newFilters.availability = [
          newFilters?.availability_from || 0,
          newFilters?.availability_to || 0,
        ];
      }

      delete newFilters.availability_from;
      delete newFilters.availability_to;

      return newFilters as AvailabilityForm;
    },
  }),
);

////////////////////////////////////////////////////////////////////////////

const defaultTalentAvailabilityFilter: TalentAvailability = {
  applied_recently: undefined,
  looking_for_work: undefined,
  looking_for_work_options: [],
};

export const talentAvailabilityOptionSet = new Set<string>(
  Object.values(ENUMS.FreelancerAvailabilityForWork),
);

const isTalentAvailabilityOption = (
  param: string,
): param is TalentAvailabilityOption => {
  return !!param && talentAvailabilityOptionSet.has(param);
};

export const TalentAvailabilityForm = createFiltersForm<
  TalentAvailability,
  TalentAvailabilityParams
>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultTalentAvailabilityFilter,
    mapValuesToParams: (values) => {
      const newValues = { ...values } as TalentAvailabilityParams;

      if (values.looking_for_work_options?.length) {
        newValues.looking_for_work = values.looking_for_work_options.join(",");
      }

      if (!newValues.looking_for_work) {
        delete newValues.looking_for_work;
      }

      delete newValues.looking_for_work_options;

      return newValues;
    },
    mapParamsToValues: (values) => {
      const newValues = { ...values } as TalentAvailability;

      if (values.looking_for_work) {
        newValues.looking_for_work_options = values.looking_for_work
          .split(",")
          .filter(isTalentAvailabilityOption);
      }

      return newValues;
    },
  }),
);
////////////////////////////////////////////////////////////////////////////

export const defaultWorkingTimezoneJobFilter = {
  match_my_working_hours: undefined,
} satisfies WorkingTimezoneJobs;

const defaultWorkingTimezoneJobFormValues: WorkingTimezoneJobs =
  defaultWorkingTimezoneJobFilter;

export const WorkingTimezoneJobsForm = createFiltersForm<WorkingTimezoneJobs>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultWorkingTimezoneJobFormValues,
  }),
);
////////////////////////////////////////////////////////////////////////////

export const defaultWorkingTimezoneTalentFilter: WorkingTimezoneTalents = {
  timezone: undefined,
  hours_overlap: undefined,
};

export const workingTimezoneTalentFilterShape = z.object({
  timezone: z.custom<UTCOffset>(
    (val: unknown) =>
      !!val &&
      typeof val === "string" &&
      /^[+-][0-9][0-9]:[0-9][0-9]/.test(val),
  ),
  hours_overlap: z.nativeEnum(HOURS_OVERLAP),
});

const mapWorkingTimezoneTalentFiltersParams = <
  T extends WorkingTimezoneTalents,
>(
  newValues: T,
): T => {
  const valuesCopy = { ...newValues };

  const parseResult = workingTimezoneTalentFilterShape.safeParse(valuesCopy);
  if (!parseResult.success) {
    delete valuesCopy.timezone;
    delete valuesCopy.hours_overlap;
  }

  return valuesCopy;
};

export const WorkingTimezoneTalentsForm = createFiltersForm<
  WorkingTimezoneTalents,
  WorkingTimezoneTalents
>(
  new FiltersManager({
    useAllURLParams: true,
    defaultFormValues: defaultWorkingTimezoneTalentFilter,
    mapParamsToValues: (values) => {
      return mapWorkingTimezoneTalentFiltersParams(values);
    },
  }),
);

////////////////////////////////////////////////////////////////////////////

const defaultCommonFilters = {
  ...defaultSearch,
  ...defaultRate,
  ...defaultLocation,
  ...defaultJobType,
};

export const useJobFilters = createFiltersSearchParamsHook<
  JobFiltersFormValuesType,
  JobFiltersFetchParams
>(
  new FiltersManager({
    paramsToIgnore: ["show_saved_searches_drawer"], // just for ui purpose
    defaultFormValues: {
      ...defaultCommonFilters,
      ...defaultSkills,
      ...defaultCommitmentFilters,
      ...defaultRole,
      ...defaultWorkingTimezoneJobFormValues,
    },
    defaultParamsValues: {
      ...defaultCommonFilters,
      ...defaultSkillsParams,
      ...defaultCommitmentParamsFilters,
      ...defaultRoleParams,
      ...defaultWorkingTimezoneJobFormValues,
    },
    mapParamsToFetchValues: normalizeRolesAndSkillsFormat,
  }),
);

export const defaultPublicJobFiltersFormValues: PublicJobFiltersFormValuesType =
  {
    ...defaultRate,
    ...defaultLocation,
    ...defaultJobType,
    ...defaultSkills,
    ...defaultCommitmentFilters,
    ...defaultRole,
  };

const defaultLocationParamsFilters: Location = {
  work_from_anywhere: undefined,
  work_from_location: undefined,
  custom_location: undefined,
  country_code: undefined,
  state: undefined,
  city: undefined,
  session_token: undefined,
};

export const defaultPublicJobFiltersParamsValues: PublicJobFiltersFetchParams =
  {
    ...defaultRate,
    ...defaultJobType,
    ...defaultLocationParamsFilters,
    ...defaultSkillsParams,
    ...defaultCommitmentParamsFilters,
    ...defaultRoleParams,
    ...defaultPage,
  };

export const usePublicJobFilters = createFiltersSearchParamsHook<
  JobFiltersFormValuesType,
  JobFiltersFetchParams
>(
  new FiltersManager({
    defaultFormValues: defaultPublicJobFiltersFormValues,
    defaultParamsValues: defaultPublicJobFiltersParamsValues,
    mapParamsToFetchValues: normalizeRolesAndSkillsFormat,
  }),
);

export const useTalentFilters = createFiltersSearchParamsHook<
  TalentFiltersFormValuesType,
  TalentFiltersFetchParams
>(
  new FiltersManager({
    paramsToIgnore: ["location", "talent_status"], // just for ui purpose
    defaultFormValues: {
      ...defaultSearch,
      ...defaultTalentLocation,
      ...defaultExperience,
      ...defaultService,
      ...defaultWorkingTimezoneTalentFilter,
      ...defaultRole,
      ...defaultSkills,
    },
    mapParamsToValues: (values) => {
      const newValues = { ...values } as TalentFiltersFormValuesType;
      return mapWorkingTimezoneTalentFiltersParams(newValues);
    },
    mapParamsToFetchValues: normalizeRolesAndSkillsFormat,
  }),
);

export const usePostsFilters = createFiltersSearchParamsHook<
  PostFiltersFormValuesType,
  PostFiltersFetchParams
>(
  new FiltersManager({
    defaultFormValues: {
      ...defaultSearch,
      ...defaultOrdering,
    },
  }),
);
