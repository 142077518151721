import { type FC, useMemo } from "react";

import {
  Box,
  Chip,
  Loader,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useLazyGetTotalJobBidsQuery } from "@js/apps/jobs/api";

type BoostedJobBadgeProps = {
  isJobOpen?: boolean;
  jobId: number;
};

// Used for talents for their application info
export const BoostedJobBadge: FC<BoostedJobBadgeProps> = ({
  isJobOpen,
  jobId,
}) => {
  const [getTotalJobBidsQuery, { isLoading, data }] =
    useLazyGetTotalJobBidsQuery();

  const tooltipContent = useMemo(() => {
    const defaultLabel = "You've boosted this application.";

    if (isJobOpen) {
      const applicantsCount = data?.bids_count || 0;
      const firstLabel =
        applicantsCount < 10
          ? defaultLabel
          : `Your application is now at the top of ${applicantsCount} applicants.`;

      return isLoading ? (
        <Box display="inline-block">
          <Loader color="inherit" size={12} />
        </Box>
      ) : (
        `${firstLabel} This action cannot be undone.`
      );
    }

    return defaultLabel;
  }, [data?.bids_count, isJobOpen, isLoading]);

  return (
    <>
      <Tooltip
        onOpen={() => isJobOpen && getTotalJobBidsQuery({ jobId })}
        title={tooltipContent}
      >
        <span>
          <Chip
            sx={{ backgroundColor: "var(--medium-blue) !important" }}
            label={
              <Box display="flex" gap={0.75} alignItems="center">
                <Box>
                  <img
                    height="10px"
                    width="auto"
                    src={`${SETTINGS.STATIC_URL}jobs/boost-flash-icon.svg`}
                    alt="boosted-icon"
                  />
                </Box>
                <Typography
                  component="p"
                  variant="paragraph"
                  size="small"
                  sx={{ color: "var(--dark-blue)" }}
                >
                  Boosted
                </Typography>
              </Box>
            }
            size="x-small"
          />
        </span>
      </Tooltip>
    </>
  );
};
