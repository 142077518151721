import dayjs from "dayjs";
import { z } from "zod";

import { DateFormats } from "@js/utils/date/types";

export const transformDate =
  (format: DateFormats = DateFormats["1970-12-31"]) =>
  (val: Date) => {
    const dateUTC = dayjs.utc(val).format(format);
    return dateUTC;
  };

/**
 * @description For simple cases when frontend validation is not needed.
 * In case of custom schema remember we need to operate on UTC date to avoid timezone day shift.
 * */
export const zodDate = (format?: DateFormats) => {
  return z.coerce.date().transform(transformDate(format));
};
