import type { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import type {
  CommentsEventPayload,
  PostsEventPayload,
} from "@js/apps/give-and-get-help/types";
import type { IPost } from "@js/types/give-and-get-help";
import { assertUnreachable } from "@js/utils";

import {
  addCommentReaction,
  deleteCommentReaction,
  isCommentRelatedEvent,
  updateComment,
} from "../comments-update-recipe";
import {
  isPostRelatedEvent,
  patchPostOnCommentAdded,
  patchPostOnCommentDeleted,
} from "../posts-update-recipe";

import {
  addSinglePostReaction,
  addSinglePostVote,
  deleteSinglePostReaction,
  updateSinglePost,
} from "./helpers";

type Draft = MaybeDrafted<IPost>;

export const singlePostUpdateRecipe = (event: MessageEvent, draft: Draft) => {
  const eventData = event.data;
  if (!eventData) {
    return;
  }

  if (isPostRelatedEvent(event)) {
    return handleSinglePostPostsEvent({ eventData, draft });
  }

  if (isCommentRelatedEvent(event)) {
    return handleSinglePostCommentsEvent({ eventData, draft });
  }
};

const handleSinglePostCommentsEvent = ({
  eventData,
  draft,
}: {
  eventData: NonNullable<CommentsEventPayload>;
  draft: Draft;
}) => {
  const broadcast_type = eventData.broadcast_type;

  switch (broadcast_type) {
    case ENUMS.BroadcastType.POST_ADDED: {
      patchPostOnCommentAdded({
        addedComment: eventData.data,
        post: draft,
      });
      break;
    }
    case ENUMS.BroadcastType.POST_UPDATED:
      updateComment(eventData, draft, draft.id);
      break;

    case ENUMS.BroadcastType.POST_DELETED:
      patchPostOnCommentDeleted({
        deletedComment: eventData.data,
        post: draft,
      });
      break;

    case ENUMS.BroadcastType.REACTION_ADDED:
      addCommentReaction(eventData, draft);
      break;

    case ENUMS.BroadcastType.REACTION_DELETED:
      deleteCommentReaction(eventData, draft);
      break;

    default:
      assertUnreachable(broadcast_type);
      break;
  }
};

const handleSinglePostPostsEvent = ({
  eventData,
  draft,
}: {
  eventData: NonNullable<PostsEventPayload>;
  draft: Draft;
}) => {
  const broadcast_type = eventData.broadcast_type;

  switch (broadcast_type) {
    case ENUMS.BroadcastType.POST_UPDATED:
      return updateSinglePost(eventData, draft);
    case ENUMS.BroadcastType.REACTION_ADDED:
      addSinglePostReaction(eventData, draft);
      break;
    case ENUMS.BroadcastType.REACTION_DELETED:
      deleteSinglePostReaction(eventData, draft);
      break;
    case ENUMS.BroadcastType.VOTE_ADDED:
      addSinglePostVote(eventData, draft);
      break;
    case ENUMS.BroadcastType.YOU_VOTED:
      // do nothing - optimistic update
      break;
    case ENUMS.BroadcastType.POST_ADDED:
    case ENUMS.BroadcastType.POST_MOVED_TO_SPACE:
    case ENUMS.BroadcastType.POST_DELETED:
      break;

    default:
      assertUnreachable(broadcast_type);
  }
};
