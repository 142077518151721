import {
  Box,
  Button,
  Loader,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { pluralize } from "@js/utils";

import { useMyTalentStats } from "./use-my-talent-stats";

import styles from "./style.module.scss";

export const MyTalentStats = () => {
  const {
    stats,
    isLoading,
    filterActiveTalents,
    sortContracts,
    onViewJobsClick,
  } = useMyTalentStats();

  return (
    <Box className={styles.stats}>
      <StatCard
        value={stats?.open_roles}
        isLoading={isLoading}
        label={`View job${pluralize(stats?.open_roles || 0, { zeroPlural: true })}`}
        title="Open roles"
        to="/employer/dashboard/my_jobs/"
        onClick={onViewJobsClick}
      />
      <StatCard
        value={stats?.active_talent}
        isLoading={isLoading}
        label="View talent"
        title="Active talent"
        onClick={filterActiveTalents}
      />
      <StatCard
        value={stats?.hired_bids_expiring_within_30_days}
        isLoading={isLoading}
        label="Show contracts"
        title="Contracts ending within 30 days"
        onClick={sortContracts}
      />
    </Box>
  );
};

type StatCardProps = {
  title: string;
  label: string;
  value: number | undefined;
  isLoading: boolean;
  to?: string;
  onClick?: () => void;
};

const StatCard = ({
  title,
  label,
  value = 0,
  isLoading,
  to,
  onClick,
}: StatCardProps) => {
  const sm = useMediaQuery(1100);

  return (
    <Box className={styles.statCard}>
      <Typography variant="label" component="p">
        {title}
      </Typography>
      <Box className={styles.statCardContent}>
        {isLoading ? (
          <Loader />
        ) : (
          <Typography
            data-testid="stat-card-value"
            size={sm ? "small" : "medium"}
            variant="title"
            fontWeight={400}
            component="p"
          >
            {value}
          </Typography>
        )}
        {!!value && (
          <Button
            variant="no-padding"
            RouterLink={RouterLink}
            shape="squared"
            disableRipple
            size="x-small"
            fontWeight={400}
            to={to}
            onClick={onClick}
            sx={{
              whiteSpace: "nowrap",
              textDecoration: "underline !important",
              color: "var(--grey-1)",
            }}
          >
            {label}
          </Button>
        )}
      </Box>
    </Box>
  );
};
