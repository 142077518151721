import { useMemo } from "react";
import _ from "underscore";

import { useUser } from "@js/apps/common/hooks/use-user";
import { useEmployerInvoiceListingContext } from "@js/apps/invoices/context/employer-invoice-listing";
import { openPaymentModal } from "@js/apps/payments/components/payment-modal";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { EmployerInvoice, Invoice } from "@js/types/invoices";

import { markEmployerInvoicesAsPaid } from "../../actions";
import { employerInvoicesApi } from "../../api";
import {
  closeInvoicePreviewModal,
  openInvoicePreviewModal,
} from "../../components/invoice-preview";

type UseEmployerInvoiceListingTableArg = {
  onInvoicesPaid: () => void;
  onInvoicesPaymentFailed: () => void;
};

export const useEmployerInvoiceListingTable = ({
  onInvoicesPaid,
  onInvoicesPaymentFailed,
}: UseEmployerInvoiceListingTableArg) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const {
    invoiceList,
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    resetSelectedInvoicesIds,
  } = useEmployerInvoiceListingContext();

  const isUnpayableInvoiceSelected = useMemo(() => {
    return !!_.findWhere(
      invoiceList.filter(({ id }) => selectedInvoicesIds.includes(id)),
      { can_be_paid: false },
    );
  }, [invoiceList, selectedInvoicesIds]);

  const onCloseSuccessModal = () => {
    closeInvoicePreviewModal();
    resetSelectedInvoicesIds();
    onInvoicesPaid();
  };

  const startPaymentFlow = () => {
    if (!user?.is_otp_enabled) {
      openOTPDisabledWarningModal();
      return;
    }

    openPaymentModal({ onInvoicesPaymentFailed });
  };

  const handleSingleInvoicePayment = (invoice: Invoice) => {
    setSelectedInvoicesIds([invoice.id]);
    startPaymentFlow();
  };

  const onInvoicesMarkedAsPaid = () => {
    dispatch(
      employerInvoicesApi.util.invalidateTags([
        "EmployerInvoicesIds",
        "EmployerInvoicesMinimal",
      ]),
    );
    resetSelectedInvoicesIds();
  };

  const markInvoiceAsPaid = async (invoicesIds: number[]) => {
    return markEmployerInvoicesAsPaid(invoicesIds)
      .then(() => {
        onInvoicesPaid();
        onInvoicesMarkedAsPaid();
        Snackbar.success("Successfully marked as paid.");
      })
      .catch((error) => Snackbar.error(error));
  };

  const openInvoicePreview = (invoice: EmployerInvoice) => {
    openInvoicePreviewModal({
      onPay: handleSingleInvoicePayment,
      markInvoiceAsPaidCallback: () => {
        onInvoicesPaid();
        onInvoicesMarkedAsPaid();
        closeInvoicePreviewModal();
      },
      invoice,
      invoiceEndpoint: "employer_invoices",
    });
  };

  return {
    isUnpayableInvoiceSelected,
    invoiceList,
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    onCloseSuccessModal,
    handleSingleInvoicePayment,
    startPaymentFlow,
    markInvoiceAsPaid,
    openInvoicePreview,
  };
};
