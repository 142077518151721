import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import type { GetEmployerBatchInvoicesParamsOrderBy } from "@js/apps/invoices/types";
import { useParsedQueryParams } from "@js/hooks/parsed-query-params";
import { clearEmpties } from "@js/utils";

import { EMPLOYER_INVOICE_BATCHES_TABLE_SORTABLE_COLUMNS } from "./columns";

const schema = z.object({
  page: z.coerce.number().positive().int().optional(),
  order_by: z.enum(EMPLOYER_INVOICE_BATCHES_TABLE_SORTABLE_COLUMNS).optional(),
  order_dir: z.enum(["asc", "desc"]).optional(),
});

type EmployerInvoiceBatchesFilters = z.infer<typeof schema>;

export const useEmployerInvoiceBatchesFilters = () => {
  const [, setSearchParams] = useSearchParams();
  const { data: filters } = useParsedQueryParams(schema);

  const filtersWithDefaults = useMemo(() => {
    return { page: 1, ...filters };
  }, [filters]);

  const handleSortChange = ({
    sortBy,
    sortDir,
  }: {
    sortBy: GetEmployerBatchInvoicesParamsOrderBy | undefined;
    sortDir: "asc" | "desc" | undefined;
  }) => {
    setSearchParams(
      (prevParams) => {
        const oldParamsObject = Object.fromEntries(
          new URLSearchParams(prevParams).entries(),
        );

        const newParamsObject: EmployerInvoiceBatchesFilters = {
          ...oldParamsObject,
          order_by: sortBy,
          order_dir: sortDir,
          page: undefined,
        };

        clearEmpties(newParamsObject);

        return new URLSearchParams(newParamsObject as Record<string, string>);
      },
      { state: { disableRemountIfTheSameLocation: true } },
    );
  };

  return { filters: filtersWithDefaults, handleSortChange };
};
