import { useCallback } from "react";
import { type Control, Controller } from "react-hook-form";

import { Carousel, Stack } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import {
  clickMyTalentSearch,
  filterMyTalentSearch,
} from "@js/apps/employer/actions";
import { useAppDispatch } from "@js/hooks";

import type { MyTalentFilterToApply } from "./constants";
import { MY_TALENT_FILTER_TO_APPLY_LABEL } from "./constants";
import { MyTalentJobNameFilterPopover } from "./my-talent-job-name-filter-popover";
import { MyTalentJobOwnerFilterPopover } from "./my-talent-job-owner-filter-popover";
import { MyTalentRateTypeFilterPopover } from "./my-talent-rate-type-filter-popover";
import { MyTalentRoleFilterPopover } from "./my-talent-role-filter-popover";
import { MyTalentSearchFilter } from "./my-talent-search-filter";
import { MyTalentStatusFilterPopover } from "./my-talent-status-filter-popover";
import type { EmployerMyTalentFormValues } from "./schemas";

import styles from "./my-talent-filters.module.scss";

type MyTalentFiltersProps = {
  control: Control<EmployerMyTalentFormValues>;
};

export const MyTalentFilters = ({ control }: MyTalentFiltersProps) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const userHasAccessToInvoices = !!user?.can_view_employer_invoices;

  const handleFilterApply: GetFilterSlidesArg["handleFilterApply"] =
    useCallback(
      ({ filter, value }) => {
        dispatch(
          filterMyTalentSearch({
            filterType: MY_TALENT_FILTER_TO_APPLY_LABEL[filter],
            filterValue: value,
          }),
        );
      },
      [dispatch],
    );

  const handleSearchChange = useCallback(
    (search: string | null | undefined) => {
      if (!search) {
        return;
      }

      dispatch(clickMyTalentSearch());
    },
    [dispatch],
  );

  const slides = getFilterSlides({
    userHasAccessToInvoices,
    control,
    handleFilterApply,
  });

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
      <Controller
        control={control}
        name="search"
        render={({ field: { onChange, value } }) => (
          <MyTalentSearchFilter
            search={value}
            onChange={(newFilterValue) => {
              onChange(newFilterValue);
              handleSearchChange(newFilterValue);
            }}
          />
        )}
      />

      <Carousel
        slideProps={{ style: { height: "auto", width: "auto" } }}
        spaceBetween={8}
        slides={slides}
        className={styles.filtersCarousel}
      />
    </Stack>
  );
};

type GetFilterSlidesArg = {
  userHasAccessToInvoices: boolean;
  control: Control<EmployerMyTalentFormValues>;
  handleFilterApply: (arg: {
    filter: MyTalentFilterToApply;
    value: unknown;
  }) => void;
};

const getFilterSlides = ({
  userHasAccessToInvoices,
  control,
  handleFilterApply,
}: GetFilterSlidesArg) => {
  return [
    <Controller
      key="status"
      control={control}
      name="status"
      render={({ field: { onChange, value, name } }) => (
        <MyTalentStatusFilterPopover
          status={value}
          onChange={(newFilterValue) => {
            onChange(newFilterValue);
            handleFilterApply({ filter: name, value: newFilterValue });
          }}
        />
      )}
    />,

    <Controller
      key="job"
      control={control}
      name="jobs"
      render={({ field: { onChange, value, name } }) => (
        <MyTalentJobNameFilterPopover
          jobs={value}
          onChange={(newFilterValue) => {
            onChange(newFilterValue);
            handleFilterApply({ filter: name, value: newFilterValue });
          }}
        />
      )}
    />,

    <Controller
      key="owner"
      control={control}
      name="job_owners"
      render={({ field: { onChange, value, name } }) => (
        <MyTalentJobOwnerFilterPopover
          jobOwners={value}
          onChange={(newFilterValue) => {
            onChange(newFilterValue);
            handleFilterApply({ filter: name, value: newFilterValue });
          }}
        />
      )}
    />,

    <Controller
      key="role"
      control={control}
      name="roles"
      render={({ field: { onChange, value, name } }) => (
        <MyTalentRoleFilterPopover
          roles={value}
          onChange={(newFilterValue) => {
            onChange(newFilterValue);
            handleFilterApply({ filter: name, value: newFilterValue });
          }}
        />
      )}
    />,

    userHasAccessToInvoices ? (
      <Controller
        key="rate"
        control={control}
        name="rate_types"
        render={({ field: { onChange, value, name } }) => (
          <MyTalentRateTypeFilterPopover
            rateTypes={value}
            onChange={(newFilterValue) => {
              onChange(newFilterValue);
              handleFilterApply({ filter: name, value: newFilterValue });
            }}
          />
        )}
      />
    ) : null,
  ].filter(Boolean);
};
