import React from "react";

import { useSuggestPostCategoryMutation } from "@js/apps/give-and-get-help/api";
import {
  SuggestCategoryForm,
  SuggestCategoryModalFields,
} from "@js/apps/give-and-get-help/components/suggest-category-modal";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

type SuggestCategoryModalProps = {
  onCancel: () => void;
  onSubmitSuccess: () => void;
  onSubmitFail: () => void;
};

export const openSuggestCategoryModal = ({
  onSubmitSuccess,
  onSubmitFail,
  onCancel,
}: SuggestCategoryModalProps) => {
  ModalInstance.open({
    closeButton: false,
    className: "suggest-category-modal",
    keepOnBackdropClick: true,
    disableEscapeKeyDown: true,
    children: (
      <SuggestCategoryModal
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFail={onSubmitFail}
        onCancel={onCancel}
      />
    ),
  });
};

const SuggestCategoryModal = ({
  onSubmitSuccess,
  onSubmitFail,
  onCancel,
}: SuggestCategoryModalProps) => {
  const [suggestCategory] = useSuggestPostCategoryMutation();

  return (
    <SuggestCategoryForm
      onSubmit={({ categoryName }: { categoryName: string }) =>
        new Promise((resolve, reject) => {
          suggestCategory({ name: categoryName })
            .unwrap()
            .then(resolve)
            .catch(reject);
        })
      }
      onSubmitSuccess={() => {
        ModalInstance.close();
        onSubmitSuccess();
      }}
      onSubmitFail={(error) => {
        if (!error?.categoryName) {
          Snackbar.error("Failed to suggest a category.");
        }

        onSubmitFail();
      }}
    >
      <SuggestCategoryModalFields
        onCancel={() => {
          ModalInstance.close();
          onCancel();
        }}
      />
    </SuggestCategoryForm>
  );
};
