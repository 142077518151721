import React from "react";

import type {
  BoxProps,
  TypographyColors,
  TypographyProps,
} from "@hexocean/braintrust-ui-components";
import {
  Box,
  IconButton,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { LongArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

type DefaultActionBarProps = Omit<BoxProps, "content"> & {
  title: string;
  subtitle: React.ReactNode;
  actions?: JSX.Element[];
  backUrl?: string;
  textColor?: TypographyColors;
  titleProps?: Optional<TypographyProps, "component">;
  subtitleProps?: Optional<TypographyProps, "component">;
  content?: JSX.Element;
};

export const DefaultActionBar = ({
  title,
  subtitle,
  actions = [],
  backUrl,
  content,
  textColor = "white",
  titleProps,
  subtitleProps,
  ...props
}: DefaultActionBarProps) => {
  return (
    <RoundedBox
      borderRadius="24px"
      display="flex"
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
      flexDirection={{ xs: "column", sm: "row" }}
      gap={4}
      px={{ xs: 3, sm: "36px" }}
      py={{ xs: 3, sm: "42px" }}
      className="action__bar capitalize-first-letter"
      {...props}
    >
      <Box>
        <Typography
          color={textColor}
          component="h2"
          variant="paragraph"
          size="large"
          {...titleProps}
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          {backUrl && (
            <IconButton
              href={backUrl}
              variant="transparent"
              RouterLink={RouterLink}
              aria-label="Go back"
            >
              <LongArrowLeftIcon sx={{ color: "var(--white)" }} />
            </IconButton>
          )}
          <Typography
            color={textColor}
            variant="label"
            size="small"
            component="p"
            fontWeight={400}
            {...subtitleProps}
          >
            {subtitle}
          </Typography>
        </Box>
        {content && <Box>{content}</Box>}
      </Box>
      <Box
        display="flex"
        flexShrink={0}
        alignItems="center"
        className="buttons__wrapper"
      >
        {actions.map((action, index) => {
          return (
            <Box key={action.key} ml={index === 0 ? 0 : 1}>
              {action}
            </Box>
          );
        })}
      </Box>
    </RoundedBox>
  );
};
