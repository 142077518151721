import { useMemo } from "react";
import z from "zod";

import { useUser } from "@js/apps/common/hooks";
import { useLocalStorageState } from "@js/apps/common/hooks/use-local-storage-state";
import { LocalStorage } from "@js/services";

import type { EmployerMyTalentColumnField } from "../types";

import { MY_TALENT_TABLE_COLUMNS } from "./columns";

const defaultColumns = MY_TALENT_TABLE_COLUMNS.filter(
  (cell) => cell.default,
).map((cell) => cell.field);

const allFields = MY_TALENT_TABLE_COLUMNS.map((col) => col.field) as [
  EmployerMyTalentColumnField,
  ...EmployerMyTalentColumnField[],
];

const myTalentTableColumnsWithoutInvoices = MY_TALENT_TABLE_COLUMNS.filter(
  (column) => !column.view_invoices_permission_required,
);

const visibleColumnsValueSchema = z.array(z.enum(allFields));

export const useMyTalentCustomizedColumns = () => {
  const user = useUser();
  const [columns, setColumns] = useLocalStorageState<
    EmployerMyTalentColumnField[]
  >({
    key: LocalStorage.keys.MY_TALENT_TABLE_COLUMNS_VISIBILITY,
    initialValue: defaultColumns,
  });

  const userHasAccessToInvoices = !!user?.can_view_employer_invoices;
  const columnOptions = userHasAccessToInvoices
    ? MY_TALENT_TABLE_COLUMNS
    : myTalentTableColumnsWithoutInvoices;

  const sanitizedColumns = useMemo(() => {
    const parseResult = visibleColumnsValueSchema.safeParse(columns);
    if (!parseResult.success) {
      return defaultColumns;
    }

    const filteredColumns = parseResult.data.filter((columnKey) =>
      columnOptions.some((columnOption) => columnOption.field === columnKey),
    );

    if (filteredColumns.length === 0) {
      return defaultColumns;
    }

    return filteredColumns;
  }, [columns, columnOptions]);

  const options = useMemo(() => {
    return columnOptions.map((column) => {
      const disabled =
        sanitizedColumns.length === 1 &&
        sanitizedColumns.includes(column.field);

      return {
        value: column.field,
        label: column.headerName,
        disabled,
      };
    });
  }, [sanitizedColumns, columnOptions]);

  const onChange = (selectedValue: EmployerMyTalentColumnField[]) => {
    setColumns(selectedValue);
  };

  return {
    columns: sanitizedColumns,
    options,
    onChange,
  };
};
