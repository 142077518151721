import { Chip } from "@hexocean/braintrust-ui-components";
import { type GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { EmployerMyTalent } from "@js/types/employer";

type StatusCellProps = GridRenderCellParams<
  EmployerMyTalent,
  EmployerMyTalent["status_label"]
>;

export const StatusCell = ({ value }: StatusCellProps) => {
  return (
    <Chip
      label={value}
      color={value === ENUMS.IsActiveStatusLabels.inactive ? "beige" : "green"}
      size="small"
      sx={{ p: "4px 12px !important" }}
      LabelProps={{
        variant: "label",
        size: "small",
      }}
    />
  );
};
