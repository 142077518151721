import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useUpdateEmployerBidInterviewSectionMutation } from "../../api";

type NextRoundButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  onOptionClick?: () => void;
  closeDrawer?: () => void;
} & Partial<ButtonProps>;

export const NextRoundButton = ({
  job,
  bid,
  onOptionClick,
  closeDrawer,
  ...buttonProps
}: NextRoundButtonProps) => {
  const [updateEmployerBidInterviewSection] =
    useUpdateEmployerBidInterviewSectionMutation();
  return (
    <Button
      variant="primary"
      shape="squared"
      disabled={!!job.completed_at || job.openings_left === 0}
      {...buttonProps}
      sx={{ flexShrink: 0 }}
      onClick={async () => {
        onOptionClick?.();
        await updateEmployerBidInterviewSection({
          bidId: bid.id,
          status: ENUMS.JobInterviewStatus.ROUND_2,
        });
        if (closeDrawer) closeDrawer();
      }}
    >
      Advance to next round
    </Button>
  );
};
