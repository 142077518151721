import { SheetPaperIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";
import { useHasFilterPopoverAnimationFinished } from "@js/hooks/use-has-filter-popover-animation-finished";

import { EmployerMyTalentJobNameForm } from "./forms/employer-my-talent-job-name-form";
import { MY_TALENT_FILTER_TO_APPLY_LABEL } from "./constants";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentJobNameFilterPopoverProps = {
  jobs: EmployerMyTalentFormValues["jobs"];
  onChange: (jobs: EmployerMyTalentFormValues["jobs"]) => void;
};

export const MyTalentJobNameFilterPopover = ({
  jobs,
  onChange,
}: MyTalentJobNameFilterPopoverProps) => {
  const isAnyJobApplied = Boolean(jobs && jobs.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, hasPopoverAnimationFinished } =
    useHasFilterPopoverAnimationFinished();

  const handleApply = (newJobs: EmployerMyTalentFormValues["jobs"]) => {
    onChange(newJobs);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterPopover
      label={MY_TALENT_FILTER_TO_APPLY_LABEL.jobs}
      icon={<SheetPaperIcon />}
      isFilterApplied={isAnyJobApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
    >
      <EmployerMyTalentJobNameForm
        jobs={jobs}
        onApply={handleApply}
        shouldOpenDropdown={hasPopoverAnimationFinished}
      />
    </FilterPopover>
  );
};
