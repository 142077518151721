import { useMemo, useRef } from "react";

import {
  Box,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { prepareDOMInput } from "@js/services/dom-sanitize";
import type { PostComment } from "@js/types/give-and-get-help";

import { useHandleReadMore } from "../../hooks/use-handle-read-more";
import { ReadMoreButton } from "../read-more-button";

import styles from "./styles.module.scss";

type PostCommentTextProps = {
  comment: PostComment;
  onExpand: () => void;
  onCollapse: () => void;
};

export const PostCommentText = ({
  comment,
  onCollapse,
  onExpand,
}: PostCommentTextProps) => {
  const isMobile = useMediaQuery("sm");
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { textContent, showButton, showFullText, toggleReadMore } =
    useHandleReadMore({
      id: comment.id,
      content: comment.text,
      parentRef: contentRef,
      onExpand,
      onCollapse,
    });

  const sanitizedText = useMemo(
    () => prepareDOMInput(textContent, { markdown: false }),
    [textContent],
  );

  return (
    <Box ref={contentRef}>
      <Typography
        variant="paragraph"
        component="div"
        size={isMobile ? "small" : "medium"}
        className={styles.commentText}
      >
        <span dangerouslySetInnerHTML={{ __html: sanitizedText }} />
        {showButton && (
          <ReadMoreButton
            showFullText={showFullText}
            onClick={toggleReadMore}
          />
        )}
      </Typography>
    </Box>
  );
};
