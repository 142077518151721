import { useCallback, useEffect, useState } from "react";
import { change } from "redux-form";

import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { JobFormValues, ManagedJob } from "@js/types/jobs";

import { useGenerateInterviewQuestionMutation } from "../../api";
import { JobInterviewQuestionSnackbarContent } from "../../components/job-interview-question-snackbar-content";
import { INTERVIEW_QUESTIONS_FORM_ID } from "../../constants";

const errorMessage = {
  interview_question: {
    title: "AI generated question error",
    message:
      "Unable to automatically generate questions, please type the questions you’d like to ask.",
  },
  interview_criteria: {
    title: "AI grading criteria error",
    message:
      "Unable to automatically generate grading criteria for, please specify your own grading criteria.",
  },
};

export const useAiInterviewQuestion = (job: ManagedJob | undefined) => {
  const [isGeneratingQuestions, setIsGeneratingQuestions] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [generateInterviewQuestion] = useGenerateInterviewQuestionMutation();

  const snackbarError = ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => {
    Snackbar.custom(
      <JobInterviewQuestionSnackbarContent header={title} message={message} />,
      {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      },
    );
  };

  const updateForm = useCallback(
    (
      data: Pick<
        JobFormValues,
        "is_ai_interview_enabled" | "interview_questions" | "interview_criteria"
      >,
    ) => {
      for (const key in data) {
        dispatch(change(INTERVIEW_QUESTIONS_FORM_ID, key, data[key]));
      }
    },
    [dispatch],
  );

  const enableAndGenerateQuestion = useCallback(
    async (jobId: number) => {
      setIsGeneratingQuestions(true);
      generateInterviewQuestion({ jobId })
        .unwrap()
        .then((data) => {
          updateForm({
            is_ai_interview_enabled: true,
            interview_questions: data?.interview_questions,
            interview_criteria: data?.interview_criteria?.map(
              (criteria) => criteria.name,
            ),
          });
        })
        .catch(() => {
          snackbarError(errorMessage.interview_question);
        })
        .finally(() => setIsGeneratingQuestions(false));
    },
    [generateInterviewQuestion, updateForm],
  );

  useEffect(() => {
    if (!job || !job.user_can_manage) {
      return;
    }

    if (
      !job?.is_ai_interview_enabled &&
      job.interview_questions?.length === 0
    ) {
      enableAndGenerateQuestion(job.id);
    }
  }, [job, enableAndGenerateQuestion]);

  useEffect(() => {
    if (!job || !job.user_can_manage || !job.is_ai_interview_enabled) {
      return;
    }

    if (job?.interview_questions?.length === 0) {
      snackbarError(errorMessage.interview_question);
    }
    if (job?.interview_criteria?.length === 0) {
      snackbarError(errorMessage.interview_criteria);
    }
  }, [job]);

  return {
    isGeneratingQuestions,
  };
};
