import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import { type GridColDef } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { GetEmployerBatchInvoicesParamsOrderBy } from "@js/apps/invoices/types";
import type { InvoiceBatch } from "@js/types/invoices";

import type { EmployerInvoiceBatchesTableColumnField } from "../types";

import { DownloadCell } from "./download-cell";

export type EmployerInvoiceBatchesTableColumnDef = GridColDef<InvoiceBatch> & {
  field: EmployerInvoiceBatchesTableColumnField;
  headerName: string;
};

export const EMPLOYER_INVOICE_BATCHES_TABLE_SORTABLE_COLUMNS = [
  "frequency",
  "period_end",
  "period_start",
] as const satisfies GetEmployerBatchInvoicesParamsOrderBy[];

export const EMPLOYER_INVOICE_BATCHES_TABLE_COLUMNS: EmployerInvoiceBatchesTableColumnDef[] =
  [
    {
      field: "frequency",
      headerName: "Frequency",
      minWidth: 140,
      maxWidth: 230,
      flex: 1,
    },
    {
      field: "period_start",
      headerName: "Period start",
      minWidth: 140,
      maxWidth: 230,
      flex: 1,
    },
    {
      field: "period_end",
      headerName: "Period end",
      minWidth: 140,
      maxWidth: 230,
      flex: 1,
    },
    {
      field: "pdf_file",
      headerName: "PDF file",
      minWidth: 150,
      maxWidth: 200,
      flex: 1,
      renderCell: ({
        value,
      }: GridRenderCellParams<InvoiceBatch, InvoiceBatch["pdf_file"]>) => {
        return <DownloadCell href={value?.file} />;
      },
    },
    {
      field: "summary_page_pdf_file",
      headerName: "Summary PDF file",
      minWidth: 180,
      maxWidth: 240,
      flex: 1,
      renderCell: ({
        value,
      }: GridRenderCellParams<
        InvoiceBatch,
        InvoiceBatch["summary_page_pdf_file"]
      >) => {
        return <DownloadCell href={value?.file} />;
      },
    },
    {
      field: "zip_file",
      headerName: "Zip file",
      width: 150,
      renderCell: ({
        value,
      }: GridRenderCellParams<InvoiceBatch, InvoiceBatch["zip_file"]>) => {
        return <DownloadCell href={value?.file} />;
      },
    },
  ];

export const getEmployerInvoiceBatchesTableColumns = () => {
  return EMPLOYER_INVOICE_BATCHES_TABLE_COLUMNS.map((column) => {
    return {
      ...column,
      resizable: false,
      sortable: (
        EMPLOYER_INVOICE_BATCHES_TABLE_SORTABLE_COLUMNS as string[]
      ).includes(column.field),
    };
  });
};
