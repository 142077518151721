import {
  FETCHING_INVITATION_HISTORY,
  FETCHING_INVITATION_HISTORY_FAILED,
  FETCHING_INVITATION_HISTORY_SUCCESS,
  FETCHING_JOB_EVENTS,
  FETCHING_JOB_EVENTS_FAILED,
  FETCHING_JOB_EVENTS_SUCCESS,
  FETCHING_PROPOSAL_HISTORY,
  FETCHING_PROPOSAL_HISTORY_FAILED,
  FETCHING_PROPOSAL_HISTORY_SUCCESS,
} from "@js/apps/jobs/action-types";
import type { JobsState } from "@js/types/jobs";

import * as CreateJobActionTypes from "./apps/create-job/action-types";
import {
  GENERATE_AI_JOB_DESCRIPTION,
  UPLOAD_JOB_DESCRIPTION,
} from "./apps/create-job/action-types";

const INITIAL_STATE: JobsState = {
  total: 0,
  savedSections: [],
  fetchingFreelancerOffers: false,
  freelancerOffers: null,
  fetchingInvitationHistory: false,
  invitationHistory: [],
  fetchingProposalHistory: false,
  proposalHistory: { count: 0, next: null, previous: null, results: [] },
  fetchingJobEvents: false,
  aiGenerated: null,
  uploadedJobDescription: null,
  jobEvents: {
    results: [],
    count: 0,
  },
};

export default (state = INITIAL_STATE, action): JobsState => {
  switch (action.type) {
    case FETCHING_JOB_EVENTS:
      return {
        ...state,
        fetchingJobEvents: true,
      };
    case FETCHING_JOB_EVENTS_SUCCESS:
      return {
        ...state,
        fetchingJobEvents: false,
        jobEvents: action.payload,
      };
    case FETCHING_JOB_EVENTS_FAILED:
      return {
        ...state,
        fetchingJobEvents: false,
        jobEvents: INITIAL_STATE.jobEvents,
      };

    case CreateJobActionTypes.CLEAR_JOB_FORM_STATE:
      return {
        ...state,
        uploadedJobDescription: null,
        aiGenerated: null,
      };

    case CreateJobActionTypes.SET_FIELDS_TO_DISPLAY_CHANGES_SAVED_MARKER:
      return {
        ...state,
        savedSections: action.payload,
      };

    case FETCHING_INVITATION_HISTORY:
      return {
        ...state,
        fetchingInvitationHistory: true,
        invitationHistory: INITIAL_STATE.invitationHistory,
      };
    case FETCHING_INVITATION_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingInvitationHistory: false,
        invitationHistory: action.payload,
      };
    case FETCHING_INVITATION_HISTORY_FAILED:
      return {
        ...state,
        fetchingInvitationHistory: false,
        invitationHistory: INITIAL_STATE.invitationHistory,
      };

    case FETCHING_PROPOSAL_HISTORY:
      return {
        ...state,
        fetchingProposalHistory: true,
        proposalHistory: INITIAL_STATE.proposalHistory,
      };
    case FETCHING_PROPOSAL_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingProposalHistory: false,
        proposalHistory: action.payload,
      };
    case FETCHING_PROPOSAL_HISTORY_FAILED:
      return {
        ...state,
        fetchingProposalHistory: false,
        proposalHistory: INITIAL_STATE.proposalHistory,
      };

    case UPLOAD_JOB_DESCRIPTION:
      return {
        ...state,
        uploadedJobDescription: action.payload,
      };

    case GENERATE_AI_JOB_DESCRIPTION:
      return {
        ...state,
        aiGenerated: action.payload,
      };

    default:
      return state;
  }
};
