import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmissionError } from "redux-form";
import _ from "underscore";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import {
  useGetFreelancerBidQuery,
  useUpdateFreelancerBidMutation,
} from "@js/apps/freelancer/api";
import { Snackbar } from "@js/components/snackbar";
import { WARNING_MODAL_TYPE } from "@js/forms/hooks/unsaved-changes-warning";
import { useAppDispatch } from "@js/hooks";
import type { FreelancerBidParams } from "@js/types/jobs";
import { deepClone, mapKeys } from "@js/utils";

export const useEditBid = () => {
  const { bidId } = useParams();
  const {
    isLoading,
    data: bid,
    refetch: refetchFreelancerBid,
  } = useGetFreelancerBidQuery({ id: bidId });
  const user = useUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateFreelancerBid] = useUpdateFreelancerBidMutation();

  const onSubmit = useCallback(
    (values: FreelancerBidParams) => {
      const _values = deepClone(values);
      if (_values["payment_amount"] === "")
        _values["payment_amount"] = undefined;
      _values.resume =
        _values.resume === null || typeof _values.resume === "number"
          ? _values.resume
          : _values.resume?.id;

      return updateFreelancerBid({ id: bid?.id, values: _values })
        .unwrap()
        .then(() => Snackbar.success(`Application has been updated.`))
        .then(refetchFreelancerBid)
        .catch((error) => {
          if (error.data) throw new SubmissionError(error.data);
        });
    },
    [bid, updateFreelancerBid, refetchFreelancerBid],
  );

  const onSubmitSuccess = useCallback(async () => {
    if (user?.freelancer) {
      await dispatch(fetchFreelancerProfile(user.freelancer));
      await dispatch(fetchCurrentUser());
    }
    navigate("/talent/dashboard/my_jobs/my_proposals/");
  }, [dispatch, navigate, user?.freelancer]);

  const initialValues = useMemo(() => {
    if (!bid) return {};

    const applicationAnswers = mapKeys(
      bid.application_answers || [],
      "application_question",
    );
    const paymentAmount = bid.payment_amount?.endsWith(".00")
      ? bid.payment_amount.slice(0, -3)
      : bid.payment_amount;

    return {
      attachments: bid.attachments,
      id: bid.id,
      job: bid.job.id,
      payment_amount: paymentAmount,
      location_requirement_met: bid.location_requirement_met,
      timezone_requirement_met: bid.timezone_requirement_met,
      calendar_url: bid.calendar_url,
      new_application_answers:
        bid.application_questions?.map(({ id, ...rest }) => {
          const hasId = !!id;
          return {
            ...rest,
            application_question: id,
            answer: hasId ? applicationAnswers[id]?.answer : null,
          };
        }) || [],
      attachments_ids: _.pluck(bid.attachments ?? [], "id"),
      freelancer_availability: bid.freelancer_availability,
      freelancer_availability_specific_date:
        bid.freelancer_availability_specific_date,
      resume: bid.resume,
      is_boosted: bid?.is_boosted,
    } as FreelancerBidParams;
  }, [bid]);

  const _loading = !bid || isLoading;

  return {
    attachments: bid?.attachments,
    job: bid?.job,
    loading: _loading,
    bid,
    onSubmit,
    onSubmitSuccess,
    initialValues,
    unsavedChangesWarning: true,
    unsavedChangesWarningModelType: WARNING_MODAL_TYPE.NEW_BID,
  };
};
