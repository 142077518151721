import { useEffect, useMemo, useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { fetchPopularLocationOptions } from "@js/apps/common/actions";
import type {
  LocationSelectReason,
  TalentMixedLocation,
} from "@js/apps/freelancer/types";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { handleOptionDisabled } from "../hook/helpers";
import type { JobCustomLocationlabels } from "../types";

type UsePopularLocationsButtonsProps = {
  input: TypedWrappedFieldProps<TalentMixedLocation[] | null>["input"];
  onChange: (
    data: Array<TalentMixedLocation | string>,
    reason: LocationSelectReason,
  ) => void;
};

const JobCustomLocationsKeys = Object.keys(ENUMS.JobCustomLocationTypeLabels);

const JobCustomLocationsLabels: JobCustomLocationlabels =
  ENUMS.JobCustomLocationTypeLabels;

export const usePopularLocationsButtons = ({
  input,
  onChange,
}: UsePopularLocationsButtonsProps) => {
  const options = useAppSelector((state) => state.common.popularLocations);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const mappedOptions = useMemo(() => {
    return options.map((location) => {
      if (
        location.custom_location &&
        JobCustomLocationsKeys.includes(location.custom_location)
      ) {
        return {
          ...location,
          name: JobCustomLocationsLabels[location.custom_location] as string,
        };
      }

      return location;
    });
  }, [options]);

  useEffect(() => {
    dispatch(fetchPopularLocationOptions());
  }, [dispatch]);

  useEffect(() => {
    const newSelectedLocation = Array.isArray(input.value)
      ? input.value.map(
          (location) =>
            (location.custom_location ||
              location.place_id ||
              location.id) as string,
        )
      : [];

    setSelectedLocations(newSelectedLocation);
  }, [input.value]);

  const filterOutSelectedValue = (value: string) => {
    const filteredValues = [...(input.value || [])].filter((location) => {
      const locationId =
        location.id || location.custom_location || location.place_id;

      return locationId !== value;
    });

    return filteredValues;
  };

  const handleOnClick = (value: string) => {
    if (isBtnSelected(value)) {
      const newValue = filterOutSelectedValue(value);

      onChange(newValue, "removeOption");

      return;
    }

    const newValue =
      mappedOptions.find(
        (option) =>
          option.custom_location === value || option.place_id === value,
      ) || value;

    const processedValue =
      typeof newValue === "object"
        ? {
            ...newValue,
            place_id: newValue.place_id ?? undefined,
            name: newValue.name ?? undefined,
          }
        : newValue;

    onChange([...(input.value || []), processedValue], "selectOption");
  };

  const isBtnSelected = (btnValue: string) => {
    return selectedLocations.includes(btnValue);
  };

  const isBtnDisabled = (btnValue: string) => {
    return handleOptionDisabled(btnValue, input.value || []);
  };

  return {
    popularLocationOptions: mappedOptions,
    handleOnClick,
    isBtnSelected,
    isBtnDisabled,
  };
};
