import { useState } from "react";
import React from "react";
import type { WrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";

import {
  AutocompleteSmallerInput,
  PaperWithVioletOptions,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  CircleCheckGreenIcon,
  CircleUncheckedIcon,
  LocationPinIcon,
  MenuArrowDownIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import {
  type Location,
  LocationForm,
} from "@js/apps/common/components/filters/config";
import { usePredictedFreelancerLocationDetails } from "@js/apps/common/hooks/use-predicted-freelancer-location-details";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { CheckboxField } from "@js/forms/fields";
import { GoogleComboboxField } from "@js/forms/fields";
import { useQueryParams } from "@js/hooks";
import { PossibleFilters } from "@js/types/common";

import { JobPopoverFilterButton } from "../../components";

import { useJobLocationFilterFieldManager } from "./use-job-location-filter-field-manager";

const WORK_FROM_ANYWHERE_LABEL = "Work from anywhere";

const useLocationFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const queryParams = useQueryParams();
  const selectedLocation = isLocationSelected(queryParams)
    ? {
        city: queryParams.city,
        state: queryParams.state,
        country: queryParams.country,
        custom_location: queryParams.custom_location,
        location: queryParams.location,
      }
    : null;
  const workFromAnywhere = queryParams.work_from_anywhere === "true";
  const workFromLocation = queryParams.work_from_location === "true";
  const { predictedFreelancerLocationDetails } =
    usePredictedFreelancerLocationDetails();

  const initialLocation = predictedFreelancerLocationDetails
    ? {
        location: predictedFreelancerLocationDetails?.formatted_address || "",
        place_details: predictedFreelancerLocationDetails,
      }
    : null;

  const isActive = (!!selectedLocation && workFromLocation) || workFromAnywhere;

  const getLabel = () => {
    if (workFromAnywhere && workFromLocation && !!selectedLocation) {
      return `${WORK_FROM_ANYWHERE_LABEL} & ${selectedLocation.location}`;
    }

    if (workFromLocation && !!selectedLocation) {
      return `${selectedLocation.location}`;
    }

    if (workFromAnywhere) {
      return `${WORK_FROM_ANYWHERE_LABEL}`;
    }

    return "Location";
  };

  const label = getLabel();

  return {
    label,
    isOpen,
    setIsOpen,
    initialLocation,
    selectedLocation,
    isActive,
  };
};

const isLocationSelected = (params: {
  city?: string;
  state?: string;
  country_code?: string;
  custom_location?: string;
  location?: string;
}) => {
  return (
    (params.city && params.state && params.country_code && params.location) ||
    (params.custom_location && params.location)
  );
};

const formId = "job-filters__location";

export const LocationFilter = () => {
  const {
    label,
    isOpen,
    setIsOpen,
    initialLocation,
    isActive,
    selectedLocation,
  } = useLocationFilter();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.LOCATION,
      filter_value: values,
    });
  };

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      startIcon={<LocationPinIcon />}
      isActive={isActive}
      label={label}
      popoverContent={
        <LocationForm
          transformInitialValues={(values) => {
            if (selectedLocation) {
              return {
                ...values,
                ...selectedLocation,
                location: selectedLocation?.custom_location
                  ? {
                      location_type: "custom",
                      custom_location: selectedLocation.custom_location,
                      location: selectedLocation.location,
                    }
                  : {
                      location_type: "google",
                      place_id: "fake_place_id",
                      location: selectedLocation.location,
                    },
              };
            }

            if (initialLocation) {
              return {
                ...values,
                location: {
                  location_type: "google",
                  place_id: "fake_place_id",
                  location: initialLocation.location,
                  place_details: initialLocation.place_details,
                },
              };
            }

            return values;
          }}
          form={formId}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Fields
                submit={submit}
                names={[
                  "work_from_anywhere",
                  "work_from_location",
                  "custom_location",
                  "location",
                  "city",
                  "state",
                  "country_code",
                ]}
                validate={{
                  location: validateLocation,
                }}
                component={LocationFilterCheckboxes}
              />
            );
          }}
        </LocationForm>
      }
    />
  );
};

const validateLocation = (_: string, allValues: Partial<Location>) => {
  if (allValues.work_from_location && !allValues.location) {
    return "Please select a country, state, or city";
  }

  return undefined;
};

type LocationFilterCheckboxesProps = {
  submit: () => void;
  work_from_anywhere: WrappedFieldProps;
  work_from_location: WrappedFieldProps;
  location: WrappedFieldProps; // just for input string purpose
  custom_location: WrappedFieldProps; // custom option like United states only, Europe etc
  city: WrappedFieldProps;
  state: WrappedFieldProps;
  country_code: WrappedFieldProps;
  session_token: WrappedFieldProps;
};

const LocationFilterCheckboxes = ({
  submit,
  work_from_anywhere,
  work_from_location,
  location, // just for input string purpose
  custom_location, // custom option like United states only, Europe etc
  city,
  state,
  country_code,
}: LocationFilterCheckboxesProps) => {
  const { disableApply, options, handleReset, handleAutocompleteChange } =
    useJobLocationFilterFieldManager({
      work_from_anywhere,
      work_from_location,
      location,
      custom_location,
      city,
      state,
      country_code,
    });

  return (
    <JobPopoverFilterButton.Content
      onReset={handleReset}
      onApply={submit}
      disableApply={disableApply}
    >
      <CheckboxField
        className="job-filter-location__checkbox"
        input={work_from_anywhere.input}
        meta={work_from_anywhere.meta}
        labelVariant={
          options.isWorkFromAnywhereSelected ? "violet" : "transparent"
        }
        label={
          <>
            <Typography
              component="h3"
              variant="label"
              size="large"
              lineHeight={1.4}
              fontWeight={500}
            >
              Work from anywhere 🌎
            </Typography>
            <Typography
              variant="label"
              component="span"
              size="small"
              fontWeight={400}
              fontSize={13}
            >
              Jobs with no location requirements
            </Typography>
          </>
        }
        icon={<CircleUncheckedIcon />}
        checkedIcon={<CircleCheckGreenIcon />}
      />
      <CheckboxField
        className="job-filter-location__checkbox"
        input={work_from_location.input}
        meta={work_from_location.meta}
        labelVariant={
          options.isWorkFromLocationSelected ? "violet" : "transparent"
        }
        label={
          <>
            <Typography
              component="h3"
              variant="label"
              size="large"
              lineHeight={1.4}
              fontWeight={500}
            >
              Jobs in my location 📌
            </Typography>
            <GoogleComboboxField
              includeFullPlaceDetails
              placeholder="Select a country, state, or city"
              label={undefined}
              id="location"
              component={AutocompleteSmallerInput}
              PaperComponent={PaperWithVioletOptions}
              noErrorOnTouch
              disableClearable
              popupIcon={<MenuArrowDownIcon style={{ color: "black" }} />}
              fullWidth
              placesServiceTypes={"regions_without_sublocality"}
              useCustomLocations
              locationIcon={false}
              input={{
                ...location.input,
                onChange: handleAutocompleteChange,
              }}
              meta={location.meta}
            />
          </>
        }
        icon={<CircleUncheckedIcon />}
        checkedIcon={<CircleCheckGreenIcon />}
      />
    </JobPopoverFilterButton.Content>
  );
};
