import React from "react";
import type { WrappedFieldProps } from "redux-form";

import type { ToggleDrawer } from "@hexocean/braintrust-ui-components";
import { HomeEmojiIcon } from "@hexocean/braintrust-ui-components/Icons";

import { RequirementCard } from "./card";

type RequirementLocationCardProps = WrappedFieldProps & {
  toggleDrawer: ToggleDrawer;
};

export const RequirementLocationCardField = ({
  toggleDrawer,
  input,
}: RequirementLocationCardProps) => {
  const locationsList = input.value;

  const detailContent =
    locationsList.length === 0
      ? "Work from anywhere"
      : locationsList
          .map((location) => location.name || location.formatted_address)
          .join(", ");

  return (
    <RequirementCard
      type="locations"
      label="Talent location"
      detail={detailContent}
      icon={<HomeEmojiIcon />}
      toggleDrawer={toggleDrawer}
    />
  );
};
