import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ComboBoxMultiple,
  PaperWithGreenOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { useGetEmployerInvoicesTalentQuery } from "@js/apps/employer/api";
import {
  FilterPopoverFooter,
  FilterPopoverFormLayoutWrapper,
} from "@js/apps/employer/components/filter-popover";
import { useInitiallyOpenFilterPopoverDropdown } from "@js/apps/employer/hooks/use-initially-open-filter-popover-dropdown";
import type { RhfForm } from "@js/rhf/types";

import { employerInvoicesTalentFormSchema } from "../schema";

import { EmployerInvoicesFiltersAutocomplete } from "./employer-invoices-filters-autocomplete";

type FormType = RhfForm<typeof employerInvoicesTalentFormSchema>;
type FormValues = FormType["Values"];
type DefaultFormValues = FormType["DefaultValues"];

type TalentFormProps = {
  freelancers: DefaultFormValues["freelancers"];
  onApply: (freelancer: FormValues["freelancers"]) => void;
  hasFilterPopoverFinishedAnimation: boolean;
};

export const TalentForm = ({
  freelancers,
  onApply,
  hasFilterPopoverFinishedAnimation,
}: TalentFormProps) => {
  const defaultValues = useMemo(() => {
    return { freelancers };
  }, [freelancers]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerInvoicesTalentFormSchema),
    defaultValues,
  });

  const { data: talentsFetchedOptions, isLoading } =
    useGetEmployerInvoicesTalentQuery({});

  const options = useMemo(() => {
    return talentsFetchedOptions ?? [];
  }, [talentsFetchedOptions]);

  const { field } = useController({
    name: "freelancers",
    control: control,
  });

  const onSubmit: SubmitHandler<FormValues> = (value) => {
    onApply(value.freelancers);
  };

  const onReset = () => {
    reset({ freelancers: [] });
  };

  const valueList = useMemo(() => {
    return options.filter((data) => {
      return field.value?.includes(data.id);
    });
  }, [field.value, options]);

  const isFilterEmpty = !field.value?.length;
  const { autocompleteTextFieldRef } = useInitiallyOpenFilterPopoverDropdown({
    isFilterEmpty,
    hasFilterPopoverFinishedAnimation,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <ComboBoxMultiple
            {...field}
            openOnFocus
            value={valueList}
            ref={null}
            onChange={(_event, values) => {
              field.onChange(
                values.map((value) => {
                  return value.id;
                }),
              );
            }}
            component={EmployerInvoicesFiltersAutocomplete}
            PaperComponent={PaperWithGreenOptions}
            initialTaxonomiesLoading={isLoading}
            loading={isLoading}
            options={options}
            getOptionLabel={(option) => {
              return option.talent_name;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.talent_name}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autocompleteTextFieldRef}
                  placeholder={isFilterEmpty ? "Select a talent" : undefined}
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Select talent",
                  }}
                />
              );
            }}
          />
        }
        formFooter={<FilterPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
