import type { ReactNode } from "react";
import { Suspense, useEffect, useMemo } from "react";
import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import axios from "axios";

import { Box, BUCProvider, Loader } from "@hexocean/braintrust-ui-components";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { AuthenticatedUserOnlyWrapper } from "@js/components/authenticated-user-only-wrapper";
import { configureRequestsLibrary } from "@js/configure";
import { gatherFrontendRoutes } from "@js/routes/gather-routes";
import { store } from "@js/store";

import {
  fetchCurrentUser,
  postFreelancerLogin,
  postUserLogin,
} from "./apps/auth/actions";
import { fetchEmployerProfile } from "./apps/employer/actions";
import { ErrorBoundary } from "./components/error-boundary";
import { SmartlookProvider } from "./components/smartlook-provider";
import { Snackbar, StyledSnackbarProvider } from "./components/snackbar";
import { SnackbarUtilsConfigurator } from "./components/snackbar/snackbar-utils-configurator";
import StripeProvider from "./components/stripe-provider";
import { WebSocketManagerContainer } from "./components/websocket";
import { useAppDispatch, useAppSelector } from "./hooks";
import browserRouter, { routes } from "./routes";

global.gatherFrontendRoutes = gatherFrontendRoutes(routes((e) => e));

import type { Timeout } from "react-number-format/types/types";

import { useAccountType, useFreelancerId } from "./apps/common/hooks";
import { openBoostModal } from "./apps/jobs/components/job-list-boost-modal/job-list-boost-modal";
import { Web3NetworkProvider } from "./apps/web3/provider";
import { projectTheme } from "./theme";

import "@hexocean/braintrust-ui-components/braintrust-ui-components.css";

/*
    Check also ./js/apps/common/views/app.js
*/

const App = () => {
  const router = useMemo(() => browserRouter(additionalContext), []);

  useEffect(() => {
    configureRequestsLibrary(router);
  }, [router]);
  return (
    <ErrorBoundary>
      <AppProviders store={store}>
        <RouterProvider router={router} />
      </AppProviders>
    </ErrorBoundary>
  );
};

// when not included inside router, using react-router elements (eg. Link) inside snackbar will throw error that it cannot be used outside of router scope
const additionalContext = (children: JSX.Element): JSX.Element => {
  return (
    <StyledSnackbarProvider>
      <SnackbarUtilsConfigurator />
      {children}
    </StyledSnackbarProvider>
  );
};

const AppProviders = ({ store: appStore, children }) => {
  return (
    <Provider store={appStore}>
      <BUCProvider theme={projectTheme}>
        <InitialUserFetch>
          <AuthenticatedUserOnlyWrapper
            wrapperComponent={
              <WebSocketManagerContainer children={undefined} />
            }
          >
            <StripeProvider>
              <SmartlookProvider>
                <Web3NetworkProvider>
                  <Suspense fallback={<Loader centered />}>{children}</Suspense>
                </Web3NetworkProvider>
              </SmartlookProvider>
            </StripeProvider>
          </AuthenticatedUserOnlyWrapper>
        </InitialUserFetch>
      </BUCProvider>
    </Provider>
  );
};

const useInitialUserFetch = () => {
  const [fetched, setFetched] = React.useState(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    let isCancelled = false;

    const loadFreelancerProfile = async (freelancerId: number) => {
      const freelancer = await dispatch(fetchFreelancerProfile(freelancerId));
      if (isCancelled) {
        return;
      }

      return dispatch(postFreelancerLogin(freelancer));
    };

    const fetch = async () => {
      try {
        const user = await dispatch(fetchCurrentUser());

        document.addEventListener("visibilitychange", async function () {
          if (document.visibilityState === "visible")
            await dispatch(fetchCurrentUser());
        });

        if (isCancelled) {
          return;
        }

        await dispatch(postUserLogin(user));
        if (isCancelled) {
          return;
        }

        if (user.freelancer) {
          await loadFreelancerProfile(user.freelancer);
        } else if (user.employer) {
          await dispatch(fetchEmployerProfile());
        }

        if (isCancelled) {
          return;
        }

        setFetched(true);
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.status === 401) {
          // unauthorized, expected (eg login view)
          setFetched(true);
          return;
        }
        Snackbar.error("Something went wrong, please refresh the page");
      }
    };

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [dispatch]);

  return fetched;
};

const useFetchMissingFreelancerProfile = (userHasBeenFetched: boolean) => {
  // const [initPayment, { data, isLoading }] = useLazyInitCoinbasePaymentQuery();
  const dispatch = useAppDispatch();

  const freelancerId = useFreelancerId();
  const isFreelancer = useAccountType();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );

  const isMissingFreelancerProfile = freelancerProfile === undefined;

  const shouldFetchFreelancerProfile =
    userHasBeenFetched && isFreelancer && isMissingFreelancerProfile;

  useEffect(() => {
    if (!shouldFetchFreelancerProfile || !freelancerId) return;

    dispatch(fetchFreelancerProfile(freelancerId));
  }, [shouldFetchFreelancerProfile, dispatch, freelancerId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isPurchaseModal = urlParams.get("ispurchasemodal");
    const fetchData = async () => {
      openBoostModal();
    };
    let setTimroutID: Timeout;
    if (isFreelancer?.isFreelancer && isPurchaseModal === "true") {
      setTimroutID = setTimeout(() => fetchData(), 2500);
    }
    return () => {
      clearTimeout(setTimroutID);
    };
  }, [isFreelancer?.isFreelancer]);
};

export const InitialUserFetch = ({ children }: { children: ReactNode }) => {
  const fetched = useInitialUserFetch();

  // there can be a case where user is already fetched but we are missing their freelancer profile data
  useFetchMissingFreelancerProfile(fetched);

  if (!fetched) {
    return (
      <Box width="100%" height="100%">
        <Loader centered />
      </Box>
    );
  }

  return children;
};

export default App;
