import type { MouseEvent } from "react";
import { useState } from "react";

export const useFilterPopover = () => {
  const [anchorElement, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorElement);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return {
    isOpen,
    handleClick,
    handleClose,
    anchorElement,
  };
};
