import type { NavigateOptions } from "react-router-dom";

import type { EmployerMyTalentColumnField } from "../types";

export const SET_SEARCH_PARAMS_OPTIONS = {
  state: { disableRemountIfTheSameLocation: true, noScroll: true },
} satisfies NavigateOptions;

export const SORT_BY_FIELD_VALUES = [
  "name",
  "country",
  "email",
  "expected_hours_per_week",
  "job_end_date",
  "job_owner",
  "job_start_date",
  "job_title",
  "last_invoice_amount",
  "last_invoice_date",
  "rate",
  "bill_rate",
  "rate_type_label",
  "role",
  "state",
  "status_label",
  "total_invoiced",
  "type_label",
  "ytd_invoice_amount",
] as const satisfies EmployerMyTalentColumnField[];

export const MY_TALENT_FILTER_TO_APPLY_LABEL = {
  jobs: "Job title",
  job_owners: "Job owner",
  rate_types: "Rate type",
  roles: "Role",
  status: "Status",
} as const;

export type MyTalentFilterToApply =
  keyof typeof MY_TALENT_FILTER_TO_APPLY_LABEL;
