import { useUser } from "@js/apps/common/hooks";

import { EmployerDashboardLayout } from "../../employer/views/dashboard/layout";
import { FreelancerDashboardLayout } from "../../freelancer/views/dashboard/layout";

export const FreelancerOrEmployerDashboardLayout = ({ children, ...rest }) => {
  const user = useUser();

  if (!user) {
    throw Error("User data is missing");
  }

  const DashboardLayout = user.freelancer
    ? FreelancerDashboardLayout
    : EmployerDashboardLayout;

  return <DashboardLayout {...rest}>{children}</DashboardLayout>;
};
