import { type FC } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { Emoji } from "@js/components/emoji";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";
import type { AppDispatch } from "@js/store";

export const SuccessPurchaseModal: FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("sm");

  return (
    <Box
      id="insight-success-modal"
      width={isMobile ? "90vw" : "514px"}
      maxWidth="514px"
      height="100%"
      padding={isMobile ? 2 : 4}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection={"column"}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="p" variant="label" size="large" fontWeight={500}>
          Application Insights activated
        </Typography>

        <IconButton
          variant="tertiary"
          size="small"
          aria-label="Close location mismatch modal"
          onClick={() => ModalInstance.close()}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        borderRadius={2}
        textAlign="center"
        sx={{
          background: "var(--medium-green)",
          "& img": {
            maxWidth: isMobile ? "361px" : "100%",
          },
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/${isMobile ? "job-insight.svg" : "job-insight-banner.png"}`}
          width="100%"
          height="auto"
          alt="job-insight-image"
        />
      </Box>

      <Box>
        <Typography
          component="h6"
          variant="title"
          size="small"
          fontWeight={400}
        >
          Congratulations! You just unlocked 30 days of Application Insights.
        </Typography>
        <Typography component="p" variant="paragraph" size="large">
          What's next?
        </Typography>
      </Box>

      <Box display="flex" gap={3}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "48px",
            width: "48px",
            borderRadius: "24px",
            backgroundColor: "var(--medium-green)",
            flexShrink: 0,
          }}
        >
          <Emoji emoji="📝" />
        </Box>
        <Typography component="p" variant="paragraph" size="large">
          Find your great match jobs and see personalized feedback on the job
          details.
        </Typography>
      </Box>

      <Box textAlign="end" marginTop={2}>
        <Button
          variant="positive-2"
          shape="squared"
          RouterLink={RouterLink}
          to="jobs"
          onClick={() => {
            dispatch(fetchCurrentUser());
            ModalInstance.close();
          }}
        >
          See open jobs
        </Button>
      </Box>
    </Box>
  );
};

export const openSuccessPurchaseModal = (dispatch: AppDispatch): void => {
  ModalInstance.open({
    padding: false,
    keepOnBackdropClick: true,
    children: <SuccessPurchaseModal />,
  });

  const observer = new MutationObserver(() => {
    const modal = document.querySelector("#insight-success-modal");
    if (modal) {
      const parent = modal?.parentNode?.querySelector("button");
      parent?.addEventListener("click", function () {
        ModalInstance.close();
        dispatch(fetchCurrentUser());
      });
      observer.disconnect();
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
};
