import { useState } from "react";

import { MyWorkListingPage } from "../my-work-listing-page";

import { FreelancerDashboardBidsPage } from ".";

export const FreelancerDashboardBidsListingPage = () => {
  const [showClosedApplications, setShowClosedApplications] = useState(false);

  const checkboxProps = {
    checkboxText: "Show closed applications",
    isChecked: showClosedApplications,
    setIsChecked: setShowClosedApplications,
  };

  return (
    <MyWorkListingPage pageTitle="Applications" checkboxProps={checkboxProps}>
      <FreelancerDashboardBidsPage
        showClosedApplications={showClosedApplications}
      />
    </MyWorkListingPage>
  );
};
