import React, { useEffect, useState } from "react";

import type { ButtonVariants } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { JobStatusDisplayed } from "@js/apps/employer/components";
import {
  DRAFT_STATUS,
  DraftAccordion,
  JobAccordion,
} from "@js/apps/employer/components";
import { CreateJobAiModalInstance } from "@js/apps/employer/components/create-job-ai-modal";
import {
  CreateJobModalInstance,
  openCreateJobModal,
} from "@js/apps/employer/components/create-job-modal";
import { useCheckJobsLimit } from "@js/apps/employer/hooks/check-jobs-limit";
import { createJobFromMyJobsClicked } from "@js/apps/jobs/actions";
import { viewClientInviteDashboard } from "@js/apps/onboarding/actions";
import { useAppDispatch, useUserRegistrationQueryParams } from "@js/hooks";

import { ATSInfoBar } from "../ats-info-bar";

export type LoadedSections = Record<
  JobStatusDisplayed | typeof DRAFT_STATUS,
  boolean
>;

export const jobSectionsToDisplay = [
  ENUMS.JobStatus.ACTIVE,
  ENUMS.JobStatus.OPEN,
  ENUMS.JobStatus.ON_HOLD,
  ENUMS.JobStatus.COMPLETED,
];

const loadedSectionsInitialState = [
  ...jobSectionsToDisplay,
  DRAFT_STATUS,
].reduce((accu, curr) => ({ ...accu, [curr]: false }), {}) as LoadedSections;

const EmployerDashboardJobsComponent = (): JSX.Element => {
  const [loadedSections, setLoadedSections] = useState<LoadedSections>(
    loadedSectionsInitialState,
  );

  const dispatch = useAppDispatch();
  const handleBtnClick = () => dispatch(createJobFromMyJobsClicked());

  const { isClientInvite } = useUserRegistrationQueryParams();

  useEffect(() => {
    if (isClientInvite) {
      dispatch(viewClientInviteDashboard());
    }
  }, [isClientInvite, dispatch]);

  const isLoading = !!Object.values(loadedSections).some((value) => !value);

  return (
    <Box>
      <Box
        position="relative"
        sx={{
          paddingBottom: { xs: "24px", sm: 0 },
          marginBottom: { xs: "42px", sm: 0 },
        }}
      >
        <Typography
          variant="display"
          component="h1"
          size="small"
          fontWeight={400}
          className="dib mb+ underline"
        >
          My Jobs
        </Typography>
        {isLoading ? (
          <Loader />
        ) : (
          <PostJobButton
            onClick={handleBtnClick}
            sx={{
              position: "absolute",
              top: { xs: "auto", sm: 4 },
              right: { xs: "auto", sm: 0 },
              bottom: { xs: 0, sm: "auto" },
              left: { xs: 0, sm: "auto" },
            }}
          />
        )}
      </Box>

      {!isLoading && <ATSInfoBar />}
      <JobAccordion
        isLoadingOther={isLoading}
        setLoadedSections={setLoadedSections}
        status={ENUMS.JobStatus.OPEN}
        headerText="Open jobs"
        name="open-jobs"
      />
      <JobAccordion
        isLoadingOther={isLoading}
        setLoadedSections={setLoadedSections}
        status={ENUMS.JobStatus.ACTIVE}
        headerText="Active Jobs"
        name="active-jobs"
        showEmpty
      />
      <JobAccordion
        isLoadingOther={isLoading}
        setLoadedSections={setLoadedSections}
        status={ENUMS.JobStatus.ON_HOLD}
        name="paused-jobs"
        headerText="Paused jobs"
      />
      <JobAccordion
        isLoadingOther={isLoading}
        setLoadedSections={setLoadedSections}
        status={ENUMS.JobStatus.COMPLETED}
        name="completed-jobs"
        headerText="Completed jobs"
      />
      <DraftAccordion
        isLoadingOther={isLoading}
        setLoadedSections={setLoadedSections}
        name="draft-jobs"
        headerText="Job drafts"
      />
    </Box>
  );
};

type PostJobButtonProps = {
  onClick?: () => void;
  style?: React.CSSProperties;
  variant?: ButtonVariants;
  sx?: any;
};

export const PostJobButton = ({
  onClick,
  variant = "positive",
  ...props
}: PostJobButtonProps) => {
  const { checkJobsLimit } = useCheckJobsLimit();

  const handleBtnClick = () => {
    onClick?.();
    checkJobsLimit({ onLimitNotExceeded: openCreateJobModal });
  };

  return (
    <>
      <Button
        variant={variant}
        shape="squared"
        onClick={handleBtnClick}
        {...props}
      >
        Create a job
      </Button>
      <CreateJobModalInstance />
      <CreateJobAiModalInstance />
    </>
  );
};

export { EmployerDashboardJobsComponent };
