import { useCallback } from "react";

import {
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { FRONTEND_STORAGE_KEYS } from "@js/apps/common/frontend-storage/constants";
import type { EmployerInvoice } from "@js/types/invoices";

type UseEmployerInvoicesDiscoverSelectionArg = {
  invoices: EmployerInvoice[];
  setSelectedInvoicesIds: (invoicesIds: number[]) => void;
};

export const useEmployerInvoicesDiscoverSelection = ({
  invoices,
  setSelectedInvoicesIds,
}: UseEmployerInvoicesDiscoverSelectionArg) => {
  const invoiceIdToSelect = invoices[0]?.id;
  const {
    data: isEmployerInvoicesSelectionIntroduced,
    isError: isErrorOnGet,
    isLoading,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.EMPLOYER_INVOICES_SELECTION_INTRODUCED_AT },
    { skip: !invoiceIdToSelect },
  );
  const [setStorageValue, { isError: isErrorOnSet }] =
    useSetStorageValueMutation();

  const isError = isErrorOnGet || isErrorOnSet;

  const showTooltip =
    !isEmployerInvoicesSelectionIntroduced &&
    !isError &&
    !isLoading &&
    !!invoiceIdToSelect;

  const selectionIntroductionInvoiceId = showTooltip
    ? invoiceIdToSelect
    : undefined;

  const onInvoicesSelectionIntroduced = useCallback(() => {
    if (!showTooltip) {
      return;
    }

    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.EMPLOYER_INVOICES_SELECTION_INTRODUCED_AT,
      value: new Date().toISOString(),
    });
  }, [setStorageValue, showTooltip]);

  const handleTryOutSelection = useCallback(() => {
    onInvoicesSelectionIntroduced();

    if (!selectionIntroductionInvoiceId) {
      return;
    }

    setSelectedInvoicesIds([selectionIntroductionInvoiceId]);
  }, [
    selectionIntroductionInvoiceId,
    onInvoicesSelectionIntroduced,
    setSelectedInvoicesIds,
  ]);

  const handleDismissSelection = useCallback(() => {
    onInvoicesSelectionIntroduced();
  }, [onInvoicesSelectionIntroduced]);

  return {
    selectionIntroductionInvoiceId,
    handleDismissSelection,
    handleTryOutSelection,
  };
};
