import cx from "classnames";

import {
  Box,
  ButtonCore,
  Typography,
} from "@hexocean/braintrust-ui-components";

import type { CreateJobOption } from "..";

type CreateJobMenuItemProps = {
  icon: string;
  id: string;
  onClick: () => void;
  subText?: string;
  title: string;
};

const CreateJobMenuItem = ({
  icon,
  id,
  onClick,
  subText,
  title,
}: CreateJobMenuItemProps) => {
  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", sm: "185px" },
        padding: { xs: "24px 32px", sm: "32px" },
      }}
      className={cx("create-job-item", "button-variant--white")}
      component={ButtonCore}
      onClick={onClick}
    >
      <Box className={`job-item-icon job-item-icon--${id}`}>
        <img src={icon} alt="job type" />
      </Box>
      <Typography
        variant="label"
        size="medium"
        component="span"
        fontWeight={500}
        textAlign={"left"}
      >
        {title}
      </Typography>
      <Typography
        variant="label"
        size="small"
        component="span"
        fontWeight={400}
        textAlign={"left"}
        sx={{ color: "#767676" }}
      >
        {subText}
      </Typography>
    </Box>
  );
};

type CreateJobMenuProps = { createJobOptions: CreateJobOption[] };

export const CreateJobMenu = ({ createJobOptions }: CreateJobMenuProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 4,
          marginTop: 5,
          justifyContent: "center",
        }}
      >
        {createJobOptions
          .filter((e) => !e.disabled)
          .map((option) => (
            <CreateJobMenuItem key={option.id} {...option} />
          ))}
      </Box>
    </>
  );
};
