import React from "react";

import {
  Button,
  Loader,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { RouterLink } from "@js/components/link";
import { getCurrentPathEncoded } from "@js/utils";

import {
  FreelancerInvoiceListEmptyState,
  FreelancerInvoicesListingTable,
  Hero,
} from "../../components";
import { InvoicePreviewModalInstance } from "../../components/invoice-preview";
import { useFreelancerInvoiceListing } from "../../hooks";

export const FreelancerInvoicesListingPageContent = ({
  onlyMobileMenu = false,
}) => {
  const {
    filters,
    invoiceList,
    invoicesSummary,
    total,
    loading,
    isAnyFilterApplied,
    canFreelancerCopyLatestInvoice,
    copyFreelancerLatestInvoiceId,
    page,
    cancelFreelancerInvoice,
    changeFilters,
    fetchInvoices,
  } = useFreelancerInvoiceListing();
  const user = useUser();

  const showCreateButton =
    !!user?.freelancer_approved || !!user?.freelancer_has_grant_job_type_bids;

  const isEmpty = !isAnyFilterApplied && !invoiceList.length && !loading;

  if (loading) {
    return <Loader className="center" />;
  }

  if (isEmpty) {
    return <FreelancerInvoiceListEmptyState />;
  }

  return (
    !isEmpty && (
      <>
        <Hero
          showCreateButton={showCreateButton}
          additionalActions={
            <Button
              variant="secondary"
              href={SETTINGS.SUPPORT_URLS.TALENT_FAQ_ARTICLE_URL}
              className="mt+"
              RouterLink={RouterLink}
            >
              Discover How It Works
            </Button>
          }
          due={invoicesSummary.due_within_30_days}
          overdue={invoicesSummary.overdue}
          canFreelancerCopyLatestInvoice={canFreelancerCopyLatestInvoice}
          copyFreelancerLatestInvoiceId={copyFreelancerLatestInvoiceId}
        />
        <FreelancerInvoicesListingTable
          prevPath={getCurrentPathEncoded()}
          filters={filters}
          onlyMobileMenu={onlyMobileMenu}
          onSubmit={changeFilters}
          onInvoicesPaid={fetchInvoices}
          cancelFreelancerInvoice={cancelFreelancerInvoice}
          invoiceList={invoiceList}
        />
        <PaginationWrapper mt={0}>
          <Pagination
            page={page}
            count={total}
            perPage={SETTINGS.INVOICE_LISTING_INVOICES_PER_PAGE}
            RouterLink={RouterLink}
          />
        </PaginationWrapper>
        <InvoicePreviewModalInstance />
      </>
    )
  );
};
