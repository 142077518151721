import type { ReactNode } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

import { EMPLOYER_INVOICES_SELECTION_LIMIT } from "../../constants";
import { EmployerInvoicesTableCellTooltip } from "../employer-invoices-table-cell-tooltip";

type CheckboxCellSelectionLimitWrapperProps = {
  children: ReactNode;
};

export const CheckboxCellSelectionLimitWrapper = ({
  children,
}: CheckboxCellSelectionLimitWrapperProps) => {
  return (
    <EmployerInvoicesTableCellTooltip
      title={
        <Typography component="span" variant="paragraph" size="medium">
          Maximum invoices selected. You can only select up to{" "}
          {EMPLOYER_INVOICES_SELECTION_LIMIT} invoices.
        </Typography>
      }
      placement="top"
      maxWidth={271}
    >
      <Box>{children}</Box>
    </EmployerInvoicesTableCellTooltip>
  );
};
