import type {
  UseQuery,
  UseQueryStateOptions,
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import type {
  QueryDefinition,
  SubscriptionOptions,
} from "@reduxjs/toolkit/query";

import type { AppDispatch } from "@js/store";

export const mapTypedDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch,
});

export const getUseQueryHookWithDefaultOptions =
  <
    D extends QueryDefinition<any, any, any, any> = QueryDefinition<
      any,
      any,
      any,
      any
    >,
  >(
    useQueryHook: UseQuery<D>,
    defaultOptions: DistributiveOmit<
      SubscriptionOptions & UseQueryStateOptions<D, ReturnType<UseQuery<D>>>,
      "selectFromResult" | "skip"
    > & { refetchOnMountOrArgChange?: number | boolean },
  ): UseQuery<D> =>
  (arg, options) => {
    return useQueryHook(arg, { ...defaultOptions, ...options });
  };
