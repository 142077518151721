import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import cs from "classnames";

import { Button, Grid, Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import { Form } from "@js/forms/components/form";
import { PasswordField, TextField } from "@js/forms/fields";
import { useAppSelector } from "@js/hooks";

import { Section, SectionSubtitle } from "../helpers";

const PASSWORD_FORM_ID = "password-form";

const selector = formValueSelector(PASSWORD_FORM_ID);

const PasswordForm = ({ submit, error, submitting }) => {
  const isPasswordSet = useUser()?.is_password_set;
  const currentPassword = useAppSelector((state) =>
    selector(state, "current_password"),
  );
  const newPassword = useAppSelector((state) => selector(state, "password1"));
  const confirmPassword = useAppSelector((state) =>
    selector(state, "password2"),
  );
  const disabled = isPasswordSet && !currentPassword;

  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Section title="Password">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SectionSubtitle>
              {isPasswordSet ? "Change password" : "Set password"}
            </SectionSubtitle>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p">
              Changing your password will log you out of all your active
              Braintrust sessions.
            </Typography>
          </Grid>
          {isPasswordSet && (
            <Grid item sm={6} xs={12} style={{ marginRight: "50%" }}>
              <Field
                id="current_password"
                name="current_password"
                type="password"
                component={TextField}
                label="Current password"
              />
            </Grid>
          )}
          <Grid
            item
            sm={6}
            xs={12}
            className={cs({ "section-disabled": disabled })}
          >
            <Field
              id="password1"
              name="password1"
              disabled={disabled}
              component={PasswordField}
              label="New password"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={cs({ "section-disabled": disabled })}
          >
            <Field
              id="password2"
              name="password2"
              disabled={disabled}
              component={PasswordField}
              label="Confirm password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="primary"
              disabled={
                submitting || !newPassword || !confirmPassword || disabled
              }
              onClick={submit}
            >
              Set New Password
            </Button>
          </Grid>
        </Grid>
      </Section>
    </Form>
  );
};

export default reduxForm({
  form: PASSWORD_FORM_ID,
  enableReinitialize: true,
  onSubmit: (
    values: {
      current_password: string;
      password1: string;
      password2: string;
    },
    _dispatch,
    props,
  ) => {
    props.onSubmit(values);
  },
})(PasswordForm);
