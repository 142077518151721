import type { ReactNode } from "react";
import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Loader,
  Tab,
  Tabs,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";
import type { DashboardNavItem } from "@js/layouts/app/types";
import { findCurrentNavItem } from "@js/layouts/app/utils/common-navigation";
import { FREELANCER_DASHBOARD_NAV_ITEMS } from "@js/layouts/app/utils/freelancer-navigation";

type FreelancerDashboardProps = {
  children: React.ReactNode;
  contentClassName?: string;
  pageTitle?: string;
  pageColor?: string;
};

const getActiveTab = (
  pathname: string,
  linkItem: DashboardNavItem | undefined,
) => {
  if (!linkItem || !linkItem.subLinks || linkItem.subLinks.length === 0) {
    return "";
  }

  const currentTab = linkItem.subLinks.find(({ path }) =>
    pathname.includes(path),
  );
  return currentTab ? currentTab.path : "";
};

export const FreelancerDashboardLayout = ({
  contentClassName,
  children,
  pageTitle,
  pageColor,
}: FreelancerDashboardProps) => {
  const linkItem = findCurrentNavItem({
    items: FREELANCER_DASHBOARD_NAV_ITEMS,
  });

  return (
    <AppLayout
      bgcolor={pageColor}
      flexColumn
      pageTitle={pageTitle}
      className={contentClassName}
    >
      {linkItem ? (
        <SubNavigation linkItem={linkItem}>{children}</SubNavigation>
      ) : (
        children
      )}
    </AppLayout>
  );
};

type SubNavigationProps = {
  children: ReactNode;
  linkItem: DashboardNavItem;
};

const SubNavigation = ({ children, linkItem }: SubNavigationProps) => {
  const { pathname } = useLocation();
  const activeTab = getActiveTab(pathname, linkItem);

  return (
    <>
      {linkItem.subLinks && linkItem.subLinks.length > 0 && (
        <Box component="nav" width={1} mb={3}>
          <Tabs
            value={activeTab}
            normalizePath
            variant="scrollable"
            scrollButtons={false}
            sx={{
              "& .MuiTabs-flexContainer": {
                gap: 2,
              },
            }}
          >
            {linkItem.subLinks.map(({ path, label }) => (
              <Tab
                key={path}
                value={path}
                sx={{
                  py: 0,
                }}
                component={RouterLink}
                to={path}
                label={
                  <Typography component="span" variant="label" size="medium">
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </Box>
      )}
      {!!linkItem.canAccess && (
        <Suspense fallback={<Loader centered />}>{children}</Suspense>
      )}
    </>
  );
};
