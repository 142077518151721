import React from "react";
import { useLocation } from "react-router-dom";

import { Box, UnderlinedTabs } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";
import { findCurrentNavItem } from "@js/layouts/app/utils/common-navigation";
import { FREELANCER_DASHBOARD_NAV_ITEMS } from "@js/layouts/app/utils/freelancer-navigation";

type FreelancerDashboardProps = {
  children: React.ReactNode;
  contentClassName?: string;
  pageTitle?: string;
  pageColor?: string;
};

export const FreelancerDashboardLayout = ({
  contentClassName,
  children,
  pageTitle,
  pageColor,
}: FreelancerDashboardProps) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("sm");
  const linkItem = findCurrentNavItem({
    items: FREELANCER_DASHBOARD_NAV_ITEMS,
  });

  return (
    <AppLayout
      bgcolor={pageColor}
      flexColumn
      pageTitle={pageTitle}
      className={contentClassName}
    >
      {!!linkItem ? (
        <>
          {linkItem.subLinks && linkItem.subLinks.length > 0 && (
            <Box component="nav" width={1} mb={3}>
              <UnderlinedTabs
                padding="0"
                margin="0"
                resetLeftPadding
                items={linkItem.subLinks}
                pathname={pathname}
                RouterLink={RouterLink}
                swipeable={isMobile}
              />
            </Box>
          )}
          {linkItem.canAccess ? children : null}
        </>
      ) : (
        children || null
      )}
    </AppLayout>
  );
};
