import React, { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";

import {
  Box,
  Loader,
  PaperWithVioletOptions,
  StyleAutocompleteMediumVioletChips,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  LocationPinIcon,
  MenuArrowDownIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { filterApplied } from "@js/apps/common/actions";
import type { TalentLocation } from "@js/apps/common/components/filters";
import { TalentLocationForm } from "@js/apps/common/components/filters";
import {
  useTalentLocationFilterFieldManager,
  type UseTalentLocationFilterFieldManagerArg,
} from "@js/apps/common/hooks/use-talent-location-filter-field-manager";
import { useTalentLocationFilter } from "@js/apps/freelancer/forms/fields";
import { RecommendedLocationButtonSelect } from "@js/apps/freelancer/forms/fields/recommended-location-button-select";
import type {
  LocationSelectReason,
  TalentMixedLocation,
} from "@js/apps/freelancer/types";
import type { BidRecommendedLocation } from "@js/apps/jobs/apps/bids/api";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import { GooglePlacesMultipleField } from "@js/forms/fields";
import { isError } from "@js/forms/utils";
import { useAppDispatch } from "@js/hooks";
import { PossibleFilters } from "@js/types/common";

import styles from "./styles.module.scss";

const formId = "bid-talent-filters__location";

export const BidsTalentLocationFilter = ({ jobId }: { jobId: number }) => {
  const { label, isOpen, setIsOpen, initialLocation, isActive } =
    useTalentLocationFilter();

  const dispatch = useAppDispatch();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    dispatch(
      filterApplied({
        filter_type: PossibleFilters.LOCATION,
        filter_value: values,
      }),
    );
  };

  const handleSubmitSuccess = () => {
    setIsOpen(false);
  };

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      startIcon={<LocationPinIcon />}
      isActive={isActive}
      label={label}
      anchorClassName={styles.bidTalentLocationFilterAnchor}
      popoverContent={
        <TalentLocationForm
          transformInitialValues={(values) => {
            return {
              ...values,
              location: initialLocation,

              place_id: values.place_id ? values.place_id.split(",") : [],
              custom_location: values.custom_location
                ? values.custom_location.split(",")
                : [],
              session_token: values.session_token
                ? values.session_token.split(",")
                : [],
            } as unknown as TalentLocation;
          }}
          form={formId}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Fields
                submit={submit}
                names={[
                  "custom_location",
                  "location",
                  "place_id",
                  "session_token",
                  "include_verified_locations_only",
                ]}
                jobId={jobId}
                component={TalentLocationFilterField}
              />
            );
          }}
        </TalentLocationForm>
      }
    />
  );
};

type TalentLocationFilterFieldProps = {
  submit: () => void;
  jobId: number;
} & Omit<
  UseTalentLocationFilterFieldManagerArg,
  "include_verified_locations_only"
> & {
    include_verified_locations_only: NonNullable<
      UseTalentLocationFilterFieldManagerArg["include_verified_locations_only"]
    >;
  };

const TalentLocationFilterField = ({
  submit,
  location, // just for input string purpose
  custom_location, // custom option like United states only, Europe etc
  place_id,
  session_token,
  include_verified_locations_only,
  jobId,
}: TalentLocationFilterFieldProps) => {
  const {
    handleReset,
    handleLocationSelection,
    recommendedLocations,
    recommendedByJobRequired,
    isRecommendedLocationsLoading,
  } = useTalentLocationFilterFieldManager({
    location,
    custom_location,
    place_id,
    session_token,
    include_verified_locations_only,
    jobId,
  });

  const handleChange = (
    data: TalentMixedLocation[],
    reason: LocationSelectReason,
  ) => {
    handleLocationSelection(data, reason, false);
  };

  const error = isError([
    location,
    // no error in others
  ]);

  const recommendedLocationsSectionLabel = recommendedByJobRequired
    ? "Required locations"
    : "Talent locations";

  return (
    <JobPopoverFilterButton.Content
      onReset={handleReset}
      onApply={submit}
      disableApply={error}
    >
      <Box p={1}>
        <Typography
          component="h3"
          mb={1}
          variant="label"
          size="large"
          lineHeight={1.4}
          fontWeight={500}
        >
          Select Talent location 📌
        </Typography>

        <GooglePlacesMultipleField<true>
          data-testid="multiple-location-field"
          id="location"
          component={StyleAutocompleteMediumVioletChips}
          PaperComponent={PaperWithVioletOptions}
          noErrorOnTouch
          disableClearable
          label={undefined}
          locationIcon={false}
          useCustomLocations
          placesServiceTypes={"regions_without_sublocality"}
          placeholder={
            !!location.input.value?.length
              ? ""
              : "Select a country, state, or city"
          }
          popupIcon={<MenuArrowDownIcon />}
          fullWidth
          input={{
            ...location.input,
            onChange: (value, reason) =>
              handleChange(
                value as TalentMixedLocation[],
                reason as LocationSelectReason,
              ),
          }}
          meta={location.meta}
        />
        <PopularLocationsButtonSelect
          input={{
            ...location.input,
            onChange: handleChange,
          }}
          meta={location.meta}
          label={recommendedLocationsSectionLabel}
          options={recommendedLocations}
          isLoading={isRecommendedLocationsLoading}
        />
      </Box>
    </JobPopoverFilterButton.Content>
  );
};

export type PopularLocationButtonSelectProps = {
  input: Omit<TypedWrappedFieldProps["input"], "onChange"> & {
    onChange: (
      data: TalentMixedLocation[],
      option?: LocationSelectReason,
    ) => void;
  };
  meta: TypedWrappedFieldProps["meta"];
  options: BidRecommendedLocation[];
  label: string;
  isLoading?: boolean;
};

const PopularLocationsButtonSelect = ({
  input,
  meta,
  options,
  label,
  isLoading,
}: PopularLocationButtonSelectProps) => {
  const optionsToDisplay = useMemo(
    () =>
      options
        .filter((option) => option.name || option.custom_location)
        .map((option) => {
          return {
            label: `${option.name} ${option.bids_count ? `(${option.bids_count})` : ""}`,
            value: option.custom_location
              ? option.custom_location
              : (option.place_id ?? null),
          };
        }),
    [options],
  );

  if (isLoading) return <Loader />;

  if (!options.length) return null;

  return (
    <RecommendedLocationButtonSelect
      input={input}
      meta={meta}
      options={optionsToDisplay}
      label={label}
    />
  );
};
