import React from "react";

import { AppLayoutSidebarNav } from "@js/layouts/app/sidebar-nav";

import { useAccountType } from "../../hooks";
import { useEmployerSidebar } from "../../hooks/use-employer-sidebar";
import { useFreelancerSidebar } from "../../hooks/use-freelancer-sidebar";

export const UserSidebar = (): JSX.Element => {
  const { isEmployer } = useAccountType();

  return isEmployer ? <EmployerSidebar /> : <FreelancerSidebar />;
};

const FreelancerSidebar = React.memo(() => {
  const { sidebarItems, itemVariant } = useFreelancerSidebar();

  return <AppLayoutSidebarNav itemVariant={itemVariant} items={sidebarItems} />;
});

const EmployerSidebar = React.memo(() => {
  const { sidebarItems, itemVariant } = useEmployerSidebar();

  return <AppLayoutSidebarNav itemVariant={itemVariant} items={sidebarItems} />;
});
