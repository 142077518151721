import { Typography } from "@hexocean/braintrust-ui-components";
import { type GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import { RouterLink } from "@js/components/link";
import type { EmployerInvoice } from "@js/types/invoices";

type JobTitleCellProps = GridRenderCellParams<
  EmployerInvoice,
  EmployerInvoice["subject_title"]
>;

export const JobTitleCell = ({ value, row }: JobTitleCellProps) => {
  return (
    <Typography
      component="link"
      RouterLink={RouterLink}
      variant="paragraph"
      to={`/jobs/${row.subject.id}/`}
      size="medium"
      ellipsis
      title={value}
      className="underline-on-hover"
      onClick={(ev) => ev.stopPropagation()}
    >
      {value}
    </Typography>
  );
};
