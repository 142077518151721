import { z } from "zod";

import { zodCommaNumberArray, zodCommaStringToArray } from "@js/rhf/schemas";
import type { RhfForm } from "@js/rhf/types";

import { SORT_BY_FIELD_VALUES } from "../constants";

export const employerMyTalentSearchFormSchema = z.object({
  search: z.string().nullish(),
});

export const employerMyTalentStatusFormSchema = z.object({
  status: zodCommaStringToArray
    .pipe(z.array(z.nativeEnum(ENUMS.IsActiveStatus)))
    .optional(),
});

export const employerMyTalentJobFormSchema = z.object({
  jobs: zodCommaNumberArray,
});

export const employerMyTalentJobOwnerFormSchema = z.object({
  job_owners: zodCommaNumberArray,
});

export const employerMyTalentRateTypeFormSchema = z.object({
  rate_types: zodCommaStringToArray.pipe(
    z.array(z.nativeEnum(ENUMS.JobPaymentType)).optional(),
  ),
});

export const employerMyTalentRoleFormSchema = z.object({
  roles: zodCommaNumberArray,
});

export const employerMyTalentSortFormSchema = z.object({
  order_by: z.enum(SORT_BY_FIELD_VALUES).optional(),
  order_dir: z.enum(["asc", "desc"]).optional(),
});

const employerMyTalentPageSchema = z.object({
  page: z.coerce.number().int().positive().optional(),
});

export const employerMyTalentFormSchema = z
  .object({})
  .merge(employerMyTalentSearchFormSchema)
  .merge(employerMyTalentStatusFormSchema)
  .merge(employerMyTalentJobFormSchema)
  .merge(employerMyTalentJobOwnerFormSchema)
  .merge(employerMyTalentRateTypeFormSchema)
  .merge(employerMyTalentRoleFormSchema)
  .merge(employerMyTalentSortFormSchema)
  .merge(employerMyTalentPageSchema);

export type EmployerMyTalentForm = RhfForm<typeof employerMyTalentFormSchema>;

export type EmployerMyTalentFormValues = EmployerMyTalentForm["Values"];

export type EmployerMyTalentFormValuesWithDefaults =
  EmployerMyTalentForm["DefaultValues"];
