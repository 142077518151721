import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { buildSearchParams } from "@js/utils/url";

type UseSetInitialQueryPramsArg = {
  initialQueryParams: Record<string, unknown>;
};

export const useSetInitialQueryParams = ({
  initialQueryParams,
}: UseSetInitialQueryPramsArg) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInitialized, setIsInitialized] = useState(() =>
    Boolean(Object.keys(Object.fromEntries(searchParams.entries())).length),
  );

  useEffectOnce(() => {
    if (isInitialized) {
      return;
    }

    const initialQueryParamsObject = buildSearchParams(initialQueryParams, {
      skipCurrentParams: true,
    });
    setSearchParams(initialQueryParamsObject, {
      state: { disableRemountIfTheSameLocation: true, noScroll: true },
    });

    setIsInitialized(true);
  });

  return { isInitialized };
};
