import { useMemo } from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { fetchEmployerProfile } from "@js/apps/employer/actions";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useFilteredBidList } from "../use-filtered-bid-list";

export const useJobBids = (jobId: number) => {
  const dispatch = useAppDispatch();
  const {
    bidList,
    isBidListLoading,
    refetchBidList,
    bidFilters,
    areFiltersDefault,
    total,
    isLastPage,
    resetFilters,
  } = useFilteredBidList({ jobId });
  const {
    data: job,
    refetch: refetchJob,
    isLoading: isJobLoading,
  } = useGetJobQuery({ id: Number(jobId) });
  const fetchingEmployerProfile = useAppSelector(
    (state) => state.employer.fetchingEmployerProfile,
  );
  const { isEmployer } = useAccountType();

  const interviewingBidList = useMemo(
    () =>
      bidList?.filter(
        (bid) =>
          bid.status !== ENUMS.BidStatus.NEW &&
          bid.status !== ENUMS.BidStatus.VIEWED &&
          bid.status !== ENUMS.BidStatus.REJECTED,
      ) || [],
    [bidList],
  );

  useEffectOnce(() => {
    // fetch employer profile to check if deposit payment failed
    if (!fetchingEmployerProfile && isEmployer) {
      dispatch(fetchEmployerProfile());
    }
  });

  return {
    loading: !job || isJobLoading,
    jobBidsLoading: isBidListLoading,
    job,
    bidList,
    totalBidsCount: total || 0,
    interviewingBidList,
    refetchBidList,
    refetchJob,
    areFiltersDefault,
    isShowingHiddenBids: !!bidFilters?.is_hidden,
    isLastPage,
    resetFilters,
  };
};
