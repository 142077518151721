import { FlagIcon } from "@hexocean/braintrust-ui-components/Icons";

import { FilterButtonPopover } from "./filter-button-popover";
import { EmployerMyTalentStatusForm } from "./forms";
import { useFilterPopover } from "./hooks";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentStatusFilterPopoverProps = {
  status: EmployerMyTalentFormValues["status"];
  onChange: (status: EmployerMyTalentFormValues["status"]) => void;
};

export const MyTalentStatusFilterPopover = ({
  status,
  onChange,
}: MyTalentStatusFilterPopoverProps) => {
  const isAnyStatusApplied = Boolean(status && status.length > 0);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const handleApply = (newStatus: EmployerMyTalentFormValues["status"]) => {
    onChange(newStatus);
    handleClose();
  };

  return (
    <FilterButtonPopover
      label="Status"
      icon={<FlagIcon />}
      isFilterApplied={isAnyStatusApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handleClose}
      anchorElement={anchorElement}
    >
      <EmployerMyTalentStatusForm status={status} onApply={handleApply} />
    </FilterButtonPopover>
  );
};
