import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import { mapLocationsToFrontendFormat } from "@js/apps/jobs/apps/create-job/utils/locations-mapping";
import { mapTimezonesToFrontendFormat } from "@js/apps/jobs/apps/create-job/utils/timezones-mapping";
import type { Job, JobDraft, TimezoneOption } from "@js/types/jobs";

type JobTypeMap<T> = Record<EnumType<typeof ENUMS.JobType>, T>;

const EXPECTED_HOURS_PER_WEEK: JobTypeMap<number | undefined> = {
  [ENUMS.JobType.DIRECT_HIRE]: 40,
  [ENUMS.JobType.FREELANCE]: undefined,
  [ENUMS.JobType.GRANT]: 15,
} as const;

const CONTRACT_TYPE: JobTypeMap<
  EnumType<typeof ENUMS.JobContractType> | undefined
> = {
  [ENUMS.JobType.DIRECT_HIRE]: ENUMS.JobContractType.LONG,
  [ENUMS.JobType.FREELANCE]: undefined,
  [ENUMS.JobType.GRANT]: ENUMS.JobContractType.SHORT,
} as const;

const PAYMENT_TYPE: JobTypeMap<EnumType<typeof ENUMS.JobPaymentType>> = {
  [ENUMS.JobType.DIRECT_HIRE]: ENUMS.JobPaymentType.ANNUAL,
  [ENUMS.JobType.FREELANCE]: ENUMS.JobPaymentType.HOURLY,
  [ENUMS.JobType.GRANT]: ENUMS.JobPaymentType.FIXED_PRICE,
} as const;

const DEFAULT_EXPECTED_HOURS_PER_WEEK = 20;
export const getInitialExpectedHoursPerWeekValue = (
  jobType: EnumType<typeof ENUMS.JobType> | undefined,
): number => {
  if (!jobType) {
    return DEFAULT_EXPECTED_HOURS_PER_WEEK;
  }

  return EXPECTED_HOURS_PER_WEEK[jobType] ?? DEFAULT_EXPECTED_HOURS_PER_WEEK;
};

export const getInitialContractTypeValue = (
  jobType: EnumType<typeof ENUMS.JobType> | undefined,
) => {
  if (!jobType) {
    return undefined;
  }

  return CONTRACT_TYPE[jobType];
};

export const getInitialPaymentTypeValue = (
  jobType: EnumType<typeof ENUMS.JobType> | undefined,
) => {
  if (!jobType) {
    return ENUMS.JobPaymentType.HOURLY;
  }

  return PAYMENT_TYPE[jobType];
};

export const extractJobLocationAndTimezoneInitialValues = (
  job:
    | Partial<Pick<JobDraft, "timezones" | "locations">>
    | Partial<Pick<Job, "timezones" | "locations">>,
) => {
  const locationAndTimezone: {
    locations: TalentMixedLocation[];
    timezones: TimezoneOption[];
  } = {
    locations: [],
    timezones: [],
  };

  if (job?.locations?.length) {
    locationAndTimezone.locations = mapLocationsToFrontendFormat(job.locations);
  }

  if (job.timezones?.length) {
    locationAndTimezone.timezones = mapTimezonesToFrontendFormat(job.timezones);
  }

  return locationAndTimezone;
};
