import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getPostPath } from "@js/apps/give-and-get-help/utils";
import type { IPost } from "@js/types/give-and-get-help";
import { normalizePath } from "@js/utils";

export const useRedirectToSlugBasedPost = (post: IPost | undefined) => {
  const navigate = useNavigate();
  const { pathname: currentPathname, search, hash } = useLocation();

  useEffect(() => {
    if (!post) {
      return;
    }

    const postPath = getPostPath(post);
    const isOnPostPath =
      normalizePath(postPath) === normalizePath(currentPathname);
    if (isOnPostPath) {
      return;
    }

    const postPathWithSearchAndHash = postPath + search + hash;
    navigate(postPathWithSearchAndHash, { replace: true });
  }, [navigate, post, currentPathname, search, hash]);
};
