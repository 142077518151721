import { useMemo } from "react";
import { SubmissionError } from "redux-form";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import { UPDATE_EMPLOYER_PROFILE_SUCCESS } from "@js/apps/employer/action-types";
import {
  useGetEmployerInfoQuery,
  useUpdateEmployerInfoMutation,
} from "@js/apps/employer/api";
import { useAppDispatch } from "@js/hooks";
import type { Employer } from "@js/types/employer";

import type { OnboardingOrganizationFields } from "../../components/organization/schema";
import {
  ORGANIZATION_NAME_USED_ERROR,
  ORGANIZATION_NAME_USED_ERROR_MESSAGE,
} from "../../constants";
import { prepareOrganizationInfoValues } from "../../utils";

import {
  clearOrganizationFormLocalStorage,
  getOrganizationFormInitialValues,
  getOrganizationFormLocalChanges,
  saveOrganizationFormChangesLocally,
} from "./utils";

export const useOrganizationOnboardingForm = () => {
  const user = useUser();
  const dispatch = useAppDispatch();

  const { data, isLoading: isLoadingEmployerData } = useGetEmployerInfoQuery(
    { employerId: user?.employer as number },
    { skip: user === null || !user?.employer },
  );

  const [updateEmployerInfo, { isLoading: isUpdatingEmployerData }] =
    useUpdateEmployerInfoMutation();

  const onSubmit = (values: OnboardingOrganizationFields) => {
    if (!data) return;

    const preparedValues = prepareOrganizationInfoValues(values);

    return updateEmployerInfo({
      employerId: data.id,
      newData: preparedValues,
      params: { set_account_address: true },
    })
      .unwrap()
      .then((res: Employer) => {
        dispatch({
          type: UPDATE_EMPLOYER_PROFILE_SUCCESS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch(fetchCurrentUser());
      })
      .catch((error) => {
        if (error.data.name?.includes(ORGANIZATION_NAME_USED_ERROR)) {
          throw new SubmissionError({
            name: ORGANIZATION_NAME_USED_ERROR_MESSAGE,
          });
        }
        throw new SubmissionError(error.data);
      });
  };

  const initialValues = useMemo(() => {
    const serverOrganizationFormData = getOrganizationFormInitialValues(data);

    return {
      ...serverOrganizationFormData,
      ...getOrganizationFormLocalChanges(),
    };
  }, [data]);

  return {
    isLoadingEmployerData,
    isUpdatingEmployerData,
    onSubmit,
    saveChangesLocally: saveOrganizationFormChangesLocally,
    resetForm: clearOrganizationFormLocalStorage,
    initialValues,
  };
};
