import React from "react";

import { useEmailConfirmationModal } from "@js/apps/dashboard/hooks/use-email-confirmation-modal";

import TaxFormRequiredWarning from "../withdrawal/components/tax-form-required-warning";
import WithdrawalMethodWarning from "../withdrawal/components/withdrawal-method-warning";

import { useProfileCompletionSuccess } from "./hooks/use-profile-completion-success";

export const FreelancerGlobalProvider = () => {
  useEmailConfirmationModal({
    pathToShowConfirmationModal: /^\/talent\/dashboard\/welcome\/?$/,
  });
  useProfileCompletionSuccess();

  return (
    <>
      <WithdrawalMethodWarning />
      <TaxFormRequiredWarning />
    </>
  );
};
