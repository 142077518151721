import { useCallback, useMemo } from "react";
import cs from "classnames";

import { IconButton } from "@hexocean/braintrust-ui-components";
import {
  DataGrid,
  type GridColumnHeaderSortIconProps,
  type GridColumnVisibilityModel,
  type GridSlotProps,
  type GridSortModel,
} from "@hexocean/braintrust-ui-components/components/DataGrid";
import { KeyboardArrowUpIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import type { EmployerMyTalent } from "@js/types/employer";

import type { EmployerMyTalentColumnField } from "../types";

import { getEmployerMyTalentTableColumns } from "./columns";
import { MyTalentTableEmptyState } from "./my-talent-table-empty-state";

import styles from "./my-talent-table.module.scss";

export type MyTalentTableComponentProps = {
  sortBy: EmployerMyTalentColumnField | undefined;
  sortDir: "asc" | "desc" | undefined;
  onSortChange: (arg: {
    sortBy: EmployerMyTalentColumnField | undefined;
    sortDir: "asc" | "desc" | undefined;
  }) => void;
  visibleColumns: EmployerMyTalentColumnField[];
  data: EmployerMyTalent[];
  onResetFilters: () => void;
  isLoading: boolean;
};

export const MyTalentTableComponent = ({
  sortBy,
  sortDir,
  onSortChange,
  onResetFilters,
  visibleColumns,
  data,
  isLoading,
}: MyTalentTableComponentProps) => {
  const processedColumns = useMemo(() => {
    return getEmployerMyTalentTableColumns(visibleColumns, data);
  }, [visibleColumns, data]);

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const hiddenColumnFields = processedColumns
      .filter((column) => column.isHidden)
      .map((column) => column.field);

    const visibilityModel: GridColumnVisibilityModel = {};
    for (const hiddenColumnField of hiddenColumnFields) {
      visibilityModel[hiddenColumnField] = false;
    }

    return visibilityModel;
  }, [processedColumns]);

  const sortModel = useMemo<
    [{ field: string; sort: "asc" | "desc" }] | []
  >(() => {
    if (!sortBy || !sortDir) {
      return [];
    }

    return [{ field: sortBy, sort: sortDir }];
  }, [sortBy, sortDir]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const [sortObject] = newSortModel || [];

    onSortChange({
      sortBy: sortObject?.field as EmployerMyTalentColumnField | undefined,
      sortDir: sortObject?.sort ?? undefined,
    });
  };

  const onResetFiltersRef = useEffectRef(onResetFilters);

  const renderNoRowsOverlay = useCallback(
    (props: GridSlotProps["noRowsOverlay"]) => (
      <MyTalentTableEmptyState
        {...props}
        onResetFilters={() => onResetFiltersRef.current()}
      />
    ),
    [onResetFiltersRef],
  );

  const hasNoData = !isLoading && !data.length;

  return (
    <DataGrid
      autoHeight
      columnVisibilityModel={columnVisibilityModel}
      className={cs(styles.table, { [styles.tableEmpty]: hasNoData })}
      columns={processedColumns}
      rows={data}
      disableVirtualization
      disableColumnMenu
      disableColumnResize
      disableColumnSelector
      disableMultipleRowSelection
      hideFooter
      rowSelection={false}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      rowHeight={64}
      columnHeaderHeight={68}
      loading={isLoading}
      classes={{
        overlayWrapper: hasNoData ? styles.tableNoRowsOverlay : undefined,
        overlayWrapperInner: hasNoData ? styles.tableNoRowsOverlay : undefined,
      }}
      slots={{
        columnResizeIcon: EmptyColumnResizeIcon,
        columnHeaderSortIcon: ColumnHeaderSortIcon,
        noRowsOverlay: renderNoRowsOverlay,
      }}
    />
  );
};

const EmptyColumnResizeIcon = () => {
  return <></>;
};

const ColumnHeaderSortIcon = (props: GridColumnHeaderSortIconProps) => {
  const direction = props.direction;
  const isSorted = !!direction;

  return (
    <IconButton
      variant="transparent"
      aria-label="Sort button"
      sx={{ p: "0!important", ml: "3px" }}
      size="medium"
      tabIndex={-1}
      data-sorting={String(isSorted)}
    >
      <KeyboardArrowUpIcon
        sx={{
          color: isSorted ? "var(--black)" : "var(--grey-2)",
          fontSize: "24px",
          borderRadius: "50%",
          backgroundColor: isSorted ? "var(--medium-violet)" : undefined,
          transform: direction === "desc" ? "rotate(180deg)" : undefined,
        }}
      />
    </IconButton>
  );
};
