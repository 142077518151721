import { Divider, useMediaQuery } from "@hexocean/braintrust-ui-components";
import { CategoryLabel } from "@js/apps/give-and-get-help/components/category-and-budget-utils/category-label";
import {
  BudgetLabel,
  CategoryBudgetBadgeContainer,
} from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { ActionBarOfferStatus } from "./action-bar-offer-status";

import styles from "./styles.module.scss";

type ActionBarSingleHelpOfferProps = {
  offer: HelpOffer;
  isOfferAuthor: boolean;
};

export const ActionBarSingleHelpOffer = ({
  offer,
  isOfferAuthor,
}: ActionBarSingleHelpOfferProps): JSX.Element | null => {
  const isMobile = useMediaQuery(750);

  return (
    <>
      <div className={styles.helpOffer}>
        <CategoryBudgetBadgeContainer categoryColor={offer.category.color}>
          <CategoryLabel
            category={offer.category}
            className="offer-category-wrapper"
            nameProps={{
              size: isMobile ? "small" : "medium",
              width: "100%",
              ellipsis: true,
              className: "offer-category-name",
            }}
          />

          <BudgetLabel budget={offer.budget} />
        </CategoryBudgetBadgeContainer>
        <ActionBarOfferStatus
          offer={offer}
          isOfferAuthor={isOfferAuthor}
          onMessageHelper={null} // we are at messenger, so we don't have to open it again
        />
      </div>
      <Divider color="beige" className={styles.divider} />
    </>
  );
};
