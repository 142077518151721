import { Divider, useMediaQuery } from "@hexocean/braintrust-ui-components";
import { CategoryLabel } from "@js/apps/give-and-get-help/components/category-and-budget-utils/category-label";
import {
  BudgetLabel,
  CategoryBudgetBadgeContainer,
} from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { ActionBarOfferStatus } from "./action-bar-offer-status";

type ActionBarSingleHelpOfferProps = {
  offer: HelpOffer;
  isOfferAuthor: boolean;
};

export const ActionBarSingleHelpOffer = ({
  offer,
  isOfferAuthor,
}: ActionBarSingleHelpOfferProps): JSX.Element | null => {
  const isMobile = useMediaQuery(750);

  return (
    <>
      <div className="messenger-action-bar__single-offer">
        <CategoryBudgetBadgeContainer categoryColor={offer.category.color}>
          <CategoryLabel
            category={offer.category}
            className="offer-category-wrapper"
            nameProps={{
              size: isMobile ? "small" : "medium",
              width: "100%",
              ellipsis: true,
              className: "offer-category-name",
            }}
          />

          <BudgetLabel budget={offer.budget} />
        </CategoryBudgetBadgeContainer>
        <ActionBarOfferStatus offer={offer} isOfferAuthor={isOfferAuthor} />
      </div>
      <Divider color="beige" className="messenger-action-bar__divider" />
    </>
  );
};
