import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { z } from "zod";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  StaticDateRangePicker,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { RhfForm } from "@js/rhf/types";
import { removeNullValues } from "@js/utils";

import { employerInvoicesDateRangeFormSchema } from "../employer-invoices-filters/schema";

import { ExportEmployerInvoicesModalContentWrapper } from "./export-employer-invoices-modal-content-wrapper";
import type { ExportCSVCustomDateRange } from "./helpers";

const schema = z.object({
  customRange: employerInvoicesDateRangeFormSchema.superRefine(
    (values, ctx) => {
      if (!values.date_after && !values.date_before) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["date_before"],
          message: "Either Start date or End date must be provided",
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["date_after"],
          message: "Either Start date or End date must be provided",
        });
      }
    },
  ),
});

type FormType = RhfForm<typeof schema>;
type FormValues = FormType["Values"];

export type ExportEmployerInvoicesModalCustomRangeProps = {
  customRange: ExportCSVCustomDateRange | undefined;
  onCancel: () => void;
  onEditCustomRange: (customRange: ExportCSVCustomDateRange) => void;
};

export const ExportEmployerInvoicesModalCustomRange = ({
  customRange,
  onCancel,
  onEditCustomRange,
}: ExportEmployerInvoicesModalCustomRangeProps) => {
  const defaultValues: FormValues = useMemo(() => {
    return { customRange: customRange ?? {} };
  }, [customRange]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onSubmit",
    defaultValues,
  });

  const onSubmit = (values: FormValues) => {
    const mappedCustomRange = removeNullValues(values.customRange);
    onEditCustomRange(mappedCustomRange);
  };

  const errorMessage =
    errors?.customRange?.date_before?.message ??
    errors?.customRange?.date_after?.message;

  return (
    <ExportEmployerInvoicesModalContentWrapper
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack direction="row" sx={{ mb: 2.5, gap: 0.5 }}>
        <IconButton
          variant="transparent"
          aria-label="Back"
          type="button"
          onClick={onCancel}
          size="medium"
          sx={{ "&&": { p: 0.5 } }}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography
          component="h3"
          fontWeight={400}
          variant="paragraph"
          size="large"
        >
          Select custom range
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: "min(auto, 100vw - 2rem)",
          minWidth: { sm: "500px" },
          alignItems: "center",
          borderRadius: 2,
          border: "1px solid var(--medium-beige)",
          boxShadow: !!errorMessage
            ? "0 0 0 1px inset var(--negative-2)"
            : "var(--elevation-6)",
        }}
      >
        <Controller
          name="customRange"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <StaticDateRangePicker
                id="date-range"
                containerProps={{
                  sx: {
                    ".MuiPickersLayout-root": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                startDate={serializeDate(value.date_after)}
                endDate={serializeDate(value.date_before)}
                colorVariant="green"
                onStartDateChange={(startDateValue) => {
                  onChange({
                    ...value,
                    date_after: deserializeDate(startDateValue),
                  });
                }}
                onEndDateChange={(endDateValue) => {
                  onChange({
                    ...value,
                    date_before: deserializeDate(endDateValue),
                  });
                }}
              />
            );
          }}
        />
        <Box sx={{ px: 3, mb: 2, width: "100%" }}>
          <Divider color="beige" />
        </Box>
        <CustomRangeFormFooter
          onCancel={onCancel}
          errorMessage={errorMessage}
        />
      </Stack>
    </ExportEmployerInvoicesModalContentWrapper>
  );
};

type CustomRangeFormFooterProps = {
  onCancel: () => void;
  errorMessage: string | undefined;
};

const CustomRangeFormFooter = ({
  errorMessage,
  onCancel,
}: CustomRangeFormFooterProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        width: "100%",
        px: 3,
        pb: 3,
      }}
    >
      <Button
        onClick={onCancel}
        variant="transparent-grey"
        sx={{ ml: -1, px: "8px !important", whiteSpace: "nowrap" }}
        size="x-small"
      >
        Cancel
      </Button>
      <Stack direction="row" sx={{ gap: 2, alignItems: "center" }}>
        {!!errorMessage && (
          <Typography
            variant="paragraph"
            component="p"
            error
            size="medium"
            sx={{ textAlign: "right" }}
          >
            {errorMessage}
          </Typography>
        )}
        <Button
          variant="primary"
          type="submit"
          shape="squared"
          sx={{ flexShrink: 0 }}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};

const serializeDate = (date: string | null | undefined) => {
  return date ? dayjs.utc(date) : null;
};

const deserializeDate = (date: Dayjs | null) => {
  return date ? date.format("YYYY-MM-DD") : null;
};
