import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useGetFreelancerBidsPaginatedQuery } from "@js/apps/freelancer/api";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";

import { ApplicationBoostCard } from "./application-boost-card";
import { AppliedJobsList } from "./applied-jobs-list";

export const AppliedJobsSection = () => {
  const isMobile = useMediaQuery("sm");
  const { data: mostRecentBids, isLoading } =
    useGetFreelancerBidsPaginatedQuery({
      ordering: "-created",
      page_size: 3,
      current: true,
    });
  const noBids = !isLoading && !mostRecentBids?.results.length;

  if (noBids) {
    return <></>;
  }

  return (
    <div className="applied-jobs-section">
      <Typography
        component="h3"
        variant={isMobile ? "paragraph" : "title"}
        mb={2}
        size={isMobile ? "large" : "small"}
        fontWeight={400}
      >
        I’ve applied to
      </Typography>
      {isLoading || !mostRecentBids ? (
        <Loader />
      ) : (
        <>
          <ApplicationBoostFeatureFlagWrapper>
            <ApplicationBoostCard />
          </ApplicationBoostFeatureFlagWrapper>
          <AppliedJobsList bids={mostRecentBids.results} />
        </>
      )}
    </div>
  );
};
