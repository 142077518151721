import { useMemo, useState } from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import { MessengerContext } from "@js/apps/messenger/context/messenger-context";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { getCurrentPathEncoded } from "@js/utils";

import { Messenger } from "../../components/messenger";
import { SingleRoomBox } from "../../components/single-room";
import { MessagesProvider } from "../../context";

import { GenericMessengerModalInstance } from "./manage-messenger-modal";
import type { MessengerModalProps } from "./types";

import styles from "./styles.module.scss";

export const GenericMessengerModalContent = (props: MessengerModalProps) => {
  const user = useUser();

  if (!user) {
    return (
      <div className={styles.messengerNoUser}>
        <p>Please login first to see messages.</p>
        {!window.location.href.includes("auth/login") && (
          <Button
            RouterLink={RouterLink}
            variant="primary"
            className="btn-guest"
            onClick={ModalInstance.close}
            href={`/auth/login/?next=${getCurrentPathEncoded()}`}
          >
            Sign in
          </Button>
        )}
      </div>
    );
  }

  return <MessengerWithRoomId {...props} />;
};

const MessengerWithRoomId = ({
  initialMessage,
  roomId,
}: MessengerModalProps) => {
  const [activeRoom, setActiveRoom] = useState<number | undefined>(roomId);
  const contextValue = useMemo(
    () => ({ activeRoom, setActiveRoom }),
    [activeRoom, setActiveRoom],
  );

  return (
    <MessengerContext.Provider value={contextValue}>
      <MessengerWrapper
        activeRoom={activeRoom}
        initialMessage={initialMessage}
      />
    </MessengerContext.Provider>
  );
};

type MessengerWrapperProps = {
  activeRoom?: number;
  initialMessage?: string;
};

const MessengerWrapper = ({
  activeRoom,
  initialMessage,
}: MessengerWrapperProps) => {
  const isMobile = useMediaQuery("md");

  if (isMobile && !!activeRoom) {
    return (
      <MessagesProvider inModal>
        <SingleRoomBox />
      </MessagesProvider>
    );
  }

  return (
    <MessagesProvider>
      <Messenger textBoxProps={{ initialMessage }} />
    </MessagesProvider>
  );
};

export const openGenericMessenger = (params: MessengerModalProps) => {
  GenericMessengerModalInstance.open({
    children: <GenericMessengerModalContent {...params} />,
    closeButton: true,
    className: styles.modal,
    closeButtonProps: { className: styles.closeButton },
    padding: false,
    containerScrollableNoMobilePadding: true,
  });
};
