import type { WrappedFieldProps } from "redux-form";

import { TextField, Typography } from "@hexocean/braintrust-ui-components";
import { DateField } from "@js/forms/fields";

import styles from "./styles.module.scss";

const CustomTextField = (props) => {
  return (
    <TextField
      {...props}
      label={
        <Typography
          component="span"
          variant="label"
          size="large"
          color="primary"
          display="block"
        >
          Date
        </Typography>
      }
    />
  );
};

export const IssuedAtField = ({ input, meta }: WrappedFieldProps) => {
  return (
    <DateField
      input={input}
      meta={meta}
      label="Date"
      id="issued_at"
      readOnly
      disabled
      slots={{ textField: CustomTextField }}
      slotProps={{
        textField: {
          variant: "standard",
          size: "small",
          className: styles.issuedAtTextField,
          id: "issued_at",
          error: !!meta.error,
          helperText: meta.error,
        },
      }}
    />
  );
};
