import { useEffect, useMemo, useState } from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { TextField } from "@hexocean/braintrust-ui-components";
import type {
  ComboBoxProps,
  TextFieldProps,
} from "@hexocean/braintrust-ui-components/";
import { ComboBox } from "@hexocean/braintrust-ui-components/";
import type { UserSearchParams, UserSearchResult } from "@js/apps/auth/api";
import {
  useGetUserSearchItemQuery,
  useGetUserSearchQuery,
} from "@js/apps/auth/api";
import { useSearchPhrase } from "@js/components/autocomplete-new/hooks/search-phrase";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "@js/components/autocomplete-new/hooks/synchronize-with-form-value";

type RhfUserSearchComboboxFieldProps<T extends FieldValues> =
  UseControllerProps<T> &
    Partial<
      Omit<
        ComboBoxProps<UserSearchResult & { isInitialItem?: boolean }, false>,
        "component"
      >
    > & {
      label: string | undefined;
      endpointParams?: Partial<UserSearchParams>;
      variant?: TextFieldProps["variant"];
      id: string;
      control: Control<T>;
      helperText?: string;
    };

export const RhfUserSearchComboboxField = <T extends FieldValues>({
  label,
  endpointParams,
  variant = "outlined",
  helperText,
  ...props
}: RhfUserSearchComboboxFieldProps<T>) => {
  const { field, fieldState, formState } = useController(props);
  const [value, setValue] = useState<UserSearchResult | null>(null);
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: users } = useGetUserSearchQuery({
    search: searchPhrase,
    ...endpointParams,
  });

  const defaultValue = formState.defaultValues
    ? formState.defaultValues[props.name]
    : undefined;

  const skip = fieldState.isDirty || !defaultValue || !!value; // "dirty" is cleared with every search so we need to rely on "value" too
  const { data: initialItem, isLoading: initialTaxonomyLoading } =
    useGetUserSearchItemQuery(Number(defaultValue), {
      skip,
    });

  const options = useMemo(() => {
    // include initialItem in options to avoid MUI warning related to isOptionEqualToValue
    if (initialItem) {
      return [...(users || []), { ...initialItem, isInitialItem: true }];
    }

    return users || [];
  }, [users, initialItem]);

  useSynchronizeSingleValueInternalStateWithFormValue({
    initialTaxonomyLoading,
    formValue: field.value,
    isValueEqualFormValue: field.value === value?.id,
    findOption: (option) => {
      return option.id === field.value;
    },
    setValue,
    options,
  });

  useEffect(() => {
    if (initialItem) {
      setValue(initialItem);
    }
  }, [initialItem]);

  return (
    <ComboBox<UserSearchResult & { isInitialItem?: boolean }, false>
      initialTaxonomiesLoading={initialTaxonomyLoading}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            variant={variant}
            {...params}
            label={label || "User"}
            error={fieldState.invalid}
            helperText={fieldState.error?.message || helperText}
          />
        );
      }}
      value={value || null}
      onInputChange={onInputChange}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        field.onChange(valueArg?.id || undefined);
      }}
      filterOptions={(optionsArg) => {
        return optionsArg.filter((opt) => !opt.isInitialItem);
      }}
      onBlur={field.onBlur}
      disabled={field.disabled}
      getOptionLabel={(option) => option.name_and_email}
      {...props}
    />
  );
};
