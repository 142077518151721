type DownloadCellProps = { href?: string };

export const DownloadCell = ({ href }: DownloadCellProps) => {
  if (!href) {
    return <>-</>;
  }

  return (
    <a download href={href}>
      Download
    </a>
  );
};
