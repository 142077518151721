import { EmployerMyTalentComponent } from "@js/apps/employer/components/employer-my-talent";

import { EmployerDashboardLayout } from "../layout";

import styles from "./style.module.scss";

export const EmployerDashboardMyTalentPage = () => {
  return (
    <EmployerDashboardLayout
      pageTitle="My Talent"
      bgcolor="var(--soft-violet)"
      setBgOnBody
      contentClassName={styles.page}
    >
      <EmployerMyTalentComponent />
    </EmployerDashboardLayout>
  );
};
