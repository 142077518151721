import { useState } from "react";

import { MyWorkListingPage } from "../my-work-listing-page";

import { FreelancerDashboardJobsPage } from ".";

export const DashboardMyJobsPage = (): JSX.Element => {
  const [showClosedJobs, setShowClosedJobs] = useState(false);

  const checkboxProps = {
    checkboxText: "Show past jobs",
    isChecked: showClosedJobs,
    setIsChecked: setShowClosedJobs,
  };

  return (
    <MyWorkListingPage pageTitle="Jobs History" checkboxProps={checkboxProps}>
      <FreelancerDashboardJobsPage showClosedJobs={showClosedJobs} />
    </MyWorkListingPage>
  );
};
