import React, { forwardRef } from "react";
import type { LinkProps } from "react-router-dom";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

import { trimURL } from "@js/utils/url";

export type RouterLinkProps = Omit<LinkProps, "to" | "onClick"> &
  React.RefAttributes<HTMLAnchorElement> & {
    href?: string;
    forceRemount?: boolean;
    activeClassName?: string;
    to?: LinkProps["to"];
    onClick?: (params?) => void;
    ref?: string;
    target?: string;
    rel?: string;
  };

export const RouterLink = forwardRef((props: RouterLinkProps, ref) => {
  const {
    to,
    href,
    target,
    rel,
    forceRemount = false,
    onClick = () => {
      return undefined;
    },
    activeClassName,
    className,
    ...rest
  } = props;

  const calculatedProps = {
    to,
    onClick,
    href,
    target,
    rel,
    className,
  };

  // Required for some pages, that render links on wrapping route, and don't update them on location change
  const location = useLocation();
  const currentPath = location.pathname + location.search + location.hash;
  const isActive =
    to && typeof to === "string" && trimURL(to) === trimURL(currentPath);
  if (!forceRemount && to && isActive) {
    calculatedProps.onClick = (e) => {
      e.preventDefault();
      onClick?.();
    };
  }

  const AComponent = "a";
  let Component: any = ReactRouterLink;

  if (
    typeof href === "string" &&
    !(
      href.toLowerCase().startsWith("http://") ||
      href.toLowerCase().startsWith("https://") ||
      href.startsWith("mailto:")
    )
  ) {
    calculatedProps.to = href;
  } else if (href) {
    Component = AComponent;
    calculatedProps.href = href;
    delete calculatedProps.to;
  }

  if (
    to &&
    typeof to === "string" &&
    (to.toLowerCase().startsWith("http://") ||
      to.toLowerCase().startsWith("https://") ||
      to.startsWith("data:") ||
      to.startsWith("mailto:"))
  ) {
    Component = AComponent;
    calculatedProps.href = to;
    delete calculatedProps.to;
  }

  if (target === "_blank" && to && typeof to === "string") {
    Component = AComponent;
    calculatedProps.href = to;
    calculatedProps.target = target;
    calculatedProps.rel = "noopener";
  }

  if (isActive) {
    calculatedProps.className = [calculatedProps.className, activeClassName]
      .filter(Boolean)
      .join(" ");
  }

  return (
    <Component
      ref={ref}
      {...calculatedProps}
      {...rest}
      state={{
        prevPath: window.location.pathname,
        ...props.state,
      }}
    />
  );
});
