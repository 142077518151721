import { useNavigate } from "react-router-dom";

import type { EmployerBidListBid, Job } from "@js/types/jobs";
import { assertUnreachable, capitalize, formatBudget } from "@js/utils";

import style from "./components/style.module.scss";

export const getBidUserName = (bid: EmployerBidListBid): string => {
  return bid.freelancer.user.public_name;
};

export const getBidUserLocation = (bid: EmployerBidListBid): string | null => {
  if (!bid.freelancer.location) return null;

  return bid.freelancer.location;
};

export const getBidRole = (bid: EmployerBidListBid): string => {
  return bid.freelancer.role?.name;
};

export const getRateLabel = (
  job: Job,
  bid: EmployerBidListBid,
  config?: { hideSuffix: boolean },
) => {
  const suffix = config?.hideSuffix ? "" : "/hr";

  if (
    job.job_type === ENUMS.JobType.DIRECT_HIRE &&
    Number(job.budget_minimum_usd) === Number(bid.payment_amount)
  ) {
    return formatBudget(job.budget_minimum_usd, job.budget_maximum_usd, {
      paymentType: bid.payment_type,
      hourlyRateSuffix: suffix,
      removeDecimal: bid.payment_amount?.endsWith(".00"),
    });
  }

  return formatBudget(bid.payment_amount, bid.payment_amount, {
    paymentType: bid.payment_type,
    hourlyRateSuffix: suffix,
    removeDecimal: bid.payment_amount?.endsWith(".00"),
  });
};

export const getBidItemStatusMeta = (
  status: EnumType<typeof ENUMS.BidStatus>,
  bidInterviewStatus?: EnumType<typeof ENUMS.JobInterviewStatus>,
) => {
  switch (status) {
    case ENUMS.BidStatus.VIEWED: {
      return {
        label: null,
        color: null,
      };
    }
    case ENUMS.BidStatus.NEW:
    case ENUMS.BidStatus.OFFER_DEPOSIT_PAYMENT_FAILED:
    case ENUMS.BidStatus.OFFER_UPDATED: {
      return {
        label: ENUMS.BidStatusLabels[status],
        color: "var(--negative-2)",
      };
    }
    case ENUMS.BidStatus.INTERVIEWING: {
      if (bidInterviewStatus) {
        return {
          label: capitalize(bidInterviewStatus).replace("_", " "),
          color:
            bidInterviewStatus === ENUMS.JobInterviewStatus.ROUND_1
              ? "var(--dark-violet)"
              : "var(--info-2)",
        };
      }
      return {
        label: ENUMS.BidStatusLabels[status],
        color: "var(--dark-violet)",
      };
    }
    case ENUMS.BidStatus.OFFER_WAITING_FOR_DEPOSIT:
    case ENUMS.BidStatus.OFFER_DEPOSIT_PAYMENT_IN_PROGRESS: {
      return {
        label: ENUMS.BidStatusLabels[status],
        color: "var(--info-2)",
      };
    }
    case ENUMS.BidStatus.LIVE_INTERVIEW_AFTER_AI_INTERVIEW:
    case ENUMS.BidStatus.IN_REVIEW_AFTER_AI_INTERVIEW: {
      if (bidInterviewStatus) {
        return {
          label: capitalize(bidInterviewStatus).replace("_", " "),
          color:
            bidInterviewStatus === ENUMS.JobInterviewStatus.ROUND_1
              ? "var(--dark-violet)"
              : "var(--info-2)",
        };
      }

      return {
        label: ENUMS.BidStatusLabels[status],
        color: "var(--positive-1)",
      };
    }

    case ENUMS.BidStatus.APPLICATION_IN_REVIEW:
    case ENUMS.BidStatus.OFFER_MADE: {
      return {
        label: ENUMS.BidStatusLabels[status],
        color: "var(--positive-1)",
      };
    }
    case ENUMS.BidStatus.OFFER_CANCELED:
    case ENUMS.BidStatus.OFFER_EXPIRED:
    case ENUMS.BidStatus.OFFER_DECLINED:
    case ENUMS.BidStatus.APPLICATION_CANCELED: {
      return {
        label: ENUMS.BidStatusLabels[status],
        color: "var(--grey-3)",
      };
    }
    case ENUMS.BidStatus.REJECTED: {
      return {
        label: ENUMS.BidStatusLabels[status],
        color: "var(--negative-1)",
      };
    }
    case ENUMS.BidStatus.HIRED: {
      return {
        label: `${ENUMS.BidStatusLabels[status]} 👏`,
        color: "var(--positive-1)",
      };
    }
    default:
      assertUnreachable(status);
      return {
        label: null,
        color: null,
      };
  }
};

export const useGenerateLinkAction = ({ link }: { link?: string }) => {
  const navigate = useNavigate();

  return link
    ? {
        className: style.clickableContainer,
        onClick: () =>
          navigate(link, {
            state: { prevPath: window.location.pathname, noScroll: true },
          }),
        "data-testid": "generated-clickable-container",
      }
    : {};
};
