import { useState } from "react";

import type { LabelType } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Button,
  MenuSelect,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CheckIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

export type CustomizeColumnsButtonProps<
  TValue extends string = string,
  TOption extends { label: string; value: string } = {
    label: string;
    value: TValue;
  },
> = {
  options: TOption[];
  selectedColumns: NoInfer<TValue>[];
  onChange: (value: TValue[]) => void;
};

export const CustomizeColumnsButton = <TValue extends string>({
  options,
  selectedColumns,
  onChange,
}: CustomizeColumnsButtonProps<TValue>) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <MenuSelect
      id="customize-columns-menu"
      className={styles.myTalentColumnSelectMenu}
      open={isOpen}
      onClose={onClose}
      anchor={
        <Button
          variant="no-padding"
          disableRipple
          aria-controls="customize-columns-menu"
          aria-haspopup="true"
          onClick={onOpen}
        >
          <Typography
            component="span"
            style={{ textDecoration: "underline" }}
            fontWeight={isOpen ? 500 : 400}
            color="grey-1"
          >
            Customize columns
          </Typography>
        </Button>
      }
      options={options}
      value={selectedColumns}
      multiple
      renderCustomItemLabel={({ selected, optionLabel }) => (
        <ItemLabel selected={selected} optionLabel={optionLabel} />
      )}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      onChange={onChange}
    />
  );
};

type ItemLabelProps = {
  selected: boolean | undefined;
  optionLabel: LabelType;
};

const ItemLabel = ({ selected, optionLabel }: ItemLabelProps) => {
  return (
    <Box className={styles.itemLabel}>
      <Box className={styles.itemLabelIcon}>
        {selected && <CheckIcon style={{ height: "16px", width: "16px" }} />}
      </Box>

      <Typography component="span" size="small">
        {optionLabel}
      </Typography>
    </Box>
  );
};
