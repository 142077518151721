import { StarSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FilterPopover } from "@js/apps/employer/components/filter-popover";
import { useFilterPopover } from "@js/hooks/use-filter-popover";
import { useHasFilterPopoverAnimationFinished } from "@js/hooks/use-has-filter-popover-animation-finished";

import { MY_TALENT_FILTER_TO_APPLY_LABEL } from "./constants";
import { EmployerMyTalentJobOwnerForm } from "./forms";
import type { EmployerMyTalentFormValues } from "./schemas";

type MyTalentJobOwnerFilterPopoverProps = {
  jobOwners: EmployerMyTalentFormValues["job_owners"];
  onChange: (jobOwners: EmployerMyTalentFormValues["job_owners"]) => void;
};

export const MyTalentJobOwnerFilterPopover = ({
  jobOwners,
  onChange,
}: MyTalentJobOwnerFilterPopoverProps) => {
  const isJobOwnerApplied = Boolean(jobOwners?.length);

  const { isOpen, handleClick, handleClose, anchorElement } =
    useFilterPopover();

  const { onPopoverEnter, onPopoverClose, hasPopoverAnimationFinished } =
    useHasFilterPopoverAnimationFinished();

  const handleApply = (
    newJobOwner: EmployerMyTalentFormValues["job_owners"],
  ) => {
    onChange(newJobOwner);
    handleClose();
    onPopoverClose();
  };

  const handlePopoverClose = () => {
    handleClose();
    onPopoverClose();
  };

  return (
    <FilterPopover
      label={MY_TALENT_FILTER_TO_APPLY_LABEL.job_owners}
      icon={<StarSVGIcon />}
      isFilterApplied={isJobOwnerApplied}
      isFilterOpen={isOpen}
      onClick={handleClick}
      onClose={handlePopoverClose}
      anchorElement={anchorElement}
      onPopoverEnter={onPopoverEnter}
    >
      <EmployerMyTalentJobOwnerForm
        jobOwners={jobOwners}
        onApply={handleApply}
        shouldOpenDropdown={hasPopoverAnimationFinished}
      />
    </FilterPopover>
  );
};
