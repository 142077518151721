import type { GetEmployerMyTalentParams } from "@js/apps/employer/api";

import type { EmployerMyTalentFormValuesWithDefaults } from "./schemas";

export const mapFiltersToApiFormat = (
  filters: EmployerMyTalentFormValuesWithDefaults | undefined,
): GetEmployerMyTalentParams => {
  if (!filters) {
    return {};
  }

  const { search } = filters;
  const mappedSearch = !search ? undefined : search;

  return { ...filters, search: mappedSearch };
};
