import { useEffect, useMemo } from "react";

import {
  Box,
  Loader,
  Pagination,
  PaginationWrapper,
  Stack,
} from "@hexocean/braintrust-ui-components";
import {
  useGetEmployerMyTalentQuery,
  useGetEmployerMyTalentStatsQuery,
} from "@js/apps/employer/api";
import { processGetMyTalentParams } from "@js/apps/employer/utils/my-talent";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { useSetInitialQueryParams } from "@js/hooks/use-set-initial-query-params";
import { handleDownloadFile, parseListQueryParam } from "@js/utils";

import { MyTalentFilters } from "../my-talent-filters";
import {
  initialFormValues,
  useMyTalentFilters,
} from "../my-talent-filters/hooks/use-my-talent-filters";
import { mapFiltersToApiFormat } from "../my-talent-filters/map-filters-to-api-format";

import { MyTalentTableComponent } from "./my-talent-table-component";
import { MyTalentNoDataEmptyState } from "./my-talent-table-empty-state";
import { MyTalentTableTopSection } from "./my-talent-table-top-section";
import { useMyTalentCustomizedColumns } from "./use-my-talent-customized-columns";

export const MyTalentTable = () => {
  const { isInitialized } = useSetInitialQueryParams({
    initialQueryParams: initialFormValues,
  });

  if (!isInitialized) {
    return <InitialLoader />;
  }

  return <MyTalentTableInitialized />;
};

export const MyTalentTableInitialized = () => {
  const { columns, options, onChange } = useMyTalentCustomizedColumns();
  const {
    control,
    setValue,
    filters,
    onReset: onResetFilters,
  } = useMyTalentFilters();

  const mappedFilters = useMemo(() => {
    return mapFiltersToApiFormat(filters);
  }, [filters]);

  const {
    data,
    isLoading,
    isError,
    isFetching: isFetchingMyTalent,
  } = useGetEmployerMyTalentQuery(mappedFilters);

  const {
    data: myTalentStats,
    isLoading: isLoadingMyTalentStats,
    isFetching: isFetchingMyTalentStats,
  } = useGetEmployerMyTalentStatsQuery();

  const totalTalentCount = myTalentStats?.total_hired_talent_count ?? 0;

  const handleDownloadCSV = () => {
    const params = processGetMyTalentParams(mappedFilters);
    const columnsParam = parseListQueryParam(columns);
    delete params.page;

    handleDownloadFile({
      endpoint: "/api/employer_my_talent/",
      params: { ...params, columns: columnsParam, format: "csv" },
    });
  };

  useEffect(() => {
    if (!isError) {
      return;
    }

    Snackbar.error("Something went wrong.");
  }, [isError]);

  const isFetching = isFetchingMyTalent || isFetchingMyTalentStats;
  const isTableEmpty = !data?.results.length;
  const hasNoTalent = !totalTalentCount;

  const showLoader =
    isLoading || isLoadingMyTalentStats || (isFetching && hasNoTalent);
  const showTableLoader = isFetching && isTableEmpty;
  const showPageEmptyState = !isFetching && hasNoTalent;

  if (showLoader) {
    return <InitialLoader />;
  }

  if (showPageEmptyState) {
    return <MyTalentNoDataEmptyState />;
  }

  return (
    <Stack sx={{ gap: 3 }}>
      <MyTalentFilters control={control} />
      <Box id="table">
        <MyTalentTableTopSection
          talentCount={data?.results.length}
          onChange={onChange}
          selectedColumns={columns}
          options={options}
          handleDownloadCSV={handleDownloadCSV}
        />
        <MyTalentTableComponent
          sortBy={filters?.order_by}
          sortDir={filters?.order_dir}
          onSortChange={({ sortBy, sortDir }) => {
            setValue("order_by", sortBy, { shouldDirty: true });
            setValue("order_dir", sortDir, { shouldDirty: true });
          }}
          visibleColumns={columns}
          data={data?.results ?? []}
          onResetFilters={onResetFilters}
          isLoading={showTableLoader}
        />
      </Box>
      <PaginationWrapper>
        <Pagination
          page={filters?.page}
          count={data?.count ?? 0}
          perPage={10}
          RouterLink={RouterLink}
        />
      </PaginationWrapper>
    </Stack>
  );
};

const InitialLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "10rem" }}>
      <Loader centered />
    </Box>
  );
};
