import type { RegisterValues } from "@js/apps/auth/actions";
import type { NewClientInvitationDetails } from "@js/apps/onboarding/api";
import { LocalStorage } from "@js/services";
import type {
  AccountType,
  PublicInvitationDetails,
  PublicInvitationToJob,
  User,
} from "@js/types/auth";
import { assertUnreachable, typeGuard } from "@js/utils";

import { openOnboardingConfirmationPopup } from "../../components/confirmation-popup";

type InitialValuesOptions = {
  job_id?: string;
  publicInvitationDetails?: PublicInvitationDetails | null;
  newClientInvitationDetails?: NewClientInvitationDetails;
  isClientSignup?: boolean;
};

type RoleConvertableToInterest =
  | "Product"
  | "Design"
  | "Engineering"
  | (string & Record<never, never>);

type Interest = EnumType<typeof ENUMS.Interest>;

const roleToInterestMap = new Map<RoleConvertableToInterest, Interest>([
  ["Product", ENUMS.Interest.PRODUCT],
  ["Design", ENUMS.Interest.DESIGN],
  ["Engineering", ENUMS.Interest.ENGINEERING],
]);

export const getInitialValues = (
  user: Pick<User, "first_name"> | null,
  options: InitialValuesOptions,
) => {
  const {
    job_id,
    publicInvitationDetails,
    newClientInvitationDetails,
    isClientSignup,
  } = options;
  const initialValues = JSON.parse(
    LocalStorage.getItem(LocalStorage.keys.ONBOARDING_SAVED_DATA) ?? "{}",
  );

  if (user?.first_name) {
    initialValues.first_name = user.first_name;
  }

  if (newClientInvitationDetails) {
    initialValues.first_name = newClientInvitationDetails.first_name;
    initialValues.last_name = newClientInvitationDetails.last_name;
    initialValues.email = newClientInvitationDetails.email;
  }

  if (job_id) {
    initialValues["joining_reasons"] = [ENUMS.JoiningReason.GET_A_JOB];
  }

  if (isClientSignup) {
    initialValues.phone_number = { country_iso2: "US", phone_number: null };
  }

  if (
    publicInvitationDetails &&
    typeGuard<PublicInvitationDetails, PublicInvitationToJob>(
      publicInvitationDetails,
      "job",
    ) &&
    publicInvitationDetails?.job
  ) {
    const initialInterestValue = roleToInterestMap.get(
      publicInvitationDetails?.job?.role,
    );

    initialValues["interests"] = initialInterestValue
      ? [initialInterestValue]
      : [];
  }
  return initialValues;
};

const getCreateAccountStepErrorMessage = (missingFields: string[]) => {
  return `You are missing ${missingFields.join(", ")} from previous steps.`;
};

export const getSubmissionErrorOnCreateAccountStep = (errors = {}): any => {
  const errorEntries = Object.entries(errors);
  const currentErrors = Object.fromEntries(
    errorEntries.filter(([key]) => {
      if (key === "joining_reasons" || key === "interests") {
        return false;
      }
      return true;
    }),
  );

  const previousErrors = errorEntries
    .filter(([key]) => !currentErrors[key])
    .map(([key]) => key.replace("_", " "));

  if (previousErrors.length) {
    return {
      onboarding_step_error: getCreateAccountStepErrorMessage(previousErrors),
    };
  }

  if (!!currentErrors.phone_number) {
    return {
      ...currentErrors,
      ...currentErrors.phone_number,
    };
  }

  return currentErrors;
};

type Values = Pick<RegisterValues, "first_name" | "joining_reasons">;

export const validateSubmitGoals = (values: Partial<Values>) => {
  const { first_name, joining_reasons } = values;

  const isMissingFirstName = !first_name || !Boolean(first_name.trim().length);

  const isMissingJoiningReasons =
    !joining_reasons || !Boolean(joining_reasons.length);

  if (isMissingFirstName && isMissingJoiningReasons) {
    return {
      onboarding_step_error:
        "Please add your first name and choose at least one option above.",
      first_name: "Please add your first name",
    };
  }

  if (isMissingFirstName) {
    return {
      onboarding_step_error: "Please add your first name above.",
      first_name: "Please add your first name",
    };
  }

  if (isMissingJoiningReasons) {
    return {
      onboarding_step_error: "Please choose at least one option above.",
    };
  }

  return null;
};

export const updateLocalStorageData = (data: Record<string, any>) => {
  const existingData = JSON.parse(
    LocalStorage.getItem(LocalStorage.keys.ONBOARDING_SAVED_DATA) ?? "{}",
  );
  const newData = { ...existingData, ...data };
  LocalStorage.setItem(
    LocalStorage.keys.ONBOARDING_SAVED_DATA,
    JSON.stringify(newData),
  );
};

export type JoiningReasons = Array<
  (typeof ENUMS.JoiningReason)[keyof typeof ENUMS.JoiningReason]
>;

export enum NextStep {
  CREATE_EMPLOYER_ACCOUNT,
  CREATE_USER_ACCOUNT,
  OPEN_ONBOARDING_CONFIRMATION_POPUP,
}

export const getNextStepFrom = (joiningReasons: JoiningReasons): NextStep => {
  const wantsToHireTalent = joiningReasons.includes(
    ENUMS.JoiningReason.HIRE_TALENT,
  );
  const wantsToGetAJob = joiningReasons.includes(ENUMS.JoiningReason.GET_A_JOB);

  if (wantsToHireTalent && wantsToGetAJob) {
    return NextStep.OPEN_ONBOARDING_CONFIRMATION_POPUP;
  }

  if (wantsToHireTalent) {
    return NextStep.CREATE_EMPLOYER_ACCOUNT;
  }

  return NextStep.CREATE_USER_ACCOUNT;
};

export const performActionFor = (
  nextStep: NextStep,
  callbacks: {
    onCreateEmployerAccount: () => void;
    onCreateUserAccount: () => void;
  },
): void => {
  const { onCreateEmployerAccount, onCreateUserAccount } = callbacks;

  switch (nextStep) {
    case NextStep.CREATE_EMPLOYER_ACCOUNT: {
      return onCreateEmployerAccount();
    }
    case NextStep.CREATE_USER_ACCOUNT: {
      return onCreateUserAccount();
    }
    case NextStep.OPEN_ONBOARDING_CONFIRMATION_POPUP: {
      return openOnboardingConfirmationPopup({
        onEmployerAccountClick: onCreateEmployerAccount,
        onPersonalAccountClick: onCreateUserAccount,
      });
    }
    default: {
      assertUnreachable(nextStep);
    }
  }
};

export const getValuesWithValidatePhoneNumber = <
  T extends { phone_number?: { phone_number?: string | null } },
>(
  values: T,
): T => {
  if (!values.phone_number) return values;

  if (
    values.phone_number.phone_number?.trim() === "" ||
    values.phone_number.phone_number === null
  ) {
    const { phone_number, ...valueWithoutPhoneNumber } = values;
    return valueWithoutPhoneNumber as T;
  }
  return values;
};

export const getFinishSignupRedirectUrl = ({
  finishSignupURL,
  nextQueryParam,
  accountType,
  invitationKeyQueryParam,
}: {
  accountType: AccountType;
  finishSignupURL: string | null;
  nextQueryParam: string | null;
  invitationKeyQueryParam: string | null;
}) => {
  if (accountType === "employer" && !invitationKeyQueryParam) {
    return "/onboarding/organization/";
  }

  return finishSignupURL || nextQueryParam || "/";
};
