import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmissionError } from "redux-form";

import { useAccountType } from "@js/apps/common/hooks";
import { useGetManagedJobQuery, useUpdateJobMutation } from "@js/apps/jobs/api";
import { getInitialValues } from "@js/apps/jobs/apps/create-job/utils/form-initial-values";
import { getInitialValuesOnBehalfOfAClient } from "@js/apps/jobs/apps/create-job/utils/form-initial-values-on-behalf-of-a-client";
import { prepareEditJobSubmitFormValues } from "@js/apps/jobs/apps/create-job/utils/prepare-data";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import type { JobFormValues } from "@js/types/jobs";

import { mapManageJobErrors } from "../../utils/error-mapping";

export const useEditJob = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const jobId = useIdParam();
  const canManageJobAsCoreMember = useCanManageJobsOnBehalfOfClient();
  const { isEmployer } = useAccountType();
  const [updateJob] = useUpdateJobMutation();
  const { data: job } = useGetManagedJobQuery(
    { jobId: jobId as number },
    { skip: !jobId },
  );

  const onSubmit = (values: JobFormValues) => {
    if (!jobId) {
      return;
    }

    const updateJobData = prepareEditJobSubmitFormValues(values, isEmployer);

    return updateJob({ jobId, data: updateJobData })
      .unwrap()
      .catch((error) => {
        if (!error?.data) {
          return;
        }
        const newErrors = mapManageJobErrors(updateJobData, error.data);
        throw new SubmissionError(newErrors);
      });
  };

  const onSubmitSuccess = () => {
    const navigatePath = state?.prevPath ?? `/jobs/${jobId}/invite_talent/`;
    navigate(navigatePath);
  };

  const initialValues = useMemo(() => {
    if (!job) return {};

    if (canManageJobAsCoreMember) {
      return getInitialValuesOnBehalfOfAClient(job);
    }

    return getInitialValues(job);
  }, [job, canManageJobAsCoreMember]);

  const loading = !job;

  const hideUnsavedChangesWarningURLs = useMemo(() => {
    return [`/jobs/${jobId}/edit/`, `/jobs/${jobId}/edit/set_up/`];
  }, [jobId]);

  return {
    job,
    loading,
    onSubmit,
    onSubmitSuccess,
    initialValues,
    unsavedChangesWarning: true,
    hideUnsavedChangesWarningURLs,
  };
};
