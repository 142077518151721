import { useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ComboBoxMultiple,
  createFilterOptions,
  PaperWithVioletOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import {
  FilterPopoverFooter,
  FilterPopoverFormLayoutWrapper,
} from "@js/apps/employer/components/filter-popover";
import { useInitiallyOpenFilterPopoverDropdown } from "@js/apps/employer/hooks/use-initially-open-filter-popover-dropdown";
import { useGetEmployerJobsMinimalQuery } from "@js/apps/jobs/api";
import type { RhfForm } from "@js/rhf/types";
import type { EmployerJobMinimal } from "@js/types/jobs";

import { employerMyTalentJobFormSchema } from "../schemas";

import { EmployerMyTalentFiltersAutocomplete } from "./employer-my-talent-filters-autocomplete";

type Form = RhfForm<typeof employerMyTalentJobFormSchema>;
type FormValues = Form["Values"];
type DefaultFormValues = Form["DefaultValues"];

type EmployerMyTalentJobNameFormProps = {
  jobs: DefaultFormValues["jobs"];
  onApply: (jobs: FormValues["jobs"]) => void;
  shouldOpenDropdown: boolean;
};

const jobTitleStartsWithFilter = createFilterOptions<EmployerJobMinimal>({
  trim: true,
  matchFrom: "start",
});

export const EmployerMyTalentJobNameForm = ({
  jobs,
  onApply,
  shouldOpenDropdown,
}: EmployerMyTalentJobNameFormProps) => {
  const defaultValues = useMemo(() => {
    return { jobs };
  }, [jobs]);
  const { handleSubmit, control, reset } = useForm<FormValues>({
    resolver: zodResolver(employerMyTalentJobFormSchema),
    defaultValues,
  });

  const { field } = useController({
    name: "jobs",
    control: control,
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    onApply(values.jobs);
  };

  const { data: jobsData = [], isLoading } = useGetEmployerJobsMinimalQuery();

  const onReset = () => {
    reset({ jobs: [] });
  };

  const valueObject = useMemo(() => {
    return jobsData.filter((job) => {
      return field.value?.includes(job.id);
    });
  }, [field.value, jobsData]);

  const isFilterEmpty = !field.value?.length;
  const { autocompleteTextFieldRef } = useInitiallyOpenFilterPopoverDropdown({
    hasFilterPopoverFinishedAnimation: shouldOpenDropdown,
    isFilterEmpty,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FilterPopoverFormLayoutWrapper
        formBody={
          <ComboBoxMultiple<EmployerJobMinimal, true>
            openOnFocus
            {...field}
            value={valueObject}
            ref={null}
            onChange={(_event, values) => {
              field.onChange(
                values.map((value) => {
                  return value.id;
                }),
              );
            }}
            ChipProps={{
              deleteIcon: (
                <CloseIcon sx={{ color: "var(--black)!important" }} />
              ),
            }}
            disableClearable
            component={EmployerMyTalentFiltersAutocomplete}
            PaperComponent={PaperWithVioletOptions}
            initialTaxonomiesLoading={isLoading}
            options={jobsData}
            filterOptions={jobTitleStartsWithFilter}
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id;
            }}
            getOptionLabel={(job) => {
              return job.title;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option?.title}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={autocompleteTextFieldRef}
                  {...params}
                  placeholder={isFilterEmpty ? "Select a job title" : undefined}
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Select jobs",
                  }}
                />
              );
            }}
          />
        }
        formFooter={<FilterPopoverFooter onReset={onReset} />}
      />
    </form>
  );
};
