import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Button } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { submitProposalClicked } from "@js/apps/jobs/actions";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { BoostJobButton } from "@js/apps/jobs/components/boost-job-button";
import {
  RespondToOfferButton,
  ReviewOfferButton,
  ScheduleInterviewButton,
  ViewApplicationButton,
} from "@js/apps/jobs/components/job-action-buttons/components";
import { RouterLink } from "@js/components/link";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { FreelancerBid, Job, MainJobListingJob } from "@js/types/jobs";
import { getURLWithBackLink } from "@js/utils";

export type BidButtonProps = {
  job: Job | MainJobListingJob;
  bid?: FreelancerBid;
  openInNewTab?: boolean;
  hideBoostButton?: boolean;
} & Optional<ButtonProps, "variant">;

export const BidButton = ({
  job,
  bid,
  variant = "primary",
  openInNewTab,
  hideBoostButton,
  ...props
}: BidButtonProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery("sm");
  const user = useAppSelector((state) => state.auth.user);

  if (!job) return null;

  const offerStatus = bid?.current_offer?.status;
  const statusForFreelancer = job.status_for_freelancer;

  switch (statusForFreelancer) {
    case ENUMS.JobFreelancerStatus.JOB_FILLED:
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
      if (!job.has_freelancer_bid || !bid) return null;

      return (
        <ViewApplicationButton
          jobId={job.id}
          bidId={bid.id}
          variant="primary"
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.INTERVIEWING:
      return (
        <ScheduleInterviewButton
          employerUserId={job.creator_user_id}
          variant="primary"
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
      if (!bid?.current_offer?.id) return null;

      return (
        <ReviewOfferButton
          jobId={job.id}
          offerId={bid.current_offer.id}
          variant="primary"
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      if (!bid?.current_offer?.id) {
        return null;
      }

      return (
        <RespondToOfferButton
          jobId={job.id}
          offerId={bid.current_offer.id}
          variant="primary"
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
      if (!bid) return null;

      return (
        <>
          <Box display={"flex"} gap={1} flexWrap={"wrap"}>
            {!hideBoostButton && (
              <ApplicationBoostFeatureFlagWrapper>
                <BoostJobButton
                  variant="secondary"
                  color="secondary"
                  boostCredit={user?.boost_credit}
                  bidId={bid.id}
                  fullWidth={!bid.can_edit ? isMobile : false}
                  statusForFreelancer={statusForFreelancer}
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    flexShrink: 0,
                    height: 40,
                  }}
                  isOpen={bid.can_edit || job?.is_open}
                  alreadyBoosted={bid.is_boosted}
                />
              </ApplicationBoostFeatureFlagWrapper>
            )}
            <ViewApplicationButton
              variant={variant}
              jobId={job.id}
              bidId={bid.id}
              target={openInNewTab ? "_blank" : undefined}
              {...props}
            />
          </Box>
        </>
      );
    case ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS:
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
    default:
      if (
        bid?.current_offer?.id &&
        offerStatus === ENUMS.OfferStatus.REJECTED
      ) {
        return (
          <ViewApplicationButton
            jobId={job.id}
            bidId={bid.current_offer.id}
            variant="secondary"
          />
        );
      }

      if ("can_bid" in job && !job.can_bid) {
        return null;
      }

      return (
        <Button
          variant={variant}
          target={openInNewTab ? "_blank" : undefined}
          {...props}
          onClick={() => {
            dispatch(
              submitProposalClicked({ job, search: window.location.search }),
            );
          }}
          to={getURLWithBackLink(`/jobs/${job.id}/proposals/new/`)}
          RouterLink={RouterLink}
        >
          Apply
        </Button>
      );
  }
};
