import { useEffect, useMemo, useRef } from "react";
import type {
  EventWithDataHandler,
  FormErrors,
  TypedWrappedFieldProps,
  WrappedFieldProps,
} from "redux-form";
import { Field, Fields, submit } from "redux-form";

import {
  Avatar,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  AttachmentFileIcon,
  AttachmentImageIcon,
  SendMessageIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType } from "@js/apps/common/hooks";
import { useGetPostCategoriesQuery } from "@js/apps/give-and-get-help/api";
import { getPostCategoriesOptions } from "@js/apps/give-and-get-help/components/category-and-budget-utils/utils";
import { SchedulePromptModal } from "@js/apps/messenger/components/schedule-prompt-modal";
import { DEFAULT_MESSENGER_TEXTBOX_PLACEHOLDER } from "@js/apps/messenger/components/textbox/constants";
import {
  type TextBoxFormValues,
  useSendMessage,
} from "@js/apps/messenger/hooks";
import { useTextBox } from "@js/apps/messenger/hooks/use-textbox";
import { getFormId } from "@js/apps/messenger/utils/get-messenger-form-id";
import { createFormInstance } from "@js/forms/components";
import { TextField } from "@js/forms/fields";
import { useAppDispatch } from "@js/hooks";
import type { User } from "@js/types/auth";
import type { OptionWithPrimitiveValue } from "@js/types/common";
import type { MessageRoom } from "@js/types/messenger";
import { validateAttachments } from "@js/utils/redux-form";

import { useMessagesContext } from "../../context";
import type { PostCategoryFieldProps } from "../help-offer";
import {
  closeHelpOfferModal,
  HelpOfferModal,
  openHelpOfferModal,
} from "../help-offer/help-offer-modal";
import { MessageFilesUploadField } from "../message-files-upload-field";
import { SchedulePrompt } from "../schedule-prompt/schedule-prompt";

import { TextboxAttachmentsPreview } from "./textbox-attachments-preview";
import { TextboxLinkPreviewField } from "./textbox-link-preview";

const validate = (values: TextBoxFormValues) => {
  const errors: FormErrors<TextBoxFormValues, string> = {};

  const attachmentsError = validateAttachments(values);
  if (attachmentsError) {
    errors["attachments"] = attachmentsError;
  }

  return errors;
};

const MESSENGER_TEXTBOX_FORM_ID = "messenger-textbox-form";
const MessengerTextboxForm = createFormInstance<TextBoxFormValues, unknown>(
  MESSENGER_TEXTBOX_FORM_ID,
  { validate },
);

export type TextBoxProps = {
  initialMessage?: string;
  room?: MessageRoom;
  autoFocus?: boolean;
  placeholder?: string;
};

export const TextBox = ({
  autoFocus = true,
  initialMessage = "",
  placeholder = DEFAULT_MESSENGER_TEXTBOX_PLACEHOLDER,
  room,
}: TextBoxProps) => {
  const {
    user,
    isParticipantFreelancer,
    canNotSendMessageReason,
    canSendMessage,
    initialValues,
    onSubmit,
    formId,
    messageError,
  } = useTextBox({ room, initialMessage });
  const dispatch = useAppDispatch();
  const { isEmployer } = useAccountType();

  const { data, isLoading, error } = useGetPostCategoriesQuery(undefined, {
    skip: isEmployer,
  });

  const categories = useMemo(
    () => getPostCategoriesOptions(data, false),
    [data],
  );

  const messagesStore = useMessagesContext();

  if (isLoading || !room || (!data && !error && !isEmployer)) {
    return null;
  }

  const sendOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!e.shiftKey && e.key === "Enter" && target.value) {
      e.preventDefault();
      if (room.id) {
        dispatch(submit(formId));
      }
    }
  };

  const roomId = room.id;

  const handleMessageChange: EventWithDataHandler<
    React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  > = (event) => {
    if (!event) return;

    const newMessage = event.target.value;
    messagesStore.set(roomId, newMessage);
  };

  return (
    <>
      {!!messageError && (
        <Typography
          component="p"
          variant="paragraph"
          color="negative"
          size="small"
          marginBottom={0.5}
        >
          {messageError}
        </Typography>
      )}
      <MessengerTextboxForm
        className="messenger-textbox"
        initialValues={initialValues}
        form={formId}
        key={formId}
        onSubmit={onSubmit}
      >
        <div className="messenger-textbox__fields">
          <div className="messenger-textbox__input-container">
            {user && (
              <Avatar
                size="full"
                src={user}
                className="messenger-textbox__avatar"
              />
            )}
            <Field
              id="messenger-text-box"
              name="message"
              normalize={(message) => {
                if (message.length === 0) return null;
                return message;
              }}
              component={TextField}
              onChange={handleMessageChange}
              autoFocus={autoFocus}
              disabled={!canSendMessage}
              multiline
              onKeyDown={sendOnEnter}
              minRows={1}
              placeholder={placeholder}
              className="messenger-textbox__input"
              inputProps={{ maxLength: 5000 }}
            />
          </div>
          <Fields
            names={["attachments", "showAttachmentField"]}
            component={MessageFilesUploadField}
          />
          <Field
            name="calendar_link"
            component={TextboxLinkPreviewField}
            activeRoom={room}
          />
          <Field name={"attachments"} component={TextboxAttachmentsPreview} />
        </div>
        <Fields
          names={["showAttachmentField", "attachments"]}
          component={MessengerTextboxActions}
          user={user}
          isParticipantFreelancer={isParticipantFreelancer}
          canNotSendMessageReason={canNotSendMessageReason}
          canSendMessage={canSendMessage}
          room={room}
          categories={categories}
        />
      </MessengerTextboxForm>
      <HelpOfferModal />
      <SchedulePromptModal activeRoom={room} />
    </>
  );
};

type MessengerTextboxActionsProps = {
  user: User;
  isParticipantFreelancer: boolean;
  canNotSendMessageReason: string;
  canSendMessage: boolean;
  showAttachmentField: WrappedFieldProps;
  category: WrappedFieldProps;
  budget: WrappedFieldProps;
  room: MessageRoom;
  attachments: TypedWrappedFieldProps<unknown[] | null>;
  categories: PostCategoryFieldProps["categories"];
  budgetOptions: OptionWithPrimitiveValue<number>[];
};

const MessengerTextboxActions = ({
  user,
  isParticipantFreelancer,
  canNotSendMessageReason,
  canSendMessage,
  showAttachmentField,
  room,
  attachments,
  categories,
}: MessengerTextboxActionsProps) => {
  const dispatch = useAppDispatch();
  const { setIsImageAttachment } = useMessagesContext();
  const actionsRef = useRef<HTMLDivElement>(null);
  const isShowAttachmentField = showAttachmentField.input.value;

  const { sendMessage } = useSendMessage({ room: room.id });

  const handleOpenAttachmentField = () => {
    showAttachmentField.input.onChange(!isShowAttachmentField);
    setIsImageAttachment(false);
  };

  const handleOpenImageAttachmentField = () => {
    showAttachmentField.input.onChange(!isShowAttachmentField);
    setIsImageAttachment(!isShowAttachmentField);
  };

  const handleHelpOfferClick = () => {
    openHelpOfferModal({
      onSubmit: ({ budget, category }) => {
        const budgetValue = !budget ? "0" : budget;
        sendMessage({ budget: budgetValue, category });
        closeHelpOfferModal();
      },
      postCategories: categories,
      canNotSendMessageReason,
      canSendMessage,
    });
  };

  useEffect(() => {
    if (isShowAttachmentField) {
      actionsRef.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [isShowAttachmentField, attachments.input.value]);

  const formId = getFormId(room.id);

  const showHelpOfferButton = Boolean(user.freelancer);

  return (
    <div ref={actionsRef}>
      <div className="messenger-textbox__container">
        <div className="messenger-textbox__common-actions">
          <Tooltip
            title="Add an image"
            placement="top"
            disabled={!!user?.is_banned_from_posting}
          >
            <IconButton
              className="messenger-textbox__icon messenger-textbox__icon--image"
              aria-label="Add image"
              variant="transparent"
              size="medium"
              onClick={handleOpenImageAttachmentField}
              sx={{
                "&:hover": { backgroundColor: "var(--yellow)" },
              }}
              disabled={!!user?.is_banned_from_posting}
            >
              <AttachmentImageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Add a file"
            placement="top"
            disabled={!!user?.is_banned_from_posting}
          >
            <IconButton
              className="messenger-textbox__icon"
              variant="transparent"
              size="medium"
              onClick={handleOpenAttachmentField}
              aria-label="Add file"
              sx={{
                "&:hover": { backgroundColor: "var(--yellow)" },
              }}
              disabled={!!user?.is_banned_from_posting}
            >
              <AttachmentFileIcon />
            </IconButton>
          </Tooltip>
          <SchedulePrompt user={user} activeRoom={room} />
        </div>
        <div className="messenger-textbox__actions">
          {showHelpOfferButton && (
            <Tooltip
              title="Not available for client accounts."
              disabled={isParticipantFreelancer}
            >
              <span>
                <Button
                  variant="secondary"
                  size="x-small"
                  onClick={handleHelpOfferClick}
                  shape="squared"
                  className="messenger-textbox__offer-btn"
                  disabled={!isParticipantFreelancer}
                >
                  Offer 1:1 help
                </Button>
              </span>
            </Tooltip>
          )}
          <Tooltip
            title={canNotSendMessageReason}
            disabled={!canNotSendMessageReason}
          >
            <span className="messenger-textbox__submit-button">
              <Button
                variant="primary"
                disabled={!canSendMessage}
                type="submit"
                //https://stackoverflow.com/a/56970849/15536964 -- (mobile) prevent hiding keyboard on Send button click
                onTouchEnd={(e) => {
                  e.preventDefault();
                  dispatch(submit(formId));
                }}
                endIcon={<SendMessageIcon />}
                shape="squared"
                fontWeight={400}
                size="x-small"
              >
                Send
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
