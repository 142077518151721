import type { AIGeneratedJobFormData } from "@js/apps/employer/hooks/generate-ai/utils";
import {
  extractJobLocationAndTimezoneInitialValues,
  getInitialContractTypeValue,
  getInitialExpectedHoursPerWeekValue,
  getInitialPaymentTypeValue,
} from "@js/apps/jobs/apps/create-job/utils/form-single-fields-initial-value";
import { mapRateValuesToFrontendFormat } from "@js/apps/jobs/apps/create-job/utils/rate-mapping";
import { getSanitizedQuestions } from "@js/apps/jobs/apps/create-job/utils/sanitize";
import { sanitize } from "@js/services";
import type { User } from "@js/types/auth";
import type {
  JobDraft,
  JobFormValues,
  ManagedJob,
  UploadedJob,
} from "@js/types/jobs";
import type { IsoDate } from "@js/utils/date/types";

const getCommonInitialValues = (
  job: ManagedJob | JobDraft | AIGeneratedJobFormData,
): Partial<JobFormValues> => {
  const { budget_minimum_usd, budget_maximum_usd } = job;

  const paymentType =
    job.payment_type || getInitialPaymentTypeValue(job.job_type);

  return {
    job_subscribers: job.job_subscribers ?? [],
    job_subscriber_invitations: job.job_subscriber_invitations ?? [],
    ...mapRateValuesToFrontendFormat({
      budget_minimum_usd,
      budget_maximum_usd,
      payment_type: paymentType,
    }),
    auto_invite_talent:
      job.job_fill_support_type !== ENUMS.JobFillSupportType.BYOT &&
      !job.is_private,
    contract_type:
      job.contract_type || getInitialContractTypeValue(job.job_type),
    deadline: job.deadline as IsoDate,
    expected_hours_per_week:
      job.expected_hours_per_week ||
      getInitialExpectedHoursPerWeekValue(job.job_type),
    is_private: job.is_private ?? false,
    introduction: job.introduction ? sanitize(job.introduction) : "",
    job_type: job.job_type,
    level: job.level ?? undefined,
    locations_strongly_required: !!job.locations_strongly_required,
    openings_number: job.openings_number || 1,
    is_resume_required: job.is_resume_required ?? false,
    requirements: job.requirements ? sanitize(job.requirements) : "",
    description: job.description ? sanitize(job.description) : "",
    role: job.role,
    start_date: job.start_date as IsoDate,
    timezone_overlap: job.timezone_overlap,
    title: job.title ? sanitize(job.title, { ALLOWED_TAGS: [] }) : "",
    top_skills: job.top_skills,
    experience_level: job.experience_level || undefined,
    ...extractJobLocationAndTimezoneInitialValues(job),
    is_ai_generated: !!("is_ai_generated" in job && job.is_ai_generated),
    flow_entry: job.flow_entry || "custom",
    ai_min_rate: job.ai_min_rate,
    ai_max_rate: job.ai_max_rate,
    ai_interview_max_duration: job.ai_interview_max_duration,
    is_ai_interview_enabled: job.is_ai_interview_enabled ?? true,
    interview_criteria: job.interview_criteria?.map(
      (criteria) => criteria.name,
    ),
    interview_questions: job.interview_questions,
  };
};
export const getInitialValues = (
  job: ManagedJob | AIGeneratedJobFormData,
): Partial<JobFormValues> => {
  const jobOwnerId = job.hiring_manager ?? job.creator?.id;

  return {
    ...getCommonInitialValues(job),
    id: job.id,
    new_skills: job.skills || [],
    new_application_questions: getSanitizedQuestions(
      ("application_questions" in job && job.application_questions) || [],
    ),
    jobOwnerId,
    job_subscribers: job.job_subscribers ?? [],
    job_subscriber_invitations: job.job_subscriber_invitations ?? [],
    auto_invite_talent: job.auto_invite_talent,
  };
};
export const getCopyJobInitialValues = (
  job: ManagedJob | AIGeneratedJobFormData,
): Partial<JobFormValues> => {
  return {
    ...getInitialValues(job),
    id: undefined,
    original_job: job.id,
    new_application_questions: getSanitizedQuestions(
      ("application_questions" in job &&
        job.application_questions?.map((q) => {
          return { ...q, id: undefined };
        })) ||
        [],
    ),
  };
};
export type GetDraftInitialValuesArg = {
  draft: JobDraft;
  userId: number;
};
export const getDraftInitialValues = ({
  draft,
  userId,
}: GetDraftInitialValuesArg): Partial<JobFormValues> => {
  const jobOwnerId = draft.hiring_manager ?? userId;
  const defaultJobSubscribers: JobFormValues["job_subscribers"] = [
    { team_member_id: jobOwnerId },
  ];
  const defaultJobSubscriberInvitations: JobFormValues["job_subscriber_invitations"] =
    [];
  const defaultApplicationQuestions = [
    {
      question: "Why are you the best candidate for this job?",
      order: 0,
      can_be_changed: true,
    },
  ];

  return {
    ...getCommonInitialValues(draft),
    id: draft.id,
    external_id: draft.external_id ?? undefined,
    original_job: draft.original_job,
    new_skills: draft.new_skills || [],
    new_application_questions: !!draft?.new_application_questions
      ? getSanitizedQuestions(draft?.new_application_questions)
      : defaultApplicationQuestions,
    job_subscribers: draft.job_subscribers ?? defaultJobSubscribers,
    job_subscriber_invitations:
      draft.job_subscriber_invitations ?? defaultJobSubscriberInvitations,
    jobOwnerId,
  };
};
export const getCopyDraftInitialValues = (
  arg: GetDraftInitialValuesArg,
): Partial<JobFormValues> => {
  return {
    ...getDraftInitialValues(arg),
    id: undefined,
  };
};
export const getEmptyJobInitialValues = (
  userId?: User["id"],
): Partial<JobFormValues> => {
  return {
    timezones: [],
    locations: [],
    job_subscribers: userId ? [{ team_member_id: userId }] : [],
    job_subscriber_invitations: [],
    jobOwnerId: userId,
    openings_number: 1,
  };
};
export const getUploadJobDescriptionInitialValues = (
  job: UploadedJob,
  userId?: User["id"],
): Partial<JobFormValues> => {
  return {
    ...job,
    ...getEmptyJobInitialValues(userId),
  };
};
