import type { GridRenderCellParams } from "@hexocean/braintrust-ui-components/components/DataGrid";
import type { EmployerInvoice, InvoiceItem } from "@js/types/invoices";
import { formatCurrency } from "@js/utils/currency";

import type { EmployerInvoiceItemRow, EmployerInvoiceRow } from "../types";

export const isEmployerInvoiceItemRow = (
  row: EmployerInvoiceRow,
): row is EmployerInvoiceItemRow => "invoiceItemId" in row;

export const isEmployerInvoiceRow = (
  row: EmployerInvoiceRow,
): row is EmployerInvoice => !("invoiceItemId" in row);

export const isEmployerInvoiceCellParams = (
  params: GridRenderCellParams<EmployerInvoiceRow>,
): params is GridRenderCellParams<EmployerInvoice> =>
  isEmployerInvoiceRow(params.row);

export const isInvoiceItemCellParams = (
  params: GridRenderCellParams<EmployerInvoiceRow>,
): params is GridRenderCellParams<EmployerInvoiceItemRow> =>
  isEmployerInvoiceItemRow(params.row);

export const getEmployerInvoiceItemRow = ({
  invoice,
  item,
}: {
  invoice: EmployerInvoice;
  item: InvoiceItem;
}): EmployerInvoiceItemRow => {
  return {
    ...item,
    invoiceItemId: item.id,
    invoice,
    id: `${invoice.id}-${item.id}`,
  };
};

export const formatCurrencyOrZero = (value: string | null | undefined) =>
  formatCurrency(value) || "$0.00";
