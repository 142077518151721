import React from "react";

import { useEmailConfirmationModal } from "@js/apps/dashboard/hooks/use-email-confirmation-modal";

import { AutoBlockUserWarningModalProvider } from "./components/auto-block-warning-modal";
import { CanViewInvoicesProvider } from "./components/can-view-invoices-provider";

export const EmployerGlobalProvider = () => {
  useEmailConfirmationModal({
    pathsToHideConfirmationModal: [
      /^\/onboarding\//,
      /\/auth\/logout/,
      /^\/verify_email/,
    ],
  });

  return (
    <>
      <CanViewInvoicesProvider />
      <AutoBlockUserWarningModalProvider />
    </>
  );
};
