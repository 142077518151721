import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { boostBannerClicked, boostBannerViewed } from "@js/apps/jobs/actions";
import { useAppDispatch } from "@js/hooks";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";
import { LocalStorage } from "@js/services";

export const ApplicationBoostCard = () => {
  const isMobile = useMediaQuery("sm");
  const user = useUser();
  const [showJobListBoostBanner, setShowJobListBoostBanner] = useState(false);
  const { onClickPurchaseUpgrade } = usePurchaseUpgrade("boost");
  const dispatch = useAppDispatch();
  const applicationBoostCardWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const determineBoostBannerVisibility = async () => {
      if (user?.boost_credit || !user?.freelancer_approved) {
        return;
      }

      const dismissTime = LocalStorage.getItem(
        LocalStorage.keys.HOMEPAGE_BOOST_CARD_DISMISS_TIME,
      );

      if (!dismissTime) {
        setShowJobListBoostBanner(true);
        return;
      }

      const twoHoursLater = new Date(
        parseInt(dismissTime, 10) + 2 * 60 * 60 * 1000,
      );
      setShowJobListBoostBanner(new Date() > twoHoursLater);
    };

    determineBoostBannerVisibility();
  }, [user]);

  useEffect(() => {
    const wrapperRefCurrent = applicationBoostCardWrapperRef.current;

    if (!wrapperRefCurrent) return;
    if (!showJobListBoostBanner) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            dispatch(boostBannerViewed());
            observer.disconnect();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      },
    );

    observer.observe(wrapperRefCurrent);

    return () => {
      if (wrapperRefCurrent) {
        observer.unobserve(wrapperRefCurrent);
      }
    };
  }, [applicationBoostCardWrapperRef, showJobListBoostBanner, dispatch]);

  const handleDismiss = () => {
    LocalStorage.setItem(
      LocalStorage.keys.JOB_LIST_BOOST_BANNER_DISMISS_TIME,
      String(new Date().getTime()),
    );
    setShowJobListBoostBanner(false);
  };

  const openModal = async () => {
    dispatch(boostBannerClicked());
    onClickPurchaseUpgrade();
  };

  return !showJobListBoostBanner ? null : (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        border: "1px solid var(--soft-beige)",
        borderRadius: 4,
        marginBottom: 2,
        overflow: "hidden",
        position: "relative",
      }}
      ref={applicationBoostCardWrapperRef}
    >
      <Box
        sx={{
          background: "var(--blue)",
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
          justifyContent: "center",
        }}
      >
        <Box display="flex">
          <img
            src={`${SETTINGS.STATIC_URL}jobs/application-boost-card.png`}
            width="100%"
            height="auto"
            alt="boost-card-image"
          />
        </Box>
      </Box>
      <Box p={2}>
        <Typography component="p" variant="paragraph" size="medium">
          Stand out with Application Boost
        </Typography>
        <Typography component="p" variant="paragraph" size="small">
          Elevate your applications to the top and get noticed by employers.
        </Typography>
        <Box sx={{ textAlign: "right", marginTop: 2 }}>
          <Button
            onClick={openModal}
            variant="positive"
            size="x-small"
            shape="squared"
            sx={{
              textWrap: "nowrap",
              "&.typography": {
                fontWeight: "500 !important",
              },
            }}
            endIcon={
              <ArrowRightIcon
                sx={{
                  "& > path": {
                    strokeWidth: "2.5px !important",
                  },
                }}
              />
            }
          >
            Get Application Boosts
          </Button>
        </Box>
      </Box>
      <IconButton
        size={isMobile ? "medium" : "x-small"}
        variant="transparent"
        aria-label="Dismiss boost banner"
        sx={{
          padding: isMobile ? "7px 6px !important" : "12px",
          position: "absolute",
          right: isMobile ? 0 : 1,
          top: isMobile ? 0 : 2,
        }}
        onClick={handleDismiss}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
