import React, { useMemo } from "react";
import type { TypedWrappedFieldProps, WrappedFieldsProps } from "redux-form";
import { change } from "redux-form";
import _ from "underscore";

import type {
  LinkAttachment,
  LinksFormData,
} from "@js/apps/give-and-get-help/components";
import type { CreatePostAttachment } from "@js/apps/give-and-get-help/components/post-attachments";
import { PostAttachments } from "@js/apps/give-and-get-help/components/post-attachments";
import { useAppDispatch } from "@js/hooks";
import type {
  LinkMetadataModel,
  PostAttachment,
} from "@js/types/give-and-get-help";

import type { ReplyOpenedField } from "../../forms/reply-form";
import { useRemoveLinks } from "../../hooks/remove-links";

export const PostAttachmentsWrapper = ({
  openedFields,
  links_metadata,
  links_metadata_from_text,
  links_from_text,
  excluded_links_from_text,
  attachments,
  links,
  linksEditOnClickActive,
}: WrappedFieldsProps & {
  linksEditOnClickActive?: boolean;
  attachments: TypedWrappedFieldProps<
    (PostAttachment | CreatePostAttachment)[]
  >;
}) => {
  const dispatch = useAppDispatch();
  const linksValue = links.input.value as LinksFormData;
  const linksValueFromText = links_from_text.input.value as LinksFormData;

  const linksMetadataValue = useMemo(() => {
    if (!Boolean(links_metadata.input.value)) return "";

    return links_metadata.input.value.map((metadata) => ({
      ...metadata,
    }));
  }, [links_metadata.input.value]) as LinkAttachment[];

  const { remainingLinks, remainingAttachmentLinks } = useRemoveLinks(
    linksValue,
    linksMetadataValue,
  );

  const linksMetadataFromTextValue = links_metadata_from_text.input
    .value as LinkMetadataModel[];

  const linksToDisplayAsAttachment = [
    ...linksMetadataValue,
    ...linksMetadataFromTextValue,
  ];

  const linksToDisplayAsAttachmentWithoutDuplicates =
    linksToDisplayAsAttachment.filter(
      (link, index) =>
        linksToDisplayAsAttachment.map((el) => el.url).indexOf(link.url) ===
        index,
    );

  const attachmentsToDisplay = useMemo(
    () =>
      [
        ...linksToDisplayAsAttachmentWithoutDuplicates,
        ...attachments.input.value,
      ].filter((att, i, a) => {
        const attributeToCheck = "fileId" in att ? "fileId" : "id";

        return (
          a.findIndex(
            (el) => el[attributeToCheck] === att[attributeToCheck],
          ) === i
        );
      }),
    [attachments.input.value, linksToDisplayAsAttachmentWithoutDuplicates],
  );

  const removeAttachment = (
    idOrUrl: string | number,
    isEditMode: boolean,
    isFromText?: boolean,
  ) => {
    const attachmentToRemove = attachmentsToDisplay.find((attachment) => {
      return (
        attachment.id === idOrUrl ||
        ("fileId" in attachment && attachment?.fileId === idOrUrl) ||
        ("url" in attachment && attachment?.url === idOrUrl)
      );
    });

    if (!attachmentToRemove) {
      return;
    }

    if (!("url" in attachmentToRemove)) {
      const filteredAttachments = attachments.input.value.filter(
        (attachment) => {
          if ("fileId" in attachment && attachment?.fileId === idOrUrl) {
            return false;
          }

          return attachment.id !== idOrUrl;
        },
      );

      attachments.input.onChange(filteredAttachments);

      return;
    }

    if (isFromText) {
      excluded_links_from_text.input.onChange([
        ...(excluded_links_from_text.input.value ?? []),
        attachmentToRemove.url,
      ]);
      links_from_text.input.onChange(
        linksValueFromText.filter((link) => {
          // when creating a post, and immediately removing a link before posting
          if (typeof link === "object") {
            return link.id !== idOrUrl;
          }

          // when removing a link after posting (i.e. editing a post)
          return link !== attachmentToRemove.url;
        }),
      );
      links_metadata_from_text.input.onChange(
        linksMetadataFromTextValue.filter(
          (link) => link.id !== idOrUrl && link.url !== idOrUrl,
        ),
      );
      return;
    }
    links.input.onChange(
      remainingLinks(idOrUrl, isEditMode, attachmentToRemove),
    );

    links_metadata.input.onChange(
      remainingAttachmentLinks(idOrUrl, isEditMode, attachmentToRemove),
    );
  };

  const editLinkAttachment = (url: string) => {
    const editedFormId = links_metadata.meta.form;
    const editedFieldName = "edited_link";
    const newFormValue = url;

    removeAttachment(url, true);
    dispatch(change(editedFormId, editedFieldName, newFormValue));

    const currentOpenedFields = openedFields.input.value || [];
    const newOpenedFields: ReplyOpenedField[] = _.uniq([
      ...currentOpenedFields,
      "link",
    ]);
    openedFields.input.onChange(newOpenedFields);
  };

  return (
    <PostAttachments
      attachments={attachmentsToDisplay}
      removeAttachment={removeAttachment}
      onEditLinkClick={linksEditOnClickActive ? editLinkAttachment : undefined}
      openNewTab
    />
  );
};
