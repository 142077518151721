import {
  AlignLeftIcon,
  ChatIcon,
  EarnIcon,
  JobsIcon,
  MarkerIcon,
  PeopleIcon,
  WalletIcon,
  WelcomeIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { WalletLabelWithTokenAmount } from "@js/apps/common/components/wallet-label-with-btrust-amount";
import { GiveGetHelpLabel } from "@js/apps/give-and-get-help/components/give-get-help-label";
import { SpacesNavLabel } from "@js/apps/spaces/components/spaces-nav-label";

import type { DashboardNavItem } from "../types";

export const getFreelancerNavMenuItems = ({
  jobsPath,
  myWorkMainPath,
}: {
  jobsPath: string;
  myWorkMainPath: string;
}): DashboardNavItem[] => {
  return [
    createNavItem({
      path: "/talent/dashboard/welcome/",
      ...FREELANCER_NAV_ITEMS.HOME,
    }),
    createNavItem({
      path: jobsPath,
      ...FREELANCER_NAV_ITEMS.JOBS,
    }),
    createNavItem({
      path: myWorkMainPath,
      ...FREELANCER_NAV_ITEMS.MY_WORK,
    }),
    createNavItem({
      path: "/feed",
      ...FREELANCER_NAV_ITEMS.COMMUNITY,
      subLinks: [
        {
          ...createNavItem({
            path: "/spaces",
            ...FREELANCER_NAV_ITEMS.SPACES,
          }),
          defaultToActive: true,
          distinctPathFromParent: true,
        },
        {
          path: "my_posts",
          ...FREELANCER_NAV_ITEMS.MY_POSTS,
        },
      ],
    }),
    createNavItem({
      path: "/talent/dashboard/my_wallet/",
      ...FREELANCER_NAV_ITEMS.WALLET,
    }),
    createNavItem({
      path: "/talent/dashboard/earn/",
      ...FREELANCER_NAV_ITEMS.EARN,
    }),
  ];
};

export const createNavItem = (arg: DashboardNavItem) => {
  return {
    icon: undefined,
    canAccess: true,
    defaultToActive: false,
    ...arg,
    subLinks: (arg.subLinks || []).map((link) => {
      return createNavItem({
        ...link,
        path: `${link.distinctPathFromParent ? "" : arg.path + "/"}${link.path}`,
      });
    }),
  };
};

export const FREELANCER_NAV_ITEMS = {
  HOME: {
    label: "Home",
    icon: WelcomeIcon,
  },
  JOBS: {
    label: "Jobs",
    icon: JobsIcon,
  },
  COMMUNITY: {
    label: <GiveGetHelpLabel />,
    icon: ChatIcon,
  },
  SPACES: {
    label: <SpacesNavLabel />,
    icon: PeopleIcon,
  },
  MY_POSTS: {
    label: "My posts",
    icon: AlignLeftIcon,
  },
  MY_WORK: {
    label: "My work",
    icon: MarkerIcon,
  },
  WALLET: {
    label: <WalletLabelWithTokenAmount />,
    icon: WalletIcon,
  },
  EARN: {
    label: "Refer and Earn",
    icon: EarnIcon,
  },
};

export const FREELANCER_DASHBOARD_NAV_ITEMS = [
  createNavItem({
    path: "/talent/dashboard/my_jobs",
    ...FREELANCER_NAV_ITEMS.MY_WORK,
    subLinks: [
      {
        path: "invites",
        label: "Invites",
      },
      {
        path: "my_proposals",
        label: "Applications",
      },
      {
        path: "offers",
        label: "Job Offers",
      },
      {
        path: "history",
        label: "My Jobs",
        onlyActiveOnIndex: false,
      },
      {
        path: "help_offers",
        label: "1:1 Help Offers",
        onlyActiveOnIndex: false,
        subPath: [
          "/talent/dashboard/my_jobs/help_offers/getting_help",
          "/talent/dashboard/my_jobs/help_offers/giving_help",
        ],
      },
      {
        path: "invoices",
        label: "Invoices",
      },
    ],
  }),
];
