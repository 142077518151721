import type { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import type { PaginatedResult } from "@js/types/generic";
import type {
  HelpOffer,
  IPost,
  PollOption,
  PostComment,
  ReactionAddedEventData,
  ReactionDeletedEventData,
  StickerValue,
} from "@js/types/give-and-get-help";

import type { FeedOrderingType } from "../common/components/filters";

export type AddedEventData<T> = {
  broadcast_type: typeof ENUMS.BroadcastType.POST_ADDED;
  data: T;
  is_main_post: boolean;
};

export type UpdatedEventData<T> = {
  broadcast_type: typeof ENUMS.BroadcastType.POST_UPDATED;
  data: T;
  is_main_post: boolean;
};

export type DeletedEventData<T extends object> = {
  broadcast_type: typeof ENUMS.BroadcastType.POST_DELETED;
  data: T;
  is_main_post: boolean;
};

export type DeletedCommentEventData = {
  broadcast_type: typeof ENUMS.BroadcastType.POST_DELETED;
  data: CommentDeletedEventData;
  is_main_post: boolean;
};

export type VoteAddedEventData = {
  broadcast_type: typeof ENUMS.BroadcastType.VOTE_ADDED;
  data: { post_id: number; poll_option_id: number };
  is_main_post: boolean;
};

export type YouVotedEventData = {
  broadcast_type: typeof ENUMS.BroadcastType.YOU_VOTED;
  data: PollOption[];
  is_main_post: boolean;
};

export type PostMovedEventData = {
  broadcast_type: typeof ENUMS.BroadcastType.POST_MOVED_TO_SPACE;
  data: { id: number };
  is_main_post: boolean;
};

export type PostsEventPayload =
  | ({ is_main_post: true } & (
      | AddedEventData<IPost>
      | UpdatedEventData<IPost>
      | DeletedEventData<{ id: number }>
      | ReactionAddedEventData
      | ReactionDeletedEventData
      | VoteAddedEventData
      | YouVotedEventData
    ))
  | PostMovedEventData
  | undefined;

type CommentAddedEventData = AddedEventData<
  PostComment & {
    main_post_comments_count?: number;
    main_post_id: number;
  }
>;

export type CommentDeletedEventData = DeletedEventData<{
  id: number;
  main_post_id: number;
  deleted_posts_count: number;
  main_post_comments_count: number;
}>;

export type CommentsEventPayload =
  | ({ is_main_post: false } & (
      | CommentAddedEventData
      | UpdatedEventData<PostComment>
      | CommentDeletedEventData
      | ReactionAddedEventData
      | ReactionDeletedEventData
    ))
  | undefined;

type PossibleBroadcastType<T> = T extends {
  broadcast_type: infer U;
}
  ? U
  : never;

export type BatchedPostOrCommentEvent = {
  broadcast_type: typeof ENUMS.BroadcastType.BATCHED_POST_OR_COMMENT_EVENTS;
  data: ({
    event_type: PossibleBroadcastType<PostsEventPayload | CommentsEventPayload>;
  } & Omit<PostsEventPayload | CommentsEventPayload, "broadcast_type">)[];
};

// API
export type GetPostsReturnPayload = PaginatedResult<IPost> & {
  visited_feed_three_times?: boolean;
};

export type GetPostsParams = {
  category?: number;
  hashtag?: number;
  post_hashtag?: number;
  page?: number;
  page_size?: number;
  search?: string;
  ordering?: FeedOrderingType;
  space?: number;
  /* Required for cache invalidation for spaces posts, it's not passed to the backend */
  postsSessionIdx?: number;
};

export type GetMyPostsParams = Pick<GetPostsParams, "page" | "page_size">;

export type PostsParams = { id: number; is_user_first_post?: boolean };

export type DeleteCommentParams = { id: number; parent_id: number };

export type CommentsParams = { postId: number };

export type AddPostParams = Pick<IPost, "text" | "title"> &
  Partial<
    Pick<
      IPost,
      "budget" | "poll_options" | "poll_text" | "links" | "followed" | "space"
    >
  > & {
    category: number;
    self_promotion_response: number | null;
  };

export type UpdatePostPropsParams =
  | (AddPostParams & Pick<IPost, "id">)
  | (PostComment & Pick<PostComment, "id">);

export type GetCommentsReturnPayload = {
  comments: PostComment[];
};

export type AddPostCommentParams = {
  data: PostComment;
  postId: number;
};

export type UpdatePostCommentPropsParamsType =
  | (AddPostCommentParams & Pick<IPost, "id">)
  | (PostComment & Pick<PostComment, "id">);

export type EditPostCommentParams = {
  postId: number;
  data: UpdatePostCommentPropsParamsType;
};

export type ToggleReactionParams = {
  postId: number;
  mainPostId: number;
  data: { sticker: StickerValue };
};

export type GetHelpOffersParams = {
  other_user?: number;
  recipient?: number;
  direction?: EnumType<typeof ENUMS.HelpOfferDirectionFilter>;
  status?: EnumType<typeof ENUMS.HelpOfferStatus>;
};

export type EditHelpOfferParams = {
  id: number;
  data: HelpOffer;
};

export type RequestHelpOfferApprovalParams = {
  offerId: number;
  data: { budget: string };
};

export type DeclineOfferData = { decline_reason: string };

export type DeclineOfferProps = {
  offerId: number;
  data?: DeclineOfferData;
};

export type ReplyType = "reply-to-post" | "reply-to-comment" | "edit-comment";

export type CreateOrEditPostMode = "create-post" | "edit-post";

export type DeletedPostData = {
  id: number;
  deleted_posts_count: number;
  main_post_id: number | null;
  main_post_comments_count: number;
};

export type DeletedPostCommentData = {
  id: number;
  deleted_posts_count: number;
  main_post_id: number;
  main_post_comments_count: number;
};

export type RequestHelpOfferRefundParams = {
  offerId: number;
  data: { refund_requested_reason: string };
};

export type IssueRefundPostParams = {
  id: number;
  data: {
    budget: string;
    receiver: Pick<HelpOffer["receiver"]["user"], "first_name" | "last_name">;
    author: Pick<HelpOffer["author"]["user"], "first_name" | "last_name">;
  };
};

export type ReportPostParams = {
  id: number;
  data: { reason: string; message_to_moderator?: string };
};

export type HelpOfferRequestParams = {
  offerId: number;
  data: Pick<HelpOffer, "category" | "budget" | "receiver" | "author">;
};

export type AcceptHelpOfferParams = {
  offerId: number;
  accepted_budget: string | number;
  code?: string | null;
  is_backup_code?: boolean;
};

export type PostLocationType = EnumType<typeof PostLocation.Values>;

export type Hashtag = {
  id: number;
  name: string;
};

export type PostHashtag = {
  id: number; // FK
  hashtag_id: number;
  hashtag_name: string;
};

export type PostAttachments = { id?: number; isLoading?: boolean };

type NotFoundError = {
  error: {
    data: "Not found.";
    status: 404;
  };
  isUnhandledError?: boolean;
};

export function isNotFoundError(obj: any): obj is NotFoundError {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.error === "object" &&
    obj.error !== null &&
    obj.error.data?.detail === "Not found." &&
    obj.error.status === 404
  );
}

export type GetSavedPostsReturnPayload = PaginatedResult<IPost>;

export type GetSavedPostsParams = {
  page: number;
};

export type BookmarkPostParams = {
  id: number;
  post: number;
};

export type SelfPromotionRequestBody = Pick<IPost, "text" | "title">;

export type SelfPromotionCheckResponse = {
  id: number | null;
  is_violation: boolean;
};
