import { useLocation } from "react-router-dom";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import {
  isInterviewAvailable,
  useGetLastInterviewQuery,
} from "@js/apps/interview";
import { useJobLocationContext } from "@js/apps/jobs/context";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import { getURLWithBackLink } from "@js/utils";

import {
  clickBrowseOpenJobsButton,
  clickViewJobAfterSearch,
  viewJobClicked,
} from "../../actions";
import { getJobDetailsUrl } from "../../utils";

type ViewJobButtonProps = {
  jobId: number;
  fullWidth?: boolean;
  resultPosition?: number;
  searchResults?: boolean;
} & Omit<ButtonProps, "variant">;

export const ViewJobButton = ({
  jobId,
  searchResults,
  resultPosition,
  fullWidth,
  ...props
}: ViewJobButtonProps) => {
  const dispatch = useAppDispatch();
  const location = useJobLocationContext();
  const { search } = useLocation();
  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.JOB_SEARCH_BOX,
  );

  const handleClick = () => {
    dispatch(viewJobClicked({ source: location, search }));

    if (searchResults && resultPosition !== undefined)
      dispatch(
        clickViewJobAfterSearch({
          jobId,
          location,
          resultPosition,
          searchQueryId,
        }),
      );
  };
  return (
    <JobActionButtonBase
      label="View job"
      variant="primary"
      withIcon
      fullWidth={fullWidth}
      onClick={handleClick}
      to={getJobDetailsUrl({ id: jobId, search, location })}
      {...props}
    />
  );
};

type StartScreeningButtonProps = ButtonProps & {
  withIcon?: boolean;
};

export const StartScreeningButton = (props: StartScreeningButtonProps) => {
  const { isFreelancerApproved, isEmployer } = useAccountType();
  const { data: interview } = useGetLastInterviewQuery(undefined, {
    skip: isFreelancerApproved || isEmployer,
  });
  const isInterviewAvailableForUser = isInterviewAvailable(interview);
  const user = useUser();

  if (!isInterviewAvailableForUser || !user || !user.freelancer) return null;

  return (
    <JobActionButtonBase
      label="Start screening"
      to={`/talent/${user.freelancer}?interview=true`}
      {...props}
    />
  );
};

type ViewApplicationButtonProps = ButtonProps & {
  jobId: number;
  bidId?: number;
  withIcon?: boolean;
};

export const ViewApplicationButton = ({
  jobId,
  bidId,
  ...props
}: ViewApplicationButtonProps) => {
  if (!bidId) return null;

  return (
    <JobActionButtonBase
      label="View application"
      to={getURLWithBackLink(`/jobs/${jobId}/proposals/${bidId}/edit/`)}
      {...props}
    />
  );
};

type ScheduleInterviewButtonProps = ButtonProps & {
  employerUserId?: number;
  withIcon?: boolean;
};

export const ScheduleInterviewButton = ({
  employerUserId,
  ...props
}: ScheduleInterviewButtonProps) => {
  const user = useUser();
  const { openMessengerModal } = useOpenMessengerModal();

  if (!user || !employerUserId) return null;

  const handleScheduleInterview = () => {
    openMessengerModal({
      context: {
        participants: [employerUserId, user.id],
      },
    });
  };

  return (
    <JobActionButtonBase
      label="Schedule interview"
      onClick={handleScheduleInterview}
      {...props}
    />
  );
};

type OfferButtonProps = Omit<ButtonProps, "children"> & {
  jobId: number;
  offerId: number;
};

export const ReviewOfferButton = ({
  jobId,
  offerId,
  ...props
}: OfferButtonProps) => {
  return (
    <JobActionButtonBase
      label="Review offer"
      to={getURLWithBackLink(`/jobs/${jobId}/offers/${offerId}`)}
      {...props}
    />
  );
};

export const RespondToOfferButton = ({
  jobId,
  offerId,
  ...props
}: OfferButtonProps) => {
  return (
    <JobActionButtonBase
      label="Respond to offer"
      to={getURLWithBackLink(`/jobs/${jobId}/offers/${offerId}`)}
      {...props}
    />
  );
};

export const BrowseOpenJobs = (props: Omit<ButtonProps, "variant">) => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(clickBrowseOpenJobsButton());
  };

  return (
    <JobActionButtonBase
      label="Browse open jobs"
      to="/jobs"
      variant="primary"
      onClick={handleClick}
      {...props}
    />
  );
};

type JobActionButtonBaseProps = ButtonProps & {
  label: string;
  withIcon?: boolean;
};

export const JobActionButtonBase = ({
  label,
  withIcon,
  ...props
}: JobActionButtonBaseProps) => {
  return (
    <Button
      rel="noopener noreferrer"
      target="_blank"
      RouterLink={RouterLink}
      {...props}
      sx={{
        ...props.sx,
        display: { xs: "none", sm: "flex" },
        flexShrink: 0,
        height: { sm: 40 },
      }}
    >
      {label}
      {withIcon && (
        <ArrowTopRightIcon
          style={{
            fontSize: props.size === "x-small" ? 16 : 24,
            marginLeft: 8,
          }}
        />
      )}
    </Button>
  );
};
