import { Button, Loader } from "@hexocean/braintrust-ui-components";
import type { EmptyStateProps } from "@js/apps/freelancer/components";
import { MyWorkEmptyState } from "@js/apps/freelancer/components";
import { ListItem } from "@js/apps/freelancer/components/my-jobs-list-item";
import { useFreelancerJobs } from "@js/apps/jobs/hooks/freelancer-jobs";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";

type FreelancerDashboardJobsPageProps = {
  showClosedJobs: boolean;
};

export const FreelancerDashboardJobsPage = ({
  showClosedJobs,
}: FreelancerDashboardJobsPageProps) => {
  const { isLoading, jobs } = useFreelancerJobs(showClosedJobs);

  if (!jobs?.length) {
    return (
      <MyJobsEmptyList
        title="No active jobs yet"
        description="Jobs you are actively working will appear here."
      />
    );
  }

  return (
    <JobList
      jobs={jobs}
      loading={isLoading}
      emptyStateContent={
        <MyJobsEmptyList
          title="No active jobs yet"
          description="Jobs you are actively working will appear here."
        />
      }
    />
  );
};

type JobListProps = {
  jobs?: Job[];
  loading: boolean;
  emptyStateContent: React.ReactNode;
};

const JobList = ({ jobs, loading, emptyStateContent }: JobListProps) => {
  if (loading) return <Loader />;

  if (!jobs?.length) return <>{emptyStateContent}</>;

  return (
    <div>
      {jobs.map((job) => (
        <ListItem job={job} key={job.id} />
      ))}
    </div>
  );
};

type MyJobsEmptyListProps = {
  title: EmptyStateProps["title"];
  description: EmptyStateProps["description"];
};

const MyJobsEmptyList = ({ title, description }: MyJobsEmptyListProps) => (
  <MyWorkEmptyState
    title={title}
    description={description}
    icon="💼"
    iconBackgroundColor="var(--medium-grey)"
    cta={
      <Button
        sx={{ mt: 2 }}
        variant="white-border-beige"
        shape="squared"
        to="/role-jobs/"
        RouterLink={RouterLink}
      >
        Search jobs
      </Button>
    }
  />
);
