import type { ReactNode } from "react";

import type { StackProps } from "@hexocean/braintrust-ui-components";
import { Box, Chip, Stack, Tooltip } from "@hexocean/braintrust-ui-components";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";

export const BoostBidBadge = () => {
  return (
    <ApplicationBoostFeatureFlagWrapper>
      <Chip
        sx={{
          backgroundColor: `#E8F2FC !important`,
          color: `#4E86CF !important`,
        }}
        label={<BoostBidChipLabel label="Boosted" />}
        size="x-small"
      />
    </ApplicationBoostFeatureFlagWrapper>
  );
};

const BoostBidChipLabel = ({
  label,
  sx,
}: {
  label: ReactNode;
  sx?: StackProps["sx"];
}) => {
  return (
    <>
      <Tooltip title="This candidate has boosted this job as their top choice, demonstrating strong interest and commitment">
        <Stack
          direction="row"
          sx={{
            gap: "5px",
            alignItems: "center",
            fontSize: "12px",
            lineHeight: "24px",
            ...sx,
          }}
        >
          <Box>
            <img
              src={`${SETTINGS.STATIC_URL}jobs/boost-icon-blue.svg`}
              alt="check-email"
            />
          </Box>
          {label}
        </Stack>
      </Tooltip>
    </>
  );
};
