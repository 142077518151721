import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { AccountInfoForm } from "@js/apps/freelancer/forms/account-info";
import {
  useAccountInfo,
  useFreelancerProfile,
} from "@js/apps/freelancer/hooks";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const About = () => {
  const profile = useFreelancerProfile();
  const { formConfig } = useAccountInfo(profile);

  return (
    <Box className="profile-page-modal-account-info" sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <AccountInfoForm
        {...formConfig}
        headerComponent={
          <Typography component="h3" variant="title" size="small" mb={4}>
            👋 Bio
          </Typography>
        }
      />
    </Box>
  );
};
