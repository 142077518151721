import { useMemo } from "react";
import z from "zod";

import { useLocalStorageState } from "@js/apps/common/hooks/use-local-storage-state";
import { employerInvoicesColumnsChanged } from "@js/apps/employer/actions";
import { useAppDispatch } from "@js/hooks";
import { LocalStorage } from "@js/services";

import type { EmployerInvoicesTableColumnField } from "../types";

import {
  EMPLOYER_INVOICES_ALL_COLUMNS_FIELDS,
  EMPLOYER_INVOICES_COLUMNS_OPTIONS,
  EMPLOYER_INVOICES_DEFAULT_COLUMNS_FIELDS,
} from "./columns";

const allFields = EMPLOYER_INVOICES_ALL_COLUMNS_FIELDS as [
  EmployerInvoicesTableColumnField,
  ...EmployerInvoicesTableColumnField[],
];

const visibleColumnsValueSchema = z.array(z.enum(allFields));

export const useEmployerInvoicesCustomizedColumns = () => {
  const [columns, setColumns] = useLocalStorageState<
    EmployerInvoicesTableColumnField[]
  >({
    key: LocalStorage.keys.EMPLOYER_INVOICES_CUSTOMIZED_COLUMNS,
    initialValue: EMPLOYER_INVOICES_DEFAULT_COLUMNS_FIELDS,
  });
  const dispatch = useAppDispatch();

  const sanitizedColumns = useMemo(() => {
    const parseResult = visibleColumnsValueSchema.safeParse(columns);
    if (!parseResult.success) {
      return EMPLOYER_INVOICES_DEFAULT_COLUMNS_FIELDS;
    }

    return parseResult.data;
  }, [columns]);

  const options = useMemo(() => {
    return EMPLOYER_INVOICES_COLUMNS_OPTIONS.map((column) => {
      const disabled =
        sanitizedColumns.length === 1 &&
        sanitizedColumns.includes(column.value);

      return {
        ...column,
        disabled,
      };
    });
  }, [sanitizedColumns]);

  const onColumnsChange = (
    selectedValue: EmployerInvoicesTableColumnField[],
  ) => {
    setColumns(selectedValue);
  };

  const onColumnsMenuClose = () => {
    dispatch(
      employerInvoicesColumnsChanged({
        columnOptions: EMPLOYER_INVOICES_COLUMNS_OPTIONS,
        selectedColumns: sanitizedColumns,
      }),
    );
  };

  return {
    columns: sanitizedColumns,
    columnsOptions: options,
    onColumnsChange,
    onColumnsMenuClose,
  };
};
